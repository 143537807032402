import React from 'react';
import { withRouter, Route, Switch, NavLink } from 'react-router-dom';
import { Grid, Container, Divider, Modal, Button } from '@material-ui/core';

import MobilePersonalDetailForm from '../forms/MobilePersonalDetails';
import BackArrowLogo from '../../../assets/back_arrow.png';
import LifestyleLogo from '../../../assets/lifestyle.png';
import BfsiLogo from '../../../assets/bfsi.png';
import FoodLogo from '../../../assets/food.png';
import AutomobileLogo from '../../../assets/automobile.png';
import FashionLogo from '../../../assets/fashion.png';
import DigitalLogo from '../../../assets/digital.png';
import CloseLogo from '../../../assets/close.png';
import Iframe from 'react-iframe';

class AboutMe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IframeHtml: null,
      ShowIframe: false,
    };
  }

  componentDidMount() {}

  async submitForm() {
    await this.personalDetailForm.submitForm();
  }

  render() {
    return (
      <Grid container style={{ backgroundColor: 'white', fontSize: '12px' }}>
        <Grid
          container
          style={{
            border: '1px solid #DCDCDC',
            // padding: "15px",
            width: '100%',
          }}
        >
          <Grid item md={12}>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                justifyContent: 'space-between',
              }}
            >
              <Grid item md={12}>
                <NavLink
                  to="/influencer/settings"
                  style={{
                    textDecoration: 'none',
                    lineHeight: '17px',
                    margin: '5px',
                    color: 'black',
                  }}
                >
                  <span>
                    <img src={BackArrowLogo} style={{ width: 20 }} />
                  </span>
                  &nbsp;&nbsp;
                  <span
                    style={{
                      fontSize: 15,
                    }}
                  >
                    About Me
                  </span>
                </NavLink>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={5}>
                <Divider
                  style={{
                    background:
                      'linear-gradient(90deg, rgba(171,171,171,1) 0%, rgba(176,176,176,0) 100%)',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            marginBottom: 30,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <Grid item md={5}>
            <Divider
              style={{
                background:
                  'linear-gradient(90deg, rgba(171,171,171,1) 0%, rgba(176,176,176,0) 100%)',
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            marginBottom: 30,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <Grid item md={5}>
            How can we help you Tell you more about yourself
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            marginBottom: 30,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <Grid
            item
            md={5}
            style={{
              border: '1px solid #000000',
              padding: '15px',
              width: '100%',
              color: '#1E9CB2',
            }}
          >
            Fill out this form
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            marginBottom: 30,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <Grid
            item
            md={12}
            style={{
              padding: '15px',
              width: '100%',
              color: '#1E9CB2',
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              style={{ marginBottom: 30 }}
            >
              <Button
                style={{ textTransform: 'none', padding: 0 }}
                onClick={() => {
                  this.setState({
                    IframeHtml: 0,
                    ShowIframe: true,
                  });
                }}
                // href="https://m8q0s1wparo.typeform.com/to/NkbG27WK?typeform-source=mail.google.com"
                // target="_blank"
              >
                <Grid
                  item
                  md={4}
                  style={{
                    border: '1px solid #D6D6D6',
                    borderRadius: '5px',
                    // padding: "15px",
                    color: '#1E9CB2',
                    width: '25vw',
                    // height: "100px",
                    backgroundColor: '#FBFBFB',
                  }}
                >
                  <img src={BfsiLogo} style={{ height: 25, padding: '15px' }} />
                  <p style={{ margin: 0 }}>BFSI</p>
                </Grid>
              </Button>
              <Button
                style={{ textTransform: 'none', padding: 0 }}
                onClick={() => {
                  this.setState({
                    IframeHtml: 1,
                    ShowIframe: true,
                  });
                }}
                //href="#"
                //target="_blank"
              >
                <Grid
                  item
                  md={4}
                  style={{
                    border: '1px solid #D6D6D6',
                    borderRadius: '5px',
                    // padding: "15px",
                    color: '#1E9CB2',
                    width: '25vw',
                    // height: "100px",
                    backgroundColor: '#FBFBFB',
                  }}
                >
                  <img src={FashionLogo} style={{ height: 25, padding: '15px' }} />
                  <p style={{ margin: 0 }}>Fashion</p>
                </Grid>
              </Button>
              <Button
                style={{ textTransform: 'none', padding: 0 }}
                onClick={() => {
                  this.setState({
                    IframeHtml: 2,
                    ShowIframe: true,
                  });
                }}
                // href="https://m8q0s1wparo.typeform.com/to/XlizfFQv?typeform-source=mail.google.com"
                // target="_blank"
              >
                <Grid
                  item
                  md={4}
                  style={{
                    border: '1px solid #D6D6D6',
                    borderRadius: '5px',
                    // padding: "15px",
                    color: '#1E9CB2',
                    width: '25vw',
                    // height: "100px",
                    backgroundColor: '#FBFBFB',
                  }}
                >
                  <img src={FoodLogo} style={{ height: 25, padding: '15px' }} />
                  <p style={{ margin: 0 }}>Food</p>
                </Grid>
              </Button>
            </Grid>
            <Grid container direction="row" justifyContent="space-around" alignItems="center">
              <Button
                style={{ textTransform: 'none', padding: 0 }}
                onClick={() => {
                  this.setState({
                    IframeHtml: 3,
                    ShowIframe: true,
                  });
                }}
                //href="#"
                //target="_blank"
              >
                <Grid
                  item
                  md={4}
                  style={{
                    border: '1px solid #D6D6D6',
                    borderRadius: '5px',
                    // padding: "15px",
                    color: '#1E9CB2',
                    width: '25vw',
                    // height: "100px",
                    backgroundColor: '#FBFBFB',
                  }}
                >
                  <img src={LifestyleLogo} style={{ height: 25, padding: '15px' }} />
                  <p style={{ margin: 0 }}>Lifestyle</p>
                </Grid>
              </Button>
              <Button
                style={{ textTransform: 'none', padding: 0 }}
                onClick={() => {
                  this.setState({
                    IframeHtml: 4,
                    ShowIframe: true,
                  });
                }}
                //href="#"
                //target="_blank"
              >
                <Grid
                  item
                  md={4}
                  style={{
                    border: '1px solid #D6D6D6',
                    borderRadius: '5px',
                    // padding: "15px",
                    color: '#1E9CB2',
                    width: '25vw',
                    // height: "100px",
                    backgroundColor: '#FBFBFB',
                  }}
                >
                  <img src={AutomobileLogo} style={{ height: 25, padding: '15px' }} />
                  <p style={{ margin: 0 }}>Automobile</p>
                </Grid>
              </Button>
              <Button
                style={{ textTransform: 'none', padding: 0 }}
                onClick={() => {
                  this.setState({
                    IframeHtml: 5,
                    ShowIframe: true,
                  });
                }}
                //href="#"
                //target="_blank"
              >
                <Grid
                  item
                  md={4}
                  style={{
                    border: '1px solid #D6D6D6',
                    borderRadius: '5px',
                    // padding: "15px",
                    color: '#1E9CB2',
                    width: '25vw',
                    // height: "100px",
                    backgroundColor: '#FBFBFB',
                  }}
                >
                  <img src={DigitalLogo} style={{ height: 25, padding: '15px' }} />
                  <p style={{ margin: 0 }}>Digital</p>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {
          this.state.IframeHtml == 0 && this.state.ShowIframe && (
            <Iframe
              url="https://form.typeform.com/to/NkbG27WK?typeform-medium=embed-snippet"
              width="100%"
              height="80%"
              display="initial"
              position="absolute"
              top="0"
            />
          )
          // </div>
        }
        {
          this.state.IframeHtml == 1 && this.state.ShowIframe && (
            <Iframe
              url="https://m8q0s1wparo.typeform.com/to/JHdfoQgJ"
              width="100%"
              height="80%"
              display="initial"
              position="absolute"
              top="0"
            />
          )
          // </div>
        }
        {
          this.state.IframeHtml == 2 && this.state.ShowIframe && (
            <Iframe
              url="https://m8q0s1wparo.typeform.com/to/XlizfFQv?typeform-source=mail.google.com"
              width="100%"
              height="80%"
              display="initial"
              position="absolute"
              top="0"
            />
          )
          // </div>
        }
        {
          this.state.IframeHtml == 4 && this.state.ShowIframe && (
            <Iframe
              url="https://m8q0s1wparo.typeform.com/to/Gv16Xr2H"
              width="100%"
              height="80%"
              display="initial"
              position="absolute"
              top="0"
            />
          )
          // </div>
        }
        {this.state.ShowIframe && (
          <div style={{ position: 'absolute', top: '70px', right: '5px', opacity: 1 }}>
            <Button
              style={{ textTransform: 'none', padding: 0 }}
              onClick={() => {
                this.setState({
                  IframeHtml: null,
                  ShowIframe: false,
                });
              }}
            >
              <img style={{ width: 20, height: 20 }} src={CloseLogo} />
            </Button>
          </div>
        )}
      </Grid>
    );
  }
}

export default withRouter(AboutMe);
