import { useState, useEffect } from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LocationIcon from 'assets/discovery/Location-Icon.png';

import MultiSelect from 'components/PlannerTheme/Elements/MultiSelect';
import { getPlannerCountries } from 'api_handlers/discovery';

function InfluencerCountry({ influencerCountries, hideAdvanceFilters, classes }) {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getPlannerCountries().then((response) => {
      // console.log("response data", response.data);
      setCountries(response.data);
    });
  }, []);
  return (
    <>
      <div
        style={{
          display: 'flex',
          marginBottom: 10,
          justifyContent: 'center',
        }}
      >
        <img src={LocationIcon} width={22} style={{ marginRight: 7 }} />
        <Typography className={classes.advanceHeading}>Country</Typography>
      </div>
      <div style={{ marginTop: 10 }}>
        <MultiSelect
          label={'Select Country'}
          options={countries
            .filter((location) => location)
            .map((location) => ({ label: location, value: location }))}
          value={influencerCountries || []}
          onChange={(event, selectedValues) => {
            hideAdvanceFilters('influencerCountries', selectedValues);
          }}
        />
      </div>
    </>
  );
}

const styles = {
  advanceHeading: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 14,
  },
};

export default withStyles(styles)(InfluencerCountry);
