import React from 'react';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Modal,
  Tab,
  Tabs,
  Typography,
  Link,
  Chip,
  TextField,
} from '@material-ui/core';
import DiscoverySocialMediaSummery from './DiscoverySocialMediaSummery';

import { colorConstants } from '../../../constants/colors';
import { campaignActions } from '../../../actions/campaign';

import InstagramLogo from '../../../assets/instagram-logo.png';
import YouTubeLogo from '../../../assets/youtube-logo.png';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { campaignHandlers } from '../../../api_handlers/campaign';
import NegoitateInput from '../../ux/NegoitateInput';
import AddCircleLogo from '../../../assets/add_circle.png';
import EllipsisText from 'react-ellipsis-text';

class DiscoveryInfluencerCrad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      influencer: this.props,
      socialPlatform: this.props.youtube_link
        ? 'youtube'
        : this.props.instagram_link
        ? 'instagram'
        : '',
      modalOpen: false,
    };
  }

  toggleSocialPlatform = (event, platform) => {
    this.setState({
      socialPlatform: platform,
    });
  };

  // updateInfluencerStatus = (status) => {
  //     this.setState({'status':status})
  //     this.updateinfluencer(this.state.brand_quote,status)
  //     // this.toggleModal()
  // }

  render() {
    var field_of_intrest = this.state.influencer.field_of_intrest;
    var more_intrest_number = 0;
    if (field_of_intrest.length > 2) {
      more_intrest_number = field_of_intrest.length - 2;
      field_of_intrest = field_of_intrest.slice(0, 2);
    }
    return (
      <Card
        style={{
          radius: '5px',
          maxHeight: '400px',
          minHeight: '400px',
        }}
      >
        <CardContent>
          <Grid container spacing={3} direction="row" justify="center" alignItems="center">
            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Grid item md={1}>
                  <img
                    src={AddCircleLogo}
                    alt="add-Influnecer"
                    style={{
                      width: '25px',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                  <Grid item xs={12}>
                    <Avatar
                      src={this.state.influencer.avatar}
                      alt={this.state.influencer.name}
                      style={{
                        width: '6vw',
                        height: '6vw',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        marginTop: '10px',
                        textAlign: 'left',
                      }}
                      component="h6"
                      variant="h6"
                    >
                      <EllipsisText
                        text={this.state.influencer.name}
                        tooltip={this.state.influencer.name}
                        length={'30'}
                      />
                      {/* {this.state.influencer.name} */}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    {field_of_intrest?.map((field) => (
                      <Chip
                        label={
                          <EllipsisText text={field.name} tooltip={field.name} length={'12'} />
                        }
                        size="small"
                        style={{
                          background: '#1E9CB2',
                          color: 'white',
                          fontSize: '11px',
                          padding: '0px 8px 0px',
                          marginRight: '7px',
                          height: 20,
                        }}
                      ></Chip>
                    ))}
                    {field_of_intrest.length == 0 ? (
                      <Chip
                        label={''}
                        size="small"
                        style={{
                          background: 'white',
                          color: 'white',
                          fontSize: '11px',
                          padding: '0px 8px 0px',
                          marginRight: '7px',
                          height: 20,
                        }}
                      ></Chip>
                    ) : null}
                    {more_intrest_number ? (
                      <Chip
                        label={' +' + more_intrest_number}
                        size="small"
                        style={{
                          background: '#1E9CB2',
                          color: 'white',
                          fontSize: '11px',
                          padding: '0px 8px 0px',
                          marginRight: '7px',
                          height: 20,
                        }}
                      ></Chip>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        marginTop: '10px',
                        textAlign: 'left',
                      }}
                      component="h6"
                      variant="h6"
                    >
                      {this.state.influencer.youtube_link && (
                        <a target="_blank" href={this.state.influencer.youtube_link}>
                          <img src={YouTubeLogo} alt={'youtube-logo'} width={'20'} />
                        </a>
                      )}
                      &nbsp;
                      {this.state.influencer.instagram_link && (
                        <a target="_blank" href={this.state.influencer.instagram_link}>
                          <img src={InstagramLogo} alt={'instagram-logo'} width={'15'} />
                        </a>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*<Grid container>
                        <Grid item xs={12}>
                        <hr style={{
                            width: '99%',
                            border: '1px solid #A6A6A6'
                        }}/>
                        </Grid>
                    </Grid>*/}
          {(this.state.influencer.youtube_link || this.state.influencer.instagram_link) && (
            <Grid container>
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'block',
                    fontWeight: 'bold',
                    fontSize: '13px',
                    backgroundColor: '#FAFAFA',
                  }}
                >
                  <Tabs
                    value={this.state.socialPlatform}
                    onChange={this.toggleSocialPlatform}
                    wrapped={'true'}
                  >
                    {' '}
                    {this.state.influencer.youtube_link && (
                      <Tab
                        value={'youtube'}
                        label={
                          <span
                            style={{
                              textTransform: 'none',
                              fontSize: 11,
                              fontWeight: 400,
                              textAlign: 'center',
                            }}
                          >
                            <img src={YouTubeLogo} alt={'youtube-logo'} width={'15'} />
                            &nbsp;Youtube
                          </span>
                        }
                      ></Tab>
                    )}
                    {this.state.influencer.instagram_link && (
                      <Tab
                        value={'instagram'}
                        label={
                          <span
                            style={{
                              textTransform: 'none',
                              fontSize: 11,
                              fontWeight: 400,
                              textAlign: 'center',
                            }}
                          >
                            <img src={InstagramLogo} alt={'instagram-logo'} width={'12'} />
                            &nbsp;Instagram
                          </span>
                        }
                      ></Tab>
                    )}
                  </Tabs>
                  {this.state.socialPlatform == 'instagram' &&
                    this.state.influencer.instagram_link && (
                      <DiscoverySocialMediaSummery
                        socialPlatform={this.state.socialPlatform}
                        {...this.state.influencer.instagram_detail}
                        {...this.state.influencer.youtube_detail}
                      />
                    )}
                  {this.state.socialPlatform == 'youtube' && this.state.influencer.youtube_link && (
                    <DiscoverySocialMediaSummery
                      socialPlatform={this.state.socialPlatform}
                      {...this.state.influencer.instagram_detail}
                      {...this.state.influencer.youtube_detail}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.campaign.loading,
});

const actionCreators = {
  getCampaignDetails: campaignActions.getCampaignDetails,
  getInfluencers: campaignActions.getInfluencers,
  updateInfluencerStatus: campaignActions.updateInfluencerStatus,
};

export default connect(mapStateToProps, actionCreators)(withRouter(DiscoveryInfluencerCrad));
