import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { gridSpacing } from 'store/constant';

import SliderFilter from './SliderFilter'
import GenderSplitSliderFilter from './GenderSplitSliderFilter';
import AutocompleteFilter from './AutocompleteFilter';

import { getCityStateList } from 'api_handlers/discovery';
import { getPlannerAudienceInterests } from 'api_handlers/discovery';

import { updateFilters } from 'actions/planner-filters';



const InfluencerFilters = () => {
  const dispatch = useDispatch();
  const audienceCredibility = useSelector((state) => state.plannerFilters.audienceCredibility);
  const ageGroup = useSelector((state) => state.plannerFilters.audienceAgeGroup);
  const audienceInterest = useSelector((state) => state.plannerFilters.audienceInterest);
  const audienceLocation = useSelector((state) => state.plannerFilters.audienceLocation);

  const [categoryOptions, setCategoryOptions] = React.useState([]);


  const [cityOptions, setCityOptions] = React.useState([]);

  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(async () => {

    const cityResponse = await getCityStateList(true);
    setCityOptions([...cityResponse.data.filter(option => option)]);

    const fieldOfInterest = await getPlannerAudienceInterests();
    setCategoryOptions([...fieldOfInterest]);
  
  }, [])

  return (
    <Grid container spacing={gridSpacing} sx={{height: 300, overflowY: 'scroll', pl: 4, pr: 2}}>
      <Grid item xs={12}>
        <SliderFilter value={audienceCredibility || 0} min={0} max={100} title='Credibility' payloadKey={'audienceCredibility'} showTextField={true} stepSize={0.1}/>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} alignItems='center' justifyContent='space-evenly' sx={{ mb: 2 }}>
          <Grid item xs={3}>
            <Typography variant="h6" gutterBottom>
              Age Group
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Select
              labelId="ageGroup-select"
              id="ageGroup"
              name="ageGroup"
              value={ageGroup}
              onChange={(event) => {
                dispatch(updateFilters('audienceAgeGroup', event.target.value))
              }}
              label="Age Group"
              fullWidth
              size='small'
            >
              <MenuItem value={'13-17'}>13 - 17</MenuItem>
              <MenuItem value={'18-24'}>18 - 24</MenuItem>
              <MenuItem value={'25-34'}>25 - 34</MenuItem>
              <MenuItem value={'35-44'}>35 - 44</MenuItem>
              <MenuItem value={'45-64'}>45 - 64</MenuItem>
              <MenuItem value={'65-'}>65 - </MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Grid>

      {/* Interest Filter */}
      <Grid item xs={12}>
        <AutocompleteFilter value={audienceInterest} options={categoryOptions} title='Interest' payloadKey={'audienceInterest'} />
      </Grid>

      {/* Location Filter */}
      <Grid item xs={12}>
        <AutocompleteFilter value={audienceLocation} options={cityOptions} title='Location' payloadKey={'audienceLocation'}/>
      </Grid>

      {/* Gender Split Filter */}
      <Grid item xs={12}>
        <GenderSplitSliderFilter title='Gender Split' payloadKey={'audienceGenderSplit'}/>
      </Grid>

    </Grid>
  )
}

export default InfluencerFilters;