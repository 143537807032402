import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import { campaignActions } from '../../actions/campaign';

import ReportingDashboard from '../reporting/Dashboard';
import InfluencerDashboard from '../influencers/InfluencerDashboard';
import Feed from '../Feed/Feed';
import CampaignSidePanel from './CampaignSidePanel';
import Communication from '../Communication/communication';
import ContentApproval from 'components/influencers/ContentApproval';
import { isWhatsAppModuleEnable } from 'components/utils';

class CampaignDetail extends React.Component {
  constructor(props) {
    super(props);
    this.influencerdRef = React.createRef();
    this.downloadReport = this.downloadReport.bind(this);
  }

  componentDidMount() {
    this.props.updateCampaignId(this.props.match.params.campaignId);
  }

  downloadReport() {
    if (this.influencerdRef.state.alignment) {
      this.influencerdRef.downloadReport();
    }
  }

  render() {
    return (
      <>
        <Grid container style={{ marginBottom: 10 }}>
          <Grid item md={12}>
            <CampaignSidePanel downloadReport={this.downloadReport} />
          </Grid>
        </Grid>
        <Grid container style={{ paddingLeft: 85 }}>
          <Grid item md={12}>
            <Switch>
              <Route path={`${this.props.match.url}/dashboard`} component={ReportingDashboard} />
              <Route path={`${this.props.match.path}/feeds`} component={Feed} />
              <Route
                path={`${this.props.match.path}/influencers`}
                component={InfluencerDashboard}
              />
              {
                isWhatsAppModuleEnable() ? 
                <Route
                  path={`${this.props.match.path}/communication`}
                  component={Communication}
                /> : null }
              {
                isWhatsAppModuleEnable() ?
                <Route
                  path={`${this.props.match.path}/content-approval`}
                  component={ContentApproval}
                /> : null
              }
            </Switch>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const actionCreators = {
  updateCampaignId: campaignActions.updateCampaignId,
};

export default connect(mapStateToProps, actionCreators)(withRouter(CampaignDetail));
