import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Filter from '../Filter';
import MinMaxInput from 'components/Theme/Elements/MinMaxInput';

import EngagementRateIcon from 'assets/discovery/Eng-Rate-icon.png';

function AvgEngagementFilter({ value = [null, null], setFilters }) {
  const classes = useStyles();

  const handleFilter = (values) => {
    setFilters({
      avgEngagement: values,
    });
  };

  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Filter label={'Average Engagement'} labelIconSrc={EngagementRateIcon}>
          <Grid container className={classes.filterContainer}>
            <MinMaxInput value={value} onChange={handleFilter} />
          </Grid>
        </Filter>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {},
}));

export default AvgEngagementFilter;
