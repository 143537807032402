import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Grid, Button } from '@material-ui/core';

import facebookLoginButton from '../../assets/connect_with_facebook.png';
import InstagramLogo from '../../assets/instagram-logo.png';
import { borderRadius } from '@material-ui/system';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookLoginButton from '../../assets/facebook_login_button.png';
import FacebookIcon from '@mui/icons-material/Facebook';

class FacebookLogin extends Component {
  constructor(props) {
    super(props);
  }

  getScope() {
    const scopes = [
      'public_profile',
      'email',
      'pages_show_list',
      'instagram_manage_insights',
      'instagram_basic',
      'pages_read_engagement',
    ];

    if (this.props.userType === 'brand') {
      scopes.push('ads_read');
      scopes.push('ads_management');
    }

    return scopes.join(',');
  }
  initFacebookSdk() {
    return new Promise((resolve) => {
      // wait for facebook sdk to initialize before starting the react app
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '449781952733647',
          cookie: true,
          xfbml: true,
          version: 'v11.0',
        });

        // auto authenticate with the api if already logged in with facebook
        window.FB.getLoginStatus(({ authResponse }) => {
          if (authResponse) {
            // accountService.apiAuthenticate(authResponse.accessToken).then(resolve);
          } else {
            resolve();
          }
        });
      };

      // load facebook sdk script
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    });
  }

  // async apiAuthenticate(accessToken) {
  //     // authenticate with the api using a facebook access token,
  //     // on success the api returns an account object with a JWT auth token
  //     const response = await axios.post(`${baseUrl}/authenticate`, { accessToken });
  //     const account = response.data;
  //     accountSubject.next(account);
  //     startAuthenticateTimer();
  //     return account;
  // }

  async login() {
    // login with facebook then authenticate with the API to get a JWT auth token
    const that = this;

    // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
    await window.FB.api('/me/permissions', 'delete', null, async () => {
      await window.FB.logout();
      const { authResponse } = await new Promise(() => {
        window.FB.login(
          (response) => {
            that.props.onSuccess(response.authResponse);
          },
          { scope: this.getScope() },
        );
      });

      if (!authResponse) return;
      that.props.onSuccess(authResponse);
    });

    // get return url from location state or default to home page
    // const { from } = history.location.state || { from: { pathname: "/" } };
    // history.push(from);
  }

  async logout() {
    // stopAuthenticateTimer();
    // accountSubject.next(null);
    // history.push('/login');
  }

  componentDidMount() {
    this.initFacebookSdk().then(() => {});
  }

  render() {
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        onClick={() => {
          this.login();
        }}
        style={{
          cursor: 'pointer',
          margin: '5px',
        }}
      >
        <Button
          style={{
            background: '#1877f2',
            borderRadius: '3px',
            color: 'white',
            fontSize: 16,
            textTransform: 'none',
            padding: '10px',
            height: '43px',
            fontWeight: 900,
          }}
          onClick={() => {
            // this.updateInstagramConnection()
          }}
          disabled={this.props.is_linked}
        >
          <FacebookIcon style={{ marginRight: 5 }} />
          {this.props.label}
        </Button>
        {/* <img src={FacebookLoginButton} width={200}/> */}
      </Grid>
    );
  }
}

export default withRouter(FacebookLogin);
