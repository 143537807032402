import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Button,
  Radio,
  TextField,
  RadioGroup,
  FormControlLabel,
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Input,
  Badge,
  InputLabel,
  Snackbar,
  Slide,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import EditIcon from '@material-ui/icons/Edit';
import toast, { Toaster } from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import {
  getCities,
  getStates,
  getInfluencerProfile,
  updateInfluencerProfile,
  updatePassword,
} from '../../../api_handlers/influencer.js';

import ErrorDisplay from './ErrorDisplay';

const styles = {
  font13: {
    fontSize: 13,
  },
  avatar: {
    height: 100,
    width: 100,
  },
  editIcon: {
    color: 'white',
    background: '#3581F3',
    borderRadius: '50%',
    height: 20,
    width: 20,
    paddingLeft: 5,
    paddingTop: 5,
  },
};

class PersonalDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pic: null,
      name: null,
      email: null,
      phoneNumber: null,
      dob: null,
      gender: null,
      addressLine: null,
      city: null,
      state: null,
      pincode: null,
      passwordModalOpen: false,
      oldPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      cityOptions: [],
      stateOptions: [],
      selectedCity: null,
      selectedState: null,
      snackbarOpen: false,
      formErrors: {},
      changePasswordError: {},
    };

    this.backendKeyMap = {
      password: 'New Password',
      old_password: 'Current Password',
      confirm_new_password: 'Retype New Password',
    };
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
    this.loadData();
  }

  async loadData() {
    const influencerData = await getInfluencerProfile();
    this.setState({
      pic: influencerData.avatar,
      name: influencerData.name,
      email: influencerData.user.email,
      phoneNumber: influencerData.phone_number,
      dob: new Date(influencerData.d_o_b),
      gender: influencerData.gender,
    });

    if (influencerData.address) {
      this.setState({
        addressLine: influencerData.address.address,
        city: influencerData.address.city,
        state: influencerData.address.state,
        pincode: influencerData.address.pin,
      });
    }

    const cities = await getCities();
    const states = await getStates();

    this.setState({
      cityOptions: cities,
      stateOptions: states,
      selectedCity: cities.filter((city) => city.id === this.state.city)[0],
      selectedState: states.filter((state) => state.id === this.state.state)[0],
    });
  }

  collectFormData() {
    let formData = {};
    let address = {};

    if (this.state.pic) formData['avatar'] = this.state.pic;
    if (this.state.name) formData['name'] = this.state.name;
    if (this.state.email) formData['user'] = { email: this.state.email };
    if (this.state.phoneNumber) formData['phone_number'] = this.state.phoneNumber;
    if (this.state.dob)
      formData['d_o_b'] = `${this.state.dob.getFullYear()}-${(
        this.state.dob.getMonth() +
        1 +
        ''
      ).padStart(2, '0')}-${(this.state.dob.getUTCDate() + '').padStart(2, '0')}`;
    if (this.state.gender) formData['gender'] = this.state.gender;

    if (this.state.addressLine) address['address'] = this.state.addressLine;
    if (this.state.selectedCity) address['city'] = this.state.selectedCity.id;
    if (this.state.selectedState) address['state'] = this.state.selectedState.id;
    if (this.state.pincode) address['pin'] = this.state.pincode;

    if (Object.keys(address).length > 0) formData['address'] = address;

    return formData;
  }

  notify() {
    toast('Data updated');
  }

  async submitForm() {
    const formData = this.collectFormData();

    if (Object.keys(formData).length > 0) {
      try {
        const resp = await updateInfluencerProfile(formData);
        this.notify();
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.influencer) {
          user['influencer']['name'] = this.state.name;
          localStorage.setItem('user', JSON.stringify(user));
        }

        if (resp && this.props.callback) {
          this.props.callback();
        }
      } catch (error) {
        this.setState({
          formErrors: await error.data,
        });
      }
    } else if (this.props.callback) {
      this.props.callback();
    }
  }

  async changePassword() {
    const formData = {
      old_password: this.state.oldPassword,
      password: this.state.newPassword,
      confirm_new_password: this.state.confirmNewPassword,
    };
    try {
      const resp = await updatePassword(formData);
      this.setState({
        passwordModalOpen: false,
        oldPassword: null,
        newPassword: null,
        confirmNewPassword: null,
      });
    } catch (error) {
      this.setState({
        changePasswordError: await error.data,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const phone_number_style = { width: '100%', '-webkit-font-smoothing': '' };
    return (
      <form
        noValidate
        autoComplete="off"
        style={{
          fontSize: 12,
          textAlign: 'left',
          width: '100%',
        }}
      >
        <ErrorDisplay errors={this.state.formErrors} backendKeyMap={this.backendKeyMap} />
        {!this.props.fieldsToShow || this.props.fieldsToShow.includes('profile_pic') ? (
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            style={{ marginBottom: 30 }}
          >
            <Grid item md={2}>
              Add Photo<sup>*</sup>
            </Grid>
            <Grid item md={1}>
              :
            </Grid>
            <Grid item md={7}>
              <Badge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={
                  <InputLabel htmlFor="profile-pic" classes={{ root: classes.editIcon }}>
                    <EditIcon style={{ fontSize: 14 }} />
                  </InputLabel>
                }
              >
                <Avatar
                  src={this.state.pic}
                  classes={{
                    root: classes.avatar,
                  }}
                />
              </Badge>

              <input
                type="file"
                id="profile-pic"
                onChange={(event) => {
                  const files = event.target.files;

                  if (FileReader && files && files.length) {
                    var fr = new FileReader();
                    fr.onload = () => {
                      this.setState({
                        pic: fr.result,
                      });
                    };
                    fr.readAsDataURL(files[0]);
                  }
                }}
                InputLabelProps={{ shrink: false }}
                style={{
                  width: '100%',
                  background: '#F9F9F9',
                  display: 'none',
                }}
              />
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {!this.props.fieldsToShow || this.props.fieldsToShow.includes('name') ? (
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            style={{ marginBottom: 30 }}
          >
            <Grid item md={2}>
              Name<sup>*</sup>
            </Grid>
            <Grid item md={1}>
              :
            </Grid>
            <Grid item md={7}>
              <TextField
                id="standard-basic"
                value={this.state.name}
                onChange={(event) => {
                  this.setState({ name: event.target.value });
                }}
                InputLabelProps={{ shrink: false }}
                style={{
                  fontSize: 10,
                  width: '100%',
                  background: '#F9F9F9',
                }}
                InputProps={{
                  style: {
                    fontSize: 13,
                  },
                }}
              />
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {!this.props.fieldsToShow || this.props.fieldsToShow.includes('email') ? (
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            style={{ marginBottom: 30 }}
          >
            <Grid item md={2}>
              Email<sup>*</sup>
            </Grid>
            <Grid item md={1}>
              :
            </Grid>
            <Grid item md={7}>
              <TextField
                id="standard-basic"
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
                InputLabelProps={{ shrink: false }}
                style={{
                  width: '100%',
                  background: '#F9F9F9',
                }}
                InputProps={{
                  style: {
                    fontSize: 13,
                  },
                }}
              />
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {!this.props.fieldsToShow || this.props.fieldsToShow.includes('name') ? (
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            style={{ marginBottom: 30 }}
          >
            <Grid item md={2}>
              Phone-Number<sup>*</sup>
            </Grid>
            <Grid item md={1}>
              :
            </Grid>
            <Grid item md={7}>
              <PhoneInput
                inputProps={{
                  name: 'Phone Number',
                  required: true,
                }}
                value={this.state.phoneNumber}
                country={'in'}
                countryCodeEditable={false}
                enableSearch={true}
                containerClass={
                  'MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth'
                }
                onChange={(phone) => this.setState({ phoneNumber: '+' + phone })}
                inputStyle={phone_number_style}
                specialLabel={''}
              />
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {!this.props.fieldsToShow || this.props.fieldsToShow.includes('dob') ? (
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            style={{ marginBottom: 30 }}
          >
            <Grid item md={2}>
              Date of Birth<sup>*</sup>
            </Grid>
            <Grid item md={1}>
              :
            </Grid>
            <Grid item md={7}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  value={this.state.dob}
                  onChange={(value) => {
                    this.setState({ dob: value });
                  }}
                  InputLabelProps={{ shrink: false }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  style={{
                    background: '#F9F9F9',
                  }}
                  InputProps={{
                    style: {
                      fontSize: 13,
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {!this.props.fieldsToShow || this.props.fieldsToShow.includes('gender') ? (
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            style={{ marginBottom: 30 }}
          >
            <Grid item md={2}>
              Gender<sup>*</sup>
            </Grid>
            <Grid item md={1}>
              :
            </Grid>
            <Grid item md={7}>
              <RadioGroup
                row
                aria-label="position"
                name="gender"
                value={this.state.gender}
                onChange={(event) => {
                  this.setState({ gender: event.target.value });
                }}
              >
                <FormControlLabel
                  value="male"
                  control={<Radio color="primary" />}
                  label="Male"
                  classes={{
                    label: classes.font13,
                  }}
                />
                <FormControlLabel
                  value="female"
                  control={<Radio color="primary" />}
                  label="Female"
                  classes={{
                    label: classes.font13,
                  }}
                />
                <FormControlLabel
                  value="unknown"
                  control={<Radio color="primary" />}
                  label="I prefer not to say"
                  classes={{
                    label: classes.font13,
                  }}
                />
              </RadioGroup>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {!this.props.fieldsToShow || this.props.fieldsToShow.includes('addressLine') ? (
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            style={{ marginBottom: 30 }}
          >
            <Grid item md={2}>
              Address Line
            </Grid>
            <Grid item md={1}>
              :
            </Grid>
            <Grid item md={7}>
              <TextField
                id="standard-basic"
                value={this.state.addressLine}
                onChange={(event) => {
                  this.setState({ addressLine: event.target.value });
                }}
                InputLabelProps={{ shrink: false }}
                style={{
                  width: '100%',
                  background: '#F9F9F9',
                }}
                InputProps={{
                  style: {
                    fontSize: 13,
                  },
                }}
              />
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {!this.props.fieldsToShow || this.props.fieldsToShow.includes('state/city') ? (
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            style={{ marginBottom: 30 }}
          >
            <Grid item md={2}>
              State/City
            </Grid>
            <Grid item md={1}>
              :
            </Grid>
            <Grid item md={7}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <Autocomplete
                    options={this.state.stateOptions}
                    getOptionLabel={(option) => option.name}
                    value={this.state.selectedState}
                    InputLabelProps={{ shrink: false }}
                    renderInput={(params) => <TextField {...params} margin="normal" />}
                    onChange={(event, value) => {
                      this.setState({
                        selectedState: value,
                      });
                    }}
                    classes={{
                      option: classes.font13,
                      input: classes.font13,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Autocomplete
                    options={this.state.cityOptions}
                    getOptionLabel={(option) => option.name}
                    value={this.state.selectedCity}
                    InputLabelProps={{ shrink: false }}
                    renderInput={(params) => <TextField {...params} margin="normal" />}
                    onChange={(event, value) => {
                      this.setState({
                        selectedCity: value,
                      });
                    }}
                    classes={{
                      option: classes.font13,
                      input: classes.font13,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {!this.props.fieldsToShow || this.props.fieldsToShow.includes('pincode') ? (
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            style={{ marginBottom: 30 }}
          >
            <Grid item md={2}>
              Pincode
            </Grid>
            <Grid item md={1}>
              :
            </Grid>
            <Grid item md={7}>
              <TextField
                id="standard-basic"
                value={this.state.pincode}
                onChange={(event) => {
                  this.setState({ pincode: event.target.value });
                }}
                InputLabelProps={{ shrink: false }}
                style={{
                  width: '100%',
                  background: '#F9F9F9',
                }}
                InputProps={{
                  style: {
                    fontSize: 13,
                  },
                }}
              />
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {!this.props.fieldsToShow || this.props.fieldsToShow.includes('change_password') ? (
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            style={{ marginBottom: 30 }}
          >
            <Grid item md={2}>
              Password
            </Grid>
            <Grid item md={1}>
              :
            </Grid>
            <Grid item md={7}>
              <Button
                style={{
                  textTransform: 'none',
                  border: '1px solid #1E9CB2',
                  color: '#1E9CB2',
                }}
                onClick={() => {
                  this.setState({ passwordModalOpen: true });
                }}
              >
                Change Password
              </Button>
              <Modal
                open={this.state.passwordModalOpen}
                onClose={() => {
                  this.setState({ passwordModalOpen: false });
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{
                  top: 170,
                }}
              >
                <Card
                  style={{
                    width: 600,
                    margin: 'auto',
                  }}
                >
                  <CardHeader title="Change Password"></CardHeader>
                  <CardContent>
                    <Grid container classes={{ root: classes.font13 }}>
                      Please use a strong password to keep your account safe from hackers
                    </Grid>
                    <ErrorDisplay
                      errors={this.state.changePasswordError}
                      backendKeyMap={this.backendKeyMap}
                    />
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                      style={{
                        marginBottom: 30,
                        marginTop: 30,
                      }}
                    >
                      <Grid item md={4} classes={{ root: classes.font13 }}>
                        Current Password
                      </Grid>
                      <Grid item md={1}>
                        :
                      </Grid>
                      <Grid item md={7}>
                        <TextField
                          id="standard-basic"
                          onChange={(event) => {
                            this.setState({ oldPassword: event.target.value });
                          }}
                          InputLabelProps={{ shrink: false }}
                          style={{
                            width: '100%',
                            background: '#F9F9F9',
                          }}
                          InputProps={{
                            style: {
                              fontSize: 13,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                      style={{ marginBottom: 30 }}
                    >
                      <Grid item md={4} classes={{ root: classes.font13 }}>
                        New Password
                      </Grid>
                      <Grid item md={1}>
                        :
                      </Grid>
                      <Grid item md={7}>
                        <TextField
                          id="standard-basic"
                          onChange={(event) => {
                            this.setState({ newPassword: event.target.value });
                          }}
                          InputLabelProps={{ shrink: false }}
                          style={{
                            width: '100%',
                            background: '#F9F9F9',
                          }}
                          InputProps={{
                            style: {
                              fontSize: 13,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                      style={{ marginBottom: 30 }}
                    >
                      <Grid item md={4} classes={{ root: classes.font13 }}>
                        Retype New Password
                      </Grid>
                      <Grid item md={1}>
                        :
                      </Grid>
                      <Grid item md={7}>
                        <TextField
                          id="standard-basic"
                          onChange={(event) => {
                            this.setState({ confirmNewPassword: event.target.value });
                          }}
                          InputLabelProps={{ shrink: false }}
                          style={{
                            width: '100%',
                            background: '#F9F9F9',
                          }}
                          InputProps={{
                            style: {
                              fontSize: 13,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      alignItems="center"
                      style={{
                        justifyContent: 'center',
                      }}
                    >
                      <Grid item md={2}>
                        <Button
                          style={{
                            textTransform: 'none',
                            border: '1px solid #1E9CB2',
                            color: '#1E9CB2',
                          }}
                          onClick={() => {
                            this.setState({ passwordModalOpen: false });
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item md={2}>
                        <Button
                          onClick={() => {
                            this.changePassword();
                          }}
                          style={{
                            textTransform: 'none',
                            color: 'white',
                            background: '#9DA6AF',
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Modal>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        <Toaster />
      </form>
    );
  }
}

export default withStyles(styles)(PersonalDetails);
