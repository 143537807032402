import React from 'react';
import { withRouter } from 'react-router-dom';
import LoginPage from './LoginPage';
import MobileLoginPage from './MobileLoginPage';

class LoginWrapper extends React.Component {
  render() {
    return (
      <>{window.matchMedia('(min-width: 768px)').matches ? <LoginPage /> : <MobileLoginPage />}</>
    );
  }
}

export default withRouter(LoginWrapper);
