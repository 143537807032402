import { InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

function TextFieldUpdated({ children, className, value = '', ...props }) {
  const classes = useStyles();
  return <InputBase value={value} {...props} className={classNames(className, classes.root)} />;
}

const useStyles = makeStyles(() => ({
  root: {
    background: '#EFF0F9',
    border: '1px solid #FFFFFF',
    boxShadow:
      '-5px 5px 10px rgba(232, 233, 242, 0.2), 5px -5px 10px rgba(232, 233, 242, 0.2), -5px -5px 10px rgba(246, 247, 255, 0.9), 5px 5px 13px rgba(232, 233, 242, 0.9), inset 1px 1px 2px rgba(246, 247, 255, 0.3), inset -1px -1px 2px rgba(232, 233, 242, 0.5)',
    borderRadius: 10,
    paddingLeft: 7,
    fontFamily: "'Nunito Sans', sans-serif",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 25,
    letterSpacing: '0.03e',
    color: '#4A4A4A',
  },
}));

export default TextFieldUpdated;
