import {
  influencerBase,
  InfluencerList,
  influencerBaseV2,
  cityStateDropdown,
  apiServer,
} from './index';

export function getInfluencerProfile() {
  return influencerBase('profile', 'get');
}

export function updateInfluencerProfile(data, file) {
  return influencerBase('profile', 'put', data, file);
}

export function getLanguages(data) {
  return influencerBase('language', 'get', data);
}

export function getFieldOfInterest(data) {
  return influencerBase('field-of-interest', 'get', data);
}

export function updatePassword(data) {
  return influencerBase('change-password', 'put', data);
}

export function getCities() {
  return influencerBase('city', 'get');
}

export function getStates() {
  return influencerBase('state', 'get');
}

export function getInfluencerList(page, formData) {
  return InfluencerList(page, formData);
}

export function influencerInstagramFetchedPosts() {
  return influencerBaseV2('instagram/fetched-posts');
}

export function influencerInstagramFetchNewPosts() {
  return influencerBaseV2('instagram/fetch-new-posts');
}

export function influencerInstagramFetchMorePosts() {
  return influencerBaseV2('instagram/fetch-more-posts');
}

export function influencerInstagramAddPost(data) {
  return apiServer.post('/influencer/instagram/add-post', JSON.stringify(data));
}

export function cityStateList(data) {
  return cityStateDropdown('state_city_dropdown', data);
}
