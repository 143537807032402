import React, {useRef, useEffect, useState} from 'react';

import { withStyles } from '@material-ui/core/styles';

import { getContentList } from 'api_handlers/discovery';
import { Grid, Typography, Avatar } from '@material-ui/core';

let timer;

const ContentPending = ({classes, campaign_id}) => {

    const [ influencersList, setinfluencersList ] = useState([]);

    useEffect(() => {
        getContentData();
    }, [campaign_id]);

    const getContentData = async () => {
        let data = await getContentList(campaign_id, "content_pending");
        setinfluencersList(data);
        if (timer) {
            clearInterval(timer);
        }
        timer = setInterval(() => {
            getContentData();
        }, 5000);
    }

    return <Grid container md={12} justify='flex-start'>
        {
            influencersList.map((data, index)=>{
                return <Grid item style={{ width: 400 }}>
                    <Grid container justify="space-between">
                    <Grid item className={classes.card}>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="space-between">
                        <Grid
                            item
                            md={11}>
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                            {data.influencer_name}
                        </Typography>
                        </Grid>
                        <Grid
                            item
                            md={11}>
                            <Avatar
                                style={{  
                                    height: 100,
                                    width: 100
                                }}
                                alt={data.influencer_name}
                                src={data.avatar} />
                        </Grid>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
            })
        }
    </Grid>

}

const styles = (theme) => ({
    card: {
        background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
        boxShadow:
        '-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
        borderRadius: 20,
        padding: 20,
        maxWidth: 300,
        marginLeft: 20,
        marginBottom: 50,
        height: '100%', width: '100%'
    }
})

export default withStyles(styles)(ContentPending);
