import { combineReducers } from 'redux';
import { campaign } from './campaign';
import { auth } from './auth';
import brand from './brand';
import discovery from './discovery';
import planner from './planner';
import planner2 from './planner2';
import plannerFilters from './planner-filters';

export var rootReducer = combineReducers({
  auth,
  campaign,
  brand,
  discovery,
  planner,
  planner2,
  plannerFilters,
});
