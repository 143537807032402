import { noAuthAPIServer } from './index';

export function fetchYoutubePriceIndex(url, email, category_multiplier) {
  return noAuthAPIServer.get('/extra/price-index/youtube', {
    params: {
      channel_url: url,
      category_multiplier: category_multiplier,
      email: email,
    },
  });
}

export function fetchYoutubePriceIndexDemo(url, email) {
  return noAuthAPIServer.get('/extra/price-index2/youtube', {
    params: {
      channel_url: url,
      email: email,
    },
  });
}

export function saveContactUsDetails(data) {
  return noAuthAPIServer.post('/extra/contact-us', JSON.stringify(data));
}
