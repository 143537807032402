import * as React from 'react';
import { Grid, Typography, Slider, TextField, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CustomSlider from 'components/PlannerTheme/Elements/Slider/Slider';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';

import ObjectiveOption from './ObjectiveOption';
import { fontSize } from '@material-ui/system';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function countertostring(counter, decimal = true) {
  counter = parseInt(counter);
  if (counter >= 1000 && counter < 99999) {
    return (
      parseInt(counter / 1000.0)
        .toFixed(0)
        .toString() + 'K'
    );
  } else if (counter >= 99999 && counter <= 9999999) {
    return (
      parseFloat(counter / 100000.0)
        .toFixed(decimal && 2)
        .toString() + 'L'
    );
  } else if (counter > 9999999) {
    return (
      parseFloat(counter / 10000000.0)
        .toFixed(decimal && 3)
        .toString() + 'Cr'
    );
  } else {
    return counter.toString();
  }
}

function valuetext(value) {
  return `₹${countertostring(value)}`;
}

class FilterSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textValue: this.props.currValue,
      isError: false,
    };
  }

  handleBudgetChange(e) {
    if (Number(e.target.value.replace(/\D/g, '')) > this.props.maxValue) {
      this.setState({
        textValue: this.props.maxValue,
        isError: true,
      });
    } else {
      this.setState({
        textValue: Number(e.target.value.replace(/\D/g, '')),
        isError: false,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currValue !== this.props.currValue) {
      this.setState({ textValue: this.props.currValue });
    }
    if (prevState.textValue !== this.state.textValue) {
      this.props.setFilters(this.props.filter, this.state.textValue);
    }
    if (this.props.filter == 'pricePerCreator' && prevProps.currBudget !== this.props.currBudget) {
      if (this.props.currBudget <= 10000000) {
        this.setState({ textValue: this.props.currBudget * 0.1 });
      } else {
        this.setState({ textValue: this.props.maxValue });
      }
    }
  }
  render() {
    let { classes, selected, setSelectedObjective } = this.props;
    console.log('currValue: ', this.props.currValue);
    // console.log('currBudge)

    return (
      <div
        onClick={() => {
          setSelectedObjective(this.props.objective);
        }}
        style={{ position: 'relative' }}
      >
        <ObjectiveOption {...(selected ? { selected: true } : null)}>
          {this.props.title}
        </ObjectiveOption>
        {selected ? (
          <div className={classes.modal}>
            {this.state.isError ? (
              <span className={classes.error}>
                The value cannot exceed {countertostring(this.props.maxValue, false)}.
              </span>
            ) : (
              ''
            )}
            <div className={classes.box}>
              <TextField
                className={classes.resize}
                value={this.state.textValue.toLocaleString()}
                InputProps={{
                  disableUnderline: true,
                  onChange: (e) => this.handleBudgetChange(e),
                  startAdornment: (
                    <InputAdornment style={{ fontSize: '12px' }} position="start">
                      ₹
                    </InputAdornment>
                  ),
                  classes: {
                    input: classes.resize,
                  },
                }}
              />
            </div>
            <div className={classes.slider}>
              <RemoveIcon
                className={classes.remove}
                onClick={() => {
                  if (this.state.textValue >= this.props.minValue + this.props.step) {
                    this.setState({
                      textValue: this.state.textValue - this.props.step,
                    });
                    this.props.setFilters(this.props.fitler, this.state.textValue);
                  }
                }}
              />
              <CustomSlider
                aria-label="Always visible"
                max={this.props.maxValue}
                min={this.props.minValue}
                valueLabelDisplay="on"
                onChange={(event, newValue) => {
                  this.setState({
                    textValue: newValue,
                    isError: false,
                  });
                  this.props.setFilters(this.props.filter, this.state.textValue);
                }}
                step={this.props.step}
                value={this.state.textValue}
                valueLabelFormat={valuetext}
              />
              <AddIcon
                className={classes.add}
                onClick={() => {
                  this.setState({
                    textValue: this.state.textValue + this.props.step,
                  });
                  this.props.setFilters(this.props.filter, this.state.textValue);
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const styles = {
  modal: {
    background: '#ffffff',
    position: 'absolute',
    width: 300,
    height: 100,
    top: 128,
    padding: 20,
    boxShadow: '5px 4px 20px rgba(104, 104, 104, 0.25)',
    borderRadius: '30px',
    display: 'flex',
    zIndex: 5,
  },
  error: {
    fontSize: '12px',
    color: 'red',
    position: 'absolute',
    left: '15%',
    top: '18%',
  },
  box: {
    boxSizing: 'border-box',

    position: 'absolute',
    left: '65.23%',
    right: '6.58%',
    top: '10.55%',
    bottom: '68.2%',

    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.9) 0%, #FBFCFF 100%)',
    border: '1px solid #C4C4C4',
    borderRadius: '10px',
  },
  slider: {
    top: '65px',
    position: 'absolute',
    width: '250px',
    left: '50px',
  },
  resize: {
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.03em',
  },
  add: {
    position: 'absolute',
    top: '2px',
    left: '254px',
    cursor: 'pointer',
    color: '#757575',
  },
  remove: {
    position: 'absolute',
    right: '264px',
    top: '2px',
    cursor: 'pointer',
    color: '#757575',
  },
};

export default withStyles(styles)(FilterSlider);
