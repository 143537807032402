import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { Grid, Container, Divider } from '@material-ui/core';

const html = `
<html>
    <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
        <title>Influencer- BFSI survey</title>
        <style type="text/css"> html{ margin: 0; height: 100%; overflow: hidden; } iframe { position: absolute; left:0; right:0; bottom:0; top:0; border: 0; } </style>
    </head>
    <body>
        <iframe id="typeform-full" width="100%" height="100%" frameborder="0" allow="camera; microphone; autoplay; encrypted-media;" src="https://form.typeform.com/to/NkbG27WK?typeform-medium=embed-snippet"></iframe> <script type="text/javascript" src="https://embed.typeform.com/embed.js"></script> 
    </body>
</html>
`;

class AboutMe extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  async submitForm() {
    await this.personalDetailForm.submitForm();
  }

  render() {
    return (
      <Container maxWidth={800}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    );
  }
}

export default withRouter(AboutMe);
