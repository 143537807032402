import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Select from 'components/Theme/Elements/Select';
import Slider from 'components/Theme/Elements/Slider';

function DropDownSlider({ options, value, onChange }) {
  const [selectValue, setSelectValue] = useState(value[0]);
  const [sliderValue, setSliderValue] = useState(value[1]);

  function handleSelectChange({ target }) {
    setSelectValue(target.value);
    onChange([target.value, sliderValue]);
  }

  function handleSliderChange(event, value) {
    setSliderValue(value);
    onChange([selectValue, value]);
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item md={5}>
        <Select options={options} value={selectValue} onChange={handleSelectChange} />
      </Grid>
      <Grid item md={5}>
        <Slider
          valueLabelDisplay="auto"
          value={sliderValue}
          onChange={(event, value) => setSliderValue(value)}
          onChangeCommitted={handleSliderChange}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {},
}));

export default DropDownSlider;
