import { Typography, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import CategoryChip from './components/CategoryChip';

function CategoryContainer({ categories }) {
  const classes = useStyles();
  const theme = useTheme();
  const colors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.info.main,
    theme.palette.warning.main,
  ];

  return (
    <Grid container className={classes.root}>
      {categories.slice(0, 2).map((name, i) => (
        <Grid item style={{ marginRight: 35 }} key={name}>
          <CategoryChip label={name} color={colors[i % colors.length]} />
        </Grid>
      ))}
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    // background: 'linear-gradient(135deg, #E3E4ED 0%, #FBFCFF 100%)',
    // boxShadow: '-1px 1px 2px rgba(227, 228, 234, 0.2), 1px -1px 2px rgba(227, 228, 234, 0.2), -1px -1px 2px rgba(251, 252, 255, 0.9), 1px 1px 3px rgba(227, 228, 234, 0.9), inset 1px 1px 2px rgba(251, 252, 255, 0.3), inset -1px -1px 2px rgba(227, 228, 234, 0.5)',
    borderRadius: 5,
    height: 50,
    justifyContent: 'start',
    alignItems: 'center',
  },
}));

export default CategoryContainer;
