import React from 'react';
import { withRouter } from 'react-router-dom';
import PriceIndex from './PriceIndex';
import MobilePriceIndex from './MobilePriceIndex';

class MobilePriceIndexWrapper extends React.Component {
  render() {
    return (
      <>{window.matchMedia('(min-width: 768px)').matches ? <PriceIndex /> : <MobilePriceIndex />}</>
    );
  }
}

export default withRouter(MobilePriceIndexWrapper);
