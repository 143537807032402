import { Component } from 'react';
import {
  Typography,
  Button,
  Grid,
  Modal,
  TextField,
  FormControl,
  NativeSelect,
  InputBase,
  MenuItem,
} from '@material-ui/core';

import moment from 'moment';

import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import DateRangePicker from '../../ux/DateRangePicker';

import Statistics from './cards/youtube/Statistics';
// import Statistics from '../../reporting/cards/youtube/Statistics';
import EngagementStatistic from './cards/youtube/EngagementStatistic';
import PostTable from './cards/youtube/PostTable';
import { withStyles } from '@material-ui/core/styles';
import { colorConstants } from '../../../constants/colors';
import { campaignHandlers } from '../../../api_handlers/campaign';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { downloadFile } from '../../../api_handlers/index';
import { getInfluencerYoutubeStatistic } from '../../../api_handlers/reporting';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FFF',
    border: '0.5px solid #C8C8C8',
    borderRadius: '5px',
    fontSize: 11,
    paddingLeft: '15px',
    height: '20px',
    width: '100px',
    margin: '5px',
  },
}))(InputBase);

class YoutubeFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      info_modalOpen: false,
      user_type: JSON.parse(localStorage.getItem('user')).user_type,
      stats: null,
      message: null,
      startDate: null,
      endDate: null,
      prev_startDate: null,
      prev_endDate: null,
    };
  }
  toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen });
  info_toggleModal = () => this.setState({ info_modalOpen: !this.state.info_modalOpen });

  downloadReport() {
    downloadFile(`/campaign/all/report/influencer/youtube/feed/download`, {
      influencer_id: this.props.match.params.influencerId,
      start_date: this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : null,
      end_date: this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : null,
    });
  }

  async addPostLink(url) {
    const post_data = {
      action_type: 'youtube',
      campaign_id: this.props.match.params.campaignId,
      influencer_id: this.props.match.params.influencerId,
      url,
    };
    await campaignHandlers.addPostLink(post_data).then((r) => {
      this.setState({
        info_modalOpen: true,
        message: r.message,
      });
    });
    if (this.state.message == 'Updated') {
      window.location.reload();
    }
  }
  async getData() {
    const campaign_id = this.props.match.params.campaignId;
    const influencer_id = this.props.match.params.influencerId;
    const startDate = this.state.startDate
      ? moment(this.state.startDate).format('YYYY-MM-DD')
      : null;
    const endDate = this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : null;
    const resp = await getInfluencerYoutubeStatistic(
      campaign_id,
      influencer_id,
      startDate,
      endDate,
    );
    this.setState({ stats: resp.data });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  render() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} xl={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12}>
              <Grid container spacing={1}>
                <Grid item md={4}>
                  <EngagementStatistic
                    campaign_id={this.props.match.params.campaignId}
                    influencer_id={this.props.match.params.influencerId}
                    stats={this.state.stats}
                  />
                </Grid>
                <Grid item md={8}>
                  <Statistics
                    extraMargin={20}
                    campaign_id={this.props.match.params.campaignId}
                    influencer_id={this.props.match.params.influencerId}
                    stats={this.state.stats}
                  />
                </Grid>
                <Grid
                  container
                  justify="flex-end"
                  spacing={1}
                  style={{ marginTop: '0px' }}
                  alignItems="center"
                >
                  <Grid item md={7} style={{ paddingLeft: 0 }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={1}
                      style={{
                        align: 'center',
                        paddingRight: 20,
                      }}
                    >
                      <Grid
                        item
                        md={1}
                        style={{
                          fontSize: 11,
                          paddingRight: 0,
                        }}
                      >
                        <span>Filter by:</span>
                      </Grid>
                      <Grid item md={4}>
                        <DateRangePicker
                          id="date-picker"
                          placement="right-end"
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onSelect={async (result) => {
                            await this.setState({
                              startDate: result[0].startDate,
                              endDate: result[0].endDate,
                            });
                            this.postTable.reloadData();
                            this.getData();
                          }}
                        ></DateRangePicker>
                      </Grid>

                      <Grid item md={4}>
                        <Button
                          variant="contained"
                          endIcon={<CloudDownloadIcon />}
                          style={{
                            fontSize: 12,
                            textTransform: 'none',
                            backgroundColor: '#444',
                            color: 'white',
                          }}
                          onClick={() => {
                            this.downloadReport();
                          }}
                        >
                          Report
                        </Button>
                      </Grid>
                      {this.state.user_type != 'brand' ? (
                        <Grid item md={3} style={{ paddingLeft: 0, paddingRight: '10px' }}>
                          <Button
                            variant="contained"
                            style={{
                              color: 'white',
                              backgroundColor: '#1E9CB2',
                              marginRight: '10px',
                            }}
                            onClick={this.toggleModal}
                          >
                            Add Post Link
                          </Button>
                          <div>
                            <Modal
                              open={this.state.modalOpen}
                              onClose={this.toggleModal}
                              aria-labelledby="simple-modal-title"
                              aria-describedby="simple-modal-description"
                            >
                              <div
                                style={{
                                  backgroundColor: 'white',
                                  width: '50vw',
                                  transform: 'translate(50%, 40vh)',
                                  paddingLeft: '10px',
                                  borderRadius: '15px',
                                  boxShadow:
                                    '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                                }}
                                align={'left'}
                              >
                                <h3 style={{ paddingTop: '10px' }}>Add Post</h3>
                                <hr style={{ width: '99%' }} />
                                <Grid container spacing={2}>
                                  <Grid item md={12}>
                                    Paste your Youtube video URL to add it to the Campaign
                                  </Grid>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    style={{ padding: '10px' }}
                                  >
                                    <Grid item md={2} alignItems="flex-end">
                                      <p>URL:</p>
                                    </Grid>
                                    <Grid item md={9}>
                                      <TextField
                                        id="post_url"
                                        style={{ margin: 8 }}
                                        placeholder="Paste URL"
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        variant="outlined"
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <br />
                                <div
                                  style={{ paddingBottom: '18px', paddingTop: '20px' }}
                                  align={'center'}
                                >
                                  <Button
                                    variant="outlined"
                                    style={{
                                      color: colorConstants.TEXT_BLUE,
                                      borderColor: colorConstants.TEXT_BLUE,
                                      marginRight: '10px',
                                    }}
                                    onClick={this.toggleModal}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: colorConstants.TEXT_BLUE,
                                      color: 'white',
                                    }}
                                    onClick={() =>
                                      this.addPostLink(document.getElementById('post_url').value)
                                    }
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </div>
                            </Modal>
                          </div>
                          <div>
                            <Snackbar
                              open={this.state.info_modalOpen}
                              autoHideDuration={5000}
                              onClose={this.info_toggleModal}
                            >
                              <Alert onClose={this.info_toggleModal} severity="info">
                                {this.state.message}
                              </Alert>
                            </Snackbar>
                          </div>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Grid container spacing={1}>
                    <Grid item md={12}>
                      <PostTable
                        {...this.props}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onRef={(ref) => (this.postTable = ref)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default YoutubeFeed;
