import React, { useState } from 'react';
import { Toolbar, Grid, Button, Popper } from '@material-ui/core';

import { Link, withRouter } from 'react-router-dom';

import StyledNavLink from '../ux/StyledNavLink';
import ButtonStyledLink from '../ux/ButtonStyledLink';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import NotificationsIcon from '@material-ui/icons/Notifications';
import InfluencerLogo from '../../assets/influencer-logo.png';
import homeLogo from '../../assets/home.svg';
import targetLogo from '../../assets/target.svg';
import userLogo from '../../assets/users_1.svg';
import usersLogo from '../../assets/users.svg';
import { campaignActions } from '../../actions/campaign';
import { connect } from 'react-redux';

import { colorConstants } from '../../constants/colors';

const footerStyle = {
  backgroundColor: 'white',
  fontSize: '20px',
  color: '#1E9CB2',
  // borderTop: "1px solid #E7E7E7",
  textAlign: 'center',
  position: 'fixed',
  left: '0',
  bottom: '0',
  height: '55px',
  width: '100%',
  paddingLeft: '0px',
  paddingRight: '0px',
};

function BottomHeader(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const logOut = () => {
    for (const key of ['access', 'refresh', 'user']) localStorage.removeItem(key);
    window.location = '/login';
  };
  const active_style = {
    width: '25vw',
    'border-top': '2px solid',
    'border-color': '#1E9CB2',
  };
  const non_active_style = { width: '25vw' };

  const user = JSON.parse(localStorage.getItem('user'));
  const name = user.user_type === 'brand' ? user.brand.name : user.influencer.name;
  return (
    <Toolbar style={footerStyle}>
      <Grid container justify="space-between" alignItems="center">
        <Grid
          container
          justifyContent="stretch"
          justify="space-evenly"
          style={{
            fontSize: 12,
          }}
        >
          <Grid
            item
            md={3}
            style={
              props.location.pathname == '/influencer/campaigns' ? active_style : non_active_style
            }
          >
            <ButtonStyledLink
              to={'/influencer/campaigns'}
              label={'My Campaigns'}
              icon={targetLogo}
            />
          </Grid>
          <Grid
            item
            md={3}
            style={props.location.pathname == '/influencer/past' ? active_style : non_active_style}
          >
            <ButtonStyledLink to={'/influencer/past'} label={'Past Campaigns'} icon={targetLogo} />
          </Grid>
          <Grid
            item
            md={3}
            style={
              props.location.pathname == '/influencer/settings/personal-settings'
                ? active_style
                : non_active_style
            }
          >
            <ButtonStyledLink
              to={'/influencer/settings/personal-settings/'}
              label={'My Account'}
              icon={userLogo}
            />
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

const mapStateToProps = (state) => ({
  loading: state.campaign.loading,
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(withRouter(BottomHeader));
