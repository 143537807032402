import { Grid, Typography, withStyles } from '@material-ui/core';
import MultiSelect from 'components/PlannerTheme/Elements/MultiSelect';
import LocationIcon from 'assets/discovery/Location-Icon.png';
import { useState, useEffect } from 'react';
import { getCityStateList } from 'api_handlers/discovery';

function InfluencerCity({ influencerCity, hideAdvanceFilters, influencerState, classes }) {
  const [city, setCity] = useState([]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        // var occurrences = [];
        // for (var i = 0; i < influencerState.length; i++) {
        //   occurrences.push(influencerState[i]['value']);
        // }
        // const data = { state: occurrences };
        const resp = await getCityStateList(true);
        setCity(resp.data);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };
    fetchCities();
  }, []);

  return (
    <Grid container direction="column">
      <Grid
        item
        style={{
          display: 'flex',
          marginBottom: 10,
          justifyContent: 'center',
        }}
      >
        <img src={LocationIcon} width={22} style={{ marginRight: 7 }} />
        <Typography className={classes.advanceHeading}>City</Typography>
      </Grid>
      <Grid item>
        <MultiSelect
          label={'Select City'}
          options={city.map((location) => ({label: location, value: location}))}
          value={influencerCity || []}
          onChange={(event, selectedValues) => {
            hideAdvanceFilters('influencerCity', selectedValues);
          }}
        />
      </Grid>
    </Grid>
  );
}

const styles = {
  advanceHeading: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 14,
  },
};

export default withStyles(styles)(InfluencerCity);
