

const circleSx = {
  // width: '100px',
  // height: '100px',
  // backgroundColor: '#4CAF50',
  color: 'black',
  fontSize: '14px',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  textAlign: 'center',
  position: 'absolute',
  lineHeight: 1.2 // No quotes needed for numeric values
};


const CreatorTypeCircle = ({color, size, text1, text2, top, left}) => {
  return (
    <div style={{...circleSx, backgroundColor: color, height: size, width: size, top: top || 0, left: left || 0}}>
        {text1}<br />{text2}
    </div>
  )
}

export default CreatorTypeCircle;