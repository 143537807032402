import React from 'react';
import { withRouter, Route, Switch, NavLink } from 'react-router-dom';
import { Grid, Container, Divider } from '@material-ui/core';

import MobilePersonalDetailForm from '../forms/MobilePersonalDetails';
import BackArrowLogo from '../../../assets/back_arrow.png';

class Security extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  async submitForm() {
    await this.personalDetailForm.submitForm();
  }

  render() {
    return (
      <Grid container style={{ backgroundColor: 'white', fontSize: '12px' }}>
        <Grid
          container
          style={{
            border: '1px solid #DCDCDC',
            padding: '15px',
            width: '100%',
          }}
        >
          <Grid item md={12}>
            <Grid
              container
              // spacing={12}
              direction="row"
              alignItems="center"
              style={{
                justifyContent: 'space-between',
              }}
            >
              <Grid item md={12}>
                <NavLink
                  to="/influencer/settings"
                  style={{
                    textDecoration: 'none',
                    lineHeight: '17px',
                    margin: '5px',
                    color: 'black',
                  }}
                >
                  <span>
                    <img src={BackArrowLogo} style={{ width: 20 }} />
                  </span>
                  &nbsp;&nbsp;
                  <span
                    style={{
                      fontSize: 15,
                    }}
                  >
                    Security Settings
                  </span>
                </NavLink>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={5}>
                <Divider
                  style={{
                    background:
                      'linear-gradient(90deg, rgba(171,171,171,1) 0%, rgba(176,176,176,0) 100%)',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            marginBottom: 30,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <Grid item md={5}>
            <Divider
              style={{
                background:
                  'linear-gradient(90deg, rgba(171,171,171,1) 0%, rgba(176,176,176,0) 100%)',
              }}
            />
          </Grid>
        </Grid>
        <Grid container style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Grid item md={12} style={{ width: '100%' }}>
            <MobilePersonalDetailForm
              onRef={(ref) => (this.personalDetailForm = ref)}
              fieldsToShow={['email', 'phone_number', 'change_password']}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Security);
