import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';

// material-ui
import { yellow, grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

// project imports
import Avatar from 'ui-component/extended/Avatar';
import ExtendedChip from 'ui-component/extended/Chip';

// assets
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SellIcon from '@mui/icons-material/Sell';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import HttpsIcon from '@mui/icons-material/Https';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';

import SkeletonTableRow from './SkeletonTableRow';
import SocialMediaIcon from '../Extra/SocialMediaIcon';

import { updateSelectedInfluencers, showPremiumFeatureDialog, updateUser } from 'actions/planner2';
import { checkProfilePermission, unlockProfile } from 'api_handlers/planner2';
import { fetchNewInfluencer } from 'api_handlers/planner2';
import { getDiscoveryInfluencersDetails } from 'api_handlers/planner2';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { DialogActions } from '@mui/material';

import mixpanel from 'mixpanel-browser';

import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import ListIcon from '../../../../assets/discovery/list-icon.svg';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const getCategoryChips = (row) => {
  const categories = [];

  if (row.primary_category && row.primary_category.name && row.primary_category.name.length > 0) {
    categories.push(row.primary_category.name);
  }

  const categoryChips = categories.slice(0, 3).map((category, index) => (
    <Grid item key={index}>
      <ExtendedChip label={category} sx={{ color: 'secondary.main' }} />
    </Grid>
  ));

  if (!row.interest_categories) return categoryChips;

  row.interest_categories.forEach((category) => {
    categories.push(category);
  });

  if (categories.length <= 3) return categoryChips;

  return (
    <>
      {categoryChips}
      {
        <Grid item>
          <ExtendedChip label={`+${categories.length - 3}`} sx={{ color: 'secondary.main' }} />
        </Grid>
      }
    </>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    '& .action-container': {
      visibility: 'visible',
      opacity: 1,
    },
  },
}));

// const ActionButton = styled(Button)(({ theme, vibrate }) => ({
//   visibility: 'hidden',
//   opacity: 0,
//   position: 'absolute',
//   right: theme.spacing(2),
//   bottom: '-20px',
//   // transform: 'translateY(-50%)',
//   transition: 'visibility 0s, opacity 0.5s linear',
//   fontSize: '10px',
//   animation: vibrate ? 'vibrate 0.2s ease 5' : 'none', // Vibrate 5 times for 1 second total
//   '@keyframes vibrate': {
//     '0%': { transform: 'translateX(0)' },
//     '25%': { transform: 'translateX(-2px)' },
//     '50%': { transform: 'translateX(2px)' },
//     '75%': { transform: 'translateX(-2px)' },
//     '100%': { transform: 'translateX(0)' },
//   },
// }));

const StyledHeaderColumn = ({ name, Icon }) => {
  return (
    <TableCell sx={{ border: 1, borderColor: grey[200], borderTop: 'none' }}>
      <Grid
        container
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        spacing={0}
      >
        <Grid item>
          <Icon sx={{ fontSize: 20, color: 'secondary.main' }} />
        </Grid>
        <Grid item sx={{ mb: 1 }}>
          <Typography fontWeight={'bold'} sx={{ color: 'secondary.main' }} fontSize={'0.8rem'}>
            {name}
          </Typography>
        </Grid>
      </Grid>
    </TableCell>
  );
};

const StyledTableCell = ({ children }) => {
  return (
    <TableCell align="center" sx={{ border: 1, borderColor: grey[200] }} fontSize="0.8rem">
      <Typography fontWeight={'bold'} fontSize={'0.8rem'} sx={{ textTransform: 'capitalize' }}>
        {children}
      </Typography>
    </TableCell>
  );
};

const UserList = ({ influencers, loading, handleAddToCampaign }) => {
  const user = useSelector((state) => state.planner2.user);
  const selected = useSelector((state) => state.planner2.selectedInfluencers);
  const [updateStatus, setUpdateStatus] = React.useState('');

  let history = useHistory();
  const dispatch = useDispatch();

  const [profilePermissionMap, setProfilePermissionMap] = React.useState({});
  const [unlockedProfilePermissionMap, setUnlockedProfilePermissionMap] = React.useState({});
  const [loaderMap, setLoaderMap] = React.useState({});

  const [showUnlockedProfileCreditOver, setShowUnlockedProfileCreditOver] = React.useState(false);
  const [showPremiumProfileCreditOver, setShowPremiumProfileCreditOver] = React.useState(false);
  const [showIQProfileNotExists, setShowIQProfileNotExists] = React.useState(false);

  const [vibrate, setVibrate] = useState(false);

  const skeletonCount = Array.from({ length: 20 });

  const setSelected = (newSelected) => {
    dispatch(updateSelectedInfluencers(newSelected));
  };

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = influencers.map((n) => n.id);
      setSelected(new Set(newSelecteds));
      if (process.env.NODE_ENV === 'production') {
        mixpanel.track("discover_select_all_list")
      }
      return;
    }
    setSelected(new Set());
  };

  const handleClick = (event, id) => {
    if (selected.has(id)) {
      const newSelected = new Set(selected);
      newSelected.delete(id);
      setSelected(newSelected);
    } else {
      setSelected(new Set(selected).add(id));
    }
  };

  const fetchDetails = async (getId) => {
    if (getId) {
      setLoaderMap((prevLoaderMap) => {
        console.log('Previous loaderMap:', prevLoaderMap);
        const newLoaderMap = { ...prevLoaderMap, [getId]: !vibrate ? false : true };
        console.log('Updated loaderMap:', newLoaderMap);
        return newLoaderMap;
      });
      console.log('fetch details', loaderMap);

      try {
        const response = await getDiscoveryInfluencersDetails(getId);

        // if (response?.update_status === 'updating') {
        //     setUpdateStatus('updating');
        // }
        // if (response?.update_status === 'updated') {
        //     setUpdateStatus('updated');
        // }
        return response;
      } catch (error) {
        console.error('Error fetching details:', error);
        setLoaderMap((prevLoaderMap) => {
          console.log('Previous loaderMap:', prevLoaderMap);
          const newLoaderMap = { ...prevLoaderMap, [getId]: false };
          console.log('Updated loaderMap:', newLoaderMap);
          return newLoaderMap;
        });
      } finally {
        setLoaderMap((prevLoaderMap) => {
          console.log('Previous loaderMap:', prevLoaderMap);
          const newLoaderMap = { ...prevLoaderMap, [getId]: false };
          console.log('Updated loaderMap:', newLoaderMap);
          return newLoaderMap;
        });
      }
    }
  };

  const handleShowDetails = async (row, open) => {
    setLoaderMap((prevLoaderMap) => {
      console.log('Previous loaderMap:', prevLoaderMap);
      const newLoaderMap = { ...prevLoaderMap, [row.id]: true };
      console.log('Updated loaderMap:', newLoaderMap);
      return newLoaderMap;
    });
    let response = await fetchDetails(row.id);
    if (row.url && row.url.indexOf('https://www.instagram.com/None') > -1) {
      row.url = response.url;
    }
    console.log('2 loader mapp', loaderMap[row.id]);
    const isLoading = loaderMap[row.id];
    if (!isLoading) {
      if (profilePermissionMap[row.id]) {
        if (!unlockedProfilePermissionMap[row.id]) {
          try {
            const resp = await unlockProfile(row.id, false);
            if (resp?.detail === 'Success') {
              openProfileHandler(row, false);
            } else {
              setLoaderMap((prevLoaderMap) => {
                console.log('Previous loaderMap:', prevLoaderMap);
                const newLoaderMap = { ...prevLoaderMap, [row.id]: false };
                console.log('Updated loaderMap:', newLoaderMap);
                return newLoaderMap;
              });
            }
          } catch (error) {
            setLoaderMap((prevLoaderMap) => {
              console.log('Previous loaderMap:', prevLoaderMap);
              const newLoaderMap = { ...prevLoaderMap, [row.id]: false };
              console.log('Updated loaderMap:', newLoaderMap);
              return newLoaderMap;
            });
            if (error && error.response && error.response.status === 403) {
              showUnlockedProfileCreditOver(true);
            }
          }
        } else {
          openProfileHandler(row, false);
        }
      } else {
        if (open === true) {
          setVibrate((vibrate) => !vibrate);
          setTimeout(() => setVibrate(false), 1000);
        } else {
          unlockProfileHandler(row);
        }
      }
    } else {
      setLoaderMap((prevLoaderMap) => {
        console.log('Previous loaderMap:', prevLoaderMap);
        const newLoaderMap = { ...prevLoaderMap, [row.id]: false };
        console.log('Updated loaderMap:', newLoaderMap);
        return newLoaderMap;
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const resp = await checkProfilePermission({
      payload: {
        profile_ids: influencers.map((inf) => inf.id),
      },
    });
    setProfilePermissionMap(resp['profile_id_access_map']['standard-profiles'] || {});
    setUnlockedProfilePermissionMap(resp['profile_id_access_map']['unlocked-profiles'] || {});
  }, [influencers]);

  const openProfileHandler = (row, is_premium) => {
    dispatch(updateUser());
    setProfilePermissionMap((prevData) => ({
      ...prevData,
      [row.id]: true,
    }));
    setUnlockedProfilePermissionMap((prevData) => ({
      ...prevData,
      [row.id]: true,
    }));
    setLoaderMap((prevLoaderMap) => {
      console.log('Previous loaderMap:', prevLoaderMap);
      const newLoaderMap = { ...prevLoaderMap, [row.id]: false };
      console.log('Updated loaderMap:', newLoaderMap);
      return newLoaderMap;
    });
    if (process.env.NODE_ENV === 'production') {
      mixpanel.track("Insights viewed", {
        "url": `/planner/profile-details/${row.platform}/${
            row.platform === 'instagram'
            ? `${row.url.split('/')[3]}`
            : `${row.url.split('/')[4]}`
        }`,
        "type": (is_premium ? 'Premium' : 'standard')
      });
    }
    window.open(
      `/planner/profile-details/${row.platform}/${
        row.platform === 'instagram' ? `${row.url.split('/')[3]}` : `${row.url.split('/')[4]}`
      }`,
      '_blank',
    );
  };

  const unlockProfileHandler = async (row) => {
    setLoaderMap((prevLoaderMap) => {
      console.log('Previous loaderMap:', prevLoaderMap);
      const newLoaderMap = { ...prevLoaderMap, [row.id]: true };
      console.log('Updated loaderMap:', newLoaderMap);
      return newLoaderMap;
    });
    try {
      if (!row.audience_data_exist) {
        const { data } = await fetchNewInfluencer({
          payload: {
            link: row.url,
            'with-audience-data': true,
          },
        });
        if (data.data && data.data.dump && Object.keys(data.data.dump).length === 0) {
          setShowIQProfileNotExists(true);
          return;
        }
      }

      const resp = await unlockProfile(row.id, true);
      if (resp?.detail === 'Success') {
        openProfileHandler(row, true);
      } else {
        setLoaderMap((prevLoaderMap) => {
          console.log('Previous loaderMap:', prevLoaderMap);
          const newLoaderMap = { ...prevLoaderMap, [row.id]: false };
          console.log('Updated loaderMap:', newLoaderMap);
          return newLoaderMap;
        });
      }
    } catch (error) {
      setLoaderMap((prevLoaderMap) => {
        console.log('Previous loaderMap:', prevLoaderMap);
        const newLoaderMap = { ...prevLoaderMap, [row.id]: false };
        console.log('Updated loaderMap:', newLoaderMap);
        return newLoaderMap;
      });
      if (error && error.response && error.response.status === 403) {
        setShowPremiumProfileCreditOver(true);
      }
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ border: 1, borderColor: grey[200], borderTop: 'none' }}>
              <Checkbox
                color="primary"
                indeterminate={selected.size > 0 && selected.size < influencers.length}
                checked={influencers.length > 0 && selected.size === influencers.length}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            </TableCell>
            <StyledHeaderColumn name="Profile" Icon={AccountCircleIcon} />
            {/* <StyledHeaderColumn name="Score" Icon={CheckCircleIcon} /> */}
            <StyledHeaderColumn name="Followers" Icon={GroupIcon} />
            <StyledHeaderColumn name="ER" Icon={GroupAddIcon} />
            <StyledHeaderColumn name="Avg Views" Icon={RemoveRedEyeIcon} />
            <StyledHeaderColumn name="Avg Engagement" Icon={GroupAddIcon} />
            <StyledHeaderColumn name="Location" Icon={LocationOnIcon} />
            <TableCell>
              <Grid
                container
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                spacing={0}
              >
                <Grid item>
                  <SellIcon sx={{ fontSize: 20, color: 'secondary.main' }} />
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    fontWeight={'bold'}
                    sx={{ color: 'secondary.main' }}
                    fontSize={'0.8rem'}
                  >
                    Categories
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell sx={{ maxWidth: 0 }} align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? skeletonCount.map((_, index) => <SkeletonTableRow key={index} />) : null}

          {influencers && influencers.length > 0
            ? influencers.map((row, index) => {
                const isItemSelected = selected.has(row.id);
                return (
                  <StyledTableRow hover key={row.id} style={{ position: 'relative' }}>
                    <TableCell
                      sx={{ pl: 2, border: 1, borderColor: grey[200] }}
                      padding="checkbox"
                      onClick={(event) => handleClick(event, row.id)}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={
                          {
                            // 'aria-labelledby': labelId
                          }
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ border: 1, borderColor: grey[200] }}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar
                          alt="User 1"
                          src={row.picture}
                          style={{ cursor: 'pointer' }}
                          onClick={async () => {
                            await handleShowDetails(row, true);
                          }}
                        />
                        <Stack>
                          <Stack
                            direction={'row'}
                            alignItems="center"
                            justifyContent={'center'}
                            spacing={0.1}
                          >
                            <Typography
                              variant="subtitle1"
                              fontWeight={'bold'}
                              fontSize={'0.8rem'}
                              style={{ cursor: 'pointer' }}
                              onClick={async () => {
                                await handleShowDetails(row, true);
                              }}
                            >
                              {row.name}
                            </Typography>
                            <Stack>
                              <SocialMediaIcon platform={row.platform} url={row.url} />
                            </Stack>
                          </Stack>
                          <Typography
                            variant="subtitle2"
                            noWrap
                            fontWeight={'bold'}
                            fontSize={'0.8rem'}
                          >
                            {row.language}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                    {/* <TableCell sx={{ border: 1, borderColor: grey[200] }}>
                                    <ExtendedChip
                                        avatar={<CheckCircleIcon sx={{ height: 18 }} />}
                                        label={`SPEED: ${row.speed_score}`}
                                        chipcolor="success"
                                        sx={{
                                            '& .MuiChip-label': {
                                                fontWeight: 800,
                                                fontSize: '0.7rem',
                                                paddingLeft: '6px',
                                                paddingRight: '6px'
                                            },
                                            '& .MuiChip-avatar': {height: 15, marginLeft: 0},
                                            height: 25
                                        }}
                                    />
                                </TableCell> */}
                    <StyledTableCell>{row.followers}</StyledTableCell>
                    <StyledTableCell>{row.engagement_rate} </StyledTableCell>
                    <StyledTableCell>{row.avg_views} </StyledTableCell>
                    <StyledTableCell>{row.prediction?.enagaged_users_display} </StyledTableCell>
                    <StyledTableCell>{row.city} </StyledTableCell>
                    <TableCell sx={{ maxWidth: 200 }}>
                      <Grid container spacing={1}>
                        {getCategoryChips(row)}
                      </Grid>
                    </TableCell>
                    <TableCell sx={{  maxHeight: '100%' }} align="right">
                      <Grid
                        container
                        spacing={1}
                        width={'50%'}
                        sx={{
                          visibility: 'hidden',
                          opacity: 0,
                          position: 'absolute',
                          right: '1%',
                          top: '-0.1px',
                          marginTop: '1px',
                        }}
                        className="action-container"
                        height={'85%'}
                        justifyContent={'flex-end'}
                        alignItems={'center'}
                      >
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(event) => {
                              if (process.env.NODE_ENV === 'production') {
                                mixpanel.track("discover_add_to_list", {
                                  "type": "collab_list"
                                })
                              }
                              // if (user.premium_user) 
                              handleAddToCampaign([row.id], 'Planner');
                              // else dispatch(showPremiumFeatureDialog(true));
                            }}
                            startIcon={
                                <FormatListBulletedRoundedIcon style={{ width: '10px', height: '15px' }} />
                              }
                            sx={{
                              fontSize: 10,
                              width: '130px',
                            }}
                            
                          >
                            Add to List
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(event) => {
                              if (user.premium_user) handleAddToCampaign([row.id], 'AddToCampaign');
                              else dispatch(showPremiumFeatureDialog(true));
                            }}
                            sx={{
                              fontSize: 10,
                              width: '130px',
                            }}
                            startIcon={
                              <WorkspacePremiumIcon style={{ width: '10px', height: '15px' }} />
                            }
                          >
                            Add to Campaign
                          </Button>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={
                              !profilePermissionMap[row.id]
                                ? 'Unlock this profile for 1 premium credit.'
                                : !user?.premium_user &&
                                  profilePermissionMap[row.id] &&
                                  !unlockedProfilePermissionMap[row.id]
                                ? 'Unlock this profile  for 1 standard credit.'
                                : ''
                            }
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              vibrate={vibrate}
                              onClick={() => {
                                if (user?.credit_balance === 0 && !profilePermissionMap[row.id]) {
                                  setShowPremiumProfileCreditOver(true);
                                } else if (
                                  !user?.premium_user &&
                                  user?.fetched_credit_balance === 0 &&
                                  profilePermissionMap[row.id] &&
                                  !unlockedProfilePermissionMap[row.id]
                                ) {
                                  setShowUnlockedProfileCreditOver(true);
                                } else {
                                  handleShowDetails(row, false);
                                }
                              }}
                              style={{
                                color: loaderMap[row.id] ? 'black' : 'black',
                                fontSize: 10,
                                width: '130px',
                              }}
                              sx={{
                                animation: vibrate ? 'vibrate 0.2s ease 5' : 'none',
                                '@keyframes vibrate': {
                                  '0%': { transform: 'translateX(0)' },
                                  '25%': { transform: 'translateX(-2px)' },
                                  '50%': { transform: 'translateX(2px)' },
                                  '75%': { transform: 'translateX(-2px)' },
                                  '100%': { transform: 'translateX(0)' },
                                },
                              }}
                              startIcon={
                                loaderMap[row.id] ? (
                                  <CircularProgress size={14} sx={{ color: 'black' }} />
                                ) : profilePermissionMap[row.id] ? <RemoveRedEyeIcon style={{ width: '10px', height: '15px' }}/> : (
                                  <HttpsIcon style={{ width: '10px', height: '15px' }} />
                                )
                              }
                              disabled={loaderMap[row.id]}
                            >
                              {loaderMap[row.id] ? 'Loading' : 'Show Details'}
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </StyledTableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
      {showUnlockedProfileCreditOver ? (
        <React.Fragment>
          <BootstrapDialog
            onClose={() => {
              setShowUnlockedProfileCreditOver(false);
            }}
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title"></DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => {
                setShowUnlockedProfileCreditOver(false);
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers style={{ textAlign: 'center' }}>
              You’ve reached the limit of unlocking standard profiles. Use your remaining credits to
              unlock premium profiles or upgrade for unlimited access.
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowUnlockedProfileCreditOver(false);
                  dispatch(showPremiumFeatureDialog(true));
                }}
              >
                Upgrade
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </React.Fragment>
      ) : null}
      {showPremiumProfileCreditOver ? (
        <React.Fragment>
          <BootstrapDialog
            onClose={() => {
              setShowPremiumProfileCreditOver(false);
            }}
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title"></DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => {
                setShowPremiumProfileCreditOver(false);
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers style={{ textAlign: 'center' }}>
              You’ve used all your premium credits. Upgrade to unlock more premium profiles!
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowPremiumProfileCreditOver(false);
                  dispatch(showPremiumFeatureDialog(true));
                }}
              >
                Upgrade
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </React.Fragment>
      ) : null}
      {showIQProfileNotExists ? (
        <React.Fragment>
          <BootstrapDialog
            onClose={() => {
              setShowIQProfileNotExists(false);
            }}
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth
          >
            <DialogTitle
              sx={{ m: 0, p: 2, textAlign: 'center', fontWeight: 'bold' }}
              id="customized-dialog-title"
            >
              We are fetching the data for this profile.
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => {
                setShowIQProfileNotExists(false);
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers style={{ textAlign: 'center' }}>
              <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={30} sx={{ marginRight: '10px' }} />
                Please wait while we are fetching the latest data for this profile, you can come
                back and check later.
              </div>
            </DialogContent>
          </BootstrapDialog>
        </React.Fragment>
      ) : null}
    </TableContainer>
  );
};

export default UserList;
