import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import StyledNavLink from '../ux/StyledNavLink';

class SidePanel extends React.Component {
  render() {
    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        spacing={4}
        style={{
          background: 'white',
          fontWeight: '400',
          fontSize: 13,
          paddingLeft: 20,
          height: 700,
        }}
      >
        {this.props.allowedSocailMediaLinks &&
        this.props.allowedSocailMediaLinks.includes('youtube') ? (
          <Grid item>
            <StyledNavLink to={`${this.props.match.url}/youtube`} label={'YouTube Feeds'} />
          </Grid>
        ) : (
          ''
        )}
        {this.props.allowedSocailMediaLinks &&
        this.props.allowedSocailMediaLinks.includes('instagram') ? (
          <Grid item>
            <StyledNavLink to={`${this.props.match.url}/instagram`} label={'Instagram Feeds'} />
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    );
  }
}

// export default withRouter(SidePanel);

const mapStateToProps = (state) => {
  return {
    allowedSocailMediaLinks: state.brand.displayPanels?.social_media.link_display,
  };
};

export default connect(mapStateToProps)(withRouter(SidePanel));
