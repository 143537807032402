import { createTheme } from '@material-ui/core';

const planner_theme = createTheme({
  palette: {
    primary: {
      light: '#AC88FF',
      main: '#702DDD',
      dark: '#4A3A99',
    },
    secondary: {
      main: '#EF1A74',
    },
    info: {
      main: '#0095FF',
    },
    warning: {
      main: '#FEBD1C',
    },
    background: {
      default: '#EBEBEB',
    },
  },
  typography: {
    fontFamily: "'Nunito Sans', sans-serif",
    h3: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
      fontSize: 18,
      letterSpacing: '0.03em',
      color: '#000000',
    },
    h4: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: '0.03em',
      color: '#757575',
    },
    h5: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 600,
      fontSize: 20,
      letterSpacing: '0.03em',
      color: '#4A4A4A',
      textAlign: 'left',
    },
    subtitle1: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
      fontSize: 18,
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
      color: '#4A4A4A',
    },
    subtitle2: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 700,
      fontSize: 14,
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
      color: '#4A4A4A',
    },
    subtitle3: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
      letterSpacing: '0.0075em',
      color: '#4A4A4A',
    },
    body1: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
      color: '#4A4A4A',
    },
    body2: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 500,
      fontSize: 11,
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
      color: '#4A4A4A',
    },
  },
});

export default planner_theme;
