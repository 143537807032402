import * as React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import CustomSlider from 'components/PlannerTheme/Elements/Slider/Slider';

import ObjectiveOption from './ObjectiveOption';

function countertostring(counter) {
  counter = parseInt(counter);
  if (counter >= 1000 && counter < 99999) {
    return (
      parseInt(counter / 1000.0)
        .toFixed(0)
        .toString() + 'K'
    );
  } else if (counter >= 99999 && counter <= 1000000) {
    return (
      parseFloat(counter / 100000.0)
        .toFixed(2)
        .toString() + 'L'
    );
  } else {
    return counter.toString();
  }
}

function valuetext(value) {
  return `₹${countertostring(value)}`;
}

class PricePerCreator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textValue: this.props.pricePerCreator,
      isError: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.textValue !== this.state.textValue) {
      this.props.setFilters('pricePerCreator', this.state.textValue);
    }
    if (prevProps.currBudget !== this.props.currBudget) {
      if (this.props.currBudget <= 10000000) {
        this.setState({ textValue: this.props.currBudget * 0.1 });
      } else {
        this.setState({ textValue: 1000000 });
      }
    }
  }

  handlePPcChange(e) {
    if (Number(e.target.value.replace(/\D/g, '')) > 1000000) {
      this.setState({
        textValue: 1000000,
        isError: true,
      });
    } else {
      this.setState({
        textValue: Number(e.target.value.replace(/\D/g, '')),
        isError: false,
      });
    }
  }

  render() {
    let { classes, selected, setSelectedObjective, pricePerCreator } = this.props;

    return (
      <div
        onClick={() => {
          setSelectedObjective('PricePerCreator');
        }}
        style={{ position: 'relative' }}
      >
        <ObjectiveOption {...(selected ? { selected: true } : null)}>
          max Price Per Creator
        </ObjectiveOption>
        {selected ? (
          <div className={classes.modal}>
            {this.state.isError ? <h6>The value cannot exceed 10L.</h6> : ''}
            <div className={classes.box}>
              <TextField
                outlined
                onChange={(e) => this.handlePPcChange(e)}
                value={this.state.textValue.toLocaleString()}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment style={{ fontSize: '12px' }} position="start">
                      ₹
                    </InputAdornment>
                  ),
                  classes: {
                    input: classes.resize,
                  },
                }}
              />
            </div>
            <div className={classes.slider}>
              <RemoveIcon
                className={classes.remove}
                onClick={() => {
                  if (this.state.textValue >= 1000) {
                    this.setState({
                      textValue: this.state.textValue - 1000,
                    });
                    this.props.setFilters('pricePerCreator', this.state.textValue);
                  }
                }}
              />
              <CustomSlider
                aria-label="Always visible"
                max={1000000}
                min={0}
                valueLabelDisplay="on"
                onChange={(event, newValue) => {
                  this.setState({
                    textValue: newValue,
                    isError: false,
                  });

                  this.props.setFilters('pricePerCreator', this.state.textValue);
                }}
                step={1000}
                value={this.state.textValue}
                valueLabelFormat={valuetext}
              />
              <AddIcon
                className={classes.add}
                onClick={() => {
                  this.setState({
                    textValue: this.state.textValue + 1000,
                  });
                  this.props.setFilters('pricePerCreator', this.state.textValue);
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const styles = {
  modal: {
    background: '#ffffff',
    position: 'absolute',
    width: 300,
    // width: 416,
    height: 100,
    // top: 90,
    top: 128,
    padding: 20,
    boxShadow: '5px 4px 20px rgba(104, 104, 104, 0.25)',
    borderRadius: '30px',
    zIndex: 5,
  },
  box: {
    boxSizing: 'border-box',

    position: 'absolute',
    left: '65.23%',
    right: '6.58%',
    top: '10.55%',
    bottom: '68.2%',

    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.9) 0%, #FBFCFF 100%)',
    border: '1px solid #C4C4C4',
    borderRadius: '10px',
  },
  slider: {
    top: '65px',
    position: 'absolute',
    width: '250px',
    left: '50px',
  },
  resize: {
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.03em',
  },
  add: {
    position: 'absolute',
    top: '2px',
    left: '254px',
    cursor: 'pointer',
    color: '#757575',
  },
  remove: {
    position: 'absolute',
    right: '264px',
    top: '2px',
    cursor: 'pointer',
    color: '#757575',
  },
};

export default withStyles(styles)(PricePerCreator);
