import React, {useRef, useEffect, useState} from 'react';

import { withStyles } from '@material-ui/core/styles';

import { getContentList, updateContentStatus, sendWhatsappMessage } from 'api_handlers/discovery';
import { Grid, Typography, TextField, Button, IconButton, Avatar, Modal } from '@material-ui/core';

import document_icon from '../../../assets/document_icon.png';
import CloseIcon from '@mui/icons-material/Close';

import { CloseRounded, FeedbackRounded } from '@material-ui/icons';

import ImageValidator from 'components/ux/ImageValidator';

import moment from 'moment';

let timer;

const ContentRejected = ({classes, campaign_id}) => {

    const [ influencersList, setinfluencersList ] = useState([]);
    const [ type, setType ] = useState('');
    const [ selectedIndex, setSelectedIndex ] = useState(-1);
    const [ message, setMessage ] = useState('');
    const [ currentMessageIndex, setCurrentMessageIndex ] = useState(-1);
    const [openModal, setOpenModal] = useState(false);
    const [contentUrl, setContentUrl] = useState(false);

    useEffect(() => {
        getContentData();
    }, [campaign_id]);

    const getContentData = async () => {
        if (timer) {
            clearInterval(timer);
        }
        let data = await getContentList(campaign_id, "rejected");
        setinfluencersList(data.results.sort((a, b) => {
            if (!a.updated_at && !b.updated_at) return 0;
            if (!a.updated_at) return 1;
            if (!b.updated_at) return -1;
            return new Date(b.updated_at) - new Date(a.updated_at);
        }));
        timer = setInterval(() => {
            getContentData();
        }, 5000);
    }

    return <div style={{ display: 'flex', width: '100%', position: 'relative' }}>
        <Grid container md={12} justify='flex-start'>
        {
            influencersList.map((data, index)=>{
                return <Grid item style={{ width: 400 }} key={data.id}>
                    <Grid container justify="space-between">
                    <Grid item className={classes.card} style={{ position: 'relative' }}>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="space-between">
                        <Grid
                            item
                            md={11}>
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                            {data.influencer_details.name}
                        </Typography>
                        </Grid>
                        <Grid
                            item
                            md={11}
                            onClick={()=>{
                                setContentUrl(data.content_url)
                                setOpenModal(true)
                            }}>
                            <ImageValidator 
                                showControls={true}
                                className='top-post-img'
                                url={data.content_url}
                                placeContainerHolderStyle={{ height: 220, display: 'flex', alignItems: 'center' }}>
                                <a 
                                    onClick={(e)=>{
                                        e.stopPropagation()
                                    }}
                                    href={data.content_url} target='_blank'><img src={document_icon} style={{ height: 220 }}/></a>
                            </ImageValidator>
                        </Grid>
                        <div style={{ marginTop: 10 }}                                 
                                    onClick={(e)=>{
                                        e.stopPropagation()
                                    }} >
                                    <Button
                                        variant="contained" 
                                        disabled={data.status == 'approved'}
                                        style={{ background: data.status == 'approved' ? '#c6c6c6' : 'rgb(102, 187, 106)', color: 'white' }}
                                        onClick={(e)=>{
                                            setType('approved');
                                            setSelectedIndex(index);
                                            setCurrentMessageIndex(-1);
                                        }}>Approve</Button>
                                    <Button
                                        variant="contained" 
                                        disabled={data.status == 'rejected'}
                                        style={{ marginLeft: 10, background: data.status == 'rejected' ? '#c6c6c6' : 'rgb(244, 67, 54)', color: 'white' }}
                                        onClick={(e)=>{
                                            setType('rejected');
                                            setSelectedIndex(index);
                                            setCurrentMessageIndex(-1);
                                        }}>Reject</Button>
                                </div>
                                <div style={{ 
                                    display: 'flex', 
                                    justifyContent: 'space-between', 
                                    width: '100%', 
                                    marginTop: 5,
                                    alignItems: 'center' }}
                                    onClick={(e)=>{
                                        e.stopPropagation()
                                    }} >
                                    <span style={{ fontSize: 12 }}>
                                        {moment(data.updated_at).format('DD/MM/YYYY hh:mm a')}
                                    </span>
                                    <span>
                                        {data.comment ? <IconButton
                                            onClick={()=>{
                                                setCurrentMessageIndex(index)
                                            }}>
                                            <FeedbackRounded />
                                        </IconButton> : null }
                                    </span>
                                </div>
                                {
                                    index == selectedIndex ? 
                                        <div style={{ 
                                                width: 300,
                                                position: 'absolute', 
                                                bottom: -70, 
                                                background: 'white', 
                                                padding: 10, 
                                                zIndex: 9,
                                                borderRadius: 5,
                                                boxShadow: '5px 5px 20px #c6c6c6' }}>
                                            <div>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <IconButton 
                                                        style={{ padding: 0 }}
                                                        onClick={()=>{
                                                            setSelectedIndex(-1);
                                                        }}>
                                                        <CloseRounded/>
                                                    </IconButton>
                                                </div>
                                                <TextField
                                                    placeholder='Add your feedback'
                                                    id="standard-text"
                                                    variant='outlined'
                                                    InputProps={{ disableUnderline: true,style:{maxHeight:"55px",maxWidth:'1000vw' }}}
                                                    className={classes.wrapText}
                                                    onChange={(e)=>{
                                                        setMessage(e.target.value);
                                                    }}
                                                />
                                                <div style={{ marginTop: 10 }}>
                                                    <Button
                                                        variant="contained" 
                                                        style={{ marginTop: 5, background: '#FEBD1C', color: 'white' }}
                                                        onClick={(e)=>{
                                                            setSelectedIndex(-1);
                                                            updateContentStatus(influencersList[selectedIndex].id, type, message);
                                                            influencersList[selectedIndex].status = type;
                                                            influencersList[selectedIndex].comment = message;
                                                            setinfluencersList(influencersList);
                                                        }}>Submit</Button>
                                                    <Button
                                                        variant="contained" 
                                                        style={{ marginTop: 5, background: '#25D366', color: 'white', marginLeft: 10 }}
                                                        onClick={(e)=>{
                                                            setSelectedIndex(-1);
                                                            updateContentStatus(influencersList[selectedIndex].id, type, message);
                                                            influencersList[selectedIndex].status = type;
                                                            influencersList[selectedIndex].comment = message;
                                                            if (data.phone_number) {
                                                                sendWhatsappMessage(data.phone_number, "", data.influencer_details.influencer_id, campaign_id, message);
                                                            }
                                                            setinfluencersList(influencersList);
                                                        }}>Send</Button>
                                                </div>
                                            </div>
                                        </div>
                                    : null   
                                }
                                {
                                        index == currentMessageIndex ? 
                                            <div style={{ 
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                width: 300,
                                                position: 'absolute', 
                                                bottom: 0, 
                                                background: 'white', 
                                                padding: 10, 
                                                zIndex: 9,
                                                borderRadius: 5,
                                                boxShadow: '5px 5px 20px #c6c6c6' }}>
                                                <Avatar 
                                                    src={data.approved_by_avatar}
                                                    style={{ 
                                                        width: 20, 
                                                        height: 20,
                                                        marginRight: 5
                                                    }}/>
                                                <Typography 
                                                    style={{ fontWeight: 'bold' }}>
                                                    {data.approved_by_username}
                                                </Typography>
                                                <Typography style={{ flex: 1 }}>
                                                    {data.comment}
                                                </Typography>
                                                <IconButton 
                                                    onClick={()=>{
                                                        setCurrentMessageIndex(-1);
                                                    }}>
                                                    <CloseRounded/>
                                                </IconButton>
                                            </div> : null
                                }
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
            })
        }
    </Grid>{
        openModal ? 
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                    style={{ 
                        padding: 20,
                        paddingTop: 0,
                        position: 'fixed', 
                        width: '39%', 
                        left: '25%',
                        top: 100,
                        height: '70%',
                    }}
                >
                    <Grid container className={classes.root}>
                    <Grid item onClick={() => setOpenModal(false)} style={{alignSelf: 'flex-end', cursor: 'pointer'}}>
                        <CloseIcon/>
                    </Grid>
                    <Grid item md={12}>
                    {contentUrl && contentUrl.length > 0 ? (
                    <ImageValidator
                        showControls={true}
                        className='chat-img'
                        url={contentUrl}
                        placeContainerHolderStyle={{ height: 220, display: 'flex', alignItems: 'center' }}>
                        <a href={contentUrl} target='_blank'><img src={document_icon} style={{ height: 220 }}/></a>
                    </ImageValidator>
                    ) : null}
                    </Grid>
                    </Grid>
                </Modal> : null
            }
    </div>

}

const styles = (theme) => ({
    card: {
        background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
        boxShadow:
        '-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
        borderRadius: 20,
        padding: 20,
        maxWidth: 300,
        marginLeft: 20,
        marginBottom: 50,
        height: '100%', width: '100%'
    },
    root: {
        // background: 'white',
        filter: 'drop-shadow(5px 8px 15px rgba(171, 171, 171, 0.25))',
        // height: 500,
        width: 'fit-content',
        borderRadius: 10,
        padding: 25,
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: 'auto',
        // overflowY: 'scroll',
    },
})

export default withStyles(styles)(ContentRejected);
