import { Typography, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { withStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import CategoryChip from "./components/CategoryChip";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#3A3A3A",
    color: "white",
    borderRadius: "2px",
    maxWidth: 220,
    width: 120,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
  arrow: {
    color: "#3A3A3A",
  },
}))(Tooltip);

function CategoryContainer({ categories, primary_category }) {
  const classes = useStyles();
  const theme = useTheme();
  const colors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.info.main,
    theme.palette.warning.main,
  ];

  return (
    <Grid container spacing={2} className={classes.root}>
      {primary_category ?
          <Grid item>
            <CategoryChip label={primary_category}
             color="#FC2E2E"
             borderColor="#FC2E2E" />
          </Grid>
      : null}
      {categories.slice(0, 3).map((name, i) => (
        <Grid item key={name}>
          <CategoryChip label={name} 
          color="#4A4A4A"
          borderColor="#C9C9C9"
          />
        </Grid>
      ))}
      {categories.length > 3 ? (
        <HtmlTooltip
          title={
            <>
              {categories.slice(3).map((key, i) => (
                <div>
                  <b>{key}</b>
                </div>
              ))}
            </>
          }
          arrow
          placement="right-start"
        >
          <p>
            <Grid>
              <CategoryChip label="show more..." color="#ef1473" />
            </Grid>
          </p>
        </HtmlTooltip>
      ) : (
        ""
      )}
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    // background: 'linear-gradient(135deg, #E3E4ED 0%, #FBFCFF 100%)',
    // boxShadow: '-1px 1px 2px rgba(227, 228, 234, 0.2), 1px -1px 2px rgba(227, 228, 234, 0.2), -1px -1px 2px rgba(251, 252, 255, 0.9), 1px 1px 3px rgba(227, 228, 234, 0.9), inset 1px 1px 2px rgba(251, 252, 255, 0.3), inset -1px -1px 2px rgba(227, 228, 234, 0.5)',
    borderRadius: 5,
    height: 50,
    // justifyContent: 'space-evenly',
    alignItems: "center",
  },
}));

export default CategoryContainer;
