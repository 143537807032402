import { Grid, makeStyles, Typography } from '@material-ui/core';

export default function RelevantHashTags({ relevant_tags = [] }) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item md={12} className={classes.header}>
        <Typography variant="h3" style={{ color: 'black', fontSize: 16 }}>
          RELEVANT HASHTAGS
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Grid container justifyContent="flex-start" style={{ height: '100%' }}>
          {relevant_tags.length > 0
            ? relevant_tags.slice(0, 3).map((hashtag, i) => (
                <Grid item className={classes.card} style={{ width: '30%' }}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item md={11}>
                      <Typography variant="body2">#{hashtag.tag}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            : ''}
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  header: {
    background: '#F3F4FE',
    // boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    // borderRadius: 10,
    padding: 15,
    // marginBottom: 30,
    marginTop: 10,
  },
  card: {
    background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    // boxShadow:'-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    // borderRadius: 20,
    padding: 20,
    marginTop: 5,
    marginLeft: 2,
  },
}));
