import { constants } from '../constants/planner2';

const initialState = {
  page: 1,
  payload: constants.DEFAULT_PAYLOAD,
  influencers: [],
  drawerOpen: true,
  loading: false,
  totalInfluencersCount: 0,
  selectedInfluencers: new Set(),
  user: {},
  showPremiumFeatureDialog: false,
  predictionStatus: null,
  predictions: {},
  newInfluencers: [],
  newInfluencersSearch: [],
  autoCompleteValues: [],
};

const Planner2 = (state = initialState, action) => {
  switch (action.type) {
    case constants.UPDATE_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case constants.UPDATE_PAYLOAD:
      const payload = {...action.payload};
      const budget = payload["campaign-level-requirements"]["budget"];

      if(budget && budget > 0 && !payload["creator-level-requirements"]["creator-type"]){
        payload["creator-level-requirements"]["creator-type"] = { "macro": budget };
      }
      if(budget && budget > 0 && !payload["campaign-level-requirements"]["price-per-creator"]){
        payload["campaign-level-requirements"]["price-per-creator"] = 0.1 * budget;
      }
        
      return {
        ...state,
        payload: payload,
      };
    case constants.UPDATE_INFLUENCERS:
      return {
        ...state,
        influencers: [...action.influencers],
      };
    case constants.TOGGLE_DRAWER_OPEN:
      return {
        ...state,
        drawerOpen: !state.drawerOpen,
      };
    case constants.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case constants.TOTAL_INFLUENCERS:
      return {
        ...state,
        totalInfluencersCount: action.totalInfluencersCount,
      };
    case constants.UPDATE_SELECTED_INFLUENCERS:
      return {
        ...state,
        selectedInfluencers: action.selectedInfluencers,
      };
    case constants.UPDATE_USER:
      return {
        ...state,
        user: action.user,
      };
    case constants.SHOW_PREMIUM_FEATURE_DIALOG:
      return {
        ...state,
        showPremiumFeatureDialog: action.showPremiumFeatureDialog,
      };
    case constants.UPDATE_PREDICTION_STATUS:
      return {
        ...state,
        predictionStatus: action.status,
      };
    case constants.UPDATE_PREDICTIONS:
      return {
        ...state,
        predictions: action.predictions,
      };
    case constants.ADD_NEW_INFLUENCER:
      return {
        ...state,
        newInfluencers: [...state.newInfluencers, action.data]
      };
    case constants.UPDATE_NEW_INFLUENCER_SEARCH:
      return {
        ...state,
        newInfluencersSearch: [...action.data]
      };
    case constants.UPDATE_AUTO_COMPLETE_VALUES:
      return {
        ...state,
        autoCompleteValues: [...action.autoCompleteValues]
      };
    default:
      return state;
  }
}

export default Planner2;
