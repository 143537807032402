// material-ui
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';


const SkeletonTableRow = () => (
    <TableRow>
      <TableCell sx={{paddingLeft: 3}}>
        <Skeleton variant="rectangle" width={20} height={20}/>
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Skeleton variant="circular" width={40} height={40} />
          <Stack>
              <Stack direction="row" alignItems="center" spacing={0.25}>
                  <Skeleton variant="text" width={110} height={30}/>
              </Stack>
              <Skeleton variant="text" width={80} height={20}/>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={60} height={30}/>
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={60} height={30}/>
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={60} height={30}/>
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={40} height={30}/>
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={80} height={30}/>
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={80} height={30}/>
      </TableCell>
      <TableCell>
        <Grid container spacing={1}>
          {[1, 2, 3].map((index) => (
              <Grid item key={index}><Skeleton variant="rounded" width={60} height={30}/></Grid>
          ))}
        </Grid>
      </TableCell>
    </TableRow>
);

export default SkeletonTableRow;
