export const campaignConstants = {
  GET_CAMPAIGNS_REQUEST: 'GET_CAMPAIGNS_REQUEST',
  GET_CAMPAIGNS_SUCCESS: 'GET_CAMPAIGNS_SUCCESS',
  GET_CAMPAIGNS_FAILURE: 'GET_CAMPAIGNS_FAILURE',
  GET_CAMPAIGN_DETAILS_REQUEST: 'GET_CAMPAIGN_DETAILS_REQUEST',
  GET_CAMPAIGN_DETAILS_SUCCESS: 'GET_CAMPAIGN_DETAILS_SUCCESS',
  GET_CAMPAIGN_DETAILS_FAILURE: 'GET_CAMPAIGN_DETAILS_FAILURE',
  GET_INFLUENCERS_REQUEST: 'GET_INFLUENCERS_REQUEST',
  GET_INFLUENCERS_SUCCESS: 'GET_INFLUENCERS_SUCCESS',
  GET_INFLUENCERS_FAILURE: 'GET_INFLUENCERS_FAILURE',
  GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
  GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
  GET_REPORT_FAILURE: 'GET_REPORT_FAILURE',
  CAMPAIGN_ID: 'CAMPAIGN_ID',
  CHANGE_INFLUENCERS_ALIGNMENT: 'CHANGE_INFLUENCERS_ALIGNMENT',
  DOWNLOAD_INFLUENCERS_REPORT: 'DOWNLOAD_INFLUENCERS_REPORT',
};
