import { Component } from 'react';
import { Card, CardContent, Grid, Modal } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Line } from 'react-chartjs-2';

import NoDataLabel from '../../reporting/cards/common/NoDataLabel';
import { getVideoGraphData } from '../../../api_handlers/reporting';

class VideoGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };
  }

  getData() {
    getVideoGraphData(this.props.videoId, 'view_count').then((response) => {
      this.setState({ data: response.data, loading: false });
    });
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
  }

  render() {
    return (
      <Grid container spacing={1}>
        <Modal
          open={this.props.open}
          onClose={() => {
            this.props.handleClose();
          }}
          style={{ top: 170 }}
        >
          <Card
            style={{
              width: 600,
              margin: 'auto',
            }}
          >
            <CardContent>
              <Grid container>
                <Grid item md={12} class="dashboard-card-title">
                  View over time
                </Grid>
                <Grid item md={12} style={{ height: 237 }}>
                  {this.state.loading ? (
                    <CircularProgress style={{ marginTop: 90 }} />
                  ) : this.state.data.length === 0 ? (
                    <NoDataLabel offsetTop={90} />
                  ) : (
                    <Line
                      height={240}
                      width={700}
                      data={{
                        labels: this.state.data.labels,
                        datasets: [
                          {
                            label: 'View',
                            data: this.state.data.dataset,
                            fill: false,
                            backgroundColor: 'rgb(255, 99, 132)',
                            borderColor: 'rgba(255, 99, 132, 0.2)',
                          },
                        ],
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Modal>
      </Grid>
    );
  }
}

export default VideoGraph;
