import React from 'react';
import { withRouter } from 'react-router-dom';

import BaseSidePanel from '../../ux/BaseSidePanel';

import FacebookIcon from '@material-ui/icons/Facebook';

class SidePanel extends React.Component {
  getNavigationLinks() {
    return [
      {
        to: `${this.props.match.url}/social-settings`,
        label: 'Social Settings',
        icon: FacebookIcon,
      },
    ];
  }

  render() {
    return <BaseSidePanel navigationLinks={this.getNavigationLinks()} />;
  }
}

export default withRouter(SidePanel);
