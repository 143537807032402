import { useEffect, useState, Component } from 'react';
import { connect } from 'react-redux';
import { Typography, Grid, Chip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import HeaderLabel from './components/HeaderLabel';
import ListSelect from './components/ListSelect';
import SortIcon from '@mui/icons-material/Sort';
import CustomColumns from './CustomColumns';

import ChannelIcon from 'assets/discovery/channel-icon.png';
import FollowerIcon from 'assets/discovery/followers-icon.png';
import AvgViewIcon from 'assets/discovery/Avg-View-icon.png';
import GenderIcon from 'assets/discovery/Gender-icon.png';
import EngRateIcon from 'assets/discovery/Eng-Rate-icon.png';
import PPPIcon from 'assets/discovery/PPP-icon.png';
import PriceIcon from 'assets/discovery/Price-icon.png';
import HeadingIcon from 'assets/discovery/Heading-Columns-icon.png';
import SpeedIcon from 'assets/discovery/SpeedIcon.png';
import ScorePriceIcon from 'assets/discovery/PriceIcon.png';
import ImpactIcon from 'assets/discovery/ImpactIcon.png';
import Checkbox from '@mui/material/Checkbox';
import LocationIcon from 'assets/discovery/Location-Icon.png';
import LanguageIcon from 'assets/discovery/Language-icon.png';
import GenderSplitIcon from 'assets/discovery/gender-split-icon.png';
import InterestIcon from 'assets/discovery/interest-icon.png';
import { getPlannerInfluencers, getPlannerDownload } from 'api_handlers/discovery';

class ListHeader extends Component {
  constructor(props) {
    super(props);
    this.sortColumn = this.sortColumn.bind(this);
    this.allowedColumns = this.allowedColumns.bind(this);
    this.state = {
      showColumnSelectModal: false,
      columns: [],
      default: true,
      removedItem: [],
      checkLimit: 0,
      sortColumns: '',
      sortOrders: 'asc',
      micro: 0,
      macro: 0,
      mega: 0,
      nano: 0,
    };
  }

  setSort = () => {
    const { sortColumns, sortOrders, micro, macro, mega, nano } = this.state;

    const payload = {
      ...this.props.payload,
      'advance-filters': {
        ...this.props.payload['advance-filters'],
        sortBy: sortColumns,
        sortOrder: sortOrders,
      },
      'creator-level-requirements': {
        ...this.props.payload['creator-level-requirements'],
        'creator-type-remain-budget': {
          micro: micro,
          macro: macro,
          mega: mega,
          nano: nano,
        },
      },
    };

    delete payload['budget_left'];
    delete payload['exclude_ids'];

    this.props.getPlannerInfluencersData(payload, 1);
  };

  async sortColumn(column) {
    this.setState(
      {
        sortColumns: column,
        sortOrders:
          this.state.sortOrders === 'asc' && this.props.sortBy === column ? 'desc' : 'asc',
      },
      this.setSort,
    );
    this.props.setSortBy(column);
    this.props.setSortOrder(
      this.props.sortOrder === 'asc' && this.props.sortBy === column ? 'desc' : 'asc',
    );
  }

  setCreatorTypeStateIfKeyExists = (type) => {
    this.setState({
      [type]: this.props.payload['creator-level-requirements']['creator-type'][type],
    });
  };

  componentDidUpdate(prevProps) {
    ['macro', 'micro', 'mega', 'nano'].forEach((type) => {
      if (type in this.props.payload['creator-level-requirements']['creator-type']) {
        const prevTypeBudget =
          prevProps.payload['creator-level-requirements']['creator-type'][type];
        const typeBudget = this.props.payload['creator-level-requirements']['creator-type'][type];
        if (prevTypeBudget !== typeBudget) {
          this.setCreatorTypeStateIfKeyExists(type);
        }
      }
    });
  }

  setShowColumnSelectModal(value) {
    this.setState({ showColumnSelectModal: value });
  }

  allowedColumns(column, isDefault, removedItem, checkLimit) {
    if (isDefault === false) {
      if (this.state.columns.length === 0) {
        this.setState({
          columns: Array.from(new Set(this.state.columns.concat(column))),
          default: false,
          checkLimit: checkLimit,
        });
      } else {
        this.state.columns = this.state.columns.filter((c) => !removedItem.includes(c));
        this.setState({
          columns: Array.from(new Set(this.state.columns.concat(column))),
          default: false,
          removedItem: this.state.removedItem.concat(removedItem),
          checkLimit: checkLimit,
        });
      }
    } else {
      this.setState({
        default: true,
        columns: [
          'Speed Score',
          'Country',
          'Average Views',
          'Fair Price',
          'Impact Score',
          'Followers',
          'Engagement Rate',
          'Audience Gender',
        ],
        checkLimit: 7,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.columns !== this.state.columns) {
      this.props.onSelectColumns(
        this.state.columns,
        this.state.default,
        this.state.removedItem,
        this.state.checkLimit,
      );
    }
  }

  // this.props.onSelectColumns(this.state.columns, this.state.default)

  render() {
    let {
      classes,
      count,
      sortBy,
      setSortBy,
      sortOrder,
      setSortOrder,
      getPlannerInfluencersData,
      listSelect,
      setlistSelect,
      influencers,
      selectedInfluencer,
      setSelectedInfluencer,
      recommendation_count,
      recommendation,
      setRecommendation,
      isPlanActive,
    } = this.props;

    return (
      <Grid container className={classes.root}>
        <Grid item md={1} style={{ textAlign: 'left' }}>
          {/* <Checkbox size="small" /> */}
          {isPlanActive ? (
            <ListSelect
              count={count}
              listSelect={listSelect}
              setlistSelect={setlistSelect}
              recommendation_count={recommendation_count}
              influencers={influencers}
              selectedInfluencer={selectedInfluencer}
              setSelectedInfluencer={setSelectedInfluencer}
              recommendation={recommendation}
              setRecommendation={setRecommendation}
              selectedPrediction={this.props.selectedPrediction}
              setSelectedPrediction={this.props.setSelectedPrediction}
              isPlanActive={this.props.isPlanActive}
            />
          ) : null}
        </Grid>
        <Grid item md={2}>
          <Grid container justifyContent="center" alignItems="center" spacing={1}>
            <Grid item md={'auto'}>
              <Typography className={classes.font16}>INFLUENCERS</Typography>
            </Grid>
            <Grid item md={'auto'}>
              <Chip
                size="small"
                label={`(${count})`}
                className={classes.font16}
                style={{ color: '#757575', backgroundColor: 'white' }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={8}>
          <Grid container className={classes.matrixBox} style={{ paddingLeft: '42px' }}>
            {/* <Grid item md={1}> */}
            {/*     <HeaderLabel imageSrc={ChannelIcon} label="Channel"/> */}
            {/* </Grid> */}
            {this.state.columns.includes('Speed Score') || this.state.default ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  imageSrc={SpeedIcon}
                  label="Speed"
                  // sortable={true}
                  // sort={sortBy === "speed"}
                  // sortOrder={sortOrder}
                  // sortByThis={() => this.sortColumn("speed")}
                />
              </Grid>
            ) : (
              ''
            )}
            {/* {this.state.columns.includes('Price Score') || this.state.default ?
            <Grid item md className={classes.moreWidth}>
              <HeaderLabel
                imageSrc={ScorePriceIcon}
                label='Price'
                sortable={true}
                sort={sortBy === 'price'}
                sortOrder={sortOrder}
                sortByThis={() => this.sortColumn('price')}
              />
            </Grid>
            : ("")} */}
            {this.state.columns.includes('Impact Score') || this.state.default ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  imageSrc={ImpactIcon}
                  label="Impact"
                  // sortable={false}
                  // sort={sortBy === "impact"}
                  // sortOrder={sortOrder}
                  // sortByThis={() => this.sortColumn("impact")}
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Followers') || this.state.default ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  imageSrc={FollowerIcon}
                  label="Followers"
                  sortable={true}
                  sort={sortBy === 'followers'}
                  sortOrder={sortOrder}
                  sortByThis={() => this.sortColumn('followers')}
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Engagement Rate') || this.state.default ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  imageSrc={EngRateIcon}
                  label="Eng. Rate"
                  sortable={true}
                  sort={sortBy === 'eng-rate'}
                  sortOrder={sortOrder}
                  sortByThis={() => this.sortColumn('eng-rate')}
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Average Views') || this.state.default ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  imageSrc={AvgViewIcon}
                  label="Avg. Views"
                  sortable={true}
                  sort={sortBy === 'views'}
                  sortOrder={sortOrder}
                  sortByThis={() => this.sortColumn('views')}
                  tooltip="Instagram - Avg_reel_plays. Youtube - Avg_views"
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Audience Gender') || this.state.default ? (
              <Grid item md={1}>
                <HeaderLabel
                  imageSrc={GenderIcon}
                  label="Audience Gender"
                  sortable={false}
                  // sort={sortBy === 'audience-gender'}
                  // sortOrder={sortOrder}
                  // sortByThis={() => this.sortColumn('audience-gender')}
                />
              </Grid>
            ) : (
              ''
            )}
            {/* <Grid item md className={classes.moreWidth}> */}
            {/*     <HeaderLabel */}
            {/*         imageSrc={PPPIcon} */}
            {/*         label="Paid Post Performance" */}
            {/*         sortable={true} */}
            {/*         sort={sortBy === 'ppp'} */}
            {/*         sortOrder={sortOrder} */}
            {/*         sortByThis={() => this.sortColumn('ppp')} */}
            {/*     /> */}
            {/* </Grid> */}
            {this.state.columns.includes('Fair Price') || this.state.default ? (
              <Grid item md={1}>
                <HeaderLabel
                  imageSrc={PriceIcon}
                  label="Fair Price"
                  sortable={true}
                  sort={sortBy === 'fair-price'}
                  sortOrder={sortOrder}
                  sortByThis={() => this.sortColumn('fair-price')}
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Channel') ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  style={{ zIndex: -1 }}
                  imageSrc={ChannelIcon}
                  label="Channel"
                  sortable={false}
                  // sort={sortBy === 'views'}
                  // sortOrder={sortOrder}
                  // sortByThis={() => this.sortColumn('views')}
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Language') ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  imageSrc={LanguageIcon}
                  label="Language"
                  // sortable={true}
                  // sort={sortBy === 'language'}
                  // sortOrder={sortOrder}
                  // sortByThis={() => this.sortColumn('language')}
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Audience Age') ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  imageSrc={GenderSplitIcon}
                  label="Audience Age"
                  sortable={false}
                  // sort={sortBy === 'views'}
                  // sortOrder={sortOrder}
                  // sortByThis={() => this.sortColumn('views')}
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Influencer Location') ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  imageSrc={LocationIcon}
                  label="Influencer Location"
                  // sortable={true}
                  // sort={sortBy === 'inf_location'}
                  // sortOrder={sortOrder}
                  // sortByThis={() => this.sortColumn('inf_location')}
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Interest') ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  imageSrc={InterestIcon}
                  label="Audience Interest"
                  sortable={false}
                  // sort={sortBy === 'views'}
                  // sortOrder={sortOrder}
                  // sortByThis={() => this.sortColumn('views')}
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Audience Location') ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  imageSrc={LocationIcon}
                  label="Audience Location"
                  sortable={false}
                  // sort={sortBy === 'views'}
                  // sortOrder={sortOrder}
                  // sortByThis={() => this.sortColumn('views')}
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Influencer Gender') ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  imageSrc={GenderIcon}
                  label="Influencer Gender"
                  // sortable={true}
                  // sort={sortBy === 'inf_gender'}
                  // sortOrder={sortOrder}
                  // sortByThis={() => this.sortColumn('inf_gender')}
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Influencer Age') ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  imageSrc={GenderSplitIcon}
                  label="Influencer Age"
                  sortable={false}
                  // sort={sortBy === 'views'}
                  // sortOrder={sortOrder}
                  // sortByThis={() => this.sortColumn('views')}
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Country') || this.state.default ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel imageSrc={GenderSplitIcon} label="Country" />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Contact Number') ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  imageSrc={GenderIcon}
                  label="Contact Number"
                  sortable={false}
                  // sort={sortBy === 'views'}
                  // sortOrder={sortOrder}
                  // sortByThis={() => this.sortColumn('views')}
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Email') ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  imageSrc={GenderIcon}
                  label="Email"
                  sortable={false}
                  // sort={sortBy === 'views'}
                  // sortOrder={sortOrder}
                  // sortByThis={() => this.sortColumn('views')}
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Actual Price') ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel
                  imageSrc={PriceIcon}
                  label="Actual Price"
                  // sortable={true}
                  // sort={sortBy === 'views'}
                  // sortOrder={sortOrder}
                  // sortByThis={() => this.sortColumn('views')}
                />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Brand Final Price') ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel imageSrc={PriceIcon} label="Brand Final Price" sortable={false} />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Influencer Final Price') ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel imageSrc={PriceIcon} label="Influencer Final Price" sortable={false} />
              </Grid>
            ) : (
              ''
            )}
            {this.state.columns.includes('Deliverables') ? (
              <Grid item md className={classes.moreWidth}>
                <HeaderLabel imageSrc={InterestIcon} label="Deliverables" sortable={false} />
              </Grid>
            ) : (
              ''
            )}
            {/* <Grid item spacing={0.5} md={1}> */}
            {this.state.showColumnSelectModal ? (
              <CustomColumns
                onSelectColumns={this.allowedColumns}
                columns={this.props.columns}
                hideColumnSelectModal={() => this.setShowColumnSelectModal(false)}
                isStaff={this.props.isStaff}
              />
            ) : null}

            <div
              style={{
                width: '0px',
                float: 'right',
                display: 'inline-block',
                paddingLeft: 0,
                marginLeft: 0,
                textAlign: 'right',
              }}
              onClick={() => {
                // setShowPopup(true);
                this.setState({ showColumnSelectModal: true });
              }}
            >
              <SortIcon
                style={{ width: '0.6em', margin: '0px', cursor: 'pointer' }}
                className={classes.customColumns}
              />
            </div>

            {/* </Grid> */}
          </Grid>
        </Grid>
        {/* <Grid item md={1}> */}
        {/*     <img src={HeadingIcon} alt="icon" /> */}
        {/* </Grid> */}
      </Grid>
    );
  }
}

const styles = {
  root: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    boxShadow:
      '-1px 1px 2px rgba(215, 216, 221, 0.2), 1px -1px 2px rgba(215, 216, 221, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(215, 216, 221, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 221, 0.5)',
    borderRadius: 10,
    background: '#FFFFFF',
    height: 50,
  },
  moreWidth: {
    maxWidth: '10.5%',
  },
  matrixBox: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  font12: {
    fontSize: 12,
  },
  font16: {
    fontSize: 14,
  },
};

// const mapStateToProps = (state) => {
//     return {
//         filters: state.discovery.filters
//     }
// }
// const actionCreators = {
//     setFilters: updateFilter,
//     getFilteredInfluencer,
//     resetFilter,
// };
// export default connect(mapStateToProps, actionCreators) (
//     ListHeader
// );

export default withStyles(styles)(ListHeader);
