import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

import { updateFilters } from 'actions/planner-filters';


function formatNumber(num) {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(0) + 'B';
  }
  if (num >= 1e6) {
    return (num / 1e6).toFixed(0) + 'M';
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(0) + 'K';
  }
  return num.toString();
}

const StyledTypography = styled(Typography)({
  wordWrap: 'break-word',
  // Alternatively, you can use overflowWrap
  // overflowWrap: 'break-word',
});

const SliderFilter = ({
  title, payloadKey, value, min, max, showTextField=false, onValueChange, stepSize
}) => {
  const dispatch = useDispatch();
  const step = stepSize || Math.max(parseInt(max / 1000), 1)

  return (
    <Grid container spacing={2} alignItems='center' justifyContent='space-evenly' sx={{ mb: 2 }}>
          <Grid item xs={3}>
            <StyledTypography variant="h6" gutterBottom>
              {title}
            </StyledTypography>
          </Grid>
          <Grid item xs={showTextField ? 6 : 8}>
            <Slider
              value={value}
              onChange={(event, newValue) => {
                dispatch(updateFilters(payloadKey, newValue, false))
                onValueChange && onValueChange(newValue)
              }}
              valueLabelDisplay="auto"
              getAriaValueText={(v) => formatNumber(v)}
              valueLabelFormat={(v) => formatNumber(v)}
              max={max}
              min={min}
              step={step}
            />
          </Grid>
          {showTextField && (<Grid item xs={3}>
            <TextField size='small'
              inputProps={{
                step: step,
                min: min,
                max: max,
                type: 'number',
                style: { width: '85px'}
              }}
              value={value}
              onChange={(event) => {
                const newValue = parseFloat(event.target.value)
                dispatch(updateFilters(payloadKey, newValue, false))
                onValueChange && onValueChange(newValue)
              }}
            />
          </Grid>)}
        </Grid>
  )
}

export default SliderFilter