import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';

import StyledNavLink from '../ux/StyledNavLink';

class Home extends React.Component {
  logout() {
    for (const key of ['access', 'refresh', 'user']) localStorage.removeItem(key);
    window.location = '/login';
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={4}
        style={{
          // background: 'white',
          fontWeight: '400',
          fontSize: 13,
        }}
      >
        <Grid item md={12}>
          <h2>Welcome to Influencer.in</h2>
          <h3>Your Facebook/Instagram account has been connected successfully!</h3>
          <h4>You can close the browser now.</h4>
        </Grid>
        <Grid item md={12}>
          <Button
            onClick={() => {
              this.logout();
            }}
          >
            Logout
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Home);
