export default function countertostring(counter) {
  counter = parseFloat(counter);
  if (counter >= 1000 && counter < 999999) {
    return (
      parseFloat(counter / 1000.0)
        .toFixed(2)
        .toString() + 'K'
    );
  } else if (counter > 999999) {
    return (
      parseFloat(counter / 1000000.0)
        .toFixed(2)
        .toString() + 'M'
    );
  } else {
    return counter.toFixed(2).toString();
  }
}
