import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import FacebookLogin from '../../../components/auth/FacebookLogin';
import YoutubeLogin from '../../../components/auth/YoutubeLogin';

import { getBrandProfile } from '../../../api_handlers/brand.js';
import { brandFacebookConnect, brandYoutubeConnect } from '../../../api_handlers/auth';

class SocialConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInstagramLinked: false,
      isYoutubeLinked: false,
    };
  }

  updateInstagramConnection() {}

  updateYoutubeConnection() {}

  async registerToBackendFacebook(data) {
    const resp = await brandFacebookConnect(data);
    if (resp.status === 200) this.setState({ isInstagramLinked: true });
  }

  async registerToBackendYoutube(data) {
    console.log('==== youtube data', data);
    // const user = await authHandlers.youtubeLogin(data)
    // if(user) this.updateYoutubeConnection()
    const resp = await brandYoutubeConnect(data);
    if (resp.status === 200) this.setState({ isYoutubeLinked: true });
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const brandProfile = await getBrandProfile();
    this.setState({
      isInstagramLinked: brandProfile.data.is_facebook_connected,
      isYoutubeLinked: brandProfile.data.is_youtube_connected,
    });
  }

  render() {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        style={{
          marginBottom: 30,
          marginTop: 30,
        }}
      >
        <Grid item md={12}>
          <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <FacebookLogin
                label="Connect your Facebook account"
                onSuccess={(response) => {
                  this.registerToBackendFacebook(response);
                }}
                userType="brand"
              />
            </Grid>
            {this.state.isInstagramLinked ? (
              <Grid item>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  style={{
                    color: 'green',
                  }}
                >
                  <Grid item>
                    <CheckCircleIcon />
                  </Grid>
                  <Grid item>
                    <span style={{ fontSize: 12 }}>connected!</span>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <YoutubeLogin
                label="Connect your Google account"
                is_linked={this.state.isYoutubeLinked}
                onSuccess={(response) => {
                  this.registerToBackendYoutube(response);
                }}
                userType="brand"
              />
            </Grid>
            {this.state.isYoutubeLinked ? (
              <Grid item>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  style={{
                    color: 'green',
                  }}
                >
                  <Grid item>
                    <CheckCircleIcon />
                  </Grid>
                  <Grid item>
                    <span style={{ fontSize: 12 }}>connected!</span>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(SocialConnect);
