import { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import InfluencerLogo from '../../assets/influencer-logo.png';

class BrandFooter extends Component {
  render() {
    const year=new Date()
    return (
      <Grid
        container
        alignItems="center"
        spacing={1}
        style={{
          background: 'lightgrey',
          marginTop: '10px',
        }}
      >
        <Grid item md={12}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item md={2}>
              <Grid container justify="center">
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item md={5} style={{ fontSize: '13px' }} alignItems="flex-end">
                    <i>&nbsp;&nbsp;&nbsp;powered by</i>
                  </Grid>
                  <Grid item md={7}>
                    <img
                      style={{ marginLeft: '-10px' }}
                      src={InfluencerLogo}
                      alt="influencer-logo"
                      height={'30px'}
                    ></img>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
        >
          <Grid container
          justifyContent='center'
          alignItems='center'
          >
          <Grid item
          style={{ fontSize: '13px',  bottom: '5px'}}
          
          >
          <i>© {year.getFullYear()} DIGINFLUENZ PRIVATE LIMITED</i>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default BrandFooter;
