import { withStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';

export default withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    // borderRadius: 4,
    position: 'relative',
    backgroundColor: '#f9b725',
    border: '0.5px solid #C8C8C8',
    // borderRadius: '5px',
    fontSize: 11,
    fontWeight: 600,
    // paddingLeft: '15px',
    // height: '15px',
    width: '90px',
    margin: '5px',
    padding: 5,
    textAlign: 'center',
  },
}))(InputBase);
