import { useEffect, useState, Component } from 'react';
import { connect } from 'react-redux';
import { Typography, Grid, Chip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import HeadingIcon from 'assets/discovery/Heading-Columns-icon.png';
import EngRateIcon from 'assets/discovery/Eng-Rate-icon.png';

import HeaderLabel from './HeaderLabel';

class ListHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { classes } = this.props;

    return (
      <Grid container className={classes.root}>
        <Grid item md={10}>
          <Grid container className={classes.matrixBox} style={{ paddingLeft: '50px' }}>
            <Grid item md className={classes.moreWidth}>
              <HeaderLabel
                imageSrc={HeadingIcon}
                label="Creator"
                sortable={false}
                // sort={sortBy === "reach"}
                // sortOrder={sortOrder}
                // sortByThis={() => this.sortColumn("reach")}
              />
            </Grid>
            <Grid item md className={classes.moreWidth}>
              <HeaderLabel
                imageSrc={HeadingIcon}
                label="Post"
                sortable={false}
                // sort={sortBy === "reach"}
                // sortOrder={sortOrder}
                // sortByThis={() => this.sortColumn("reach")}
              />
            </Grid>
            <Grid item md className={classes.moreWidth}>
              <HeaderLabel
                imageSrc={HeadingIcon}
                label="Category"
                sortable={false}
                // sort={sortBy === "reach"}
                // sortOrder={sortOrder}
                // sortByThis={() => this.sortColumn("reach")}
              />
            </Grid>
            <Grid item md className={classes.moreWidth}>
              <HeaderLabel
                imageSrc={HeadingIcon}
                label="Views"
                sortable={false}
                // sort={sortBy === "reach"}
                // sortOrder={sortOrder}
                // sortByThis={() => this.sortColumn("reach")}
              />
            </Grid>
            <Grid item md className={classes.moreWidth}>
              <HeaderLabel
                imageSrc={HeadingIcon}
                label="Likes"
                sortable={false}
                // sort={sortBy === "reach"}
                // sortOrder={sortOrder}
                // sortByThis={() => this.sortColumn("reach")}
              />
            </Grid>
            <Grid item md className={classes.moreWidth}>
              <HeaderLabel
                imageSrc={HeadingIcon}
                label="Comments"
                sortable={false}
                // sort={sortBy === "reach"}
                // sortOrder={sortOrder}
                // sortByThis={() => this.sortColumn("reach")}
              />
            </Grid>
            <Grid item md className={classes.moreWidth}>
              <HeaderLabel
                imageSrc={EngRateIcon}
                label="Engg Rate"
                sortable={false}
                // sort={sortBy === "reach"}
                // sortOrder={sortOrder}
                // sortByThis={() => this.sortColumn("reach")}
              />
            </Grid>
            <Grid item md className={classes.moreWidth}>
              <HeaderLabel
                imageSrc={HeadingIcon}
                label="Fair Price"
                sortable={false}
                // sort={sortBy === "reach"}
                // sortOrder={sortOrder}
                // sortByThis={() => this.sortColumn("reach")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = {
  root: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxShadow:
      '-1px 1px 2px rgba(215, 216, 221, 0.2), 1px -1px 2px rgba(215, 216, 221, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(215, 216, 221, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 221, 0.5)',
    borderRadius: 10,
    background: '#FFFFFF',
    height: 50,
  },
  moreWidth: {
    maxWidth: '0.5%',
  },
  matrixBox: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  font12: {
    fontSize: 12,
  },
  font16: {
    fontSize: '0.8em',
    fontWeight: 600,
  },
};

export default withStyles(styles)(ListHeader);
