// third-party

// assets
import DashboardIcon from '@mui/icons-material/Dashboard';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CampaignIcon from '@mui/icons-material/Campaign';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
// import ListIcon from '../assets/discovery/list-icon.svg'

const icons = {
  DashboardIcon: DashboardIcon,
  AnalyticsIcon: AnalyticsIcon,
  CampaignIcon: CampaignIcon,
  LiveTvIcon: LiveTvIcon,
  WorkspacePremiumIcon: WorkspacePremiumIcon,
  FormatListBulletedRoundedIcon: FormatListBulletedRoundedIcon,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'dashboard',
  title: '',
  icon: icons.DashboardIcon,
  type: 'group',
  children: [
    {
      id: 'discover',
      title: 'Discover',
      type: 'item',
      url: '/planner/discover',
      icon: icons.DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: 'collab-list',
      title: 'Collab List',
      type: 'item',
      url: '/planner/collab-list',
      icon: icons.FormatListBulletedRoundedIcon,
      breadcrumbs: false,
    },
    {
      id: 'campaigns',
      title: 'Campaign',
      type: 'item',
      url: '/brand/campaigns',
      icon: icons.CampaignIcon,
      breadcrumbs: false,
      premiumIcon: icons.WorkspacePremiumIcon,
    },
    {
      id: 'competitors-analysis',
      title: 'Competitors Analysis',
      type: 'item',
      url: '/competitor-insights/',
      icon: icons.AnalyticsIcon,
      breadcrumbs: false,
      premiumIcon: icons.WorkspacePremiumIcon,
    },
    {
      id: 'curated-list',
      title: 'Curated List',
      type: 'item',
      url: '/planner/curated-list',
      icon: icons.LiveTvIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
