import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { authHandlers } from 'api_handlers/auth';


const AuthResetPassword = ({ uid, token, ...others }) => {
  const theme = useTheme();

  const [errors, setErrors] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordResetConfirmed, isPasswordResetConfirmed] = useState(false);
  

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const resetPassword = (e) => {
    e.preventDefault();
    setPasswordError(false);
    isPasswordResetConfirmed(false);
    let isValidationError = false;
    if (!(password && password.length > 0)) {
      setPasswordError(true);
      isValidationError = true;
    }
    if (!(confirmPassword && confirmPassword.length > 0)) {
      setConfirmPasswordError(true);
      isValidationError = true;
    }
    if (confirmPassword !== password) {
        setConfirmPasswordError(true);
        isValidationError = true;
    }
    if (isValidationError) {
        return
    }
    setIsSubmitting(true);
    authHandlers.confirmResetPassword(uid, token, {new_password: password})
        .then(async (response) => {
            if ([200, 201].includes(response.status)) {
              isPasswordResetConfirmed(true);
            } else if ([400, 401, 402, 403].includes(response.status)) {
              setIsSubmitting(false);
                const data = await response.json();
                setErrors({ 'non_field_errors': [data.message] });
            } else {
              setIsSubmitting(false);
              setErrors({ 'non_field_errors': ['Please Try Again'] });
            }
        })
        .catch((e)=>{
            setIsSubmitting(false);
            setErrors({ 'non_field_errors': ['Please Try Again'] });
        });
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <form noValidate onSubmit={resetPassword}>
        <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={passwordError}>
          <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
          <OutlinedInput
            required
            id="outlined-adornment-password-login"
            type={showPassword ? 'text' : 'password'}
            name="password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            inputProps={{}}
            onChange={(e)=>{
                let value = e.target.value;
                setPasswordError(false);
                if (!(value && value.length > 0)) {
                    setPasswordError(true);
                }
                setPassword(value);
            }}
          />
        {passwordError && (
            <FormHelperText error id="standard-weight-helper-text-password-login">
            Please Enter Valid Password
            </FormHelperText>
        )}
        </FormControl>
        <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={confirmPasswordError}>
          <InputLabel htmlFor="outlined-adornment-confirm-password-login">Confirm Password</InputLabel>
          <OutlinedInput
            required
            id="outlined-adornment-confirm-password-login"
            type={showConfirmPassword ? 'text' : 'password'}
            name="confirm_password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownConfirmPassword}
                  edge="end"
                  size="large"
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Confirm Password"
            inputProps={{}}
            onChange={(e)=>{
                let value = e.target.value;
                setConfirmPasswordError(false);
                if (!(value && value.length > 0)) {
                    setConfirmPasswordError(true);
                }
                setConfirmPassword(value);
            }}
          />
        {confirmPasswordError && (
            <FormHelperText error id="standard-weight-helper-text-confirm-password-login">
                Password Does Not Match
            </FormHelperText>
        )}
        </FormControl>
        <Box sx={{ mt: 2 }}>
            <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="secondary">
                Reset Password
            </Button>
        </Box>
        {
            passwordResetConfirmed ? 
            <Box sx={{ mt: 2 }}>
                <Typography style={{ color: 'green' }}>
                  Password has been reset.
                </Typography>
            </Box> : null
        }
        {
            errors ? 
            <Box sx={{ mt: 3 }}>
                <FormHelperText error>{'non_field_errors' in errors ? errors['non_field_errors'].map(each=>{
                    return each;
                }) :
                <ul>
                {Object.keys(errors).map((key) => {
                  const error = errors[key];
                  if (Array.isArray(error)) {
                    return (
                      <li style={{ fontSize: 12 }}>
                        {error}
                      </li>
                    );
                  } else {
                    return (
                      <li style={{ fontSize: 12 }}>
                        {Object.values(error).join(',')}
                      </li>
                    );
                  }
                })}
              </ul>}</FormHelperText>
            </Box> : null
        }
      </form>
    </>
  );
};

export default AuthResetPassword;