import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Grid, Typography, Button} from '@mui/material';

import { gridSpacing } from 'store/constant';

import InfluencerList from '../InfluencerList';

// import { getPlannerInfluencersES } from 'api_handlers/es';
import { fetchPlanInfluencers } from 'api_handlers/planner2';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';


const PlanInfluencers = ({match: { params }}) => {
  const [influencers, setInfluencers] = useState([]);
  const [count, setCount] = useState(0)
  const [platform, setPlatform] = useState("all")
  const [sortBy, setSortBy] = useState("followers");
  const [page, setPage] = useState(1)

  const {planUUID} = params
  const {listName} = params
  // const page = useSelector((state) => state.planner2.page) || 1;
  // const user = useSelector((state) => state.planner2.user)
  const location = useLocation();
  const message = location.state?.text || 'noAuth';
  const history = useHistory();

  // const fetchInfluencers = async (page) => {
  //   const response = await getPlannerInfluencersES(
  //     {
  //       "campaign-level-requirements": {},
  //       "creator-level-requirements": {},
  //       "advance-filters": {},
  //       "setReset": false
  //     }, page);
  //   const {results} = await response.json();
  //   setInfluencers(results);
  // }

  // const updateFilters = async () => {}

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
        console.log("platform PlanInfluencer List: ", platform)
      fetchPlanInfluencers(planUUID, platform, page, sortBy).then((response)=>{
        console.log("RESPONSE: ", response)
        setCount(response.count)
        setInfluencers(response.results.map(profile=>{
        return profile
      }));
    });
    // fetchInfluencers(1)
  }
  , [page, platform, sortBy]);

  const handleDiscoverClick = () => {
    history.push("/planner/discover");
  };

  const handlePlatformChange = (newPlatform) => {
    setPlatform(newPlatform);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
  };

  const noInfluencers = influencers.length === 0;

    return (
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={12}>
          <InfluencerList
            influencers={influencers}
            // fetchInfluencers={fetchInfluencers}
            totalResults={count}
            // updateFilters={updateFilters}
            isAuth={message}
            onPlatformChange={handlePlatformChange}
            noInfluencers={noInfluencers}
            listName={decodeURIComponent(listName)}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
          />
        </Grid>
      </Grid>
    )
}

export default PlanInfluencers;