import { Grid, Typography, withStyles } from '@material-ui/core';
import LocationIcon from 'assets/discovery/Location-Icon.png';
import MultiSelect from 'components/PlannerTheme/Elements/MultiSelect';
import { getCityStateList } from 'api_handlers/discovery';
import { useState, useEffect } from 'react';

function InfluencerState({ influencerState, hideAdvanceFilters, classes, influencerCountries }) {
  const [states, setStates] = useState([]);
  useEffect(() => {
    const fetchStates = async () => {
      try {
        // var occurrences = [];
        // for (var i = 0; i < influencerCountries.length; i++) {
        //   occurrences.push(influencerCountries[i]['value']);
        // }
        // const data = { country: occurrences };
        // console.log('countries', occurrences);
        const resp = await getCityStateList();
        console.log("States: ", resp)
        setStates(resp.data);
      } catch (error) {
        console.error('Error fetching states:', error);
      }
    };
    fetchStates();
  }, []);

  return (
    <Grid container direction="column">
      <Grid
        item
        style={{
          display: 'flex',
          marginBottom: 10,
          justifyContent: 'center',
        }}
      >
        <img src={LocationIcon} width={22} style={{ marginRight: 7 }} />
        <Typography className={classes.advanceHeading}>State</Typography>
      </Grid>
      <Grid item>
        <MultiSelect
          label={'Select State'}
          options={states.map((state) => ({label: state, value: state}))}
          value={influencerState || []}
          onChange={(event, selectedValues) => {
            hideAdvanceFilters('influencerState', selectedValues);
          }}
        />
      </Grid>
    </Grid>
  );
}

const styles = {
  advanceHeading: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 14,
  },
};

export default withStyles(styles)(InfluencerState);
