import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Button, Paper } from '@material-ui/core';

import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import FacebookLogin from '../../../components/auth/FacebookLogin';
import YoutubeLogin from '../../../components/auth/YoutubeLogin';

import { getInfluencerProfile, updateInfluencerProfile } from '../../../api_handlers/influencer.js';
import { authHandlers } from '../../../api_handlers/auth';

class ConnectSocialMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInstagramLinked: false,
      isYoutubeLinked: false,
    };
  }

  updateInstagramConnection() {
    this.setState({
      isInstagramLinked: true,
    });
    updateInfluencerProfile({
      is_instagram_linked: true,
    });
  }

  updateYoutubeConnection() {
    this.setState({
      isYoutubeLinked: true,
    });
    updateInfluencerProfile({
      is_youtube_linked: true,
    });
  }

  async registerToBackendFacebook(data) {
    const user = await authHandlers.facebookLogin(data);
    if (user) this.updateInstagramConnection();
  }

  async registerToBackendYoutube(data) {
    const user = await authHandlers.youtubeLogin(data);
    if (user) this.updateYoutubeConnection();
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const influencerData = await getInfluencerProfile();
    this.setState({
      isInstagramLinked: influencerData.is_instagram_linked,
      isYoutubeLinked: influencerData.is_youtube_linked,
    });
  }

  render() {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        style={{
          marginBottom: 30,
          marginTop: 30,
        }}
      >
        <Grid item md={10}>
          <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <FacebookLogin
                label="Connect your Facebook page"
                is_linked={this.state.isInstagramLinked}
                onSuccess={(response) => {
                  this.registerToBackendFacebook(response);
                }}
              />
            </Grid>
            {this.state.isInstagramLinked ? (
              <Grid item>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  style={{
                    color: 'green',
                  }}
                >
                  <Grid item>
                    <CheckCircleIcon />
                  </Grid>
                  <Grid item>
                    <span style={{ fontSize: 12 }}>connected!</span>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <YoutubeLogin
                label="Connect your Youtube Account"
                is_linked={this.state.isYoutubeLinked}
                onSuccess={(response) => {
                  this.registerToBackendYoutube(response);
                }}
              />
            </Grid>
            {this.state.isYoutubeLinked ? (
              <Grid item>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  style={{
                    color: 'green',
                  }}
                >
                  <Grid item>
                    <CheckCircleIcon />
                  </Grid>
                  <Grid item>
                    <span style={{ fontSize: 12 }}>connected!</span>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(ConnectSocialMedia);
