import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Filter from '../Filter';
import Slider from 'components/Theme/Elements/Slider';

import EngagementRateIcon from 'assets/discovery/Eng-Rate-icon.png';

function valuetext(value) {
  return `${value}%`;
}

function AudienceCredibilityFilter({ value = null, setFilters }) {
  const classes = useStyles();
  const [engRate, setEngRate] = useState(value);

  const handleFilter = (event, newValue) => {
    setFilters({
      audienceCredibility: newValue,
    });
  };

  const handleChange = (event, newValue) => {
    setEngRate(newValue);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Filter label={'Audience Credibility'} labelIconSrc={EngagementRateIcon}>
          <Grid container className={classes.filterContainer}>
            <Slider
              value={engRate}
              onChangeCommitted={handleFilter}
              onChange={handleChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={valuetext}
            />
          </Grid>
        </Filter>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {},
}));

export default AudienceCredibilityFilter;
