import { Component } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import InstagramMobileFeed from './InstagramMobileFeed';
import YoutubeMobileFeed from './YoutubeMobileFeed';

class Feed extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ marginTop: 20 }}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/youtube`}
            component={() => <YoutubeMobileFeed {...this.props} />}
          />
          <Route
            exact
            path={`${this.props.match.path}/instagram`}
            component={() => <InstagramMobileFeed {...this.props} />}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(Feed);
