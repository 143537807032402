import { Typography, Grid, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import WcIcon from '@material-ui/icons/Wc';

import InstagramRectIconButton from 'components/Discovery/components/InstagramRectIconButton';
import CategoryContainer from 'components/Discovery/components/CategoryContainer';

function BasicInfo({ name, picture, interests = [], url, audience_short_desc = {} }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item md={8}>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
          <Grid item md={'auto'} className={classes.avatarContainer}>
            <Avatar alt={name} src={picture} className={classes.avatar} />
          </Grid>
          <Grid item md={8}>
            <Grid container style={{ marginBottom: 20 }}>
              <Grid item>
                <Grid container>
                  <Typography variant="subtitle2">{name}</Typography>
                </Grid>
                <Grid container>
                  <Typography variant="body1">Micro Influencer</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <InstagramRectIconButton
                  onClick={() => {
                    window.open(url, '_blank');
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <CategoryContainer
                categories={(Array.isArray(interests) ? interests : []).map(
                  (interest) => interest.name,
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={3}>
        <Grid container className={classes.audienceInfoBase}>
          <Grid item md={12} className={classes.audienceInfo}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h4" style={{ color: 'white', fontWeight: 600 }}>
                  Audience Info
                </Typography>
              </Grid>
              <Grid item>
                <TrendingUpIcon />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 20 }}>
              <Grid item md={5}>
                <Grid container justifyContent="center" alignItems="center" direction="column">
                  <Grid item>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row "
                    >
                      <Grid item md={'auto'}>
                        <LocationOnIcon />
                      </Grid>
                      <Grid item md={'auto'}>
                        <Typography variant="h4" style={{ color: 'white' }}>
                          {audience_short_desc?.location?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4" style={{ color: 'white', fontWeight: 600 }}>
                      {audience_short_desc?.location?.percentage}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3}>
                <Grid container justifyContent="center" alignItems="center" direction="column">
                  <Grid item>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row "
                    >
                      <Grid item md={'auto'}>
                        <WcIcon />
                      </Grid>
                      <Grid item md={'auto'}>
                        <Typography variant="h4" style={{ color: 'white' }}>
                          {audience_short_desc?.gender?.code?.charAt(0)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4" style={{ color: 'white', fontWeight: 600 }}>
                      {audience_short_desc?.gender?.percentage}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4}>
                <Grid container justifyContent="center" alignItems="center" direction="column">
                  <Grid item>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row "
                    >
                      <Typography variant="h4" style={{ color: 'white' }}>
                        {`${audience_short_desc?.age?.code} yrs`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4" style={{ color: 'white', fontWeight: 600 }}>
                      {audience_short_desc?.age?.percentage}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarContainer: {
    background: '#EFF0F9',
    boxShadow:
      '-5px 5px 10px rgba(203, 204, 212, 0.2), 5px -5px 10px rgba(203, 204, 212, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(203, 204, 212, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(203, 204, 212, 0.5)',
    borderRadius: '50%',
    padding: 10,
  },
  avatar: {
    filter: 'drop-shadow(0px 5px 10px rgba(141, 141, 141, 0.25))',
    height: 150,
    width: 150,
  },
  audienceInfoBase: {
    background:
      'linear-gradient(118.02deg, rgba(239, 26, 116, 0.57) 32.63%, rgba(254, 189, 28, 0.63) 94.6%)',
    borderRadius: 10,
    height: 150,
  },
  audienceInfo: {
    background: 'rgba(239, 240, 249, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(27.2667px)',
    borderRadius: 10,
    color: 'white',
    padding: '10px 20px',
  },
}));

export default BasicInfo;
