import { Grid, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MinMaxInput from 'components/Theme/Elements/MinMaxInput';

import Filter from '../Filter';

import AudienceViewIcon from 'assets/discovery/Avg-View-icon.png';

function AudienceViewsFilter({ value = [null, null], setFilters }) {
  const classes = useStyles();

  function handleFilter(values) {
    setFilters({
      audienceView: values,
    });
  }

  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Filter label={'Average Reach'} labelIconSrc={AudienceViewIcon}>
          <Grid container className={classes.filterContainer}>
            <MinMaxInput value={value} onChange={handleFilter} />
          </Grid>
        </Filter>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  input: {
    background: '#EFF0F9',
    boxShadow:
      '-2px 2px 4px rgba(215, 216, 220, 0.2), 2px -2px 4px rgba(215, 216, 220, 0.2), -2px -2px 4px rgba(255, 255, 255, 0.9), 2px 2px 5px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 10,
    width: '100%',
    height: 40,
  },
  button: {
    background: '#000000',
    borderRadius: 10,
    padding: 7,
    color: 'white',
    marginRight: 2,
  },
  buttonIcon: {
    fontSize: '20px !important',
  },
}));

export default AudienceViewsFilter;
