import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { updateFilters } from 'actions/planner-filters';


const SelectFilter = ({title, payloadKey, selectOptions}) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => state.plannerFilters[payloadKey]);

  return (
    <Grid container spacing={2} alignItems='center' justifyContent='space-evenly' sx={{ mb: 2 }}>
          <Grid item xs={3}>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Select
              name={payloadKey}
              value={value}
              onChange={(event) => {
                dispatch(updateFilters(payloadKey, event.target.value))
              }}
              label={payloadKey}
              fullWidth
              size='small'
            >
              {selectOptions.map(option => {
                return <MenuItem value={option.value}>{option.label}</MenuItem>
              })}
            </Select>
          </Grid>
        </Grid>
  )
}

export default SelectFilter;