import { Typography, Grid, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// import AvatarImage from "assets/discovery/inf-avatar.png"
import GenderIcon from 'assets/discovery/Gender-icon.png';
import instaIcon from 'assets/discovery/insta-icon.png';
import YoutubeIcon from 'assets/discovery/youtube-icon.png';

function InfluencerInfo({ name, gender, language, picture, age_group, platform }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item md={4}>
        <Grid container direction="row">
          <Grid item md={10} style={{ maxWidth: '50%' }}>
            <img src={picture} style={{ width: '80%', borderRadius: '50%' }} />
          </Grid>
          <Grid item md={2} style={{ alignSelf: 'flex-end', marginBottom: '5%' }}>
            {platform == 'instagram' ? (
              <img src={instaIcon} style={{ width: '50%', borderRadius: '50%' }} />
            ) : (
              <img src={YoutubeIcon} style={{ width: '60%', borderRadius: '50%' }} />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={8}>
        <Grid container direction="column" className={classes.aboutContainer}>
          <Typography variant="h3" className={classes.influencerName}>
            {name}
          </Typography>
          <Typography className={classes.language}>{language}</Typography>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <img src={GenderIcon} alt="icon" className={classes.genderIcon} />
            </Grid>
            <Grid item>
              <Typography className={classes.genderInfo}>
                {gender == 'FEMALE' ? 'F' : 'M'} | {age_group} yrs
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    // background: '#FFFFFF',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  aboutContainer: {
    color: '#757575',
  },
  genderIcon: {
    height: 13,
    width: 13,
  },
  genderInfo: {
    fontSize: 11,
    color: '#757575',
  },
  avatar: {
    position: 'initial',
  },
  influencerName: {
    fontSize: 14,
    fontWeight: 400,
    color: 'black',
    textAlign: 'left',
  },
  language: {
    color: '#757575',
    textAlign: 'left',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 11,
  },
}));

export default InfluencerInfo;
