import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Checkbox, TextField, Button, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import {
  getLanguages,
  getFieldOfInterest,
  getInfluencerProfile,
  updateInfluencerProfile,
} from '../../../api_handlers/influencer.js';
import { formatDistanceToNow } from 'date-fns';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const styles = {
  font13: {
    fontSize: 13,
  },
  font12: {
    fontSize: 12,
  },
};

class AboutYourself extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languages: [],
      fieldOfInterest: [],
      selectedLanguages: [],
      selectedFieldOfInterest: [],
      aboutYourself: '',
    };
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
    this.loadData();
  }

  async loadData() {
    this.setState({
      languages: await getLanguages(),
      fieldOfInterest: await getFieldOfInterest(),
    });

    const influencerData = await getInfluencerProfile();
    this.setState({
      aboutYourself: influencerData.about,
      selectedLanguages: this.state.languages.filter((language) =>
        influencerData.language_known.includes(language.id),
      ),
      selectedFieldOfInterest: this.state.fieldOfInterest.filter((foi) =>
        influencerData.field_of_intrest.includes(foi.id),
      ),
    });
  }

  collectFormData() {
    let formData = {};

    if (Object.keys(this.state.selectedFieldOfInterest).length > 0) {
      formData['field_of_intrest'] = this.state.selectedFieldOfInterest.map((foi) => foi.id);
    }

    if (Object.keys(this.state.selectedLanguages).length > 0) {
      formData['language_known'] = this.state.selectedLanguages.map((language) => language.id);
    }

    if (this.state.aboutYourself) formData['about'] = this.state.aboutYourself;

    return formData;
  }

  async submitForm() {
    const formData = this.collectFormData();

    if (Object.keys(formData).length > 0) {
      const resp = await updateInfluencerProfile(formData);

      if (resp && this.props.callback) {
        this.props.callback();
      }
    } else if (this.props.callback) {
      this.props.callback();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <form
        noValidate
        autoComplete="off"
        style={{
          fontSize: 13,
          textAlign: 'left',
          width: '100%',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          style={{ marginBottom: 30 }}
        >
          <Grid item md={2} classes={{ root: classes.font12 }}>
            Field of Interest<sup>*</sup>
          </Grid>
          <Grid item md={1}>
            :
          </Grid>
          <Grid item md={7}>
            <Autocomplete
              id="tags-standard"
              multiple
              InputLabelProps={{ shrink: false }}
              options={this.state.fieldOfInterest}
              value={this.state.selectedFieldOfInterest}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </React.Fragment>
              )}
              style={{
                width: '100%',
                background: '#F9F9F9',
              }}
              renderInput={(params) => <TextField {...params} variant="standard" />}
              onChange={(event, selectedValues) => {
                this.setState({
                  selectedFieldOfInterest: selectedValues,
                });
              }}
              classes={{
                option: classes.font13,
                input: classes.font13,
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginBottom: 30 }}
        >
          <Grid item md={2} classes={{ root: classes.font12 }}>
            Language Known
          </Grid>
          <Grid item md={1}>
            :
          </Grid>
          <Grid item md={7}>
            <Autocomplete
              id="tags-standard"
              multiple
              InputLabelProps={{ shrink: false }}
              options={this.state.languages}
              value={this.state.selectedLanguages}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </React.Fragment>
              )}
              style={{
                width: '100%',
                background: '#F9F9F9',
              }}
              renderInput={(params) => <TextField {...params} variant="standard" />}
              onChange={(event, selectedValues) => {
                this.setState({
                  selectedLanguages: selectedValues,
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginBottom: 30 }}
        >
          <Grid item md={2} classes={{ root: classes.font12 }}>
            About Yourself
          </Grid>
          <Grid item md={1}>
            :
          </Grid>
          <Grid
            item
            md={7}
            classes={{
              root: classes.font13,
            }}
          >
            <TextField
              id="standard-basic"
              multiline
              rows={7}
              value={this.state.aboutYourself}
              InputLabelProps={{ shrink: false }}
              style={{
                width: '100%',
                background: '#F9F9F9',
                fontSize: 13,
              }}
              onChange={(event) => {
                this.setState({
                  aboutYourself: event.target.value,
                });
              }}
              InputProps={{
                style: {
                  fontSize: 13,
                  paddingLeft: 10,
                },
              }}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withStyles(styles)(AboutYourself);
