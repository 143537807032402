import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TextField from 'components/Theme/Elements/TextField';

function MinMaxInput({ value = [null, null], onChange }) {
  const classes = useStyles();
  const [minValue, setMinValue] = useState(value[0]);
  const [maxValue, setMaxValue] = useState(value[1]);

  function handleMinChange({ target }) {
    setMinValue(target.value);
    onChange([target.value, maxValue]);
  }

  function handleMaxChange({ target }) {
    setMaxValue(target.value);
    onChange([minValue, target.value]);
  }

  return (
    <Grid container className={classes.root}>
      <Grid item md={5}>
        <TextField
          placeholder="min"
          // className={classes.searchInput}
          value={minValue}
          onChange={handleMinChange}
        />
      </Grid>
      <Grid item md={5}>
        <TextField
          placeholder="max"
          // className={classes.searchInput}
          value={maxValue}
          onChange={handleMaxChange}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default MinMaxInput;
