import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { Grid, Container, Divider, Button } from '@material-ui/core';

import PersonalDetailForm from '../forms/PersonalDetails';
import AboutYourself from '../forms/AboutYourself';

class Personal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  async submitForm() {
    await this.personalDetailForm.submitForm();
    await this.aboutYourselfForm.submitForm();
  }

  render() {
    return (
      <Container maxWidth={800} style={{ marginLeft: 30 }}>
        <Grid container>
          <Grid item md={12}>
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="center"
              style={{
                justifyContent: 'space-between',
              }}
            >
              <Grid item md={2}>
                <span
                  style={{
                    fontSize: 15,
                  }}
                >
                  Personal Information
                </span>
              </Grid>
              <Grid item md={2}>
                <Button
                  onClick={() => {
                    this.submitForm();
                  }}
                  style={{
                    background: '#1E9CB2',
                    color: 'white',
                    padding: '5px 20px',
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={5}>
                <Divider
                  style={{
                    background:
                      'linear-gradient(90deg, rgba(171,171,171,1) 0%, rgba(176,176,176,0) 100%)',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 30 }}>
          <Grid item md={8}>
            <PersonalDetailForm
              onRef={(ref) => (this.personalDetailForm = ref)}
              fieldsToShow={[
                'profile_pic',
                'name',
                'dob',
                'gender',
                'addressLine',
                'state/city',
                'pincode',
              ]}
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 20, marginBottom: 10 }}>
          <Grid item>
            <span
              style={{
                fontSize: 15,
              }}
            >
              About Yourself
            </span>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 30 }}>
          <Grid item md={5}>
            <Divider
              style={{
                background:
                  'linear-gradient(90deg, rgba(171,171,171,1) 0%, rgba(176,176,176,0) 100%)',
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={8}>
            <AboutYourself onRef={(ref) => (this.aboutYourselfForm = ref)} />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(Personal);
