import { constants } from '../constants/discovery';

const initialState = {
  influencers: [],
  influencersCount: 0,
  payload: null,
  loading: false,
  filters: {
    sortBy: null,
    sortOrder: null,
    search: null,
    platform: null,
    followers: [null, null],
    category: null,
    engagementRate: null,
    location: null,
    audienceView: [null, null],
    gender: null,
    avgEngagement: [null, null],
    language: null,
    ppp: null,
    audienceLocation: null,
    audienceInterest: null,
    audienceGender: [null, null],
    audienceAgeGroup: [null, null],
    isSocialLogin: null,
    isEmail: null,
    isPhone: null,
    audienceCredibility: null,
    audienceReachability: [null, null],
    page: 1,
  },
};

export default function campaign(state = initialState, action) {
  switch (action.type) {
    case constants.GET_INFLUENCER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.GET_INFLUENCER_SUCCESS:
      const page = state.filters.page;
      return {
        ...state,
        loading: false,
        influencers:
          page === 1 ? [...action.data.results] : [...state.influencers, ...action.data.results],
        influencersCount: action.data.count,
        page: state.filters.page + 1,
        filters: {
          ...state.filters,
          page: state.filters.page + 1,
        },
      };
    case constants.GET_INFLUENCER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case constants.UPDATE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filter,
          // page: 1
        },
        // influencers: [],
        // influencersCount: 0,
      };
    case constants.RESET_FILTER:
      return {
        ...state,
        filters: { ...initialState.filters },
      };
    default:
      return state;
  }
}
