import { createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FEBD1C',
    },
    secondary: {
      main: '#FFFFFF',
    },
    info: {
      main: '#0095FF',
    },
    warning: {
      main: '#FEBD1C',
    },
    background: {
      default: '#E5E5E5',
    },
  },
  typography: {
    fontFamily: "'Nunito Sans', sans-serif",
    h3: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 600,
      fontSize: 20,
      letterSpacing: '0.03em',
      color: '#4A4A4A',
      textAlign: 'left',
    },
    h5: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 600,
      fontSize: 20,
      letterSpacing: '0.03em',
      color: '#4A4A4A',
      textAlign: 'left',
    },
    heading: {
      fontFamily: 'Nunito Sans',
      fontWeight: 400,
      fontSize: 20,
      lineHeight: 27,
    },
    heading1: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 600,
      fontSize: 20,
      letterSpacing: '0.03em',
      color: '#4A4A4A',
      textAlign: 'left',
    },
    subheading: {
      fontFamily: 'Nunito Sans',
      fontWeight: 400,
      fontSize: 20,
      lineHeight: 27,
      letterSpacing: '0.03em',
    },
    label: {
      fontFamily: 'Nunito Sans',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 22,
    },
    title: {
      fontFamily: 'Nunito Sans',
      fontWeight: 600,
      fontSize: 18,
      lineHeight: 25,
    },
    subtitle: {
      fontFamily: 'Nunito Sans',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
    },
    subtitle3: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
      letterSpacing: '0.0075em',
      color: '#4A4A4A',
    },
    deatil: {
      fontFamily: 'Nunito Sans',
      fontWeight: 600,
      fontSize: 10,
      lineHeight: 14,
    },
    value1: {
      fontFamily: 'Nunito Sans',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 27,
    },
    value2: {
      fontFamily: 'Nunito Sans',
      fontWeight: 400,
      fontSize: 18,
      lineHeight: 25,
    },
    category: {
      fontFamily: 'Nunito Sans',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
    },
  },
});

export default theme;
