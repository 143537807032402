import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Link from 'components/Theme/Elements/Link';
import ImageButton from 'components/Theme/Elements/ImageButton';

import Logo from 'assets/discovery/logo.png';
import BrandLogo from 'assets/discovery/brand-logo.png';

function Header() {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item>
        <img src={Logo} className={classes.logo} alt="logo" />
      </Grid>
      <Grid item>
        <Grid container spacing={5} className={classes.linkBox}>
          <Link>Dashboard</Link>
          <Link>My Campaign</Link>
          <Link>Influencers</Link>
          <Link selected>Discover</Link>
          <Link>Account</Link>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container className={classes.brandBox} spacing={2}>
          <Grid item>
            <Typography>Happilo</Typography>
          </Grid>
          <Grid item>
            <ImageButton imageSrc={BrandLogo} height={45} width={45} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    background: '#EFF0F9',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 224, 0.2), 5px -5px 10px rgba(215, 216, 224, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 224, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 224, 0.5)',
    borderRadius: 10,
    height: 60,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  logo: {
    height: 23,
  },
  brandBox: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  linkBox: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
}));

export default Header;
