import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

function Link({ children, selected }) {
  const classes = useStyles();
  return (
    <Grid item className={classNames(classes.root)}>
      <Typography className={selected ? classes.selected : classes.name}>{children}</Typography>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '3px !important',
    paddingBottom: '3px !important',
    cursor: 'pointer',
  },
  selected: {
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '27px',
    letterSpacing: '0.03em',
  },
  name: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '27px',
    letterSpacing: '0.03em',
  },
}));

export default Link;
