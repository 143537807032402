import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Filter from '../Filter';
import YesNoRadio from 'components/Theme/Elements/YesNoRadio';

import GenderIcon from 'assets/discovery/Gender-icon.png';

const options = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];

function GenderFilter({ value, setFilters }) {
  const classes = useStyles();

  const handleChange = ({ target }) => {
    setFilters({
      gender: target.value,
    });
  };

  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Filter label={'Influencer Gender'} labelIconSrc={GenderIcon}>
          <Grid container className={classes.filterContainer}>
            <YesNoRadio value={value} onChange={handleChange} options={options} />
          </Grid>
        </Filter>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  input: {
    background: '#EFF0F9',
    boxShadow:
      '-2px 2px 4px rgba(215, 216, 220, 0.2), 2px -2px 4px rgba(215, 216, 220, 0.2), -2px -2px 4px rgba(255, 255, 255, 0.9), 2px 2px 5px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 10,
    width: '100%',
    height: 40,
  },
  button: {
    background: '#000000',
    borderRadius: 10,
    padding: 7,
    color: 'white',
    marginRight: 2,
  },
  buttonIcon: {
    fontSize: '20px !important',
  },
}));

export default GenderFilter;
