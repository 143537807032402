import { AppBar, Box, Button, Grid, IconButton, Switch, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SearchIcon from 'assets/planner/search_icon.png';
import Filter from './components/Filter';
import Header from './components/Header/Header';
import InstagramInsights from './components/InstagramInsights';
import YoutubeInsights from './components/ytInsights';
import { downloadInstagramData, downloadYtData } from 'api_handlers/competitor';

import DateRangePicker from '../ux/DateRangePicker';
import { textAlign } from '@material-ui/system';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Insights({ classes }) {
  const { keyword, brand_id } = useParams();
  const [selectedFeedTab, setSelectedFeedTab] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [brandName, setBrandName] = useState('');
  const [loadData, setLoadData] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [textSearch, setTextSearch] = useState('')
  const [searchIconClicked, setSearchIconClicked] = useState(false);
  const [paidPost, setPaidPost] = useState(false);


  console.log(' = == = = keyword', keyword);

  const reloadData = async (brand_name) => {
    console.log('Brand NAme: ', brand_name);
    setBrandName(brand_name);
    setLoadData(false);
    if (brand_name && brand_name.length > 0) {
      setInfoModalOpen(true);
      setTimeout(() => {
        setLoadData(true);
      }, 1000);
    } else {
      setErrorModalOpen(true);
    }
  };

  const infoToggleModal = async () => {
    setInfoModalOpen(!infoModalOpen);
  };

  const errorToggleModal = async () => {
    setErrorModalOpen(!errorModalOpen);
  };

  const downloadData = async () => {
    if (startDate && endDate) {
      if (selectedFeedTab === 0) {
        try {
          console.log('brandName', keyword);
          const resp = await downloadInstagramData(keyword, startDate, endDate, searchKeyword);
        } catch (error) {
          console.error('Error fetching creators:', error);
        }
      } else if (selectedFeedTab === 1) {
        try {
          const resp = await downloadYtData(keyword, startDate, endDate, searchKeyword);
        } catch (error) {
          console.error('Error fetching creators:', error);
        }
      }
    }
  };

  function handleTabFunc(bool){
    if(bool || searchKeyword || startDate || endDate || paidPost){
      setSelectedFeedTab(0)
    }else{
      setSelectedFeedTab(1) 
    }
  }

  return (
    <Grid container style={{ background: 'white' }}>
      <Grid className={classes.base}>
        <Grid container className={classes.root}>
          <Grid item xs={12} xl={12}>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <Grid
                  container
                  justify="flex-end"
                  spacing={1}
                  style={{ marginTop: '0px' }}
                  alignItems="center"
                >
                  <Grid item md={12}>
                    <Header />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Filter reloadData={reloadData} />
            <Snackbar open={errorModalOpen} autoHideDuration={1500} onClose={errorToggleModal}>
              <Alert onClose={errorToggleModal} severity="error">
                Please Enter Brand Name Or Hashtags
              </Alert>
            </Snackbar>
            <Snackbar open={infoModalOpen} autoHideDuration={1500} onClose={infoToggleModal}>
              <Alert onClose={infoToggleModal} severity="info">
                We have received your request. Data will be available in next few hours.
              </Alert>
            </Snackbar>
          </Grid>
          {keyword ? (
            <Grid container>
              <Grid item md={12} style={{ marginTop: 45 }}>
                <AppBar
                  position="static"
                  indicatorColor="primary"
                  style={{
                    backgroundColor: '#F3F3F3',
                    color: '#333',
                    display: 'block',
                    marginTop: '20px',
                    marginBottom: '30px',
                  }}
                >
                  <Tabs
                    value={selectedFeedTab}
                    onChange={(event, value) => {
                      if (value >= 0) {
                        setSelectedFeedTab(value);
                      }
                    }}
                    aria-label="simple tabs example"
                  >
                    <Tab label="Instagram" id="instagram-tab" />
                    <Tab label="Youtube" id="youtube-tab" />
                    <Grid container justifyContent="flex-end">
                      <Grid item md={12}>
                        <Grid container justifyContent='flex-end'>
                          <Grid item className={classes.keywordContainer}>
                            <Grid container style={{height:'100%'}}  justifyContent='center' alignItems='center'>
                              <Grid item style={{textAlign:'center'}}>
                              <TextField
                                id="myTextField" 
                                InputProps={{
                                  disableUnderline: true,
                                  inputProps: {
                                    style: { textJustify: "center", paddingTop: 1 },
                                }
                                }}
                                value={textSearch}
                                className={classes.keywordField}
                                placeholder='Keyword'
                                onChange={(event)=>{
                                  setTextSearch(event.target.value)
                                }}  
                                onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                    setSearchKeyword(event.target.value);
                                    event.preventDefault();
                                  }
                                }}
                              >
                            </TextField>
                              </Grid>
                              <Grid style={{alignSelf:'center'}}>
                              <img src={SearchIcon} className={classes.icon} onClick={()=>{
                              // setSearchIconClicked(true);
                              setSearchKeyword(textSearch);
                              // setSelectedFeedTab(0);
                            }}/>
                              </Grid>
                            </Grid>
                          </Grid>
                        <Grid item md={2} style={{ paddingTop: 5,marginRight:140}}>
                          
                        <DateRangePicker
                          dateWidth="270px"
                          id="date-picker"
                          placement="left-end"
                          startDate={startDate}
                          endDate={endDate}
                          onSelect={async (result) => {
                            setStartDate(result[0].startDate);
                            setEndDate(result[0].endDate);
                          }}
                        ></DateRangePicker>
                      </Grid>
                      <Grid item md={2} style={{marginRight:10,paddingTop: 3 }}>
                        <Switch onChange={(e)=>{
                          setPaidPost(e.target.checked);
                        }}></Switch> Paid Post
                      </Grid>
                      <Grid item md={2} style={{marginRight:40,paddingTop: 3 }}>
                        <Button
                          onClick={downloadData}
                          className={classes.downloadButton}
                        >
                          Download
                        </Button>
                      </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Tabs>
                </AppBar>
                <TabPanel value={selectedFeedTab} index={0}>
                  <InstagramInsights
                    brand_id={brand_id}
                    brandName={keyword}
                    startDate={startDate}
                    endDate={endDate}
                    searchKeyword={searchKeyword}
                    paidPost={paidPost}
                    key={`${startDate}-${endDate}-${searchKeyword}-${paidPost}`}
                    handleTab={(bool)=>{
                      handleTabFunc(bool)
                    }}
                  />
                </TabPanel>
                <TabPanel value={selectedFeedTab} index={1}>
                  <YoutubeInsights
                    brand_id={brand_id}
                    brandName={keyword}
                    startDate={startDate}
                    endDate={endDate}
                    searchKeyword={searchKeyword}
                    paidPost={paidPost}
                    key={`${startDate}-${endDate}-${searchKeyword}-${paidPost}`}
                  />
                </TabPanel>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = (theme) => ({
  base: {
    background: 'white',
    // height: 700,
    width: '100%',
    margin: 'auto',
    marginTop: 0,
  },
  root: {
    background: 'white',
    filter: 'drop-shadow(5px 8px 15px rgba(171, 171, 171, 0.25))',
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
    paddingTop: 10,
  },
  downloadButton: {
    textTransform: 'none',
    width: 150,
    height: 39,
    fontWeight: 700,
    background: '#FEBD1C',
    borderRadius: '10px',
    color: 'white',
    fontSize: 16,
    marginRight: 30,
    filter: 'drop-shadow(5px 7px 7px rgba(186, 186, 186, 0.25))',
  },
  header: {
    color: '#4A4A4A',
    fontSize: '20px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    lineHeight: 1.167,
    letterSpacing: '0.03em',
    padding: '15px',
    background: '#F3F4FE',
    boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    borderRadius: '10px',
    marginBottom: '6px',
    marginTop: '35px',
  },

  card: {
    background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: '52px',
    paddingRight: '52px',
  },
  keywordContainer:{
    background:'white',
    border: '1px solid #C4C4C4',
    borderRadius: '10px',
    marginRight:30,
    height:35,
    marginTop:5,

  },
  keywordField:{
    background: '#FFFFFF',
    width: 200,
    height: 20,
    outline: 'none',
    marginLeft:20,
    marginRight:5,
    fontSize: 12,
    
  },
  icon: {
    height: 20,
    width: 20,
    cursor:'pointer',
    marginRight:20,
    width:15,
    height:15,
    marginTop:5
  },
});

export default withStyles(styles)(Insights);
