import { useState, useEffect } from 'react';
import { Typography, Grid, Modal, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CampaignIcon from 'assets/discovery/Campaign-Icon.png';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Button from 'components/Theme/Elements/Button';
import CampaignCard from './components/CampaignCard';

import { campaignHandlers } from 'api_handlers/campaign';

function AddToCampaignModal({
  campaignModalOpen = false,
  setCampaignModalOpen,
  selectedInfluencer,
  setSelectedInfluencer,
}) {
  const classes = useStyles();
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    campaignHandlers.getCampaigns().then((result) => {
      setCampaigns(result);
    });
  }, []);

  async function handleDone() {
    await setSelectedInfluencer([]);
    setCampaignModalOpen(false);
  }

  return (
    <Modal open={campaignModalOpen} onClose={setCampaignModalOpen} className={classes.base}>
      <Grid container className={classes.root}>
        <Grid item md={12}>
          <Grid container className={classes.header}>
            <Grid item md={3}>
              <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                  <Typography variant="h3">CAMPAIGNS</Typography>
                </Grid>
                <Grid item>
                  <img src={CampaignIcon} className={classes.campaignIcon} alt="icon" />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              className={classes.closeContainer}
              onClick={() => setCampaignModalOpen(false)}
            >
              <Grid container>
                <Grid item>
                  <Typography variant="h4">Close</Typography>
                </Grid>
                <Grid item>
                  <HighlightOffIcon className={classes.closeIcon} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container className={classes.body} spacing={1}>
            <Grid item md={12}>
              <Typography variant="h4">Select Campaign</Typography>
            </Grid>
            <Grid item md={12}>
              <Grid container spacing={2} className={classes.cardContainer}>
                {campaigns.map((campaign, i) => (
                  <Grid item md={12} key={i}>
                    <CampaignCard {...campaign} selectedInfluencer={selectedInfluencer} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleDone}>
                DONE
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

const useStyles = makeStyles(() => ({
  base: {
    background: 'white',
    height: 700,
    width: 900,
    margin: 'auto',
    marginTop: 50,
  },
  root: {
    background: '#F3F4FE',
    filter: 'drop-shadow(5px 8px 15px rgba(171, 171, 171, 0.25))',
    height: 700,
    width: 900,
    borderRadius: 10,
    padding: 40,
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  campaignIcon: {
    height: 30,
    width: 30,
  },
  divider: {
    border: '2px solid #7B61FF',
    borderRadius: 10,
    background: '#7B61FF',
  },
  body: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 25,
    height: 500,
  },
  cardContainer: {
    overflowY: 'scroll',
    height: 400,
  },
  closeContainer: {
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.9) 0%, #FBFCFF 100%)',
    boxShadow:
      '-1px 1px 2px rgba(215, 216, 220, 0.2), 1px -1px 2px rgba(215, 216, 220, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: 7,
    height: 30,
    cursor: 'pointer',
  },
  closeIcon: {
    fontSize: 14,
    color: '#757575',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 6,
  },
}));

export default AddToCampaignModal;
