import ImageButton from 'components/Theme/Elements/ImageButton';
import InstagramIcon from 'assets/discovery/insta-icon.png';

function InstagramRectIconButton({ ...props }) {
  return (
    <ImageButton imageSrc={InstagramIcon} height={45} width={45} varient="rectangle" {...props} />
  );
}

export default InstagramRectIconButton;
