import { useEffect, useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function IconLabel({ imageSrc, label }) {
  const classes = useStyles();
  const [dimensions, setDimensions] = useState({});

  useEffect(() => {
    const img = new Image();
    img.onload = function () {
      if (this.width > this.height) setDimensions({ width: 15 });
      else setDimensions({ height: 15 });
    };
    img.src = imageSrc;
  }, []);

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid item md={'auto'} className={classes.iconBox}>
        <img src={imageSrc} alt="icon" className={classNames(classes.icon)} style={dimensions} />
      </Grid>
      <Grid item md={8}>
        <Typography className={classes.label}>{label}</Typography>
      </Grid>
    </Grid>
  );
}

IconLabel.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'flex',
    alignItems: 'center',
  },
  iconBox: {},
  icon: {},
  label: {
    fontSize: '0.7em',
  },
}));

export default IconLabel;
