import { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import moment from 'moment';

import VideoGraph from '../../modals/VideoGraph';
import { getYoutubeFeeds } from '../../../../api_handlers/feeds';
import { getYoutubeFeedsColumns, postYoutubeFeedsColumns } from '../../../../api_handlers/campaign';
import { isBgmi } from 'components/utils';

const styles = (theme) => ({
  headerCell: {
    background: '#C4C4C4',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 12,
  },
  table: {
    minWidth: 650,
  },
  cell: {
    textAlign: 'center',
    fontSize: 12,
  },
});

const items = [
  'Post Type',
  'Cost Per View',
  'Cost Per Engagement',
  // "Views",
  // "Likes",
  // "Comments",
  'Impressions',
  'Estimated Minutes Watched',
  'Average View Duration(s)',
  'Shares',
  'Dislike Count',
  'Peak CCV',
  'Impression CTR',
  'Unique Viewers',
  'Watch Time',
  'Chat Msgs',
  'Audience Retention',
  // "Gender Split",
  // "Age Split",
  // "Geography Split",
  'Engagement Rate',
];

class PostTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feeds: [],
      count: 0,
      page: 1,
      loading: false,
      openVideoGraph: false,
      selectedVideo: null,
      orderBy: 'created_at',
      order: 'desc',
      columnsArray: [],
    };
  }

  async getData() {
    this.setState({
      loading: true,
    });
    const resp = await getYoutubeFeeds(this.state.page, {
      influencer_id: this.props.selectedInfluencer,
      start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
      end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
      allowedtablecolumns: this.props.allowedTableColumns,
      order_by: this.state.order === 'desc' ? '-' + this.state.orderBy : this.state.orderBy,
      language_ids:this.props.selectedLanguage
    });
    this.props.allowedTableColumns.length !== 0 ? this.postColumns() : this.getColumns();
    this.getColumns();
    this.setState({
      feeds: this.state.feeds.concat(resp.results),
      count: resp.count,
      loading: false,
    });
    this.props.getFeeds(this.state.feeds)

  }

  async reloadData() {
    await this.setState({
      feeds: [],
      count: 0,
      page: 1,
    });
    this.getData();
    this.props.getFeeds(this.state.feeds)


  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
    window.addEventListener('scroll', this.infiniteScroll);
    setTimeout(() => {
      this.getData(this.state.page);
    }, 30);
  }

  ellipsis(text) {
    if (text.length > 80) return text.slice(0, 77) + '...';
    return text;
  }

  infiniteScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      if (this.state.feeds.length < this.state.count && this.state.loading == false) {
        let newPage = this.state.page;
        newPage++;
        this.setState({
          page: newPage,
        });
        this.getData();
      }
    }
  };

  createSortHandler = (property) => async (event) => {
    await this.setState({
      orderBy: property,
      order: this.state.order === 'asc' ? 'desc' : 'asc',
    });
    this.reloadData();
  };

  async getColumns() {
    const colsGet = await getYoutubeFeedsColumns();
    this.setState({
      columnsArray: colsGet.youtube_columns,
    });
  }

  async postColumns() {
    const colsPost = await postYoutubeFeedsColumns({
      youtube_columns: this.props.allowedTableColumns,
    });
    // console.log(colsPost.youtube_columns)
  }
  
  render() {
    

    const { classes } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));
    return (
      <>
        <TableContainer component={Paper}>
          <Table stickyHeader className={classes.table} aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* {this.props.allowedTableColumns &&
                this.props.allowedTableColumns.includes("Post Name") ? ( */}
                <TableCell className={classes.headerCell}>Post Name</TableCell>
                {/* ) : (
                  ""
                )} */}
                <TableCell className={classes.headerCell}>Language</TableCell>

                {this.state.columnsArray.includes('Post Type') ||
                this.props.allowedTableColumns.includes('Post Type') ? (
                  <TableCell className={classes.headerCell}>Post Type</TableCell>
                ) : (
                  ''
                )}
                {/* {this.props.allowedTableColumns &&
                this.props.allowedTableColumns.includes("User") ? ( */}
                <TableCell className={classes.headerCell}>User</TableCell>
                {/* ) : (
                  ""
                )} */}
                {this.state.columnsArray.includes('Cost Per View') ||
                this.props.allowedTableColumns.includes('Cost Per View') ? (
                  <TableCell className={classes.headerCell}>Cost Per View</TableCell>
                ) : (
                  ''
                )}
                {this.state.columnsArray.includes('Cost Per Engagement') ||
                this.props.allowedTableColumns.includes('Cost Per Engagement') ? (
                  <TableCell className={classes.headerCell}>Cost Per Engagement</TableCell>
                ) : (
                  ''
                )}
                {/* {this.state.columnsArray.includes("Views") ||
                this.props.allowedTableColumns.includes("Views") ? ( */}
                <TableCell className={classes.headerCell}>
                  <TableSortLabel
                    active={this.state.orderBy === 'view_count'}
                    direction={this.state.orderBy === 'view_count' ? this.state.order : 'desc'}
                    onClick={this.createSortHandler('view_count')}
                  >
                    View
                  </TableSortLabel>
                </TableCell>
                {/* ) : (
                  ""
                )} */}
                {/* {this.state.columnsArray.includes("Likes") ||
                this.props.allowedTableColumns.includes("Likes") ? ( */}
                <TableCell className={classes.headerCell}>
                  <TableSortLabel
                    active={this.state.orderBy === 'like_count'}
                    direction={this.state.orderBy === 'like_count' ? this.state.order : 'desc'}
                    onClick={this.createSortHandler('like_count')}
                  >
                    Likes
                  </TableSortLabel>
                </TableCell>
                {/* ) : (
                  ""
                )} */}
                {/* {this.state.columnsArray.includes("Comments") ||
                this.props.allowedTableColumns.includes("Comments") ? ( */}
                <TableCell className={classes.headerCell}>
                  <TableSortLabel
                    active={this.state.orderBy === 'comment_count'}
                    direction={this.state.orderBy === 'comment_count' ? this.state.order : 'desc'}
                    onClick={this.createSortHandler('comment_count')}
                  >
                    Comments
                  </TableSortLabel>
                </TableCell>
                {/* ) : (
                  ""
                )} */}
                {this.state.columnsArray.includes('Impressions') ||
                this.props.allowedTableColumns.includes('Impressions') ? (
                  <TableCell className={classes.headerCell}>
                    <TableSortLabel
                      active={this.state.orderBy === 'view_count'}
                      direction={this.state.orderBy === 'view_count' ? this.state.order : 'desc'}
                      onClick={this.createSortHandler('view_count')}
                    >
                      Impressions
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  ''
                )}
                {this.state.columnsArray.includes('Estimated Minutes Watched') ||
                this.props.allowedTableColumns.includes('Estimated Minutes Watched') ? (
                  <TableCell className={classes.headerCell}>
                    <TableSortLabel
                      active={this.state.orderBy === 'estimatedminuteswatched'}
                      direction={
                        this.state.orderBy === 'estimatedminuteswatched' ? this.state.order : 'desc'
                      }
                      onClick={this.createSortHandler('estimatedminuteswatched')}
                    >
                      Estimated Minutes Watched
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  ''
                )}
                {this.state.columnsArray.includes('Average View Duration(s)') ||
                this.props.allowedTableColumns.includes('Average View Duration(s)') ? (
                  <TableCell className={classes.headerCell}>
                    <TableSortLabel
                      active={this.state.orderBy === 'averageviewduration'}
                      direction={
                        this.state.orderBy === 'averageviewduration' ? this.state.order : 'desc'
                      }
                      onClick={this.createSortHandler('averageviewduration')}
                    >
                      Average View Duration(s)
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  ''
                )}
                {this.state.columnsArray.includes('Shares') ||
                this.props.allowedTableColumns.includes('Shares') ? (
                  <TableCell className={classes.headerCell}>
                    <TableSortLabel
                      active={this.state.orderBy === 'share_count'}
                      direction={this.state.orderBy === 'share_count' ? this.state.order : 'desc'}
                      onClick={this.createSortHandler('share_count')}
                    >
                      Shares
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  ''
                )}

                {this.state.columnsArray.includes('Dislike Count') ||
                this.props.allowedTableColumns.includes('Dislike Count') ? (
                  <TableCell className={classes.headerCell}>Dislike Count</TableCell>
                ) : (
                  ''
                )}

                {this.state.columnsArray.includes('Engagement Rate') ||
                this.props.allowedTableColumns.includes('Engagement Rate') ? (
                  <TableCell className={classes.headerCell}>Engagement Rate</TableCell>
                ) : (
                  ''
                )}

                {this.state.columnsArray.includes('Peak CCV') ||
                this.props.allowedTableColumns.includes('Peak CCV') ? (
                  <TableCell className={classes.headerCell}>Peak CCV</TableCell>
                ) : (
                  ''
                )}

                {this.state.columnsArray.includes('Impression CTR') ||
                this.props.allowedTableColumns.includes('Impression CTR') ? (
                  <TableCell className={classes.headerCell}>Impression CTR</TableCell>
                ) : (
                  ''
                )}

                {this.state.columnsArray.includes('Unique Viewers') ||
                this.props.allowedTableColumns.includes('Unique Viewers') ? (
                  <TableCell className={classes.headerCell}>Unique Viewers</TableCell>
                ) : (
                  ''
                )}

                {this.state.columnsArray.includes('Watch Time') ||
                this.props.allowedTableColumns.includes('Watch Time') ? (
                  <TableCell className={classes.headerCell}>Watch Time</TableCell>
                ) : (
                  ''
                )}

                {this.state.columnsArray.includes('Chat Msgs') ||
                this.props.allowedTableColumns.includes('Chat Msgs') ? (
                  <TableCell className={classes.headerCell}>Chat Msgs</TableCell>
                ) : (
                  ''
                )}

                {this.state.columnsArray.includes('Audience Retention') ||
                this.props.allowedTableColumns.includes('Audience Retention') ? (
                  <TableCell className={classes.headerCell}>Audience Retention</TableCell>
                ) : (
                  ''
                )}
                {this.state.columnsArray.includes('Gender Split') ||
                this.props.allowedTableColumns.includes('Gender Split') ? (
                  <TableCell className={classes.headerCell}>Gender Split</TableCell>
                ) : (
                  ''
                )}
                {this.state.columnsArray.includes('Age Split') ||
                this.props.allowedTableColumns.includes('Age Split') ? (
                  <TableCell className={classes.headerCell}>Age Split</TableCell>
                ) : (
                  ''
                )}
                {this.state.columnsArray.includes('Geography Split') ||
                this.props.allowedTableColumns.includes('Geography Split') ? (
                  <TableCell className={classes.headerCell}>Geography Split</TableCell>
                ) : (
                  ''
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.feeds.map((feed) =>(
                
                <TableRow key={feed.name}>
                  {/* {this.props.allowedTableColumns &&
                  this.props.allowedTableColumns.includes("Post Name") ? ( */}
                  <TableCell component="th" scope="feed" className={classes.cell}>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item={3}>
                        <img src={feed.thumbnail} height={48} weight={48} />
                      </Grid>
                      <Grid item={8}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                        >
                          <Grid
                            item
                            md={12}
                            style={{
                              wordBreak: 'normal',
                              width: 180,
                              textAlign: 'left',
                            }}
                          >
                            <span>
                              <a
                                href={feed.url}
                                target="_blank"
                                style={{
                                  color: '#111111',
                                  textDecoration: 'none',
                                }}
                              >
                                {this.ellipsis(feed.title)}
                              </a>
                            </span>
                          </Grid>
                          <Grid item md={12}>
                            <span>
                              <i>Added on: {feed.added_on}</i>
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item={1}>
                        <TrendingUpIcon
                          onClick={async () => {
                            await this.setState({
                              openVideoGraph: true,
                              selectedVideo: feed.id,
                            });
                            this.videoGraphModal.getData();
                          }}
                        />
                      </Grid>
                    </Grid>
                    {feed.name}
                  </TableCell>
                  <TableCell className={classes.cell}>
                      {feed.language?feed.language:'-'}
                  </TableCell>
                  {this.state.columnsArray.includes('Post Type') ||
                  this.props.allowedTableColumns.includes('Post Type') ? (
                    <TableCell className={classes.cell}>{feed.post_type}</TableCell>
                  ) : (
                    ''
                  )}
                  {/* {this.props.allowedTableColumns &&
                  this.props.allowedTableColumns.includes("User") ? ( */}
                 

                  <TableCell className={classes.cell}>
                    <a href={'/influencer/campaigns/all/feeds/' + feed.influencer_id + '/youtube'}>
                      {feed.user_name}
                    </a>
                  </TableCell>

                  {this.state.columnsArray.includes('Cost Per View') ||
                  this.props.allowedTableColumns.includes('Cost Per View') ? (
                    <TableCell className={classes.cell}>{feed.cpv}</TableCell>
                  ) : (
                    ''
                  )}
                  {this.state.columnsArray.includes('Cost Per Engagement') ||
                  this.props.allowedTableColumns.includes('Cost Per Engagement') ? (
                    <TableCell className={classes.cell}>{feed.cpe}</TableCell>
                  ) : (
                    ''
                  )}
                  {/* {this.state.columnsArray.includes("Views") ||
                  this.props.allowedTableColumns.includes("Views") ? ( */}
                  <TableCell className={classes.cell}>{feed.view_count}</TableCell>
                  {/* ) : (
                    ""
                  )} */}
                  {/* {this.state.columnsArray.includes("Likes") ||
                  this.props.allowedTableColumns.includes("Likes") ? ( */}
                  <TableCell className={classes.cell}>{feed.like_count}</TableCell>
                  {/* ) : (
                    ""
                  )} */}
                  {/* {this.state.columnsArray.includes("Comments") ||
                  this.props.allowedTableColumns.includes("Comments") ? ( */}
                  <TableCell className={classes.cell}>{feed.comment_count}</TableCell>
                  {/* ) : (
                    ""
                  )} */}
                  {this.state.columnsArray.includes('Impressions') ||
                  this.props.allowedTableColumns.includes('Impressions') ? (
                    <TableCell className={classes.cell}>
                      {isBgmi(user) ? feed.impression_count : 'N/A'}
                    </TableCell>
                  ) : (
                    ''
                  )}
                  {this.state.columnsArray.includes('Estimated Minutes Watched') ||
                  this.props.allowedTableColumns.includes('Estimated Minutes Watched') ? (
                    <TableCell className={classes.cell}>{feed.estimatedminuteswatched}</TableCell>
                  ) : (
                    ''
                  )}
                  {this.state.columnsArray.includes('Average View Duration(s)') ||
                  this.props.allowedTableColumns.includes('Average View Duration(s)') ? (
                    <TableCell className={classes.cell}>{feed.averageviewduration}</TableCell>
                  ) : (
                    ''
                  )}
                  {this.state.columnsArray.includes('Shares') ||
                  this.props.allowedTableColumns.includes('Shares') ? (
                    <TableCell className={classes.cell}>{feed.share_count}</TableCell>
                  ) : (
                    ''
                  )}

                  {this.state.columnsArray.includes('Dislike Count') ||
                  this.props.allowedTableColumns.includes('Dislike Count') ? (
                    <TableCell className={classes.cell}>{feed.dislike_count}</TableCell>
                  ) : (
                    ''
                  )}

                  {this.state.columnsArray.includes('Engagement Rate') ||
                  this.props.allowedTableColumns.includes('Engagement Rate') ? (
                    <TableCell className={classes.cell}>{feed.engagement_rate}</TableCell>
                  ) : (
                    ''
                  )}

                  {this.state.columnsArray.includes('Peak CCV') ||
                  this.props.allowedTableColumns.includes('Peak CCV') ? (
                    <TableCell className={classes.cell}>{feed.peak_ccv}</TableCell>
                  ) : (
                    ''
                  )}

                  {this.state.columnsArray.includes('Impression CTR') ||
                  this.props.allowedTableColumns.includes('Impression CTR') ? (
                    <TableCell className={classes.cell}>{feed.impression_ctr}</TableCell>
                  ) : (
                    ''
                  )}

                  {this.state.columnsArray.includes('Unique Viewers') ||
                  this.props.allowedTableColumns.includes('Unique Viewers') ? (
                    <TableCell className={classes.cell}>{feed.unique_viewers}</TableCell>
                  ) : (
                    ''
                  )}

                  {this.state.columnsArray.includes('Watch Time') ||
                  this.props.allowedTableColumns.includes('Watch Time') ? (
                    <TableCell className={classes.cell}>{feed.watch_time}</TableCell>
                  ) : (
                    ''
                  )}

                  {this.state.columnsArray.includes('Chat Msgs') ||
                  this.props.allowedTableColumns.includes('Chat Msgs') ? (
                    <TableCell className={classes.cell}>{feed.chat_msgs}</TableCell>
                  ) : (
                    ''
                  )}

                  {this.state.columnsArray.includes('Audience Retention') ||
                  this.props.allowedTableColumns.includes('Audience Retention') ? (
                    <TableCell className={classes.cell}>{feed.audience_ret}</TableCell>
                  ) : (
                    ''
                  )}

                  {this.state.columnsArray.includes('Gender Split') ||
                  this.props.allowedTableColumns.includes('Gender Split') ? (
                    <TableCell className={classes.cell}>{feed.gender_split_data}</TableCell>
                  ) : (
                    ''
                  )}

                  {this.state.columnsArray.includes('Age Split') ||
                  this.props.allowedTableColumns.includes('Age Split') ? (
                    <TableCell className={classes.cell}>{feed.age_split_data}</TableCell>
                  ) : (
                    ''
                  )}

                  {this.state.columnsArray.includes('Geography Split') ||
                  this.props.allowedTableColumns.includes('Geography Split') ? (
                    <TableCell className={classes.cell}>{feed.location_split}</TableCell>
                  ) : (
                    ''
                  )}
                </TableRow>
              )
                  )}
            </TableBody>
          </Table>
        </TableContainer>
        <VideoGraph
          onRef={(ref) => (this.videoGraphModal = ref)}
          campaignId={this.props.campaignId}
          open={this.state.openVideoGraph}
          handleClose={() => {
            this.setState({ openVideoGraph: false });
          }}
          videoId={this.state.selectedVideo}
        />
      </>
    );
  }
}

// export default withStyles(styles, {})(PostTable);

const mapStateToProps = (state) => {
  return {
    // allowedTableColumns: state.brand.displayPanels?.feeds.table_columns,
  };
};

export default connect(mapStateToProps)(withStyles(styles, {})(PostTable));
