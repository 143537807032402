import React from 'react';
import { Grid } from '@material-ui/core';

import CountDisplay from '../../ux/CountDisplay';

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import UserIcon from '../../../assets/User.png';
import VideoCamera from '../../../assets/users_group.png'
// import ViewIcon from '../../../assets/View.png';
import EngagementIcon from '../../../assets/Engagement.png';
import EngagementRateIcon from '../../../assets/EngagementRate.png';
import CPVIcon from '../../../assets/CPV.png';
import CPEIcon from '../../../assets/CPE.png';

class SocialMediaSummery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      followers:
        this.props.socialPlatform == 'instagram'
          ? this.props.instagram_follower_count
          : this.props.youtube_subscribers,
      avg_views:
        this.props.socialPlatform == 'instagram'
          ? this.props.instagram_avg_views
          : this.props.youtube_avg_views,
      avg_engagement:
        this.props.socialPlatform == 'instagram'
          ? this.props.instagram_avg_engagement
          : this.props.youtube_avg_engagement,
      engagement_rate:
        this.props.socialPlatform == 'instagram'
          ? this.props.instagram_engagement_rate
          : this.props.youtube_engagement_rate,
      estimated_cpv:
        this.props.socialPlatform == 'instagram'
          ? this.props.instagram_estimated_cpv
          : this.props.youtube_estimated_cpv,
      estimated_cpe:
        this.props.socialPlatform == 'instagram'
          ? this.props.instagram_estimated_cpe
          : this.props.youtube_estimated_cpe,
      follower_label: this.props.socialPlatform == 'instagram' ? 'Followers' : 'Subscribers',
    };
  }
  render() {
    // console.log(this.props,this.props.socialPlatform == 'instagram',this.state.followers,this.onChange());
    return (
      <Grid
        container
        justify="space-evenly"
        alignItems="center"
        style={{
          background: '#F2F2F2',
          height: '80px',
          paddingLeft: '20px',
          marginTop: '10px',
        }}
      >
        <Grid item md={2}>
          <CountDisplay
            icon={UserIcon}
            label={this.state.follower_label}
            count={this.state.followers}
            color={'#7158F1'}
          />
        </Grid>
        <Grid item md={2}>
          <CountDisplay
            // icon={ViewIcon}
            icon={VideoCamera}
            label={'Avg View'}
            count={this.state.avg_views}
            color={'#7158F1'}
            width={'29px'}
            height={'30px'}
          />
        </Grid>
        <Grid item md={2}>
          <CountDisplay
            icon={EngagementIcon}
            label={'Avg Engagement'}
            count={this.state.avg_engagement}
            color={'#7158F1'}
          />
        </Grid>
        <Grid item md={2}>
          <CountDisplay
            icon={EngagementRateIcon}
            label={'Engagement Rate'}
            count={this.state.engagement_rate}
            color={'#7158F1'}
          />
        </Grid>
        <Grid item md={2}>
          <CountDisplay
            icon={CPVIcon}
            label={'Estimated CPV'}
            count={this.state.estimated_cpv}
            color={'#7158F1'}
          />
        </Grid>
        <Grid item md={2}>
          <CountDisplay
            icon={CPEIcon}
            label={'Estimated CPE'}
            count={this.state.estimated_cpe}
            color={'#7158F1'}
          />
        </Grid>
      </Grid>
    );
  }
}

export default SocialMediaSummery;
