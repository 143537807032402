import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DropDownSlider from 'components/Theme/Elements/DropDownSlider';

import Filter from '../Filter';

import FollowersIcon from 'assets/discovery/followers-icon.png';

const options = [
  { label: '<500', value: '-500' },
  { label: '500-1K', value: '500-1000' },
  { label: '1K-1.5k', value: '1000-1500' },
  { label: '>1.5K', value: '1500-' },
];

function AudienceGenderSplitFilter({ value = [null, null], setFilters }) {
  const classes = useStyles();

  function handleFilter(values) {
    setFilters({
      audienceReachability: values,
    });
  }

  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Filter label={'Audience Reachability'} labelIconSrc={FollowersIcon}>
          <Grid container className={classes.filterContainer}>
            <DropDownSlider value={value} onChange={handleFilter} options={options} />
          </Grid>
        </Filter>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {},
}));

export default AudienceGenderSplitFilter;
