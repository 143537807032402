import React from 'react';

import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';


import HttpsIcon from '@mui/icons-material/Https';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';


const StyledTypography = styled(Typography)({
  wordWrap: 'break-word',
  // Alternatively, you can use overflowWrap
  // overflowWrap: 'break-word',
});

function formatNumber(num) {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(0) + 'B';
  }
  if (num >= 1e6) {
    return (num / 1e6).toFixed(0) + 'M';
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(0) + 'K';
  }
  return num.toString();
}

const CreatorTypeSliderFilter = ({title, payloadKey, value, mainBudget, locked, updateLock, onValueChange}) => {
  const LockIcon = locked ? HttpsIcon : LockOpenIcon;

  const toggleLock = () => {
    updateLock(!locked);
  }

  return (
    <Grid container alignItems={'center'} justifyContent={'space-evenly'} spacing={2}>
      <Grid item xs={11}>
      <Grid container spacing={2} alignItems='center' justifyContent='space-evenly' sx={{ mb: 2 }}>
          <Grid item xs={3}>
            <StyledTypography variant="h6" gutterBottom>
              {title}
            </StyledTypography>
          </Grid>
          <Grid item xs={6}>
            <Slider
              value={value}
              onChange={(event, newValue) => {
                onValueChange && onValueChange(newValue)
              }}
              valueLabelDisplay="auto"
              getAriaValueText={(value) => formatNumber(value)}
              valueLabelFormat={(value) => formatNumber(value)}
              max={mainBudget}
              min={0}
              step={parseInt(mainBudget / 1000)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField size='small' value={value} endAdornment={
                <InputAdornment position="end">
                  <CurrencyRupeeIcon />
                </InputAdornment>
              }
              onChange={(event) => {
                onValueChange && onValueChange(parseInt(event.target.value))
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} sx={{pb: 2}}>
        <LockIcon fontSize='medium' color='primary' onClick={toggleLock} sx={{cursor: 'pointer'}}/>
      </Grid>
    </Grid>
  )
}

export default CreatorTypeSliderFilter;