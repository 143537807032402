import { Component } from 'react';
import {
  Typography,
  Button,
  Grid,
  Modal,
  TextField,
  FormControl,
  NativeSelect,
  InputBase,
  MenuItem,
  AppBar,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import Statistics from './cards/instagram/Statistics';
import EngagementStatistics from './cards/instagram/EngagementStatistics';
import InstagramPostTable from './cards/instagram/InstagramPostTable';
import { withStyles } from '@material-ui/core/styles';
import { colorConstants } from '../../../constants/colors';
import { campaignHandlers } from '../../../api_handlers/campaign';
import Loading from '../../../components/Common/Loading/Loading';
import { getInfluencerInstagramStatistic } from '../../../api_handlers/reporting';
import AddPost from '../modals/AddPost';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FFF',
    border: '0.5px solid #C8C8C8',
    borderRadius: '5px',
    fontSize: 11,
    paddingLeft: '15px',
    height: '20px',
    width: '100px',
    margin: '2px',
  },
}))(InputBase);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class InstagramDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      info_modalOpen: false,
      message: null,
      is_loading: false,
      user_type: JSON.parse(localStorage.getItem('user')).user_type,
      stats: null,
      addPostModalOpen: false,
      selectedFeedTab: 0,
    };
  }
  toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen });
  info_toggleModal = () => this.setState({ info_modalOpen: !this.state.info_modalOpen });

  async addPostLink(url) {
    this.setState({
      is_loading: true,
    });
    const post_data = {
      action_type: 'instagram',
      campaign_id: this.props.match.params.campaignId,
      url,
    };
    try {
      await campaignHandlers.addPostLink(post_data).then((r) => {
        this.setState({
          info_modalOpen: true,
          message: r.message,
          is_loading: false,
        });
      });
    } catch (error) {
      this.setState({
        is_loading: false,
        info_modalOpen: true,
        message: 'Something went wrong',
      });
    }
    if (this.state.message == 'Updated') {
      window.location.reload();
    }
  }

  async getData() {
    const campaign_id = this.props.match.params.campaignId;
    const influencer_id = this.props.match.params.influencerId;
    const resp = await getInfluencerInstagramStatistic(campaign_id, influencer_id);
    this.setState({ stats: resp.data });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  render() {
    return (
      <Grid container spacing={1}>
        {this.state.is_loading && <Loading />}
        <Grid item xs={12} xl={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12}>
              <Grid container spacing={1}>
                <Grid item md={4}>
                  <EngagementStatistics
                    campaign_id={this.props.match.params.campaignId}
                    influencer_id={this.props.match.params.influencerId}
                    stats={this.state.stats}
                  />
                </Grid>
                <Grid item md={8}>
                  <Statistics
                    extraMargin={20}
                    campaign_id={this.props.match.params.campaignId}
                    influencer_id={this.props.match.params.influencerId}
                    stats={this.state.stats}
                  />
                </Grid>
                <Grid item md={12}>
                  <Grid
                    container
                    justify="flex-end"
                    spacing={4}
                    style={{ marginTop: '0px' }}
                    alignItems="center"
                  >
                    {this.state.user_type == 'brand' ? (
                      <Grid
                        item
                        md={1}
                        style={{
                          fontSize: 11,
                        }}
                      >
                        <span>Filter by:</span>
                      </Grid>
                    ) : (
                      ''
                    )}
                    {this.state.user_type == 'brand' ? (
                      <Grid
                        item
                        md={3}
                        style={{
                          paddingLeft: 0,
                          paddingRight: 10,
                        }}
                      >
                        <FormControl>
                          <NativeSelect
                            // value={age}
                            // onChange={handleChange}
                            input={<BootstrapInput />}
                          >
                            <option value={'date_range'}>Date Range</option>
                          </NativeSelect>
                        </FormControl>
                        <FormControl>
                          <NativeSelect
                            // value={age}
                            // onChange={handleChange}
                            input={<BootstrapInput />}
                          >
                            <option value={'post_type'}>Post Type</option>
                          </NativeSelect>
                        </FormControl>
                      </Grid>
                    ) : (
                      ''
                    )}
                    {this.state.user_type != 'brand' ? (
                      <Grid
                        item
                        md={2}
                        style={{
                          paddingLeft: 0,
                          paddingRight: '10px',
                        }}
                      >
                        {/* <Button
                                                variant="contained"
                                                style={{
                                                    color: "white",
                                                    backgroundColor: "#1E9CB2",
                                                    marginRight: "10px",
                                                }}
                                                onClick={this.toggleModal}
                                            >
                                                Add Post Link
                                            </Button> */}
                        <div>
                          <Modal
                            open={this.state.modalOpen}
                            onClose={this.toggleModal}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                          >
                            <div
                              style={{
                                backgroundColor: 'white',
                                width: '50vw',
                                transform: 'translate(50%, 40vh)',
                                paddingLeft: '10px',
                              }}
                              align={'left'}
                            >
                              <h3
                                style={{
                                  paddingTop: '10px',
                                }}
                              >
                                Add Post
                              </h3>
                              <hr
                                style={{
                                  width: '99%',
                                }}
                              />
                              <Grid container spacing={2}>
                                <Grid item md={12}>
                                  Paste your Instagram post URL to add it to the Campaign
                                </Grid>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  style={{
                                    padding: '10px',
                                  }}
                                >
                                  <Grid item md={2} alignItems="flex-end">
                                    <p>URL:</p>
                                  </Grid>
                                  <Grid item md={9}>
                                    <TextField
                                      id="post_url"
                                      style={{
                                        margin: 8,
                                      }}
                                      placeholder="Paste URL"
                                      fullWidth
                                      margin="normal"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      variant="outlined"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <br />
                              <div
                                style={{
                                  paddingBottom: '18px',
                                  paddingTop: '20px',
                                }}
                                align={'center'}
                              >
                                <Button
                                  variant="outlined"
                                  style={{
                                    color: colorConstants.TEXT_BLUE,
                                    borderColor: colorConstants.TEXT_BLUE,
                                    marginRight: '10px',
                                  }}
                                  onClick={this.toggleModal}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: colorConstants.TEXT_BLUE,
                                    color: 'white',
                                  }}
                                  onClick={() =>
                                    this.addPostLink(document.getElementById('post_url').value)
                                  }
                                >
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </Modal>
                        </div>
                        <div>
                          <Snackbar
                            open={this.state.info_modalOpen}
                            autoHideDuration={5000}
                            onClose={this.info_toggleModal}
                          >
                            <Alert onClose={this.info_toggleModal} severity="info">
                              {this.state.message}
                            </Alert>
                          </Snackbar>
                        </div>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Grid container>
                    <Grid item md={12}>
                      <AppBar
                        position="static"
                        indicatorColor="primary"
                        style={{
                          backgroundColor: '#F3F3F3',
                          color: '#333',
                          display: 'block',
                        }}
                      >
                        <Tabs
                          value={this.state.selectedFeedTab}
                          onChange={(event, value) => {
                            this.setState({ selectedFeedTab: value });
                          }}
                          aria-label="simple tabs example"
                        >
                          <Tab label="Feeds" id="feed-tab" />
                          <Tab label="Stories" id="story-tab" />
                          <Button
                            variant="contained"
                            size="small"
                            style={{
                              color: 'white',
                              backgroundColor: '#1E9CB2',
                              marginRight: '10px',
                              position: 'absolute',
                              right: '15px',
                              top: '9px',
                            }}
                            onClick={() => {
                              this.setState({ addPostModalOpen: !this.state.addPostModalOpen });
                            }}
                          >
                            Add Post
                          </Button>
                          <AddPost
                            modalOpen={this.state.addPostModalOpen}
                            onClose={() => {
                              this.setState({ addPostModalOpen: false });
                            }}
                            campaignId={this.props.match.params.campaignId}
                          />
                        </Tabs>
                      </AppBar>
                      <TabPanel value={this.state.selectedFeedTab} index={0}>
                        <InstagramPostTable storyTab={false} {...this.props} />
                      </TabPanel>
                      <TabPanel value={this.state.selectedFeedTab} index={1}>
                        <InstagramPostTable storyTab={true} {...this.props} />
                      </TabPanel>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default InstagramDashboard;
