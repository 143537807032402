import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { gridSpacing } from 'store/constant';

import SliderFilter from './SliderFilter'
import AutocompleteFilter from './AutocompleteFilter';

import { getLanguages } from 'api_handlers/influencer';
import { getDeliverables } from 'api_handlers/brand';
import { getPlannerCountries, getCityStateList } from 'api_handlers/discovery';

import { updateFilters } from 'actions/planner-filters';

const InfluencerFilters = () => {
  const dispatch = useDispatch();
  const engagementRate = useSelector(state => state.plannerFilters.engagementRate);
  const engagedUsers = useSelector(state => state.plannerFilters.engagedUsers);
  const views = useSelector(state => state.plannerFilters.views);
  const ageGroup = useSelector(state => state.plannerFilters.ageGroup);

  const [languageOptions, setLocationOptions] = React.useState([]);
  const [deliverableOptions, setDeliverableOptions] = React.useState([]);
  const [countryOptions, setCountryOptions] = React.useState([]);
  const [stateOptions, setStateOptions] = React.useState([]);
  // const [cityOptions, setCityOptions] = React.useState([]);

  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(async () => {
    const langs = await getLanguages();
    setLocationOptions([...langs.map((language) => language.name)]);

    const deliverableResponse = await getDeliverables()
    setDeliverableOptions([...deliverableResponse.data.map((deliverable) => deliverable)]);

    const countryResponse = await getPlannerCountries();
    setCountryOptions([...countryResponse.data.filter(option => option)]);

    const stateResponse = await getCityStateList();
    setStateOptions([...stateResponse.data.filter(option => option)]);

    // const cityResponse = await getCityStateList(true);
    // setCityOptions([...cityResponse.data.filter(option => option)]);


  
  }, [])

  return (
    <Grid container spacing={gridSpacing} sx={{height: 300, overflowY: 'scroll', pl: 4, pr: 2}}>
      <Grid item xs={12}>
        <SliderFilter value={engagementRate !== undefined ? engagementRate : ''} title='Engagement Rate'  min={0} max={100} stepSize={0.1} payloadKey={'engagementRate'} showTextField={true} />
      </Grid>
      <Grid item xs={12}>
        <SliderFilter value={engagedUsers || 0} title='Average Engaged Users' min={0} max={1000000} payloadKey={'engagedUsers'} showTextField={true} />
      </Grid>
      <Grid item xs={12}>
        <SliderFilter value={views || 0} title='Average Views' min={0} max={10000000} payloadKey={'views'} showTextField={true} />
      </Grid>
      {/* Age Group Filter */}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems='center' justifyContent='space-evenly' sx={{ mb: 2 }}>
          <Grid item xs={3}>
            <Typography variant="h6" gutterBottom>
              Age Group
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Select
              labelId="ageGroup-select"
              id="ageGroup"
              name="ageGroup"
              value={ageGroup}
              onChange={(event) => {
                dispatch(updateFilters('ageGroup', event.target.value))
              }}
              label="Age Group"
              fullWidth
              size='small'
            >
              <MenuItem value={'13-17'}>13 - 17</MenuItem>
              <MenuItem value={'18-24'}>18 - 24</MenuItem>
              <MenuItem value={'25-34'}>25 - 34</MenuItem>
              <MenuItem value={'35-44'}>35 - 44</MenuItem>
              <MenuItem value={'45-64'}>45 - 64</MenuItem>
              <MenuItem value={'65-'}>65 - </MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Grid>


      {/* Language Filter */}
      <Grid item xs={12}>
        <AutocompleteFilter options={languageOptions} title='Language' payloadKey={'language'} />
      </Grid>

      {/* Deliverables Filter */}
      <Grid item xs={12}>
        <AutocompleteFilter options={deliverableOptions} title='Deliverables' payloadKey={'deliverables'} />
      </Grid>
    

      {/* Country Filter */}
      <Grid item xs={12}>
        <AutocompleteFilter options={countryOptions} title='Country' payloadKey={'country'} />
      </Grid>

      {/* State Filter */}
      <Grid item xs={12}>
        <AutocompleteFilter options={stateOptions} title='State' payloadKey={'state'} />
      </Grid>

      {/* Region Filter */}
      <Grid item xs={12}>
        <AutocompleteFilter options={['North', 'East', 'West', 'South']} title='Region' payloadKey={'region'} />
      </Grid>

    </Grid>
  )
}

export default InfluencerFilters;