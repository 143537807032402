import { constants } from '../constants/discovery';

import { getPlannerInfluencers } from 'api_handlers/discovery';

export function updateSearchedSelectedInfluencerIds(searchedSelectedInfluencerIds) {
  return (dispatch) => {
    dispatch(update());
  };

  function update() {
    return {
      type: constants.UPDATE_SEARCHED_SELECTED_INFLUENCER_IDS,
      searchedSelectedInfluencerIds,
    };
  }
}
