import React from 'react';
import { BrowserRouter, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import Home from './Home';
import CampaignDashboard from './CampaignDashboard';
import MobileDashboard from './MobileDashboard';
import MobileCampaignDashboard from './MobileCampaignDashboard';
import Feed from './Feed/Feed';
import MobileFeed from './Feed/MobileFeed';
import NavigationBar from './NavigationBar';
import MobileNavigationBar from './MobileNavigationBar';
import MobileBottomHeader from './MobileBottomHeader';
import NavBar from '../brands/NavigationBar';
import ProfileSetup from './profile-setup/Index';
import Settings from './settings/Settings';
import MobileSettings from './settings/MobileSettings';
import Personal from './settings/MobilePersonal';
import Security from './settings/MobileSecurity';
import Social from './settings/MobileSocial';
import AboutMe from './settings/MobileAboutMe';
import ComingSoon from '../ux/ComingSoon';

class InfluencerRouter extends React.Component {
  render() {
    return (
      <>
        <Grid container>
          {JSON.parse(localStorage.getItem('user')).user_type != 'brand' ? (
            <Grid item md={12}>
              {window.matchMedia('(min-width: 768px)').matches ? (
                <NavigationBar />
              ) : (
                <MobileNavigationBar />
              )}
            </Grid>
          ) : (
            <Grid item md={12}>
              <NavBar />
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item md={12} style={{ width: '100%' }}>
            <Route exact path={'/influencer'}>
              <Redirect to={`${this.props.match.path}/campaigns`} />
            </Route>

            <Switch>
              {window.matchMedia('(min-width: 768px)').matches ? (
                <Route
                  exact
                  path={`${this.props.match.path}/campaigns`}
                  component={CampaignDashboard}
                />
              ) : (
                <Route
                  exact
                  path={`${this.props.match.path}/campaigns`}
                  component={MobileCampaignDashboard}
                />
              )}
              {window.matchMedia('(min-width: 768px)').matches ? (
                <Route
                  path={`${this.props.match.path}/campaigns/:campaignId/feeds/:influencerId`}
                  component={Feed}
                />
              ) : (
                <Route
                  path={`${this.props.match.path}/campaigns/:campaignId/feeds/:influencerId`}
                  component={MobileFeed}
                />
              )}
              <Route path={`${this.props.match.path}/dashboard`} component={MobileDashboard} />
              <Route
                exact
                path={`${this.props.match.path}/profile-setup`}
                component={ProfileSetup}
              />
              {window.matchMedia('(min-width: 768px)').matches ? (
                <Route path={`${this.props.match.path}/settings`} component={Settings} />
              ) : (
                <Route path={`${this.props.match.path}/settings`} component={MobileSettings} />
              )}
              <Route path={`${this.props.match.path}/personal-settings`}>
                <Personal />
              </Route>
              <Route path={`${this.props.match.path}/security-settings`}>
                <Security />
              </Route>
              <Route path={`${this.props.match.path}/social-settings`}>
                <Social />
              </Route>
              <Route path={`${this.props.match.path}/about-me`}>
                <AboutMe />
              </Route>
              <Route path={`${this.props.match.path}/comingsoon`}>
                <ComingSoon />
              </Route>
              <Route path={`${this.props.match.path}/past`}>
                <ComingSoon />
              </Route>
            </Switch>
          </Grid>
        </Grid>
        <Grid container>
          {JSON.parse(localStorage.getItem('user')).user_type != 'brand' ? (
            <Grid item md={12}>
              {window.matchMedia('(min-width: 768px)').matches ? '' : <MobileBottomHeader />}
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </>
    );
  }
}

export default withRouter(InfluencerRouter);
