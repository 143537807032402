import React from 'react';
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import { updateBrandDisplayPanels } from '../../actions/brand';

import CampaignDashboard from '../campaign/CampaignDashboard';
import BrandDashboard from '../reporting/Dashboard';
import NavigationBar from './NavigationBar';
import CampaignRoutes from '../campaign/Routes';
import BrandFooter from '../../components/auth/BrandFooter';
import DiscoverInfluencer from '../../components/influencers/DiscoverInfluencer';
import BrandSettings from './settings/Index';

class BrandRouter extends React.Component {
  componentDidMount() {
    this.props.updateBrandDisplayPanels();
  }

  render() {
    return (
      <>
        <Grid container>
          <Grid item md={12}>
            <NavigationBar />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <Switch>
              <Route path={`${this.props.match.path}/dashboard`} component={BrandDashboard} />
              <Route
                exact
                path={`${this.props.match.path}/campaigns`}
                component={CampaignDashboard}
              />
              <Route
                path={`${this.props.match.path}/campaigns/:campaignId`}
                component={CampaignRoutes}
              />
              <Route path={`${this.props.match.path}/discover`} component={DiscoverInfluencer} />
              <Route path={`${this.props.match.path}/settings`} component={BrandSettings} />
            </Switch>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={12}>
            <BrandFooter />
          </Grid>
        </Grid>
      </>
    );
  }
}

// export default withRouter(BrandRouter);

const mapStateToProps = (state) => ({});

const actionCreators = {
  updateBrandDisplayPanels,
};

export default connect(mapStateToProps, actionCreators)(withRouter(BrandRouter));
