import {
  reportingFetch,
  influencerReportingFetch,
  reportingFetchV2,
  influencerReportingFetchV2,
  adreportingFetch,
} from './index';

// export function getYoutubeStatistic(campaign_id, startDate, endDate, selectedInfluencer) {
//     console.log(startDate, endDate)
//     return reportingFetch(`youtube/stats/?start_date=${startDate}&end_date=${endDate}`)
// }

export function getYoutubeStatistic(campaign_id, startDate, endDate, selectedInfluencer,selectedLanguage) {
  return reportingFetchV2(`/youtube/stats/`, {
    params: {
      start_date: startDate,
      end_date: endDate,
      influencer_id: selectedInfluencer,
      language_ids: selectedLanguage
    },
  });
}

export function getInfluencerYoutubeStatistic(campaign_id, influencer_id, startDate, endDate) {
  return influencerReportingFetchV2(`influencer/youtube/stats/`, campaign_id, influencer_id, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
}

export function getYoutubeTopPerformers(campaign_id, metric, startDate, endDate) {
  return reportingFetch(
    `youtube/top-performers/?metric=${metric}&start_date=${startDate}&end_date=${endDate}`,
  );
}

export function getYoutubeEngagmentGraphData() {
  return reportingFetch(`youtube/graph/engegement-rate/`);
}

export function getYoutubeGenderGraphData() {
  return reportingFetch(`youtube/graph/gender-splitup/`);
}

export function getYoutubeAgeGraphData() {
  return reportingFetch(`youtube/graph/age-splitup/`);
}

export function getYoutubeTopVideos(campaignId, metric) {
  return reportingFetch(`youtube/top-videos/?metric=${metric}`);
}

export function getInstagramStatistic(params) {
  const queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  return reportingFetch(`instagram/stats/?${queryString}`);
}

export function getInfluencerInstagramStatistic(campaign_id, influencer_id) {
  return influencerReportingFetch(`influencer/instagram/stats/`, campaign_id, influencer_id);
}

export function getInstagramEngagmentGraphData() {
  return reportingFetch(`instagram/graph/engegement-rate/`);
}

export function getInstagramContentGraphData() {
  return reportingFetch(`instagram/graph/content-splitup/`);
}

export function getInstagramTopPerformers(metric, startDate, endDate) {
  return reportingFetch(
    `instagram/top-performers/?metric=${metric}&start_date=${startDate}&end_date=${endDate}`,
  );
}

export function getInstagramTopPosts(metric) {
  return reportingFetch(`instagram/top-posts/?metric=${metric}`);
}

export function getInstagramAdStats() {
  return adreportingFetch(`instagram/adstats`);
}

export function getInstagramTopHashtags() {
  return reportingFetch(`instagram/top-hashtags/`);
}

export function getInstagramCostStatistic() {
  return reportingFetch(`instagram/cost-stats/`);
}

export function getVideoGraphData(videoId, type) {
  return reportingFetch(`youtube/video/${videoId}/graph/${type}`);
}

export function getBrandedContentStats(params) {
  const queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  return reportingFetch(`instagram/branded-content/stats?${queryString}`);
}
