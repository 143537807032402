import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import BaseSidePanel from '../ux/BaseSidePanel';

import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';

class SidePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
    };
  }

  getNavigationLinks() {
    const links = [];

    if (this.props.allowedSocailMediaLinks) {
      if (this.props.allowedSocailMediaLinks.includes('youtube')) {
        links.push({
          to: `${this.props.match.url}/youtube`,
          label: 'Youtube',
          icon: YouTubeIcon,
        });
      }

      if (this.props.allowedSocailMediaLinks.includes('instagram')) {
        links.push({
          to: `${this.props.match.url}/instagram`,
          label: 'Instagram',
          icon: InstagramIcon,
        });
      }
    }

    return links;
  }

  render() {
    const { classes } = this.props;
    return <BaseSidePanel navigationLinks={this.getNavigationLinks()} />;
  }
}

const mapStateToProps = (state) => {
  return {
    allowedSocailMediaLinks: state.brand.displayPanels?.social_media.link_display,
  };
};

export default connect(mapStateToProps)(withRouter(SidePanel));
