
import Grid from '@mui/material/Grid';
import SubCard from 'ui-component/cards/SubCard';

import CreatorTypeCircle from './CreatorTypeCircle';

const containerSx = {
  position: 'relative',
  // width: 600,
  height: 250,
}

const Spends = ({predictions}) => {
  const { mega, macro, micro, nano } = predictions.creator_type;

  return (
    <SubCard title={`Spend (${predictions.total_spent})`}>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} sm={12} sx={containerSx}>
          <CreatorTypeCircle 
            color='#D9FCE0' size={170} text1={`${mega?.count} Mega`}
            text2={`₹${mega?.amount}`} />
          <CreatorTypeCircle 
            color='#FCD9E8' size={150} text1={`${macro?.count} Macro`}
            text2={`₹${macro?.amount}`} top={30} left={130}/>
          <CreatorTypeCircle 
            color='#CEEBFF' size={130} text1={`${micro?.count} Micro`}
            text2={`₹${micro?.amount}`} top={10} left={250}/>
          <CreatorTypeCircle 
            color='#FDEABB' size={100} text1={`${nano?.count} Nano`}
            text2={`₹${nano?.amount}`} top={50} left={360}/>
        </Grid>
      </Grid>
    </SubCard>
  );
};

export default Spends;
