import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ObjectiveOption from './ObjectiveOption';
import DraggableList from 'react-draggable-list';
import Drag from 'assets/planner/drag.png';
import PriorityTemplate from './PriorityTemplate';

function Priority({ selected, setSelectedObjective, list, setList }) {
  const classes = useStyles();

  return (
    <div
      onClick={() => {
        setSelectedObjective('Priority');
      }}
      style={{ position: 'relative' }}
    >
      <ObjectiveOption {...(selected ? { selected: true } : null)}>Priority</ObjectiveOption>
      {selected ? (
        <div className={classes.modal}>
          <Typography className={classes.heading}>
            What Matters the <strong>Most</strong> ?
          </Typography>
          <Typography className={classes.subHeading}>Rank in the order of Priority.</Typography>
          <div
            style={{ width: '100%', display: 'flex', margin: 10, marginTop: 20, flexWrap: 'wrap' }}
          >
            <DraggableList
              itemKey="name"
              template={PriorityTemplate}
              list={list}
              onMoveEnd={(newList) => {
                newList.map((each, index) => {
                  newList[index]['id'] = index + 1;
                });
                setList(newList);
              }}
              container={() => document.body}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  modal: {
    background: '#ffffff',
    position: 'absolute',
    width: 200,
    top: 90,
    padding: 20,
    boxShadow: '5px 4px 20px rgba(104, 104, 104, 0.25)',
    borderRadius: '30px',
    zIndex: 5,
  },
  heading: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 14,
  },
  subHeading: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 12,
    color: '#757575',
  },
  number: {
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: 16,
    color: '#FEBD1C',
    marginLeft: 5,
    marginRight: 10,
  },
  label: {
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    background: '#F1F1F1',
  },
}));

export default Priority;
