import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import CampaignLogo from '../../../assets/campaign-logo.svg';

const styles = (theme) => ({
  name: {
    fontFamily: "'Lato', sans-serif",
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'left',
  },
  objective: {
    fontFamily: "'Lato', sans-serif",
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '155.8%',
  },
  description: {
    fontFamily: "'Lato', sans-serif",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '175%',
    color: '#555555',
  },
});

const formatCount = (count) => {
  var num;
  const parsedCount = parseInt(count);
  if (parsedCount < 1) {
    return count.toFixed(2);
  } else if (parsedCount > 9999 && parsedCount <= 999999) {
    num = parsedCount / 1000;
    if (num % 1 != 0) {
      num = num.toFixed(2);
    }
    return num + 'K';
  } else if (parsedCount > 999999) {
    num = parsedCount / 1000000;
    if (num % 1 != 0) {
      num = num.toFixed(2);
    }
    return num + 'M';
  } else if (parsedCount === NaN) {
    return count;
  } else if (parsedCount && count % 1 != 0) {
    return count.toFixed(2);
  } else {
    return count;
  }
};

class Campaign extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Card
        style={{
          marginBottom: 20,
          cursor: 'pointer',
        }}
        onClick={() =>
          this.props.history.push(
            `${this.props.match.path}/${this.props.id}/feeds/${
              JSON.parse(localStorage.getItem('user')).influencer.id
            }/youtube/`,
          )
        }
      >
        <CardContent style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item md={4}>
              <Grid container>
                <Grid item md={4}>
                  <img
                    src={CampaignLogo}
                    alt={'campaign-logo'}
                    style={
                      {
                        // marginTop: '-30px',
                        // marginLeft: '10px'
                      }
                    }
                  />
                </Grid>
                <Grid item md={8}>
                  <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Grid item md={12}>
                      <Typography variant={'h6'} className={`${classes.name} campaign-name`}>
                        {this.props.name}
                      </Typography>
                    </Grid>
                    <Grid item md={12}>
                      <Typography className={`${classes.objective} campaign-objective`}>
                        Objective: {this.props.objective}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} style={{ paddingRight: 10 }}>
              <Chip
                label={
                  this.props.is_live
                    ? 'Live'
                    : this.props.status.replace(/\b[a-z]/g, function (letter) {
                        return letter.toUpperCase();
                      })
                }
                size="small"
                style={{
                  background: this.props.is_live
                    ? '#8517C8'
                    : this.props.status && this.props.status == 'paused'
                    ? 'grey'
                    : this.props.status && this.props.status == 'completed'
                    ? '#65C817'
                    : 'grey',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '11px',
                  padding: '0px 15px 0px',
                }}
              ></Chip>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{
              marginTop: '15px',
            }}
          >
            <Grid
              item
              md={10}
              style={{
                textAlign: 'left',
                paddingLeft: 25,
              }}
            >
              <Typography className={`${classes.description} campaign-description`}>
                {this.props.desc}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <hr />
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item md={12} style={{ width: '80%' }}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item md={3}>
                  <dt className={'value'}>Rs. {this.props.budget.toString()}</dt>
                  <dt className={'key'}>Your Pricing</dt>
                </Grid>
                <Grid item md={3}>
                  <dt className={'value'}>
                    {this.props.is_live || this.props.status != 'draft'
                      ? this.props.total_posts
                      : 'N/A'}
                  </dt>
                  <dt className={'key'}>Total Posts</dt>
                </Grid>
                <Grid item md={3}>
                  <dt className={'value'}>
                    {this.props.is_live || this.props.status != 'draft'
                      ? formatCount(this.props.total_views)
                      : 'N/A'}
                  </dt>
                  <dt className={'key'}>Total View</dt>
                </Grid>
                <Grid item md={3}>
                  <dt className={'value'}>
                    {this.props.is_live || this.props.status != 'draft'
                      ? formatCount(this.props.total_engagement_rate)
                      : 'N/A'}
                  </dt>
                  <dt className={'key'}>Engagement Rate</dt>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default withRouter(withStyles(styles)(Campaign));
