import discover from './discover';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [discover]
};

export default menuItems;
