import { Component } from 'react';

import MessageIcon from '../../../../../assets/vector.png';
import Thumbs_UpIcon from '../../../../../assets/Thumbs_Up.png';
import TotalViewIcon from '../../../../../assets/total_views_icon.png';
import TotalImpressionIcon from '../../../../../assets/total_impressions_icon.png';
import TotalReachIcon from '../../../../../assets/total_reach_icon.png';

const statisticsItemStyle = {
  display: 'flex',
  flexDirection: 'column',
  margin: '5px',
};

const statisticsLabel = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '9px',
};

export default class Statistics extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div
          style={{
            width: 'calc(100% - 50px)',
            margin: '10px',
            padding: '10px 15px',
            background: '#FFFFFF',
            boxShadow: '2px 5px 10px rgba(139, 139, 139, 0.25)',
            borderRadius: '5px',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>Statistics</div>
            <div
              style={{
                fontSize: '12px',
                lineHeight: '14px',
                textAlign: 'right',
                color: '#5C5C5C',
              }}
            >
              Overall data
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginTop: '10px',
            }}
          >
            <div style={{ width: '33%' }}>
              <div style={statisticsItemStyle}>
                {this.props.formatCount(this.props.stats && this.props.stats.total_views | 0)}
                <span style={statisticsLabel}>
                  <img
                    src={TotalViewIcon}
                    width={'15'}
                    height={'10'}
                    style={{ marginRight: '3px' }}
                  />
                  Total View
                </span>
              </div>
            </div>
            <div style={{ width: '33%' }}>
              <div style={{ ...statisticsItemStyle, ...{ alignItems: 'center' } }}>
                {this.props.formatCount(this.props.stats && this.props.stats.total_likes | 0)}
                <span style={statisticsLabel}>
                  <img
                    src={Thumbs_UpIcon}
                    width={'15'}
                    height={'15'}
                    style={{ marginRight: '3px' }}
                  />
                  Total Likes
                </span>
              </div>
            </div>
            <div style={{ width: '33%' }}>
              <div style={statisticsItemStyle}>
                {this.props.formatCount(this.props.stats && this.props.stats.total_comments | 0)}
                <span style={statisticsLabel}>
                  <img
                    src={MessageIcon}
                    width={'15'}
                    height={'15'}
                    style={{ marginRight: '3px' }}
                  />
                  Total Comments
                </span>
              </div>
            </div>
            <div style={{ width: '33%' }}>
              <div style={statisticsItemStyle}>
                {this.props.formatCount(this.props.stats && this.props.stats.total_views | 0)}
                <span style={statisticsLabel}>
                  <img
                    src={TotalImpressionIcon}
                    width={'15'}
                    height={'10'}
                    style={{ marginRight: '3px' }}
                  />
                  Total Impressions
                </span>
              </div>
            </div>
            <div style={{ width: '33%' }}>
              <div style={statisticsItemStyle}>
                {this.props.formatCount(this.props.stats && this.props.stats.engagement_rate | 0)}
                <span style={statisticsLabel}>
                  <img
                    src={TotalReachIcon}
                    width={'15'}
                    height={'10'}
                    style={{ marginRight: '3px' }}
                  />
                  Engagement Rate
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
