import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Avatar,
  Paper,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import NoDataLabel from '../common/NoDataLabel';
import BootstrapInput from '../../../ux/BootstrapInput';
import { getInstagramTopPosts } from '../../../../api_handlers/reporting';

import insta_placeholder from '../../../../assets/discovery/insta_placeholder.png';

import ImageValidator from 'components/ux/ImageValidator';

class TopPosts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      metric: 'like_count',
    };
  }

  async getData() {
    const resp = await getInstagramTopPosts(this.state.metric);
    this.setState({ data: resp.data, loading: false });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 300);
  }

  render() {
    return (
      <Card
        style={{
          height: 390,
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={12} class="dashboard-card-title">
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item md={4}>
                  Top Posts
                </Grid>
                <Grid item md={2}>
                  <FormControl>
                    <Select
                      // value={age}
                      onChange={async (value) => {
                        await this.setState({ metric: value.target.value });
                        this.getData();
                      }}
                      input={<BootstrapInput />}
                      defaultValue={'like_count'}
                    >
                      <MenuItem value={'like_count'}>Likes</MenuItem>
                      <MenuItem value={'comment_count'}>Comments</MenuItem>
                      <MenuItem value={'saved'}>Saved</MenuItem>
                      <MenuItem value={'impressions'}>Impressions</MenuItem>
                      <MenuItem value={'video_views'}>Video Views</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              {this.state.loading ? (
                <CircularProgress style={{ marginTop: 50 }} />
              ) : this.state.data.length === 0 ? (
                <NoDataLabel offsetTop={50} />
              ) : (
                <Grid
                  container
                  spacing={3}
                  style={{
                    height: 325,
                    overflowY: 'scroll',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {this.state.data.map((post) => {
                    return (
                      <Grid item md={3}>
                        <a
                          href={post.url}
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#444',
                            fontSize: 12,
                          }}
                        >
                          <Grid container style={{ marginBottom: '10px' }}>
                            <Grid item style={{ margin: 'auto' }}>
                              <ImageValidator
                                className='top-post-img'
                                url={post.thumbnail}>
                                <img src={insta_placeholder} className='top-post-img'/>
                              </ImageValidator>
                            </Grid>
                          </Grid>
                          <a
                            href={post.url}
                            target="_blank"
                            style={{
                              textDecoration: 'none',
                              color: '#444',
                              fontWeight: 600,
                              width: '100%',
                            }}
                          >
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              style={{ marginLeft: 10, width: '80%', margin: 'auto' }}
                            >
                              <Grid item md={8}>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                >
                                  <Grid item md={3}>
                                    <Avatar
                                      src={post.influencer.avatar}
                                      style={{
                                        width: '25px',
                                        height: '25px',
                                      }}
                                    />
                                  </Grid>
                                  <Grid item md={'auto'}>
                                    {post.influencer.name}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item md={'auto'}>
                                <Paper
                                  style={{
                                    background: '#3263E0',
                                    padding: '4px',
                                    color: '#FFF',
                                    fontWeight: '600',
                                    fontSize: '11px',
                                    marginRight: 13,
                                  }}
                                >
                                  {post.value}
                                </Paper>
                              </Grid>
                            </Grid>
                          </a>
                        </a>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
  };
};

export default connect(mapStateToProps)(withRouter(TopPosts));
