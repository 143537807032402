import { Component } from "react";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles, Theme, makeStyles } from "@material-ui/core/styles";

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: "#3A3A3A",
        color: "white",
        borderRadius: "2px",
        maxWidth: 220,
        width: 120,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
    arrow: {
        color: "#3A3A3A",
    },
}))(Tooltip);

class DiscoveryCountDisplay extends Component {
    formatCount(count) {
        var num;
        const parsedCount = parseInt(count);
        if (parsedCount < 1) {
            return count.toFixed(2);
        } else if (parsedCount > 9999 && parsedCount <= 999999) {
            num = parsedCount / 1000;
            if (num % 1 != 0) {
                num = num.toFixed(2);
            }
            return num + "K";
        } else if (parsedCount > 999999) {
            num = parsedCount / 1000000;
            if (num % 1 != 0) {
                num = num.toFixed(2);
            }
            return num + "M";
        } else if (parsedCount === NaN) {
            return count;
        } else if (parsedCount && count % 1 != 0) {
            return count.toFixed(2);
        } else {
            return count;
        }
    }

    render() {
        const Icon = this.props.icon;
        const Info = this.props.info;
        const Money = this.props.money;
        return (
            <Grid container alignItems="center" spacing={1}>
                <Grid item md={Icon ? 3 : ""} style={{ marginTop: "12px" }}>
                    {Icon ? (
                        <img
                            src={Icon}
                            alt="total-comments"
                            style={{
                                width: "12px",
                                marginRight:'2px',
                                lineHeight: "13px",
                            }}
                        />
                    ) : null}
                </Grid>
                <Grid item md={Icon ? 9 : 12}>
                    <Grid container style={{ textAlign: "left" }}>
                        <Grid item md={12}>
                            <span
                                style={{
                                    fontSize: "7px",
                                    lineHeight: "13px",
                                    fontWeight:100
                                }}
                            >
                                {this.props.label}
                            </span>
                            {Info ? (
                                <HtmlTooltip
                                    title={
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            style={{ fontSize: "7px" }}
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                <Grid item md={8}>
                                                    Instagram :
                                                </Grid>
                                                <Grid item md={4}>
                                                    {Money ? "Rs " : null}
                                                    {this.formatCount(
                                                        this.props.insta_count
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                <Grid item md={8}>
                                                    Youtube :
                                                </Grid>
                                                <Grid item md={4}>
                                                    {Money ? "Rs " : null}
                                                    {this.formatCount(
                                                        this.props.youtube_count
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    arrow
                                    placement="top-end"
                                >
                                    <p
                                        style={{
                                            backgroundColor: "#3E65CC",
                                            height: "13px",
                                            width: "13px",
                                            color: "white",
                                            margin: "0",
                                            padding: "0",
                                            marginLeft: "5px",
                                            borderRadius: "13px",
                                            fontSize: "13px",
                                            fontWeight: "bold",
                                            display: "inline-block",
                                        }}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <em>i</em>
                                        </Grid>
                                    </p>
                                </HtmlTooltip>
                            ) : null}
                        </Grid>
                        <Grid item md={12}>
                            <span
                                style={{
                                    fontSize: "12px",
                                    fontWeight: 700,
                                }}
                            >
                                {this.formatCount(this.props.count)}
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default DiscoveryCountDisplay;
