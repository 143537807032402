/* global BigInt */
import { Component } from "react";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles, Theme, makeStyles } from "@material-ui/core/styles";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: "#3A3A3A",
        color: "white",
        borderRadius: "2px",
        maxWidth: 220,
        width: 120,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
    arrow: {
        color: "#3A3A3A",
    },
}))(Tooltip);

class CountDisplay extends Component {
    formatCount(count) {
        if (typeof count === "string" && count.length > 0 ) {
            const lastChar = count[count.length - 1].toLocaleUpperCase();
            if (["B", "M", "K"].includes(lastChar)) return count;
        }



        var num;
        const parsedCount = parseFloat(count);
        if (parsedCount <= 1) {
            return parsedCount.toFixed(2);
        } else if (parsedCount > 9999 && parsedCount <= 999999) {
            num = parsedCount / 1000;
            if (num % 1 != 0) {
                num = num.toFixed(2);
            }
            return num + "K";
        } else if (parsedCount > 999999) {
            num = parsedCount / 1000000;
            if (num % 1 != 0) {
                num = num.toFixed(2);
            }
            return num + "M";
        } else if (parsedCount > 999999999) {
            num = parsedCount / 1000000000;
            if (num % 1 != 0) {
                num = num.toFixed(2);
            }
            return num + "B";
        } else if (parsedCount === NaN) {
            return count;
        } else if (parsedCount && count % 1 != 0) {
            return count.toFixed(2);
        } else {
            return count;
        }
    }

    render() {
        const Icon = this.props.icon;
        const Info = this.props.info;
        const Money = this.props.money;
        const Width = this.props.width;
        const Height = this.props.height;
        const showPercentage = this.props.showPercentage;
        return (
            <Grid container  alignItems="center" justifyContent="center" style={{marginLeft:5}} spacing={1} >
                <Grid item md={Icon ? 3 : ""} style={{ marginTop: "12px" }}>
                    {Icon ? <img src={Icon} alt="total-comments" height={Height ? Height : null} width={Width ? Width : null} /> : null}
                </Grid>
                <Grid item md={Icon ? 9 : 12}>
                    <Grid container style={{ textAlign: "left" }}>
                        <Grid item md={12}>
                            <span
                                style={{
                                    fontSize: "11px",
                                    lineHeight: "",
                                    fontWeight:600
                                }}
                            >
                                {this.props.label}
                            </span>
                            {Info ? (
                                <HtmlTooltip
                                    title={
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            style={{ fontSize: "11px" }}
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                <Grid item>Instagram :</Grid>
                                                <Grid item>
                                                    {Money ? "Rs " : null}
                                                    {this.formatCount(
                                                        this.props.insta_count
                                                    )
                                                        ? this.formatCount(
                                                              this.props
                                                                  .insta_count
                                                          )
                                                        : "0.00"}
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                <Grid item>Youtube :</Grid>
                                                <Grid item>
                                                    {Money ? "Rs " : null}
                                                    {this.formatCount(
                                                        this.props.youtube_count
                                                    )
                                                        ? this.formatCount(
                                                              this.props
                                                                  .youtube_count
                                                          )
                                                        : "0.00"}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    arrow
                                    placement="top-end"
                                >
                                    <p
                                        style={{
                                            backgroundColor: "#3E65CC",
                                            height: "13px",
                                            width: "13px",
                                            color: "white",
                                            margin: "0",
                                            padding: "0",
                                            marginLeft: "5px",
                                            borderRadius: "13px",
                                            fontSize: "13px",
                                            fontWeight: "bold",
                                            display: "inline-block",
                                        }}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <em>i</em>
                                        </Grid>
                                    </p>
                                </HtmlTooltip>
                            ) : null}
                        </Grid>
                        <Grid item md={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Grid item={9}>
                                    <span
                                        style={{
                                            fontSize: "24px",
                                            fontWeight: 700,
                                        }}
                                    >
                                        {
                                            this.props.multiCount?this.props.multiCount:this.formatCount(this.props.count)
                                        }
                                        {showPercentage ? '%' : ''}
                                    </span>
                                </Grid>
                                <Grid item md={2}>
                                    {this.props.tooltipInfo ? (
                                        <HtmlTooltip
                                            title={this.props.tooltipInfo}
                                        >
                                            <span style={{ cursor: "pointer" }}>
                                                <InfoOutlinedIcon fontSize="small" />
                                            </span>
                                        </HtmlTooltip>
                                    ) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default CountDisplay;
