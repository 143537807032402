import { campaignConstants } from '../constants/campaign';

export function campaign(
  state = {
    campaigns: [],
    current_campaign: {},
    influencers: [],
    count: 0,
    report: {},
    loading: false,
    campaignId: null,
    influencers_alignment: 'left',
    download_influencers_report: false,
  },
  action,
) {
  switch (action.type) {
    case campaignConstants.GET_CAMPAIGNS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignConstants.GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: action.campaigns,
      };
    case campaignConstants.GET_CAMPAIGNS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case campaignConstants.GET_CAMPAIGN_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignConstants.GET_CAMPAIGN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        current_campaign: action.current_campaign,
      };
    case campaignConstants.GET_CAMPAIGN_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case campaignConstants.GET_BASIC_CAMPAIGN_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignConstants.GET_BASIC_CAMPAIGN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        current_campaign: action.current_campaign,
      };
    case campaignConstants.GET_BASIC_CAMPAIGN_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case campaignConstants.GET_INFLUENCERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignConstants.GET_INFLUENCERS_SUCCESS:
      if (action.response.previous)
        return {
          ...state,
          loading: false,
          influencers: state.influencers.concat(action.response.results),
          count: action.response.count,
        };
      else
        return {
          ...state,
          loading: false,
          influencers: action.response.results,
          count: action.response.count,
        };
    case campaignConstants.GET_INFLUENCERS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case campaignConstants.GET_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignConstants.GET_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        report: action.report,
      };
    case campaignConstants.GET_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case campaignConstants.CAMPAIGN_ID:
      return {
        ...state,
        campaignId: action.campaignId,
      };
    case campaignConstants.CHANGE_INFLUENCERS_ALIGNMENT:
      return {
        ...state,
        influencers_alignment: action.influencers_alignment,
      };
    case campaignConstants.DOWNLOAD_INFLUENCERS_REPORT:
      return {
        ...state,
        download_influencers_report: action.download_influencers_report,
      };
    default:
      return state;
  }
}
