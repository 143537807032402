import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useDispatch } from 'react-redux';

// project imports
// import useConfig from 'hooks/useConfig';
import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
// import MegaMenuSection from './MegaMenuSection';
// import NotificationSection from './NotificationSection';

import config, { ThemeMode } from 'config';

import { toggleDrawerOpen } from 'actions/planner2';

// assets
import MenuIcon from '@mui/icons-material/Menu';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const downMD = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();

    const { mode, menuOrientation } = config;
    const isHorizontal = false;

    return (
        <>
            {/* logo & toggler button */}
            <Box sx={{ width: downMD ? 'auto' : 228, display: 'flex' }}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                {!isHorizontal && (
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            bgcolor: mode === ThemeMode.DARK ? 'dark.main' : 'secondary.light',
                            color: mode === ThemeMode.DARK ? 'secondary.main' : 'secondary.dark',
                            '&:hover': {
                                bgcolor: mode === ThemeMode.DARK ? 'secondary.main' : 'secondary.dark',
                                color: mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.light'
                            }
                        }}
                        onClick={() => dispatch(toggleDrawerOpen())}
                        color="inherit"
                    >
                        <MenuIcon stroke={1.5} size="20px" />
                    </Avatar>
                )}
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* mega-menu */}
            {/* <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <MegaMenuSection /> 
            </Box> */}

            {/* notification */}
            {/* <NotificationNotificationSection /> */}


            {/* profile */}
            <ProfileSection />

        </>
    );
};

export default Header;
