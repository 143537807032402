import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import ShareIcon from '@mui/icons-material/Share';

import MainCard from 'ui-component/cards/MainCard';
import { useHistory } from 'react-router-dom';

// project imports
import UserList from './UserList';
import PlannerUserList from './PlannerUserList';
import AddToCampaignModal from './AddToCampaignModal';
import { Slide } from '@material-ui/core';
import Tick from '../../../../assets/discovery/tick.png';
import ListIcon from '../../../../assets/discovery/list-icon.svg';

import { gridSpacing } from 'store/constant';
import { getPlannerDownload } from 'api_handlers/discovery';
import { showPremiumFeatureDialog, fetchInfluencersAction, updatePage } from 'actions/planner2';
import { updateFilters } from 'actions/planner-filters';
import empty from '../../../../assets/discovery/empty.jpg'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

import mixpanel from 'mixpanel-browser';

const scrollToTop = async () => {
  window.scrollTo({
    top: 0,
    behavior: 'auto', // This enables smooth scrolling
  });
};

// ==============================|| USER LIST STYLE 1 ||============================== //

const InfluencerList = ({
  influencers,
  totalResults,
  loading,
  isAuth,
  onPlatformChange,
  noInfluencers,
  listName,
  onPageChange,
  onSortChange,
  isDiscover,
  useCategorySort,
  // updateFilters,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.planner2.user);
  const page = useSelector((state) => state.planner2.page);
  const selectedInfluencers = useSelector((state) => state.planner2.selectedInfluencers);
  const [platform, setPlatform] = React.useState('all');
  const [sortBy, setSortBy] = React.useState('category');
  const [openAddToCampaign, setOpenAddToCampaign] = React.useState(false);
  const [openModalCondition, setOpenModalCondition] = useState('');
  const [buttonVariant, setButtonVariant] = useState('outlined');
  const [addToCampaignInfluencers, setAddToCampaignInfluencers] = React.useState([]);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const history = useHistory();
  // const [localPayload, setLocalPayload] = React.useState({...payload})

  const conditionalDispatch = (action) => {
    if (isAuth !== 'noAuth') {
      dispatch(action);
    }
  };

  const updatePageWithoutDispatch = (updatedPage) => {
    onPageChange(updatedPage);
  };

  const handleSortByChange = (event) => {
    const newSortBy = event.target.value;
    setSortBy(newSortBy);
    if (isAuth === 'planner' || isAuth === 'noAuth') {
      onSortChange(newSortBy);
    }
  };

  useEffect(() => {
    conditionalDispatch(updateFilters('sortBy', sortBy, true, true));
  }, [sortBy]);

  const handleDownload = () => {
    getPlannerDownload({
      selected_ids: Array.from(selectedInfluencers),
      objective: '',
      prediction: {},
      contacts: 'not_req',
      selectedColumns: [],
    });
  };

  const handleAddToCampaign = (influencerIds, isAuth) => {
    setOpenModalCondition(isAuth);
    const infs = influencers.filter((inf) => influencerIds.includes(inf.id));
    setAddToCampaignInfluencers(infs);
    setOpenAddToCampaign(true);
  };

  const handlePlatformChange = (event) => {
    const newPlatform = event.target.value;
    setPlatform(newPlatform);
    onPlatformChange(newPlatform);
  };

  const handleDiscoverClick = () => {
    if (process.env.NODE_ENV === 'production') {
      mixpanel.track("collab_list_add_influencer")
    }
    history.push('/planner/discover');
  };
  const handleShareClick = () => {
    // Copy the current page URL to the clipboard
    // navigator.clipboard.writeText(window.location.href);
    setButtonVariant('contained');
    setShareDialogOpen(true);
    navigator.clipboard.writeText(window.location.href);
    // setTimeout(() => setButtonVariant('outlined'), 2000);
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="right" />;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShareDialogOpen(false);
  };

  return (
    <MainCard
      sx={{
        '& .MuiCardHeader-root': {
          height: 70,
        },
      }}
      title={
        <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
          <Grid item>
            <Typography variant="h3">
              {isAuth === 'planner' || isAuth === 'noAuth' ? listName : 'INFLUENCERS'} (
              {totalResults})
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={0}>
              {isAuth === 'planner' || isAuth === 'noAuth' ? (
                <Grid item>
                  <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                    <InputLabel id="platform-select">Platform</InputLabel>
                    <Select
                      labelId="platform-select"
                      id="platform"
                      name="platform"
                      value={platform}
                      onChange={(event) => handlePlatformChange(event)}
                      label="Platform"
                    >
                      <MenuItem value={'all'}>All Platforms</MenuItem>
                      <MenuItem value={'instagram'}>Instagram</MenuItem>
                      <MenuItem value={'youtube'}>YouTube</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                ''
              )}

              <Grid item>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="age-select">Sort by</InputLabel>
                  <Select
                    labelId="platform-select"
                    id="sortBy"
                    name="sortBy"
                    value={sortBy}
                    onChange={handleSortByChange}
                    label="Sort By"
                  >
                    {useCategorySort &&
                      <MenuItem value={'category'}>Category</MenuItem>
                    }
                    <MenuItem value={'followers'}>Followers</MenuItem>
                    <MenuItem value={'views'}>Avg Views</MenuItem>
                    <MenuItem value={'eng-rate'}>ER</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {isAuth === 'planner' || (isAuth === 'noAuth' && !noInfluencers) ? (
                <Grid item>
                  <Button
                    sx={{ m: 1 }}
                    // autoFocus
                    variant="outlined"
                    size="medium"
                    startIcon={<ShareIcon />}
                    onClick={handleShareClick}
                  >
                    Share
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      }
      content={false}
    >
      {noInfluencers ? (
        <>  
          <img src={empty} style={{width:'300px'}}></img>
          <Typography variant="h6" color="textSecondary" sx={{ textAlign: 'center', marginTop: 4 }}>
            Add Influencers from Discover
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ cursor: 'pointer' }}
            onClick={handleDiscoverClick}
          >
            Add Influencers
          </Button>
        </>
      ) : (
        // Otherwise show the respective list based on the isAuth prop
        <>
          {isAuth === 'planner' || isAuth === 'noAuth' ? (
            <PlannerUserList
              influencers={influencers}
              loading={loading}
              handleAddToCampaign={handleAddToCampaign}
              platform={platform}
              openShareDialog={shareDialogOpen}
              setShareDialogOpen={setShareDialogOpen}
              listName={listName}
              isAuth={isAuth}
            />
          ) : (
            <UserList
              influencers={influencers}
              loading={loading}
              handleAddToCampaign={handleAddToCampaign}
            />
          )}
        </>
      )}

      {/* {text === 'planner'?
            <PlannerUserList 
                influencers={influencers} 
                loading={loading} 
                handleAddToCampaign={handleAddToCampaign}
                platform={platform} 
            />:
            <UserList influencers={influencers} loading={loading} handleAddToCampaign={handleAddToCampaign} />} */}
      <Grid item xs={12} sx={{ p: 3 }}>
        <Grid container justifyContent="space-between" spacing={gridSpacing}>
          <Grid item>
            <Pagination
              count={Math.ceil(totalResults / 20)}
              color="primary"
              page={page}
              onChange={(e, updatedPage) => {
                if (user.premium_user || updatedPage < 4) {
                  if (isAuth === 'noAuth') {
                    updatePageWithoutDispatch(updatedPage);
                  }
                  conditionalDispatch(updatePage(updatedPage));
                  conditionalDispatch(fetchInfluencersAction(updatedPage));
                  scrollToTop();
                  if (process.env.NODE_ENV === 'production') {
                    if (isDiscover) {
                      mixpanel.track(`Discover - Page clicked`, {
                          "page": updatedPage
                      });
                    }  else {
                      mixpanel.track(`Page clicked`, {
                        page: updatedPage,
                      });
                    }
                  }
                } else conditionalDispatch(showPremiumFeatureDialog(true));
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={selectedInfluencers.size > 0 && !openAddToCampaign}
      >
        <Box sx={{ p: 2, bgcolor: '#111936', borderRadius: 1, minWidth: 800 }}>
          <Grid container alignItems={'center'} justifyContent={'center'} spacing={3}>
            <Grid item xs={'auto'}>
              <Typography variant="h4" sx={{ color: '#ffffff' }}>
                Selected {selectedInfluencers.size} influencers
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  // if (user.premium_user) {
                    handleAddToCampaign(Array.from(selectedInfluencers), 'Planner');
                  // } else {
                    // conditionalDispatch(showPremiumFeatureDialog(true));
                  // }
                }}
                startIcon={<img src={ListIcon} style={{ width: '12px' }}></img>}
              >
                Add to List
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  if (user.premium_user) {
                    handleAddToCampaign(Array.from(selectedInfluencers), 'AddToCampaign');
                  } else {
                    conditionalDispatch(showPremiumFeatureDialog(true));
                  }
                }}
                startIcon={<WorkspacePremiumIcon />}
              >
                Add to Campaign
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  if (user.premium_user) {
                    handleDownload();
                  } else {
                    conditionalDispatch(showPremiumFeatureDialog(true));
                  }
                }}
                startIcon={<WorkspacePremiumIcon />}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Snackbar>
      <Snackbar
        open={shareDialogOpen}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        message={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <img src={Tick} style={{ marginRight: 8, width: '23px' }}></img>
            {/* <CheckCircleIcon  /> */}
            url is copied
          </span>
        }
        autoHideDuration={1500}
      />
      {isAuth !== 'noAuth' && (
        <AddToCampaignModal
          open={openAddToCampaign}
          setOpen={setOpenAddToCampaign}
          influencers={addToCampaignInfluencers}
          text={openModalCondition}
        />
      )}
    </MainCard>
  );
};

export default InfluencerList;
