import React from 'react';
import { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Link, Grid } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { getInstagramFeeds } from '../../../../api_handlers/feeds';
import {
  getInstagramFeedsColumns,
  postInstagramFeedsColumns,
} from '../../../../api_handlers/campaign';

const styles = (theme) => ({
  headerCell: {
    background: '#C4C4C4',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 12,
  },
  table: {
    minWidth: 650,
  },
  cell: {
    textAlign: 'center',
    fontSize: 12,
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

class InstagramPostTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feeds: [],
      count: 0,
      page: 1,
      loading: false,
      columnsArray: [],
    };
  }
  async getData() {
    this.setState({
      loading: true,
    });
    const resp = await getInstagramFeeds(this.state.page, {
      influencer_id: this.props.influencerId,
      start_date: this.props.startDate,
      end_date: this.props.endDate,
      tab: this.props.tab,
      post_type:
        this.props.selectedFeedTab == 0
          ? this.props.postType
          : this.props.selectedFeedTab == 1
          ? 'story'
          : null,
    });
    this.props.allowedTableColumns.length !== 0 ? this.postColumns() : this.getColumns();
    this.getColumns();
    this.setState({
      feeds: this.state.feeds.concat(resp.results),
      count: resp.count,
      loading: false,
    });
  }

  async getColumns() {
    const colsGet = await getInstagramFeedsColumns();
    this.setState({
      columnsArray: colsGet.instagram_columns,
    });
  }

  async postColumns() {
    const colsPost = await postInstagramFeedsColumns({
      instagram_columns: this.props.allowedTableColumns,
    });
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
    window.addEventListener('scroll', this.infiniteScroll);
    setTimeout(() => {
      this.getData(this.state.page);
    }, 30);
  }

  ellipsis(text) {
    if (text && text.length > 80) return text.slice(0, 77) + '...';
    return text;
  }

  infiniteScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      if (this.state.feeds.length < this.state.count && this.state.loading == false) {
        let newPage = this.state.page;
        newPage++;
        this.setState({
          page: newPage,
        });
        this.getData();
      }
    }
  };

  async reloadData() {
    await this.setState({
      feeds: [],
      count: 0,
      page: 1,
    });
    this.getData();
  }

  openLink(link) {
    window.open(link, '_blank');
  }

  getBrandedContentColumnValue(feed, key) {
    if (!feed.branded_content_data) return <span>{feed[key]}</span>;

    switch (this.props.tab) {
      case 'feed':
        return (
          <span>
            {feed[key] + feed.branded_content_data[key]}
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div>
                    <b>Paid</b>: {feed.branded_content_data[key]}
                  </div>
                  <div>
                    <b>Organic</b>: {feed[key]}
                  </div>
                </React.Fragment>
              }
            >
              <Button>
                <InfoOutlinedIcon fontSize="small" />
              </Button>
            </HtmlTooltip>
          </span>
        );
      case 'organic':
        return <span>{feed[key]}</span>;
      case 'bca':
        return <span>{feed.branded_content_data[key]}</span>;
    }
  }

  getTotalConversions(brandedContentData) {
    let total = 0;
    if (this.props.tab !== 'organic' && Object.keys(brandedContentData.conversions).length > 0) {
      total += Object.values(brandedContentData.conversions).reduce((a, b) => a + b);
    }
    if (this.props.tab !== 'bca' && Object.keys(brandedContentData.ga_conversions).length > 0) {
      total += Object.values(brandedContentData.ga_conversions).reduce((a, b) => a + b);
    }
    return total;
  }

  render() {
    const { classes } = this.props;
    // console.log("Reach count :", feed.branded_content_data['reach'])
    // console.log("Reach Views :", feed.branded_content_data['reach'] * 0.86)
    return (
      <TableContainer component={Paper}>
        <Table stickyHeader className={classes.table} aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>Post Name</TableCell>
              <TableCell className={classes.headerCell}>Post Type</TableCell>
              <TableCell className={classes.headerCell}>User</TableCell>
              {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('Impressions') ||
                this.props.allowedTableColumns.includes('Impressions') ? (
                  <TableCell className={classes.headerCell}>Impressions</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('Reach') ||
                this.props.allowedTableColumns.includes('Reach') ? (
                  <TableCell className={classes.headerCell}>Reach</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'bca', 'organic'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('Total Engagement') ||
                this.props.allowedTableColumns.includes('Total Engagement') ? (
                  <TableCell className={classes.headerCell}>Total Engagement</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'bca', 'organic'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('Video Views') ||
                this.props.allowedTableColumns.includes('Video Views') ? (
                  <TableCell className={classes.headerCell}>Video Views</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'bca', 'organic'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('Clicks') ||
                this.props.allowedTableColumns.includes('Clicks') ? (
                  <TableCell className={classes.headerCell}>Clicks</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('Conversions') ||
                this.props.allowedTableColumns.includes('Conversions') ? (
                  <TableCell className={classes.headerCell}>Conversions</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'bca', 'organic'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('Spend') ||
                this.props.allowedTableColumns.includes('Spend') ? (
                  <TableCell className={classes.headerCell}>Spend</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['story'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('Replies') ||
                this.props.allowedTableColumns.includes('Replies') ? (
                  <TableCell className={classes.headerCell}>Replies</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['story'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('Link Clicks') ||
                this.props.allowedTableColumns.includes('Link Clicks') ? (
                  <TableCell className={classes.headerCell}>Link Clicks</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'story'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('Shares') ||
                this.props.allowedTableColumns.includes('Shares') ? (
                  <TableCell className={classes.headerCell}>Shares</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'story'].includes(this.props.tab) ? (
                // this.state.columnsArray.includes("Views") ||
                // this.props.allowedTableColumns.includes("Views") ? (
                <TableCell className={classes.headerCell}>Views</TableCell>
              ) : (
                // ) : (
                //   ""
                // )
                ''
              )}
              {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('Saved') ||
                this.props.allowedTableColumns.includes('Saved') ? (
                  <TableCell className={classes.headerCell}>Saved</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'bca', 'organic'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('Link Clicks') ||
                this.props.allowedTableColumns.includes('Link Clicks') ? (
                  <TableCell className={classes.headerCell}>Link Clicks</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'story'].includes(this.props.tab) ? (
                // this.state.columnsArray.includes("Likes") ||
                // this.props.allowedTableColumns.includes("Likes") ? (
                <TableCell className={classes.headerCell}>Likes</TableCell>
              ) : (
                // ) : (
                //   ""
                // )
                ''
              )}
              {['feed'].includes(this.props.tab) ? (
                // this.state.columnsArray.includes("Comments") ||
                // this.props.allowedTableColumns.includes("Comments") ? (
                <TableCell className={classes.headerCell}>Comments</TableCell>
              ) : (
                // ) : (
                //   ""
                // )
                ''
              )}
              {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('Sticker Taps') ||
                this.props.allowedTableColumns.includes('Sticker Taps') ? (
                  <TableCell className={classes.headerCell}>Sticker Taps</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('Reel Plays') ||
                this.props.allowedTableColumns.includes('Reel Plays') ? (
                  <TableCell className={classes.headerCell}>Reel Plays</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('Audience Retention') ||
                this.props.allowedTableColumns.includes('Audience Retention') ? (
                  <TableCell className={classes.headerCell}>Audience Retention</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('CPM') ||
                this.props.allowedTableColumns.includes('CPM') ? (
                  <TableCell className={classes.headerCell}>CPM</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('CPV') ||
                this.props.allowedTableColumns.includes('CPV') ? (
                  <TableCell className={classes.headerCell}>CPV</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('CPE') ||
                this.props.allowedTableColumns.includes('CPE') ? (
                  <TableCell className={classes.headerCell}>CPE</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                this.state.columnsArray.includes('CPC') ||
                this.props.allowedTableColumns.includes('CPC') ? (
                  <TableCell className={classes.headerCell}>CPC</TableCell>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.feeds.map((feed) => (
              <TableRow key={feed.name}>
                <TableCell
                  component="th"
                  scope="feed"
                  className={classes.cell}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.openLink(feed.url);
                  }}
                >
                  <Grid container spacing={3} direction="row">
                    <Grid item={3}>
                      {/* {feed.thumbnail ? (
                        <img src={feed.thumbnail} height={48} weight={48} />
                      ) : null} */}
                    </Grid>
                    <Grid
                      item={9}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      {!feed.thumbnail ? (
                        <div
                          style={{
                            padding: '5px',
                            margin: '5px',
                            background: '#e8902c',
                            borderRadius: '5.12839px',
                            // width: "54px",
                            'justify-content': 'center',
                            'align-items': 'center',
                          }}
                        >
                          <p
                            style={{
                              color: '#FFFFFF',
                              // color: "black",
                              margin: '5px',
                              fontSize: '12px',
                              fontWeight: 600,
                            }}
                          >
                            TAP TO VIEW
                          </p>
                        </div>
                      ) : null}
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Grid
                          item
                          md={12}
                          style={{
                            wordBreak: 'normal',
                            width: 180,
                            textAlign: 'left',
                          }}
                        >
                          <span>{this.ellipsis(feed.title)}</span>
                        </Grid>
                        <Grid item md={12}>
                          <span>
                            <i>Added on: {feed.added_on}</i>
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {feed.name}
                </TableCell>
                <TableCell className={classes.cell}>{feed.post_type}</TableCell>
                <TableCell className={classes.cell}>{feed.user_name}</TableCell>
                {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('Impressions') ||
                  this.props.allowedTableColumns.includes('Impressions') ? (
                    <TableCell className={classes.cell}>
                      {this.getBrandedContentColumnValue(feed, 'impressions')}
                    </TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('Reach') ||
                  this.props.allowedTableColumns.includes('Reach') ? (
                    <TableCell className={classes.cell}>
                      {feed.reach > 0
                        ? Math.trunc(feed.reach)
                        : Math.trunc(feed.video_views * 0.86)}
                    </TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {['feed', 'bca', 'organic'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('Total Engagement') ||
                  this.props.allowedTableColumns.includes('Total Engagement') ? (
                    <TableCell className={classes.cell}>
                      {feed.like_count + feed.comment_count + feed.saved ? (
                        <span>
                          {feed.like_count + feed.comment_count + feed.saved}
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <div>
                                  <b>Likes</b>: {feed.like_count}
                                </div>
                                <div>
                                  <b>Comment</b>: {feed.comment_count}
                                </div>
                                <div>
                                  <b>Saved</b>: {feed.saved}
                                </div>
                              </React.Fragment>
                            }
                          >
                            <Button>
                              <InfoOutlinedIcon fontSize="small" />
                            </Button>
                          </HtmlTooltip>
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {['feed', 'bca', 'organic'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('Video Views') ||
                  this.props.allowedTableColumns.includes('Video Views') ? (
                    <TableCell className={classes.cell}>{feed.video_views}</TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {['feed', 'bca', 'organic'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('Clicks') ||
                  this.props.allowedTableColumns.includes('Clicks') ? (
                    <TableCell className={classes.cell}>
                      {feed.branded_content_data ? feed.branded_content_data.clicks : '-'}
                    </TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('Conversions') ||
                  this.props.allowedTableColumns.includes('Conversions') ? (
                    <TableCell className={classes.cell}>
                      {feed.branded_content_data ? (
                        <span>
                          {this.getTotalConversions(feed.branded_content_data)}
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                {this.props.tab !== 'organic'
                                  ? Object.keys(feed.branded_content_data.conversions).map(
                                      (key) => (
                                        <div>
                                          <b>{key}</b>: {feed.branded_content_data.conversions[key]}
                                        </div>
                                      ),
                                    )
                                  : ''}
                                {this.props.tab !== 'bca'
                                  ? Object.keys(feed.branded_content_data.ga_conversions).map(
                                      (key) => (
                                        <div>
                                          <b>{key}</b>:{' '}
                                          {feed.branded_content_data.ga_conversions[key]}
                                        </div>
                                      ),
                                    )
                                  : ''}
                              </React.Fragment>
                            }
                          >
                            <Button>
                              <InfoOutlinedIcon fontSize="small" />
                            </Button>
                          </HtmlTooltip>
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {['feed', 'bca', 'organic'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('Spend') ||
                  this.props.allowedTableColumns.includes('Spend') ? (
                    <TableCell className={classes.cell}>
                      {feed.branded_content_data ? feed.branded_content_data.spend : '-'}
                    </TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {['story'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('Replies') ||
                  this.props.allowedTableColumns.includes('Replies') ? (
                    <TableCell className={classes.cell}>{feed.replies}</TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {['story'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('Link Clicks') ||
                  this.props.allowedTableColumns.includes('Link Clicks') ? (
                    <TableCell className={classes.cell}>{feed.link_clicks}</TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {['feed', 'story'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('Shares') ||
                  this.props.allowedTableColumns.includes('Shares') ? (
                    <TableCell className={classes.cell}>{feed.shares}</TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {['feed', 'story'].includes(this.props.tab) ? (
                  // this.state.columnsArray.includes("Views") ||
                  // this.props.allowedTableColumns.includes("Views") ? (
                  <TableCell className={classes.cell}>{feed.video_views}</TableCell>
                ) : (
                  // ) : (
                  //   ""
                  // )
                  ''
                )}

                {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('Saved') ||
                  this.props.allowedTableColumns.includes('Saved') ? (
                    <TableCell className={classes.cell}>{feed.saved}</TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}

                {['feed', 'bca', 'organic'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('Link Clicks') ||
                  this.props.allowedTableColumns.includes('Link Clicks') ? (
                    <TableCell className={classes.cell}>{feed.link_clicks}</TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}

                {['feed', 'story'].includes(this.props.tab) ? (
                  // this.state.columnsArray.includes("Likes") ||
                  // this.props.allowedTableColumns.includes("Likes") ? (
                  <TableCell className={classes.cell}>{feed.like_count}</TableCell>
                ) : (
                  // ) : (
                  //   ""
                  // )
                  ''
                )}

                {['feed'].includes(this.props.tab) ? (
                  // this.state.columnsArray.includes("Comments") ||
                  // this.props.allowedTableColumns.includes("Comments") ? (
                  <TableCell className={classes.cell}>{feed.comment_count}</TableCell>
                ) : (
                  // ) : (
                  //   ""
                  // )
                  ''
                )}

                {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('Sticker Taps') ||
                  this.props.allowedTableColumns.includes('Sticker Taps') ? (
                    <TableCell className={classes.cell}>{feed.sticker_taps}</TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}

                {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('Reel Plays') ||
                  this.props.allowedTableColumns.includes('Reel Plays') ? (
                    <TableCell className={classes.cell}>{feed.reel_plays}</TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}

                {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('Audience Retention') ||
                  this.props.allowedTableColumns.includes('Audience Retention') ? (
                    <TableCell className={classes.cell}>{feed.audience_ret}</TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}

                {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('CPM') ||
                  this.props.allowedTableColumns.includes('CPM') ? (
                    <TableCell className={classes.cell}>{feed.cpm}</TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}

                {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('CPV') ||
                  this.props.allowedTableColumns.includes('CPV') ? (
                    <TableCell className={classes.cell}>{feed.cpv}</TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}

                {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('CPE') ||
                  this.props.allowedTableColumns.includes('CPE') ? (
                    <TableCell className={classes.cell}>{feed.cpe}</TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}

                {['feed', 'bca', 'organic', 'story'].includes(this.props.tab) ? (
                  this.state.columnsArray.includes('CPC') ||
                  this.props.allowedTableColumns.includes('CPC') ? (
                    <TableCell className={classes.cell}>{feed.cpc}</TableCell>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default withStyles(styles, {})(InstagramPostTable);
