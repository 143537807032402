import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

function Link({ children, selected }) {
  const classes = useStyles();
  return (
    <Grid item className={classNames(classes.root, selected ? classes.selected : null)}>
      <Typography className={classes.name}>{children}</Typography>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '3px !important',
    paddingBottom: '3px !important',
    cursor: 'pointer',
  },
  selected: {
    border: '3px solid #FEBD1C',
    boxShadow:
      '-5px 5px 10px rgba(210, 211, 219, 0.2), 5px -5px 10px rgba(210, 211, 219, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(210, 211, 219, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(210, 211, 219, 0.5)',
    borderRadius: '20px',
  },
  name: {
    fontSize: 14,
  },
}));

export default Link;
