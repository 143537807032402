import React from 'react';
import { Grid } from '@material-ui/core';

import DiscoveryCountDisplay from './DiscoveryCountDisplay';

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import UserIcon from '../../../assets/User.png';
import ViewIcon from '../../../assets/View.png';
import EngagementIcon from '../../../assets/Engagement.png';
import EngagementRateIcon from '../../../assets/EngagementRate.png';
import CPVIcon from '../../../assets/CPV.png';
import CPEIcon from '../../../assets/CPE.png';

class DiscoverySocialMediaSummery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      followers:
        this.props.socialPlatform == 'instagram'
          ? this.props.instagram_follower_count
          : this.props.youtube_subscribers,
      avg_views:
        this.props.socialPlatform == 'instagram'
          ? this.props.instagram_avg_views
          : this.props.youtube_avg_views,
      avg_engagement:
        this.props.socialPlatform == 'instagram'
          ? this.props.instagram_avg_engagement
          : this.props.youtube_avg_engagement,
      engagement_rate:
        this.props.socialPlatform == 'instagram'
          ? this.props.instagram_engagement_rate
          : this.props.youtube_engagement_rate,
      estimated_cpv:
        this.props.socialPlatform == 'instagram'
          ? this.props.instagram_estimated_cpv
          : this.props.youtube_estimated_cpv,
      estimated_cpe:
        this.props.socialPlatform == 'instagram'
          ? this.props.instagram_estimated_cpe
          : this.props.youtube_estimated_cpe,
      follower_label: this.props.socialPlatform == 'instagram' ? 'Followers' : 'Subscribers',
    };
  }
  render() {
    // console.log(this.props,this.props.socialPlatform == 'instagram',this.state.followers,this.onChange());
    return (
      <Grid
        container
        justify="space-evenly"
        alignItems="center"
        style={{
          background: '#FFFFFF',
          height: '50px',
          paddingLeft: '5px',
          marginTop: '5px',
          fontSize: '7px',
        }}
      >
        <Grid item md={3}>
          <DiscoveryCountDisplay
            icon={UserIcon}
            label={this.state.follower_label}
            count={this.state.followers}
            color={'#7158F1'}
          />
        </Grid>
        <Grid item md={3}>
          <DiscoveryCountDisplay
            icon={ViewIcon}
            label={'Avg View'}
            count={this.state.avg_views}
            color={'#7158F1'}
          />
        </Grid>
        <Grid item md={3}>
          <DiscoveryCountDisplay
            icon={EngagementIcon}
            label={'Avg Engagement'}
            count={this.state.avg_engagement}
            color={'#7158F1'}
          />
        </Grid>
        <Grid item md={3}>
          <DiscoveryCountDisplay
            icon={EngagementRateIcon}
            label={'Engagement Rate'}
            count={this.state.engagement_rate}
            color={'#7158F1'}
          />
        </Grid>
      </Grid>
    );
  }
}

export default DiscoverySocialMediaSummery;
