import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { authHandlers } from 'api_handlers/auth';


const AuthResetPassword = ({ goToDiscover, showDomainInvalidModal, goToForgotPassword, ...others }) => {
  const theme = useTheme();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [errors, setErrors] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailSent, isEmailSent] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const forgotPassword = (e) => {
    e.preventDefault();
    let isValidationError = false;
    if (!(email && email.length > 0)) {
        setEmailError(true);
        isValidationError = true;
    }
    if (isValidationError) {
        return;
    }
    setIsSubmitting(true);
    isEmailSent(false);
    authHandlers.resetPassword({email})
        .then(async (response) => {
            if ([200, 201].includes(response.status)) {
                isEmailSent(true);
            } else if ([400, 401, 402, 403].includes(response.status)) {
                const data = await response.json();
                setErrors({ 'non_field_errors': [data.message] });
                setIsSubmitting(false);
            } else {
              setErrors({ 'non_field_errors': ['Please Try Again'] });
              setIsSubmitting(false);
            }
        })
        .catch((e)=>{
            setIsSubmitting(false);
            setErrors({ 'non_field_errors': ['Please Try Again'] });
        });
  }

  return (
    <>
      <form noValidate onSubmit={forgotPassword}>
        <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={emailError}>
            <InputLabel htmlFor="outlined-adornment-email-login">Email Address</InputLabel>
            <OutlinedInput
                id="outlined-adornment-email-login"
                type="email"
                name="email"
                label="Email Address"
                inputProps={{}}
                onChange={(e)=>{
                    let value = e.target.value;
                    setEmailError(false);
                    if (!(value && value.length > 0)) {
                        setEmailError(true);
                    }
                    setEmail(value);
                }}
                />
            {emailError && (
                <FormHelperText error id="standard-weight-helper-text-email-login">
                Please Enter Valid Email
                </FormHelperText>
            )}
            </FormControl>
            <Box sx={{ mt: 2 }}>
                <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="secondary">
                    Send email
                </Button>
            </Box>
            {
                emailSent ? 
                <Box sx={{ mt: 2 }}>
                    <Typography style={{ color: 'green' }}>
                        Check your inbox, we have sent a password reset link.
                    </Typography>
                </Box> : null
            }
            {
                errors ? 
                <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{'non_field_errors' in errors ? errors['non_field_errors'].map(each=>{
                        return each;
                    }) :
                    <ul>
                    {Object.keys(errors).map((key) => {
                      const error = errors[key];
                      if (Array.isArray(error)) {
                        return (
                          <li style={{ fontSize: 12 }}>
                            {error}
                          </li>
                        );
                      } else {
                        return (
                          <li style={{ fontSize: 12 }}>
                            {Object.values(error).join(',')}
                          </li>
                        );
                      }
                    })}
                  </ul>}</FormHelperText>
                </Box> : null
            }
      </form>
    </>
  );
};

export default AuthResetPassword;