
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from 'ui-component/extended/Chip';

import { updateFilters } from 'actions/planner-filters';

const EXCLUDE_KEYS = ['sortBy', 'posts', 'bio', 'category', 'ids']
const NON_CANCELABLE_KEYS = ['budget', 'maxPricePerCreator', 'megaBudget', 'macroBudget', 'microBudget', 'nanoBudget']


function camelCaseToPhrase(camelCaseStr) {
  // Replace capital letters with space followed by the lowercase letter
  try{
    if(!isNaN(camelCaseStr) || !isNaN(parseFloat(camelCaseStr))) return camelCaseStr
    const result = camelCaseStr.replace(/([A-Z])/g, ' $1');
    // Capitalize the first letter
    return result.charAt(0).toUpperCase() + result.slice(1);
  } catch(e) {
    console.log("Error in camelCaseToPhrase", camelCaseStr, e);
    return camelCaseStr;
  }
}


const AppliedFilters = () => {
  const dispatch = useDispatch();
  const plannerFilters = useSelector((state) => state.plannerFilters);


  const filters = useMemo(() => {
    const filterValues = {};

    Object.keys(plannerFilters).forEach(key => {
      if(!plannerFilters[key]) return;
      if (EXCLUDE_KEYS.includes(key)) return;
      if (plannerFilters[key] === null) return;
      if(key === 'platform' && plannerFilters[key] === 'instagram') return;
      filterValues[key] = plannerFilters[key];
    });

    return filterValues;

  }, [plannerFilters]);

  const handleDelete = (key) => {
    dispatch(updateFilters(key, null, true));
  };
  


  return Object.keys(filters).length > 0 ? <Grid item xs={12} md={12}>
      <Grid container spacing={1} alignItems={'center'}>
        {Object.keys(filters).length > 0 && <Grid item>
          <Typography variant='h5' fontWeight={700} fontSize={'0.8rem'}>
            Applied Filters:
          </Typography>
        </Grid>}
        {Object.keys(filters).map(key => {
          const value = filters[key];
          return (<Grid item>
              <Chip
                variant='outlined'
                chipcolor='secondary'
                label={`${camelCaseToPhrase(key)} = ${camelCaseToPhrase(value)}`}
                sx={{
                  '& .MuiChip-label': {fontSize: '0.7rem', fontWeight: 700},
                  '& .MuiChip-deleteIcon': {height: '15px', width: '15px'},
                  height: '25px',
                }}
                {...(NON_CANCELABLE_KEYS.includes(key) ? {} : {onDelete: () => handleDelete(key)})}
              />
          </Grid>)
        })}
      </Grid>
    </Grid> : <></>
}

export default AppliedFilters;