import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MultiSelect from 'components/Theme/Elements/MultiSelect';

import Filter from '../Filter';

import InterestIcon from 'assets/discovery/interest-icon.png';

function AudienceInterestFilter({ value, setFilters, interests }) {
  const classes = useStyles();

  function handleFilter(event, selectedValues) {
    setFilters({
      audienceInterest: selectedValues,
    });
  }

  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Filter label={'Audience Interest'} labelIconSrc={InterestIcon}>
          <Grid container className={classes.filterContainer}>
            <MultiSelect
              options={interests.map((interest) => ({ label: interest, value: interest }))}
              value={value || []}
              onChange={handleFilter}
            />
          </Grid>
        </Filter>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {},
}));

export default AudienceInterestFilter;
