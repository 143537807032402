import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, CardContent, Grid, Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import CountDisplay from '../../../ux/CountDisplay';

import CPVIcon from '../../../../assets/CPV.png';
import CPEIcon from '../../../../assets/CPE.png';

import { getInstagramCostStatistic } from '../../../../api_handlers/reporting';

class CostStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_reach: 0,
      total_impressions: 0,
      total_replies: 0,
      total_taps_forward: 0,
      loading: true,
    };
  }

  async getData() {
    const resp = await getInstagramCostStatistic(this.props.campaignId);
    this.setState({ ...resp.data, loading: false });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  render() {
    const Icon = this.props.icon;
    return (
      <Grid container spacing={1}>
        <Grid item md={12}>
          <Card style={{ height: 133 }}>
            <CardContent>
              {this.state.loading ? (
                <CircularProgress style={{ marginTop: 5 }} />
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginTop: 20 }}
                >
                  <Grid item md={5}>
                    <CountDisplay
                      icon={CPVIcon}
                      label={'Story Impressions'}
                      count={this.state.total_impressions}
                      color={'#7158F1'}
                    />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item md={5}>
                    <CountDisplay
                      icon={CPEIcon}
                      label={'Story Reach'}
                      count={this.state.total_reach}
                      color={'#7158F1'}
                    />
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12}>
          <Card style={{ height: 133 }}>
            <CardContent>
              {this.state.loading ? (
                <CircularProgress style={{ marginTop: 5 }} />
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginTop: 20 }}
                >
                  <Grid item md={5}>
                    <CountDisplay
                      icon={CPVIcon}
                      label={'Story Replies'}
                      count={this.state.total_replies}
                    />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item md={5}>
                    <CountDisplay
                      icon={CPEIcon}
                      label={'Story Links Click'}
                      count={this.state.total_taps_forward}
                    />
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
        {/*                <Grid item md={12}>
                    <Card>
                        <CardContent>
                            {this.state.loading ? (
                                <CircularProgress style={{ marginTop: 5 }} />
                            ) : (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item md={5}>
                                        <CountDisplay
                                            label={"Total Shares"}
                                            count={this.state.total_shares}
                                        />
                                    </Grid>
                                    <Divider orientation="vertical" flexItem />
                                    <Grid item md={5}>
                                        <CountDisplay
                                            label={"Total Saves"}
                                            count={this.state.total_saves}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </CardContent>
                    </Card>
                </Grid>*/}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
  };
};

export default connect(mapStateToProps)(withRouter(CostStatistics));
