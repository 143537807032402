import { useState, useEffect } from 'react';
import { Typography, Grid, Modal, Divider, TextField, Snackbar, Text, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CampaignIcon from 'assets/discovery/Campaign-Icon.png';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Button from 'components/Theme/Elements/Button';
import { getDiscoveryInfluencersDetails, sendWhatsappMessage } from 'api_handlers/discovery';
import { addInfluencerToCampaign, createCampaign,campaignHandlers } from 'api_handlers/campaign';
import { ThemeProvider } from '@material-ui/core';
import themeV2 from 'themeV2';
import { Alert, MenuItem, Select } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CampaignList from 'components/campaign/cards/CampaignList';
import TaskAltIcon from '@mui/icons-material/TaskAlt';



const template_dict = {
  "HXf2c8659547d7af331fe5d11761c50320": "Hello Influencer,\n\nWe have an exclusive opportunity for you in Upcoming Campaign by Influencer.in. Let's team up to create something amazing. Discover the campaign details at https://product.influencer.in/. Excited to discuss further!\n\nWarm regards,\nTeam Influencer",
  "HX8340e6ffec177e070750a1897792119c": "Hi Influencer,\n\nGreat news! We are thrilled to inform you that you've been shortlisted for our upcoming campaign. Your unique style and audience engagement stood out, and we believe you'd be an excellent fit for this project. We'll be in touch soon with more details about the campaign and the next steps.\n\nCongratulations, and thank you for your interest!\nTeam Influencer.in",
  "HX20ec9cff48ea96ae85171e0f5c680119": "Hey Influencer,\n\nCongratulations on being shortlisted for our upcoming campaign with Influencer.in! We're excited to have you on board for the Upcoming Campaign. It's time for product selection. Please visit the campaign page at https://product.influencer.in/. to choose the products you'd like to feature in your content.\n\nIf you have any questions or need assistance, feel free to reach out to us. We can't wait to see the magic you'll create with our products!\n\nBest regards,\nTeam Influencer.in",
  "HXd8354ef6be43f922ced13776313dfcb3": "Hi Influencer,\n\nWe're delighted to inform you that your selected products for the Upcoming Campaign have been dispatched and are on their way to you. You should expect delivery within 2 Days.\n\nIf you have any concerns or questions regarding the delivery, please don't hesitate to reach out to us. We look forward to seeing your amazing content!\n\nBest regards,\nTeam Influencer.in",
  "HXbc01316b0bbf37c765035414b8301706": "Hi Influencer,\n\nWe hope you're doing well. It's time for content submission for the Upcoming Campaign. We can't wait to see what you've created with our products. Please send us the content before end of the campaign, including Specific Requirements.\n\nYou can submit your content through upload section. If you encounter any issues or have questions, don't hesitate to reach out to us. Thank you for your hard work and dedication to the campaign.\n\nBest regards,\nTeam Influencer.in",
  "HXb6613d2eb00572fe1696bf636d0b054f": "Hi Influencer,\n\nWe're thrilled to inform you that your submitted content for the Upcoming Campaign has been approved. We love what you've created, and we believe your audience will too. You are now clear to publish your content on your chosen platform.\n\nPlease go ahead and schedule your publication, making sure it aligns with the agreed-upon timeline. If you have any questions or need any additional support, please don't hesitate to reach out.\n\nThank you for your hard work and collaboration!\nTeam Influencer.in",
  "HX5df25eeed65f7dae52910656cf3a9986": "Hi Influencer,\n\nWe're pleased to inform you that your payment for the Upcoming Campaign has been processed. You should receive the payment in your account within 2-3 Working Days. We appreciate your hard work and dedication to the campaign.\n\nOnce you've received the payment, please confirm it by replying to this message or sending a separate confirmation email. If you have any questions or concerns, don't hesitate to reach out.\n\nThank you for your collaboration!\nTeam Influencer.in"
}

function BroadcastModal({
  communicationModalOpen = false,
  setCommunicationModalOpen,
  selectedInfluencer,
  recommendation,
  setSelectedInfluencer,
  influencers,
}) {
  const classes = useStyles();
  const [message,setMassege]=useState('')
  var [influenerDetail, setInfluenerDetail] = useState([]);
  var [contactDetails, setContactDetails] = useState([]);
  var [templatesid, setTemplatesid] = useState([]);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorCampaignModalOpen, setErrorCampaignModalOpen] = useState(false);
  const [successCampaignModalOpen, setSuccessCampaignModalOpen] = useState(false);
  const [campaign, setCampaign] = useState('');
  const [campaign_name, setCampaignName] = useState('');
  const [error_message, setErrorMessage] = useState('');
  const [campaignList,setCampaignList]=useState([])
  const [campaignId,setCampaignId]=useState()
  const [customContactNumber,setCustomContactNumber]=useState('');
  const [customContactList,setCustomContactList]=useState([]);
  const [influencerIds,setInfluencerIds]=useState([])
  const list=[]
  var contactList=[]

  useEffect(() => {

    campaignHandlers.getCampaigns().then((result) => {
      setCampaignList([...result]);
    });

    let contact = []
    var influecnerIds = []
     selectedInfluencer.map(influencers=>{
      getDiscoveryInfluencersDetails(influencers).then((response) => {
        list.push(response.name)
        setInfluenerDetail([...list])
        if (response.contact_details && response.contact_details.length > 0) {
          contact.push(response.contact_details);
          influecnerIds.push(response.influencer);
        }
      });
    })

    setContactDetails(contact);
    setInfluencerIds(influecnerIds);

  }, []);

  const errorToggleModal = async () => {
    setErrorModalOpen(!errorModalOpen);
  };

  const successToggleModal = async () => {
    if (successModalOpen) {
      setCommunicationModalOpen(false);
    }
    setSuccessModalOpen(!successModalOpen);
  };

  const errorCampaignToggleModal = async () => {
    setErrorCampaignModalOpen(!errorModalOpen);
  };

  const successCampaignToggleModal = async () => {
    if (successCampaignModalOpen) {
      setCampaign(campaign_name);
    }
    setSuccessCampaignModalOpen(!successModalOpen);
  };

  const getBrandName = () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      return user.user_type === 'brand' ? (user.brand.name ? user.brand.name+'.' : 'Influencer.in.') : 'Influencer.in.';
    } catch {
      return 'Influencer.in.'
    }
  }

  return (
    <ThemeProvider theme={themeV2}>
          <Modal open={communicationModalOpen} onClose={setCommunicationModalOpen} className={classes.base}>
      <Grid container className={classes.root}>
        <Grid item md={12}>

        <Grid container className={classes.header}>
            <Grid item md={3}>
              <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                  <Typography variant="h3">Broadcast</Typography>
                </Grid>
                <Grid item>
                  <img src={CampaignIcon} className={classes.campaignIcon} alt="icon" />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              className={classes.closeContainer}
              onClick={() => setCommunicationModalOpen(false)}
            >
              <Grid container alignItems="center">
                <Grid item>
                  <Typography variant="h3">Close</Typography>
                </Grid>
                <Grid item>
                  <HighlightOffIcon className={classes.closeIcon} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />

          <Grid container className={classes.body} spacing={1}>
            <Grid item md={6}>
              <Typography variant="h4">Shortlisted Influencers</Typography>
            </Grid>
            <Grid item md={6}>
                <Grid container direction='column' alignItems='center'>
                  <Grid item>
                     <Typography 
                     style={{
                      border:'1px solid #000000',
                      height:20,
                      width:150,
                      padding:5,
                      textAlign:'center'
                     }}
                     >Custom contact</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container justifyContent='center' alignItems='center'>
                      <Grid item>
                      <TextField 
                      variant='outlined'
                      onChange={(evt)=>{
                        setCustomContactNumber(evt.target.value)
                      }}
                      placeholder='Add a number - 98xxxxx'>

                      </TextField>
                      </Grid>
                      {/* <Grid item>
                      <IconButton color='primary'
                        onClick={()=>{
                          setCustomContactList(customContactNumber.split(','));     
                        }}>
                        <TaskAltIcon></TaskAltIcon>
                      </IconButton>
                      </Grid> */}

                    </Grid>
                    
                  </Grid>
                </Grid>
            </Grid>
            <Grid item md={12}>
              <Grid container className={classes.cardContainer}>
                {/* {shortlistedInfluencers.map((shortInf, i) => (
                  <Grid item md={12} key={i}>
                    <ShortlistedInfluencerCard {...shortInf} selectedInfluencer={selectedInfluencer} />
                  </Grid>
                ))} */}
                <Grid item md={3} className={classes.influencerNameContainer}>
                  <Grid container  justifyContent='flex-start'>
                  <Grid item md={12}><Typography style={{}} variant='h3'>Influencer Name</Typography></Grid>
                  <Grid item>
                {
                  influenerDetail.map((selectedInfluencerName,i)=>{
                    return <Grid container style={{marginTop:5}}>
                      <Grid item md={12}>
                        
                     <Typography className={classes.influencerName}>{selectedInfluencerName}</Typography>

                      </Grid>
                    </Grid>
                      })
                }
                  </Grid>
                </Grid>
                  </Grid>
                  <Grid item md={9} style={{ paddingLeft: 10 }}>
                    {campaign.length == 0 ? 
                    <Grid container justifyContent='flex-end'>
                      <TextField 
                        style={{ width: '100%', marginBottom: 10 }}                        
                        placeholder="Campaign"
                        variant="outlined"
                        value={campaign_name}
                        onChange={(evt) => setCampaignName(evt.target.value)}
                        ></TextField>
                        <Snackbar open={errorCampaignModalOpen} autoHideDuration={1500} onClose={errorCampaignToggleModal}>
                          <Alert onClose={errorCampaignToggleModal} severity="error">
                            {error_message}
                          </Alert>
                        </Snackbar>
                        <Snackbar open={successCampaignModalOpen} autoHideDuration={1000} onClose={successCampaignToggleModal}>
                          <Alert onClose={successCampaignToggleModal} severity="success">
                            Campaign added successfully
                          </Alert>
                        </Snackbar>
                      <Button style={{textTransform: "none"}} variant="contained" color="primary"
                        onClick={()=>{
                          if (campaign_name && campaign_name.length > 0) {
                            let date = new Date();
                            var yyyy = date.getFullYear().toString();
                            var mm = (date.getMonth()+1).toString();
                            var dd  = date.getDate().toString();

                            var mmChars = mm.split('');
                            var ddChars = dd.split('');

                            date = yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);
                            createCampaign({"name": campaign_name, "start_date": date, "end_date": date}).then((response)=>{
                              if (response.status == 400) {
                                setErrorCampaignModalOpen(true)
                                setErrorMessage("Please Try Again Later");
                              } else {
                                (async () => {
                                  let data = await response.json();
                                  setCampaignId(data.id)
                                  setCampaign(data.name)
                                  addInfluencerToCampaign(data.id, { profiles: selectedInfluencer }).then(() =>
                                    setSuccessCampaignModalOpen(true)
                                  )
                                })();
                              }
                            }) 
                          } else {
                            setErrorCampaignModalOpen(true)
                            setErrorMessage("Please Enter Title");
                          }
                        }}>
                        Add To Campaign
                      </Button>
                    </Grid> : 
                    <Grid container >
                          <Grid item md={12} style={{justifySelf:'flex-end',}}>
                            <Typography >{campaign}</Typography>
                          </Grid>
                          <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Select Template</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={templatesid}
                                label="Select Template"
                                onChange={(event)=>{
                                  setTemplatesid(event.target.value);
                                }}
                              >
                              <MenuItem value="HXf2c8659547d7af331fe5d11761c50320">Campaign reachout</MenuItem>
                              <MenuItem value="HX8340e6ffec177e070750a1897792119c">Shortlisted</MenuItem>
                              <MenuItem value="HX20ec9cff48ea96ae85171e0f5c680119">Product selection</MenuItem>
                              <MenuItem value="HXd8354ef6be43f922ced13776313dfcb3">Product delivery confirmation</MenuItem>
                              <MenuItem value="HXbc01316b0bbf37c765035414b8301706">Content submission Deadline</MenuItem>
                              <MenuItem value="HXb6613d2eb00572fe1696bf636d0b054f">Content approval</MenuItem>
                              <MenuItem value="HX5df25eeed65f7dae52910656cf3a9986">Payment Confirmation</MenuItem>
                            </Select>
                          </FormControl>
                          <div style={{ padding: 10 }}>
                            {templatesid && templatesid.length > 0 ?
                              <p dangerouslySetInnerHTML={{__html: template_dict[templatesid].replace(/\n/g, "<br />").replace("Upcoming Campaign", campaign).replace("upcoming campaign", campaign).replace("Influencer.in.", getBrandName())}}></p>
                            : null}
                          </div>
                          <Grid item md={12}>
                            <Grid container justifyContent='flex-end'>
                              <Grid item style={{marginTop:10,marginRight:10}}>
                              <Snackbar open={errorModalOpen} autoHideDuration={1500} onClose={errorToggleModal}>
                                <Alert onClose={errorToggleModal} severity="error">
                                  Please select template first.
                                </Alert>
                              </Snackbar>
                              <Snackbar open={successModalOpen} autoHideDuration={1500} onClose={successToggleModal}>
                                <Alert onClose={successToggleModal} severity="success">
                                  Broadcast sent successfully
                                </Alert>
                              </Snackbar>
                              <Button style={{textTransform: "none"}} variant="contained" color="primary"
                                  onClick={()=>{
                                    if (templatesid && templatesid.length > 0) {
                                      if (customContactNumber == '') {
                                        influencerIds.map(each_id=>{
                                          sendWhatsappMessage('+919582489634', templatesid, each_id, campaignId, template_dict[templatesid].replace(/\n/g, "<br />"));
                                        })
                                      } else {
                                        var numberAndInfluencerIds = [];
                                        customContactNumber.split(',').map(each=>{
                                          influencerIds.map(each_id=>{
                                            numberAndInfluencerIds.push({ 'number': each, 'id': each_id });
                                          })
                                        })
                                        numberAndInfluencerIds.map(each=>{
                                          sendWhatsappMessage('+91'+each['number'].replaceAll(' ',''), templatesid, each['id'], campaignId, template_dict[templatesid].replace(/\n/g, "<br />"));
                                        })
                                      }
                                      setSuccessModalOpen(true);
                                      if (typeof window !== 'undefined') {
                                        window.location.href = '/brand/campaigns/'+campaignId+'/communication';
                                      }
                                    } else {
                                      setErrorModalOpen(true);
                                    }
                                  }}  
                                  >
                              Send
                            </Button>
                              </Grid>
                            </Grid>
                            </Grid>
                          </Grid>
                      }
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} >
            {
              successCampaignModalOpen==false?
              <Grid container justifyContent='center'>
              <Grid item md={12} className={classes.alternateOption}>
                    <Typography style={{alignContent:'center'}}>OR</Typography>
              </Grid>
              <Grid item md={12}>
                <Typography variant='h5' style={{marginTop:15}}>Select Campaign</Typography>
              </Grid>
              <Grid item md={12} >
                <Grid container
                style={{
                  overflowY:'scroll',
                  height: 300 ,     
                  padding:5,
                  marginTop:10,
                }}
                >
  
                {
                  campaignList.map((campaign,i)=>{
                    return(
                      <Grid container  className={classes.campaignListCard}
                      onClick={()=>{
                          setCampaignId(campaign.id)
                          setCampaign(campaign.name)
                          addInfluencerToCampaign(campaign.id, { profiles: selectedInfluencer }).then(() =>
                            setSuccessCampaignModalOpen(true)
                          )
                      }}
                      >
                      <Grid item md={8}>
                            <Typography variant='h6' style={{fontSize:18,fontWeight:700}}>{campaign.name}</Typography>
                      </Grid>   
                      <Grid item md={4}>
                      <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant='h6' style={{fontSize:18,fontWeight:700}}>Start Date : </Typography>
                          </Grid>
                          <Grid item>
  
                          <Typography variant='h6' style={{fontSize:20,fontWeight:500}}>{campaign.start_date}</Typography>
                          </Grid>
  
                      </Grid>
                      </Grid>   
                        <Grid item  className={classes.influencerType}>
                              <Typography style={{fontSize:15}}>Influencer Micro | Small</Typography>    
                        </Grid>    
                      </Grid>
                    )
                  })
                }
                </Grid>
              </Grid>
            </Grid>:''
            }
          
        </Grid>
      </Grid>
    </Modal>
    </ThemeProvider>

  );
}

const useStyles = makeStyles(() => ({
  base: {
    background: 'white',
    height: '90%',
    width: '90%',
    marginLeft: '10%',
    marginTop: '5%',
  },
  root: {
    background: '#F3F4FE',
    filter: 'drop-shadow(5px 8px 15px rgba(171, 171, 171, 0.25))',
    height: '90%',
    width: '90%',
    borderRadius: 10,
    padding: 40,
    overflowY: 'auto',
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  campaignIcon: {
    height: 30,
    width: 30,
  },
  divider: {
    border: '2px solid #FEBD1C',
    borderRadius: 10,
    background: '#FEBD1C',
  },
  body: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 25,
    height: 200,
    
  },
  cardContainer: {
    // overflowY: 'scroll',
    height: 100,
    marginTop:25,
  },
  closeContainer: {
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.9) 0%, #FBFCFF 100%)',
    boxShadow:
      '-1px 1px 2px rgba(215, 216, 220, 0.2), 1px -1px 2px rgba(215, 216, 220, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 7,
    paddingBottom: 7,
    cursor: 'pointer',
  },
  closeIcon: {
    fontSize: 14,
    color: '#757575',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 6,
  },
  influencerNameContainer:{
    borderRight:'1px solid #aaaaaa',
    overflowY:'scroll',
    height:150
  },
  chatContainer:{
    border:'1px solid #000000',
    width:'100%',
  },
  sendMessageContainer:{
    border:'1px solid #000000',
  },
  chatTextFieldContainer:{
    border:'1px solid #000000',
    background:'#eeeeee',
    width:'100%'
  },
  chatTextField:{

  },
  influencerName:{
    fontSize: 15,
    marginTop:3

  },
  alternateOption:{
    // background:'#000000',
    border: '1px solid #aaaaaa',
    borderRadius:10,
    maxWidth:100,
    height:40,
    textAlign:'center',
    marginTop:30,
    padding:6
  },
  campaignListCard:{
    background: '#F3F4FE',
    boxShadow:
      '-5px 5px 10px rgba(219, 220, 229, 0.2), 5px -5px 10px rgba(219, 220, 229, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(219, 220, 229, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(219, 220, 229, 0.5)',
    borderRadius:10,
    padding:20,
    marginTop:20,
    cursor:'pointer'
  },
  influencerType:{
    background: 'linear-gradient(180deg, #FEBD1C 0%, rgba(254, 189, 28, 0.1) 100%)',
    boxShadow:
    '-1px 1px 2px rgba(215, 216, 220, 0.2), 1px -1px 2px rgba(215, 216, 220, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius:5,
    marginTop:10,
    padding:10,
    alignItems:'center'

  }
}));

export default BroadcastModal;