import React from 'react';
import { Grid } from '@material-ui/core';

class ErrorsDiv extends React.Component {
  render() {
    return (
      <Grid container style={{ textAlign: 'left' }}>
        <ul>
          {Object.keys(this.props.errors).map((key) => {
            const error = this.props.errors[key];
            if (Array.isArray(error)) {
              return (
                <li style={{ fontSize: 12 }}>
                  {this.props.backendKeyMap[key] ? this.props.backendKeyMap[key] : key}: {error}
                </li>
              );
            } else {
              return (
                <li style={{ fontSize: 12 }}>
                  {this.props.backendKeyMap[key] ? this.props.backendKeyMap[key] : key}:{' '}
                  {Object.values(error).join(',')}
                </li>
              );
            }
          })}
        </ul>
      </Grid>
    );
  }
}

export default ErrorsDiv;
