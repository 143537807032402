import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MultiSelect from 'components/Theme/Elements/MultiSelect';

import Filter from '../Filter';

import CategoryIcon from 'assets/discovery/Category-Icon.png';

function CategoryFilter({ value, setFilters, categories }) {
  const classes = useStyles();
  // const [categories, setCategories] = useState([])

  function handleFilter(event, selectedValues) {
    setFilters({
      category: selectedValues,
    });
  }

  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Filter label={'Category'} labelIconSrc={CategoryIcon}>
          <Grid container className={classes.filterContainer}>
            <MultiSelect
              options={categories
                .filter((category) => category)
                .map((category) => ({ label: category, value: category }))}
              value={value || []}
              onChange={handleFilter}
            />
          </Grid>
        </Filter>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {},
}));

export default CategoryFilter;
