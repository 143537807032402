import { Component } from 'react';
import { Typography, Button, AppBar, Tabs, Tab, Box } from '@material-ui/core';
import Loading from '../../../components/Common/Loading/Loading';

import MobileEngagementStatistics from './cards/MobileEngagementStatistics';
import MobileStatistics from './cards/instagram/MobileStatistics';
import InstaPostCardMobile from './cards/instagram/InstaPostCardMobile';

import { getInfluencerInstagramStatistic } from '../../../api_handlers/reporting';

import InstagramLogo from '../../../assets/instagram-logo.png';
import YoutubeLogo from '../../../assets/youtube_logo_gray.png';
import MobileAddPost from '../modals/MobileAdPost';

import { NavLink } from 'react-router-dom';

const linkStyle = {
  padding: '10px 15px',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  background: '#F2F2F2',
  marginRight: '20px',
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class Feed extends Component {
  constructor(props) {
    super(props);

    this.formatCount = this.formatCount.bind(this);

    this.state = {
      modalOpen: false,
      info_modalOpen: false,
      message: null,
      is_loading: false,
      user_type: JSON.parse(localStorage.getItem('user')).user_type,
      stats: null,
      addPostModalOpen: false,
      selectedFeedTab: 0,
      currentIndex: 0,
    };
  }

  formatCount(count) {
    if (typeof count === 'string' && count.length > 0) {
      const lastChar = count[count.length - 1].toLocaleUpperCase();
      if (['B', 'M', 'K'].includes(lastChar)) return count;
    }

    var num;
    const parsedCount = parseFloat(count);
    if (parsedCount <= 1) {
      return parsedCount.toFixed(2);
    } else if (parsedCount > 9999 && parsedCount <= 999999) {
      num = parsedCount / 1000;
      if (num % 1 != 0) {
        num = num.toFixed(2);
      }
      return num + 'K';
    } else if (parsedCount > 999999) {
      num = parsedCount / 1000000;
      if (num % 1 != 0) {
        num = num.toFixed(2);
      }
      return num + 'M';
    } else if (parsedCount > 999999999) {
      num = parsedCount / 1000000000;
      if (num % 1 != 0) {
        num = num.toFixed(2);
      }
      return num + 'B';
    } else if (parsedCount === NaN) {
      return count;
    } else if (parsedCount && count % 1 != 0) {
      return count.toFixed(2);
    } else {
      return count;
    }
  }

  async getData() {
    const campaign_id = this.props.match.params.campaignId;
    const influencer_id = this.props.match.params.influencerId;
    const resp = await getInfluencerInstagramStatistic(campaign_id, influencer_id);
    this.setState({ stats: resp.data });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  render() {
    return (
      <div style={{ background: 'white', paddingTop: '20px' }}>
        {this.state.is_loading && <Loading />}
        <div style={{ marginLeft: '10px', display: 'flex' }}>
          <span style={linkStyle}>
            <img src={InstagramLogo} height={'20'} width={'20'} />
          </span>
          <NavLink
            to={`/influencer/campaigns/${this.props.match.params.campaignId}/feeds/${this.props.match.params.influencerId}/youtube`}
            style={linkStyle}
          >
            <img src={YoutubeLogo} height={'18'} width={'25'} />
          </NavLink>
        </div>
        <MobileEngagementStatistics
          campaign_id={this.props.match.params.campaignId}
          influencer_id={this.props.match.params.influencerId}
          stats={this.state.stats}
          formatCount={this.formatCount}
        />
        <MobileStatistics stats={this.state.stats} formatCount={this.formatCount} />
        <AppBar
          position="static"
          indicatorColor="primary"
          style={{
            backgroundColor: '#F3F3F3',
            color: '#333',
            display: 'block',
            width: 'calc(100% - 20px)',
            margin: '0px 10px',
          }}
        >
          <Tabs
            value={this.state.selectedFeedTab}
            onChange={(event, value) => {
              this.setState({ selectedFeedTab: value });
            }}
            aria-label="simple tabs example"
          >
            <Tab label="Feeds" id="feed-tab" />
            <Tab label="Stories" id="story-tab" />
            <Button
              variant="contained"
              size="small"
              style={{
                color: 'white',
                backgroundColor: '#1E9CB2',
                marginRight: '10px',
                position: 'absolute',
                right: '15px',
                top: '9px',
              }}
              onClick={() => {
                this.setState({ addPostModalOpen: !this.state.addPostModalOpen });
              }}
            >
              Add Post
            </Button>
            <MobileAddPost
              modalOpen={this.state.addPostModalOpen}
              onClose={() => {
                this.setState({ addPostModalOpen: false });
              }}
              campaignId={this.props.match.params.campaignId}
            />
          </Tabs>
        </AppBar>
        {this.state.selectedFeedTab == 0 ? (
          <InstaPostCardMobile
            campaignId={this.props.match.params.campaignId}
            influencerId={this.props.match.params.influencerId}
            storyTab={false}
          />
        ) : null}
        {this.state.selectedFeedTab == 1 ? (
          <InstaPostCardMobile
            campaignId={this.props.match.params.campaignId}
            influencerId={this.props.match.params.influencerId}
            storyTab={true}
          />
        ) : null}
      </div>
    );
  }
}

export default Feed;
