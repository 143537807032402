import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { deepOrange } from "@material-ui/core/colors";
import { useState } from 'react';
import insta_placeholder from '../../assets/discovery/insta_placeholder.png';
import ImageValidator from "components/ux/ImageValidator";
import { Button, Modal, Grid, Typography } from "@material-ui/core";
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom'
import { withRouter, NavLink } from 'react-router-dom';

import document_icon from '../../assets/document_icon.png';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // background: 'white',
      filter: 'drop-shadow(5px 8px 15px rgba(171, 171, 171, 0.25))',
      // height: 500,
      width: 'fit-content',
      borderRadius: 10,
      padding: 25,
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: 'auto',
      // overflowY: 'scroll',
    },
    messageRow: {
      display: "flex"
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end"
    },
    messageBlue: {
      position: "relative",
      marginLeft: "20px",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#A8DDFD",
      width: "90%",
      //height: "50px",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #97C6E3",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #A8DDFD",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        left: "-15px"
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #97C6E3",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px"
      }
    },
    messageOrange: {
      position: "relative",
      marginRight: "20px",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#f8e896",
      width: "60%",
      //height: "50px",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #dfd087",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #f8e896",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        right: "-15px"
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #dfd087",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        right: "-17px"
      }
    },

    messageContent: {
      padding: 0,
      margin: 0,
      wordWrap: 'break-word'
    },
    messageTimeStampRight: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: ".65em",
      fontWeight: "300",
      marginTop: "5px",
      marginRight: "5px"
    },

    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(4),
      height: theme.spacing(4)
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: theme.spacing(4),
      height: theme.spacing(4)
    },
    displayName: {
      marginLeft: "20px"
    },
    viewButton: {
      padding: 5,
      background: 'green',
      color: 'white',
      blockSize: '40px',
      textTransform:'none'
    }
  })
);


export const MessageLeft = (props) => {
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const media = props.media ? props.media : "";
  const photoURL = props.photoURL ? props.photoURL : "dummy.js";
  const displayName = props.displayName ? props.displayName : "None";
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const handleClick = () => {
    setOpenModal(true);
  };
  return (
    <>
      <div className={classes.messageRow}>
        {/* <Avatar
          alt={displayName}
          className={classes.orange}
          src={photoURL}
        ></Avatar> */}
        <div>
            {/* {displayName ? (
          <div className={classes.displayName}>{displayName}</div>
            ):null} */}
            {message ?(
              <Grid container justifyContent="center" direction="column" className={classes.messageBlue}>
                <Grid item md={12}>
                  {
                    media && media.length > 0 ? 
                    <Grid container justifyContent="center" >
                        <Grid item md={12} onClick={handleClick} style={{textAlign:'center', cursor: 'pointer'}}>
                        <ImageValidator
                              onClick={handleClick}
                              // showControls={true}
                              showOnlyPlay={true}
                              className='top-post-img'
                              url={media}>
                              <a  onClick={(e)=>{
                                  e.stopPropagation();
                                }} href={media} target='_blank'><img src={document_icon} style={{ width: 100, height: 100 }}/></a>
                          </ImageValidator>
                        </Grid>
                        <Grid item>
                        <Stack direction="row" spacing={1} style={{paddingTop: '4%'}}>
                            <Button
                            component={Link} 
                            to={props.url}
                            variant="contained" color="success" className={classes.viewButton}
                            onClick={()=>{

                            }}
                            >
                              Approve/Reject
                            </Button>
                          </Stack>
                        </Grid>

                      </Grid>
                    :
                    <p dangerouslySetInnerHTML={{__html: message}} className={classes.messageContent}></p>
                  }
                </Grid>
                <Grid item md={12}>
                <div className={classes.messageTimeStampRight}>{timestamp}</div>
                </Grid>
                <Grid item md={12}>

                </Grid>
                  
              </Grid>
          // <div className={classes.messageBlue}>        
          //   <div>
          //     {
          //       media && media.length > 0 ? 
          //       <div onClick={handleClick}>
          //       <ImageValidator
          //           // onClick={handleClick}
          //           showControls={true}
          //           className='top-post-img'
          //           url={media}>
          //           <img src={insta_placeholder} className='top-post-img'/>
          //       </ImageValidator>
          //       <Stack direction="row" spacing={1} style={{paddingTop: '4%'}}>
          //       {/* <Button 
          //       variant="contained"
          //       className={classes.viewButton}
          //       onClick={handleClick}
          //       >
          //         View
          //       </Button> */}
          //       <Button
          //       component={Link} 
          //       to={props.url}
          //       variant="contained" color="success" className={classes.viewButton}
          //       onClick={()=>{

          //         // let url = window.location.href
          //         // let shortUrl=url.substring(0,url.lastIndexOf("/"));
          //         //     console.log(shortUrl)
          //         // // console.log(window.location.href)
          //       }}
          //       >
          //         Approve/Reject
          //       </Button>
          //     </Stack>
          //       </div>
          //        : 
          //       <p dangerouslySetInnerHTML={{__html: message}} className={classes.messageContent}></p>
          //     }
          //   </div>
          //   <div className={classes.messageTimeStampRight}>{timestamp}</div>
          // </div>
           ):null}
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{ 
          padding: 20,
          paddingTop: 0,
          position: 'absolute', 
          // width: '57%',
          width: '39%', 
          // top: '100px', 
          // left: '312px',
          top: '100px', 
          left: '25%',
          // background: 'red',
          height: '70%',

        }}
      >
        <Grid container className={classes.root}>
        <Grid item onClick={() => setOpenModal(false)} style={{alignSelf: 'flex-end', cursor: 'pointer'}}>
                  <CloseIcon/>
        </Grid>
        <Grid item md={12} onClick={handleClick}>
        {media && media.length > 0 ? (
          <ImageValidator
            onClick={handleClick}
            showControls={true}
            className='chat-img'
            url={media}>
            <img src={insta_placeholder} className='chat-img'/>
          </ImageValidator>
        ) : null}
        </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export const MessageRight = (props) => {
  const classes = useStyles();
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const media = props.media ? props.media : "";
  const [openModal, setOpenModal] = useState(false);

  const handleClick = () => {
    setOpenModal(true);
  };
  return (
    <Grid container direction="column" >
      <Grid item md={12} className={classes.messageRowRight}>
        <Grid container justifyContent="flex-end"  className={classes.messageOrange}> 
          <Grid item md={12}>
            {
              media && media.length > 0 ?
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item md={12} onClick={handleClick} style={{textAlign:'center', cursor: 'pointer'}} >
                <ImageValidator
                    onClick={handleClick}
                    // showControls={true}
                    showOnlyPlay={true}
                    className='top-post-img'
                    url={media}>
                    <a onClick={(e)=>{
                      e.stopPropagation();
                    }} href={media} target='_blank'><img src={document_icon} style={{ width: 100, height: 100 }}/></a>
                </ImageValidator>
                </Grid>
              </Grid>:
              <p dangerouslySetInnerHTML={{__html: message}} className={classes.messageContent}></p>

            }
          </Grid>
          <Grid item md={12} className={classes.messageTimeStampRight}>
          {timestamp}
          </Grid>
        </Grid>

      </Grid>
      <Grid item md={12}>
        <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{ 
          padding: 20,
          paddingTop: 0,
          position: 'absolute', 
          // width: '57%',
          width: '39%', 
          // top: '100px', 
          // left: '312px',
          top: '100px', 
          left: '25%',
          height: '70%',
        }}
      >
        <Grid container className={classes.root}>
        <Grid item onClick={() => setOpenModal(false)} style={{alignSelf: 'flex-end', cursor: 'pointer'}}>
                  <CloseIcon/>
        </Grid>
        <Grid item md={12} onClick={handleClick}>
        {media && media.length > 0 ? (
          <ImageValidator
            onClick={handleClick}
            showControls={true}
            className='chat-img'
            url={media}>
            <img src={insta_placeholder} className='chat-img'/>
          </ImageValidator>
        ) : null}
        </Grid>
        </Grid>
      </Modal>

      </Grid>

    </Grid>
    // <div className={classes.messageRowRight}>
    //   <div className={classes.messageOrange}>
    //     {
    //       media && media.length > 0 ?
    //       <div onClick={handleClick}>
    //       <ImageValidator
    //           // onClick={handleClick}
    //           showControls={true}
    //           className='top-post-img'
    //           url={media}>
    //           <img src={insta_placeholder} className='top-post-img'/>
    //       </ImageValidator>
    //       <Stack direction="row" spacing={1} style={{paddingTop: '4%'}}>
    //         {/* <Button 
    //         variant="contained"
    //         className={classes.viewButton}
    //         onClick={handleClick}
    //         >
    //           View
    //         </Button> */}
    //         <Button variant="contained" color="success" className={classes.viewButton}
    //         onClick={()=>{
              
    //         }}
    //         >
    //           Content Approval
    //         </Button>
    //       </Stack>
    //       </div>
    //        : 
    //       <p dangerouslySetInnerHTML={{__html: message}} className={classes.messageContent}></p>
    //     }
    //     <div className={classes.messageTimeStampRight}>{timestamp}</div>
    //   </div>
    //   <Modal
    //     open={openModal}
    //     onClose={() => setOpenModal(false)}
    //     aria-labelledby="modal-title"
    //     aria-describedby="modal-description"
    //     style={{ 
    //       padding: 20,
    //       paddingTop: 0,
    //       position: 'absolute', 
    //       // width: '57%',
    //       width: '39%', 
    //       // top: '100px', 
    //       // left: '312px',
    //       top: '100px', 
    //       left: '25%',
    //       height: '70%',
    //     }}
    //   >
    //     <Grid container className={classes.root}>
    //     <Grid item onClick={() => setOpenModal(false)} style={{alignSelf: 'flex-end', cursor: 'pointer'}}>
    //               <CloseIcon/>
    //     </Grid>
    //     <Grid item md={12} onClick={handleClick}>
    //     {media && media.length > 0 ? (
    //       <ImageValidator
    //         onClick={handleClick}
    //         showControls={true}
    //         className='chat-img'
    //         url={media}>
    //         <img src={insta_placeholder} className='chat-img'/>
    //       </ImageValidator>
    //     ) : null}
    //     </Grid>
    //     </Grid>
    //   </Modal>
    // </div>
  );
};

