import { useEffect, useState } from 'react';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import { TextField, Grid, Chip, InputAdornment, IconButton, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfluencerInfo from './InfluencerInfo';
// import { getDiscoverySearchResult } from 'api_handlers/discovery';
import { getDiscoverySearchResult } from 'api_handlers/es';
import { updateSearchedSelectedInfluencerIds } from 'actions/planner';
import SearchIcon from '@mui/icons-material/Search';

function SearchAutocomplete(props) {
  const [options, setOptions] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [requestSource, setRequestSource] = useState(null);
  const [delay, setDelay] = useState(false);

  const CancelToken = axios.CancelToken;

  useEffect(() => {
    if (searchKeyword.length < 3) return;
    if (typingTimeout) clearTimeout(typingTimeout);

    setTypingTimeout(
      setTimeout(async () => {
        try {
          if (requestSource) {
            requestSource.cancel('Operation canceled.');
          }
          console.log('options', options);
          const source = CancelToken.source();
          setRequestSource(source);
          getDiscoverySearchResult(searchKeyword, source).then(({ result }) => {
            setRequestSource(null);
            setOptions([...result]);
          });
        } catch (e) {
          console.error(e);
        }
      }, 500),
    );
  }, [searchKeyword]);

  const classes = useStyles();
  return (
    <Autocomplete
      multiple
      limitTags={2}
      freeSolo={searchKeyword.length > 2 && delay ? false : true}
      noOptionsText={'No result found'}
      id="planner-search-autocomplete"
      InputLabelProps={{ shrink: false }}
      options={options}
      getOptionLabel={(option) => option.label || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Influencer Name, Insta Handle, url"
          className={classes.searchInput}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => this.handleApply()} className={classes.searchButton}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          inputProps={{
            ...params.inputProps,
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
              }
            },
          }}
        />
      )}
      renderTags={(selectedValues, getTagProps) => {
        return selectedValues.map((option) => {
          console.log('render tag change');
          const props = getTagProps(option);
          return <Chip label={option.name} onDelete={props.onDelete} />;
        });
      }}
      onInputChange={(event, value) => {
        console.log('input change');
        setSearchKeyword(value);
        if (value.length < 3) setDelay(false);
        setTypingTimeout(
          setTimeout(() => {
            setDelay(true);
          }, 2500),
        );
      }}
      renderOption={(option, { selected }) => (
        <Grid style={{ height: 100, margin: 'none', marginTop: 10 }} item md={12}>
          <InfluencerInfo
            name={option.name}
            gender={option.gender || ''}
            language={option.language}
            picture={option.picture}
            age_group={option.age_group || ''}
            platform={option.platform}
          />
        </Grid>
      )}
      onChange={(event, selectedValues) => {
        console.log('influencer selected', selectedValues);
        // props.updateSearchedSelectedInfluencerIds(selectedValues.map(v => v.id))
        props.getPlannerInfluencersData({
          ...props.payload,
          searchedSelectedInfluencerIds: selectedValues.map((v) => v.id),
        });
      }}
    />
  );
}

const useStyles = makeStyles(() => ({
  searchInput: {
    width: 700,
    fontSize: 10,
    height: 35,
    borderRadius: 20,
    backgroundColor: 'white',
    paddingLeft: 20,
    boxShadow:
      '-4px 4px 8px rgba(230, 230, 230, 0.2), 4px -4px 8px rgba(230, 230, 230, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.9), 4px 4px 10px rgba(230, 230, 230, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(230, 230, 230, 0.5)',
    '& .MuiInput-underline:before': {
      border: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInput-underline:after': {
      border: 'none',
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    searchedSelectedInfluencerIds: state.planner.searchedSelectedInfluencerIds,
  };
};

const actionCreators = {
  updateSearchedSelectedInfluencerIds,
};

export default connect(mapStateToProps, actionCreators)(SearchAutocomplete);
