import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Button, TextField, Container } from '@material-ui/core';

import Loading from '../../components/Common/Loading/Loading';
import FacebookLogin from '../../components/auth/FacebookLogin';
import YoutubeLogin from '../../components/auth/YoutubeLogin';
import ErrorDisplay from '../influencers/forms/ErrorDisplay';

import { colorConstants } from '../../constants/colors';
import { authActions } from '../../actions/auth';
import { authHandlers } from '../../api_handlers/auth';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

class MobileInfluencerSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      email: null,
      phone_number: null,
      password: null,
      confirmedPassword: null,
      passwordOk: true,
      phoneNumberOk: true,
      user_type: 'influencer',
      formErrors: {},
      is_form_error: false,
    };
  }

  async signUp(e) {
    e.preventDefault();
    if (this.state.password === this.state.confirmedPassword && this.state.phone_number != null) {
      this.setState({ passwordOk: true, phoneNumberOk: true });
      const { passwordOk, confirmedPassword, name, phone_number, ...apiData } = this.state;
      apiData.influencer = {
        name,
        phone_number,
      };
      // this.props.signUp(apiData)
      const resp = authHandlers.signUp(apiData);

      resp.then(async (response) => {
        if ([200, 201].includes(response.status)) {
          const userData = await response.json();
          localStorage.setItem('access', userData.access);
          localStorage.setItem('refresh', userData.refresh);
          const user = {
            email: userData.email,
            user_type: userData.user_type,
            influencer: userData.influencer,
            brand: userData.brand,
          };
          localStorage.setItem('user', JSON.stringify(user));
          this.props.history.push('/influencer/profile-setup');
        } else if (response.status === 400) {
          const errors = await response.json();
          this.setState({
            formErrors: errors,
            is_form_error: true,
          });
        }
      });
    } else if (this.state.password != this.state.confirmedPassword) {
      this.setState({ passwordOk: false });
    } else if (this.state.phone_number == null) {
      this.setState({ phoneNumberOk: false });
    }
  }

  async registerToBackendFacebook(data) {
    const user = await authHandlers.facebookLogin(data);
    if (user) this.props.history.push('/influencer/profile-setup');
  }

  async registerToBackendYoutube(data) {
    const user = await authHandlers.youtubeLogin(data);
    if (user) this.props.history.push('/influencer/profile-setup');
  }

  render() {
    const phone_number_style = { width: '100%', '-webkit-font-smoothing': '' };
    return (
      <>
        {this.props.loading && <Loading />}
        <Container style={{ marginBottom: 20, marginTop: 20 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 15 }}
          >
            <Grid item>
              <FacebookLogin
                label="Sign up with facebook"
                onSuccess={(response) => {
                  this.registerToBackendFacebook(response);
                }}
              />
            </Grid>
            <Grid item>
              <YoutubeLogin
                label="Sign up with YouTube"
                onSuccess={(response) => {
                  this.registerToBackendYoutube(response);
                }}
              />
            </Grid>
          </Grid>
          {/* <Grid container style={{marginTop: 15, marginLeft: '50%'}}> or </Grid> */}

          {/* <form onSubmit={(e) => {this.signUp(e) }}>
                        {this.state.is_form_error &&
                            <ErrorDisplay
                                    errors={this.state.formErrors}
                                    backendKeyMap={{}}
                            />
                            }
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Influencer Name"
                            name="name"
                            autoComplete="text"
                            autoFocus
                            onChange={
                                e => this.setState({ name: e.target.value })
                            }
                        />
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email ID"
                            name="email"
                            autoComplete="email"
                            onChange={
                                e => this.setState({ email: e.target.value })
                            }
                        />
                        <PhoneInput
                            inputProps={{
                                name: 'Phone Number',
                                required: true,
                              }}
                            country={'in'}
                            countryCodeEditable={false}
                            enableSearch={true}
                            containerClass={"MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"}
                            onChange={phone => this.setState({ phone_number:'+'+phone })}
                            inputStyle={phone_number_style}
                            specialLabel={'Phone Number'}
                            containerStyle={{'color':'#555555'}}
                        />
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={
                                e => this.setState({ password: e.target.value })
                            }
                        />
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Confirm Password"
                            type="password"
                            autoComplete="current-password"
                            onChange={
                                e => this.setState({ confirmedPassword: e.target.value })
                            }
                        />
                        {!this.state.passwordOk &&
                            <p
                                style={{
                                    color: 'red'
                                }}
                            >
                                Passwords do not match.
                            </p>
                        }
                        {!this.state.phoneNumberOk &&
                            <p
                                style={{
                                    color: 'red'
                                }}
                            >
                                Please Input the Phone Number
                            </p>
                        }
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            style={{
                                backgroundColor: colorConstants.LOGIN_BUTTON,
                                marginBottom: '2vw',
                                marginTop: '2vw',
                                color: 'white'
                            }}
                        >
                            Sign Up
                        </Button>
                    </form> */}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

const actionCreators = {
  signUp: authActions.signUp,
};

export default connect(mapStateToProps, actionCreators)(withRouter(MobileInfluencerSignUp));
