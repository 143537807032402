import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { TextField, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';

import { gridSpacing } from 'store/constant';
import { getCampaigns, addInfluencerToCampaign } from 'api_handlers/campaign';
import { getPlans, addPlanProfiles, createPlan } from 'api_handlers/planner2';

import CampaignCard from './CampaignCard';
import mixpanel from 'mixpanel-browser';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function AdvancedFilters({ open, setOpen, influencers, text }) {
  const [campaigns, setCampaigns] = React.useState([]);
  const [addedInCampaigns, setAddedInCampaigns] = React.useState([]);
  const [planner, setPlanner] = React.useState([]);
  const [addedInPlan, setAddedInPlan] = React.useState([]);
  const [isCreatingPlan, setIsCreatingPlan] = React.useState(false);
  const [planName, setPlanName] = React.useState('');
  const [tags, setTags] = React.useState('');
  const [platform, setPlatform] = React.useState('all');
  const [errors, setErrors] = React.useState({ planName: 'List Name is required' });
  const user = useSelector((state) => state.planner2.user);

  const handleClose = () => {
    setAddedInPlan([]);
    setOpen(false);
    setIsCreatingPlan(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = async () => {
    try {
      if (text === 'Planner') {
        // Fetch data for Planner
        const plannerData = await getPlans(user.brand.id);
        console.log('Planner Data', plannerData.results.plans_lists);
        const plansArray = Object.keys(plannerData.results.plans_lists || {}).map((key) => ({
          ...plannerData.results.plans_lists[key],
          planName: key,
        }));
        console.log('planner', planner);
        setPlanner(plansArray);
      } else {
        // Fetch default campaigns data
        const resp = await getCampaigns();
        setCampaigns(resp);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [text]);

  const handleAddToCampaign = async (campaignId) => {
    const { data } = await addInfluencerToCampaign(campaignId, {
      profiles: influencers.map((influencer) => influencer.id),
    });

    if (data.success) {
      setAddedInCampaigns((prevValue) => [...prevValue, campaignId]);
    }
  };

  const handlePlanner = async (planId) => {
    try {
      const { data } = await addPlanProfiles(planId, {
        iq_profile_ids: influencers.map((influencer) => influencer.id),
      });

      if (data.msg) {
        setAddedInPlan((prevValue) => [...prevValue, planId]);
        console.log(data.msg);
      }
    } catch (error) {
      console.error('Error adding influencers to the plan:', error);
    }
  };

  const handleCreatePlan = () => {
    if (process.env.NODE_ENV === 'production') {
      mixpanel.track("discover_create_list", {
        "type": "collab_list"
      })
    }
    setIsCreatingPlan(true);
  };

  const handleSubmitPlan = async () => {
    // Validate Plan Name
    if (!planName.trim()) {
      setErrors({ planName: 'List Name is required' });
      return;
    }

    // Proceed with creating the plan
    try {
      const response = await createPlan({
        name: planName,
        brand_id: user.brand.id, // Replace with actual brand_id
        tags: tags,
        platform: platform,
        iq_profile_ids: influencers.map((influencer) => influencer.id),
      });
      if (process.env.NODE_ENV === 'production') {
        mixpanel.track("collab_list_created", {
          name: planName,
          brand_id: user.brand.id,
          tags: tags,
          platform: platform,
          source: 'discover'
        })
    }
      setAddedInPlan([...addedInPlan, response.data.plan_id])
      console.log('Create Response: ', response);

      if (response.status === 201) {
        setPlanName('');
        setTags('');
        setPlatform('all');
        setErrors({});
        setIsCreatingPlan(false); // Close the form
        fetchData();
      } else {
        // console.error('Error creating plan:', data);
      }
    } catch (error) {
      console.error('Error submitting plan:', error);
    }
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {text === 'AddToCampaign' ? 'Add To Campaign' : text === 'Planner' ? 'Collab List' : ''}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {!isCreatingPlan ? (
            <Grid container direction="row" spacing={gridSpacing}>
              {text === 'AddToCampaign'
                ? campaigns.map((campaign, index) => (
                    <Grid key={index} item xs={12} sm={6} md={6} lg={6}>
                      <CampaignCard
                        {...campaign}
                        added={addedInCampaigns.includes(campaign.id)}
                        handleAddToCampaign={handleAddToCampaign}
                      />
                    </Grid>
                  ))
                : text === 'Planner'
                ? planner.map((plan, index) => (
                    <Grid key={plan.id} item xs={12} sm={6} md={6} lg={6}>
                      <CampaignCard
                        id={plan.id}
                        platform={plan.platform}
                        total_influencers={plan.total_influencers}
                        tags={plan.tags}
                        created_at={plan.created_at}
                        // created_by={user.brand.}
                        updated_at={plan.updated_at}
                        planName={plan.planName}
                        added={addedInPlan.includes(plan.id)}
                        handleAddToCampaign={handlePlanner}
                      />
                    </Grid>
                  ))
                : ''}
            </Grid>
          ) : text === 'Planner' ? (
            <form noValidate>
              <TextField
                label="List Name"
                fullWidth
                required
                value={planName}
                onChange={(e) => setPlanName(e.target.value)}
                error={Boolean(errors.planName)}
                helperText={errors.planName}
                margin="normal"
              />
              <TextField
                label="Platform"
                fullWidth
                select
                value={platform}
                onChange={(e) => setPlatform(e.target.value)}
                margin="normal"
              >
                <MenuItem value="instagram">Instagram</MenuItem>
                <MenuItem value="youtube">YouTube</MenuItem>
                <MenuItem value="all">All</MenuItem>
              </TextField>
              <TextField
                label="Tags"
                fullWidth
                value={tags}
                onChange={(e) => setTags(e.target.value)}
                margin="normal"
              />
            </form>
          ) : (
            setIsCreatingPlan(false)
          )}
        </DialogContent>
        <DialogActions>
          {!isCreatingPlan ? (
            text === 'Planner' ? (
              <Grid container justifyContent={'end'} spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                  >
                    Done
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreatePlan} // Handle the create plan logic here
                  >
                    Create List
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Button
                autoFocus
                onClick={() => {
                  handleClose();
                }}
              >
                Close
              </Button>
            )
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitPlan} // Handle plan submission
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
