import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';

import StyledNavLink from '../ux/StyledNavLink';

const drawerWidth = 80;

const styles = (theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    top: 150,
    '& .MuiDrawer-paper': {
      top: 75,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      height: 'inherit',
    },
    '& .MuiListItemIcon-root': {
      minWidth: 20,
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

class RightDrawerBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
    };
  }

  handleDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          anchor="right"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: this.state.drawerOpen,
            [classes.drawerClose]: !this.state.drawerOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: this.state.drawerOpen,
              [classes.drawerClose]: !this.state.drawerOpen,
            }),
          }}
          onMouseEnter={() => {
            this.handleDrawerOpen();
          }}
          onMouseLeave={() => {
            this.handleDrawerClose();
          }}
        >
          {this.props.children}
        </Drawer>
      </div>
    );
  }
}

export default withStyles(styles)(RightDrawerBase);
