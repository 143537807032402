import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Tabs, Tab, Grid, Divider } from '@material-ui/core';
import qs from 'qs';

import FacebookLogin from '../../components/auth/FacebookLogin';
import InstagramLogin from '../../components/auth/InstagramLogin';
import NativeLogin from '../../components/Common/Login';
import BrandSignUp from '../../components/Brand/SignUp';
import InfluencerSignUp from '../../components/Influencer/SignUp';
import Footer from '../../components/auth/Footer';
import MobileFooter from '../../components/auth/MobileFooter';

import { colorConstants } from '../../constants/colors';

import { getCookie } from '../../components/utils';
import signupImageWeb from '../../assets/signup_web.jpeg';
import signupImageMobile from '../../assets/signup_mobile.jpeg';

import './LoginPage.styles.css';

const responseInstagram = (response) => {};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.isRefererNomination = getCookie('nomination_form_id');
    this.influencerHeadline = this.isRefererNomination
      ? "You're almost done!"
      : 'Make your Brand go Viral';
    this.influencerSubtitle = this.isRefererNomination
      ? 'Link your Social Media platforms to complete your nomination'
      : 'Collaborate with the best content creators and reach out to millions of users with Ease';

    this.state = {
      login: true,
      brand: true,
      style: {
        headline: this.influencerHeadline,
        subtitle: this.influencerSubtitle,
        className: 'bg-influencer',
      },
    };
  }

  brandHeadline = 'Get paid for being yourself!';
  brandSubtitle =
    'Connect with 1000s of brands to help them reach out to their potential customers by ' +
    'creating high quality content.';

  toggleLogin = () => this.setState({ login: !this.state.login });

  toggleUser = (event, brand) => {
    this.setState({ brand: !brand }, () => {
      if (this.state.brand) {
        this.setState({
          style: {
            className: 'bg-brand',
            headline: this.brandHeadline,
            subtitle: this.brandSubtitle,
          },
        });
      } else {
        this.setState({
          style: {
            className: 'bg-influencer',
            headline: this.influencerHeadline,
            subtitle: this.influencerSubtitle,
          },
        });
      }
    });
  };

  indicatorStyles = (theme) => ({
    indicator: {
      backgroundColor: this.state.brand ? colorConstants.BRAND_RED : colorConstants.INFLUENCER_BLUE,
    },
  });

  componentDidMount() {
    if (
      localStorage.getItem('access') &&
      JSON.parse(localStorage.getItem('user')).user_type == 'brand'
    ) {
      this.props.history.push(
        `/brand/dashboard/${
          JSON.parse(localStorage.getItem('user')).brand.id === 260 ? 'instagram' : 'youtube'
        }`,
      );
    } else if (
      localStorage.getItem('access') &&
      JSON.parse(localStorage.getItem('user')).user_type == 'influencer'
    ) {
      this.props.history.push('/influencer');
    }

    const queryParams = qs.parse(this.props.location.search.replace('?', ''));
    if (queryParams.t === 'inf-signup') {
      this.setState({
        login: false,
        brand: false,
      });
    }
    if (queryParams.t === 'brand-signup') {
      this.setState({
        login: false,
        brand: true,
      });
    }
    if (queryParams.t === 'inf-login') {
      this.setState({
        login: true,
        brand: false,
      });
    }
    if (queryParams.t === 'brand-login') {
      this.setState({
        login: true,
        brand: true,
      });
    }
    const url_attr = this.props.location.pathname.split('/');
    if (url_attr[2] != 'undefined') {
      if (url_attr[1] === 'signup' && url_attr[2] === 'creators') {
        this.setState({
          login: false,
          brand: false,
        });
      }
      if (url_attr[1] === 'signup' && url_attr[2] === 'brands') {
        this.setState({
          login: false,
          brand: true,
        });
      }
      if (url_attr[1] === 'login' && url_attr[2] === 'creators') {
        this.setState({
          login: true,
          brand: false,
        });
      }
      if (url_attr[1] === 'login' && url_attr[2] === 'brands') {
        this.setState({
          login: true,
          brand: true,
        });
      }
    }
  }

  render() {
    const background_image = window.matchMedia('(min-width: 768px)').matches
      ? 'https://influencer-media.s3.ap-south-1.amazonaws.com/signup_web_new.jpeg'
      : 'https://influencer-media.s3.ap-south-1.amazonaws.com/signup_mobile_new.jpeg';
    return (
      <Grid container>
        <Grid item md={12}>
          <div
            id="bg-wrapper"
            className={`bg-wrapper ${this.state.style.className}`}
            style={{
              backgroundImage: `url(${background_image}`,
              'background-size': 'cover',
              'background-repeat': 'no-repeat',
              'background-position': 'center center',
            }}
          ></div>
          <Grid container spacing={3}>
            <Grid className="tagline-wrapper" item xs={12} sm={6}>
              {/*<h1
                                style={{
                                    fontWeight: 'bold',
                                }}
                            >
                                {this.state.style.headline}
                            </h1>
                            <p>
                                {this.state.style.subtitle}
                            </p>*/}
            </Grid>
            <Grid
              className={`${
                this.state.login ? 'login_form-content-wrapper' : 'signup_form-content-wrapper'
              }`}
            >
              <Tabs
                value={!+this.state.brand ? 1 : 0}
                centered
                indicatorColor={!this.state.brand ? 'primary' : 'secondary'}
                onChange={this.toggleUser}
              >
                <Tab
                  label={'Brand'}
                  style={{
                    color: this.state.brand ? colorConstants.BRAND_RED : '',
                    fontWeight: this.state.brand ? 'bold' : '',
                  }}
                />
                <Tab
                  label={'Influencer'}
                  style={{
                    color: this.state.brand ? '' : colorConstants.INFLUENCER_BLUE,
                    fontWeight: this.state.brand ? '' : 'bold',
                  }}
                />
              </Tabs>
              {this.state.login && <NativeLogin brand={this.state.brand} />}
              {!this.state.login && (this.state.brand ? <BrandSignUp /> : <InfluencerSignUp />)}
              <Grid container className={'toggle-buttons'}>
                <span className={'pointer bottom'} href="#" variant="subtitle1">
                  Forgot Password?
                </span>
                {/* <span className={'pointer bottom'} onClick={this.toggleLogin} variant="subtitle1">
                  {this.state.login ? 'I want to create an Account' : 'I already have an account'}
                </span> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          {window.matchMedia('(min-width: 768px)').matches ? <Footer /> : <MobileFooter />}
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(LoginPage);
