import { Component } from 'react';

import { getInfluencerInstagramFeeds } from '../../../../../api_handlers/feeds';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftAltIcon from '@material-ui/icons/ArrowLeft';

const labelText = {
  fontSize: '11px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '5px',
};

const valueText = {
  fontSize: '11px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '5px',
};

const arrowStyle = {
  background: '#1E9CB2',
  boxShadow: '2px 5px 10px rgba(57, 57, 57, 0.25)',
  marginRight: '10px',
  borderRadius: '50%',
  height: '32px',
  width: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default class InstaPostCardMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feeds: [],
      count: 0,
      page: 1,
      loading: false,
      currentIndex: 0,
    };
  }

  ellipsis(text) {
    if (text && text.length > 40) return text.slice(0, 37) + '...';
    return text;
  }

  async getData() {
    this.setState({
      loading: true,
    });
    const campaignId = this.props.campaignId;
    const influencerId = this.props.influencerId;
    const resp = await getInfluencerInstagramFeeds(this.state.page, campaignId, influencerId, {
      only_story: this.props.storyTab ? true : false,
    });
    this.setState({
      feeds: this.state.feeds.concat(resp.results),
      count: resp.count,
      loading: false,
    });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData(this.state.page);
    }, 30);
  }

  render() {
    let { feeds, currentIndex } = this.state;

    return (
      <div
        style={{
          display: 'flex',
          background: 'white',
          overflow: 'scroll',
          alignItems: 'flex-start',
          height: '350px',
        }}
      >
        <div
          style={{
            background: '#F3F3F3',
            padding: '10px',
            width: '150px',
            margin: '10px',
            borderRadius: '15px',
          }}
        >
          <div style={{ ...labelText, ...{ height: '40px' } }}>Post Name</div>
          <div style={labelText}>Post Type</div>
          <div style={labelText}>User</div>
          <div style={labelText}>Impressions</div>
          <div style={labelText}>Reach</div>
          <div style={labelText}>Likes</div>
          <div style={labelText}>Comments</div>
          <div style={labelText}>Saved</div>
          <div style={labelText}>Video Views</div>
        </div>
        {feeds && feeds.length > 0 ? (
          <div
            style={{
              padding: '10px',
              flex: 1,
              margin: '10px',
              background: '#FFFFFF',
              border: '2px solid #1E9CB2',
              boxShadow: '0px 5px 10px rgba(128, 128, 128, 0.25)',
              borderRadius: '5px',
            }}
          >
            <div style={{ ...valueText, ...{ height: '40px' } }}>
              {this.ellipsis(feeds[currentIndex].title)}
            </div>
            <div style={valueText}>{feeds[currentIndex].post_type}</div>
            <div style={valueText}>{feeds[currentIndex].user_name}</div>
            <div style={valueText}>{feeds[currentIndex].impressions}</div>
            <div style={valueText}>{feeds[currentIndex].reach}</div>
            <div style={valueText}>{feeds[currentIndex].like_count}</div>
            <div style={valueText}>{feeds[currentIndex].comment_count}</div>
            <div style={valueText}>{feeds[currentIndex].saved}</div>
            <div style={valueText}>{feeds[currentIndex].video_views}</div>
          </div>
        ) : null}
        <div style={{ position: 'absolute', bottom: '50px', right: '15px', display: 'flex' }}>
          {currentIndex > 0 ? (
            <div
              style={arrowStyle}
              onClick={() => {
                let { currentIndex } = this.state;
                if (currentIndex > 0) {
                  currentIndex -= 1;
                }
                this.setState({ currentIndex: currentIndex });
              }}
            >
              <ArrowLeftAltIcon style={{ color: 'white' }} />
            </div>
          ) : null}
          {currentIndex < feeds.length - 1 ? (
            <div
              style={arrowStyle}
              onClick={() => {
                let { feeds, currentIndex, count, loading, page } = this.state;
                if (currentIndex < feeds.length - 1) {
                  currentIndex += 1;
                }
                this.setState({ currentIndex: currentIndex });
                if (feeds.length < count && loading == false && currentIndex % 7 == 0) {
                  page++;
                  this.setState({
                    page: page,
                  });
                  this.getData();
                }
              }}
            >
              <ArrowRightAltIcon style={{ color: 'white' }} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
