import { Component } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { Container, Grid, InputBase } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import SidePanel from './SidePanel';
import InstagramFeed from './InstagramFeed';
import YoutubeFeed from './YoutubeFeed';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FFF',
    border: '0.5px solid #C8C8C8',
    borderRadius: '5px',
    fontSize: 11,
    paddingLeft: '15px',
    height: '20px',
    width: '100px',
  },
}))(InputBase);

class Feed extends Component {
  render() {
    return (
      <Grid container spacing={3} style={{ marginTop: 0 }}>
        <Grid item md={12}>
          <Switch>
            <Route exact path={`${this.props.match.url}/youtube`}>
              <YoutubeFeed campaignId={this.props.match.params.campaignId} />
            </Route>
            <Route exact path={`${this.props.match.path}/instagram`}>
              <InstagramFeed campaignId={this.props.match.params.campaignId} />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Feed);
