import { Typography, Grid, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// import AvatarImage from "assets/discovery/inf-avatar.png"
import GenderIcon from 'assets/discovery/Gender-icon.png';

function InfoCard({ name, gender, language, picture, age_group }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item md={4}>
        <img src={picture} style={{ width: '100%', borderRadius: '50%' }} />
      </Grid>
      <Grid item md={7}>
        <Grid container direction="column" className={classes.aboutContainer}>
          <Typography
            variant="h3"
            style={{ color: 'white', textAlign: 'left' }}
            className={classes.inf_name}
          >
            {name}
          </Typography>
          <Typography className={classes.language}>{language}</Typography>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <img src={GenderIcon} alt="icon" className={classes.genderIcon} />
            </Grid>
            <Grid item>
              <Typography className={classes.genderInfo}>
                {gender == 'FEMALE' ? 'F' : 'M'} | {age_group} yrs
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    background: 'linear-gradient(118.02deg, rgba(239, 26, 116, 0.57) 32.63%, #FEBD1C 94.6%)',
    boxShadow: '2px 5px 10px rgba(143, 143, 143, 0.25)',
    borderRadius: 10,
    height: 130,
    width: 250,
    justifyContent: 'space-around',
    alignItems: 'center',
    marginRight: 50,
  },
  aboutContainer: {
    color: 'white',
  },
  genderIcon: {
    height: 13,
    width: 13,
  },
  genderInfo: {
    fontSize: 10,
    color: 'white',
  },
  avatar: {
    height: 95,
    width: 95,
    position: 'initial',
  },
  inf_name: {
    fontSize: 16,
    fontWeight: 400,
  },
  language: {
    color: 'white',
    textAlign: 'left',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 12,
  },
}));

export default InfoCard;
