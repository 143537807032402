import React from 'react';
import { useEffect, useState, Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TextField,
} from '@material-ui/core';

import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { fetchCreatorTypeCategory } from 'api_handlers/competitor';

const items = [
    {'metric':'Followers', 'sortBy': '-followers_count'},
    {'metric':'Likes', 'sortBy': '-likes_count'},
    {'metric':'Comments', 'sortBy': '-comments_count'},
    {'metric':'Posts', 'sortBy': '-count'},
    {'metric':'Engagement Rate', 'sortBy': '-engagement__rate'},
    {'metric': 'Creators', 'sortBy': '-creators'},
]

const styles = (theme) => ({
  header: {
    color: '#4A4A4A',
    fontSize: '20px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    lineHeight: 1.167,
    letterSpacing: '0.03em',
    padding: '15px',
    background: '#F3F4FE',
    boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    borderRadius: '10px',
    marginBottom: '15px',
  },
  headerCell: {
    background: '#e4e4e4',
    fontWeight: 400,
    lineHeight: 1.167,
    letterSpacing: '0.03em',
    paddingLeft: '28px',
  },
  table: {
    minWidth: 650,
  },
  cell: {
    textAlign: 'center',
    fontSize: 13,
  },
  card: {
    background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: '52px',
    paddingRight: '52px',
  },
  aHover: {
    color: '#111111',
  },
});

function countertostring(counter) {
  counter = parseFloat(counter);
  if (counter >= 1000 && counter < 999999) {
    return (
      parseFloat(counter / 1000.0)
        .toFixed(2)
        .toString() + 'K'
    );
  } else if (counter > 999999) {
    return (
      parseFloat(counter / 1000000.0)
        .toFixed(2)
        .toString() + 'M'
    );
  } else {
    return counter.toString();
  }
}

function CategoryCreatorTypes({ classes, brandName, startDate, endDate, searchKeyword, paidPost }) {
  const [data, setData] = useState([]);
  const [metric, setMetric] = useState(items[1].sortBy)
  const [inputValue, setInputValue] = useState('Likes')
  const formattedStartDate = moment(startDate).format('YYYY-MM-DD')
  const formattedEndDate = moment(endDate).format('YYYY-MM-DD')

  useEffect(() => {
    const fetchCreatorsType = async () => {
      try {
        const resp = await fetchCreatorTypeCategory(brandName, startDate, endDate, metric, searchKeyword, paidPost);
        setData(resp.category_type)
      } catch (error) {
        console.error('Error fetching creators:', error);
      }
    };
    console.log('Metrics: ', metric)
    fetchCreatorsType();
  }, [metric]);

  return (
    <Grid container spacing={3}>
      <Grid container md={12} className={classes.header}>
        <Grid item md={4}>
          <Typography style={{ color: 'black' }}>TYPE OF CREATORS (By Category)</Typography>
        </Grid>
        <Grid item  md={8} >
          <Grid container justifyContent='flex-end'>
          <Grid item md={2} style={{alignSelf:'center',textAlign:'center'}} >
          <Typography style={{ color: 'black' }}>Order By :- </Typography>
        </Grid>
        <Grid item md={3}  style={{marginRight:10}}>
            <Autocomplete
                id="tags-standard"
                options={items}
                value={items.find(item => item.sortBy === metric) || null}
                getOptionLabel={(option) => option.metric}
                disableCloseOnSelect
                renderInput={(params) => <TextField {...params}/>}
                onChange={(event, newValue) => {
                    setMetric(newValue.sortBy);
                }}
            />
        </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        style={{ paddingTop: '20px', paddingBottom: '20px', background: 'white' }}
      ></Grid>
      <Grid container spacing={3} className={classes.card}>
        <TableContainer component={Paper}>
          <Table stickyHeader className={classes.table} aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell}>Category</TableCell>
                <TableCell className={classes.headerCell}>Accounts</TableCell>
                <TableCell className={classes.headerCell}>Followers</TableCell>
                <TableCell className={classes.headerCell}>Likes</TableCell>
                <TableCell className={classes.headerCell}>Comments</TableCell>
                <TableCell className={classes.headerCell}>Posts</TableCell>
                <TableCell className={classes.headerCell}>Eng Rate</TableCell>
                <TableCell className={classes.headerCell}>Fair Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="feed" className={classes.cell}>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item={8}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                        >
                          <Grid
                            item
                            md={12}
                            style={{
                              wordBreak: 'normal',
                              width: 180,
                              textAlign: 'left',
                            }}
                          >
                            <Grid style={{ cursor: 'pointer' }}>
                                <Typography
                                >
                                  {row.category_name}
                                </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell className={classes.cell}>{row.creators === null ? 0 : countertostring(row.creators)}</TableCell>
                  <TableCell className={classes.cell}>{row.followers_count === null ? 0 : countertostring(row.followers_count)}</TableCell>
                  <TableCell className={classes.cell}>{row.likes_count === null ? 0 : countertostring(row.likes_count)}</TableCell>
                  <TableCell className={classes.cell}>{row.comments_count === null ? 0 : countertostring(row.comments_count)}</TableCell>
                  <TableCell className={classes.cell}>{row.count === null ? 0 : countertostring(row.count)}</TableCell>
                  <TableCell className={classes.cell}>{parseFloat(row.engagement__rate).toFixed(2)} %</TableCell>
                  <TableCell className={classes.cell}>{row.fair_price ? row.fair_price : 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(CategoryCreatorTypes);
