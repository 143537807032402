import { useEffect, useState, Component } from 'react';
import { Grid, Typography, TextField, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AdvanceFilter from 'assets/planner/advance_filter.png';
import Close from 'assets/planner/close.png';
import Date from 'assets/planner/date.png';
import Rate from 'assets/planner/rate.png';
import Audience from 'assets/planner/audience.png';
import Engagement from 'assets/planner/engagement.png';
import PostPerformance from 'assets/planner/post_performance.png';
import Gender from 'assets/planner/gender.png';
import AgeGroup from 'assets/planner/age_group.png';
import Language from 'assets/planner/language.png';
import Percentage from 'assets/planner/percentage.png';
import Credibility from 'assets/planner/credible.png';
import Male from 'assets/planner/male.png';
import Female from 'assets/planner/female.png';
import GenderIcon from 'assets/discovery/Gender-icon.png';
import PriceIcon from 'assets/discovery/Price-icon.png';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import LocationIcon from 'assets/discovery/Location-Icon.png';
import InfluencerLocation from './filterSection/InfluencerLocation';
import AudienceInterest from './filterSection/AudienceInterest';
import CustomSlider from 'components/PlannerTheme/Elements/Slider/Slider';
import MultiSelect from 'components/PlannerTheme/Elements/MultiSelect';
import YesNoRadio from 'components/Theme/Elements/YesNoRadio';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InfluencerCountry from './filterSection/InfluencerCountry';
import InfluencerState from './filterSection/InfluencerState';
import InfluencerCity from './filterSection/InfluencerCity';
import Deliverables from './filterSection/CampaignDeliverable';
import InfluencerZone from './filterSection/InfluencerZone';

import { getLanguages } from '../../../../api_handlers/influencer';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const interests = [
  'Actor',
  'App Review',
  'Art and craft',
  'Automobile',
  'Beauty',
  'Blogger',
  'Blooger',
  'Comedy',
  'Content',
  'Content Creation',
  'Content Creator',
  'Couple Life’S',
  'Crypto',
  'Dance',
  'Decor',
  'Digtal Creator',
  'education ',
  'Entertainment',
  'Fashion',
  'Fashion	Male',
  'Finance',
  'Fitness',
  'Food',
  'Gaming',
  'Gaming / E-sports',
  'Health',
  'home decor ',
  'Influencer',
  'Infotainment',
  'Investement',
  'Investment',
  'Lifestyle',
  'Luxury',
  'Motivation',
  'Motivational',
  'Music',
  'Parenting',
  'Pets',
  'Photography',
  'Phototgraphy',
  'Self Help',
  'Social Issues',
  'Sports',
  'Sustainable Fashion',
  'Tech',
  'Technology',
  'Travel',
  'Travel / Content Creator',
  'Travel/ Food',
  'Travel/ lifestyle',
  'Vlogger',
  'Wellness',
  'Television & Film',
  'Music',
  'Shopping & Retail',
  'Coffee, Tea & Beverages',
  'Camera & Photography',
  'Clothes, Shoes, Handbags & Accessories',
  'Beer, Wine & Spirits',
  'Sports',
  'Electronics & Computers',
  'Gaming',
  'Activewear',
  'Art & Design',
  'Travel, Tourism & Aviation',
  'Business & Careers',
  'Beauty & Cosmetics',
  'Healthcare & Medicine',
  'Jewellery & Watches',
  'Restaurants, Food & Grocery',
  'Toys, Children & Baby',
  'Fitness & Yoga',
  'Wedding',
  'Tobacco & Smoking',
  'Pets',
  'Healthy Lifestyle',
  'Luxury Goods',
  'Home Decor, Furniture & Garden',
  'Friends, Family & Relationships',
  'Cars & Motorbikes',
];

// const languages_map = [
//   "English",
//   "Hindi",
//   "Gujarati",
//   "Bengali",
//   "Punjabi",
//   "Marathi",
//   "Kannada",
//   "Malayalam",
//   "Tamil",
//   "Telugu",
//   "Bhojpuri",
//   "Assamese",
//   "Urdu",
//   "Tibetan",
//   "Manipuri",
//   "Mizo",
//   "Nepali",
//   "Konkani",
//   "Bodo",
//   "Dogri",
//   "Kashmiri",
//   "Maithili",
//   "Sindhi",
//   "Santali",
//   "Odia",
//   "Marwari",
//   "Tulu",
//   "Naga",
//   "Haryanvi",
//   "Kutchi",
//   "French",
//   "German",
//   "Italian",
// ];

const genderOptions = ['Male', 'Female', 'Other', 'Page'];

const ageGroupOptions = ['13-17', '18-24', '25-34', '35-44', '45-64', '65-'];

function valuetext(value) {
  return `${value}%`;
}

function numberValidation(value) {
  const num = Number(value);
  if (isNaN(num)) {
    return true;
  } else {
    return false;
  }
}

class AdvanceFilterWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: [],
      ageGroup: '',
      paidPP: '',
      avgEngaged: '',
      avgViews: '',
      languages_map: [],
      brandPrice: '',
      infPrice: '',
      keywordFilter: '',
      bioFilter:'',
    };
  }

  async loadInterest() {
    let data = await getLanguages();
    let languages_map = [];
    data.map((each) => {
      languages_map.push(each.name);
    });
    this.setState({ languages_map });
  }

  componentDidMount() {
    this.loadInterest();
  }

  render() {
    const {
      classes,
      rate,
      audienceCredibility,
      interest_selection,
      avg_engagement,
      ppp,
      gender,
      isEmail,
      isPhone,
      is_audience_data,
      languageAdvance,
      influencerAge,
      influencerLocations,
      influencerCountries,
      influencerState,
      influencerCity,
      startDate,
      endDate,
      avg_views,
      brand_final_price,
      influencer_final_price,
      deliverables,
      keywords,
      bio_keyword,
      influencerZone,
    } = this.props;
    console.log('D: ', deliverables);

    return (
      <Grid
        container
        style={{
          position: 'absolute',
          top: -60,
          right: 50,
          background: '#FFFFFF',
          zIndex: 100,
          width: 1080,
          borderRadius: 20,
          padding: 20,
          paddingBottom: 40,
          flexWrap: 'wrap',
        }}
      >
        <Grid container style={{ justifyContent: 'space-between' }}>
          <img src={AdvanceFilter} width={186} />
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              this.props.hideAdvanceFilters('showdAvanceFilter', false);
              this.props.setShowModal(false);
            }}
          >
            <img src={Close} width={25} height={25} />
          </div>
        </Grid>
        <Grid container style={{ width: '100%', flexWrap: 'wrap', marginTop: '20px' }}>
          <div style={{ flex: 1, padding: 10 }}>
            <div
              style={{
                display: 'flex',
                marginBottom: 10,
                justifyContent: 'center',
              }}
            >
              <img src={Rate} width={18} height={18} style={{ marginRight: 5 }} />
              <Typography className={classes.advanceHeading}>Engagement Rate</Typography>
            </div>
            <CustomSlider
              aria-label="Always visible"
              max={100}
              valueLabelDisplay="on"
              onChange={(event, newValue) => {
                this.props.hideAdvanceFilters('rate', newValue);
              }}
              step={1}
              value={rate}
              valueLabelFormat={valuetext}
            />
          </div>
          <div style={{ flex: 1, padding: 10 }}>
            <div
              style={{
                display: 'flex',
                marginBottom: 10,
                justifyContent: 'center',
              }}
            >
              <img src={Engagement} width={30} style={{ marginRight: 5 }} />
              <Typography className={classes.advanceHeading}>Average Engaged Users</Typography>
            </div>
            <TextField
              className={classes.search}
              InputProps={{ disableUnderline: true }}
              error={numberValidation(this.state.avgEngaged)}
              helperText={
                numberValidation(this.state.avgEngaged) ? 'Please Enter Numbers only' : ''
              }
              value={avg_engagement}
              onChange={(e) => {
                this.setState({ avgEngaged: e.target.value });
                if (!isNaN(Number(e.target.value))) {
                  this.props.hideAdvanceFilters('avg_engagement', Number(e.target.value));
                }
              }}
            />
          </div>
          <div style={{ flex: 1, padding: 10 }}>
            <div
              style={{
                display: 'flex',
                marginBottom: 10,
                justifyContent: 'center',
              }}
            >
              <img src={Audience} width={26} height={18} style={{ marginRight: 5 }} />
              <Typography className={classes.advanceHeading}>Average Views</Typography>
            </div>
            <TextField
              className={classes.search}
              InputProps={{ disableUnderline: true }}
              error={numberValidation(this.state.avgViews)}
              helperText={numberValidation(this.state.avgViews) ? 'Please Enter Numbers only' : ''}
              value={avg_views}
              onChange={(e) => {
                this.setState({ avgViews: e.target.value });
                if (!isNaN(Number(e.target.value))) {
                  this.props.hideAdvanceFilters('avg_views', Number(e.target.value));
                }
              }}
            />
          </div>
        </Grid>
        <Grid
          container
          style={{
            width: '100%',
            flexWrap: 'wrap',
            marginTop: '20px',
            alignItems: 'center',
          }}
        >
          <div style={{ flex: 1, padding: 10 }}>
            <AudienceInterest
              audienceInterest={interest_selection}
              hideAdvanceFilters={this.props.hideAdvanceFilters}
            />
          </div>
          <div style={{ flex: 1, padding: 10 }}>
            <div
              style={{
                display: 'flex',
                marginBottom: 10,
                justifyContent: 'center',
              }}
            >
              <img src={AgeGroup} width={28} style={{ marginRight: 5 }} />
              <Typography className={classes.advanceHeading}>Influencer Age Group</Typography>
            </div>
            <div style={{ marginTop: 10 }}>
              <MultiSelect
                label={'Select Age Group'}
                options={ageGroupOptions.map((age_group) => ({
                  label: age_group,
                  value: age_group,
                }))}
                value={influencerAge || []}
                onChange={(event, selectedValues) => {
                  this.props.hideAdvanceFilters('influencerAge', selectedValues);
                }}
              />
            </div>
          </div>
          <div style={{ flex: 1, padding: 10 }}>
            <div
              style={{
                display: 'flex',
                marginBottom: 10,
                justifyContent: 'center',
              }}
            >
              <img src={Language} width={28} style={{ marginRight: 5 }} />
              <Typography className={classes.advanceHeading}>Influencer Language</Typography>
            </div>
            <div style={{ marginTop: 10 }}>
              <MultiSelect
                label={'Select Language'}
                options={this.state.languages_map.map((language) => ({
                  label: language,
                  value: language,
                }))}
                value={languageAdvance || []}
                onChange={(event, selectedValues) => {
                  this.props.hideAdvanceFilters('languageAdvance', selectedValues);
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid container style={{ width: '100%', flexWrap: 'wrap', marginTop: '20px' }}>
          <div style={{ flex: 1, padding: 10 }}>
            <div
              style={{
                display: 'flex',
                marginBottom: 10,
                justifyContent: 'center',
              }}
            >
              <img src={PostPerformance} width={22} style={{ marginRight: 5 }} />
              <Typography className={classes.advanceHeading}>Paid Post Performance</Typography>
            </div>
            <div style={{ position: 'relative' }}>
              <TextField
                className={classes.search}
                InputProps={{ disableUnderline: true }}
                value={ppp}
                error={numberValidation(this.state.paidPP)}
                helperText={numberValidation(this.state.paidPP) ? 'Please Enter Numbers only' : ''}
                onChange={(e) => {
                  this.setState({ paidPP: e.target.value });
                  if (!isNaN(Number(e.target.value))) {
                    this.props.hideAdvanceFilters('ppp', Number(e.target.value));
                  }
                }}
              />
              <img src={Percentage} style={{ width: 40, position: 'absolute', right: 0 }} />
            </div>
          </div>
          <div
            style={{
              flex: 1,
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                // marginBottom: 10,
                justifyContent: 'center',
              }}
            >
              <img src={Gender} width={20} style={{ marginRight: 5 }} />
              <Typography className={classes.advanceHeading}>Influencer Gender</Typography>
            </div>
            <div style={{ marginTop: 10 }}>
              <Autocomplete
                onChange={(event, selectedValues) => {
                  this.props.hideAdvanceFilters('gender', selectedValues);
                }}
                options={genderOptions.map((genderOpt) => ({
                  label: genderOpt,
                  value: genderOpt,
                }))}
                value={gender || []}
                disableCloseOnSelect
                disableClearable
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      color="primary"
                    />
                    {option.label}
                  </>
                )}
                style={{
                  width: '200px',
                  border: 'none',
                  background: '#FFFFFF',
                  boxShadow:
                    '-2px 2px 4px rgba(219, 220, 229, 0.2), 2px -2px 4px rgba(219, 220, 229, 0.2), -2px -2px 4px rgba(255, 255, 255, 0.9), 2px 2px 5px rgba(219, 220, 229, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(219, 220, 229, 0.5)',
                  borderRadius: '20px',
                }}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    variant="outlined"
                    label="Select Gender"
                    placeholder={''}
                  />
                )}
              />
            </div>
          </div>
          <div
            style={{
              flex: 1,
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                marginBottom: 10,
                justifyContent: 'center',
              }}
            >
              <img src={Credibility} width={19} height={25} style={{ marginRight: 5 }} />
              <Typography className={classes.advanceHeading}>Audience Credibility</Typography>
            </div>
            <CustomSlider
              aria-label="Always visible"
              max={100}
              valueLabelDisplay="on"
              onChange={(event, newValue) => {
                this.props.hideAdvanceFilters('audienceCredibility', newValue);
              }}
              step={1}
              value={audienceCredibility}
              valueLabelFormat={valuetext}
            />
          </div>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start">
          {/* <div style={{ flex: 1, padding: 10 }}></div>
          <div style={{ flex: 1, padding: 10 }}></div> */}

          {this.props.isStaff ? (
            <Grid
              container
              style={{
                width: '100%',
                flexWrap: 'wrap',
                marginTop: '20px',
                alignItems: 'center',
              }}
            >
              <div style={{ flex: 1, padding: 10 }}>
                <div style={{ marginTop: 10 }}>
                  <Deliverables
                    infDeliverables={deliverables}
                    hideAdvanceFilters={this.props.hideAdvanceFilters}
                  ></Deliverables>
                </div>
              </div>

              <div style={{ flex: 1, padding: 10 }}>
                <div
                  style={{
                    display: 'flex',
                    marginBottom: 10,
                    justifyContent: 'center',
                  }}
                >
                  <img src={PriceIcon} width={28} style={{ marginRight: 5 }} />
                  <Typography className={classes.advanceHeading}>Brand Final Price</Typography>
                </div>
                <div style={{ marginTop: 10 }}>
                  <TextField
                    className={classes.search}
                    InputProps={{ disableUnderline: true }}
                    error={numberValidation(this.state.brandPrice)}
                    helperText={
                      numberValidation(this.state.brandPrice) ? 'Please Enter Numbers only' : ''
                    }
                    value={brand_final_price}
                    onChange={(e) => {
                      this.setState({ brandPrice: e.target.value });
                      if (!isNaN(Number(e.target.value))) {
                        this.props.hideAdvanceFilters('brand_final_price', Number(e.target.value));
                      }
                    }}
                  />
                </div>
              </div>
              <div style={{ flex: 1, padding: 10 }}>
                <div
                  style={{
                    display: 'flex',
                    marginBottom: 10,
                    justifyContent: 'center',
                  }}
                >
                  <img src={PriceIcon} width={28} style={{ marginRight: 5 }} />
                  <Typography className={classes.advanceHeading}>Influencer Final Price</Typography>
                </div>
                <div style={{ marginTop: 10 }}>
                  <TextField
                    className={classes.search}
                    InputProps={{ disableUnderline: true }}
                    error={numberValidation(this.state.infPrice)}
                    helperText={
                      numberValidation(this.state.infPrice) ? 'Please Enter Numbers only' : ''
                    }
                    value={influencer_final_price}
                    onChange={(e) => {
                      this.setState({ infPrice: e.target.value });
                      if (!isNaN(Number(e.target.value))) {
                        this.props.hideAdvanceFilters(
                          'influencer_final_price',
                          Number(e.target.value),
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </Grid>
          ) : (
            ''
          )}
        </Grid>

        <Grid container direction="row" spacing={10} justifyContent="flex-start" alignItems='center' style={{ paddingTop: '25px', }}>
          <Grid item  >
            {this.props.isStaff ? (
              <Grid container justifyContent='center'>
                <Grid
                item
                // md={4}
                style={{
                  padding: 10,
                  display: 'flex',
                  // marginLeft:200,
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    marginBottom: 10,
                    justifyContent: 'center',
                  }}
                >
                  {/* <img src={GenderIcon} width={20} style={{ marginRight: 5 }} /> */}
                  <EmailIcon style={{color:'#0077bb',marginRight: 5}}></EmailIcon>
                  <Typography className={classes.advanceHeading}>Email</Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: 107,
                  }}
                >
                  <Typography
                    className={isEmail == 'yes' ? classes.selectedCheck : classes.defaultCheck}
                    onClick={() => {
                      this.props.hideAdvanceFilters('isEmail', 'yes');
                    }}
                  >
                    {/* <img src={Male} width={31} /> */}
                    Yes
                  </Typography>
                  <Typography
                    className={isEmail == 'no' ? classes.selectedCheck : classes.defaultCheck}
                    onClick={() => {
                      this.props.hideAdvanceFilters('isEmail', 'no');
                    }}
                  >
                    {/* <img src={Female} width={31} /> */}
                    No
                  </Typography>
                </div>
              </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Grid>

          <Grid item >
            {this.props.isStaff ? (
              <Grid container justifyContent='center'>
                <Grid item
                style={{
                  flex: 1,
                  padding: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
                >
                <div
                  style={{
                    display: 'flex',
                    marginBottom: 10,
                    justifyContent: 'center',
                  }}
                >
                  {/* <img src={ContactsIcon} width={20} style={{ marginRight: 5 }} /> */}
                  <PhoneAndroidIcon style={{color:'#44bb44',marginRight: 5}}></PhoneAndroidIcon>
                  <Typography className={classes.advanceHeading}>Phone</Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: 107,
                  }}
                >
                  <Typography
                    className={isPhone == 'yes' ? classes.selectedCheck : classes.defaultCheck}
                    onClick={() => {
                      this.props.hideAdvanceFilters('isPhone', 'yes');
                    }}
                  >
                    {/* <img src={Male} width={31} /> */}
                    Yes
                  </Typography>
                  <Typography
                    className={isPhone == 'no' ? classes.selectedCheck : classes.defaultCheck}
                    onClick={() => {
                      this.props.hideAdvanceFilters('isPhone', 'no');
                    }}
                  >
                    {/* <img src={Female} width={31} /> */}
                    No
                  </Typography>
                </div>
                </Grid>
              </Grid>
            ) : (
              <div style={{ flex: 1, padding: 10 }}></div>
            )}
          </Grid>
          <Grid item >
              <Grid container justifyContent='flex-start'>
              <Grid
                item
                // md={4}
                style={{
                  flex: 1,
                  padding: 10,
                  display: 'flex',
                  // marginRight:200,
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    marginBottom: 10,
                    justifyContent: 'center',
                    width:200
                  }}
                >
                  <img src={GenderIcon} width={20} style={{ marginRight: 5 }} />
                  <Typography className={classes.advanceHeading}>Audience Data Available</Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: 107,
                  }}
                >
                  <Typography
                    className={is_audience_data == 'yes' ? classes.selectedCheck : classes.defaultCheck}
                    onClick={() => {
                      this.props.hideAdvanceFilters('is_audience_data', 'yes');
                    }}
                  >
                    {/* <img src={Male} width={31} /> */}
                    Yes
                  </Typography>
                  <Typography
                    className={is_audience_data == 'no' ? classes.selectedCheck : classes.defaultCheck}
                    onClick={() => {
                      this.props.hideAdvanceFilters('is_audience_data', 'no');
                    }}
                  >
                    {/* <img src={Female} width={31} /> */}
                    No
                  </Typography>
                </div>
              </Grid>
              </Grid>
          </Grid>
        </Grid>

        
        <Grid container justifyContent='flex-start' alignItems='flex-start' style={{marginTop:40}}>
        <Grid item >
            <Grid
              item
              md={12}
              style={{
                flex: 1,
                // padding: 10,
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  marginBottom: 10,
                  justifyContent: 'center',
                }}
              >
                <img src={Engagement} width={30} style={{ marginRight: 5 }} />
                <Typography className={classes.advanceHeading}>Search: By Caption</Typography>
              </div>
              <TextField
                className={classes.search}
                InputProps={{ disableUnderline: true }}
                helperText={'Enter comma separated keywords e.g. fashion,games'}
                value={keywords}
                onChange={(e) => {
                  this.setState({ keywordFilter: e.target.value });
                  this.props.hideAdvanceFilters('keywords', e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid
              item
              md={12}
              style={{
                flex: 1,
                // padding: 10,
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  marginBottom: 10,
                  justifyContent: 'center',
                }}
              >
                <img src={Engagement} width={30} style={{ marginRight: 5 }} />
                <Typography className={classes.advanceHeading}>Search: By Bio</Typography>
              </div>
              <TextField
                className={classes.search}
                InputProps={{ disableUnderline: true }}
                helperText={'Enter comma separated keywords e.g. fashion,games'}
                value={bio_keyword}
                onChange={(e) => {
                  this.setState({ bioFilter: e.target.value });
                  this.props.hideAdvanceFilters('bio_keyword', e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" className={classes.InfluencerAddressText}>
            <Grid item>
              <PersonPinCircleIcon
                width={22}
                style={{ marginRight: 7, color: '#d00000' }}
              ></PersonPinCircleIcon>
              {/* <img src={PersonPinCircleIcon}  /> */}
            </Grid>
            <Grid item>
              <Typography>Influencer Address</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <Grid direction="row" container md={12} style={{ width: '100%', flexWrap: 'wrap' }}>
            <Grid item md={4} className={classes.InfluencerAddress}>
              <InfluencerCountry
                influencerCountries={influencerCountries}
                hideAdvanceFilters={this.props.hideAdvanceFilters}
              />
            </Grid>
            {/* {influencerCountries.length > 0 ? ( */}
              <Grid item md={4} className={classes.InfluencerAddress}>
                <InfluencerState
                  // influencerCountries={influencerCountries}
                  influencerState={influencerState}
                  hideAdvanceFilters={this.props.hideAdvanceFilters}
                ></InfluencerState>
              </Grid>
            {/* ) : (
              (influencerState.length = [])
            )} */}
            {/* {influencerState.length > 0 ? ( */}
              <Grid item md={4} className={classes.InfluencerAddress}>
                <InfluencerCity
                  // influencerState={influencerState}
                  influencerCity={influencerCity}
                  hideAdvanceFilters={this.props.hideAdvanceFilters}
                ></InfluencerCity>
              </Grid>
            {/* ) : (
              (influencerCity.length = [])
            )} */}
          </Grid>
        </Grid>

        <Grid item md={12}>
          <Grid direction="row" container md={12} style={{ width: '100%', flexWrap: 'wrap' }}>
            <Grid item md={4} className={classes.InfluencerAddress}>
              <InfluencerZone
                influencerZone={influencerZone}
                hideAdvanceFilters={this.props.hideAdvanceFilters}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },
  },
})(TextField);

const styles = {
  search: {
    background: '#FFFFFF',
    boxShadow:
      '-2px 2px 4px rgba(215, 216, 220, 0.2), 2px -2px 4px rgba(215, 216, 220, 0.2), -2px -2px 4px rgba(255, 255, 255, 0.9), 2px 2px 5px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: '10px',
    width: 300,
    outline: 'none',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 10,
  },
  InfluencerAddressText: {
    fontFamily: 'Nunito Sans',
    paddingLeft: 10,
    paddingTop: 60,
    fontWeight: 400,
    fontSize: 16,
  },
  InfluencerAddress: {
    padding: 10,
    marginTop: 15,
  },
  defaultCheck: {
    padding: 10,
    background: '#FFFFFF',
    boxShadow:
      '-3px 3px 6px rgba(215, 216, 224, 0.2), 3px -3px 6px rgba(215, 216, 224, 0.2), -3px -3px 6px rgba(255, 255, 255, 0.9), 3px 3px 8px rgba(215, 216, 224, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 224, 0.5)',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  selectedCheck: {
    color: 'white',
    padding: 10,
    background: '#FEBD1C',
    boxShadow:
      '-3px 3px 6px rgba(215, 216, 224, 0.2), 3px -3px 6px rgba(215, 216, 224, 0.2), -3px -3px 6px rgba(255, 255, 255, 0.9), 3px 3px 8px rgba(215, 216, 224, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 224, 0.5)',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  advanceHeading: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 14,
  },
};

export default withStyles(styles)(AdvanceFilterWidget);
