import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { alpha } from '@material-ui/core/styles/colorManipulator';

function ButtonUpdated({ children, ...props }) {
  const classes = useStyles({ color: props.color });

  return (
    <Button className={classes.root} {...props}>
      {children}
    </Button>
  );
}

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    boxShadow: `-1px 1px 2px ${alpha(theme.palette[props.color].main, 0.2)}, 1px -1px 2px ${alpha(
      theme.palette[props.color].main,
      0.2,
    )}, -1px -1px 2px ${alpha(theme.palette[props.color].main, 0.9)}, 1px 1px 3px ${alpha(
      theme.palette[props.color].main,
      0.9,
    )}, inset 1px 1px 2px ${alpha(
      theme.palette[props.color].main,
      0.3,
    )}, inset -1px -1px 2px ${alpha(theme.palette[props.color].main, 0.5)}`,
    borderRadius: 10,
    fontWeight: 700,
  }),
}));

export default ButtonUpdated;
