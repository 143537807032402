import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Doughnut, Pie } from 'react-chartjs-2';

import InterestIcon from 'assets/discovery/interest-icon.png';
import GenderIcon from 'assets/discovery/Gender-icon.png';
import LocationIcon from 'assets/discovery/Location-Icon.png';
import GenderSplitIcon from 'assets/discovery/gender-split-icon.png';
import LanguageIcon from 'assets/discovery/Language-icon.png';
import FilterVintageIcon from '@material-ui/icons/FilterVintage';

import ActivewearIcon from 'assets/planner/Activewear.png';
import ArtDesignIcon from 'assets/planner/Art-and-Design.png';
import BeautyCosmeticIcon from 'assets/planner/Beauty-and-Cosmetics.png';
import BeerWineIcon from 'assets/planner/Beer-Wine-&-Spirits.png';
import BusinessCareerIcon from 'assets/planner/Business-and-Careers.png';
import CameraPhotographyIcon from 'assets/planner/Camera-&-Photography.png';
import CarMotorbikeIcon from 'assets/planner/Cars-&-Motorbikes.png';
import ClothesShoesIcon from 'assets/planner/Clothes-Shoes.png';
import CoffeeTeaIcon from 'assets/planner/Coffee-Tea-&-Beverages.png';
import ElectronicsComputerIcon from 'assets/planner/Electronics-and-Computers.png';
import FitnessYogaIcon from 'assets/planner/Yoga.png';
import FriendsFamilyIcon from 'assets/planner/Friends-Family-&-Relationships.png';
import GamingIcon from 'assets/planner/Gaming.png';
import HealthcareMedicineIcon from 'assets/planner/Healthcare-and-Medicine.png';
import HealthyLifestyleIcon from 'assets/planner/Healthy-Lifestyle.png';
import HomeDecorIcon from 'assets/planner/Home-Decor-Furniture-&-Garden.png';
import JewlleryWatchesIcon from 'assets/planner/Jewellery-&-Watches.png';
import LuxaryGoodsIcon from 'assets/planner/Luxury-Goods.png';
import MusicIcon from 'assets/planner/Music.png';
import PetsIcon from 'assets/planner/Pets.png';
import RestaurantFoodIcon from 'assets/planner/Restaurants-Food-&-Grocery.png';
import ShoppingRetail from 'assets/planner/Shopping-&-Retail.png';
import SportsIcon from 'assets/planner/Sports.png';
import TelevisionFilmsIcon from 'assets/planner/Television-and-Film.png';
import TobaccoSmokingIcon from 'assets/planner/Tobacco-&-Smoking.png';
import ToysIcon from 'assets/planner/Toys-Children-&-Baby.png';
import TravelTourismIcon from 'assets/planner/Travel-Tourism-and-Aviation.png';
import WeddingIcon from 'assets/planner/Wedding.png';

import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const audienceIcons = {
  'Television & Film': TelevisionFilmsIcon,
  Music: MusicIcon,
  'Shopping & Retail': ShoppingRetail,
  'Coffee, Tea & Beverages': CoffeeTeaIcon,
  'Camera & Photography': CameraPhotographyIcon,
  'Clothes, Shoes, Handbags & Accessories': ClothesShoesIcon,
  'Beer, Wine & Spirits': BeerWineIcon,
  Sports: SportsIcon,
  'Electronics & Computers': ElectronicsComputerIcon,
  Gaming: GamingIcon,
  Activewear: ActivewearIcon,
  'Art & Design': ArtDesignIcon,
  'Travel, Tourism & Aviation': TravelTourismIcon,
  'Business & Careers': BusinessCareerIcon,
  'Beauty & Cosmetics': BeautyCosmeticIcon,
  'Healthcare & Medicine': HealthcareMedicineIcon,
  'Jewellery & Watches': JewlleryWatchesIcon,
  'Restaurants, Food & Grocery': RestaurantFoodIcon,
  'Toys, Children & Baby': ToysIcon,
  'Fitness & Yoga': FitnessYogaIcon,
  Wedding: WeddingIcon,
  'Tobacco & Smoking': TobaccoSmokingIcon,
  Pets: PetsIcon,
  'Healthy Lifestyle': HealthyLifestyleIcon,
  'Luxury Goods': LuxaryGoodsIcon,
  'Home Decor, Furniture & Garden': HomeDecorIcon,
  'Friends, Family & Relationships': FriendsFamilyIcon,
  'Cars & Motorbikes': CarMotorbikeIcon,
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function AudienceDetails({
  audience_location_split = {},
  audience_interest = [],
  audience_languages = [],
  audience_age_split = [],
  audience_genders_per_age = [],
  audience_gender_split = [],
  audience_gender = {},
  is_staff,
  dump,
}) {
  const classes = useStyles();
  // const [textToCopy, setTextToCopy] = useState('Text');
  const Icon = '';
  const getLabelData = (x, key = 'code') => {
    if (['weight', 'male', 'female'].includes(key)) {
      return x?.map((i) => Math.round(i[key] * 100));
    }
    return x?.map((i) => (Math.round(i['weight'] * 100) > 0 ? i[key] : '0'));
  };

  const copyOnClick = (copyText) => {
    navigator.clipboard.writeText(copyText);
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={12} className={classes.header}>
        <Typography variant="h3" style={{ color: 'black' }}>
          AUDIENCE DETAILS
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Grid container spacing={3}>
          <Grid item md={5}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <Grid className={classes.card} style={{ height: 320 }}>
                  <Grid container spacing={1}>
                    <Grid item style={{ paddingTop: '2.5%' }}>
                      <img src={GenderIcon} alt="icon" className={classes.iconSize} />
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        maxWidth: '100%',
                        width: '94%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="h3" style={{ color: 'black' }}>
                        Audience Gender Split
                      </Typography>
                      {is_staff ? (
                        <IconButton
                          onClick={() =>
                            copyOnClick(
                              audience_gender_split.map(
                                (entry) => `${entry.code} (${Math.round(entry.weight * 100)}%)`,
                              ),
                            )
                          }
                        >
                          <ContentCopyIcon size="small" style={{ height: '0.8em' }} />
                        </IconButton>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    {audience_gender_split.every(
                      (entry) => entry.hasOwnProperty('code') && entry.hasOwnProperty('weight'),
                    ) ? (
                      <Grid item style={{ height: 250, width: 300, marginLeft: 45 }}>
                        <Doughnut
                          data={{
                            labels: audience_gender_split.map(
                              (entry) => `${entry.code} (${Math.round(entry.weight * 100)}%)`,
                            ),
                            datasets: [
                              {
                                label: 'Gender',
                                data: audience_gender_split.map((entry) =>
                                  Math.round(entry.weight * 100),
                                ),
                                backgroundColor: [
                                  'rgba(54, 162, 235, 0.6)', // Blue for Male
                                  'rgba(255, 99, 132, 0.6)', // Red for Female
                                ],
                              },
                            ],
                          }}
                          options={{
                            legend: {
                              display: false,
                              position: 'right',
                            },
                          }}
                        />
                        <Grid style={{ position: 'relative',left: '38%', bottom: '67%' }}>
                          <Typography variant="body2" style={{ color: 'black' }}>
                            {audience_gender && audience_gender.gender
                              ? audience_gender.gender
                              : null}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ color: 'black', fontWeight: 700, marginLeft: '5%' }}
                          >
                            {audience_gender && audience_gender.percentage
                              ? `${Math.round(parseFloat(audience_gender.percentage))}%`
                              : null}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid className={classes.card} style={{ height: 250 }}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <img src={InterestIcon} alt="icon" className={classes.iconSize} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h3" style={{ color: '#0061A7' }}>
                        Audience Interest
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className={classes.interestContainer}>
                    {audience_interest instanceof Array &&
                      audience_interest.map((interest, i) => {
                        return (
                          <Grid item md={12} className={classes.interest} key={i}>
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item className={classes.interestIconContainer}>
                                {/* <FilterVintageIcon
                                className={classes.interestIcon}
                              /> */}
                                <img
                                  src={audienceIcons[interest.name]}
                                  style={{
                                    color: '#B9B9B9',
                                    fontSize: 13,
                                    width: 18,
                                    height: 18,
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                md={9}
                                style={{
                                  marginLeft: 10,
                                  textAlign: 'start',
                                }}
                              >
                                <Typography variant="body1">{interest.name}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={7}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <Grid className={classes.card} style={{ height: 90 }}>
                  <Grid container spacing={1}>
                    <Grid item style={{ paddingTop: '1.5%' }}>
                      <img src={LanguageIcon} alt="icon" className={classes.iconSize} />
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        maxWidth: '100%',
                        width: '96%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="h3" style={{ color: 'black' }}>
                        Language
                      </Typography>
                      {is_staff && audience_languages ? (
                        <IconButton
                          onClick={() =>
                            copyOnClick(
                              audience_languages
                                .slice(0, 3)
                                .map((language) => `${language.name} (${language.percentage})`),
                            )
                          }
                        >
                          <ContentCopyIcon size="small" style={{ height: '0.8em' }} />
                        </IconButton>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container className={classes.languageBox}>
                    {audience_languages instanceof Array &&
                      audience_languages.slice(0, 3).map((language, i) => (
                        <Grid item className={classes.languageContainer} key={i}>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{ color: 'black', fontWeight: 700 }}
                              >
                                {language.percentage}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2" style={{ color: 'black' }}>
                                {language.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid className={classes.card} style={{ height: 200 }}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <img src={GenderSplitIcon} alt="icon" className={classes.iconSize} />
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        maxWidth: '100%',
                        width: '96%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="h3" style={{ color: 'black' }}>
                        Audience Age Split
                      </Typography>
                      {is_staff && audience_age_split instanceof Array ? (
                        <IconButton
                          onClick={() =>
                            copyOnClick(
                              audience_age_split.map(
                                (age) => `${age.code} (${Math.round(age.weight * 100)}%)`,
                              ),
                            )
                          }
                        >
                          <ContentCopyIcon size="small" style={{ height: '0.8em' }} />
                        </IconButton>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item style={{ height: 350, width: 360, marginLeft: 100 }}>
                      <Bar
                        data={{
                          labels: getLabelData(audience_age_split, 'code').filter(
                            (val) => val != '0',
                          ),
                          datasets: [
                            {
                              label: 'Age Split',
                              data: getLabelData(audience_age_split, 'weight').filter(
                                (val) => val > 0,
                              ),
                              backgroundColor: 'rgba(123, 97, 255, 0.5)',
                            },
                          ],
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid className={classes.card} style={{ height: 213 }}>
                  <Grid container spacing={1}>
                    <Grid item style={{ paddingTop: '1.5%' }}>
                      <img src={LocationIcon} alt="icon" className={classes.iconSize} />
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        maxWidth: '100%',
                        width: '96%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="h3" style={{ color: '#B90000' }}>
                        Audience Location Split
                      </Typography>
                      {is_staff &&
                      audience_location_split &&
                      (audience_location_split?.cities || audience_location_split?.countries) ? (
                        <IconButton
                          onClick={() => {
                            const locations =
                              audience_location_split?.cities?.map(
                                (x) => `${x.name} (${Math.round(x.weight * 100)}%)`,
                              ) ||
                              audience_location_split?.countries?.map(
                                (x) => `${x.name} (${Math.round(x.weight * 100)}%)`,
                              );
                            copyOnClick(locations);
                          }}
                        >
                          <ContentCopyIcon size="small" style={{ height: '0.8em' }} />
                        </IconButton>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} >
                    <Grid item style={{ height: 350, width: 400, marginLeft: 100 }}>
                      <Bar
                        data={{
                          labels: (
                            audience_location_split?.cities || audience_location_split?.countries
                          )?.map((x) => x['name']),
                          datasets: [
                            {
                              label: 'Location Split',
                              data: getLabelData(
                                audience_location_split?.cities ||
                                  audience_location_split?.countries,
                                'weight',
                              ),
                              backgroundColor: 'rgba(185, 0, 0, 0.8)',
                            },
                          ],
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  header: {
    background: '#F3F4FE',
    boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    borderRadius: 10,
    padding: 15,
    marginBottom: 15,
  },
  card: {
    background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    padding: 20,
  },
  graphCard: {
    height: 200,
  },
  iconSize: {
    width: 15,
    height: 15,
  },
  brandAffinityCard: {
    height: 170,
  },
  pricingCard: {
    height: 200,
    marginTop: 15,
  },
  interestContainer: {
    marginTop: 15,
    marginLeft: 5,
    overflowY: 'scroll',
    maxHeight: '90%',
  },
  interestIconContainer: {
    // background: "linear-gradient(135deg, #E8EAEE 0%, #FFFFFF 100%)",
    // boxShadow:
    // "-5px 5px 10px rgba(220, 221, 222, 0.2), 5px -5px 10px rgba(220, 221, 222, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(220, 221, 222, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(220, 221, 222, 0.5)",
    borderRadius: '50%',
    padding: 3,
    width: 23,
    height: 23,
  },
  interestIcon: {
    color: '#B9B9B9',
    fontSize: 13,
    paddingBottom: 2,
    width: 18,
    height: 18,
  },
  languageBox: {
    marginLeft: 20,
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  languageContainer: {
    background: '#F3F4FE',
    boxShadow:
      '-5px 5px 10px rgba(219, 220, 229, 0.2), 5px -5px 10px rgba(219, 220, 229, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(219, 220, 229, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(219, 220, 229, 0.5)',
    borderRadius: 5,
    padding: '5px 20px',
    margin: 10,
  },
}));

export default AudienceDetails;
