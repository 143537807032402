import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FollowerIcon from 'assets/discovery/followers-icon.png';
import EngIcon from 'assets/discovery/Eng-Rate-icon.png';
import AvgViewIcon from 'assets/discovery/Avg-View-icon.png';
import AvgEngIcon from 'assets/discovery/Avg-Eng-Icon.png';
import PPPIcon from 'assets/discovery/PPP-icon.png';

import StatsCard from './components/StatsCard';

function BasicStats({ followers, engagement_rate, avg_views, paid_post_performance, engagements }) {
  const classes = useStyles();
  return (
    <Grid container spacing={4} justifyContent="space-evenly">
      <Grid item>
        <StatsCard value={followers} label={'Followers'} iconSrc={FollowerIcon} />
      </Grid>
      <Grid item>
        <StatsCard value={engagement_rate} label={'Engagement Rate'} iconSrc={EngIcon} />
      </Grid>
      <Grid item>
        <StatsCard value={avg_views} label={'Average View'} iconSrc={AvgViewIcon} />
      </Grid>
      <Grid item>
        <StatsCard value={engagements} label={'Average Engagement'} iconSrc={AvgEngIcon} />
      </Grid>
      <Grid item>
        <StatsCard
          value={paid_post_performance}
          label={'Paid Post Performance'}
          iconSrc={PPPIcon}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '3px !important',
    paddingBottom: '3px !important',
    cursor: 'pointer',
  },
}));

export default BasicStats;
