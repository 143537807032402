import { Grid, makeStyles, Typography } from '@material-ui/core';

export default function TopHashTags({ top_hashtags = [] }) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item md={12} className={classes.header}>
        <Typography variant="h3" style={{ color: 'black' }}>
          TOP HASHTAGS
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Grid container justifyContent="space-between" style={{ height: '100%' }}>
          {top_hashtags.length > 0
            ? top_hashtags.slice(0, 3).map((hashtag, i) => (
                <Grid item md={3} className={classes.card} style={{ height: '100%' }}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item md={11}>
                      <Typography variant="body2">#{hashtag.tag}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            : ''}
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  header: {
    background: '#F3F4FE',
    boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    borderRadius: 10,
    padding: 15,
    marginBottom: 30,
  },
  card: {
    background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    padding: 20,
  },
  image: {
    height: 220,
    width: 220,
    borderRadius: 20,
  },
  icon: {
    height: 20,
    width: 20,
  },
}));
