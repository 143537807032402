import { useEffect, useState, Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Tick from 'assets/planner/tick.png';

class Channel extends Component {
  render() {
    const { classes, channels } = this.props;

    let isInstaSelected = channels.indexOf('Instagram') > -1;
    let isYouTubeSelected = channels.indexOf('YouTube') > -1;

    return (
      <Grid container style={{}}>
        <div
          onClick={() => {
            if (isInstaSelected) {
              channels.splice(channels.indexOf('Instagram'), 1);
              this.props.setChannels(channels);
            } else {
              channels.push('Instagram');
              this.props.setChannels(channels);
            }
          }}
          className={isInstaSelected ? classes.selectedBorder : classes.defaultBorder}
        >
          {isInstaSelected ? (
            <img src={Tick} width={17} height={13} style={{ marginRight: 5 }} />
          ) : null}
          <Typography className={isInstaSelected ? classes.selectedText : classes.default}>
            Instagram
          </Typography>
        </div>
        <div
          onClick={() => {
            if (isYouTubeSelected) {
              channels.splice(channels.indexOf('YouTube'), 1);
              this.props.setChannels(channels);
            } else {
              channels.push('YouTube');
              this.props.setChannels(channels);
            }
          }}
          className={isYouTubeSelected ? classes.selectedBorder : classes.defaultBorder}
        >
          {isYouTubeSelected ? (
            <img src={Tick} width={17} height={13} style={{ marginRight: 5 }} />
          ) : null}
          <Typography className={isYouTubeSelected ? classes.selectedText : classes.default}>
            YouTube
          </Typography>
        </div>
      </Grid>
    );
  }
}

const styles = {
  selectedText: {
    color: '#FEBD1C',
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: 13,
    cursor: 'pointer',
  },
  default: {
    color: '#757575',
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 13,
    cursor: 'pointer',
  },
  selectedBorder: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 15,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    border: '1px solid #FEBD1C',
    borderRadius: 20,
  },
  defaultBorder: {
    // fontFamily:'Nunito Sans',
    // fontWeight:700,
    display: 'flex',
    alignItems: 'center',
    marginRight: 15,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    border: '1px solid #757575',
    borderRadius: 20,
  },
};

export default withStyles(styles)(Channel);
