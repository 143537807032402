import { Component } from 'react';
import { Card, CardContent, Grid, Divider } from '@material-ui/core';

import CountDisplay from '../../../../ux/CountDisplay';

import MoneyIcon from '@material-ui/icons/AttachMoney';
import logo from '../../../../../assets/instagram-logo.png';
import vector from '../../../../../assets/vector.png';

class EngagementStatistics extends Component {
  render() {
    const Icon = this.props.icon;
    return (
      <Grid container spacing={1}>
        <Grid item md={12}>
          <Card>
            <CardContent>
              <Grid container justify={'center'}>
                <Grid item md={6}>
                  <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    md={12}
                  >
                    <img src={logo} width={'50'} height={'50'} />
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    md={12}
                  >
                    <div
                      style={{
                        padding: '10px',
                        margin: '10px',
                        background: '#FDDFDF',
                        borderRadius: '5.12839px',
                        width: '64px',
                        'justify-content': 'center',
                        'align-items': 'center',
                      }}
                    >
                      <img src={vector} width={'15'} height={'15'} />
                      <span style={{ color: '#F14444', margin: '5px', marginBottom: '10px' }}>
                        {this.props.stats && this.props.stats.total_videos}
                      </span>
                    </div>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item md={5}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item md={3} style={{ marginTop: '12px' }}>
                      {Icon ? <Icon style={{ color: '#7158F1' }} /> : null}
                    </Grid>
                    <Grid item md={9}>
                      <Grid
                        container
                        style={{ textAlign: 'left' }}
                        direction="column"
                        justify="space-evenly"
                        alignItems="center"
                      >
                        <Grid item md={12}>
                          <span
                            style={{
                              fontSize: '11px',
                              lineHeight: '',
                            }}
                          >
                            {'Total Engagement Rate'}
                          </span>
                        </Grid>
                        <Grid item md={12}>
                          <span
                            style={{
                              fontSize: '24px',
                              fontWeight: 700,
                            }}
                          >
                            <CountDisplay
                              count={this.props.stats && this.props.stats.engagement_rate}
                            />
                          </span>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          direction="column"
                          justify="space-evenly"
                          alignItems="center"
                        >
                          <div
                            style={{
                              padding: '5px',
                              margin: '5px',
                              background: '#75D12C',
                              borderRadius: '5.12839px',
                              width: '54px',
                              'justify-content': 'center',
                              'align-items': 'center',
                            }}
                          >
                            <p style={{ color: '#FFFFFF', margin: '5px', fontSize: '11px' }}>
                              Excellent
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default EngagementStatistics;
