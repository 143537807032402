import { useState, useEffect } from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LocationIcon from 'assets/discovery/Location-Icon.png';
import SelectInterest from 'assets/planner/select_interest.png';

import MultiSelect from 'components/PlannerTheme/Elements/MultiSelect';
import { getPlannerAudienceInterests } from 'api_handlers/discovery';

function AudienceInterest({ audienceInterest, hideAdvanceFilters, classes }) {
  const [interests, setInterests] = useState([]);

  useEffect(() => {
    getPlannerAudienceInterests().then((response) => {
      console.log('response data', response);
      setInterests(response);
      console.log(interests);
    });
  }, []);
  return (
    <>
      <div
        style={{
          display: 'flex',
          marginBottom: 10,
          justifyContent: 'center',
        }}
      >
        <img src={SelectInterest} width={22} style={{ marginRight: 7 }} />
        <Typography className={classes.advanceHeading}>Audience Interest</Typography>
      </div>
      <div style={{ marginTop: 10 }}>
        <MultiSelect
          label={'Select Interest'}
          options={interests.map((interest) => ({
            label: interest,
            value: interest,
          }))}
          value={audienceInterest || []}
          onChange={(event, selectedValues) => {
            hideAdvanceFilters('interest_selection', selectedValues);
          }}
        />
      </div>
    </>
  );
}

const styles = {
  advanceHeading: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 14,
  },
};

export default withStyles(styles)(AudienceInterest);
