import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import Campaign from './cards/Campaign.jsx';

import { campaignActions } from '../../actions/campaign.js';
import { connect } from 'react-redux';

import Loading from '../Common/Loading/Loading';
import NoCampaign from '../ux/NoCampaign';

class CampaignDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: [],
    };
  }

  componentDidMount() {
    this.props.getInfluencerCampaigns();
  }

  render() {
    return (
      <Grid container justify="center" style={{ marginTop: 15 }}>
        {this.props.loading && <Loading />}
        <Grid item md={7}>
          {this.props.campaigns.length ? (
            <Grid container>
              <Grid item md={12}>
                {this.props.campaigns?.map((campaign) => (
                  <Campaign {...campaign} key={campaign.id} />
                ))}
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item md={12}>
                <NoCampaign />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.campaign.loading,
  campaigns: state.campaign.campaigns,
});

const actionCreators = {
  getInfluencerCampaigns: campaignActions.getInfluencerCampaigns,
};

export default connect(mapStateToProps, actionCreators)(withRouter(CampaignDashboard));
