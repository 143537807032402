import React from 'react';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Modal,
  Tab,
  Tabs,
  Typography,
  Link,
  Chip,
  TextField,
} from '@material-ui/core';
import SocialMediaSummery from './SocialMediaSummary';

import { colorConstants } from '../../../constants/colors';
import { campaignActions } from '../../../actions/campaign';

import InstagramLogo from '../../../assets/instagram-logo.png';
import YouTubeLogo from '../../../assets/youtube-logo.png';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { campaignHandlers } from '../../../api_handlers/campaign';
import NegoitateInput from '../../ux/NegoitateInput';
import LinkIcon from '@mui/icons-material/Link';
import CardMedia from '@mui/material/CardMedia';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import DetailViewModal from 'components/Planner/components/InfluencerInfoCard/components/DetailViewModal';
import themeV2 from 'themeV2';
import AppBar from '@mui/material/AppBar';
import './influencer.styles.css';

class Influencer extends React.Component {
  constructor(props) {
    super(props);
    this.setDetailViewOpened = this.setDetailViewOpened.bind(this);
    this.state = {
      is_visible: true,
      detailViewOpened:false,
      id:'',
      shortlistedinfluencer: this.props,
      socialPlatform: this.props.youtube_link
        ? 'youtube'
        : this.props.instagram_link
        ? 'instagram'
        : '',
      pending: this.props.status === 'pending',
      rejected: this.props.status === 'rejected',
      approved: this.props.status === 'approved',
      noAction: this.props.status === 'pending' && this.props.brand_quote === 0,
      modalOpen: false,
      status: this.props.status,
      brand_quote: this.props.brand_quote,
      refrenceLinkModelOpen: false,
      prefTab: 0,
    };
    console.log(this.props);
  }
  refresh_state = () => {
    // this.handle_card()
    this.props.summary_handler();
    this.props.getCampaignDetails(this.state.shortlistedinfluencer.campaign.id);
  };

  toggleSocialPlatform = (event, platform) => {
    this.setState({
      socialPlatform: platform,
    });
    if (platform == 'youtube') {
      this.setState({
        prefTab: 1,
      });
    } else {
      this.setState({
        prefTab: 0,
      });
    }
  };
  toggleprefTab = (event, pretab) => {
    this.setState({
      prefTab: pretab,
    });
  };
  toggleRefrenceLinkModel = () =>
    this.setState({
      refrenceLinkModelOpen: !this.state.refrenceLinkModelOpen,
    });

  toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen });

  async updateshortlistedinfluencer(brand_quote, status) {
    var shortlistedinfluencer = this.state.shortlistedinfluencer;
    if (shortlistedinfluencer.ig_reel && document.getElementById('ig_reel')) {
      shortlistedinfluencer = Object.assign({}, shortlistedinfluencer, {
        ig_reel: parseInt(document.getElementById('ig_reel').value),
      });
    }
    if (this.state.shortlistedinfluencer.ig_post && document.getElementById('ig_post')) {
      shortlistedinfluencer = Object.assign({}, shortlistedinfluencer, {
        ig_post: parseInt(document.getElementById('ig_post').value),
      });
    }
    if (shortlistedinfluencer.ig_static && document.getElementById('ig_static')) {
      shortlistedinfluencer = Object.assign({}, shortlistedinfluencer, {
        ig_static: parseInt(document.getElementById('ig_static').value),
      });
    }
    if (shortlistedinfluencer.ig_story && document.getElementById('ig_story')) {
      shortlistedinfluencer = Object.assign({}, shortlistedinfluencer, {
        ig_story: parseInt(document.getElementById('ig_story').value),
      });
    }
    if (shortlistedinfluencer.ig_tv && document.getElementById('ig_tv')) {
      shortlistedinfluencer = Object.assign({}, shortlistedinfluencer, {
        ig_tv: parseInt(document.getElementById('ig_tv').value),
      });
    }
    if (shortlistedinfluencer.ig_live && document.getElementById('ig_live')) {
      shortlistedinfluencer = Object.assign({}, shortlistedinfluencer, {
        ig_live: parseInt(document.getElementById('ig_live').value),
      });
    }
    if (
      shortlistedinfluencer.yt_integrated_video &&
      document.getElementById('yt_integrated_video')
    ) {
      shortlistedinfluencer = Object.assign({}, shortlistedinfluencer, {
        yt_integrated_video: parseInt(document.getElementById('yt_integrated_video').value),
      });
    }
    if (shortlistedinfluencer.yt_shorts && document.getElementById('yt_shorts')) {
      shortlistedinfluencer = Object.assign({}, shortlistedinfluencer, {
        yt_shorts: parseInt(document.getElementById('yt_shorts').value),
      });
    }
    if (shortlistedinfluencer.yt_dedic_video && document.getElementById('yt_dedic_video')) {
      shortlistedinfluencer = Object.assign({}, shortlistedinfluencer, {
        yt_dedic_video: parseInt(document.getElementById('yt_dedic_video').value),
      });
    }
    shortlistedinfluencer = Object.assign({}, shortlistedinfluencer, {
      brand_quote: brand_quote,
    });
    shortlistedinfluencer = Object.assign({}, shortlistedinfluencer, {
      status: status,
    });
    if (shortlistedinfluencer.status == 'approved' && !shortlistedinfluencer.final_price) {
      shortlistedinfluencer = Object.assign({}, shortlistedinfluencer, {
        final_price: shortlistedinfluencer.brand_quote
          ? shortlistedinfluencer.brand_quote
          : shortlistedinfluencer.influencer_quote,
      });
    }
    this.setState({ shortlistedinfluencer: shortlistedinfluencer });
    this.setState({
      pending: shortlistedinfluencer.status === 'pending',
      rejected: shortlistedinfluencer.status === 'rejected',
      approved: shortlistedinfluencer.status === 'approved',
      noAction:
        shortlistedinfluencer.status === 'pending' && shortlistedinfluencer.brand_quote === 0,
      status: shortlistedinfluencer.status,
    });

    await campaignHandlers
      .updateShortlistedInfluencer(this.props.campaign, this.props.id, shortlistedinfluencer)
      .then((r) => this.refresh_state());
  }

  negotiatePrice = (brand_quote) => {
    this.setState({ brand_quote: parseInt(brand_quote) });
    this.updateshortlistedinfluencer(parseInt(brand_quote), this.state.status);
  };
  handleNegotitateAmount = (event) => {
    this.setState({ brand_quote: parseInt(event.target.value) });
  };

  cancelNegotiation = () => {
    this.setState({ brand_quote: 0 });
    this.updateshortlistedinfluencer(0, this.state.status);
  };

  updateInfluencerStatus = (status) => {
    this.setState({ status: status });
    this.updateshortlistedinfluencer(this.state.brand_quote, status);
  };

  setDetailViewOpened(boolean) {
    this.setState({ detailViewOpened: boolean });
  }

  getVariablePayText(shortlistedinfluencer) {
    return shortlistedinfluencer.variable_pay > 0 ? (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{
          paddingBottom: 5,
          marginRight: 25,
          fontWeight: 'bold',
        }}
      >
        <Grid item>
          <Grid container style={{ fontSize: 13 }}>
            + Rs {this.state.shortlistedinfluencer.variable_pay}
          </Grid>
          <Grid container style={{ fontSize: 9 }}>
            per conversion
          </Grid>
        </Grid>
      </Grid>
    ) : (
      ''
    );
  }

  render() {
    var field_of_intrest = this.state.shortlistedinfluencer.influencer.field_of_intrest;
    const influencerId =this.state.shortlistedinfluencer.id
    console.log("id",this.state.shortlistedinfluencer)
    var more_intrest_number = 0;
    if (field_of_intrest.length > 3) {
      more_intrest_number = field_of_intrest.length - 3;
      field_of_intrest = field_of_intrest.slice(0, 3);
    }
    return (
      <Card
        style={{
          radius: '5px',
          marginBottom: '10px',
          width: '99%',
        }}
      >
        <CardContent>
          <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
            <Grid item xs={7}>
              <Grid container
               style={{
                cursor:'pointer',
              }}
                  onClick={() => {
                    this.setState({detailViewOpened:true,id:influencerId})
                    console.log("id",influencerId)
                  }}
              >
                <Grid item xs={2}>
                  <Avatar
                    src={this.state.shortlistedinfluencer.influencer.avatar}
                    alt={this.state.shortlistedinfluencer.influencer.name}
                    style={{
                      width: '6vw',
                      height: '6vw',
                      cursor:'pointer'
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Grid
                    container
                    direction="column"
                    justify="flex-end"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          marginTop: '10px',
                          textAlign: 'left',
                        }}
                        component="h6"
                        variant="h6"
                      >
                        {this.state.shortlistedinfluencer.influencer.name}
                        &nbsp;
                        {this.state.shortlistedinfluencer.is_new && (
                          <span
                            style={{
                              fontSize: '12px',
                              color: 'white',
                              backgroundColor: 'green',
                              padding: '1px 5px',
                              borderRadius: '5px',
                              'vertical-align': 'middle',
                            }}
                          >
                            Recent
                          </span>
                        )}
                        &nbsp;
                        {this.state.shortlistedinfluencer.youtube_link && (
                          <a target="_blank" href={this.state.shortlistedinfluencer.youtube_link} rel="noreferrer">
                            <img src={YouTubeLogo} alt={'youtube-logo'} width={'20'} />
                          </a>
                        )}
                        &nbsp;
                        {this.state.shortlistedinfluencer.instagram_link && (
                          <a target="_blank" href={this.state.shortlistedinfluencer.instagram_link} rel="noreferrer">
                            <img src={InstagramLogo} alt={'instagram-logo'} width={'15'} />
                          </a>
                        )}
                        &nbsp;
                        {this.state.shortlistedinfluencer.influencer.reference_link.length != 0 && (
                          <span
                            style={{
                              'vertical-align': 'middle',
                            }}
                          >
                            <Tooltip
                              title="Delete"
                              style={{
                                cursor: 'pointer',
                              }}
                              title="Refrence Links"
                            >
                              <LinkIcon onClick={this.toggleRefrenceLinkModel} />
                            </Tooltip>
                          </span>
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          marginTop: '10px',
                          textAlign: 'left',
                          fontSize: '12px',
                        }}
                        component="span"
                        variant="span"
                      >
                        User Id:&nbsp;
                      </Typography>
                      <Typography
                        style={{
                          marginTop: '10px',
                          textAlign: 'left',
                          fontSize: '12px',
                        }}
                        component="i"
                        variant="i"
                      >
                        {this.state.shortlistedinfluencer.influencer.id}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {field_of_intrest?.map((field) => (
                        <Chip
                          label={field.name}
                          size="small"
                          style={{
                            background: '#A0A0A0',
                            color: 'white',
                            fontSize: '11px',
                            padding: '0px 8px 0px',
                            marginRight: '7px',
                            height: 20,
                          }}
                        ></Chip>
                      ))}
                      {more_intrest_number ? (
                        <Chip
                          label={' +' + more_intrest_number}
                          size="small"
                          style={{
                            background: '#A0A0A0',
                            color: 'white',
                            fontSize: '11px',
                            padding: '0px 8px 0px',
                            marginRight: '7px',
                            height: 20,
                          }}
                        ></Chip>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      {this.state.shortlistedinfluencer.ig_reel ||
                      this.state.shortlistedinfluencer.ig_post ||
                      this.state.shortlistedinfluencer.ig_static ||
                      this.state.shortlistedinfluencer.ig_story ||
                      this.state.shortlistedinfluencer.ig_tv ||
                      this.state.shortlistedinfluencer.ig_live ||
                      this.state.shortlistedinfluencer.yt_integrated_video ||
                      this.state.shortlistedinfluencer.yt_shorts ||
                      this.state.shortlistedinfluencer.yt_dedic_video ? (
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="flex-start"
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              fontSize: '13px',
                              fontWeight: '900',
                            }}
                          >
                            Deliverables
                          </Grid>
                          <Grid item xs={12}>
                            {this.state.shortlistedinfluencer.ig_reel ? 'Instagram Reel' : null}
                            {this.state.shortlistedinfluencer.ig_reel &&
                            (this.state.shortlistedinfluencer.ig_post ||
                              this.state.shortlistedinfluencer.ig_static ||
                              this.state.shortlistedinfluencer.ig_story ||
                              this.state.shortlistedinfluencer.ig_tv ||
                              this.state.shortlistedinfluencer.ig_live ||
                              this.state.shortlistedinfluencer.yt_integrated_video ||
                              this.state.shortlistedinfluencer.yt_shorts ||
                              this.state.shortlistedinfluencer.yt_dedic_video)
                              ? ' | '
                              : null}
                            {this.state.shortlistedinfluencer.ig_post ? 'Instagram Posts' : null}
                            {this.state.shortlistedinfluencer.ig_post &&
                            (this.state.shortlistedinfluencer.ig_static ||
                              this.state.shortlistedinfluencer.ig_story ||
                              this.state.shortlistedinfluencer.ig_tv ||
                              this.state.shortlistedinfluencer.ig_live ||
                              this.state.shortlistedinfluencer.yt_integrated_video ||
                              this.state.shortlistedinfluencer.yt_shorts ||
                              this.state.shortlistedinfluencer.yt_dedic_video)
                              ? ' | '
                              : null}
                            {this.state.shortlistedinfluencer.ig_static ? 'Instagram Static' : null}
                            {this.state.shortlistedinfluencer.ig_static &&
                            (this.state.shortlistedinfluencer.ig_story ||
                              this.state.shortlistedinfluencer.ig_tv ||
                              this.state.shortlistedinfluencer.ig_live ||
                              this.state.shortlistedinfluencer.yt_integrated_video ||
                              this.state.shortlistedinfluencer.yt_shorts ||
                              this.state.shortlistedinfluencer.yt_dedic_video)
                              ? ' | '
                              : null}
                            {this.state.shortlistedinfluencer.ig_story ? 'Instagram Story' : null}
                            {this.state.shortlistedinfluencer.ig_story &&
                            (this.state.shortlistedinfluencer.ig_tv ||
                              this.state.shortlistedinfluencer.ig_live ||
                              this.state.shortlistedinfluencer.yt_integrated_video ||
                              this.state.shortlistedinfluencer.yt_shorts ||
                              this.state.shortlistedinfluencer.yt_dedic_video)
                              ? ' | '
                              : null}
                            {this.state.shortlistedinfluencer.ig_tv ? 'Instagram TV' : null}
                            {this.state.shortlistedinfluencer.ig_tv &&
                            (this.state.shortlistedinfluencer.ig_live ||
                              this.state.shortlistedinfluencer.yt_integrated_video ||
                              this.state.shortlistedinfluencer.yt_shorts ||
                              this.state.shortlistedinfluencer.yt_dedic_video)
                              ? ' | '
                              : null}
                            {this.state.shortlistedinfluencer.ig_live ? 'Instagram Live' : null}
                            {this.state.shortlistedinfluencer.ig_live &&
                            (this.state.shortlistedinfluencer.yt_integrated_video ||
                              this.state.shortlistedinfluencer.yt_shorts ||
                              this.state.shortlistedinfluencer.yt_dedic_video)
                              ? ' | '
                              : null}
                            {this.state.shortlistedinfluencer.yt_integrated_video
                              ? 'Youtube Integrated Video'
                              : null}
                            {this.state.shortlistedinfluencer.yt_integrated_video &&
                            (this.state.shortlistedinfluencer.yt_shorts ||
                              this.state.shortlistedinfluencer.yt_dedic_video)
                              ? ' | '
                              : null}
                            {this.state.shortlistedinfluencer.yt_shorts ? 'Youtube Shorts' : null}
                            {this.state.shortlistedinfluencer.yt_shorts &&
                            this.state.shortlistedinfluencer.yt_dedic_video
                              ? ' | '
                              : null}
                            {this.state.shortlistedinfluencer.yt_dedic_video
                              ? 'Youtube Dedicated Video'
                              : null}
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={4} direction="row" justify="flex-end" alignItems="center">
              <Grid item>
                {!this.state.noAction && (
                  <div
                    style={{
                      backgroundColor: this.state.pending
                        ? 'whitesmoke'
                        : this.state.approved
                        ? '#EAF5DD'
                        : '#F5DDDD',
                      display: 'flex',
                      height: '90px',
                      marginRight: '20px',
                      justifyContent: 'flex-start',
                      radius: '5px',
                      fontSize: 12,
                    }}
                    align={'left'}
                  >
                    <dl
                      style={{
                        width: '16vw',
                        marginTop: '0px',
                      }}
                    >
                      <dt
                        style={{
                          fontSize: '13px',
                          paddingLeft: '15px',
                          textAlign: 'left',
                        }}
                      >
                        <p>
                          {this.state.rejected && 'You have rejected this influencer.'}
                          {this.state.approved && 'You have approved to work with this influencer.'}
                          {this.state.pending &&
                            this.state.shortlistedinfluencer.brand_quote > 0 &&
                            this.state.shortlistedinfluencer.final_price === 0 &&
                            'Your request for revisited costing is pending for approval.'}
                          {this.state.pending &&
                            this.state.shortlistedinfluencer.brand_quote > 0 &&
                            this.state.shortlistedinfluencer.final_price > 0 &&
                            'Your request for revisited costing is reveied and influencer has provide final pricing for approval.'}
                          <br />
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              this.state.pending && this.state.shortlistedinfluencer.brand_quote > 0
                                ? this.cancelNegotiation()
                                : this.updateInfluencerStatus('pending');
                            }}
                          >
                            {this.state.shortlistedinfluencer.campaign.is_live ||
                            this.state.shortlistedinfluencer.campaign.status != 'draft'
                              ? ''
                              : 'Cancel Action.'}
                          </Link>
                        </p>
                      </dt>
                    </dl>
                    <dl
                      style={{
                        paddingTop: '15px',
                        marginTop: '0px',
                        marginRight: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <dt>
                        {this.state.shortlistedinfluencer.final_price
                          ? 'Final Price'
                          : this.state.shortlistedinfluencer.brand_quote
                          ? 'Brand Quote'
                          : 'Tentative Quote'}
                      </dt>
                      <dt
                        style={{
                          fontWeight: 'bold',
                          fontSize: '19px',
                        }}
                      >
                        Rs.
                        {this.state.shortlistedinfluencer.final_price
                          ? this.state.shortlistedinfluencer.final_price
                          : this.state.shortlistedinfluencer.brand_quote
                          ? this.state.shortlistedinfluencer.brand_quote
                          : this.state.shortlistedinfluencer.influencer_quote}
                      </dt>
                      {this.getVariablePayText(this.state.shortlistedinfluencer)}
                      {this.state.pending && (
                        <dt
                          style={{
                            marginLeft: '7px',
                            marginBottom: '5px',
                          }}
                        >
                          <strike>
                            {this.state.shortlistedinfluencer.final_price &&
                            this.state.shortlistedinfluencer.final_price !=
                              this.state.shortlistedinfluencer.brand_quote
                              ? this.state.shortlistedinfluencer.brand_quote
                                ? 'Rs. ' + this.state.shortlistedinfluencer.brand_quote
                                : 'Rs. ' + this.state.shortlistedinfluencer.influencer_quote
                              : 'Rs. ' + this.state.shortlistedinfluencer.influencer_quote}
                          </strike>
                        </dt>
                      )}
                    </dl>
                  </div>
                )}
                {this.state.noAction && (
                  <div
                    style={{
                      // backgroundColor: this.state.pending ? 'whitesmoke' : (this.state.approved ? '#EAF5DD' : '#F5DDDD'),
                      // transform: this.state.pending ? 'translateX(90px)translateY(-90%)' :
                      //     (this.state.approved ? 'translateX(301%)translateY(-90%)' : 'translateX(298%)translateY(-105%)')
                      // backgroundColor: this.state.pending ? 'whitesmoke' :
                      //     (this.state.approved ? '#EAF5DD' : '#F5DDDD'),
                      display: 'flex',
                      height: '90px',
                      marginRight: '20px',
                      justifyContent: 'flex-start',
                      radius: '5px',
                      fontSize: 12,
                    }}
                  >
                    <dl
                      style={{
                        marginTop: '0px',
                        marginRight: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <dt>
                        {this.state.shortlistedinfluencer.influencer_quote
                          ? 'Tentative Quote'
                          : this.state.shortlistedinfluencer.brand_quote
                          ? 'Brand Quote'
                          : 'Final Price'}
                      </dt>
                      <dt
                        style={{
                          fontWeight: 'bold',
                          fontSize: '19px',
                        }}
                      >
                        Rs.{' '}
                        {this.state.shortlistedinfluencer.influencer_quote
                          ? this.state.shortlistedinfluencer.influencer_quote
                          : this.state.shortlistedinfluencer.brand_quote
                          ? this.state.shortlistedinfluencer.brand_quote
                          : this.state.shortlistedinfluencer.final_price}
                      </dt>
                      {this.getVariablePayText(this.state.shortlistedinfluencer)}
                      <dt
                        style={{
                          color: 'cyan',
                        }}
                      >
                        {this.state.shortlistedinfluencer.campaign.is_live ||
                        this.state.shortlistedinfluencer.campaign.status != 'draft' ? (
                          ''
                        ) : (
                          <a
                            onClick={this.toggleModal}
                            style={{
                              color: '#1E9CB2',
                              fontSize: '12px',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                          >
                            Negotiate Price
                          </a>
                        )}
                      </dt>
                    </dl>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <hr
                style={{
                  width: '99%',
                  border: '1px solid #A6A6A6',
                }}
              />
            </Grid>
          </Grid>
          {(this.state.shortlistedinfluencer.youtube_link ||
            this.state.shortlistedinfluencer.instagram_link) && (
            <Grid container>
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'block',
                    fontWeight: 'bold',
                    fontSize: '13px',
                  }}
                >
                  <Tabs
                    value={this.state.socialPlatform}
                    onChange={this.toggleSocialPlatform}
                    wrapped={'true'}
                  >
                    {' '}
                    {this.state.shortlistedinfluencer.youtube_link && (
                      <Tab
                        value={'youtube'}
                        icon={<img src={YouTubeLogo} alt={'youtube-logo'} width={'30'} />}
                        label={'YouTube'}
                      />
                    )}
                    {this.state.shortlistedinfluencer.instagram_link && (
                      <Tab
                        value={'instagram'}
                        icon={<img src={InstagramLogo} alt={'instagram-logo'} width={'30'} />}
                        label={'Instagram'}
                      ></Tab>
                    )}
                    {!(
                      this.state.shortlistedinfluencer.campaign.is_live ||
                      this.state.shortlistedinfluencer.campaign.status != 'draft'
                    ) &&
                      this.state.pending && (
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'right',
                            flex: 'auto',
                            marginLeft: 'auto',
                          }}
                        >
                          <div
                            style={{
                              float: 'right',
                              marginRight: '20px',
                            }}
                          >
                            <Button
                              variant="outlined"
                              style={{
                                color: colorConstants.TEXT_BLUE,
                                borderColor: colorConstants.TEXT_BLUE,
                                marginRight: '10px',
                              }}
                              onClick={this.updateInfluencerStatus.bind(this, 'rejected')}
                            >
                              Reject
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: colorConstants.APPROVE_BUTTON,
                                color: 'white',
                              }}
                              onClick={this.updateInfluencerStatus.bind(this, 'approved')}
                            >
                              Approve
                            </Button>
                          </div>
                        </div>
                      )}
                  </Tabs>
                  {this.state.socialPlatform == 'instagram' &&
                    this.state.shortlistedinfluencer.instagram_link && (
                      <SocialMediaSummery
                        socialPlatform={this.state.socialPlatform}
                        {...this.state.shortlistedinfluencer.instagram_detail}
                        {...this.state.shortlistedinfluencer.youtube_detail}
                      />
                    )}
                  {this.state.socialPlatform == 'youtube' &&
                    this.state.shortlistedinfluencer.youtube_link && (
                      <SocialMediaSummery
                        socialPlatform={this.state.socialPlatform}
                        {...this.state.shortlistedinfluencer.instagram_detail}
                        {...this.state.shortlistedinfluencer.youtube_detail}
                      />
                    )}
                </div>
              </Grid>
            </Grid>
          )}

          <div>
            <Modal
              open={this.state.modalOpen}
              onClose={this.toggleModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div
                style={{
                  backgroundColor: 'white',
                  width: '50vw',
                  transform: 'translate(50%, 20vh)',
                  padding: '10px',
                }}
                align={'left'}
              >
                <h3 style={{ paddingTop: '10px' }}>Negotiate Price</h3>
                <hr style={{ width: '99%' }} />
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    Enter your preferred pricing for the Influencer:{' '}
                    {this.state.shortlistedinfluencer.influencer.name}
                  </Grid>
                  <NegoitateInput
                    logo={InstagramLogo}
                    text={'Instagram Reel'}
                    value={this.state.shortlistedinfluencer.ig_reel}
                    id={'ig_reel'}
                  />
                  <NegoitateInput
                    logo={InstagramLogo}
                    text={'Instagram Post'}
                    value={this.state.shortlistedinfluencer.ig_post}
                    id={'ig_post'}
                  />
                  <NegoitateInput
                    logo={InstagramLogo}
                    text={'Instagram Static'}
                    value={this.state.shortlistedinfluencer.ig_static}
                    id={'ig_static'}
                  />
                  <NegoitateInput
                    logo={InstagramLogo}
                    text={'Instagram Story'}
                    value={this.state.shortlistedinfluencer.ig_story}
                    id={'ig_story'}
                  />
                  <NegoitateInput
                    logo={InstagramLogo}
                    text={'Instagram TV'}
                    value={this.state.shortlistedinfluencer.ig_tv}
                    id={'ig_tv'}
                  />
                  <NegoitateInput
                    logo={InstagramLogo}
                    text={'Instagram Live'}
                    value={this.state.shortlistedinfluencer.ig_live}
                    id={'ig_live'}
                  />
                  <NegoitateInput
                    logo={YouTubeLogo}
                    text={'Youtube Shorts'}
                    value={this.state.shortlistedinfluencer.yt_shorts}
                    id={'yt_shorts'}
                  />
                  <NegoitateInput
                    logo={YouTubeLogo}
                    text={'Youtube Integrated Video'}
                    value={this.state.shortlistedinfluencer.yt_integrated_video}
                    id={'yt_integrated_video'}
                  />
                  <NegoitateInput
                    logo={YouTubeLogo}
                    text={'Youtube Dedicated Video'}
                    value={this.state.shortlistedinfluencer.yt_dedic_video}
                    id={'yt_dedic_video'}
                  />

                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ margin: '8px' }}
                  >
                    <Grid item md={6} container alignItems="center">
                      &nbsp;&nbsp;&nbsp;&nbsp;Final Price (in Rupees)
                    </Grid>
                    <Grid item md={6} alignItems="flex-end">
                      <TextField
                        label="Amount"
                        variant="outlined"
                        size="small"
                        type={'number'}
                        id={'brand_quote'}
                        value={this.state.brand_quote}
                        onChange={this.handleNegotitateAmount}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <div
                  style={{
                    paddingBottom: '18px',
                    paddingTop: '20px',
                  }}
                  align={'center'}
                >
                  <Button
                    variant="outlined"
                    style={{
                      color: colorConstants.TEXT_BLUE,
                      borderColor: colorConstants.TEXT_BLUE,
                      marginRight: '10px',
                    }}
                    onClick={() => this.toggleModal()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: colorConstants.TEXT_BLUE,
                      color: 'white',
                    }}
                    onClick={() =>
                      this.negotiatePrice(document.getElementById('brand_quote').value)
                    }
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
          <div>
            <Modal
              open={this.state.refrenceLinkModelOpen}
              onClose={this.toggleRefrenceLinkModel}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div
                style={{
                  backgroundColor: 'white',
                  width: '50vw',
                  transform: 'translate(50%, 20vh)',
                  padding: '10px',
                }}
                align={'left'}
              >
                <h3 style={{ margin: '10px' }}>
                  Refrence Links
                  <span style={{ float: 'right' }}>
                    <CloseIcon onClick={this.toggleRefrenceLinkModel} />
                  </span>
                </h3>
                <hr style={{ width: '99%' }} />
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                  className="prefTabs"
                >
                  <Tabs
                    value={this.state.prefTab}
                    onChange={this.toggleprefTab}
                    indicatorColor={this.state.prefTab ? 'primary' : 'secondary'}
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    style={{
                      width: '100%',
                    }}
                  >
                    <Tab
                      label={'YouTube'}
                      style={{
                        color: !this.state.prefTab ? colorConstants.BRAND_RED : '',
                        fontWeight: !this.state.prefTab ? 'bold' : '',
                      }}
                    />

                    <Tab
                      label={'Instagram'}
                      style={{
                        color: !this.state.prefTab ? '' : colorConstants.INFLUENCER_BLUE,
                        fontWeight: !this.state.prefTab ? '' : 'bold',
                      }}
                    />
                  </Tabs>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    style={{
                      overflow: 'scroll',
                      margin: 0,
                      height: '50vh',
                    }}
                  >{}
                    {this.state.shortlistedinfluencer.influencer.reference_link?.map((each) => (
                      <>
                        {each.platform == 'youtube' && this.state.prefTab == 0 && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            key={this.state.shortlistedinfluencer.influencer.reference_link.indexOf(
                              each,
                            )}
                          >
                            <a
                              href={each.url}
                              target="_blank"
                              style={{
                                color: '#1E9CB2',
                                fontSize: '12px',
                                textDecoration: 'unset',
                                cursor: 'pointer',
                              }} rel="noreferrer"
                            >
                              {' '}
                              <CardMedia
                                component="iframe"
                                src={each.url}
                                style={{
                                  height: '300px',
                                }}
                              />
                            </a>
                          </Grid>
                        )}
                        {each.platform == 'instagram' && this.state.prefTab == 1 && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            key={this.state.shortlistedinfluencer.influencer.reference_link.indexOf(
                              each,
                            )}
                          >
                            <a
                              href={each.url}
                              target="_blank"
                              style={{
                                color: '#1E9CB2',
                                fontSize: '12px',
                                textDecoration: 'unset',
                                cursor: 'pointer',
                              }} rel="noreferrer"
                            >
                              {' '}
                              <CardMedia
                                component="iframe"
                                src={each.url}
                                style={{
                                  height: '300px',
                                }}
                              />
                            </a>
                          </Grid>
                        )}
                      </>
                    ))}
                  </Grid>
                </Grid>
                <br />
                <div
                  style={{
                    paddingBottom: '18px',
                    paddingTop: '20px',
                  }}
                  align={'center'}
                >
                  <Button
                    variant="outlined"
                    style={{
                      color: colorConstants.TEXT_BLUE,
                      borderColor: colorConstants.TEXT_BLUE,
                      marginRight: '10px',
                    }}
                    onClick={() => this.toggleRefrenceLinkModel()}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
        </CardContent>
        {
          this.state.detailViewOpened && this.state.shortlistedinfluencer?.iq_profile_id ?
          <ThemeProvider theme={themeV2}>
            <DetailViewModal
              id={this.state.shortlistedinfluencer?.iq_profile_id}
              detailViewOpened={this.state.detailViewOpened}
              setDetailViewOpened={this.setDetailViewOpened}
          />
          </ThemeProvider>
          :''
        }
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.campaign.loading,
});

const actionCreators = {
  getCampaignDetails: campaignActions.getCampaignDetails,
  getInfluencers: campaignActions.getInfluencers,
  updateInfluencerStatus: campaignActions.updateInfluencerStatus,
};

export default connect(mapStateToProps, actionCreators)(withRouter(Influencer));
