import {
  FormControl,
  // InputLabel,
  // NativeSelect,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const generateId = () => {
  return `select-${Math.floor(Math.random() * 10 + 1)}`;
};

function SelectUpdated({ children, options = [], value, onChange, ...props }) {
  const classes = useStyles();
  const id = generateId();
  return (
    <FormControl className={classes.root}>
      <Select
        labelId={id}
        id="demo-simple-select"
        value={value}
        onChange={onChange}
        className={classes.select}
        {...props}
      >
        <MenuItem aria-label="None" value="" />
        {options.map((option, i) => (
          <MenuItem value={option.value} key={i}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  select: {
    background: '#EFF0F9',
    boxShadow:
      '-5px 5px 10px rgba(219, 220, 221, 0.2), 5px -5px 10px rgba(219, 220, 221, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(219, 220, 221, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(219, 220, 221, 0.5)',
    borderRadius: 20,
    border: 'none',
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    width: '100%',
  },
}));

export default SelectUpdated;
