import { connect } from 'react-redux';
import { Typography, Grid, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import HeaderLabel from './components/HeaderLabel';

import ChannelIcon from 'assets/discovery/channel-icon.png';
import FollowerIcon from 'assets/discovery/followers-icon.png';
import AvgViewIcon from 'assets/discovery/Avg-View-icon.png';
import GenderIcon from 'assets/discovery/Gender-icon.png';
import EngRateIcon from 'assets/discovery/Eng-Rate-icon.png';
import PPPIcon from 'assets/discovery/PPP-icon.png';
import PriceIcon from 'assets/discovery/Price-icon.png';
import HeadingIcon from 'assets/discovery/Heading-Columns-icon.png';

import { updateFilter, getFilteredInfluencer, resetFilter } from 'actions/discovery';

function ListHeader({ count, filters, setFilters, getFilteredInfluencer }) {
  const classes = useStyles();

  async function sortColumn(column) {
    await setFilters({
      sortBy: column,
      sortOrder: filters.sortOrder === 'asc' && filters.sortBy === column ? 'desc' : 'asc',
    });
    getFilteredInfluencer();
  }

  return (
    <Grid container className={classes.root}>
      <Grid item md={2}>
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item md={'auto'}>
            <Typography className={classes.font12}>INFLUENCERS</Typography>
          </Grid>
          <Grid item md={'auto'}>
            <Chip color="secondary" size="small" label={`(${count})`} className={classes.font12} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={8}>
        <Grid container className={classes.matrixBox}>
          <Grid item md={1}>
            <HeaderLabel imageSrc={ChannelIcon} label="Channel" />
          </Grid>
          <Grid item md className={classes.moreWidth}>
            <HeaderLabel
              imageSrc={FollowerIcon}
              label="Followers"
              sortable={true}
              sort={filters.sortBy === 'followers'}
              sortOrder={filters.sortOrder}
              sortByThis={() => sortColumn('followers')}
            />
          </Grid>
          <Grid item md className={classes.moreWidth}>
            <HeaderLabel
              imageSrc={EngRateIcon}
              label="Eng. Rate"
              sortable={true}
              sort={filters.sortBy === 'eng-rate'}
              sortOrder={filters.sortOrder}
              sortByThis={() => sortColumn('eng-rate')}
            />
          </Grid>
          <Grid item md className={classes.moreWidth}>
            <HeaderLabel
              imageSrc={AvgViewIcon}
              label="Avg. View"
              sortable={true}
              sort={filters.sortBy === 'views'}
              sortOrder={filters.sortOrder}
              sortByThis={() => sortColumn('views')}
            />
          </Grid>
          <Grid item md={1}>
            <HeaderLabel
              imageSrc={GenderIcon}
              label="Audience Gender"
              sortable={false}
              // sort={filters.sortBy === 'audience-gender'}
              // sortOrder={filters.sortOrder}
              // sortByThis={() => sortColumn('audience-gender')}
            />
          </Grid>
          <Grid item md className={classes.moreWidth}>
            <HeaderLabel
              imageSrc={PPPIcon}
              label="Paid Post Performance"
              sortable={true}
              sort={filters.sortBy === 'ppp'}
              sortOrder={filters.sortOrder}
              sortByThis={() => sortColumn('ppp')}
            />
          </Grid>
          <Grid item md={1}>
            <HeaderLabel imageSrc={PriceIcon} label="Fair Price" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={1}>
        <img src={HeadingIcon} alt="icon" />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    boxShadow:
      '-1px 1px 2px rgba(215, 216, 221, 0.2), 1px -1px 2px rgba(215, 216, 221, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(215, 216, 221, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 221, 0.5)',
    borderRadius: 10,
    background: 'linear-gradient(135deg, #E3E4ED 0%, #FBFCFF 100%)',
    height: 50,
    position: 'sticky',
    top: 0,
  },
  moreWidth: {
    maxWidth: '10.5%',
  },
  matrixBox: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  font12: {
    fontSize: 12,
  },
}));

const mapStateToProps = (state) => {
  return {
    filters: state.discovery.filters,
  };
};

const actionCreators = {
  setFilters: updateFilter,
  getFilteredInfluencer,
  resetFilter,
};

export default connect(mapStateToProps, actionCreators)(ListHeader);

// export default ListHeader
