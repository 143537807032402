import { Grid, makeStyles, Typography } from '@material-ui/core';
import CommentIcon from 'assets/discovery/comment-icon.png';
import LikeIcon from 'assets/discovery/like-icon.png';
import ImageValidator from 'components/ux/ImageValidator';
import countertostring from 'components/PlannerV2/components/common';
import insta_placeholder from 'assets/discovery/insta_placeholder.png';
export default function RecentPosts({ recent_posts = [] }) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item md={12} className={classes.header}>
        <Typography variant="h3" style={{ color: 'black', fontSize: 16 }}>
          RECENT POSTS
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Grid container justifyContent="flex-start" style={{ height: '100%' }}>
          {recent_posts.length > 0
            ? recent_posts.slice(0, 3).map((post, i) => (
                <Grid item className={classes.card} style={{ width: '30%' }}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      md={11}
                      style={{ height: 250, cursor: 'pointer' }}
                      onClick={() => {
                        window.open(post.link, '_blank');
                      }}
                    >
                      <ImageValidator
                        className={classes.image}
                        url={post.thumbnail ? post.thumbnail: post.image}
                        >
                        <img src={insta_placeholder} 
                        className={classes.image}/>
                      </ImageValidator>
                      {/* <img src={post.thumbnail ? post.thumbnail: post.image} onError={post.user_picture} className={classes.image} alt="icon" /> */}
                    </Grid>
                    <Grid item md={11}>
                      {post.text ? (
                        <Typography variant="body2" style={{ height: 60, overflow: 'auto' }}>
                          {post.text && post.text.slice(0, 100)}...
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item md={12}>
                      <Grid container spacing={3} style={{ marginTop: 10 }}>
                        <Grid item>
                          <Grid container spacing={1}>
                            <Grid item style={{ marginTop: 2 }}>
                              <img src={LikeIcon} alt="likeicon" className={classes.icon} />
                            </Grid>
                            <Grid item>
                              <Typography variant="h3">
                                {countertostring(post.stat.likes)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            <Grid item style={{ marginTop: 2 }}>
                              <img src={CommentIcon} alt="commenticon" className={classes.icon} />
                            </Grid>
                            <Grid item>
                              <Typography variant="h3">
                                {countertostring(post.stat.comments)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            : ''}
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  header: {
    background: '#F3F4FE',
    // boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    // borderRadius: 10,
    padding: 15,
    // marginBottom: 30,
    marginTop: 10,
  },
  card: {
    background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    // boxShadow:'-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    // borderRadius: 20,
    padding: 20,
    marginTop: 5,
    marginLeft: 2,
  },
  image: {
    height: 220,
    // width: 220,
    // borderRadius: 20,
  },
  icon: {
    height: 20,
    width: 20,
  },
}));
