import React from 'react';
import { withRouter } from 'react-router-dom';

import BaseSidePanel from '../../ux/BaseSidePanel';

import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import SecurityIcon from '@material-ui/icons/Security';
import FacebookIcon from '@material-ui/icons/Facebook';
import InfoIcon from '@material-ui/icons/Info';

class SidePanel extends React.Component {
  getNavigationLinks() {
    return [
      {
        to: `${this.props.match.url}/personal-settings`,
        label: 'Personal Settings',
        icon: PermIdentityIcon,
      },
      {
        to: `${this.props.match.url}/security-settings`,
        label: 'Security Settings',
        icon: SecurityIcon,
      },
      {
        to: `${this.props.match.url}/social-settings`,
        label: 'Social Settings',
        icon: FacebookIcon,
      },
      {
        to: `${this.props.match.url}/about-me`,
        label: 'About Me',
        icon: InfoIcon,
      },
    ];
  }

  render() {
    return <BaseSidePanel navigationLinks={this.getNavigationLinks()} />;
  }
}

export default withRouter(SidePanel);
