import { Typography, Grid, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// import AvatarImage from "assets/discovery/inf-avatar.png"
import GenderIcon from 'assets/discovery/Gender-icon.png';

function InfoCard({ name, gender, language, picture, age_group }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item md={4}>
        <Avatar alt="Inf" src={picture} className={classes.avatar} />
      </Grid>
      <Grid item md={7}>
        <Grid container direction="column" className={classes.aboutContainer}>
          <Grid item md={11}>
            <Typography variant="h3" style={{ color: 'white' }}>
              {name}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography style={{ color: 'white' }}>{language}</Typography>
          </Grid>
          <Grid item md={12}>
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <img src={GenderIcon} alt="icon" className={classes.genderIcon} />
              </Grid>
              <Grid item>
                <Typography className={classes.genderInfo}>
                  {gender} | {age_group} yrs
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    background: 'linear-gradient(118.02deg, rgba(239, 26, 116, 0.57) 32.63%, #FEBD1C 94.6%)',
    boxShadow: '2px 5px 10px rgba(143, 143, 143, 0.25)',
    borderRadius: 10,
    height: 130,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  aboutContainer: {
    color: 'white',
    alignItems: 'center',
  },
  genderIcon: {
    height: 13,
    width: 13,
  },
  genderInfo: {
    fontSize: 12,
    color: 'white',
  },
  avatar: {
    height: 95,
    width: 95,
  },
}));

export default InfoCard;
