import React, {useRef, useEffect, useState, useCallback} from 'react';
import { Grid, Typography, Button, List, ListItem, ListItemAvatar, ListItemText, Avatar, IconButton, CircularProgress, Fab, InputAdornment} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from "@material-ui/core";
import { MessageLeft, MessageRight } from "./message";
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { deepOrange } from "@material-ui/core/colors";
import InstagramLogo from '../../assets/instagram-logo.png';
import YouTubeLogo from '../../assets/youtube-logo.png';
import Checkbox from '@material-ui/core/Checkbox';
import isEqual from 'lodash/isEqual';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import moment from 'moment/moment';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NavigationBar from './navbar';
import {campaignHandlers, getWhatsappChat} from '../../api_handlers/campaign'
import { sendWhatsappMessage } from 'api_handlers/discovery';
import {getInfluencerChats} from '../../api_handlers/discovery'
import {useParams} from 'react-router-dom';
import {getInfluencerCount} from '../../api_handlers/discovery';

let timer;

const Communication = ({classes}) => {
    const [count, setCount] = useState(0)
    const messagesBodyRef = useRef(null);
    const [influencers, setInfluencers] = useState([])
    const [params, setParams] = useState({})
    const [status, setStatus] = useState(null)
    const [campaignID, setCampaignID] = useState(0)
    const myValue = useSelector(state => state.campaign.campaignId);
    const [selectedInfluencer, setSelectedInfluencer] = useState({'id': 0, 'name': ''})
    const [value, setValue] = useState(0);
    const [selectedTab, setSelectedTab] = useState('');
    const options = [
        'Shortlisted Influencer', 
        'Approved Influencer', 
        'Content Pending',
        'Content Submitted',
        'Content Approved',
        'Content Rejected'
    ];
    const addToOptions = {
        "pending": 'Shortlisted Influencers',
        "approved": 'Approved Influencers',
        "content_pending": 'Content Pending',
        "content_submitted": 'Content Submitted',
        "content_approved": 'Content Approved',
        "content_rejected": 'Content Rejected',
    }
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [checked, setChecked] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [filteredInfluencers, setFilteredInfluencers] = useState();
    const [influencerChat, setInfluencerChat] = useState([]);
    const [message, setMessage] = useState('');
    const [influencerNumber, setInfluencerNumber] = useState('');
    const [dataFetched, setDataFetched] = useState(false);
    const [particularInfluencerId, setParticularInfluencerId] = useState('');
    const [file, setFile] = useState(null);
    const [clickedInfluencers, setClickedInfluencers] = useState([]);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [timeStampData, setTimeStampData] = useState({});
    const [influencerTimeStamp,setInfluencerTimeStamp]=useState([{
        "timeStamp":0,
        "date":''
    }])          
    const [savingMessage, setSavingMessage] = useState(false);
    const [isVisible, setIsVisible] = useState(false)
    const param = useParams();
    const [isSender,setIsSender]=useState(false)
    const [showFab, setShowFab] = useState(false);
    const [loadingChats, setLoadingChats] = useState(true);
    // const [openMessage,setOpenMessage]=useState(false);
    // const [selectedInfluencerId,setSelectedInfluencerId]=useState()

    const handleCheckedInfluencer = (influencerId) => () => {
        const isChecked = checked.includes(influencerId);
        const newChecked = isChecked ? checked.filter(id => id !== influencerId) : [...checked, influencerId];
        setChecked(newChecked);
    };

    const handleSelectAllChange = () => {
        const allInfluencersIds = influencers.map((inf) => inf.influencer_id);
        if (selectAll) {
          setChecked([]);
        } else {
          setChecked(allInfluencersIds);
        }
        setSelectAll(!selectAll);
    };

    // };
  
    const handleClick = (index) => {
      console.info(`You clicked ${options[index]}`);
      var influencerStatus=null

        if(options[index] == 'All Influencers'){
            influencerStatus=null
        }
        if(options[index] == 'Shortlisted Influencer'){
            influencerStatus='pending'
        }
        if(options[index] == 'Approved Influencer'){
            influencerStatus='approved'
        }
        if(options[index] == 'Content Pending'){
            influencerStatus='content_pending'
        }
        if(options[index] == 'Content Submitted'){
            influencerStatus='content_submitted'
        }
        if(options[index] == 'Content Approved'){
            influencerStatus='content_approved'
        }
        if(options[index] == 'Content Rejected'){
            influencerStatus='content_rejected'
        }
        campaignHandlers.updateInfluencerStatus(campaignID,selectedInfluencer['id'],influencerStatus)
        setDataFetched(false)        
    };

    async function fetchInfluencersCount() {
        try {
            const resp = await getInfluencerCount(campaignID);
            console.log("Influencer Status Count: ", resp)
            setCount(resp.data)
        } catch(error) {
            console.error("Error fetching influencers status Count:", error);
        }
    }

    async function fetchInfluencerList() {
        try {
            setLoadingChats(true);
            const data = await getInfluencerChats(param.campaignId, status);
            setLoadingChats(false);
            modifyTimeStamp(data)

            console.log("Influencer Status:-", status);
            console.log("DATA:-", data);
            setInfluencers(data);
            setFilteredInfluencers(data);
        } catch (error) {
            console.error("Error fetching influencers data:", error);
        }
        }


    // useEffect(()=>{

    //         influencerChatTime()
    //         async function influencerChatTime() {
    //             try {
    //                 const data = await getInfluencerChats(param.campaignId,null);
    //                 // console.log("Influencer Status:-", status);
    //                 modifyTimeStamp(data)
    //             } catch (error) {
    //                 console.error("Error fetching influencers data:", error);
    //             }
    //             }
    //     },[])

    const handleMenuItemClick = (event, index) => {
        handleClick(index)
        setDataFetched(false);
        //   setSelectedIndex(index);
        setOpen(false);
    };
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue)
        // const tabLabels = ['All', 'Read', 'Unread', 'Undelivered'];
        // setSelectedTab(tabLabels[newValue]);
    };

    const handleInfluencerClick = (influencerId, influencerName, avatar, email, contact, address, insta_link, youtube_link, status) => {
        console.log(`Clicked on influencer with ID ${influencerId}`);

        // const isClicked = clickedInfluencers.includes(influencerId);
        // const newClickedInfluencers = isClicked
        // ? clickedInfluencers.filter(id => id !== influencerId)
        // : [...clickedInfluencers, influencerId];
  
        // setClickedInfluencers(newClickedInfluencers);
        // setInfClicked(true);
        if (particularInfluencerId != influencerId) {
            if (timer) {
                clearInterval(timer);
            }
            setInfluencerChat([]);
            setMessage('');
            setSelectedInfluencer({
                'id': influencerId,
                'name': influencerName,
                'avatar': avatar, 
                'email': email, 
                'contact': contact, 
                'address': address,
                'instagram_link' : insta_link,
                'youtube_link': youtube_link,
                'status': status,
            })
            setParticularInfluencerId('');
            setChatData(influencerId);
            setParams({'influencers': influencerId})
        }
    };

    const setChatData = async (influencerId) => {
        if (timer) {
            clearInterval(timer);
        }
        let resp = await getWhatsappChat(influencerId, campaignID);
        let json = await resp.json();
        setSavingMessage(false);
        if (!json['detail']) {
            json = json.sort(
                (a, b) => a.id > b.id ? 1 : -1
            )
            if(json.length==0){
                setIsSender(false)
            }
            setInfluencerChat(json);
            try {
                setInfluencerNumber(json[json.length-1].influencer_number);
            } catch {
                setInfluencerNumber('');
            }
            try {
                var canSendChat = false;
                for (var i = 0; i < json.length; i++) {
                    var each = json[i];
                    var startDate=new Date(each.timestamp)
                    var currentDate=new Date()
                    const duration = moment(currentDate).diff(moment(startDate));
                    const totalHours=duration/(3600*1000);
                    if (each.is_sender && totalHours <= 24) {
                        canSendChat = true;
                        break
                    }
                }
                setIsSender(canSendChat);
            } catch {

            }
            try {
                filteredInfluencers.map((each, index)=>{
                    if (each.influencer_id == influencerId) {
                        filteredInfluencers[index]['last_msg']={
                            'body': json[json.length-1].text_content,
                            'timestamp': json[json.length-1].timestamp
                        }
                        setFilteredInfluencers(filteredInfluencers);
                        modifyTimeStamp(filteredInfluencers);
                    }
                })
            } catch(e){
                console.log(e);
            }
            setParticularInfluencerId(influencerId);   
            if (messagesBodyRef.current) {
                messagesBodyRef.current.addEventListener("scroll", handleScroll);
            }     
            timer = setInterval(() => {
                setChatData(influencerId);
            }, 5000);
        }
    }

    const getStatus = (influencerState) => {
        setDataFetched(false);
        setStatus(influencerState)
        setParticularInfluencerId('');
        if (timer) {
            clearInterval(timer);
        }
    }

    function removeHtmlTags(input) {
        var doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.body.textContent || "";
    }
    
    useEffect(() => {
        setCampaignID(myValue);
        setDataFetched(false);

    }, [myValue]);

    useEffect(() => {
        console.log(campaignID, status, dataFetched);
        if (!dataFetched && campaignID) {
            fetchInfluencersCount();
            fetchInfluencerList();
            setDataFetched(true);

        }
      }, [campaignID, status, dataFetched]);

      const modifyTimeStamp=(influencer)=>{
        setTimeStampData({});
        influencer.map(data=>{
            if(data.last_msg.timestamp){
                const timestamp=data.last_msg.timestamp
                var startDate=new Date(timestamp)
                if (data.last_msg.timestamp.indexOf("+05:30") == -1) {
                    startDate.setHours(startDate.getHours() + 5);
                    startDate.setMinutes(startDate.getMinutes() + 30);
                }
                var currentDate=new Date()
                const duration = moment(currentDate).diff(moment(startDate));
                const totalHours=duration/(3600*1000);
                // console.log('total hours',duration/(3600*1000))
                if(totalHours<=24){
                    var time = moment(startDate).format('hh:mm a');
                    timeStampData[data.influencer_id]={ "timeStamp":data.last_msg.timestamp,"date":time};
                    setTimeStampData(timeStampData);
                }
                else if(totalHours>24 && totalHours<48){
                    timeStampData[data.influencer_id]={ "timeStamp":data.last_msg.timestamp,"date":"Yesterday"}
                    setTimeStampData(timeStampData);
                }else if(totalHours>48){
                    const date=`${startDate.getDate()} ${monthNames[startDate.getMonth()]}`
                    timeStampData[data.influencer_id]={ "timeStamp":data.last_msg.timestamp,"date":date}
                    setTimeStampData(timeStampData);
                }
                
            }

        })
      }

   
    useEffect(() => {
        if (value === 0){
            const sortedInfluencers = [...influencers].sort((a, b) => {
                if (!a.last_msg.timestamp && !b.last_msg.timestamp) return 0;
                if (!a.last_msg.timestamp) return 1;
                if (!b.last_msg.timestamp) return -1;
                return new Date(b.last_msg.timestamp) - new Date(a.last_msg.timestamp);
            });
            setFilteredInfluencers(sortedInfluencers);
            
        }

        if (value === 1 || value === 2 || value === 3) {
          const statusFilters = {
            1: ["read"],
            2: ["delivered", "received"],
            3: ["queued", "failed", "sent"],
          };
    
          const filteredInf = influencers.filter((influencer) =>
            statusFilters[value].some((tab) =>
              influencer.msg_status.includes(tab)
            )
          );


          const sortedInfluencers = [...filteredInf].sort((a, b) => {
            if (!a.last_msg.timestamp && !b.last_msg.timestamp) return 0;
            if (!a.last_msg.timestamp) return 1;
            if (!b.last_msg.timestamp) return -1;
            return new Date(b.last_msg.timestamp) - new Date(a.last_msg.timestamp);
          });
    
          setFilteredInfluencers(sortedInfluencers);
        }
      }, [value, influencers]);
    

    useEffect(() => {
        setSelectedInfluencer({});

    }, [status, value]);

    const handleScroll = useCallback(() => {
        const isScrolledUp = messagesBodyRef.current.scrollTop>0;
        const isScrolledToBottom = messagesBodyRef.current.scrollHeight - messagesBodyRef.current.scrollTop <= (messagesBodyRef.current.clientHeight + 100);
        setShowFab(isScrolledUp);
        if (!isScrolledToBottom) {
          setIsVisible(true);
        }else{
          setIsVisible(false);

        }
    }, [messagesBodyRef, showFab]);

    useEffect(() => {
        if (messagesBodyRef.current) {
          messagesBodyRef.current.scrollTop = messagesBodyRef.current.scrollHeight;
        }        
    }, [particularInfluencerId]);

    function handleBackToBottomClick() {
        messagesBodyRef.current.scrollTop = messagesBodyRef.current.scrollHeight;
        setShowFab(false)
        setIsVisible(false)
      }

    return (
        <Grid container spacing={3} style={{ border: '1px solid #ccc', padding: '0px',}}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <NavigationBar
                    getStatus={getStatus}
                    checked={checked} 
                    campaignID={campaignID}
                    count={count}
                />
            </Grid>

        <Grid container item xs={12} spacing={3}>
            <Grid item xs={3} 
            style={{
                padding: '0px',
                height:"75vh",
                minHeight:'75vh'
                }}>

                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        style={{minWidth: '10px'}}
                    >
                        <Tab label="All"/>
                        <Tab label="Read"/>
                        <Tab label="Unread"/>
                        <Tab label="Undelivered"/>
                        </Tabs>
                    </AppBar>
                <ListItem style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Typography style={{paddingRight:2,  fontSize: '0.85rem'}}>Select all </Typography>
                    <Checkbox
                        edge="start"
                        onChange={handleSelectAllChange}
                        checked={selectAll}
                        style={{}}
                    />
                </ListItem>
                <List style={{overflowY: 'auto', maxHeight: '54vh'}}>
                {
                    loadingChats ? <CircularProgress /> : null
                }
                {filteredInfluencers &&
                filteredInfluencers.map((inf, i) => (
                    inf.status==status || status==null?
                    <ListItem key={inf.influencer_id} button onClick={
                        () => handleInfluencerClick(
                            inf.influencer_id,
                            inf.influencer_name, 
                            inf.avatar, 
                            inf.email, 
                            inf.phone_no, 
                            inf.address,
                            inf.instagram_link || null,
                            inf.youtube_link || null,
                            inf.status,
                            )
                        
                        }
                        className={clickedInfluencers.includes(inf.influencer_id) ? classes.highlightedInfluencer : ''}
                        >

                        <ListItemAvatar>
                            <Avatar alt={inf.influencer_name} src={inf.avatar} />

                        </ListItemAvatar>
                       <Grid container>
                        <Grid item md={9}>
                        <ListItemText
                        primary={
                            <span  style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>{inf.influencer_name}</span>

                        }
                        >
                        </ListItemText>
                       
                    
                        </Grid>
                        <Grid item md={3} style={{marginTop:'1vh'}}>
                            <ListItemText
                            primary={
                                timeStampData[inf.influencer_id] ?
                                 <React.Fragment key={i}>
    
                                        {inf.msg_status !== '' && (
                                            <Typography
                                                // component="span"
                                                variant="body2"
                                                display='inline'
                                                style={{ float: 'right', fontSize: '11px',}}
                                            >
                                                {timeStampData[inf.influencer_id].date}
                                            </Typography>
                                        )}
                                    </React.Fragment> : ''}
                            
                            >
                                
                            </ListItemText>

                            </Grid>
                        <Grid item md={12}>
                        <ListItemText 
                        
                        secondary={
                            <React.Fragment>
                              <Typography
                                // component="span"
                                variant="body2"
                                display='inline'
                                color="textPrimary"
                                style={{ fontFamily: 'Georgia, serif' }}
                              >
                               {inf.msg_status !== '' ? 
                                inf.last_msg.body.length > 20 ? 
                                    `${removeHtmlTags(inf.last_msg.body).slice(0, 20)}...` : 
                                    inf.last_msg.body == '-' ? 'Media' : removeHtmlTags(inf.last_msg.body)
                                : "Influencer not Reached out"}
                                </Typography>
                            </React.Fragment>
                        }
                    />
                        </Grid>
                       </Grid>
                    <ListItemSecondaryAction>
                        <Checkbox
                            edge="end"
                            onChange={handleCheckedInfluencer(inf.influencer_id)}
                            checked={checked.includes(inf.influencer_id)}
                        />
                    </ListItemSecondaryAction>
                    </ListItem>:''
              ))}
                </List>
            </Grid>

            <Grid item xs={6} style={{ borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc', borderTop: '1px solid #ccc'}}>
            <Grid container className={classes.container}>
            {Object.keys(selectedInfluencer).length !== 0 ? (
            <Paper className={classes.paper} zDepth={2}>
                <Grid item className={classes.influencerPannel}>
                <Avatar
                    alt={selectedInfluencer.name}
                    className={classes.orange}
                    src={selectedInfluencer.avatar}
                ></Avatar>
                <div className={classes.displayName}>{selectedInfluencer.name}</div>
                </Grid>
                <Paper
                id="style-1" 
                className={classes.messagesBody}
                style={{position: 'relative'}}
                // style={{position:'static'}}
                ref={messagesBodyRef}
                >
                <Grid container justifyContent='flex-end' >
                   
                    <div style={{marginBottom: '20px',}}></div>
                    <Grid item>
                    {
                    influencerChat.map(each=>{
                        if (each.is_sender) {
                            return <MessageLeft
                                message={each.text_content}
                                timestamp={moment(each.timestamp).format('DD/MM/YYYY hh:mm a')}
                                photoURL={selectedInfluencer.avatar}
                                displayName={selectedInfluencer.name}
                                avatarDisp={false}
                                media={each.media}
                                url={`/brand/campaigns/${campaignID}/content-approval`}
                            />
                        }
                        return <MessageRight
                            message={each.text_content}
                            timestamp={moment(each.timestamp).format('DD/MM/YYYY hh:mm a')}
                            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
                            displayName="Influencer Team"
                            avatarDisp={false}
                            media={each.media}
                            url={`/brand/campaigns/${campaignID}/content-approval`}
                        />
                    })
                }
                        
                        <div style={{marginTop: '20px'}}></div> 
                        </Grid>
                        <Grid item style={{position:'sticky',textAlign:'end', bottom: '8%'}} >
                    {/* {showFab ? ( */}
                    <Fab
                        variant="contained"
                        color="primary"
                        style={{display: isVisible ? 'grid' : 'none',}}
                        // style={{minHeight:'20px'}}
                        className={classes.backToBottomButton}
                        onClick={()=>handleBackToBottomClick()}
                        >
                        <KeyboardArrowDownIcon style={{color:'#ffffff'}}></KeyboardArrowDownIcon>   
                        </Fab>
                    {/* ): null} */}
                    </Grid>
                </Grid>
                </Paper>
                <form className={classes.wrapForm}  noValidate autoComplete="off" onSubmit={(e)=>{
                    e.preventDefault();
                }}>
               <Grid item md={12}>

               <Grid container  justifyContent='flex-start' alignItems='center' spacing={1}>
                    <Grid item md={9} lg={10} sm={8} xs={8}>
                    <TextField
                    // placeholder='Type Your Message'
                    id="standard-text"
                    

                    
                    // label="Type Your Message"
                    disabled={isSender?false:true}
                    placeholder={isSender?'Type Your Message':
                    `Please wait for influencer's reply`
                    }           
                    variant='outlined'
                    InputProps={{  startAdornment: (
                        isSender?'':
                        <InputAdornment position="start">
                          <ErrorOutlineIcon />
                        </InputAdornment>
                      ),disableUnderline: true,style:{maxHeight:"55px",maxWidth:'1000vw',color:'#000000'}}}
                    className={classes.wrapText}
                    onChange={(e)=>{
                        setMessage(e.target.value);
                    }}
                    value={message}
                    //margin="normal"
                    />
                    </Grid>
               <Grid item md={2} lg={1} sm={2} xs={2}>
                {
                   
                }
               <Button 
                    disabled={savingMessage || isSender?false:true}
                    sx={{marginLeft:100}}
                        alignItems='center'
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={(e)=>{
                            
                    }}>
                    <FileUploadIcon />
                    <input type="file" style={{ opacity: 0, width: 40, position: 'absolute', left: 0 }} onChange={(e)=>{
                        sendWhatsappMessage(influencerNumber, "", selectedInfluencer['id'], campaignID, message, e.target.files[0]);
                        setSavingMessage(true);
                    }}/>
                </Button>
               </Grid>
                <Grid item md={1} lg={1} sm={2} xs={2}>
                <Button 
                    variant="contained"
                    color="primary"
                    disabled={isSender?false:true}
                    className={classes.button}
                    onClick={(e)=>{
                        sendWhatsappMessage(influencerNumber, "", selectedInfluencer['id'], campaignID, message);
                        setMessage('');
                        setSavingMessage(true);
                    }}>
                    {savingMessage ? <CircularProgress style={{ height: 10 }}/> : <SendIcon />}
                </Button>
                </Grid>
              
                </Grid>
              

               </Grid>
            
                </form>

            </Paper>
            ) :
            <div style={{display: 'flex',flexWrap: 'nowrap',alignItems: 'center', marginTop: '10%', marginLeft: '18%'}}>
            <div><ErrorOutlineIcon></ErrorOutlineIcon></div>
            <Typography style={{fontSize:20,fontWeight:700, paddingLeft:5, paddingBottom:2}}>Please select an influencer to view their message</Typography>
            </div>
            }
            </Grid>
            </Grid>
            {/* </Grid> */}

            <Grid item xs={3} style={{borderTop: '1px solid #ccc'}}>
            {selectedInfluencer && (
                <Grid container justifyContent='center' alignItems='center' style={{ display: 'flex', flexDirection: 'column',alignItems: 'center', marginTop: '10px', paddingTop: '40px' }}>
                    <Grid item md={12} style={{maxHeight:'30%',maxWidth:'32%'}}>
                    <Avatar alt={selectedInfluencer.name} src={selectedInfluencer.avatar} style={{ width:'auto',height:"auto",minHeight:'7vw',minWidth:'7vw',}}/>
                    </Grid>
                    <Grid item md={12}>
                    {/* <div style={{display: 'inline-block'}}> */}
                 {selectedInfluencer.instagram_link && (
                    <a target="_blank" href={selectedInfluencer.instagram_link} rel="noreferrer">
                        <img src={InstagramLogo} style={{padding: '2px'}} alt={'instagram-logo'} width={'20'} />
                    </a>
                )}
                {selectedInfluencer.youtube_link && (
                    <a target="_blank" href={selectedInfluencer.youtube_link} rel="noreferrer">
                        <img src={YouTubeLogo} alt={'youtube-logo'} style={{padding: '4px'}} width={'20'} />
                    </a>
                )}
                
                {/* </div> */}
                        
                    </Grid>
                    <Grid item md={12}>
                <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>Name</Typography>
                <Typography variant="subtitle1">{selectedInfluencer.name}</Typography>
                        
                        </Grid>
                        <Grid item md={12}>
                       <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>Email</Typography>
                       <Typography variant="subtitle1">{selectedInfluencer.email}</Typography>
                        
                        </Grid>
                        <Grid item md={12}>
                        <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>Contact Details</Typography>
                        <Typography variant="subtitle1">{selectedInfluencer.contact}</Typography>
                        </Grid>
                </Grid>
          )}
            
        <Grid item style={{marginTop:20}}>
        <Button 
        startIcon={status ? null : <AddIcon style={{height:20,width:20}}></AddIcon>}
        className={classes.influencerStatusButton}
        ref={anchorRef} 
        variant="contained" color="primary" 
         size="small"
         aria-controls={open ? 'split-button-menu' : undefined}
         aria-expanded={open ? 'true' : undefined}
         aria-label="select merge strategy"
         aria-haspopup="menu"
        onClick={handleToggle}>{status ? addToOptions[status] : `${selectedInfluencer.status ? addToOptions[selectedInfluencer.status]: 'Add to:-'}`}</Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                  id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem className={classes.menuItems}
                      style={{
                        
                      }}
                        key={option}
                        // disabled={index === 2}
                        // selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        </Grid>
        </Grid>
        </Grid>
        </Grid>
  );
};

const styles = (theme) => ({
    paper: {
        width: "37vw",
        height: "72vh",
        maxWidth: "40vw",
        maxHeight: "100%",
        display: "flex",
        // alignItems: "center",
        flexDirection: "column",
        position: "relative"
    },
    container: {
        // width: "48vw",
        // height: "75vh",
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        position: 'relative',
    },
    messagesBody: {
        width: "calc( 100% - 20px )",
        // margin: 10,
        padding: 10,
        overflowY: "scroll",
        height: "calc( 100% - 80px )",
        // display:'flex'
    },
    wrapForm : {
        display: "flex",
        justifyContent: "center",
        padding:10,
        width: "95%",
        margin: `${theme.spacing(0)} auto`
    },
    wrapText  : {
        width: "100%",
        // border:'1px solid #aaaaaa',
        borderRadius:4,
        // paddingTop:10,
        disableUnderline: true ,
        
    },
    button: {

        maxHeight:"40%",
        minWidth:"35px",
        maxWidth:'40px',
        height:"35px",
        // width:'2,'
        marginTop:5,
        // marginLeft:10
        // width:10,
        // marginLeft:5
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
        width: theme.spacing(4),
        height: theme.spacing(4),
        position: 'relative',
    },
    displayName: {
        marginLeft: "20px",
        position: "absolute",
        left: '30px',
        top: '12px',
    },
    influencerStatusButton:{
        textTransform:'none',
        width:'70%',
        fontSize:14
    },
    menuItems:{
        "&:hover": {
            backgroundColor: 'rgb(63, 81, 181, 0.30)',
            borderRadius:3
            
          }

    },
    influencerPannel: {
        width: '100%',
        backgroundColor: '#e0e0e0',
        padding: '10px',
        zIndex: 2,
    },
    highlightedInfluencer: {
        backgroundColor: '#f0f0f0',
      },
      backToBottomButton:{
        position:'absolute',
        zIndex:111,
        background:'#3f51b5',
        height:'40px',
        width:'40px',
        marginLeft:'-45px'
      }
})

export default withStyles(styles)(Communication);
