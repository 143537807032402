import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Checkbox, TextField, Button, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import {
  getLanguages,
  getFieldOfInterest,
  updateInfluencerProfile,
} from '../../../api_handlers/influencer.js';
import { formatDistanceToNow } from 'date-fns';

import AboutYourselfForm from '../forms/AboutYourself';
import MobileAboutYourselfForm from '../forms/MobileAboutYourself';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class AboutYourself extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languages: [],
      fieldOfInterest: [],
      selectedLanguages: [],
      selectedFieldOfInterest: [],
      aboutYourself: '',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    this.setState({
      languages: await getLanguages(),
      fieldOfInterest: await getFieldOfInterest(),
    });
  }

  collectFormData() {
    let formData = {};

    if (Object.keys(this.state.selectedFieldOfInterest).length > 0) {
      formData['field_of_intrest'] = this.state.selectedFieldOfInterest.map((foi) => foi.id);
    }

    if (Object.keys(this.state.selectedLanguages).length > 0) {
      formData['language_known'] = this.state.selectedLanguages.map((language) => language.id);
    }

    if (this.state.aboutYourself) formData['about'] = this.state.aboutYourself;

    return formData;
  }

  async submitForm() {
    this.form.submitForm();
  }

  get_form() {
    if (window.matchMedia('(min-width: 768px)').matches) {
      return (
        <AboutYourselfForm onRef={(ref) => (this.form = ref)} callback={this.props.handleNext} />
      );
    } else {
      return (
        <MobileAboutYourselfForm
          onRef={(ref) => (this.form = ref)}
          callback={this.props.handleNext}
        />
      );
    }
  }

  render() {
    return (
      <>
        {this.get_form()}
        <Grid container>
          <Paper style={{ margin: 'auto', marginBottom: '50px' }}>
            <Button
              onClick={() => {
                this.submitForm();
              }}
              style={{
                background: '#1E9CB2',
                color: 'white',
                padding: '5px 20px',
                textTransform: 'none',
              }}
            >
              Save and Continue
            </Button>
          </Paper>
        </Grid>
      </>
    );
  }
}

export default withRouter(AboutYourself);
