import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, CardContent, Grid, CircularProgress } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import { RadialChart, DiscreteColorLegend } from 'react-vis';
import { withStyles } from '@material-ui/core/styles';
import { getInstagramContentGraphData } from '../../../../api_handlers/reporting';
import NoDataLabel from '../common/NoDataLabel';

const styles = (theme) => ({
  doughnut: {
    height: '50 !important',
  },
});

class ContentSplitupGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
    };
  }

  async getData() {
    const resp = await getInstagramContentGraphData(this.props.campaignId);
    this.setState({ data: resp, loading: false });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  render() {
    const { classes } = this.props;
    return (
      <Card>
        <CardContent style={{ height: '235px' }}>
          <Grid container>
            <Grid item md={12} class="dashboard-card-title" style={{}}>
              Content Splitup
            </Grid>
            <Grid item md={12}>
              <Grid container style={{ margin: '10px' }}>
                <Grid item md={12}>
                  {this.state.loading ? (
                    <CircularProgress style={{ marginTop: 50 }} />
                  ) : Object.keys(this.state.data).length === 0 ? (
                    <NoDataLabel offsetTop={50} />
                  ) : (
                    <div
                      style={{
                        position: 'relative',
                        margin: 'auto',
                        width: '15vw',
                        height: '15vh',
                      }}
                    >
                      <Doughnut
                        data={{
                          labels: this.state.data.labels,
                          datasets: [
                            {
                              label: '# of Votes',
                              data: this.state.data.data,
                              backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                              ],
                              borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                              ],
                              borderWidth: 1,
                            },
                          ],
                          options: {
                            legend: {
                              position: 'left',
                              labels: {
                                boxWidth: 10,
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
  };
};
export default connect(mapStateToProps)(withRouter(withStyles(styles, {})(ContentSplitupGraph)));
