import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import config, { ThemeMode } from 'config';

// assets
import BudgetFilters from './components/BudgetFilters';
import InfluencerFilters from './components/InfluencerFilters';
import AudienceFilters from './components/AudienceFilters';
import InternalTeamFilters from './components/InternalTeamFilters';

import { fetchInfluencersAction } from 'actions/planner2';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// tabs option
const tabsOption = [
  {
    label: 'Budget',
  },
  {
    label: 'Engagement',
  },
  {
    label: 'Audience',
  },
  {
    label: 'Internal Team',
  },
];

export default function AdvancedFilters({ open, setOpen }) {
  const dispatch = useDispatch();
  const { mode } = config;
  const handleClose = () => {
    setOpen(false);
  };

  const user = useSelector((state) => state.planner2.user);
  const [value, setValue] = React.useState(0);

  const filteredTabsOption = user.is_staff
  ? tabsOption.filter((tab) => tab) // Show only Internal Team tab
  : tabsOption.filter((tab) => tab.label !== 'Internal Team'); // Show all tabs except Internal Team


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Advanced Filters
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <MainCard content={false}>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12} lg={12}>
                    <CardContent sx={{padding: 0}}>
                      <Tabs
                        value={value}
                        variant="scrollable"
                        onChange={handleChange}
                        sx={{
                            mb: 3,
                            '& a': {
                                minHeight: 'auto',
                                minWidth: 10,
                                py: 1.5,
                                px: 1,
                                mr: 2.2,
                                color: mode === ThemeMode.DARK ? 'grey.600' : 'grey.900',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            },
                            '& a.Mui-selected': {
                                color: 'primary.main'
                            },
                            '& a > svg': {
                                mb: '0px !important',
                                mr: 1.1
                            }
                        }}
                    >
                        {filteredTabsOption.map((tab, index) => (
                          <Tab
                            key={index}
                            label={
                              <Grid container direction="column">
                                <Typography variant="subtitle1" color="inherit">
                                  {tab.label}
                                </Typography>
                              </Grid>
                            }
                            {...a11yProps(index)}
                          />
                        ))}
                        </Tabs>
                        <TabPanel value={value} index={0}>
                          <BudgetFilters />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <InfluencerFilters />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          <AudienceFilters />
                        </TabPanel>
                        {user.is_staff ? (
                          <TabPanel value={value} index={3}>
                            <InternalTeamFilters /> 
                          </TabPanel>
                        ): ""}
                    </CardContent>
                  </Grid>
                  {/* <Grid item xs={12} lg={8}>
                    <CardContent
                      sx={{
                        borderLeft: '1px solid',
                        borderColor: mode === ThemeMode.DARK ? 'background.default' : 'grey.200',
                        height: '100%',
                      }}
                    >
                      
                    </CardContent>
                  </Grid> */}
                </Grid>
              </MainCard>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => {
            dispatch(fetchInfluencersAction(1));
            handleClose();
          }}>
            Apply
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
