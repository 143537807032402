import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DropDownSlider from 'components/Theme/Elements/DropDownSlider';

import Filter from '../Filter';

import FollowersIcon from 'assets/discovery/followers-icon.png';

const options = [
  { label: '13-17', value: '13-17' },
  { label: '18-24', value: '18-24' },
  { label: '25-34', value: '25-34' },
  { label: '35-44', value: '35-44' },
  { label: '45-64', value: '45-64' },
  { label: '65-', value: '65-' },
];

function AudienceAgeGroup({ value = [null, null], setFilters }) {
  const classes = useStyles();

  function handleFilter(values) {
    setFilters({
      audienceAgeGroup: values,
    });
  }

  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Filter label={'Audience Age Group'} labelIconSrc={FollowersIcon}>
          <Grid container className={classes.filterContainer}>
            <DropDownSlider value={value} onChange={handleFilter} options={options} />
          </Grid>
        </Filter>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {},
}));

export default AudienceAgeGroup;
