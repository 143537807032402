import React from 'react';
import {
  Grid,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Snackbar,
  Divider,
} from '@material-ui/core';

import { saveContactUsDetails } from '../../api_handlers/extra';

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      email: null,
      phone: null,
      launchTime: null,
      budget: null,
      snackbarOpen: false,
      errors: null,
    };
  }

  saveForm() {
    this.setState({ errors: null });
    const { name, email, phone, launchTime, budget } = this.state;
    const data = {
      name,
      email,
      phone,
      budget,
      launch_time: launchTime,
    };
    saveContactUsDetails(data)
      .catch(async (errors) => {
        return errors.response;
      })
      .then(async (response) => {
        if ([200, 201].includes(response.status)) {
          this.setState({
            snackbarOpen: true,
          });
          setTimeout(() => {
            this.setState({
              snackbarOpen: false,
            });
          }, 5000);
        } else if ([400, 401, 402, 403].includes(response.status)) {
          const errors = {};
          Object.keys(response.data).forEach((key) => (errors[key] = response.data[key][0]));
          console.log('errors  data', errors);
          this.setState({
            errors: errors,
          });
        }
      });
  }

  render() {
    return (
      <Grid item md={12} style={{ background: 'white', borderRadius: 5, padding: 10 }}>
        <Grid container style={{ marginTop: 20, marginBottom: 20, marginLeft: 10 }}>
          <Grid item md={12} style={{ fontSize: 20, textAlign: 'left' }}>
            Contact Us
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 30 }}>
          <Grid item md={12}>
            <Divider
              style={{
                background:
                  'linear-gradient(90deg, rgba(171,171,171,1) 0%, rgba(176,176,176,0) 100%)',
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            color: 'red',
            marginLeft: 50,
            marginBottom: 20,
            fontSize: 12,
          }}
        >
          {this.state.errors ? (
            <Grid item>
              {Object.keys(this.state.errors).map((key) => {
                return (
                  <Grid container>
                    {key}: {this.state.errors[key]}
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginBottom: 30 }}
        >
          <Grid item md={5}>
            Name
          </Grid>
          <Grid item md={1}>
            :
          </Grid>
          <Grid item md={6}>
            <TextField
              required
              id="standard-basic"
              value={this.state.name}
              onChange={(event) => {
                this.setState({ name: event.target.value });
              }}
              InputLabelProps={{ shrink: false }}
              style={{
                width: '100%',
                background: '#F9F9F9',
              }}
              InputProps={{
                style: {
                  fontSize: 13,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginBottom: 30 }}
        >
          <Grid item md={5}>
            Email
          </Grid>
          <Grid item md={1}>
            :
          </Grid>
          <Grid item md={6}>
            <TextField
              required
              id="standard-basic"
              value={this.state.email}
              onChange={(event) => {
                this.setState({ email: event.target.value });
              }}
              InputLabelProps={{ shrink: false }}
              style={{
                width: '100%',
                background: '#F9F9F9',
              }}
              InputProps={{
                style: {
                  fontSize: 13,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginBottom: 30 }}
        >
          <Grid item md={5}>
            Phone
          </Grid>
          <Grid item md={1}>
            :
          </Grid>
          <Grid item md={6}>
            <TextField
              required
              id="standard-basic"
              value={this.state.phone}
              onChange={(event) => {
                this.setState({ phone: event.target.value });
              }}
              InputLabelProps={{ shrink: false }}
              style={{
                width: '100%',
                background: '#F9F9F9',
              }}
              InputProps={{
                style: {
                  fontSize: 13,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginBottom: 30 }}
        >
          <Grid item md={5}>
            When do you want to lauch campaign
          </Grid>
          <Grid item md={1}>
            :
          </Grid>
          <Grid item md={6}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={this.state.launchTime}
                onChange={(event) => {
                  this.setState({ launchTime: event.target.value });
                }}
              >
                <FormControlLabel value="today" control={<Radio />} label="Today" />
                <FormControlLabel value="this_week" control={<Radio />} label="This Week" />
                <FormControlLabel value="this_month" control={<Radio />} label="This Month" />
                <FormControlLabel
                  value="not_yet_decided"
                  control={<Radio />}
                  label="Not yet decided"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginBottom: 30 }}
        >
          <Grid item md={5}>
            Tentative Budget
          </Grid>
          <Grid item md={1}>
            :
          </Grid>
          <Grid item md={6}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={this.state.budget}
                onChange={(event) => {
                  this.setState({ budget: event.target.value });
                }}
              >
                <FormControlLabel
                  value="under_10L"
                  control={<Radio />}
                  label="Under Rs. 10 Lakhs"
                />
                <FormControlLabel
                  value="10L-50L"
                  control={<Radio />}
                  label="Rs. 10 Lakhs - 50 Lakhs"
                />
                <FormControlLabel
                  value="50L-1C"
                  control={<Radio />}
                  label="Rs. 50 Lakhs - 1 Crore"
                />
                <FormControlLabel value="over_1C" control={<Radio />} label="Over Rs. 1 Crore" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid>
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: '#F2643E',
              marginBottom: '2vw',
              marginTop: '2vw',
              color: 'white',
            }}
            onClick={() => {
              this.saveForm();
            }}
          >
            Submit
          </Button>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.snackbarOpen}
          // onClose={handleClose}
          message="Data successfully saved"
          key={'bottom' + 'center'}
        />
      </Grid>
    );
  }
}

export default ContactUs;
