
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { updateFilters } from 'actions/planner-filters';


const AutocompleteFilter = ({ title, payloadKey, options, value }) => {
  const dispatch = useDispatch();
  // const selectedValues = useMemo(() => value.length > 0 ? value.split(',') : [], [value])
  // console.log("in autocomplete", title, selectedValues, value)

  return (
    <Grid container spacing={2} alignItems='center' justifyContent='space-evenly' sx={{ mb: 2 }}>
          <Grid item xs={3}>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              multiple
              size="small"
              options={options}
              value={value || []}
              // getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} /> }
              onChange={(_, newValue) => {
                dispatch(updateFilters(payloadKey, newValue))
              }}
              limitTags={1}
              fullWidth
            />
          </Grid>
        </Grid>
  )
}

export default AutocompleteFilter