import axios from 'axios';
import { apiServer, apiServerV2, noAuthAPIServer2 } from './index';
import { devConfig } from '../config';


export function checkProfilePermission(payload) {
  console.log("checkProfilePermission", payload)
  return apiServerV2.post(
    `discover/profile-access/status`, JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error in get Content Approval List:', error);
      throw error;
    });
}

export function unlockProfile(id, profile_type) {
  let url = `discover/${id}/unlock`;
  if (profile_type) {
    url += '?is_premium=true';
  }
  return apiServerV2.get(url)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error in get Content Approval List:', error);
      throw error;
    });
}

export function fetchNewInfluencer(payload) {
  return axios.post(
    `${devConfig.API_HOST}/api/v1/campaign/19/social_media/create/planner_profile/`,
    payload,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`
      } 
    })
}


export function fetchCuratedList(platform, category, search, page, rowsPerPage) {
  let url = `discover/curated-all?platform=${platform}`;
  if (category) {
    url += `&category=${category}`;
  }
  if (search) {
    url += `&search=${search}`
  }
  if (page && rowsPerPage){
    url += `&page=${page}&rows_per_page=${rowsPerPage}`
  }
  return apiServer.get(url)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error in get Currated List:', error);
      throw error;
    });
}

export function fetchCuratedInfluencers(id, page) {
  return apiServer.get(`discover/curated-list?id=${id}&page=${page}`)
  .then((response) => {
    console.log('API Response:', response); // Log the entire response object
    return response.data;
  })
    .catch((error) => {
      console.error('Error in get Currated List:', error);
      throw error;
    });
}

export function getDiscoveryInfluencersDetails(id) {
  return apiServerV2.get(`/discover/${id}`).then((response) => {
    return response.data;
  });
}

export function getProfileUpdateStatus(iq_id){
  return apiServerV2.get(`/discover/update-status?iq_id=${iq_id}`).then((response) => {
    return response.data;
  })
    .catch((error) => {
      console.error('Profile not Found: ', error);
      throw error
    });
}

export function getPlans(brand_id){
  return apiServer.get(`/discover/plan-list?brand_id=${brand_id}`).then((response) => {
    return response.data
  })
  .catch((error) => {
    console.error('Plan not Found: ', error);
    throw error
  });
}

export function addPlanProfiles(planId, data) {
  return apiServer.put(`/discover/plan/${planId}/add-iqprofile/`, JSON.stringify(data));
}

export function createPlan(data) {
  return apiServer.post(`/discover/plan/create/`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then((response) => response)
  .catch((error) => {
    console.error('Error in Creating a Plan', error);
    throw error;
  });
}

export function fetchPlanList( brand_id, platform, search, page, rowsPerPage) {
  let url = `/discover/plan-list?brand_id=${brand_id}`;
  if (platform) {
    url += `&platform=${platform}`;
  }
  if (search) {
    url += `&search=${search}`
  }
  if (page && rowsPerPage){
    url += `&page=${page}&rows_per_page=${rowsPerPage}`
  }
  return apiServer.get(url)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error in get Plan List:', error);
      throw error;
    });
}

export function fetchPlanInfluencers(id, platform, page, sortBy) {
  return noAuthAPIServer2.get(`discover/plan-influencer?uuid=${id}&platform=${platform}&sort-by=${sortBy}&page=${page}`)
  .then((response) => {
    console.log('API Response:', response);
    return response.data;
  })
    .catch((error) => {
      console.error('Error in get Plan List:', error);
      throw error;
    });
}
