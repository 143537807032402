import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import moment from 'moment';

import { USE_ES } from 'config';

import Statistics from './cards/youtube/Statistics';
import EngagementGraph from './cards/youtube/EngagementGraph';
import TopPerformers from './cards/common/TopPerformers';
import GenderSplitUpGraph from './cards/youtube/GenderSplitupGraph';
import AgeSplitUpGraph from './cards/youtube/AgeSplitupGraph';
import TopVideos from './cards/youtube/TopVideos';
import CampaignListCard from '../campaign/cards/CampaignList';

import Grid from '@material-ui/core/Grid';

import { getYoutubeTopPerformers, getYoutubeStatistic } from '../../api_handlers/reporting';
import { getYoutubeTopPerformersES, getYoutubeStatisticES } from 'api_handlers/es';

class YoutubeDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topPerformers: [],
      loading: true,
      topPerformersMetric: 'view_count',
      stats: null,
    };
  }

  async getData() {
    const fetchFunction = USE_ES ? getYoutubeTopPerformersES : getYoutubeTopPerformers;

    const resp = await fetchFunction(
      this.props.campaignId,
      this.state.topPerformersMetric,
      this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
      this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
    );
    this.setState({ topPerformers: resp.data, loading: false });
  }
  async getYoutubeStatsData() {
    const fetchFunction = USE_ES ? getYoutubeStatisticES : getYoutubeStatistic;

    const resp = await fetchFunction(
      this.props.campaignId,
      this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
      this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
    );
    this.setState({ stats: resp.data });
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
    setTimeout(() => {
      this.getData();
      this.getYoutubeStatsData();
    }, 30);
  }

  updateDashboardData() {
    this.getData();
    this.getYoutubeStatsData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.startDate !== this.props.startDate && prevProps.endDate !== this.props.endDate)
      this.updateDashboardData();
  }

  render() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} xl={12}>
          <Grid container spacing={1}>
            <Grid item xs={9} md={8} xl={8}>
              <Grid container spacing={1}>
                <Grid item md={12}>
                  {this.props.displayCardsList &&
                  this.props.displayCardsList.includes('statistics') ? (
                    <Statistics
                      socialPlatform={this.props.report?.socialPlatform}
                      {...this.props.report?.statistics}
                      startDate={this.props.startDate}
                      endDate={this.props.endDate}
                      stats={this.state.stats}
                    />
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item md={12}>
                  {this.props.displayCardsList &&
                  this.props.displayCardsList.includes('engagement_graph') ? (
                    <EngagementGraph />
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} xl={4}>
              {this.props.displayCardsList &&
              this.props.displayCardsList.includes('top_performers') ? (
                <TopPerformers
                  topPerformers={this.state.topPerformers}
                  loading={this.state.loading}
                  campaignId={this.props.campaignId}
                  onRef={(ref) => (this.topPerformersRef = ref)}
                  startDate={this.props.startDate}
                  endDate={this.props.endDate}
                  onChange={async (metricEvent) => {
                    await this.setState({ topPerformersMetric: metricEvent.target.value });
                    this.getData();
                  }}
                />
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item md={4}>
              {this.props.displayCardsList &&
              this.props.displayCardsList.includes('gender_graph') ? (
                <GenderSplitUpGraph />
              ) : (
                ''
              )}
            </Grid>
            <Grid item md={8}>
              {this.props.displayCardsList &&
              this.props.displayCardsList.includes('age_split_graph') ? (
                <AgeSplitUpGraph />
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{padding:12}}>
          {this.props.displayCardsList && this.props.displayCardsList.includes('top_videos') ? (
            <TopVideos top_posts={this.props.report?.top_posts} />
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12}>
          {this.props.displayCardsList &&
          this.props.displayCardsList.includes('recent_campaigns') ? (
            <CampaignListCard />
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
    displayCardsList: state.campaign.campaignId
      ? state.brand.displayPanels?.campaign.dashboard
      : state.brand.displayPanels?.brand.dashboard,
  };
};

export default connect(mapStateToProps)(withRouter(YoutubeDashboard));
