import { Link as RouterLink } from 'react-router-dom';

// material-ui
import Link from '@mui/material/Link';

// project imports
import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <img
    src="https://product.influencer.in/static/media/logo.9bfbfb27.png"
    alt="INFLUENCER"
    height={30}
  />
);

export default LogoSection;
