import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function StatsCard({ value, label, iconSrc }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.iconContainer}>
        <img src={iconSrc} className={classes.icon} alt="icon" />
      </Grid>
      <Grid item>
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography variant="h3" style={{ color: '#4A4A4A' }}>
            {value}
          </Typography>
        </Grid>
        <Grid container>
          <Typography variant="body1" style={{fontSize:14}}>{label}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    background: '#EFF0F9',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 224, 0.2), 5px -5px 10px rgba(215, 216, 224, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 224, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 224, 0.5)',
    borderRadius: 10,
    padding: 10,
  },
  iconContainer: {
    background: 'linear-gradient(135deg, #E3E4ED 0%, #FBFCFF 100%)',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 221, 0.2), 5px -5px 10px rgba(215, 216, 221, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 221, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 221, 0.5)',
    borderRadius: '50%',
    height: 18,
    width: 18,
    marginRight: 10,
  },
  icon: {
    height: 14,
    width: 14,
  },
}));

export default StatsCard;
