import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, CardContent, Grid, Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Line } from 'react-chartjs-2';
import NoDataLabel from '../common/NoDataLabel';

import { USE_ES } from 'config';

import { getInstagramEngagmentGraphData } from '../../../../api_handlers/reporting';
import { getInstagramEngagmentGraphDataES } from '../../../../api_handlers/es';

class EngagementGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  async getData() {
    const fetchFunction = USE_ES ? getInstagramEngagmentGraphDataES : getInstagramEngagmentGraphData;

    const resp = await fetchFunction(this.props.campaignId);
    this.setState({ data: resp.data, loading: false });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  render() {
    return (
      <Card style={{ height: 327 }}>
        <CardContent>
          <Grid container>
            <Grid item md={12} class="dashboard-card-title">
              Engagement Rate over time
            </Grid>
            <Grid item md={12}>
              {this.state.loading ? (
                <CircularProgress style={{ marginTop: 120 }} />
              ) : this.state.data.length === 0 ? (
                <NoDataLabel offsetTop={120} />
              ) : (
                <Line
                  // height={140}
                  width={600}
                  data={{
                    labels: this.state.data.labels,
                    datasets: [
                      {
                        label: 'Likes',
                        data: this.state.data.like_count,
                        fill: false,
                        backgroundColor: 'rgb(255, 99, 132)',
                        borderColor: 'rgb(255, 99, 132)',
                      },
                      {
                        label: 'Comments',
                        data: this.state.data.comment_count,
                        fill: false,
                        backgroundColor: 'rgb(54, 162, 235)',
                        borderColor: 'rgb(54, 162, 235)',
                      },
                      {
                        label: 'Saved',
                        data: this.state.data.saved,
                        fill: false,
                        backgroundColor: 'rgb(255, 206, 86)',
                        borderColor: 'rgb(255, 206, 86)',
                      },
                      {
                        label: 'Impressions',
                        data: this.state.data.impressions,
                        fill: false,
                        backgroundColor: 'rgb(75, 192, 192)',
                        borderColor: 'rgb(75, 192, 192)',
                      },
                      {
                        label: 'Video Views',
                        data: this.state.data.video_views,
                        fill: false,
                        backgroundColor: 'rgb(255, 159, 64)',
                        borderColor: 'rgb(255, 159, 64)',
                      },
                      {
                        label: 'Post Count',
                        data: this.state.data.post_count,
                        fill: false,
                        backgroundColor: 'rgb(5, 5, 5)',
                        borderColor: 'rgb(5, 5, 5)',
                      },
                    ],
                  }}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
  };
};

export default connect(mapStateToProps)(withRouter(EngagementGraph));
