import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

function ImageButton({
  imageSrc,
  height,
  width,
  imageHeight,
  imageWidth,
  border,
  varient,
  ...props
}) {
  const classes = useStyles({ height, width });

  const getBorderParams = () => {
    if (border)
      return {
        variant: 'outlined',
        color: 'secondary',
      };
  };

  return (
    <Button
      className={classNames(
        classes.root,
        varient === 'circled' ? classes.circled : classes.roundedCorner,
      )}
      {...getBorderParams()}
      {...props}
    >
      <img src={imageSrc} alt="img" height={imageHeight} width={imageWidth} />
    </Button>
  );
}

ImageButton.defaultProps = {
  border: false,
  varient: 'circled',
};

const useStyles = makeStyles(() => ({
  root: (props) => ({
    background: 'linear-gradient(135deg, #E3E4ED 0%, #FBFCFF 100%)',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 221, 0.2), 5px -5px 10px rgba(215, 216, 221, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 221, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 221, 0.5)',
    padding: 0,
    height: props.height,
    width: props.width,
    minWidth: props.width,
    cursor: 'pointer',
  }),
  circled: {
    borderRadius: '50%',
  },
  roundedCorner: {
    borderRadius: 10,
  },
}));

export default ImageButton;
