require('dotenv').config();

const API_HOST = process.env.REACT_APP_API_HOST || 'https://api-staging.influencer.in';

// const API_HOST = "https://api.influencer.in";
// const API_HOST = "http://localhost:8001";
const FACEBOOK_APP_ID = '449781952733647';
const INSTAGRAM_APP_ID = '487541268980617';
const INSTAGRAM_HOST = 'https://api.instagram.com/oauth/authorize';

const devConfig = {
  API_HOST: API_HOST,
};

const WHATSAPP_NUMBERS = [
  '+919958559379', '+918754493825'
]

const WHATSAPP_SEND_ONLY_TO_TEAM = true
const USE_ES = (process.env.REACT_APP_USE_ES || 'false') === 'true';
const USE_PLANNER_ES = (process.env.REACT_APP_USE_PLANNER_ES || 'false') === 'true';

export { USE_ES, USE_PLANNER_ES, API_HOST, devConfig, FACEBOOK_APP_ID, INSTAGRAM_APP_ID, INSTAGRAM_HOST, WHATSAPP_NUMBERS, WHATSAPP_SEND_ONLY_TO_TEAM };


export const DASHBOARD_PATH = '/dashboard/default';
export const HORIZONTAL_MAX_ITEM = 7;

export const MenuOrientation = {
    VERTICAL: 'vertical',
    HORIZONTAL: 'horizontal'
};

export const ThemeMode = {
    LIGHT: 'light',
    DARK: 'dark'
};

export const ThemeDirection = {
    LTR: 'ltr',
    RTL: 'rtl'
};

const config = {
    menuOrientation: MenuOrientation.VERTICAL,
    miniDrawer: false,
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    mode: ThemeMode.LIGHT,
    presetColor: 'theme7',
    i18n: 'en',
    themeDirection: ThemeDirection.LTR,
    container: false
};

export default config;
