import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { Grid } from '@material-ui/core';

class ButtonStyledLink extends React.Component {
  render() {
    return (
      <NavLink
        to={this.props.to}
        style={{
          textDecoration: 'none',
          lineHeight: '17px',
          margin: '5px',
          color: 'black',
        }}
        activeStyle={{
          fontWeight: 'bold',
          color: '#1E9CB2',
        }}
      >
        <Grid container direction="column" justify="space-between" alignItems="center">
          {this.props.icon ? (
            <Grid item md={12}>
              <img src={this.props.icon} alt="icon" />
            </Grid>
          ) : (
            ''
          )}
          <Grid item md={12}>
            {this.props.label}
          </Grid>
        </Grid>
      </NavLink>
    );
  }
}

export default withRouter(ButtonStyledLink);
