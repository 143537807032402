import { Component } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  Paper,
  CircularProgress,
  FormControl,
  Select,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import NoDataLabel from './NoDataLabel';

import { downloadFile } from '../../../../api_handlers/index';
import BootstrapInput from '../../../ux/BootstrapInput';
import countertostring from 'components/PlannerV2/components/common';
class TopPerformers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metric: 'view_count',
    };
  }

  downloadReport() {
    downloadFile(`/campaign/${this.props.campaignId}/report/youtube/top-performer/download`, {
      metric: this.state.metric,
      start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
      end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
    });
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
  }

  render() {
    return (
      // <Card style={{ height: '100%' }}>
      //   <CardContent style={{ padding: '7px 0px' }}>
      <Grid container style={{ border: '1px solid #dedede', height: '100%' }}>
        <Grid item md={12}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{
              fontSize: '12px',
            }}
          >
            <Grid
              item
              md={4}
              class="dashboard-card-title"
              style={{ marginLeft: '10px', marginTop: '7px' }}
            >
              <Typography style={{ fontSize: 15, fontWeight: 600 }}> Top performers</Typography>
            </Grid>
            <Grid
              item
              md={7}
              style={{
                fontSize: '11px',
                fontWeight: '500',
                marginTop: '9px',
              }}
            >
              <FormControl variant='outlined'>
                <Select
                  // value={age}
                  onChange={(value) => {
                    this.props.onChange(value);
                    this.setState({ metric: value.target.value });
                  }}
                  input={<BootstrapInput />}
                  defaultValue={'view_count'}
                >
                  <MenuItem value={'view_count'}>View</MenuItem>
                  <MenuItem value={'engagement_rate'}>Engagement rate</MenuItem>
                  <MenuItem value={'comment_count'}>Comments</MenuItem>
                  <MenuItem value={'like_count'}>Likes</MenuItem>
                </Select>
              </FormControl>
              <IconButton
                color="#000000"
                aria-label="upload picture"
                component="span"
                onClick={() => {
                  this.downloadReport();
                }}
              >
                <CloudDownloadIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          style={{
            overflowY: 'scroll',
            height: 360,
          }}
        >
          {this.props.loading ? (
            <CircularProgress style={{ marginTop: 150 }} />
          ) : this.props.topPerformers.length === 0 ? (
            <NoDataLabel offsetTop={160} />
          ) : (
            <List dense>
              {this.props.topPerformers.map((influencer, i) => {
                return (
                  <>
                    <ListItem key={i} button style={{ marginTop: '9px', marginBottom: '9px' }}>
                      <ListItemAvatar>
                        <Avatar
                          src={influencer.avatar}
                          style={{
                            width: '50px',
                            height: '50px',
                          }}
                        />
                      </ListItemAvatar>
                      <span primary={influencer.name} class="dashboard-card-label">
                        <a
                          href={
                            '/influencer/campaigns/all/feeds/' +
                            influencer.influencer_id +
                            '/youtube'
                          }
                          style={{
                            textDecoration: 'none',
                            color: '#444',
                            fontSize: 14,
                          }}
                        >
                          {influencer.name}
                        </a>
                      </span>
                      <ListItemSecondaryAction>
                        <Grid
                          style={{
                            background: '#f9b725',
                            padding: '5px',
                            color: '#000',
                            fontWeight: '600',
                            fontSize: '13px',
                            borderRadius:3
                          }}
                        >
                          {countertostring(influencer.value)}
                        </Grid>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {i !== this.props.topPerformers.length - 1 ? (
                      <Divider variant="fullWidth" />
                    ) : null}
                  </>
                );
              })}
            </List>
          )}
        </Grid>
      </Grid>
      //   </CardContent>
      // </Card>
    );
  }
}

export default TopPerformers;
