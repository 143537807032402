import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Select from 'components/Theme/Elements/Select';

import Filter from '../Filter';

import LocationIcon from 'assets/discovery/Location-Icon.png';
import MultiSelect from 'components/Theme/Elements/MultiSelect';
import { getDiscoveryLocations } from 'api_handlers/discovery';

function LocationFilter({ value, setFilters }) {
  const classes = useStyles();
  const [locations, setLocations] = useState([]);

  function handleFilter(event, selectedValues) {
    setFilters({
      location: selectedValues,
    });
  }

  useEffect(() => {
    getDiscoveryLocations().then((response) => {
      console.log('response data', response.data);
      setLocations(response.data);
    });
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Filter label={'Influencer Location'} labelIconSrc={LocationIcon}>
          <Grid container className={classes.filterContainer}>
            <MultiSelect
              options={locations
                .filter((location) => location)
                .map((location) => ({ label: location, value: location }))}
              value={value || []}
              onChange={handleFilter}
            />
          </Grid>
        </Filter>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {},
  filterContainer: {},
}));

export default LocationFilter;
