import * as React from 'react';
import { Grid, Typography, TextField, Button, IconButton, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SliderWithoutBackgroundCustomSlider from 'components/PlannerTheme/Elements/SliderWithoutBackground/SliderWithoutBackground';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
class CreatorsBudgetValue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      icons: false,
    };
  }
  changeLockIcon() {
    this.setState({ icons: !this.state.icons });
  }
  render() {
    let { classes } = this.props;
    let icons = false;
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={3} className={classes.align}>
          <Typography className={classes.label}>
            {this.props.text}
            <Typography className={classes.label2}>{this.props.text2}</Typography>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <SliderWithoutBackgroundCustomSlider
            className={classes.slider}
            aria-label="Always visible"
            max={this.props.budget}
            // disabled={this.props.isDisabled}
            onChange={
              (event, newValue) =>
                this.props.setCelebsType(`${this.props.setCelebsTypeText}`, newValue)
              //   newValue <= this.props.max_value + this.props.value
              //     ? newValue
              //     : this.props.max_value + this.props.value
              // )
            }
            step={1000}
            value={this.props.value}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            inputProps={{ style: { fontSize: 11 } }}
            placeholder="₹ 0"
            className={classes.search}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment className={classes.symbol} position="start">
                  ₹
                </InputAdornment>
              ),
            }}
            disabled
            value={`${this.props.value}`}
            // onChange={(e) =>
            //   this.props.setCelebsType(
            //     `${this.props.setCelebsTypeText}`,
            //     Number(e.target.value) <=
            //       this.props.max_value + this.props.value
            //       ? Number(e.target.value)
            //       : this.props.max_value + this.props.value
            //   )
            // }
          />
        </Grid>
        <Grid item sx={{ textAlign: 'center' }}>
          <IconButton
            onClick={() => {
              this.changeLockIcon();
              this.props.setInfluencerTypeLock(`${this.props.setLockText}`, !this.state.icons);
            }}
          >
            {this.state.icons === false ? (
              <LockOpenIcon style={{ color: '#AAAAAA' }}></LockOpenIcon>
            ) : (
              <LockIcon style={{ color: '#FEBD1C' }}></LockIcon>
            )}
          </IconButton>
        </Grid>
        {this.props.reset === true ? (
          <Grid item className={classes.gridItems}>
            <Button
              className={classes.resetButton}
              onClick={() => {
                this.props.setCelebsType('mega', 0);
                this.props.setCelebsType('macro', 0);
                this.props.setCelebsType('micro', this.props.budget);
                this.props.setCelebsType('nano', 0);
              }}
            >
              Reset
            </Button>
          </Grid>
        ) : (
          <Grid item className={classes.gridItems}>
            <Button disabled></Button>
          </Grid>
        )}
      </Grid>
    );
  }
}

const styles = {
  search: {
    border: '1px solid #C4C4C4',
    borderRadius: '10px',
    background: '#FFFFFF',
    width: 90,
    height: 15,
    outline: 'none',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 80,
    fontSize: 12,
    justifyContent: 'center',
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000000',
    },
  },
  optionLabel: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 16,
  },
  label: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 13,
    color: '#757575',
    marginBottom: 10,
    marginRight: 50,
    display: 'flex',
  },
  label2: {
    fontSize: 10,
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    marginTop: 3,
    marginLeft: 5,
    color: '#757575',
    display: 'flex',
  },
  align: {
    textAlign: 'center',
  },
  slider: {
    width: 150,
  },
  resetButton: {
    textTransform: 'none',
    color: '#757575',
  },
  gridItems: {
    maxWidth: '20%',
    marginLeft: 20,
  },
};

export default withStyles(styles)(CreatorsBudgetValue);
