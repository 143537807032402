import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Divider } from '@material-ui/core';

import ProfileStepper from './Stepper';
import background from '../../../assets/profile-setup-bg.png';
import './ProfileSetup.css';

class ProfileSetup extends React.Component {
  render() {
    const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
    const activeStep = 0;
    return (
      <Grid
        container
        spacing={4}
        style={{
          background: 'white',
        }}
      >
        <Grid item md={4} className={`web_account_wrapper`}>
          <Grid container className={`account_setup_message`}>
            <Grid item md={8}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={`account_setup_tagline`}
              >
                <Grid item>
                  <h1>Complete Your Account Setup</h1>
                </Grid>
                <Grid item>
                  <h5>
                    Your personal information will help us understand you and your audience which
                    will be used to find the the right brand for you.
                  </h5>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} className={`mobile_account_wrapper`}>
          <Grid container className={`account_setup_message`}>
            <Grid item md={8}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={`account_setup_tagline`}
              >
                <Grid item>
                  <h1>Complete Your Account Setup</h1>
                </Grid>
                <Grid item>
                  <h5>
                    Your personal information will help us understand you and your audience which
                    will be used to find the the right brand for you.
                  </h5>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={8} className={`web_account_setup_form`}>
          <Grid className={`account_setup_heading`}>
            <h3>Complete Account Setup</h3>
          </Grid>
          <Divider></Divider>
          <Grid container direction="row" justifyContent="center" style={{ marginTop: 30 }}>
            <Grid item md={12}>
              <ProfileStepper />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} className={`mobile_account_setup_form`}>
          <Grid className={`account_setup_heading`}>
            <h3>Complete Account Setup</h3>
          </Grid>
          <Divider></Divider>
          <Grid container direction="row" justifyContent="center" style={{ marginTop: 30 }}>
            <Grid item md={12}>
              <ProfileStepper />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(ProfileSetup);
