import { useEffect, useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function IconLabel({
  imageSrc,
  label,
  sortable = false,
  sort = false,
  sortOrder = '',
  sortByThis,
}) {
  const classes = useStyles();
  const [dimensions, setDimensions] = useState({});

  useEffect(() => {
    const img = new Image();
    img.onload = function () {
      if (this.width > this.height) setDimensions({ width: 15 });
      else setDimensions({ height: 15 });
    };
    img.src = imageSrc;
  }, []);

  return (
    <Grid
      container
      className={classNames(classes.root, sortable ? classes.sortableLabel : null)}
      spacing={1}
      onClick={() => sortable && sortByThis()}
    >
      <Grid item md={'auto'} className={classes.iconBox}>
        <img src={imageSrc} alt="icon" className={classNames(classes.icon)} style={dimensions} />
      </Grid>
      <Grid item md={6}>
        <Typography className={classNames(classes.label, sortable ? classes.sortableLabel : null)}>
          {label}
        </Typography>
      </Grid>
      {sortable && sort ? (
        <Grid item>
          {sortOrder === 'asc' ? (
            <ArrowUpwardIcon className={classes.sortArrow} />
          ) : (
            <ArrowDownwardIcon className={classes.sortArrow} />
          )}
        </Grid>
      ) : null}
    </Grid>
  );
}

IconLabel.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'flex',
    alignItems: 'center',
  },
  sortableLabel: {
    cursor: 'pointer',
    fontWeight: 700,
  },
  sortArrow: {
    fontSize: '15px !important',
  },
  label: {
    fontSize: '0.7em',
  },
}));

export default IconLabel;
