import { Grid, Typography, makeStyles } from '@material-ui/core';
import CreatorDetailedInsightsCard from './CreatorDetailedInsightsCard';
import { withStyles } from '@material-ui/core/styles';

import { useParams } from 'react-router-dom';

function DetailedInsights({ classes }) {
  const { keyword } = useParams();
  // const classes = useStyles();
  const query = new URLSearchParams(window.location.href);
  const searchKeyword = query.get('keyword')
  const paidPost = query.get('paidPost')
  // console.log('searchKeyword',searchKeyword)
  // console.log('keyword___',keyword)
  
  return (
    <Grid container direction="column">
      <Grid item md={12} className={classes.header}>
        <Grid item md={12}>
          <Typography style={{ color: 'black' }}>DETAILED INSIGHTS</Typography>
        </Grid>
      </Grid>
      {/* <Grid item md={12}>
            <Typography style={{fontSize:30,marginTop:30,marginLeft:50,fontWeight:550,textAlign:'start'}}>Detailed Insights for "Category Clicked"</Typography>
        </Grid > */}
      <Grid item md={12} style={{ marginLeft: 33, textAlign: 'start' }}>
        <CreatorDetailedInsightsCard keyword={keyword} searchKeyword={searchKeyword} paidPost={paidPost}></CreatorDetailedInsightsCard>
      </Grid>
    </Grid>
  );
}

const styles = (theme) => ({
  header: {
    color: '#4A4A4A',
    fontSize: '20px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    lineHeight: 1.167,
    letterSpacing: '0.03em',
    padding: '15px',
    background: '#F3F4FE',
    boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    borderRadius: '10px',
    marginBottom: '0px',
    marginTop: '20px',
    marginLeft: '27px',
    marginRight: '40px',
  },
});

export default withStyles(styles)(DetailedInsights);

// const useStyles = makeStyles(() => ({
//     a:{
//         fontSize:100
//     }
// }));
