import { apiServer } from './index';
import { devConfig } from '../config';
import history from '../history';
let download = require('downloadjs');

export function getPlannerInfluencers(payload = {}, page = 1) {
  const GET_PLANNER_INFLUENCER_URL = `${devConfig.API_HOST}/api/v1/discover/new`;
  return fetch(GET_PLANNER_INFLUENCER_URL + '?page=' + page, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ payload: payload }),
  }).then((response) => {
    return response;
  });
}

export function getPlannerDownload(payload = {}) {
  const GET_PLANNER_INFLUENCER_URL = `${devConfig.API_HOST}/api/v1/campaign/19/report/iqprofile/planner/download`;
  return fetch(GET_PLANNER_INFLUENCER_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ payload: payload }),
  })
    .then((response) => {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, 'download.csv', 'application/csv');
    });
}

export function getDiscoveryInfluencers(params) {
  return apiServer.get(`/discover/?${params}`).then((response) => {
    return response.data;
  });
}

export function getDiscoveryInfluencersDetails(id) {
  return apiServer.get(`/discover/${id}`).then((response) => {
    return response.data;
  });
}

export function getDiscoveryLocations() {
  return apiServer.get(`/discover/locations`).then((response) => {
    return response.data;
  });
}

export function getPlannerCountries() {
  return apiServer.get(`/discover/countries`).then((response) => {
    return response.data;
  });
}

export function getPlannerAudienceInterests() {
  return apiServer.get(`/discover/interest-brand`).then((response) => {
    return response.data.audienceInterests;
  });
}

export function getDiscoveryLanguages() {
  return apiServer.get(`/discover/languages`).then((response) => {
    return response.data;
  });
}

export function getDiscoveryFilterData() {
  return apiServer.get(`/discover/filter-data`).then((response) => {
    return response.data;
  });
}

export function getDiscoverySearchResult(query, source) {
  return apiServer
    .get(`/discover/search?query=${encodeURIComponent(query)}`, { cancelToken: source.token })
    .then((response) => {
      return response.data;
    });
}

export function getPlannerAudienceLocations() {
  return apiServer.get(`/discover/audience-location`).then((response) => {
    return response.data.audience_cities;
  });
}

export function sendWhatsappMessage(whatsapp_number, template_sid, influencer, campaign, text_content, file) {
  const GET_PLANNER_INFLUENCER_URL = `${devConfig.API_HOST}/api/v1/influencer/send_whatsapp_message`;
  let body = { whatsapp_number, influencer, campaign, text_content };
  if (template_sid) {
    body['template_sid'] = template_sid
  }
  if (file) {
    let form_data = new FormData();
    form_data.append('media_url', file, file.name);
    form_data.append('influencer', body['influencer']);
    form_data.append('campaign', body['campaign']);
    form_data.append('whatsapp_number', body['whatsapp_number']);
    return fetch(GET_PLANNER_INFLUENCER_URL, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      },
      body: form_data,
    }).then((response) => {
      return response;
    });
  }
  return fetch(GET_PLANNER_INFLUENCER_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    return response;
  });
}

export function getInfluencerChats(campaign_id, influencer_status) {
  const statusParam = influencer_status ? `&influencer_status=${influencer_status}` : '';

  const apiUrl = `/discover/influencer-chats?campaign_id=${campaign_id}${statusParam}`;

  return apiServer.get(apiUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error in getInfluencerChats:', error);
      throw error;
    });
}

export function getContentList(campaign_id, content_status) {
  const statusParam = content_status ? `?status=${content_status}&` : '?';

  let apiUrl = `/live_campaign/${campaign_id}/content_approval_list${statusParam}page_size=50`
  
  return apiServer.get(apiUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error in get Content Approval List:', error);
      throw error;
    });
}

export function updateContentStatus(content_id, content_status, comment) {
  const apiUrl = `/live_campaign/${content_id}/content_update_status`

  return apiServer.post(apiUrl, JSON.stringify({ status: content_status, comment }))
  .then((response) => response.results)
  .catch((error) => {
    console.error('Error in updating Content Status:', error);
    throw error;
  })
}

export function getInfluencerCount(campaign_id) {
  const apiUrl = `/discover/influencer-count?campaign_id=${campaign_id}`

  return apiServer.get(apiUrl)
  .then((response) => response)
  .catch((error) => {
    console.error('Error in getting Influencer Count', error);
    throw error
  })
}

export function InfluencerUpdateProfile(planner_id,influencer,platform){
  const apiUrl=`/discover/update-iqdata?planner_id=${planner_id}&influencer=${influencer}&platform=${platform}`
  console.log('url',apiUrl)
  return apiServer.get(apiUrl)
    .then((response) => response)
    .catch((error) => {
      console.error('Error in get Content Approval List:', error);
      throw error;
    });
}

export function createInstagramIQProfile(link) {
  const apiUrl=`${devConfig.API_HOST}/api/v1/campaign/1/social_media/create/instagram/profile_planner/`
  return apiServer.post(apiUrl, JSON.stringify({ link: link }))
  .then((response) => response)
  .catch((error) => {
    console.error('Error in updating Content Status:', error);
    throw error;
  })
}

export function createYoutubeIQProfile(link) {
  const apiUrl=`${devConfig.API_HOST}/api/v1/campaign/1/social_media/create/youtube/profile_planner/`
  return apiServer.post(apiUrl, JSON.stringify({ link: link }))
  .then((response) => response)
  .catch((error) => {
    console.error('Error in updating Content Status:', error);
    throw error;
  })
}

export function getCityStateList(is_city) {
  const cityParam = is_city ? `?is_city=True` : '';
  return apiServer.get(`/discover/city-state-list${cityParam}`).then((response) => {
    return response.data;
  });
}

export function getIdViaChannelYt(channel_id){
  const apiUrl=`${devConfig.API_HOST}/api/v1/discover/yt-channel-to-planner-id?channel_id=${channel_id}`
  return apiServer.get(apiUrl)
  .then((response) => response)
  .catch((error) => {
    console.error('Error in updating Content Status:', error);
    throw error;
  })
}

export function getIdViaHandler(handler){
  const apiUrl=`${devConfig.API_HOST}/api/v1/discover/insta-handler-to-planner-id?handler=${handler}`
  return apiServer.get(apiUrl)
  .then((response) => response)
  .catch((error) => {
    console.error('Error in updating Content Status:', error);
    throw error;
  })
}