import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';

// import {INSTAGRAM_HOST, INSTAGRAM_APP_ID}

class InstagramLogin extends Component {
  login() {
    const url =
      'https://api.instagram.com/oauth/authorize?client_id=487541268980617&redirect_uri=https://localhost:3000/instagram/callback/&scope=user_profile,user_media&response_type=code';
    window.location.href = encodeURI(url);
  }

  render() {
    return (
      <Grid
        container
        alignItems="center"
        spacing={1}
        style={{
          background: 'white',
          height: 50,
        }}
      >
        <Grid item md={12}>
          <button
            className="btn btn-Instagram"
            onClick={() => {
              this.login();
            }}
          >
            <i className="fa fa-facebook mr-1"></i>
            Login with Instagram
          </button>
          <button className="btn btn-facebook" onClick={this.logout}>
            <i className="fa fa-facebook mr-1"></i>
            Logout from Instagram
          </button>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(InstagramLogin);
