import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, CardContent, Grid } from '@material-ui/core';

import CountDisplay from '../../../../ux/CountDisplay';

// import ViewIcon from '@material-ui/icons/VisibilityOutlined';
// import LikeIcon from '@material-ui/icons/ThumbUpOutlined';
// import CommentIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import logo from '../../../../../assets/instagram-logo.png';
import ViewIcon from '../../../../../assets/users_group.png';
import Thumbs_UpIcon from '../../../../../assets/Thumbs_Up.png';
import CommentIcon from '../../../../../assets/Comment.png';
import EngagementIcon from '../../../../../assets/Engagement.png';
import EngagementRateIcon from '../../../../../assets/EngagementRate.png';

import { getInfluencerInstagramStatistic } from '../../../../../api_handlers/reporting';
import CircularProgress from '@material-ui/core/CircularProgress';

class Statistics extends Component {
  constructor(props) {
    super(props);
  }

  // async getData() {
  //     const campaign_id = this.props.campaign_id
  //     const influencer_id = this.props.influencer_id
  //     const resp = await getInfluencerInstagramStatistic(campaign_id, influencer_id)
  //     this.setState({ ...resp.data, loading: false })
  // }

  // componentDidMount() {
  //     setTimeout(() => {
  //         this.getData()
  //     }, 30)
  // }

  render() {
    return (
      <Card>
        <CardContent>
          <Grid container>
            <Grid
              item
              md={12}
              style={{
                marginBottom: (this.props.extraMargin | 0) + 10,
              }}
            >
              <Grid container justify="space-between">
                <Grid item md={4}>
                  <Grid container alignItems="center">
                    <Grid item md={2}>
                      <img src={logo} height={'29'} />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      style={{
                        fontSize: '13px',
                        fontWeight: 500,
                      }}
                    >
                      Statistics
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{
                    fontSize: '12px',
                    lineHeight: '14px',
                    textAlign: 'right',
                    color: '#5C5C5C',
                  }}
                >
                  Overall data
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              {!this.props.stats ? (
                <CircularProgress />
              ) : (
                <Grid container spacing={0} justify="space-around">
                  <Grid item md={2}>
                    <CountDisplay
                      icon={ViewIcon}
                      label={'Total Video Views'}
                      count={this.props.stats && this.props.stats.total_video_views | 0}
                      color={'#7158F1'}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <CountDisplay
                      icon={Thumbs_UpIcon}
                      label={'Total Likes'}
                      count={this.props.stats && this.props.stats.total_likes | 0}
                      color={'#B563DC'}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <CountDisplay
                      icon={CommentIcon}
                      label={'Total Comments'}
                      count={this.props.stats && this.props.stats.total_comments | 0}
                      color={'#E78615'}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <CountDisplay
                      icon={CommentIcon}
                      label={'Total Saved'}
                      count={this.props.stats && this.props.stats.total_saved}
                      color={'#0CBD8C'}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <CountDisplay
                      icon={ViewIcon}
                      label={'Total Reach'}
                      count={this.props.stats && this.props.stats.total_reach | 0}
                      color={'#7158F1'}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <CountDisplay
                      icon={ViewIcon}
                      label={'Total Impressions'}
                      count={this.props.stats && this.props.stats.total_impressions | 0}
                      color={'#7158F1'}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
  };
};

export default connect(mapStateToProps)(withRouter(Statistics));
