import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import InterestIcon from 'assets/discovery/interest-icon.png';
import GenderIcon from 'assets/discovery/Gender-icon.png';
import LocationIcon from 'assets/discovery/Location-Icon.png';
import GenderSplitIcon from 'assets/discovery/gender-split-icon.png';
import LanguageIcon from 'assets/discovery/Language-icon.png';
import FilterVintageIcon from '@material-ui/icons/FilterVintage';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function AudienceDetails({ audience_interest = [], audience_languages = [] }) {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item md={12} className={classes.header}>
        <Typography variant="h4" style={{ color: 'black' }}>
          AUDIENCE DETAILS
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Grid container spacing={3}>
          <Grid item md={5}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <Grid className={classes.card} style={{ height: 200 }}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <img src={GenderSplitIcon} alt="icon" className={classes.iconSize} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" style={{ color: 'black' }}>
                        Audience Age Split
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item style={{ height: 350, width: 350, marginLeft: 40 }}>
                      <Bar
                        data={{
                          labels: ['Below 17', '18 - 25', '26 - 35', '36 - 45'],
                          datasets: [
                            {
                              label: 'Age Split',
                              data: [123, 432, 534, 423],
                              backgroundColor: 'rgba(123, 97, 255, 0.5)',
                            },
                          ],
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid className={classes.card} style={{ height: 350 }}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <img src={InterestIcon} alt="icon" className={classes.iconSize} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" style={{ color: '#0061A7' }}>
                        Audience Interest
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className={classes.interestContainer}>
                    {audience_interest.map((interest, i) => (
                      <Grid item md={12} className={classes.interest} key={i}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item className={classes.interestIconContainer}>
                            <FilterVintageIcon className={classes.interestIcon} />
                          </Grid>
                          <Grid item>
                            <Typography variant="body1">{interest.name}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={7}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <Grid className={classes.card} style={{ height: 75 }}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <img src={LanguageIcon} alt="icon" className={classes.iconSize} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" style={{ color: 'black' }}>
                        Language
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.languageBox}>
                    {audience_languages.slice(0, 3).map((language, i) => (
                      <Grid item className={classes.languageContainer} key={i}>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography variant="body1" style={{ color: 'black', fontWeight: 700 }}>
                              {language.percentage}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2" style={{ color: 'black' }}>
                              {language.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid className={classes.card} style={{ height: 200 }}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <img src={GenderIcon} alt="icon" className={classes.iconSize} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" style={{ color: 'black' }}>
                        Audience Gender Split
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item style={{ height: 350, width: 400, marginLeft: 100 }}>
                      <Bar
                        data={{
                          labels: ['Below 17', '18 - 25', '26 - 35', '36 - 45'],
                          datasets: [
                            {
                              label: 'Male',
                              data: [123, 432, 534, 423],
                              backgroundColor: 'rgba(0, 97, 167, 0.5)',
                            },
                            {
                              label: 'Female',
                              data: [123, 432, 534, 423],
                              backgroundColor: 'rgba(239, 26, 116, 0.5)',
                            },
                          ],
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid className={classes.card} style={{ height: 200 }}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <img src={LocationIcon} alt="icon" className={classes.iconSize} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" style={{ color: '#B90000' }}>
                        Audience Location Split
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item style={{ height: 350, width: 400, marginLeft: 100 }}>
                      <Bar
                        data={{
                          labels: ['Mumbai', 'Pune', 'Bangalore', 'Chennai'],
                          datasets: [
                            {
                              label: 'Location Split',
                              data: [123, 432, 534, 423],
                              backgroundColor: 'rgba(185, 0, 0, 0.8)',
                            },
                          ],
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  header: {
    background: '#F3F4FE',
    boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    borderRadius: 10,
    padding: 15,
    marginBottom: 15,
  },
  card: {
    background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    padding: 20,
  },
  graphCard: {
    height: 200,
  },
  iconSize: {
    width: 15,
    height: 15,
  },
  brandAffinityCard: {
    height: 170,
  },
  pricingCard: {
    height: 200,
    marginTop: 15,
  },
  interestContainer: {
    marginTop: 20,
    marginLeft: 20,
    overflowY: 'scroll',
    maxHeight: '90%',
  },
  interestIconContainer: {
    background: 'linear-gradient(135deg, #E8EAEE 0%, #FFFFFF 100%)',
    boxShadow:
      '-5px 5px 10px rgba(220, 221, 222, 0.2), 5px -5px 10px rgba(220, 221, 222, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(220, 221, 222, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(220, 221, 222, 0.5)',
    borderRadius: '50%',
    padding: 3,
    width: 23,
    height: 23,
  },
  interestIcon: {
    color: '#B9B9B9',
    fontSize: 13,
  },
  languageBox: {
    marginLeft: 20,
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  languageContainer: {
    background: '#F3F4FE',
    boxShadow:
      '-5px 5px 10px rgba(219, 220, 229, 0.2), 5px -5px 10px rgba(219, 220, 229, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(219, 220, 229, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(219, 220, 229, 0.5)',
    borderRadius: 5,
    padding: '5px 20px',
    margin: 10,
  },
}));

export default AudienceDetails;
