import React from 'react';
import { Modal, Paper, Typography, Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

const TemplateModal = ({ isOpen, onClose, templateId, templateDict, sendBroadCastMessage, classes }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>

         {/* <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
        <MenuItem>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Template</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={templatesId}
              onChange={handleTemplateChange}
              label="Select Template"
            >
              <MenuItem value="HXf2c8659547d7af331fe5d11761c50320">Campaign reachout</MenuItem>
              <MenuItem value="HX8340e6ffec177e070750a1897792119c">Shortlisted</MenuItem>
              <MenuItem value="HX20ec9cff48ea96ae85171e0f5c680119">Product selection</MenuItem>
              <MenuItem value="HXd8354ef6be43f922ced13776313dfcb3">Product delivery confirmation</MenuItem>
              <MenuItem value="HXbc01316b0bbf37c765035414b8301706">Content submission Deadline</MenuItem>
              <MenuItem value="HXb6613d2eb00572fe1696bf636d0b054f">Content approval</MenuItem>
              <MenuItem value="HX5df25eeed65f7dae52910656cf3a9986">Payment Confirmation</MenuItem>
            </Select>
          </FormControl>
        </MenuItem>
        <MenuItem onClick={handleShowLayouts}>Apply Template</MenuItem>
      </Menu> */}

      <Paper style={{ padding: 20, position: 'absolute', width: '59%', top: '120px', left: '312px' }}>
        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: templateDict[templateId].replace(/\n/g, '<br />') }} />
        <Button 
          variant="contained" color="primary" style={{position: 'absolute', right: '15px', bottom: '15px'}}
          onClick={(e)=>{
            sendBroadCastMessage();
          }}>
          <SendIcon />
        </Button>
      </Paper>
    </Modal>
  );
};

export default TemplateModal;