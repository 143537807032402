import { constants } from '../constants/discovery';

import { getDiscoveryInfluencers, getPlannerInfluencers } from 'api_handlers/discovery';

function objectToParams(object) {
  let str = '';
  for (let key in object) {
    if (str !== '') {
      str += '&';
    }
    str += key + '=' + encodeURIComponent(object[key]);
  }
  return str;
}

export function getInfluencers() {
  return (dispatch, getState) => {
    const {
      discovery: { filters },
    } = getState();

    const simple_join_filters = [
      'followers',
      'audienceView',
      'avgEngagement',
      'audienceGender',
      'audienceAgeGroup',
      'audienceReachability',
    ];
    const value_join_filters = [
      'category',
      'location',
      'language',
      'audienceLocation',
      'audienceInterest',
    ];

    const modifiedFilters = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key] === null) return;
      if (Array.isArray(filters[key]) && filters[key].every((el) => el === null)) return;
      modifiedFilters[key] = filters[key];
    });

    simple_join_filters.forEach((key) => {
      if (!modifiedFilters[key]) return;
      modifiedFilters[key] = modifiedFilters[key].join(',');
    });

    value_join_filters.forEach((key) => {
      if (!modifiedFilters[key]) return;
      modifiedFilters[key] = modifiedFilters[key].map((item) => item.value).join(',');
    });

    dispatch(request());
    getDiscoveryInfluencers(objectToParams(modifiedFilters)).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        dispatch(failure());
      },
    );
  };

  function request() {
    return { type: constants.GET_INFLUENCER_REQUEST };
  }

  function success(data) {
    return { type: constants.GET_INFLUENCER_SUCCESS, data };
  }

  function failure() {
    return { type: constants.GET_INFLUENCER_FAILURE };
  }
}

export function updateFilter(filter) {
  return (dispatch) => {
    dispatch(update());
    // dispatch(getInfluencers())
  };

  function update() {
    return { type: constants.UPDATE_FILTER, filter };
  }
}

export function updatePayload(payload) {
  return (dispatch) => {
    dispatch(update());
    dispatch(getInfluencers());
  };

  function update() {
    return { type: constants.UPDATE_PAYLOAD, payload };
  }
}

export function getFilteredInfluencer() {
  return (dispatch) => {
    dispatch(update());
    dispatch(getInfluencers());
  };

  function update() {
    return { type: constants.UPDATE_FILTER, filter: { page: 1 } };
  }
}

export function resetFilter() {
  return (dispatch) => {
    dispatch(reset());
  };

  function reset() {
    return { type: constants.RESET_FILTER };
  }
}
