import { Component } from 'react';
import { Grid } from '@material-ui/core';

class NoDataLabel extends Component {
  render() {
    return (
      <Grid container style={{ marginTop: this.props.offsetTop }}>
        <Grid
          item
          md={12}
          style={{
            padding: '7px 0px',
            color: 'gray',
          }}
        >
          No Data Available
        </Grid>
      </Grid>
    );
  }
}

export default NoDataLabel;
