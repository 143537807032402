import { useEffect, useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import { yellow } from '@mui/material/colors';

import Button from 'components/Theme/Elements/Button';

import CategoryContainer from '../CategoryContainer';
import InstagramRectIconButton from '../InstagramRectIconButton';

import InfoCard from './components/InfoCard';
import DetailViewModal from './components/DetailViewModal';
import { getDiscoveryFilterData } from 'api_handlers/discovery';
import { API_HOST } from '../../../../config';

function InfluencerInfoCard({
  id,
  name,
  gender,
  language,
  picture,
  age_group,
  followers,
  engagement_rate,
  avg_views,
  audience_gender,
  url,
  interests = [],
  fair_price,
  paid_post_performance,
  influencer,
  selectedInfluencer,
  setSelectedInfluencer,
}) {
  const classes = useStyles();
  const [detailViewOpened, setDetailViewOpened] = useState(false);

  function handleSelection(e) {
    e.preventDefault();
    setSelectedInfluencer([influencer, ...selectedInfluencer]);
  }

  function handleUnSelection(e) {
    e.preventDefault();
    const influencers = [...selectedInfluencer];
    influencers.splice(influencers.indexOf(influencer), 1);
    console.log('influencers', influencers);
    setSelectedInfluencer([...influencers]);
  }

  function editInfluencer() {
    window.open(`${API_HOST}/admin/discover/iqprofile/${id}/change/`, '_blank');
  }

  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    getDiscoveryFilterData().then((response) => {
      setFilterData(response);
    });
  }, []);

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item className={classes.cardBox}>
        <InfoCard
          name={name}
          gender={gender}
          language={language}
          picture={picture}
          age_group={age_group}
        />
      </Grid>
      <Grid item md={8}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          className={classes.statsContainer}
        >
          <Grid item md={1}>
            <InstagramRectIconButton
              onClick={() => {
                window.open(url, '_blank');
              }}
            />
          </Grid>
          <Grid item md className={classes.moreWidth}>
            <Typography variant="subtitle1">{followers}</Typography>
          </Grid>
          <Grid item md className={classes.moreWidth}>
            <Typography variant="subtitle1">{engagement_rate}</Typography>
          </Grid>
          <Grid item md className={classes.moreWidth}>
            <Typography variant="subtitle1">{avg_views}</Typography>
          </Grid>
          <Grid item md className={classes.moreWidth}>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="subtitle1">{audience_gender.percentage}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">{audience_gender.gender}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md className={classes.moreWidth}>
            <Typography variant="subtitle1">{paid_post_performance}</Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="subtitle1" style={{ fontSize: 14 }}>
              {fair_price}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.categoryContainer}>
          <Grid item md={6}>
            <CategoryContainer
              categories={(Array.isArray(interests) ? interests : []).map(
                (interest) => interest.name,
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={1}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            {selectedInfluencer.includes(influencer) ? (
              <Button
                aria-label="add"
                variant="contained"
                color="primary"
                className={classes.checkButton}
                onClick={handleUnSelection}
              >
                <DoneIcon />
              </Button>
            ) : (
              <Button
                aria-label="add"
                color="primary"
                variant="contained"
                className={classes.addButton}
                onClick={handleSelection}
              >
                <AddIcon />
              </Button>
            )}

            {filterData.is_staff ? (
              <Grid item>
                <Button
                  aria-label="add"
                  color="primary"
                  variant="contained"
                  className={classes.editButton}
                  onClick={editInfluencer}
                >
                  <EditIcon style={{ color: 'yellow', fontSize: 'small' }} />
                </Button>
              </Grid>
            ) : null}
          </Grid>

          <Grid item>
            <Button variant="contained" color="secondary" onClick={() => setDetailViewOpened(true)}>
              View Details
            </Button>
            {detailViewOpened ? (
              <DetailViewModal
                id={id}
                detailViewOpened={detailViewOpened}
                setDetailViewOpened={setDetailViewOpened}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    background: '#EFF0F9',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 224, 0.2), 5px -5px 10px rgba(215, 216, 224, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 224, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 224, 0.5)',
    borderRadius: 10,
    flexGrow: 1,
    height: 150,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
  },
  cardBox: {
    flexGrow: 0,
    maxWidth: '20%',
    flexBasis: '20%',
  },
  statsContainer: {
    paddingRight: 50,
  },
  categoryContainer: {
    marginLeft: '10%',
  },
  addButton: {
    borderRadius: '50%',
    padding: 4,
    minWidth: 0,
  },
  checkButton: {
    borderRadius: '50%',
    padding: 4,
    minWidth: 0,
    background: '#82E900',
    '&.MuiButton-containedPrimary:hover': {
      background: '#179020',
    },
  },
  editButton: {
    borderRadius: '50%',
    padding: 4,
    minWidth: 0,
    marginTop: '5%',
    height: 30,
    width: 30,
  },
  moreWidth: {
    maxWidth: '10.5%',
  },
}));

export default InfluencerInfoCard;
