import { devConfig } from '../config';
import { apiServer } from '.';
import store from '../store';
import { displayColumns } from './index.js';

const axios = require('axios');

let controller = null
let signal = null

export const campaignHandlers = {
  getCampaigns,
  getInfluencerCampaigns,
  addPostLink,
  getCampaignDetails,
  getBasicCampaignDetails,
  getInfluencers,
  getReport,
  updateInfluencerStatus,
  updateShortlistedInfluencer,
  getCampaignInfluencerOptions,
  getInfluencerLanguageOptions,
  getCampaignLanguageOptions,
  getInfluencersData
};

export function getCampaigns() {
  const BASE_CAMPAIGN_URL = `${devConfig.API_HOST}/api/v1/brand/campaign/optimized?page_size=100`;
  return fetch(`${BASE_CAMPAIGN_URL}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
  })
    .then((r) => r.json())
    .then(({ results }) => results);
}

function getInfluencerCampaigns() {
  const BASE_CAMPAIGN_URL = `${devConfig.API_HOST}/api/v1/influencer/campaign/`;
  return fetch(`${BASE_CAMPAIGN_URL}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
  })
    .then((r) => r.json())
    .then(({ results }) => results);
}


function getCampaignDetails(campaign_id) {
  const BASE_CAMPAIGN_URL = `${devConfig.API_HOST}/api/v1/brand/campaign`;
  return fetch(`${BASE_CAMPAIGN_URL}/${campaign_id}/optimized`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
  }).then((r) => r.json());
}

function getBasicCampaignDetails(campaign_id) {
  const BASE_CAMPAIGN_URL = `${devConfig.API_HOST}/api/v1/brand/basic_campaign`;
  return fetch(`${BASE_CAMPAIGN_URL}/${campaign_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
  }).then((r) => r.json());
}

// function getCampaignDetails(campaign_id) {
//     const BASE_CAMPAIGN_URL = `${devConfig.API_HOST}/api/v1/brand/campaign`
//     console.log("header", localStorage.getItem('access'))
//     return axios({
//         method: 'get',
//         url:`${BASE_CAMPAIGN_URL}/${campaign_id}`,
//         headers: {
//             'Authorization': `Bearer Shadab`,
//             'Content-Type': 'application/json',
//         }
//     })
//         .then(r => r.json())
//         // .then(result => result)
// }

function getInfluencers(campaign_id, status = null, page = null, params = {}) {
  const BASE_INFLUENCERS_URL = `${devConfig.API_HOST}/api/v1/brand/influencer_list/${campaign_id}/`;
  if (status) params['status'] = status;
  if (page) params['page'] = page;

  const queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  return fetch(`${BASE_INFLUENCERS_URL}?${queryString}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
  }).then((r) => r.json());
  // .then(({ results }) => results)
}

function getInfluencersData(campaign_id){
  const BASE_URL=`${devConfig.API_HOST}/api/v1/brand/campaign/${campaign_id}/com_list`
  return fetch(BASE_URL,{
    mathod:'GET',
    headers:{
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type':'application/json'
    }
  })
}

async function  updateInfluencerStatus(campaignId,id,status){
  const BASE_URL=`${devConfig.API_HOST}/api/v1/brand/campaign/${campaignId}/com_list`
  const data={'influencer_id':id,"status":status}
  return fetch(BASE_URL,{
    method:'PUT',
    headers:{
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type':'application/json'
    },
    body:JSON.stringify(data)
  })
}


function updateShortlistedInfluencer(campaign_id, shortlisted_id, shortlisted) {
  
  const BASE_INFLUENCERS_URL = `${devConfig.API_HOST}/api/v1/brand/influencer_list/${shortlisted_id}/`;
  return fetch(BASE_INFLUENCERS_URL, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(shortlisted),
  }).then((r) => r.json());
  // .then(({ results }) => results)
}

// function updateInfluencerStatus(influencer_update) {
//   const UPDATE_INFLUENCER_STATUS_URL = `${devConfig.API_HOST}/api/v1/brand/campaign/update_influencer_status/`;
//   return fetch(UPDATE_INFLUENCER_STATUS_URL, {
//     method: 'POST',
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem('access')}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(influencer_update),
//   })
//     .then((r) => r.json())
//     .then((message) => message);
// }

function addPostLink(post_data) {
  const ADD_POST_URL = `${devConfig.API_HOST}/api/v1/influencer/add_post_link`;
  return fetch(ADD_POST_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(post_data),
  })
    .then((r) => r.json())
    .then((message) => message);
}

async function getReport(campaign_id) {
  const BASE_REPORT_URL = `${devConfig.API_HOST}/api/v1/report`;

  await fetch(`${BASE_REPORT_URL}/${campaign_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
  })
    .then((r) => r.json())
    .then(({ result }) => result);
}

function getCampaignInfluencerOptions(campaign_id) {
  return fetch(`${devConfig.API_HOST}/api/v1/brand/campaign/${campaign_id}/influencer-options`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
  }).then((r) => r.json());
}

function getCampaignLanguageOptions(campaign_id) {
  return fetch(`${devConfig.API_HOST}/api/v1/brand/campaign/${campaign_id}/language-options`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
  }).then((r) => r.json());
}

function getInfluencerLanguageOptions(campaign_id){
  return fetch(`${devConfig.API_HOST}/api/v1/brand/campaign/${campaign_id}/youtube-language-options`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    },
  }).then((r) => r.json());

}

function waitForCampaignData() {
  return setTimeout(() => {
    const state = store.getState();
    if (!state.campaign.campaignId) waitForCampaignData();
  }, 200);
}

export async function ShortlistedInfluencerHeaderSummary() {
  await waitForCampaignData();
  const state = store.getState();
  return apiServer
    .get(`campaign/${state.campaign.campaignId}/social_media/influencer-header-summary`)
    .then((response) => {
      return response.data;
    });
}

export function addInfluencerToCampaign(campaignId, data) {
  return apiServer.put(`brand/campaign/${campaignId}/add-influencers`, JSON.stringify(data));
}

export function getYoutubeFeedsColumns() {
  const state = store.getState();
  return displayColumns(`youtube/display_columns/${state.campaign.campaignId}/`, 'GET');
}
export function postYoutubeFeedsColumns(params) {
  const state = store.getState();
  return displayColumns(`youtube/display_columns/${state.campaign.campaignId}/`, 'PUT', params);
}
export function getInstagramFeedsColumns(params) {
  const state = store.getState();
  return displayColumns(`instagram/display_columns/${state.campaign.campaignId}/`, 'GET', params);
}
export function postInstagramFeedsColumns(params) {
  const state = store.getState();
  return displayColumns(`instagram/display_columns/${state.campaign.campaignId}/`, 'PUT', params);
}

export function createCampaign(payload = {}) {
  const BASE_URL = `${devConfig.API_HOST}/api/v1/brand/campaign/`;
  return fetch(BASE_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...payload }),
  });
}

export function getWhatsappChat(influencer, campaign) {
  const BASE_URL = `${devConfig.API_HOST}/api/v1/discover/get-whatsapp-chat?influencer=${influencer}&campaign=${campaign}`;
  if (controller) {
    controller.abort();
  }
  controller = new AbortController()
  signal = controller.signal
  return fetch(BASE_URL, {
    method: 'GET',
    signal: signal,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
  });
}