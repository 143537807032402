import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, Chip, CardMedia } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clock from '../../assets/clock.png';

const styles = (theme) => ({
  name: {
    fontFamily: "'Lato', sans-serif",
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '17px',
  },
  description: {
    fontFamily: "'Lato', sans-serif",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '30px',
    lineHeight: '175%',
    color: '#555555',
  },
});

class ComingSoon extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Card
        style={{
          cursor: 'pointer',
          margin: 20,
          borderRadius: 5,
        }}
      >
        <CardContent style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Grid container justify="center" alignItems="center">
            <Grid item md={12}>
              <Grid className={`${classes.description}`} justify="center" alignItems="center">
                <CardMedia>Hold on! Something exciting is coming up!</CardMedia>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default withRouter(withStyles(styles)(ComingSoon));
