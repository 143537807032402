import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Menu, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import {
  CheckBoxOutlineBlank as UncheckedIcon,
  CheckBox as CheckedIcon
} from '@material-ui/icons';
import TemplateModal from './templateModal';
import { sendWhatsappMessage } from 'api_handlers/discovery';

const template_dict = {
    "HXf2c8659547d7af331fe5d11761c50320": "Hello Influencer,\n\nWe have an exclusive opportunity for you in Upcoming Campaign by Influencer.in. Let's team up to create something amazing. Discover the campaign details at https://product.influencer.in/. Excited to discuss further!\n\nWarm regards,\nTeam Influencer",
    "HX8340e6ffec177e070750a1897792119c": "Hi Influencer,\n\nGreat news! We are thrilled to inform you that you've been shortlisted for our upcoming campaign. Your unique style and audience engagement stood out, and we believe you'd be an excellent fit for this project. We'll be in touch soon with more details about the campaign and the next steps.\n\nCongratulations, and thank you for your interest!\nTeam Influencer.in",
    "HX20ec9cff48ea96ae85171e0f5c680119": "Hey Influencer,\n\nCongratulations on being shortlisted for our upcoming campaign with Influencer.in! We're excited to have you on board for the Upcoming Campaign. It's time for product selection. Please visit the campaign page at https://product.influencer.in/. to choose the products you'd like to feature in your content.\n\nIf you have any questions or need assistance, feel free to reach out to us. We can't wait to see the magic you'll create with our products!\n\nBest regards,\nTeam Influencer.in",
    "HXd8354ef6be43f922ced13776313dfcb3": "Hi Influencer,\n\nWe're delighted to inform you that your selected products for the Upcoming Campaign have been dispatched and are on their way to you. You should expect delivery within 2 Days.\n\nIf you have any concerns or questions regarding the delivery, please don't hesitate to reach out to us. We look forward to seeing your amazing content!\n\nBest regards,\nTeam Influencer.in",
    "HXbc01316b0bbf37c765035414b8301706": "Hi Influencer,\n\nWe hope you're doing well. It's time for content submission for the Upcoming Campaign. We can't wait to see what you've created with our products. Please send us the content before end of the campaign, including Specific Requirements.\n\nYou can submit your content through upload section. If you encounter any issues or have questions, don't hesitate to reach out to us. Thank you for your hard work and dedication to the campaign.\n\nBest regards,\nTeam Influencer.in",
    "HXb6613d2eb00572fe1696bf636d0b054f": "Hi Influencer,\n\nWe're thrilled to inform you that your submitted content for the Upcoming Campaign has been approved. We love what you've created, and we believe your audience will too. You are now clear to publish your content on your chosen platform.\n\nPlease go ahead and schedule your publication, making sure it aligns with the agreed-upon timeline. If you have any questions or need any additional support, please don't hesitate to reach out.\n\nThank you for your hard work and collaboration!\nTeam Influencer.in",
    "HX5df25eeed65f7dae52910656cf3a9986": "Hi Influencer,\n\nWe're pleased to inform you that your payment for the Upcoming Campaign has been processed. You should receive the payment in your account within 2-3 Working Days. We appreciate your hard work and dedication to the campaign.\n\nOnce you've received the payment, please confirm it by replying to this message or sending a separate confirmation email. If you have any questions or concerns, don't hesitate to reach out.\n\nThank you for your collaboration!\nTeam Influencer.in"
  }

const NavigationBar = ({getStatus, checked, campaignID, count}) => {
    const labels = ['All Influencers', 'Shortlisted Influencers', 'Approved Influencers', 'Content Pending', 'Content Submitted', 'Content Approved','Content Rejected'];
    const [selectedLabel, setSelectedLabel] = useState('All Influencers');
    const [anchorEl, setAnchorEl] = useState(null);
    const [templatesId, setTemplatesId] = useState('');
    const [showTemplate, setShowTemplate] = useState(false)
    const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
    const addCount = {
        'Shortlisted Influencers': "pending",
        'Approved Influencers': "approved",
        'Content Pending': "content_pending",
        'Content Submitted': "content_submitted",
        'Content Approved': "content_approved",
        'Content Rejected': "content_rejected",
    }
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTemplateChange = (event) => {
        setTemplatesId(event.target.value);
    };

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLabelClick = async (label) => {
        if(label == 'All Influencers'){
            getStatus(null)
        }
        if(label == 'Shortlisted Influencers'){
            getStatus('pending')
        }
        if(label == 'Approved Influencers'){
            getStatus('approved')
        }
        if(label == 'Content Pending'){
            getStatus('content_pending')
        }
        if(label == 'Content Submitted'){
            getStatus('content_submitted')
        }
        if(label == 'Content Approved'){
            getStatus('content_approved')
        }
        if(label == 'Content Rejected'){
            getStatus('content_rejected')
        }
        setSelectedLabel(label);

    };

    const handleShowLayouts = () => {
        if(templatesId){
            setShowTemplate(true)
            setIsTemplateModalOpen(true);
            handleClose();
            console.log('Show Layouts button clicked');
        }
    };

    const isLabelSelected = (label) => selectedLabel === label;

    const sendBroadCastMessage = () => {
        if (templatesId) {
            checked.map(each_id=>{
                sendWhatsappMessage('+919582489634', templatesId, each_id, campaignID, template_dict[templatesId].replace(/\n/g, "<br />"));
            })
        }
        setShowTemplate(false);
    }

    useEffect(() => {
        getStatus(null);
      }, []);

    return (
        <Grid container spacing={2} alignItems="center">
        <Grid item xs={3} style={{paddingRight:'120px'}}>
            <Typography variant="subtitle1">
            Create Broadcast
            </Typography>
            <Button variant="contained" size="small" onClick={handleButtonClick}>
            Select Template
            </Button>
        </Grid>

        <Grid item xs={9} style={{ display: 'flex', alignItems: 'center' }}>
            {labels.map((label, index) => (
                <React.Fragment key={index}>
                <Typography
                    variant="subtitle1"
                    onClick={() => handleLabelClick(label)}
                    style={{
                    fontWeight: isLabelSelected(label) ? 'bold' : 'normal',
                    textDecoration: isLabelSelected(label) ? 'underline' : 'none',
                    cursor: 'pointer',
                    marginRight: '12px',
                    fontSize: '15px',
                    }}
                >
                    {label}({label === 'All Influencers' ? (count['total'] || 0) : (count[addCount[label]] || 0)})
                </Typography>
                </React.Fragment>
            ))}
            </Grid>
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{minWidth: '250px'}}
        >
        <MenuItem>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Template</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={templatesId}
              onChange={handleTemplateChange}
              label="Select Template"
              style={{minWidth: '250px'}}
            >
              <MenuItem value="HXf2c8659547d7af331fe5d11761c50320">Campaign reachout</MenuItem>
              <MenuItem value="HX8340e6ffec177e070750a1897792119c">Shortlisted</MenuItem>
              <MenuItem value="HX20ec9cff48ea96ae85171e0f5c680119">Product selection</MenuItem>
              <MenuItem value="HXd8354ef6be43f922ced13776313dfcb3">Product delivery confirmation</MenuItem>
              <MenuItem value="HXbc01316b0bbf37c765035414b8301706">Content submission Deadline</MenuItem>
              <MenuItem value="HXb6613d2eb00572fe1696bf636d0b054f">Content approval</MenuItem>
              <MenuItem value="HX5df25eeed65f7dae52910656cf3a9986">Payment Confirmation</MenuItem>
            </Select>
          </FormControl>
        </MenuItem>
        <MenuItem onClick={handleShowLayouts}>Apply Template</MenuItem>
      </Menu>
      {showTemplate? (
        <TemplateModal
            isOpen={isTemplateModalOpen}
            onClose={() => setIsTemplateModalOpen(false)}
            templateId={templatesId}
            templateDict={template_dict}
            sendBroadCastMessage={sendBroadCastMessage}
        />
      ): null}
        </Grid>
  );
};

export default NavigationBar;
