import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';

import Loading from '../../components/Common/Loading/Loading';
import ErrorDisplay from '../influencers/forms/ErrorDisplay';

import { colorConstants } from '../../constants/colors';
import { authHandlers } from '../../api_handlers/auth';

class BrandSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      email: null,
      website_url: null,
      password: null,
      confirmedPassword: null,
      passwordOk: true,
      user_type: 'brand',
      formErrors: {},
      is_form_error: false,
    };
  }

  signUp(data) {
    const resp = authHandlers.signUp(data);
    resp.then(async (response) => {
      if ([200, 201].includes(response.status)) {
        const userData = await response.json();
        localStorage.setItem('access', userData.access);
        localStorage.setItem('refresh', userData.refresh);
        const user = {
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          user_type: userData.user_type,
          influencer: userData.influencer,
          brand: userData.brand,
        };
        localStorage.setItem('user', JSON.stringify(user));
        this.props.history.push('/brand/campaign');
      } else if ([400, 401, 402, 403].includes(response.status)) {
        const errors = await response.json();
        this.setState({
          formErrors: errors,
          is_form_error: true,
        });
      }
    });
  }

  submitForm = (e) => {
    e.preventDefault();
    if (this.state.password === this.state.confirmedPassword) {
      this.setState({ passwordOk: true });
      const { passwordOk, confirmedPassword, name, website_url, ...apiData } = this.state;
      apiData.brand = {
        name,
        website_url,
      };
      this.signUp(apiData);
    } else this.setState({ passwordOk: false });
  };

  render() {
    return (
      <>
        {this.props.loading && <Loading />}
        <Container maxWidth="sm">
          {this.state.is_form_error && (
            <ErrorDisplay errors={this.state.formErrors} backendKeyMap={{}} />
          )}
          <form onSubmit={this.submitForm}>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Brand Name"
              name="name"
              autoComplete="text"
              onChange={(e) => this.setState({ name: e.target.value })}
              autoFocus
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Official Email ID"
              name="email"
              autoComplete="email"
              onChange={(e) => this.setState({ email: e.target.value })}
            />
            <TextField
              type={'url'}
              id={'websiteUrl'}
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="website_url"
              label="Website URL"
              onChange={(e) => this.setState({ website_url: e.target.value })}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Confirm Password"
              type="password"
              autoComplete="current-password"
              onChange={(e) => this.setState({ confirmedPassword: e.target.value })}
            />
            {!this.state.passwordOk && (
              <p
                style={{
                  color: 'red',
                }}
              >
                Passwords do not match.
              </p>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{
                backgroundColor: colorConstants.LOGIN_BUTTON,
                marginBottom: '2vw',
                marginTop: '2vw',
                color: 'white',
              }}
            >
              Sign Up
            </Button>
          </form>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

const actionCreators = {
  // signUp: authActions.signUp
};

export default connect(mapStateToProps, actionCreators)(withRouter(BrandSignUp));
