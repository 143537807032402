import { useState, useEffect } from 'react';
import { Typography, Grid, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import InstagramIconButton from 'components/Discovery/components/InstagramIconButton';
import YouTubeIconButton from 'components/Discovery/components/YouTubeIconButton';

import BasicInfo from './components/BasicInfo';
import BasicStats from './components/BasicStats';
import InfluencerDetails from './components/InfluencerDetails';
import AudienceDetails from './components/AudienceDetails';
import PopularPosts from './components/PopularPosts';
import CommercialPosts from './components/CommercialPosts/CommercialPosts';
import RecentPosts from './components/RecentPosts/RecentPosts';
import TopReels from './components/TopReels/TopReels';
import RecentReels from './components/RecentReels/RecentReels';
import TopHashTags from './components/TopHashTags/TopHashTags';
import RelevantHashTags from './components/RelevantHashTags/RelevantHashTags';
import { getDiscoveryInfluencersDetails,} from 'api_handlers/discovery';
function DetailViewModal({ id, detailViewOpened = false, setDetailViewOpened, is_staff }) {
  const classes = useStyles();
  const [influenerDetail, setInfluenerDetail] = useState({});
  const [isClicked, setIsclicked] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [updateStatus, setUpdateStatus] = useState('');

  const fetchDetails = async () => {
    getDiscoveryInfluencersDetails(id).then((response) => {
      setInfluenerDetail(response);
      console.log('Response Update Status: ', response?.update_status);
      if (response?.update_status === 'updating') {
        setUpdateStatus('updating');
      }
      if (response?.update_status === 'updated') {
        setUpdateStatus('updated');
      }
    });
  };


  useEffect(() => {
    // getDiscoveryInfluencersDetails(id).then((response) => {
    //   setInfluenerDetail(response);

    // });
    fetchDetails();
  }, [isClicked]);

  return (
    <Modal
      open={detailViewOpened}
      onClose={() => setDetailViewOpened(false)}
      className={classes.base}
    >
      <Grid container className={classes.root}>
        <Grid item md={12}>
          <Grid container className={classes.header}>
            <Grid item md={3}>
              <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                  <InstagramIconButton selected />
                </Grid>
                <Grid item>
                  <YouTubeIconButton />
                </Grid>
              </Grid>
            </Grid>
            <Grid
                item
                className={classes.closeContainer}
                onClick={() => setDetailViewOpened(false)}
              >
                <Grid container>
                  <Grid item>
                    <Typography variant="h3">Close</Typography>
                  </Grid>
                  <Grid item>
                    <HighlightOffIcon className={classes.closeIcon} />
                  </Grid>
                </Grid>
              </Grid>
          </Grid>
          <Grid container className={classes.body} spacing={1}>
            <Grid item md={12}>
              <Grid container spacing={4}>
                <Grid item md={12}>
                  <BasicInfo
                    {...influenerDetail}
                    is_staff={is_staff}
                    isClicked={(val) => setIsclicked(val)}
                    updateStatus={updateStatus === 'updating' ? true : false}
                  />
                </Grid>
                <Grid item md={12}>
                  <BasicStats {...influenerDetail} />
                </Grid>
                <Grid item md={12}>
                  <InfluencerDetails {...influenerDetail} />
                </Grid>
                <Grid item md={12}>
                  <AudienceDetails {...influenerDetail} is_staff={is_staff} />
                </Grid>
                <Grid item md={12}>
                  <PopularPosts {...influenerDetail} />
                </Grid>
                {influenerDetail.youtube === null ? (
                  <Grid item md={12}>
                    <CommercialPosts {...influenerDetail} />
                  </Grid>
                ) : null}
                <Grid item md={12}>
                  <RecentPosts {...influenerDetail} />
                </Grid>
                {influenerDetail.youtube === null ? (
                  <Grid item md={12}>
                    <TopReels {...influenerDetail} />
                  </Grid>
                ) : null}
                {influenerDetail.youtube === null ? (
                  <Grid item md={12}>
                    <RecentReels {...influenerDetail} />
                  </Grid>
                ) : null}
                {influenerDetail.youtube === null ? (
                  <Grid item md={12}>
                    <TopHashTags {...influenerDetail} />
                  </Grid>
                ) : null}
                <Grid item md={12}>
                  <RelevantHashTags {...influenerDetail} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

const useStyles = makeStyles(() => ({
  base: {
    background: 'white',
    height: 700,
    width: 1200,
    margin: 'auto',
    marginTop: 50,
  },
  root: {
    background: 'white',
    filter: 'drop-shadow(5px 8px 15px rgba(171, 171, 171, 0.25))',
    height: 700,
    width: 1200,
    borderRadius: 10,
    padding: 45,
    overflowY: 'scroll',
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 40,
  },

  closeContainer: {
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.9) 0%, #FBFCFF 100%)',
    boxShadow:
      '-1px 1px 2px rgba(215, 216, 220, 0.2), 1px -1px 2px rgba(215, 216, 220, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: 7,
    height: 30,
    cursor: 'pointer',
  },
  closeIcon: {
    fontSize: 14,
    color: '#757575',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 6,
  },
}));

export default DetailViewModal;
