import { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Link, Grid } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

import YouTubeLogo from '../../assets/youtube-logo.png';

const useStyles = makeStyles({
  headerCell: {
    background: '#C4C4C4',
    fontWeight: 600,
    textAlign: 'center',
  },
  table: {
    minWidth: 650,
  },
  cell: {
    textAlign: 'center',
  },
});

const styles = (theme) => ({
  headerCell: {
    background: '#C4C4C4',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 12,
  },
  table: {
    minWidth: 650,
  },
  cell: {
    textAlign: 'center',
    fontSize: 12,
  },
  link: {
    textDecoration: 'none',
    color: '#333',
    fontStyle: 'italic',
    fontWeight: 700,
  },
});

class InfluencerTabularList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  ellipsis(text) {
    if (text.length > 80) return text.slice(0, 77) + '...';
    return text;
  }

  render() {
    const { classes } = this.props;
    return (
      <TableContainer component={Paper}>
        <Table stickyHeader className={classes.table} aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>Name</TableCell>
              {this.props.influencers.length > 0 &&
              this.props.influencers[0].campaign.brand == 78 ? (
                <TableCell className={classes.headerCell}>Email</TableCell>
              ) : (
                ''
              )}
              {this.props.influencers.length > 0 &&
              this.props.influencers[0].campaign.brand == 78 ? (
                <TableCell className={classes.headerCell}>Phone Number</TableCell>
              ) : (
                ''
              )}
              <TableCell className={classes.headerCell}>Subscribers</TableCell>
              <TableCell className={classes.headerCell}>Created on</TableCell>
              <TableCell className={classes.headerCell}>Video Count</TableCell>
              <TableCell className={classes.headerCell}>Likes</TableCell>
              <TableCell className={classes.headerCell}>View</TableCell>
              <TableCell className={classes.headerCell}>Comment</TableCell>
              <TableCell className={classes.headerCell}>Engagement Rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.influencers.map((influencer) => (
              <TableRow key={influencer.influencer.name}>
                <TableCell className={classes.cell}>
                  <Grid container spacing={2}>
                    <Grid item md="auto">
                      <a
                        _blank="true"
                        className={classes.link}
                        href={
                          '/influencer/campaigns/all/feeds/' + influencer.influencer.id + '/youtube'
                        }
                        YouTubeLogo
                      >
                        {influencer.influencer.name}
                      </a>
                    </Grid>
                    <Grid item md={1}>
                      <a href={influencer.youtube_link}>
                        <img src={YouTubeLogo} height={10} />
                      </a>
                    </Grid>
                  </Grid>
                </TableCell>
                {influencer.campaign.brand == 78 ? (
                  <TableCell className={classes.cell}>
                    {influencer.user_email ? influencer.user_email : 'N/A'}
                  </TableCell>
                ) : (
                  ''
                )}
                {influencer.campaign.brand == 78 ? (
                  <TableCell className={classes.cell}>
                    {influencer.influencer.phone_number
                      ? influencer.influencer.phone_number
                      : 'N/A'}
                  </TableCell>
                ) : (
                  ''
                )}
                <TableCell className={classes.cell}>
                  {influencer.youtube_campaign_detail.youtube_subscribers}
                </TableCell>
                <TableCell className={classes.cell}>
                  {influencer.influencer.date_joined
                    ? moment(influencer.influencer.date_joined).format('DD-MM-YYYY')
                    : 'N/A'}
                </TableCell>
                <TableCell className={classes.cell}>
                  {influencer.youtube_campaign_detail.youtube_total_video}
                </TableCell>
                <TableCell className={classes.cell}>
                  {influencer.youtube_campaign_detail.youtube_total_likes}
                </TableCell>
                <TableCell className={classes.cell}>
                  {influencer.youtube_campaign_detail.youtube_total_views}
                </TableCell>
                <TableCell className={classes.cell}>
                  {influencer.youtube_campaign_detail.youtube_total_comments}
                </TableCell>
                <TableCell className={classes.cell}>
                  {influencer.youtube_campaign_detail.youtube_engagement_rate}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default withStyles(styles, {})(InfluencerTabularList);
