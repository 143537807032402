import { devConfig } from '../config';
import { getCookie } from '../components/utils';
import { apiServer } from './index';

const BASE_URL = `${devConfig.API_HOST}/api/v1/users`;
const headers = {
  'Content-Type': 'application/json',
};
if (localStorage.getItem('access')) {
  headers['Authorization'] = `Bearer ${localStorage.getItem('access')}`;
}

function login(userData) {
  return fetch(`${BASE_URL}/login/`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(userData),
  });
  // .then(r => r.json())
  // .then(({ access, refresh, success, influencer, brand, user_type, email }) => {
  //     if (access && refresh && success) {
  //         localStorage.setItem('access', access)
  //         localStorage.setItem('refresh', refresh)
  //         const user = {
  //             email,
  //             user_type,
  //             influencer,
  //             brand
  //         }
  //         localStorage.setItem('user', JSON.stringify(user))
  //         return user
  //     }
  //     return null
  // })
}

function signUp(userData) {
  userData['nomination_id'] = getCookie('nomination_form_id');
  return fetch(`${BASE_URL}/signup`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(userData),
  });
  // .then(r => r.json())
  // .then(({ access, refresh, success, influencer, brand, user_type, email }) => {
  //     if (access && refresh && success) {
  //         localStorage.setItem('access', access)
  //         localStorage.setItem('refresh', refresh)
  //         const user = {
  //             email,
  //             user_type,
  //             influencer,
  //             brand
  //         }
  //         localStorage.setItem('user', JSON.stringify(user))
  //         return user
  //     }
  //     return null
  // })
}

function facebookLogin(data) {
  data['nomination_id'] = getCookie('nomination_form_id');
  return fetch(`${BASE_URL}/facebook/registration`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  })
    .catch((error) => {})
    .then((r) => r.json())
    .then(
      ({
        access,
        refresh,
        success,
        influencer,
        brand,
        user_type,
        email,
        first_name,
        last_name,
      }) => {
        if (access && refresh && success) {
          localStorage.setItem('access', access);
          localStorage.setItem('refresh', refresh);
          const user = {
            first_name,
            last_name,
            email,
            user_type,
            influencer,
            brand,
          };
          localStorage.setItem('user', JSON.stringify(user));
          return user;
        }
      },
    );
}

function youtubeLogin(data) {
  data['nomination_id'] = getCookie('nomination_form_id');
  return fetch(`${BASE_URL}/youtube/registration`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  })
    .then((r) => r.json())
    .then(
      ({
        access,
        refresh,
        success,
        influencer,
        brand,
        user_type,
        email,
        first_name,
        last_name,
      }) => {
        if (access && refresh && success) {
          localStorage.setItem('access', access);
          localStorage.setItem('refresh', refresh);
          const user = {
            first_name,
            last_name,
            email,
            user_type,
            influencer,
            brand,
          };
          localStorage.setItem('user', JSON.stringify(user));
          return user;
        }
      },
    );
}

export function brandFacebookConnect(data) {
  return apiServer.post('users/brand/facebook/connect', JSON.stringify(data));
}

export function brandYoutubeConnect(data) {
  return apiServer.post('users/brand/youtube/connect', JSON.stringify(data));
}

export function getCurrentUser() {
  return apiServer.get(`users/current_user`)
}

function youtubeBrandLogin(data) {
  data['nomination_id'] = getCookie('nomination_form_id');
  return fetch(`${BASE_URL}/brand/youtube/registration`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  })
    .then((r) => r.json())
    .then(
      ({
        access,
        refresh,
        success,
        influencer,
        brand,
        user_type,
        email,
        first_name,
        last_name,
        error
      }) => {
        if (access && refresh && success) {
          localStorage.setItem('access', access);
          localStorage.setItem('refresh', refresh);
          const user = {
            first_name,
            last_name,
            email,
            user_type,
            influencer,
            brand,
          };
          localStorage.setItem('user', JSON.stringify(user));
          return { user };
        } else {
          return { error };
        }
      },
    );
}

export function resetPassword(data) {
  return fetch(`${BASE_URL}/password-reset/`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  })
}

export function confirmResetPassword(uid, token, data) {
  return fetch(`${BASE_URL}/password-reset-confirm/${uid}/${token}/`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  })
}

export const authHandlers = {
  login,
  signUp,
  facebookLogin,
  youtubeLogin,
  youtubeBrandLogin,
  resetPassword,
  confirmResetPassword
};
