import { useEffect, useState } from 'react';
import { Grid, Drawer, Button as BaseButton, ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';

import PlatformFilter from './components/PlatformFilter';
import FollowersFilter from './components/FollowersFilter';
import CategoryFilter from './components/CategoryFilter';
import LocationFilter from './components/LocationFilter';
import EngagementRateFilter from './components/EngagementRateFilter';
import AudienceViewsFilter from './components/AudienceViewsFilter';
import GenderFilter from './components/GenderFilter';
import AvgEngagementFilter from './components/AvgEngagementFilter';
import LanguageFilter from './components/LanguageFilter';
import PPPFilter from './components/PPPFilter';
import AudienceLocationFilter from './components/AudienceLocationFilter';
import AudienceInterestFilter from './components/AudienceInterestFilter';

import AudienceGenderSplitFilter from './components/AudienceGenderSplitFilter';
import AudienceAgeGroupFilter from './components/AudienceAgeGroupFilter';
import SocialLoginAccessFilter from './components/SocialLoginAccessFilter';
import IsPhoneFilter from './components/IsPhoneFilter';
import IsEmailFilter from './components/IsEmailFilter';
import AudienceCredibilityFilter from './components/AudienceCredibilityFilter';
import AudienceReachabilityFilter from './components/AudienceReachabilityFilter';

import { getDiscoveryFilterData } from 'api_handlers/discovery';

function FilterDrawer({
  open,
  closeDrawer,
  filters,
  setFilters,
  getFilteredInfluencer,
  resetFilter,
}) {
  const classes = useStyles();
  const [filterData, setFilterData] = useState({});

  function handleApply() {
    getFilteredInfluencer();
  }

  useEffect(() => {
    getDiscoveryFilterData().then((response) => {
      setFilterData(response);
    });
  }, []);

  return (
    <Drawer
      className={classes.base}
      variant="temporary"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Grid container className={classes.filterContainer} spacing={5}>
        <Grid item md={10}>
          <BaseButton
            variant="contained"
            size="large"
            className={classes.filterButton}
            startIcon={<FilterAltIcon className={classes.filterButtonIcon} />}
            endIcon={<CloseIcon className={classes.closeButtonIcon} />}
            onClick={() => closeDrawer()}
          >
            Filter
          </BaseButton>
        </Grid>
        <Grid item md={10}>
          <PlatformFilter value={filters.platform} setFilters={setFilters} />
        </Grid>
        <Grid item md={10}>
          <FollowersFilter value={filters.followers} setFilters={setFilters} />
        </Grid>
        <Grid item md={10}>
          <CategoryFilter
            value={filters.category}
            setFilters={setFilters}
            categories={filterData.audience_interests || []}
          />
        </Grid>
        <Grid item md={10}>
          <EngagementRateFilter value={filters.engagementRate} setFilters={setFilters} />
        </Grid>
        <Grid item md={10}>
          <LocationFilter value={filters.location} setFilters={setFilters} />
        </Grid>
        <Grid item md={10}>
          <AudienceViewsFilter value={filters.audienceView} setFilters={setFilters} />
        </Grid>
        <Grid item md={10}>
          <GenderFilter value={filters.gender} setFilters={setFilters} />
        </Grid>
        <Grid item md={10}>
          <AvgEngagementFilter value={filters.avgEngagement} setFilters={setFilters} />
        </Grid>
        <Grid item md={10}>
          <LanguageFilter value={filters.language} setFilters={setFilters} />
        </Grid>
        <Grid item md={10}>
          <PPPFilter value={filters.ppp} setFilters={setFilters} />
        </Grid>
        <Grid item md={10}>
          <AudienceLocationFilter
            value={filters.audienceLocation}
            setFilters={setFilters}
            locations={filterData.audience_locations || []}
          />
        </Grid>
        <Grid item md={10}>
          <AudienceInterestFilter
            value={filters.audienceInterest}
            setFilters={setFilters}
            interests={filterData.audience_interests || []}
          />
        </Grid>
        <Grid item md={10}>
          <AudienceGenderSplitFilter value={filters.audienceGender} setFilters={setFilters} />
        </Grid>
        <Grid item md={10}>
          <AudienceAgeGroupFilter value={filters.audienceAgeGroup} setFilters={setFilters} />
        </Grid>
        {filterData.is_staff ? (
          <Grid item md={10}>
            <SocialLoginAccessFilter value={filters.isSocialLogin} setFilters={setFilters} />
          </Grid>
        ) : null}
        {filterData.is_staff ? (
          <Grid item md={10}>
            <IsPhoneFilter value={filters.isPhone} setFilters={setFilters} />
          </Grid>
        ) : null}
        {/* {filterData.is_staff ? <Grid item md={10}>
                    <IsEmailFilter
                        value={filters.isEmail}
                        setFilters={setFilters}
                    />
                </Grid> : null} */}
        {filterData.is_staff ? (
          <Grid item md={10}>
            <AudienceCredibilityFilter
              value={filters.audienceCredibility}
              setFilters={setFilters}
            />
          </Grid>
        ) : null}
        {filterData.is_staff ? (
          <Grid item md={10}>
            <AudienceReachabilityFilter
              value={filters.audienceReachability}
              setFilters={setFilters}
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid container className={classes.buttonContainer}>
        <ButtonGroup disableElevation variant="contained" style={{ margin: 'auto' }}>
          <BaseButton onClick={() => resetFilter()}>RESET</BaseButton>
          <BaseButton
            color="primary"
            onClick={() => {
              closeDrawer();
              handleApply();
            }}
          >
            APPLY
          </BaseButton>
        </ButtonGroup>
      </Grid>
    </Drawer>
  );
}

const useStyles = makeStyles((theme) => ({
  base: {},
  filterContainer: {
    height: '90vh',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    overflowY: 'scroll',
  },
  drawerPaper: {
    width: 300,
    background: theme.palette.background.default,
  },
  filterButton: {
    background:
      'linear-gradient(99.05deg, #FEBD1C 24.26%, rgba(242, 63, 96, 0.77) 74.68%, rgba(239, 26, 116, 0.93) 89.51%)',
    border: '2px solid #FFFFFF',
    borderRadius: 10,
    color: 'white',
    paddingTop: 5,
    paddingBottom: 5,
  },
  closeButtonIcon: {
    marginLeft: 70,
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 20,
  },
}));

export default FilterDrawer;
