import React from 'react';
import { withRouter, Route, Switch, NavLink } from 'react-router-dom';
import { Grid, Container, Divider, Button } from '@material-ui/core';

import PersonalDetailForm from '../forms/MobilePersonalDetails';
import AboutYourself from '../forms/MobileAboutYourself';
import BackArrowLogo from '../../../assets/back_arrow.png';

class Personal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  async submitForm() {
    await this.personalDetailForm.submitForm();
    await this.aboutYourselfForm.submitForm();
  }

  render() {
    return (
      <Grid style={{ backgroundColor: 'white', fontSize: '12px' }}>
        <Grid
          container
          style={{
            border: '1px solid #DCDCDC',
            padding: '15px',
            width: '100%',
          }}
        >
          <Grid item md={12}>
            <Grid
              container
              // spacing={12}
              direction="row"
              alignItems="center"
              style={{
                justifyContent: 'space-between',
              }}
            >
              <Grid item md={12}>
                <NavLink
                  to="/influencer/settings"
                  style={{
                    textDecoration: 'none',
                    lineHeight: '17px',
                    margin: '5px',
                    color: 'black',
                  }}
                >
                  <span>
                    <img src={BackArrowLogo} style={{ width: 20 }} />
                  </span>
                  &nbsp;&nbsp;
                  <span
                    style={{
                      fontSize: 15,
                    }}
                  >
                    Personal Information
                  </span>
                </NavLink>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={5}>
                <Divider
                  style={{
                    background:
                      'linear-gradient(90deg, rgba(171,171,171,1) 0%, rgba(176,176,176,0) 100%)',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 30, paddingLeft: 16, paddingRight: 16 }}>
          <Grid item md={12} style={{ width: '100%' }}>
            <PersonalDetailForm
              onRef={(ref) => (this.personalDetailForm = ref)}
              fieldsToShow={[
                'profile_pic',
                'name',
                'dob',
                'gender',
                'addressLine',
                'state/city',
                'pincode',
              ]}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            marginTop: 20,
            marginBottom: 10,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <Grid item>
            <span
              style={{
                fontSize: 15,
              }}
            >
              About Yourself
            </span>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            marginBottom: 30,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <Grid item md={5}>
            <Divider
              style={{
                background:
                  'linear-gradient(90deg, rgba(171,171,171,1) 0%, rgba(176,176,176,0) 100%)',
              }}
            />
          </Grid>
        </Grid>
        <Grid container style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Grid item md={8}>
            <AboutYourself onRef={(ref) => (this.aboutYourselfForm = ref)} />
          </Grid>
        </Grid>
        <Grid item md={12} style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Button
            onClick={() => {
              this.submitForm();
            }}
            style={{
              background: '#1E9CB2',
              color: 'white',
              padding: '5px 20px',
              marginBottom: '70px',
              width: '100%',
            }}
          >
            Save & Continue
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Personal);
