import { categories } from "components/Discovery/components/ActionBar/components/FilterDrawer/components/AudienceInterestFilter/categories";

export const constants = {
  UPDATE_PAGE: 'UPDATE_PAGE',
  UPDATE_PAYLOAD: 'UPDATE_PAYLOAD',
  UPDATE_INFLUENCERS: 'UPDATE_INFLUENCERS',
  TOGGLE_DRAWER_OPEN: 'TOGGLE_DRAWER_OPEN',
  SET_LOADING: 'SET_LOADING',
  TOTAL_INFLUENCERS: 'TOTAL_INFLUENCERS',
  UPDATE_SELECTED_INFLUENCERS: 'UPDATE_SELECTED_INFLUENCERS',
  UPDATE_USER: 'UPDATE_USER',
  SHOW_PREMIUM_FEATURE_DIALOG: 'SHOW_PREMIUM_FEATURE_DIALOG',
  UPDATE_PREDICTION_STATUS: 'UPDATE_PREDICTION_STATUS',
  UPDATE_PREDICTIONS: 'UPDATE_PREDICTIONS',
  ADD_NEW_INFLUENCER: 'ADD_NEW_INFLUENCER',
  UPDATE_NEW_INFLUENCER_SEARCH: 'UPDATE_NEW_INFLUENCER_SEARCH',
  UPDATE_AUTO_COMPLETE_VALUES: 'UPDATE_AUTO_COMPLETE_VALUES',
  PAYLOAD_PATH_MAPPING: {
    ids: "searchedSelectedInfluencerIds",
    posts: "advance-filters.keyword_filter",
    bio: "advance-filters.bio_keyword",
    category: "campaign-level-requirements.category",
    categories: "campaign-level-requirements.category",
    platform: "creator-level-requirements.platform",
    locations: "advance-filters.influencer_city",
    gender: "advance-filters.gender",
    budget: "campaign-level-requirements.budget",
    maxPricePerCreator: "campaign-level-requirements.price-per-creator",
    engagementRate: "advance-filters.engagementRate",
    engagedUsers: "advance-filters.avg_engagement",
    views: "advance-filters.avg_views",
    ageGroup: "advance-filters.influencerAgeGroup",
    language: "advance-filters.language",
    deliverables: "advance-filters.deliverables",
    country: "advance-filters.influencer_country",
    state: "advance-filters.influencer_state",
    // city: "advance-filters.influencer_city",
    region: "advance-filters.region",
    audienceCredibility: "advance-filters.audienceCredibility",
    audienceAgeGroup: "creator-level-requirements.audienceAgeGroup",
    audienceInterest: "advance-filters.audienceInterest",
    audienceLocation: "creator-level-requirements.audienceLocation",
    audienceGenderSplit: "creator-level-requirements.audienceGender",
    isEmail: "advance-filters.isEmail",
    isPhone: "advance-filters.isPhone",
    isAudienceData: "advance-filters.is_audience_data",
    influencerFinalPrice: "advance-filters.influencer_final_price",
    brandFinalPrice: "advance-filters.brand_final_price",
    sortBy: "advance-filters.sortBy",
    megaBudget: "creator-level-requirements.creator-type.mega",
    macroBudget: "creator-level-requirements.creator-type.macro",
    microBudget: "creator-level-requirements.creator-type.micro",
    nanoBudget: "creator-level-requirements.creator-type.nano",
    creatorType: "advance-filters.creator_type",
  },
  DEFAULT_PAYLOAD: {
    "campaign-level-requirements": {},
    "creator-level-requirements": {
      "platform": "instagram"
    },
    "advance-filters": {
      "sortOrder": "desc",
    },
    "setReset": false
  }
}