import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DownArrow from 'assets/planner/down-arrow.png';
import DownArrowActive from 'assets/planner/down-arrow-active.png';

function ParticularFilter({ selected, children }) {
  const classes = useStyles();
  return (
    <Typography className={selected ? classes.selected : classes.filterOption}>
      {children}
      <img
        className={classes.downArrow}
        src={selected ? DownArrowActive : DownArrow}
        width={16}
        height={10}
      />
    </Typography>
  );
}

const useStyles = makeStyles(() => ({
  selected: {
    cursor: 'pointer',
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: 14,
    letterSpacing: '0.03em',
    textAlign: 'center',
  },
  filterOption: {
    padding: 0,
    marginLeft: 0,
    cursor: 'pointer',
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 13,
    letterSpacing: '0.03rem',
    color: '#000000',
    textAlign: 'center',
  },
  downArrow: {
    marginLeft: 5,
    color: '#000000',
  },
}));

export default ParticularFilter;
