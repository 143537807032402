import { Component } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Divider,
  Button,
  FormControlLabel,
  IconButton,
} from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { withStyles } from '@material-ui/core/styles';
import CheckBox from '@mui/material/Checkbox';
import SortIcon from '@mui/icons-material/Sort';

class customColumns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColumns: [],
      default: true,
      checkLimit: this.props.columns.length,
      removedItem: [],
      count: 8,
    };
  }

  handleOnChange = (event) => {
    if (event.target.checked === true) {
      this.setState({
        displayColumns: this.state.displayColumns.concat(event.target.value),
        default: false,
        checkLimit: this.state.checkLimit + 1,
      });
    }
    if (event.target.checked === false) {
      const index = this.state.displayColumns.indexOf(event.target.value);
      const index2 = this.props.columns.indexOf(event.target.value);
      this.setState({
        removedItem: this.state.removedItem.concat(event.target.value),
        checkLimit: this.state.checkLimit - 1,
      });
      if (index > -1) {
        this.setState({
          removedItem: this.state.removedItem.concat(event.target.value),
        });
        this.props.columns.splice(index2, 1);
        this.state.displayColumns.splice(index, 1);
        this.setState({
          default: false,
        });
      }
    }
  };

  applyClick = () => {
    this.props.hideColumnSelectModal();
    this.props.onSelectColumns(
      this.state.displayColumns,
      this.state.default,
      this.state.removedItem,
      this.state.checkLimit,
    );
  };

  defaultClick = () => {
    this.setState({ default: true });
    this.props.hideColumnSelectModal();
    this.props.onSelectColumns(
      this.state.displayColumns,
      this.state.default,
      this.state.removedItem,
      this.state.checkLimit,
    );
  };

  // disableClick = () => {
  //   if(this.state.checkLimit > 7){
  //     if(this.state.displayColumns.length === 0){
  //       if
  //     }
  //   }
  // }

  render() {
    let { classes, isStaff } = this.props;
    const { hideColumnSelectModal } = this.props;

    return (
      <Grid
        container
        style={{
          position: 'absolute',
          top: -230,
          right: 50,
          background: '#f5f5f5',
          zIndex: 100,
          width: 1080,
          borderRadius: 20,
          padding: 20,
          paddingBottom: 40,
          flexWrap: 'wrap',
        }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={10}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.heading}>CUSTOM COLUMNS</Typography>
                </Grid>
                <Grid item xs={1}>
                  <SortIcon className={classes.icon} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Button
                className={classes.applyButton}
                onClick={() =>
                  // hideColumnSelectModal()
                  this.applyClick()
                }
              >
                Apply
              </Button>
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="delete" size="small" onClick={() => hideColumnSelectModal()}>
                <ClearIcon className={classes.closeButton} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            className={classes.defaultButton}
            onClick={() => this.defaultClick()}
          >
            Default
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={classes.selectText}
            style={{ color: this.state.checkLimit > 7 ? 'red' : '#757575' }}
          >
            You can select upto 8 metrics
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            {/* <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Channel</Typography>
                </Grid>
                <Grid item xs={2}> 
                  <FormControlLabel
                    control={<CheckBox className={classes.checked}
                    value="Channel"
                    onChange={this.handleOnChange}
                    defaultChecked= {this.props.columns.includes("Channel")}
                    disabled = {this.state.checkLimit > 7 && !this.state.displayColumns.includes("Channel") && !this.props.columns.includes("Channel")}
                    />}
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Average Views</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        className={classes.checked}
                        defaultChecked={this.props.columns.includes('Average Views')}
                        value="Average Views"
                        onChange={this.handleOnChange}
                        disabled={
                          this.state.checkLimit > 7 &&
                          !this.state.displayColumns.includes('Average Views') &&
                          !this.props.columns.includes('Average Views')
                        }
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Language</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        className={classes.checked}
                        defaultChecked={this.props.columns.includes('Language')}
                        value="Language"
                        onChange={this.handleOnChange}
                        disabled={
                          this.state.checkLimit > 7 &&
                          !this.state.displayColumns.includes('Language') &&
                          !this.props.columns.includes('Language')
                        }
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Audience Location</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        className={classes.checked}
                        defaultChecked={this.props.columns.includes('Audience Location')}
                        value="Audience Location"
                        onChange={this.handleOnChange}
                        disabled={
                          this.state.checkLimit > 7 &&
                          !this.state.displayColumns.includes('Audience Location') &&
                          !this.props.columns.includes('Audience Location')
                        }
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Speed Score</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        className={classes.checked}
                        defaultChecked={this.props.columns.includes('Speed Score')}
                        value="Speed Score"
                        onChange={this.handleOnChange}
                        disabled={
                          this.state.checkLimit > 7 &&
                          !this.state.displayColumns.includes('Speed Score') &&
                          !this.props.columns.includes('Speed Score')
                        }
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Audience Gender</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        className={classes.checked}
                        defaultChecked={this.props.columns.includes('Audience Gender')}
                        value="Audience Gender"
                        onChange={this.handleOnChange}
                        disabled={
                          this.state.checkLimit > 7 &&
                          !this.state.displayColumns.includes('Audience Gender') &&
                          !this.props.columns.includes('Audience Gender')
                        }
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Audience Age</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        className={classes.checked}
                        defaultChecked={this.props.columns.includes('Audience Age')}
                        value="Audience Age"
                        onChange={this.handleOnChange}
                        disabled={
                          this.state.checkLimit > 7 &&
                          !this.state.displayColumns.includes('Audience Age') &&
                          !this.props.columns.includes('Audience Age')
                        }
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Audience Interest</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        className={classes.checked}
                        defaultChecked={this.props.columns.includes('Interest')}
                        value="Interest"
                        onChange={this.handleOnChange}
                        disabled={
                          this.state.checkLimit > 7 &&
                          !this.state.displayColumns.includes('Interest') &&
                          !this.props.columns.includes('Interest')
                        }
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
            {/*
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Price Score</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={<CheckBox className={classes.checked} 
                    // checked={this.state.checked}
                    value="Price Score"
                    onChange={this.handleOnChange}/>}
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Fair Price</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        className={classes.checked}
                        defaultChecked={this.props.columns.includes('Fair Price')}
                        value="Fair Price"
                        onChange={this.handleOnChange}
                        disabled={
                          this.state.checkLimit > 7 &&
                          !this.state.displayColumns.includes('Fair Price') &&
                          !this.props.columns.includes('Fair Price')
                        }
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
            {isStaff ? (
              <Grid item>
                <Grid container className={classes.metrics}>
                  <Grid item xs={2} className={classes.dragIcon}>
                    <DragIndicatorIcon></DragIndicatorIcon>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className={classes.text}>Actual Price</Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <CheckBox
                          className={classes.checked}
                          defaultChecked={this.props.columns.includes('Actual Price')}
                          value="Actual Price"
                          onChange={this.handleOnChange}
                        />
                      }
                    ></FormControlLabel>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Impact Score</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        className={classes.checked}
                        defaultChecked={this.props.columns.includes('Impact Score')}
                        value="Impact Score"
                        onChange={this.handleOnChange}
                        disabled={
                          this.state.checkLimit > 7 &&
                          !this.state.displayColumns.includes('Impact Score') &&
                          !this.props.columns.includes('Impact Score')
                        }
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Influencer Location</Typography>
                </Grid>

                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        className={classes.checked}
                        defaultChecked={this.props.columns.includes('Influencer Location')}
                        value="Influencer Location"
                        onChange={this.handleOnChange}
                        disabled={
                          this.state.checkLimit > 7 &&
                          !this.state.displayColumns.includes('Influencer Location') &&
                          !this.props.columns.includes('Influencer Location')
                        }
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
            {isStaff ? (
              <Grid item>
                <Grid container className={classes.metrics}>
                  <Grid item xs={2} className={classes.dragIcon}>
                    <DragIndicatorIcon></DragIndicatorIcon>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className={classes.text}>Email</Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <CheckBox
                          className={classes.checked}
                          defaultChecked={this.props.columns.includes('Email')}
                          value="Email"
                          onChange={this.handleOnChange}
                        />
                      }
                    ></FormControlLabel>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Followers</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        className={classes.checked}
                        defaultChecked={this.props.columns.includes('Followers')}
                        value="Followers"
                        onChange={this.handleOnChange}
                        disabled={
                          this.state.checkLimit > 7 &&
                          !this.state.displayColumns.includes('Followers') &&
                          !this.props.columns.includes('Followers')
                        }
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Influencer Gender</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        className={classes.checked}
                        defaultChecked={this.props.columns.includes('Influencer Gender')}
                        value="Influencer Gender"
                        onChange={this.handleOnChange}
                        disabled={
                          this.state.checkLimit > 7 &&
                          !this.state.displayColumns.includes('Influencer Gender') &&
                          !this.props.columns.includes('Influencer Gender')
                        }
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
            {isStaff ? (
              <Grid item>
                <Grid container className={classes.metrics}>
                  <Grid item xs={2} className={classes.dragIcon}>
                    <DragIndicatorIcon></DragIndicatorIcon>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className={classes.text}>Contact Number</Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <CheckBox
                          className={classes.checked}
                          defaultChecked={this.props.columns.includes('Contact Number')}
                          value="Contact Number"
                          onChange={this.handleOnChange}
                        />
                      }
                    ></FormControlLabel>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Engagement Rate</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        className={classes.checked}
                        defaultChecked={this.props.columns.includes('Engagement Rate')}
                        value="Engagement Rate"
                        onChange={this.handleOnChange}
                        disabled={
                          this.state.checkLimit > 7 &&
                          !this.state.displayColumns.includes('Engagement Rate') &&
                          !this.props.columns.includes('Engagement Rate')
                        }
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Influencer Age</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        className={classes.checked}
                        defaultChecked={this.props.columns.includes('Influencer Age')}
                        value="Influencer Age"
                        onChange={this.handleOnChange}
                        disabled={
                          this.state.checkLimit > 7 &&
                          !this.state.displayColumns.includes('Influencer Age') &&
                          !this.props.columns.includes('Influencer Age')
                        }
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container className={classes.metrics}>
                <Grid item xs={2} className={classes.dragIcon}>
                  <DragIndicatorIcon></DragIndicatorIcon>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text}>Country</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <CheckBox
                        className={classes.checked}
                        defaultChecked={this.props.columns.includes('Country')}
                        value="Country"
                        onChange={this.handleOnChange}
                        disabled={
                          this.state.checkLimit > 7 &&
                          !this.state.displayColumns.includes('Country') &&
                          !this.props.columns.includes('Country')
                        }
                      />
                    }
                  ></FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            {isStaff ? (
              <Grid item>
                <Grid container className={classes.metrics}>
                  <Grid item xs={2} className={classes.dragIcon}>
                    <DragIndicatorIcon></DragIndicatorIcon>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className={classes.text}>Brand Final Price</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <CheckBox
                          className={classes.checked}
                          defaultChecked={this.props.columns.includes('Brand Final Price')}
                          value="Brand Final Price"
                          onChange={this.handleOnChange}
                        />
                      }
                    ></FormControlLabel>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
            {isStaff ? (
              <Grid item>
                <Grid container className={classes.metrics}>
                  <Grid item xs={2} className={classes.dragIcon}>
                    <DragIndicatorIcon></DragIndicatorIcon>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className={classes.text}>Influencer Final Price</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <CheckBox
                          className={classes.checked}
                          defaultChecked={this.props.columns.includes('Influencer Final Price')}
                          value="Influencer Final Price"
                          onChange={this.handleOnChange}
                        />
                      }
                    ></FormControlLabel>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
            {isStaff ? (
              <Grid item>
                <Grid container className={classes.metrics}>
                  <Grid item xs={2} className={classes.dragIcon}>
                    <DragIndicatorIcon></DragIndicatorIcon>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className={classes.text}>Deliverables</Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <CheckBox
                          className={classes.checked}
                          defaultChecked={this.props.columns.includes('Deliverables')}
                          value="Deliverables"
                          onChange={this.handleOnChange}
                        />
                      }
                    ></FormControlLabel>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = {
  heading: {
    width: 500,
    color: '#000000',
    fontWeight: 400,
    fontFamily: 'Nunito Sans',
    fontSize: 16,
    lineHeight: 1,
    padding: 10,
    borderBottom: '6px solid #FEBD1C',
    textAlign: 'start',
  },
  icon: {
    marginTop: 3,
    color: '#676767',
  },
  applyButton: {
    width: 100,
    height: 35,
    background: '#FEBD1C',
    color: '#ffffff',
    fontWeight: 700,
    textTransform: 'none',
    borderRadius: 10,
    alignItems: 'center',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    lineHeight: '23px',
    fontSize: 14,
  },
  closeButton: {
    cursor: 'pointer',
    color: '#757575',
    display: 'flex',
    marginTop: 4,
  },
  defaultButton: {
    width: 100,
    height: 30,
    boxShadow:
      '-5px 5px 10px rgba(230, 230, 230, 0.2), 5px -5px 10px rgba(230, 230, 230, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(230, 230, 230, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(230, 230, 230, 0.5)',
    borderRadius: 20,
    border: '2px solid #FEBD1C',
    marginTop: 30,
    textTransform: 'none',
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'flex-start',
    justifyContent: 'center',
    letterSpacing: '0.03em',
    lineHeight: 1,
    fontSize: 12,
  },
  selectText: {
    color: '#757575',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Nunito Sans',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'flex-start',
    marginTop: 10,
    marginBottom: 10,
  },
  metrics: {
    width: 260,
    height: 40,
    marginTop: 15,
    borderRadius: 5,
    background: '#E7E7E7',
    alignItems: 'center',
  },
  dragIcon: {
    marginTop: 5,
    color: '#888888',
  },
  text: {
    color: '#000000',
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'flex-start',
  },
  checked: {
    borderRadius: 1,
    border: '2px solid rgba(255,255,255)',

    '&$checked': {
      color: '#BBBBBB',
      '&.Mui-checked': {
        color: '#FEBD1C',
      },
    },
  },
};

export default withStyles(styles)(customColumns);
