import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { authHandlers } from 'api_handlers/auth';
import YoutubeLogin from 'components/auth/YoutubeLogin';

import mixpanel from 'mixpanel-browser';

const AuthSignup = ({ goToDiscover, showDomainInvalidModal, ...others }) => {
  const theme = useTheme();

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [number, serNumber] = useState('');
  const [numberError, setNumberError] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [websiteUrlError, setWebsiteUrlError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [errors, setErrors] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const signUp = (e) => {
    e.preventDefault();
    setErrors('');
    setEmailError(false);
    setPasswordError(false);
    setNameError(false);
    setNumberError(false);
    setWebsiteUrlError(false);
    let isValidationError = false;
    if (!(email && email.length > 0)) {
        setEmailError(true);
        isValidationError = true;
    }
    if (!(password && password.length > 0)) {
        setPasswordError(true);
        isValidationError = true;
    }
    if (!(number && number.length > 0)) {
        setNumberError(true);
        isValidationError = true;
    }
    if (!(name && name.length > 0)) {
        setNameError(true);
        isValidationError = true;
    }
    if (!(websiteUrl && websiteUrl.length > 0)) {
        setWebsiteUrlError(true);
        isValidationError = true;
    }
    if (!(confirmPassword && confirmPassword.length > 0)) {
        setConfirmPasswordError(true);
        isValidationError = true;
    }
    if (confirmPassword !== password) {
        setConfirmPasswordError(true);
        isValidationError = true;
    }
    if (isValidationError) {
        return
    }
    let isValidEmail = true;
    let personalDomain = ["gmail.com","yahoo.com","outlook.com","protonmail.com","aol.com","zoho.com","icloud.com","gmx.com","yandex.com","mail.com","google.com"]
    personalDomain.map(each=>{
      console.log(isValidEmail && email.indexOf(each) > -1);
      if (isValidEmail && email.indexOf(each) > -1) {
        isValidEmail = false;
      }
    })
    if (!isValidEmail) {
      showDomainInvalidModal();
      setErrors({ 'non_field_errors': ['Please Enter Valid Domain'] });
      return;
    }
    setIsSubmitting(true);
    let data = {
        email,
        password,
        user_type: 'brand',
    };
    data.brand = {
        name,
        website_url: websiteUrl.startsWith('www.') ? 'https://' + websiteUrl : websiteUrl,
        phone_number: number.indexOf('+') === -1 ? '+91'+number : number
    };
    const resp = authHandlers.signUp(data);
    resp.then(async (response) => {
      setIsSubmitting(false);
      if ([200, 201].includes(response.status)) {
        const userData = await response.json();
        localStorage.setItem('access', userData.access);
        localStorage.setItem('refresh', userData.refresh);
        const user = {
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          user_type: userData.user_type,
          influencer: userData.influencer,
          brand: userData.brand,
        };
        localStorage.setItem('user', JSON.stringify(user));
        if (process.env.NODE_ENV === "production") {
          mixpanel.track("Sign-up");
        }
        goToDiscover();
      } else if ([400, 401, 402, 403].includes(response.status)) {
        const errors = await response.json();
        setErrors(errors);
      } else {
        setErrors({ 'non_field_errors': ['Please Try Again'] });
      }
    });
  }

  const registerToBackendYoutube = async(data) => {
    const resp_data = await authHandlers.youtubeBrandLogin(data);
    if (resp_data.error && resp_data.error === 'Please provide valid brand email') {
      if (process.env.NODE_ENV === "production") {
        mixpanel.track("Google_signup", {
          'type': 'unsuccessful'
        });
      }
      showDomainInvalidModal();
    } else if (resp_data.user) {
      if (process.env.NODE_ENV === "production") {
        mixpanel.track("Google_signup", {
          'type': 'successful'
        });
      }
      goToDiscover();
    } else {
      setErrors({ 'non_field_errors': ['Please Try Again'] });
    }
  }

  return (
    <>
      <form noValidate onSubmit={signUp}>
        <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={nameError}>
              <InputLabel htmlFor="outlined-adornment-name-login">Name</InputLabel>
              <OutlinedInput
                required
                id="outlined-adornment-name-login"
                type={'text'}
                name="name"
                label="Brand Name"
                inputProps={{}}
                onChange={(e)=>{
                    let value = e.target.value;
                    setNameError(false);
                    if (!(value && value.length > 0)) {
                        setNameError(true);
                    }
                    setName(value);
                }}
              />
            {nameError && (
                <FormHelperText error id="standard-weight-helper-text-name-login">
                    Please Enter Valid Name
                </FormHelperText>
            )}
        </FormControl>
        <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={numberError}>
              <InputLabel htmlFor="outlined-adornment-number-login">Phone Number</InputLabel>
              <OutlinedInput
                required
                id="outlined-adornment-number-login"
                type={'text'}
                name="phone_number"
                label="Phone Number"
                inputProps={{}}
                onChange={(e)=>{
                    let value = e.target.value;
                    setNumberError(false);
                    if (!(value && value.length > 0)) {
                        setNumberError(true);
                    }
                    serNumber(value);
                }}
              />
            {numberError && (
                <FormHelperText error id="standard-weight-helper-text-number-login">
                    Please Enter Phone Number
                </FormHelperText>
            )}
        </FormControl>
        <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={emailError}>
            <InputLabel htmlFor="outlined-adornment-email-login">Work Email Address</InputLabel>
            <OutlinedInput
                id="outlined-adornment-email-login"
                type="email"
                name="email"
                label="Email Address"
                inputProps={{}}
                onChange={(e)=>{
                    let value = e.target.value;
                    setEmailError(false);
                    if (!(value && value.length > 0)) {
                        setEmailError(true);
                    }
                    setEmail(value);
                }}
                />
            {emailError && (
                <FormHelperText error id="standard-weight-helper-text-email-login">
                Please Enter Valid Email
                </FormHelperText>
            )}
            </FormControl>
            <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={passwordError}>
              <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
              <OutlinedInput
                required
                id="outlined-adornment-password-login"
                type={showPassword ? 'text' : 'password'}
                name="password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                inputProps={{}}
                onChange={(e)=>{
                    let value = e.target.value;
                    setPasswordError(false);
                    if (!(value && value.length > 0)) {
                        setPasswordError(true);
                    }
                    setPassword(value);
                }}
              />
            {passwordError && (
                <FormHelperText error id="standard-weight-helper-text-password-login">
                Please Enter Valid Password
                </FormHelperText>
            )}
            </FormControl>
            <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={confirmPasswordError}>
              <InputLabel htmlFor="outlined-adornment-confirm-password-login">Confirm Password</InputLabel>
              <OutlinedInput
                required
                id="outlined-adornment-confirm-password-login"
                type={showConfirmPassword ? 'text' : 'password'}
                name="confirm_password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                      size="large"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
                inputProps={{}}
                onChange={(e)=>{
                    let value = e.target.value;
                    setConfirmPasswordError(false);
                    if (!(value && value.length > 0)) {
                        setConfirmPasswordError(true);
                    }
                    setConfirmPassword(value);
                }}
              />
            {confirmPasswordError && (
                <FormHelperText error id="standard-weight-helper-text-confirm-password-login">
                    Password Does Not Match
                </FormHelperText>
            )}
            </FormControl>
            <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={websiteUrlError}>
              <InputLabel htmlFor="outlined-adornment-website-login">Website Url</InputLabel>
              <OutlinedInput
                required
                id="outlined-adornment-website-login"
                type={'text'}
                name="website_url"
                label="Website Url"
                inputProps={{}}
                onChange={(e)=>{
                    let value = e.target.value;
                    setWebsiteUrlError(false);
                    if (!(value && value.length > 0)) {
                        setWebsiteUrlError(true);
                    }
                    setWebsiteUrl(value);
                }}
              />
            {websiteUrlError && (
                <FormHelperText error id="standard-weight-helper-text-website-login">
                    Please Enter Valid Website Url
                </FormHelperText>
            )}
            </FormControl>
            {
                errors ? 
                <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{'non_field_errors' in errors ? errors['non_field_errors'].map(each=>{
                        return each;
                    }) :
                    <ul>
                    {Object.keys(errors).map((key) => {
                      const error = errors[key];
                      if (Array.isArray(error)) {
                        return (
                          <li style={{ fontSize: 12 }}>
                            {error}
                          </li>
                        );
                      } else {
                        return (
                          <li style={{ fontSize: 12 }}>
                            {Object.values(error).join(',')}
                          </li>
                        );
                      }
                    })}
                  </ul>}</FormHelperText>
                </Box> : null
            }
            <Box sx={{ mt: 2 }}>
                <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="secondary">
                    Sign Up
                </Button>
            </Box>
            <Box sx={{ mt: 2 }}>
              Or
            </Box>
            <YoutubeLogin
                label="Signup with google"
                onSuccess={(response) => {
                  registerToBackendYoutube(response);
                }}
              />
      </form>
    </>
  );
};

export default AuthSignup;