import { campaignConstants } from '../constants/campaign';
import { campaignHandlers } from '../api_handlers/campaign';

function getCampaigns() {
  return (dispatch) => {
    dispatch(request());
    campaignHandlers.getCampaigns().then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        dispatch(failure());
      },
    );
  };

  function request() {
    return { type: campaignConstants.GET_CAMPAIGNS_REQUEST };
  }

  function success(campaigns) {
    return { type: campaignConstants.GET_CAMPAIGNS_SUCCESS, campaigns };
  }

  function failure() {
    return { type: campaignConstants.GET_CAMPAIGNS_FAILURE };
  }
}

function getInfluencerCampaigns() {
  return (dispatch) => {
    dispatch(request());
    campaignHandlers.getInfluencerCampaigns().then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        dispatch(failure());
      },
    );
  };

  function request() {
    return { type: campaignConstants.GET_CAMPAIGNS_REQUEST };
  }

  function success(campaigns) {
    return { type: campaignConstants.GET_CAMPAIGNS_SUCCESS, campaigns };
  }

  function failure() {
    return { type: campaignConstants.GET_CAMPAIGNS_FAILURE };
  }
}

function getCampaignDetails(campaign_id) {
  return (dispatch) => {
    dispatch(request());
    campaignHandlers.getCampaignDetails(campaign_id).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        dispatch(failure());
      },
    );
  };

  function request() {
    return { type: campaignConstants.GET_CAMPAIGN_DETAILS_REQUEST };
  }

  function success(current_campaign) {
    return {
      type: campaignConstants.GET_CAMPAIGN_DETAILS_SUCCESS,
      current_campaign,
    };
  }

  function failure() {
    return { type: campaignConstants.GET_CAMPAIGN_DETAILS_FAILURE };
  }
}

function getBasicCampaignDetails(campaign_id) {
  return (dispatch) => {
    dispatch(request());
    campaignHandlers.getBasicCampaignDetails(campaign_id).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        dispatch(failure());
      },
    );
  };

  function request() {
    return { type: campaignConstants.GET_CAMPAIGN_DETAILS_REQUEST };
  }

  function success(current_campaign) {
    return {
      type: campaignConstants.GET_CAMPAIGN_DETAILS_SUCCESS,
      current_campaign,
    };
  }

  function failure() {
    return { type: campaignConstants.GET_CAMPAIGN_DETAILS_FAILURE };
  }
}

function getInfluencers(campaign_id, status = null, page = null, params = {}) {
  return (dispatch) => {
    dispatch(request());
    campaignHandlers.getInfluencers(campaign_id, status, page, params).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        dispatch(failure());
      },
    );
  };

  function request() {
    return { type: campaignConstants.GET_INFLUENCERS_REQUEST };
  }

  function success(response) {
    return { type: campaignConstants.GET_INFLUENCERS_SUCCESS, response };
  }

  function failure() {
    return { type: campaignConstants.GET_INFLUENCERS_FAILURE };
  }
}

function updateInfluencerStatus(influencer_update) {
  campaignHandlers.updateInfluencerStatus(influencer_update);
}

function updateShortlistedInfluencer(campaign_id, shortlisted_id, shortlisted) {
  campaignHandlers.updateShortlistedInfluencer(campaign_id, shortlisted_id, shortlisted);
}

function addPostLink(post_data) {
  campaignHandlers.addPostLink(post_data);
}

function updateCampaignId(campaignId) {
  return (dispatch) => {
    dispatch(setCampaignId(campaignId));
  };

  function setCampaignId(campaignId) {
    return {
      type: campaignConstants.CAMPAIGN_ID,
      campaignId: campaignId,
    };
  }
}

function changeInfluencersAlignment(alignment) {
  return {
    type: campaignConstants.CHANGE_INFLUENCERS_ALIGNMENT,
    influencers_alignment: alignment,
  };
}

function downloadInfluencersReport() {
  return (dispatch) => {
    dispatch({
      type: campaignConstants.DOWNLOAD_INFLUENCERS_REPORT,
      download_influencers_report: true,
    });
    setTimeout(() => {
      dispatch({
        type: campaignConstants.DOWNLOAD_INFLUENCERS_REPORT,
        download_influencers_report: false,
      });
    });
  };
}

export const campaignActions = {
  getCampaigns,
  getInfluencerCampaigns,
  getCampaignDetails,
  getBasicCampaignDetails,
  getInfluencers,
  updateInfluencerStatus,
  addPostLink,
  updateCampaignId,
  updateShortlistedInfluencer,
  changeInfluencersAlignment,
  downloadInfluencersReport,
};
