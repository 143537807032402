import { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Link, Grid } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment';

import { getInfluencerYoutubeFeeds } from "../../../../../api_handlers/feeds";

function createData(
    name: string,
    reel_link: string,
    plays: string,
    likes: string,
    comments: string
) {
    return { name, reel_link, plays, likes, comments };
}

const rows = [
    createData(
        "Varun",
        "https://www.instagram.com/p/CIva7MJJx30/",
        "234,730",
        "47,824",
        "170"
    ),
    createData(
        "Thakur Anoop",
        "https://www.instagram.com/p/CIvVWEIBSCZ/",
        "224,603",
        "38,081",
        "158"
    ),
    createData(
        "Jubin Shah",
        "https://www.instagram.com/p/CIxjDxgHw9B/",
        "432,940",
        "63,667",
        "479"
    ),
    createData(
        "Prem Vats",
        "https://www.instagram.com/p/CIxLS4ejz9y/",
        "202,237",
        "30,504",
        "170"
    ),
    createData(
        "Ajaz Ahmed",
        "https://www.instagram.com/p/CIvb5NQpSnH/",
        "65,564",
        "12,553",
        "130"
    ),
    createData(
        "Manav Chabra",
        "https://www.instagram.com/p/CIx7P5gH4eg/",
        "278,050",
        "63,227",
        "196"
    ),
    createData(
        "Rohit Khandelwal",
        "https://www.instagram.com/p/CIsoqbNJwO9/",
        "131,768",
        "19,186",
        "80"
    ),
    createData(
        "Mridul Madhok",
        "https://www.instagram.com/p/CIszUiCl1Ue/",
        "733,260",
        "43,705",
        "174"
    ),
];

const useStyles = makeStyles({
    headerCell: {
        background: "#C4C4C4",
        fontWeight: 600,
        textAlign: "center",
    },
    table: {
        minWidth: 650,
    },
    cell: {
        textAlign: "center",
    },
});

const styles = (theme) => ({
    headerCell: {
        background: "#C4C4C4",
        fontWeight: 600,
        textAlign: "center",
        fontSize: 12,
    },
    table: {
        minWidth: 650,
    },
    cell: {
        textAlign: "center",
        fontSize: 12,
    },
});

class PostTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feeds: [],
            count: 0,
            page: 1,
            loading: false,
        };
    }
    async getData() {
        this.setState({
            loading: true,
        });
        const campaignId = this.props.match.params.campaignId;
        const influencerId = this.props.match.params.influencerId;
        const resp = await getInfluencerYoutubeFeeds(
            this.state.page,
            campaignId,
            influencerId,
            {
              start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD'): null,
              end_date: this.props.endDate? moment(this.props.endDate).format('YYYY-MM-DD'): null,
              order_by:
                this.state.order === "desc"
                  ? "-" + this.state.orderBy
                  : this.state.orderBy,
            }
        );
        this.setState({
            feeds: this.state.feeds.concat(resp.results),
            count: resp.count,
            loading: false,
        });
    }

    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
        window.addEventListener("scroll", this.infiniteScroll);
        setTimeout(() => {
            this.getData(this.state.page);
        }, 30);
    }

    async reloadData() {
        await this.setState({
          feeds: [],
          count: 0,
          page: 1,
        });
        this.getData();
    }

    ellipsis(text) {
        if (text.length > 80) return text.slice(0, 77) + "...";
        return text;
    }

    infiniteScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.offsetHeight
        ) {
            if (
                this.state.feeds.length < this.state.count &&
                this.state.loading == false
            ) {
                let newPage = this.state.page;
                newPage++;
                this.setState({
                    page: newPage,
                });
                this.getData();
            }
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <TableContainer component={Paper}>
                <Table
                    stickyHeader
                    className={classes.table}
                    aria-label="sticky table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.headerCell}>
                                Post Name
                            </TableCell>
                            <TableCell className={classes.headerCell}>
                                Post Type
                            </TableCell>
                            <TableCell className={classes.headerCell}>
                                User
                            </TableCell>
                            <TableCell className={classes.headerCell}>
                                Cost Per View
                            </TableCell>
                            <TableCell className={classes.headerCell}>
                                Cost Per Engagement
                            </TableCell>
                            <TableCell className={classes.headerCell}>
                                View
                            </TableCell>
                            <TableCell className={classes.headerCell}>
                                Likes
                            </TableCell>
                            <TableCell className={classes.headerCell}>
                                Comments
                            </TableCell>
                            <TableCell className={classes.headerCell}>
                                Estimated Minutes Watched
                            </TableCell>
                            <TableCell className={classes.headerCell}>
                                Average Duration(s)
                            </TableCell>
                            <TableCell className={classes.headerCell}>
                                Shares
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.feeds.map((feed) => (
                            <TableRow key={feed.name}>
                                <TableCell
                                    component="th"
                                    scope="feed"
                                    className={classes.cell}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item={3}>
                                            <img
                                                src={feed.thumbnail}
                                                height={48}
                                                weight={48}
                                            />
                                        </Grid>
                                        <Grid item={9}>
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="space-between"
                                                alignItems="flex-start"
                                            >
                                                <Grid
                                                    item
                                                    md={12}
                                                    style={{
                                                        wordBreak: "normal",
                                                        width: 180,
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    <span>
                                                        {this.ellipsis(
                                                            feed.title
                                                        )}
                                                    </span>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <span>
                                                        <i>
                                                            Added on:{" "}
                                                            {feed.added_on}
                                                        </i>
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {feed.name}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {feed.post_type}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {feed.user_name}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {feed.cpv}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {feed.cpe}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {feed.view_count}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {feed.like_count}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {feed.comment_count}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {feed.estimatedminuteswatched}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {feed.averageviewduration}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {feed.share_count}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default withStyles(styles, {})(PostTable);
