import { Component } from 'react';

import vector from '../../../../assets/vector.png';

export default class EngagementStatistics extends Component {
  render() {
    return (
      <div>
        <div
          style={{
            width: 'calc(100% - 50px)',
            margin: '0px 10px',
            padding: '5px 15px',
            background: '#FFFFFF',
            boxShadow: '2px 5px 10px rgba(139, 139, 139, 0.25)',
            borderRadius: '5px',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-betwenn',
              alignItems: 'center',
            }}
          >
            <div style={{ flex: 1, fontSize: '12px', textAlign: 'left' }}>
              Total Engagement Rate
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {this.props.formatCount(this.props.stats && this.props.stats.engagement_rate)}
              <div
                style={{
                  padding: '5px',
                  margin: '10px 0px 10px 15px',
                  background: '#75D12C',
                  borderRadius: '5.12839px',
                  width: '54px',
                  'justify-content': 'center',
                  'align-items': 'center',
                }}
              >
                <p style={{ color: '#FFFFFF', margin: '5px', fontSize: '11px' }}>Excellent</p>
              </div>
            </div>
          </div>
          <span
            style={{
              background: '#F9C4C4',
              borderRadius: '5px',
              padding: '10px 15px',
            }}
          >
            <img src={vector} width={'15'} height={'15'} />
            <span style={{ color: '#F14444', margin: '5px', marginBottom: '10px' }}>
              {this.props.stats && this.props.stats.total_videos}
            </span>
          </span>
        </div>
      </div>
    );
  }
}
