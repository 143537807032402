import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';

import FollowerIcon from 'assets/discovery/followers-icon.png';
import InterestIcon from 'assets/discovery/interest-icon.png';
import BrandAffinityIcon from 'assets/discovery/Brand-affiniy-icon.png';
import PriceIcon from 'assets/discovery/Price-icon.png';
import FilterVintageIcon from '@material-ui/icons/FilterVintage';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
);

function InfluencerDetails({ interests = [], fair_price, brand_affinity = [] }) {
  const classes = useStyles();
  console.log('interests', interests);
  return (
    <Grid container spacing={3}>
      <Grid item md={12} className={classes.header}>
        <Typography variant="h4" style={{ color: 'black' }}>
          INFLUENCER DETAILS
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Grid container justifyContent="space-between">
          <Grid item md={4} className={classNames(classes.card, classes.graphCard)}>
            <Grid container spacing={1}>
              <Grid item>
                <img src={FollowerIcon} alt="icon" className={classes.iconSize} />
              </Grid>
              <Grid item>
                <Typography variant="h4" color="secondary">
                  Followers
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item style={{ height: 300, width: 300, marginLeft: 5 }}>
                <Line
                  data={{
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                    datasets: [
                      {
                        label: 'Dataset 1',
                        data: [1223, 1242, 2432, 4323, 553, 2343, 3333, 122],
                        borderColor: 'rgb(239, 26, 116)',
                        backgroundColor: 'rgba(239, 26, 116, 0.5)',
                      },
                    ],
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} className={classNames(classes.card, classes.graphCard)}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h4" style={{ color: '#D94343' }}>
                  Likes
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4">/ Comments</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item style={{ height: 100, width: 100, marginLeft: 30 }}>
                <Doughnut
                  data={{
                    datasets: [
                      {
                        label: '# of Votes',
                        data: [10, 12],
                        backgroundColor: ['rgba(211, 31, 31, 0.2)', 'rgba(169, 169, 169, 0.2)'],
                        borderColor: ['rgba(211, 31, 31, 1)', 'rgba(169, 169, 169, 1)'],
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2} className={classNames(classes.card, classes.graphCard)}>
            <Grid container spacing={1} style={{ marginBottom: 8 }}>
              <Grid item>
                <img src={InterestIcon} alt="icon" className={classes.iconSize} />
              </Grid>
              <Grid item>
                <Typography variant="h4" style={{ color: '#0061A7' }}>
                  Interests
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              {interests.map((interest, i) => (
                <Grid item md={12} className={classes.interest} key={i}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item md={2} className={classes.interestIconContainer}>
                      <FilterVintageIcon className={classes.interestIcon} />
                    </Grid>
                    <Grid item md={9}>
                      <Typography variant="body1">{interest.name}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} className={classNames(classes.card, classes.brandAffinityCard)}>
        <Grid container spacing={1}>
          <Grid item>
            <img src={BrandAffinityIcon} alt="icon" className={classes.iconSize} />
          </Grid>
          <Grid item>
            <Typography variant="h4" style={{ color: 'black' }}>
              Influencer Brand Affinity
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 20, marginLeft: 20 }}>
          {(Array.isArray(brand_affinity) ? brand_affinity : []).map((brand, i) => (
            <Grid item className={classes.brandContainer} key={i}>
              {brand.name}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item md={12} className={classNames(classes.card, classes.pricingCard)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <img src={PriceIcon} alt="icon" className={classes.iconSize} />
          </Grid>
          <Grid item>
            <Typography variant="h4" style={{ color: '#FD6A00' }}>
              Pricing
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 20, marginLeft: 20 }} spacing={3}>
          <Grid item md={2} className={classes.fairPriceValueBox}>
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h4" style={{ color: 'black' }}>
                  Fair Price
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3" style={{ color: '#FD6A00' }}>
                  {fair_price}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Grid container>
              <Grid item md={12}>
                <Typography variant="h4" style={{ color: 'black' }}>
                  Details
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Typography variant="body1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  header: {
    background: '#F3F4FE',
    boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    borderRadius: 10,
    padding: 15,
    marginBottom: 15,
  },
  card: {
    background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    padding: 20,
  },
  graphCard: {
    height: 200,
  },
  iconSize: {
    width: 15,
    height: 15,
  },
  brandAffinityCard: {
    height: 170,
  },
  pricingCard: {
    height: 200,
    marginTop: 15,
  },
  interestIconContainer: {
    background: 'linear-gradient(135deg, #E8EAEE 0%, #FFFFFF 100%)',
    boxShadow:
      '-5px 5px 10px rgba(220, 221, 222, 0.2), 5px -5px 10px rgba(220, 221, 222, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(220, 221, 222, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(220, 221, 222, 0.5)',
    borderRadius: '50%',
    padding: 3,
  },
  interestIcon: {
    color: '#B9B9B9',
    fontSize: 13,
  },
  fairPriceValueBox: {
    background: '#F3F4FE',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 224, 0.2), 5px -5px 10px rgba(215, 216, 224, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 224, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 224, 0.5)',
    borderRadius: 10,
    height: 100,
    padding: 20,
  },
  brandContainer: {
    background: '#F2F3F7',
    boxShadow:
      '-5px 5px 10px rgba(218, 219, 222, 0.2), 5px -5px 10px rgba(218, 219, 222, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(218, 219, 222, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(218, 219, 222, 0.5)',
    borderRadius: 10,
    padding: 12,
    margin: 10,
  },
}));

export default InfluencerDetails;
