import ImageButton from 'components/Theme/Elements/ImageButton';
import InstagramIcon from 'assets/discovery/insta-icon.png';
import YoutubeIcon from 'assets/discovery/youtube-icon.png';

function InstagramRectIconButton({ ...props }) {
  return (
    <ImageButton
      imageSrc={props.platform == 'youtube' ? YoutubeIcon : InstagramIcon}
      height={10}
      width={10}
      varient="rectangle"
      {...props}
    />
  );
}

export default InstagramRectIconButton;
