import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import InfluencerCard from './cards/Influencer';

class InfluencerList extends React.Component {
  render() {
    return (
      <Grid container>
        <Grid item md={12}>
          {this.props.influencers?.map((influencer) => (
            <InfluencerCard
              {...influencer}
              key={influencer.id}
              handler={this.props.handler}
              summary_handler={this.props.summaryHandler}
            />
          ))}
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(InfluencerList);
