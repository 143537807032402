import React, { Component } from 'react';
import { Button, Grid, Checkbox, TextField, Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

import moment from 'moment';

import { USE_ES } from 'config';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Statistics from '../reporting/cards/youtube/Statistics';
import EngagementStatistic from './cards/youtube/EngagementStatistic';
import PostTable from './cards/youtube/PostTable';
import DateRangePicker from '../ux/DateRangePicker';
import { campaignHandlers } from '../../api_handlers/campaign';
import { downloadFile } from '../../api_handlers/index';
import { getYoutubeStatistic } from '../../api_handlers/reporting';
import { getYoutubeStatisticES } from 'api_handlers/es';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MultiSelect from 'components/PlannerTheme/Elements/MultiSelect';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const items = [
  'Post Type',
  'Cost Per View',
  'Cost Per Engagement',
  // "Views",
  // "Likes",
  // "Comments",
  'Impressions',
  'Estimated Minutes Watched',
  'Average View Duration(s)',
  'Shares',
  'Dislike Count',
  'Peak CCV',
  'Impression CTR',
  'Unique Viewers',
  'Watch Time',
  'Chat Msgs',
  'Audience Retention',
  // "Gender Split",
  // "Age Split",
  // "Geography Split",
  'Engagement Rate',
];

class YoutubeFeed extends Component {
  constructor(props) {
    super(props);

    this.handleFilter = this.handleFilter.bind(this);
    this.handleLangauge=this.handleLangauge.bind(this)

    this.state = {
      influencerOptions: [],
      languageOptions:[],
      selectedInfluencer: null,
      selectedLanguage:'',
      startDate: null,
      endDate: null,
      prev_startDate: null,
      prev_endDate: null,
      campaign: null,
      defaultDateRange: [],
      is_loading: false,
      stats: null,
      language:[],
      category: [],
      isNull:true
    };
  }

  downloadReport() {
    downloadFile(`/campaign/${this.props.campaignId}/report/youtube/feed/download`, {
      influencer_id: this.state.selectedInfluencer,
      start_date: this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : null,
      end_date: this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : null,
      language_ids: this.state.selectedLanguage,
    });
  }
  async getData() {
    const fetchFunction = USE_ES ? getYoutubeStatisticES : getYoutubeStatistic;
  
    const resp = await fetchFunction(
      this.props.campaignId,
      this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : null,
      this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : null,
      this.state.selectedInfluencer,
      this.state.selectedLanguage
    );
    this.setState({ stats: resp.data });
  }

  componentDidMount() {
    campaignHandlers.getCampaignInfluencerOptions(this.props.campaignId).then((data) => {
      this.setState({ influencerOptions: data });
      
    });
    campaignHandlers.getInfluencerLanguageOptions(this.props.campaignId).then(data=>{
      this.setState({languageOptions:data})
    })
    this.setState({
      loading: false,
    });
    // campaignHandlers.getBasicCampaignDetails(this.props.campaignId).then((data => {
    //     this.setState({ campaign: data, startDate: data.report_start_date ? new Date(data.report_start_date).toISOString() : null, endDate: data.report_end_date ? new Date(data.report_end_date).toISOString() : null, loading: false, defaultDateRange: [new Date(data.report_start_date), new Date(data.report_end_date)] })
    // }))
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  componentDidUpdate() {
    if (
      this.state.prev_startDate !== this.state.startDate &&
      this.state.prev_endDate !== this.state.endDate
    ) {
      this.getData();
      this.setState({
        prev_startDate: this.state.startDate,
        prev_endDate: this.state.endDate,
      });
    }
  }

  handleLangauge(event , selectedValues){
    this.state.language.splice(0, this.state.language.length);
    this.setState({
      language: this.state.language.concat(...selectedValues),
      
    });
    var selectedLanguage_ids = selectedValues.map(item => item.language_id).join(',');
    this.setState({selectedLanguage:selectedLanguage_ids})
    this.postTable.reloadData();
    this.getData();
  }

  handleFilter(event, selectedValues) {
    this.state.category.splice(0, this.state.category.length);
    this.setState({
      category: this.state.category.concat(...selectedValues),
    });
    this.postTable.reloadData();
    this.getData();
  }
  render() {

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} xl={12}>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Grid
                container
                justify="flex-end"
                spacing={1}
                style={{ marginTop: '0px' }}
                alignItems="center"
              >
                <Grid item md={3}>
                  <Autocomplete
                    id="tags-standard"
                    multiple
                    InputLabelProps={{ shrink: false }}
                    options={items}
                    disableCloseOnSelect
                    renderTags={(values) => <Chip label={values[0] + ' +' + (values.length - 1)} />}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </React.Fragment>
                    )}
                    style={{
                      width: '100%',
                      background: '#F9F9F9',
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Columns to display" />
                    )}
                    getOptionDisabled={(options) => (this.state.category.length > 7 ? true : false)}
                    onChange={this.handleFilter}
                  />
                </Grid>

                <Grid
                  item
                  md={1}
                  style={{
                    fontSize: 11,
                    paddingRight: 0,
                  }}
                >
                  <span>Filter by:</span>
                </Grid>
                <Grid item md={7} style={{ paddingLeft: 0 }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    style={{
                      align: 'center',
                    }}
                  >
                    <Grid item md={3}>
                      <DateRangePicker
                        id="date-picker"
                        placement="left-end"
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onSelect={async (result) => {
                          await this.setState({
                            startDate: result[0].startDate,
                            endDate: result[0].endDate,
                          });
                          this.postTable.reloadData();
                          this.getData();
                        }}
                      ></DateRangePicker>
                    </Grid>
                    <Grid item md={3}>
                      <Autocomplete
                        options={this.state.influencerOptions}
                        getOptionLabel={(option) => option.influencer__name}
                        InputLabelProps={{ shrink: false }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="normal"
                            variant="outlined"
                            size="small"
                            placeholder='Influencer Name'
                            style={{
                              textTransform:false,
                              width: '100%',
                              background: '#FFF',
                              fontSize: '11px',
                              height: 35,
                              padding:5.5,
                              marginBottom: 20,
                              paddingTop: 0,
                            }}
                          />
                        )}
                        onChange={async (event, value) => {
                          await this.setState({
                            selectedInfluencer: value ? value.influencer_id : null,
                          });
                          this.postTable.reloadData();
                          this.getData();
                        }}
                        style={{
                          fontSize: '11px',
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Autocomplete
                    id="tags-standard"
                    multiple
                    InputLabelProps={{ shrink: false }}
                    // options={this.state.languageOptions.map(data=>data.language_name)}
                    options={this.state.languageOptions}
                    getOptionLabel={(option) => option.language_name}
                    disableCloseOnSelect
                    // renderTags={(values) => <Chip label={values.map(lang=>lang.language_name.slice(0,2)) + ' +' + (values.length)} />}
                    renderTags={(values) => <Chip label={values.map(lang=>lang.language_name.slice(0,2)).join(',') } />}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.language_name}
                      </React.Fragment>
                    )}
                    style={{
                      width: '100%',
                      background: '#F9F9F9',
                      
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" 
                      size='small' 
                      label="Select Language"
                      style={{
                        textTransform:false,
                        width: '100%',
                        background: '#FFF',
                        fontSize: '11px',
                        height: 35,
                        // padding:5.5,
                        marginBottom: 10,
                        paddingTop: 0,
                      }}
                      />
                    )}
                    getOptionDisabled={(options) => (this.state.language.length > 4  || this.state.isNull==true? true : false)}
                    onChange={async (event, selectedValues) => {
                      this.state.language.splice(0, this.state.language.length);
                      await this.setState({
                        language: this.state.language.concat(...selectedValues),
                        
                      });
                      var selectedLanguage_ids = selectedValues.map(item => item.language_id).join(',');
                      this.setState({selectedLanguage:selectedLanguage_ids})
                      this.postTable.reloadData();
                      this.getData();
                    }}
                  />
                    </Grid>
                    <Grid item md={2}>
                      <Button
                        variant="contained"
                        endIcon={<CloudDownloadIcon />}
                        style={{
                          fontSize: 12,
                          textTransform: 'none',
                          backgroundColor: '#444',
                          color: 'white',
                        }}
                        onClick={() => {
                          this.downloadReport();
                        }}
                      >
                        Report
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <Grid container spacing={1}>
                <Grid item md={4}>
                  <EngagementStatistic
                    socialPlatform={this.props.report?.socialPlatform}
                    {...this.props.report?.statistics}
                    extraMargin={20}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    stats={this.state.stats}
                  />
                </Grid>
                <Grid item md={8}>
                  <Statistics
                    socialPlatform={this.props.report?.socialPlatform}
                    {...this.props.report?.statistics}
                    extraMargin={20}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    stats={this.state.stats}
                    selectedInfluencer={this.state.selectedInfluencer}
                  />
                </Grid>
                <Grid item md={12}>
                  <Grid container spacing={1}>
                    <Grid item md={12}>
                      {!this.state.loading ? (
                        <PostTable
                          campaignId={this.props.campaignId}
                          selectedInfluencer={this.state.selectedInfluencer}
                          selectedLanguage={this.state.selectedLanguage}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onRef={(ref) => (this.postTable = ref)}
                          allowedTableColumns={this.state.category}
                          getFeeds={ (val)=>{
                             val.length>0?this.setState({isNull:false}):this.setState({isNull:true})
                          }}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default YoutubeFeed;
