import { Component } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

class Recommendation extends Component {
  // const classes = useStyles();
  formatCount(count) {
    if (typeof count === 'string' && count.length > 0) {
      const lastChar = count[count.length - 1].toLocaleUpperCase();
      if (['B', 'M', 'K'].includes(lastChar)) return count;
    }

    var num;
    const parsedCount = parseFloat(count);
    if (parsedCount <= 1) {
      return parsedCount.toFixed(2);
    } else if (parsedCount > 9999 && parsedCount <= 999999) {
      num = parsedCount / 1000;
      if (num % 1 != 0) {
        num = num.toFixed(2);
      }
      return num + 'K';
    } else if (parsedCount > 999999) {
      num = parsedCount / 1000000;
      if (num % 1 != 0) {
        num = num.toFixed(2);
      }
      return num + 'M';
    } else if (parsedCount > 999999999) {
      num = parsedCount / 1000000000;
      if (num % 1 != 0) {
        num = num.toFixed(2);
      }
      return num + 'B';
    } else if (parsedCount === NaN) {
      return count;
    } else if (parsedCount && count % 1 != 0) {
      return count.toFixed(2);
    } else {
      return count;
    }
  }

  formatEngagementRate(count) {
    const num = (parseFloat(count) * 100).toFixed(2);
    return num + '%';
  }
  render() {
    let {
      classes,
      selectedPrediction,
      setSelectedPrediction,
      selectedObjectiveType,
      amount,
      reset,
      showOther,
    } = this.props;
    return (
      <div
        spacing={0}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          marginBottom: 10,
        }}
      >
        <div className={classes.reco_box} style={{ flex: 7, marginRight: 10 }}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 10,
              paddingTop: 10,
              boxSizing: 'border-box',
            }}
          >
            <Grid item>
              {selectedObjectiveType == 'Reach' && (
                <div className={classes.reco_text}>
                  <span className={classes.yellow_text}>PREDICTION &nbsp;&nbsp;</span> You could
                  reach{' '}
                  <span className={classes.yellow_text}>
                    {!reset ? this.formatCount(selectedPrediction.reach) : 0}
                    &nbsp;
                  </span>{' '}
                  Users with{' '}
                  <span className={classes.yellow_text}>
                    ₹ {!reset ? this.formatCount(selectedPrediction.max_price) : 0}{' '}
                  </span>
                </div>
              )}
              {selectedObjectiveType == 'CPV' && (
                <div className={classes.reco_text}>
                  <span className={classes.yellow_text}>PREDICTION &nbsp;&nbsp;</span> You could
                  reach{' '}
                  <span className={classes.yellow_text}>
                    {!reset ? this.formatCount(selectedPrediction.reach) : 0}
                    &nbsp;
                  </span>{' '}
                  Users with{' '}
                  <span className={classes.yellow_text}>
                    ₹ {!reset ? this.formatCount(selectedPrediction.max_price) : 0}{' '}
                  </span>{' '}
                  at an average CPV of{' '}
                  <span className={classes.yellow_text}>
                    {selectedPrediction.max_price && !reset
                      ? this.formatCount(selectedPrediction.views / selectedPrediction.max_price)
                      : '0'}
                  </span>
                </div>
              )}

              {selectedObjectiveType == 'Engagement Rate' && (
                <div className={classes.reco_text}>
                  <span className={classes.yellow_text}>PREDICTION &nbsp;&nbsp;</span> You could
                  engage with{' '}
                  <span className={classes.yellow_text}>
                    {!reset ? this.formatCount(selectedPrediction.enagaged_users) : 0}
                    &nbsp;
                  </span>{' '}
                  Users with{' '}
                  <span className={classes.yellow_text}>
                    ₹ {!reset ? this.formatCount(selectedPrediction.max_price) : 0}{' '}
                  </span>
                </div>
              )}
            </Grid>
            <Grid item className={classes.grid_border}></Grid>
            <Grid item className={classes.reco_stats}>
              {selectedObjectiveType == 'Reach' && (
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item md={3}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item className={classes.stats_value}>
                        {!reset ? this.formatCount(selectedPrediction.reach) : 0}
                      </Grid>
                      <Grid item className={classes.stats_labels}>
                        Reach
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item className={classes.stats_value}>
                        {!reset ? this.formatCount(selectedPrediction.views) : 0}
                      </Grid>
                      <Grid item className={classes.stats_labels}>
                        Views
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item className={classes.stats_value}>
                        {!reset ? this.formatCount(selectedPrediction.enagaged_users) : 0}
                      </Grid>
                      <Grid item className={classes.stats_labels}>
                        Engaged Users
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item className={classes.stats_value}>
                        {selectedPrediction.followers && !reset
                          ? this.formatEngagementRate(
                              selectedPrediction.enagaged_users / selectedPrediction.followers,
                            )
                          : '0.00%'}
                      </Grid>
                      <Grid item className={classes.stats_labels}>
                        Eng. Rate
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {selectedObjectiveType == 'CPV' && (
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item md={2}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item className={classes.stats_value}>
                        {!reset ? this.formatCount(selectedPrediction.views) : 0}
                      </Grid>
                      <Grid item className={classes.stats_labels}>
                        Views
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={2}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item className={classes.stats_value}>
                        ₹
                        {selectedPrediction.max_price && !reset
                          ? this.formatCount(
                              selectedPrediction.views / selectedPrediction.max_price,
                            )
                          : '0'}
                      </Grid>
                      <Grid item className={classes.stats_labels}>
                        Cost per view
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={2}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item className={classes.stats_value}>
                        {!reset ? this.formatCount(selectedPrediction.reach) : 0}
                      </Grid>
                      <Grid item className={classes.stats_labels}>
                        Reach
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item className={classes.stats_value}>
                        {selectedPrediction.followers && !reset
                          ? this.formatEngagementRate(
                              selectedPrediction.enagaged_users / selectedPrediction.followers,
                            )
                          : '0.00%'}
                      </Grid>
                      <Grid item className={classes.stats_labels}>
                        Engagement rate
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item className={classes.stats_value}>
                        {!reset ? this.formatCount(selectedPrediction.enagaged_users) : 0}
                      </Grid>
                      <Grid item className={classes.stats_labels}>
                        Engaged users
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {selectedObjectiveType == 'Engagement Rate' && (
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item md={2}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item className={classes.stats_value}>
                        {!reset ? this.formatCount(selectedPrediction.enagaged_users) : 0}
                      </Grid>
                      <Grid item className={classes.stats_labels}>
                        Engaged users
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={2}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item className={classes.stats_value}>
                        {selectedPrediction.followers && !reset
                          ? this.formatEngagementRate(
                              selectedPrediction.enagaged_users / selectedPrediction.followers,
                            )
                          : '0.00%'}
                      </Grid>
                      <Grid item className={classes.stats_labels}>
                        Engagement rate
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={2}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item className={classes.stats_value}>
                        {!reset ? this.formatCount(selectedPrediction.reach) : 0}
                      </Grid>
                      <Grid item className={classes.stats_labels}>
                        Reach
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item className={classes.stats_value}>
                        {!reset ? this.formatCount(selectedPrediction.views) : 0}
                      </Grid>
                      <Grid item className={classes.stats_labels}>
                        Views
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item className={classes.stats_value}>
                        ₹
                        {selectedPrediction.max_price && !reset
                          ? this.formatCount(
                              selectedPrediction.enagaged_users / selectedPrediction.max_price,
                            )
                          : '0'}
                      </Grid>
                      <Grid item className={classes.stats_labels}>
                        Cost per Engagement
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
        <div
          className={classes.reco_box}
          style={{
            padding: 20,
            flex: 7,
            boxSizing: 'border-box',
            display: 'flex',
          }}
        >
          <Grid>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item className={classes.reco_data}>
                <Grid container>
                  <Grid item>Spend</Grid>
                  {/* <Grid item>{!showOther && <CircularProgress style={{height: 20}}/>}</Grid> */}
                </Grid>
              </Grid>
              <Grid item className={classes.grey_text}>
                ₹ {!reset ? this.formatCount(selectedPrediction.max_price) : 0}
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              marginLeft: 20,
            }}
          >
            <Grid className={classes.circular_div}>
              <Grid container direction="row" justifyContent="space-around" alignItems="center">
                <Grid item md={3} style={{}}>
                  <div className={classes.green_circle}>
                    <span>
                      <span className={classes.circle_text_1}>
                        {!reset ? selectedPrediction.mega.count : 0}{' '}
                      </span>
                      Mega
                    </span>
                    <span className={classes.circle_text_2}>
                      ₹{!reset ? this.formatCount(selectedPrediction.mega.amount) : 0}
                    </span>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div className={classes.red_circle}>
                    <span>
                      <span className={classes.circle_text_1}>
                        {!reset ? selectedPrediction.macro.count : 0}{' '}
                      </span>
                      Macro
                    </span>
                    <span className={classes.circle_text_2}>
                      ₹{!reset ? this.formatCount(selectedPrediction.macro.amount) : 0}
                    </span>
                  </div>
                  {/* <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{padding:25}}
                                    >
                                    <Grid item>
                                        <span className={classes.circle_text_1}>{selectedPrediction.macro.count} </span>Macro 
                                    </Grid>
                                    <Grid item>
                                        <span className={classes.circle_text_2}>₹{this.formatCount(selectedPrediction.macro.amount)}</span>
                                    </Grid>
                                    </Grid> */}
                </Grid>
                <Grid item md={3} style={{}}>
                  <div className={classes.blue_circle}>
                    <span>
                      <span className={classes.circle_text_1}>
                        {!reset ? selectedPrediction.micro.count : 0}{' '}
                      </span>
                      Micro
                    </span>
                    <span className={classes.circle_text_2}>
                      ₹{!reset ? this.formatCount(selectedPrediction.micro.amount) : 0}
                    </span>
                  </div>
                  {/* <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{padding:25}}
                                    >
                                    <Grid item>
                                    </Grid>
                                    <Grid item>
                                    
                                    </Grid>
                                  </Grid> */}
                </Grid>
                <Grid item md={3}>
                  <div className={classes.yellow_circle}>
                    <span>
                      <span className={classes.circle_text_1}>
                        {!reset ? selectedPrediction.nano.count : 0}{' '}
                      </span>
                      Nano
                    </span>
                    <span className={classes.circle_text_2}>
                      ₹{!reset ? this.formatCount(selectedPrediction.nano.amount) : 0}
                    </span>
                  </div>
                  {/* <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <span className={classes.circle_text_1}>
                        {!reset ? selectedPrediction.nano.count : 0}{" "}
                      </span>
                      Nano
                    </Grid>
                    <Grid item>
                      <span className={classes.circle_text_2}>
                        ₹
                        {!reset
                          ? this.formatCount(selectedPrediction.nano.amount)
                          : 0}
                      </span>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  root: {
    width: '100%',
  },
  moreWidth: {
    maxWidth: '10.5%',
  },
  reco_box: {
    // justifyContent: 'space-evenly',
    // alignItems: 'center'
    background: '#FFFFFF',
    // padding:10,
    height: 280,
    maxHeight: 280,
    borderRadius: 10,
    boxShadow: '3px 5px 20px rgba(131, 131, 131, 0.25)',
    borderRadius: '10px',
  },
  reco_text: {
    padding: 20,
    margin: 20,
    fontSize: 24,
    fontWeight: 400,
    width: '90%',
  },
  reco_stats: {
    margin: 20,
    fontSize: 24,
    fontWeight: 600,
    width: '90%',
  },
  reco_data: {
    // margin: 20,
    fontSize: 26,
    fontWeight: 400,
    textAlign: 'left',
  },
  yellow_text: {
    color: '#FEBD1C',
    fontWeight: 700,
  },
  grid_border: {
    width: '100%',
    border: '1px solid #D4D4D4',
  },
  stats_value: {
    padding: 10,
  },
  stats_labels: {
    fontWeight: 400,
    fontSize: 16,
    color: '#757575',
  },
  grey_text: {
    fontWeight: 400,
    fontSize: 18,
    color: '#757575',
    textAlign: 'left',
  },
  circular_div: {
    // margin:20,
    // padding:50,
    fontSize: 16,
    fontWeight: 300,
    position: 'relative',
    alignItems: 'center',
  },
  green_circle: {
    marginTop: -60,
    marginLeft: -20,
    height: 170,
    width: 170,
    borderRadius: 85,
    backgroundColor: '#d9fce0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  red_circle: {
    height: 150,
    width: 150,
    borderRadius: 75,
    backgroundColor: '#FCD9E8',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blue_circle: {
    marginTop: -40,
    height: 130,
    width: 130,
    borderRadius: 65,
    backgroundColor: '#CEEBFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    position: 'relative',
  },
  yellow_circle: {
    marginLeft: -20,
    marginBottom: -40,
    height: 100,
    width: 100,
    borderRadius: '50%',
    backgroundColor: '#FDEABB',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    position: 'relative',
  },
  circle_text_1: {
    fontSize: 20,
    fontWeight: 700,
  },
  circle_text_2: {
    fontSize: 20,
    fontWeight: 700,
  },
  font12: {
    fontSize: 12,
  },
};

export default withStyles(styles)(Recommendation);
// export default Recommendation
