import { Checkbox, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },
  },
})(TextField);

function MultiSelect({ value, onChange, options, label, placeholder }) {
  const classes = useStyles();
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      defaultValue={value}
      onChange={onChange}
      options={options}
      value={value}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            color="primary"
          />
          {option.label}
        </>
      )}
      style={{
        width: '100%',
        border: 'none',
        background: '#FFFFFF',
        boxShadow:
          '-2px 2px 4px rgba(219, 220, 229, 0.2), 2px -2px 4px rgba(219, 220, 229, 0.2), -2px -2px 4px rgba(255, 255, 255, 0.9), 2px 2px 5px rgba(219, 220, 229, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(219, 220, 229, 0.5)',
        borderRadius: '20px',
      }}
      renderInput={(params) => (
        <CssTextField {...params} variant="outlined" label={label} placeholder={''} />
      )}
    />
  );
}

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '3px !important',
    paddingBottom: '3px !important',
    cursor: 'pointer',
  },
  selected: {
    border: '3px solid #FEBD1C',
    boxShadow:
      '-5px 5px 10px rgba(210, 211, 219, 0.2), 5px -5px 10px rgba(210, 211, 219, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(210, 211, 219, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(210, 211, 219, 0.5)',
    borderRadius: '20px',
  },
  name: {
    fontSize: 14,
  },
  MuiOutlinedInput: {
    border: 'none',
  },
}));

export default MultiSelect;
