import * as React from 'react';
import { Grid, Typography, TextField, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CreatorsBudgetValue from './CreatorsBudgetValue';

import SliderWithoutBackgroundCustomSlider from 'components/PlannerTheme/Elements/SliderWithoutBackground/SliderWithoutBackground';

class CreatorsType extends React.Component {
  render() {
    let { classes, mega, macro, micro, nano, budget } = this.props;
    const max_value = budget - mega - macro - micro - nano;
    return (
      <Grid
        container
        direction="column"
        style={{
          flex: 3,
          border: '1px solid #ADADAD',
          borderRadius: 25,
          marginLeft: 0,
          boxSizing: 'border-box',
          padding: 10,
        }}
      >
        <Grid item>
          <CreatorsBudgetValue
            text="Mega"
            text2="(>1M)"
            setCelebsTypeText="mega"
            setLockText="ismegaLocked"
            budget={budget}
            value={mega}
            setInfluencerTypeLock={this.props.setInfluencerTypeLock}
            max_value={max_value}
            setCelebsType={this.props.setCelebsType}
            remain_value={max_value}
            reset={true}
          ></CreatorsBudgetValue>
        </Grid>
        <Grid item>
          <CreatorsBudgetValue
            text="Macro"
            text2="(100k-1M)"
            setCelebsTypeText="macro"
            setLockText="ismacroLocked"
            budget={budget}
            setInfluencerTypeLock={this.props.setInfluencerTypeLock}
            value={macro}
            max_value={max_value}
            setCelebsType={this.props.setCelebsType}
          ></CreatorsBudgetValue>
        </Grid>
        <Grid item>
          <CreatorsBudgetValue
            text="Micro"
            text2="(10k-100k)"
            setCelebsTypeText="micro"
            setLockText="ismicroLocked"
            // isDisabled={this.props.isDisabled}
            budget={budget}
            value={micro}
            setInfluencerTypeLock={this.props.setInfluencerTypeLock}
            max_value={max_value}
            setCelebsType={this.props.setCelebsType}
          ></CreatorsBudgetValue>
        </Grid>
        <Grid item>
          <CreatorsBudgetValue
            text="Nano"
            text2="(<10k)"
            setCelebsTypeText="nano"
            setLockText="isnanoLocked"
            budget={budget}
            setInfluencerTypeLock={this.props.setInfluencerTypeLock}
            value={nano}
            max_value={max_value}
            setCelebsType={this.props.setCelebsType}
          ></CreatorsBudgetValue>
        </Grid>

        {/* <Grid item>
          <Grid container justifyContent='center'>
            <Grid item className={classes.gridItems}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
              >
                <Grid item style={{ position: 'relative', right: '26px' }}>
                  <Typography className={classes.label}>
                    Celebs
                    <Typography className={classes.label2}></Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    inputProps={{ style: { fontSize: 11 } }}
                    placeholder='₹ 0'
                    className={classes.search}
                    InputProps={{ disableUnderline: true }}
                    value={`${celebs}`}
                    onChange={(e) =>
                      this.props.setCelebsType(
                        'celebs',
                        Number(e.target.value) <= max_value + celebs
                          ? Number(e.target.value)
                          : max_value + celebs
                      )
                    }
                  />
                </Grid>
                <Grid item>
                  <SliderWithoutBackgroundCustomSlider
                    className={classes.slider}
                    aria-label='Always visible'
                    max={budget}
                    onChange={(event, newValue) =>
                      this.props.setCelebsType(
                        'celebs',
                        newValue <= max_value + celebs
                          ? newValue
                          : max_value + celebs
                      )
                    }
                    step={1000}
                    value={celebs}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.gridItems}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
              >
                <Grid item>
                  <Typography className={classes.label}>
                    Macro
                    <Typography className={classes.label2}>
                      (100K-500K)
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    inputProps={{ style: { fontSize: 11 } }}
                    placeholder='₹ 0'
                    className={classes.search}
                    InputProps={{ disableUnderline: true }}
                    value={`${macro}`}
                    onChange={(e) =>
                      this.props.setCelebsType(
                        'macro',
                        Number(e.target.value) <= max_value + macro
                          ? Number(e.target.value)
                          : max_value + macro
                      )
                    }
                  />
                </Grid>
                <Grid item>
                  <SliderWithoutBackgroundCustomSlider
                    className={classes.slider}
                    aria-label='Always visible'
                    max={budget}
                    onChange={(event, newValue) =>
                      this.props.setCelebsType(
                        'macro',
                        newValue <= max_value + macro
                          ? newValue
                          : max_value + macro
                      )
                    }
                    step={1000}
                    value={macro}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.gridItems}>
              <Grid container justifyContent='center' alignItems='center'>
                <Grid item style={{ position: 'relative', right: '3px' }}>
                  <Typography className={classes.label}>
                    Micro
                    <Typography className={classes.label2}>
                      (10K-100K)
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    inputProps={{ style: { fontSize: 11 } }}
                    placeholder='₹ 0'
                    className={classes.search}
                    InputProps={{ disableUnderline: true }}
                    value={`${micro}`}
                    onChange={(e) =>
                      this.props.setCelebsType(
                        'micro',
                        Number(e.target.value) <= max_value + micro
                          ? Number(e.target.value)
                          : max_value + micro
                      )
                    }
                  />
                </Grid>
                <Grid item>
                  <SliderWithoutBackgroundCustomSlider
                    className={classes.slider}
                    aria-label='Always visible'
                    max={budget}
                    onChange={(event, newValue) =>
                      this.props.setCelebsType(
                        'micro',
                        newValue <= max_value + micro
                          ? newValue
                          : max_value + micro
                      )
                    }
                    step={1000}
                    value={micro}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.gridItems}>
              <Grid container justifyContent='center' alignItems='center'>
                <Grid item style={{ position: 'relative', right: '15px' }}>
                  <Typography className={classes.label}>
                    Nano
                    <Typography className={classes.label2}>
                      ({'<'}10K)
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    inputProps={{ style: { fontSize: 11 } }}
                    placeholder='₹ 0'
                    className={classes.search}
                    InputProps={{ disableUnderline: true }}
                    value={`${nano}`}
                    onChange={(e) =>
                      this.props.setCelebsType(
                        'nano',
                        Number(e.target.value) <= max_value + nano
                          ? Number(e.target.value)
                          : max_value + nano
                      )
                    }
                  />
                </Grid>
                <Grid item>
                  <SliderWithoutBackgroundCustomSlider
                    className={classes.slider}
                    aria-label='Always visible'
                    max={budget}
                    onChange={(event, newValue) =>
                      this.props.setCelebsType(
                        'nano',
                        newValue <= max_value + nano
                          ? newValue
                          : max_value + nano
                      )
                    }
                    step={1000}
                    value={nano}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.gridItems}>
              <Button
                className={classes.resetButton}
                onClick={() => {
                  this.props.setCelebsType('celebs', 0);
                  this.props.setCelebsType('macro', 0);
                  this.props.setCelebsType('micro', 0);
                  this.props.setCelebsType('nano', 0);
                }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid> */}

        <Grid item>
          <Grid container justifyContent="center" marginLeft={100}>
            <Grid item>
              <Typography className={classes.optionLabel}>Budget</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.moneylabel}>₹{budget}</Typography>
            </Grid>
            {/* <Grid item>
              <Typography className={classes.optionLabel}>
                Budget Allocated
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.moneylabel}>
                ₹{celebs + macro + micro + nano}
              </Typography>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = {
  optionLabel: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 13,
    marginLeft: 250,
  },
  moneylabel: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 13,
    color: '#757575',
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 20,
  },
  slider: {
    width: 150,
    marginTop: 40,
  },
  resetButton: {
    textTransform: 'none',
    color: '#757575',
  },
  gridItems: {
    maxWidth: '20%',
    marginLeft: 20,
  },
};

export default withStyles(styles)(CreatorsType);
