import React from 'react';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';

class NumberInputButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: this.props.counter,
      id: this.props.id,
    };
  }
  handleIncrement = () => {
    this.setState({ counter: this.state.counter + 1 });
  };

  handleDecrement = () => {
    this.setState({ counter: this.state.counter - 1 });
  };
  render() {
    return (
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button onClick={this.handleDecrement} style={{ fontWeight: '900' }}>
          -
        </Button>
        <TextField
          variant="outlined"
          size="small"
          id={this.state.id}
          value={this.state.counter}
          required
          style={{ width: '50px' }}
          onChange={(event) => {
            this.setState({ counter: event.target.value });
          }}
        />
        <Button onClick={this.handleIncrement} style={{ fontWeight: '900' }}>
          +
        </Button>
      </ButtonGroup>
    );
  }
}

export default NumberInputButton;
