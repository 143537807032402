import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Tabs, Tab, Grid, Divider, Avatar, Modal } from '@material-ui/core';
import qs from 'qs';
import Link from '@mui/material/Link';

import { colorConstants } from '../../constants/colors';

import { getCookie } from '../../components/utils';
import InfluencerLogo from '../../assets/influencer-logo.png';
import PriceIndexDemoLogo from '../../assets/price_index.png';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@material-ui/core/Button';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { nonAuthHandlers } from '../../api_handlers/nonauth';
import { fetchYoutubePriceIndexDemo } from '../../api_handlers/extra';
import Loading from '../../components/Common/Loading/Loading';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import toast, { Toaster } from 'react-hot-toast';

import './PriceIndexDemo.styles.css';

const responseInstagram = (response) => {};

class PriceIndexDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      youtube: false,
      insta_handle: null,
      youtube_url: null,
      data: null, //{"data_exist": true, "follower_count": "69.1 M", "impressions": 8361799, "estimated_engagement": "987.3 K", "cost_min": "24.7 lac", "cost_max": "39.5 lac", "igtv_range": ["22.2 lac", "35.5 lac"], "reel_range": ["17.3 lac", "27.6 lac"], "st_video_range": ["9.9 lac", "15.8 lac"], "video_post_range": ["17.3 lac", "27.6 lac"], "story_n_swipe_range": ["7.4 lac", "11.8 lac"], "estimated_views": "1.7 M", "estimated_engagement_rate": 1.43, "estimated_engage_follower": "988.4 K", "insta_handle": "gal_gadot", "profile_pic_url": "https://instagram.flko7-1.fna.fbcdn.net/v/t51.2885-19/132197016_214462623590305_6641430723485871092_n.jpg?_nc_ht=instagram.flko7-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=-4GNkeOmOeAAX--DUwD&edm=AEF8tYYBAAAA&ccb=7-4&oh=f257fa779d5dfc677c41edb06913fc24&oe=61BCC62B&_nc_sid=a9513d"},
      multiplier_data: null,
      summary: null,
      is_data_found: false,
      email_required: false,
      email: '',
      email_valid: true,
      emailModalOpen: false,
      is_loading: false,
    };
  }

  toggleEmailModal = () => {
    this.setState({ emailModalOpen: !this.state.emailModalOpen });
  };

  toggleUser = (event, youtube) => {
    this.setState({ youtube: !!youtube }, () => {
      if (this.state.youtube) {
        this.setState({
          insta_handle: null,
          style: {
            className: 'bg-youtube',
          },
        });
      } else {
        this.setState({
          youtube_url: null,
          style: {
            className: 'bg-influencer',
          },
        });
      }
    });
  };

  handleChange = (event) => {
    const email = event.target.value;
    this.setState({ email: email });
    if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      this.setState({
        email_valid: false,
      });
    } else {
      this.setState({
        email_valid: true,
      });
    }
  };

  toggleFaq = (event, youtube) => {
    this.setState({ youtube: !!youtube }, () => {
      if (this.state.youtube) {
        this.setState({
          insta_handle: null,
          style: {
            className: 'bg-youtube',
          },
        });
      } else {
        this.setState({
          youtube_url: null,
          style: {
            className: 'bg-influencer',
          },
        });
      }
    });
  };

  countertostring(counter) {
    counter = parseInt(counter);
    if (counter >= 1000 && counter < 999999) {
      return (
        parseInt(counter / 1000.0)
          .toFixed(2)
          .toString() + ' K'
      );
    } else if (counter >= 999999) {
      return (
        parseInt(counter / 1000000.0)
          .toFixed(2)
          .toString() + ' M'
      );
    } else {
      return counter.toString();
    }
  }

  indianmoneytostring(counter) {
    counter = parseInt(counter);
    if (counter >= 1000 && counter < 100000) {
      return parseInt(counter / 1000).toString() + ' K';
    } else if (counter >= 100000 && counter <= 9999999) {
      return parseInt(counter / 100000).toString() + ' lac';
    } else if (counter >= 10000000) {
      return parseInt(counter / 10000000).toString() + ' Cr.';
    } else {
      return counter.toString();
    }
  }
  calculate_youtube_data(summary, multiplier_data, pre_data) {
    const data = {};
    data['avg_views'] = summary['view_count'] / summary['video_count'];
    data['avg_engagement'] = (
      (summary['like_count'] + summary['dislike_count'] + summary['comment_count']) /
      summary['video_count']
    ).toFixed(2);
    data['title'] = pre_data['title'];
    data['profile_pic_url'] = pre_data['profile_pic_url'];
    data['estimated_engagement'] = this.countertostring(data['avg_engagement']);
    data['estimated_engagement_rate'] = (
      (data['avg_engagement'] * 100) /
      summary['subscriber_count']
    ).toFixed(2);
    data['subscribers'] = this.countertostring(summary['subscriber_count']);
    data['estimated_views'] = this.countertostring(data['avg_views']);
    data['data_exist'] = true;
    data['cost_min'] = multiplier_data['cost_min_multiplier'] * data['avg_views'];
    data['cost_max'] = multiplier_data['cost_max_multiplier'] * data['avg_views'];
    data['dedicated_range'] = [
      this.indianmoneytostring(multiplier_data['dedicated_range_multiplier'] * data['cost_min']),
      this.indianmoneytostring(multiplier_data['dedicated_range_multiplier'] * data['cost_max']),
    ];
    data['integrated_range'] = [
      this.indianmoneytostring(multiplier_data['integrated_range_multiplier'] * data['cost_min']),
      this.indianmoneytostring(multiplier_data['integrated_range_multiplier'] * data['cost_max']),
    ];
    data['shorts_range'] = [
      this.indianmoneytostring(multiplier_data['shorts_range_multiplier'] * data['cost_min']),
      this.indianmoneytostring(multiplier_data['shorts_range_multiplier'] * data['cost_max']),
    ];
    data['live_range'] = [
      this.indianmoneytostring(multiplier_data['live_range_multiplier'] * data['cost_min']),
      this.indianmoneytostring(multiplier_data['live_range_multiplier'] * data['cost_max']),
    ];
    data['cost_min'] = this.indianmoneytostring(data['cost_min']);
    data['cost_max'] = this.indianmoneytostring(data['cost_max']);

    this.setState({
      data: data,
    });
  }

  calculate_insta_data(summary, multiplier_data, pre_data) {
    const data = {};
    data['follower_count'] = summary['follower_count'];
    if (summary['view_count']) {
      data['impressions'] = summary['view_count'];
    } else {
      data['impressions'] = ((summary['like_count'] | 0) + (summary['comment_count'] | 0)) * 1.5;
    }
    data['estimated_engagement'] =
      ((summary['like_count'] | 0) + (summary['comment_count'] | 0)) / (summary['post_count'] | 0);
    data['cost_min'] = 0;
    data['cost_max'] = 0;
    data['cost_min'] = multiplier_data['cost_min_multiplier'] * data['estimated_engagement'];
    data['cost_max'] = multiplier_data['cost_max_multiplier'] * data['estimated_engagement'];
    if (data['cost_min'] && data['cost_min'] < 1000) {
      data['cost_min'] = data['cost_min'] + 1000;
      data['cost_max'] = data['cost_max'] + 1000;
    }
    data['igtv_range'] = [
      this.indianmoneytostring(data['cost_min'] * multiplier_data['igtv_range_multiplier']),
      this.indianmoneytostring(data['cost_max'] * multiplier_data['igtv_range_multiplier']),
    ];
    data['reel_range'] = [
      this.indianmoneytostring(data['cost_min'] * multiplier_data['reel_range_multiplier']),
      this.indianmoneytostring(data['cost_max'] * multiplier_data['reel_range_multiplier']),
    ];
    data['st_video_range'] = [
      this.indianmoneytostring(data['cost_min'] * multiplier_data['st_video_range_multiplier']),
      this.indianmoneytostring(data['cost_max'] * multiplier_data['st_video_range_multiplier']),
    ];
    data['video_post_range'] = [
      this.indianmoneytostring(
        data['cost_min'] * multiplier_data['video_post_range_range_multiplier'],
      ),
      this.indianmoneytostring(
        data['cost_max'] * multiplier_data['video_post_range_range_multiplier'],
      ),
    ];
    data['story_n_swipe_range'] = [
      this.indianmoneytostring(
        data['cost_min'] * multiplier_data['story_n_swipe_range_multiplier'],
      ),
      this.indianmoneytostring(
        data['cost_max'] * multiplier_data['story_n_swipe_range_multiplier'],
      ),
    ];

    data['cost_min'] = this.indianmoneytostring(data['cost_min']);
    data['cost_max'] = this.indianmoneytostring(data['cost_max']);
    data['estimated_views'] = this.countertostring(
      (data['impressions'] / (summary['video_count'] | summary['post_count'] | 0)).toFixed(2),
    );
    data['estimated_engagement_rate'] = (
      (data['estimated_engagement'] * 100) /
      (data['follower_count'] | 0)
    ).toFixed(2);
    data['estimated_engage_follower'] = this.countertostring(
      ((data['estimated_engagement_rate'] * data['follower_count']) / 100).toFixed(2),
    );
    data['estimated_engagement'] = this.countertostring(data['estimated_engagement']);
    data['follower_count'] = this.countertostring(data['follower_count']);
    data['insta_handle'] = pre_data['insta_handle'];
    data['profile_pic_url'] = pre_data['profile_pic_url'];
    data['data_exist'] = true;

    this.setState({
      data: data,
    });
  }

  priceIndexDemo(data) {
    const resp = nonAuthHandlers.priceIndexDemo(data);
    resp.then(async (response) => {
      if ([200, 201].includes(response.status)) {
        const userData = await response.json();
        this.setState({
          data: userData.data,
          multiplier_data: userData.multiplier_data,
          summary: userData.summary,
          email_required: false,
          is_loading: false,
        });
        this.calculate_insta_data(this.state.summary, this.state.multiplier_data, this.state.data);
      } else if ([400, 401, 402, 403, 500, 504].includes(response.status)) {
        const errors = await response.json();
        console.log(response.status);
        this.setState({
          // formErrors: errors,
          // is_form_error: true,
          is_loading: false,
        });
        if (response.status == 400) {
          this.notify(errors.error);
        }
      }
    });
  }

  notify(message) {
    toast(message);
  }

  submitForm = (e) => {
    e.preventDefault();
    if (!this.state.email_required) {
      if (!this.state.youtube) {
        this.setState({ is_loading: true });
        this.priceIndexDemo({
          insta_handle: this.state.insta_handle,
          email: this.state.email,
        });
      } else {
        this.setState({ is_loading: true });
        this.getYoutubePriceIndexDemo();
      }
    } else {
      this.setState({
        emailModalOpen: true,
      });
    }
  };

  onChangeQty(key, e) {
    const data = this.state.multiplier_data;
    data[key] = e.target.value;

    this.setState({
      multiplier_data: data,
    });
    if (!this.state.youtube) {
      this.calculate_insta_data(this.state.summary, this.state.multiplier_data, this.state.data);
    } else {
      this.calculate_youtube_data(this.state.summary, this.state.multiplier_data, this.state.data);
    }
  }

  submitEmailForm() {
    if (!this.state.email) {
      return;
    } else if (
      this.state.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
    ) {
      this.setState({
        email_valid: false,
      });
    } else {
      if (!this.state.youtube) {
        this.setState({ is_loading: true });
        this.priceIndexDemo({
          insta_handle: this.state.insta_handle,
          email: this.state.email,
        });
        this.setState({ emailModalOpen: false });
      } else {
        this.setState({ is_loading: true });
        this.getYoutubePriceIndexDemo();
        this.setState({ emailModalOpen: false });
      }
    }
  }

  async getYoutubePriceIndexDemo() {
    try {
      const { data } = await fetchYoutubePriceIndexDemo(this.state.youtube_url, this.state.email);
      this.setState({
        is_loading: false,
        data: data.data,
        multiplier_data: data.multiplier_data,
        summary: data.summary,
        email_required: false,
      });
      this.calculate_youtube_data(this.state.summary, this.state.multiplier_data, this.state.data);
    } catch {
      this.setState({ is_loading: false });
    }
  }
  async isEmailRequired() {
    const resp = nonAuthHandlers.emailRequired();
    resp.then(async (response) => {
      if ([200, 201].includes(response.status)) {
        const userData = await response.json();
        this.setState({
          email_required: false,
          is_loading: false,
        });
      } else if ([400, 401, 402, 403, 504].includes(response.status)) {
        const errors = await response.json();
        console.log(response.status);
        this.setState({
          // formErrors: errors,
          // is_form_error: true,
          is_loading: false,
        });
      }
    });
  }
  // componentDidMount() {
  //     this.isEmailRequired();
  // }

  render() {
    return (
      <Grid container>
        {this.state.is_loading && <Loading />}
        <Grid container direction="column" justifyContent="center" alignItems="stretch">
          <Grid item>
            <img
              style={{ marginLeft: '-10px' }}
              src={InfluencerLogo}
              alt="influencer-logo"
              height={'30px'}
            ></img>
          </Grid>
          <Grid item>
            <Grid direction="column" justifyContent="center" alignItems="stretch">
              <Grid item style={{ background: '#FFE0D8' }}>
                <Grid
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
                  style={{
                    maxWidth: '600px',
                    minHeight: '300px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    paddingTop: 60,
                  }}
                >
                  <Grid
                    item
                    className="indexHeading"
                    style={{
                      fontSize: '48px',
                      fontWeight: 'bold',
                    }}
                  >
                    <img
                      style={{ marginLeft: '-10px' }}
                      src={PriceIndexDemoLogo}
                      alt="influencer-logo"
                      height={'36px'}
                    ></img>{' '}
                    <span className="indexSiteName" style={{ color: '#F2643E' }}>
                      Influencer.in
                    </span>{' '}
                    Price Index
                  </Grid>
                  <Grid item className="indexDescp" style={{ fontSize: 18, paddingTop: 20 }}>
                    Evaluate the potential cost for different types of influencer collaborations
                    based on profile performance analytics.
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="">
                <Grid className="index_form-content-wrapper">
                  <Tabs
                    value={+this.state.youtube}
                    centered
                    indicatorColor={this.state.youtube ? 'secondary' : 'primary'}
                    onChange={this.toggleUser}
                  >
                    <Tab
                      icon={<InstagramIcon />}
                      label={'Instagram'}
                      style={{
                        color: this.state.youtube ? '' : '#F2643E',
                        fontWeight: this.state.youtube ? '' : 'bold',
                      }}
                    />
                    <Tab
                      icon={<YouTubeIcon />}
                      label={'Youtube'}
                      style={{
                        color: this.state.youtube ? colorConstants.BRAND_RED : '',
                        fontWeight: this.state.youtube ? 'bold' : '',
                      }}
                    />
                  </Tabs>
                  <form onSubmit={this.submitForm}>
                    {this.state.youtube ? (
                      <Box>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="youtube_url"
                          label="Youtube Channel/User URL"
                          name="youtube_url"
                          autoComplete="text"
                          onChange={(e) =>
                            this.setState({
                              youtube_url: e.target.value,
                            })
                          }
                          autoFocus
                        />
                      </Box>
                    ) : (
                      <Box>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="insta_handle"
                          label="Instagram Handle"
                          name="insta_handle"
                          autoComplete="text"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span
                                  style={{
                                    color: '#1E9CB2',
                                    fontSize: 20,
                                  }}
                                >
                                  @
                                </span>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            this.setState({
                              insta_handle: e.target.value,
                            })
                          }
                          autoFocus
                        />
                      </Box>
                    )}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{
                        backgroundColor: '#F2643E',
                        marginBottom: '2vw',
                        marginTop: '2vw',
                        color: 'white',
                      }}
                    >
                      Calculate
                    </Button>
                  </form>
                  <Grid container style={{ display: 'inline' }}>
                    <strong>*</strong>This calculator only shows results for influencers with
                    Business or Creator accounts and is in Beta. If you have any inputs on a
                    specific calculation, please DM us on Insta
                    {/*(<Link
                                            href="https://www.instagram.com/influencer.in/"
                                            target="_blank"
                                            underline="hover"
                                            color="primary"
                                            rel="noreferrer"
                                            sx={{display: 'inline'}}
                                        >
                                            @influencer.in
                                        </Link>)*/}
                    .<br></br>
                    <strong>Please note:</strong> This is only a broad estimate based on available
                    data and may vary significantly.
                  </Grid>
                </Grid>
              </Grid>
              <Grid container style={{ paddingTop: 300 }}>
                {' '}
              </Grid>
              <Grid item className="">
                <Grid
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
                  style={{
                    maxWidth: '600px',
                    minHeight: '300px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  {this.state.multiplier_data ? (
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Property</TableCell>
                          <TableCell>Value</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(this.state.multiplier_data).map((key, i) => (
                          <TableRow>
                            <TableCell>{key}</TableCell>
                            <TableCell>
                              <input
                                type="number"
                                value={this.state.multiplier_data[key]}
                                step="0.01"
                                autoFocus
                                onFocus={(e) => e.currentTarget.select()}
                                onChange={(e) => this.onChangeQty(key, e)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : null}
                </Grid>
              </Grid>
              {this.state.data && this.state.data.data_exist ? (
                <Grid item className="">
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    style={{
                      maxWidth: '1000px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <Grid item md={6}>
                      <Grid
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                        style={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            style={{
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              minHeight: 112,
                            }}
                          >
                            <Grid
                              item
                              md={8}
                              style={{
                                background: '#1E9CB2',
                              }}
                            >
                              <Grid
                                direction="column"
                                justifyContent="start"
                                alignItems="stretch"
                                style={{
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                  textAlign: 'initial',
                                  padding: 20,
                                }}
                              >
                                <Grid
                                  item
                                  style={{
                                    fontSize: 16,
                                    color: 'white',
                                  }}
                                >
                                  Esitmated Price
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    fontSize: 24,
                                    fontWeight: 'bold',
                                    color: 'white',
                                    paddingTop: 20,
                                  }}
                                >
                                  ₹{this.state.data.cost_min} - ₹{this.state.data.cost_max}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          style={{
                            paddingTop: 20,
                            textAlign: 'initial',
                          }}
                        >
                          <strong>
                            {this.state.youtube
                              ? this.state.data.title
                              : `@${this.state.data.insta_handle}`}
                          </strong>{' '}
                          can charge{' '}
                          <strong>
                            ₹{this.state.data.cost_min} – ₹{this.state.data.cost_max}
                          </strong>
                          <br></br>
                          {this.state.youtube
                            ? this.state.data.title
                            : `@${this.state.data.insta_handle}`}{' '}
                          is expected to engage an estimated{' '}
                          <strong>{this.state.data.estimated_engage_follower}</strong> followers,
                          capture a projected <strong>{this.state.data.estimated_views}</strong>{' '}
                          views and generate an average engagement rate of{' '}
                          <strong>{this.state.data.estimated_engagement_rate}%</strong> on execution
                          of brand collaboration on their
                          {this.state.youtube ? ' Youtube Feed' : ' Instagram feed.'}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        style={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          'vertical-align': 'baseline',
                          padding: 50,
                        }}
                      >
                        <Grid item className="">
                          <Grid direction="column" justifyContent="center" alignItems="stretch">
                            <Grid item>
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                              >
                                <Grid item md={4}></Grid>

                                <Grid
                                  item
                                  md={6}
                                  className="box1"
                                  style={{
                                    width: '100px',
                                    height: '100px',
                                  }}
                                >
                                  <Grid
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="stretch"
                                    style={{
                                      paddingTop: 15,
                                    }}
                                  >
                                    <Grid
                                      item
                                      style={{
                                        color: '#1E9CB2',
                                        fontSize: '26px',
                                      }}
                                    >
                                      {this.state.data.estimated_engagement}
                                    </Grid>
                                    <Grid
                                      item
                                      style={{
                                        color: 'black',
                                        fontSize: '14px',
                                      }}
                                    >
                                      Est. Engagement
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  md={6}
                                  className="box2"
                                  style={{
                                    width: '100px',
                                    height: '100px',
                                  }}
                                >
                                  <Grid
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="stretch"
                                    style={{
                                      paddingTop: 15,
                                    }}
                                  >
                                    <Grid
                                      item
                                      style={{
                                        color: '#1E9CB2',
                                        fontSize: '26px',
                                      }}
                                    >
                                      {this.state.data.estimated_engagement_rate}%
                                    </Grid>
                                    <Grid
                                      item
                                      style={{
                                        color: 'black',
                                        fontSize: '14px',
                                      }}
                                    >
                                      Engagement Rate
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item md={4}>
                                  <Avatar
                                    src={this.state.data.profile_pic_url}
                                    alt={'proile pic'}
                                    style={{
                                      width: '6vw',
                                      height: '6vw',
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  md={6}
                                  className="box3"
                                  style={{
                                    width: '100px',
                                    height: '100px',
                                  }}
                                >
                                  <Grid
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="stretch"
                                    style={{
                                      paddingTop: 15,
                                    }}
                                  >
                                    <Grid
                                      item
                                      style={{
                                        color: '#1E9CB2',
                                        fontSize: '26px',
                                      }}
                                    >
                                      {this.state.youtube
                                        ? this.state.data.subscribers
                                        : this.state.data.follower_count}
                                    </Grid>
                                    <Grid
                                      item
                                      style={{
                                        color: 'black',
                                        fontSize: '14px',
                                      }}
                                    >
                                      {this.state.youtube ? 'Subscribers' : 'Followers'}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  md={6}
                                  className="box4"
                                  style={{
                                    width: '100px',
                                    height: '100px',
                                  }}
                                >
                                  <Grid
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    style={{
                                      paddingTop: 15,
                                    }}
                                  >
                                    <Grid
                                      item
                                      style={{
                                        color: '#1E9CB2',
                                        fontSize: '26px',
                                      }}
                                    >
                                      {this.state.data.estimated_views}
                                    </Grid>
                                    <Grid
                                      item
                                      style={{
                                        color: 'black',
                                        fontSize: '14px',
                                      }}
                                    >
                                      Est. View
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {this.state.data && this.state.data.data_exist ? (
                <Grid item className="">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      maxWidth: '600px',
                      // minHeight: "300px",
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      paddingTop: 60,
                      fontSize: 16,
                      color: 'black',
                    }}
                  >
                    <Grid
                      item
                      md={4}
                      style={{
                        background: '#F2643E',
                        color: 'white',
                        border: '1px solid white',
                        padding: 10,
                      }}
                    >
                      Deliverables
                    </Grid>
                    <Grid
                      item
                      md={8}
                      style={{
                        background: '#F2643E',
                        color: 'white',
                        border: '1px solid white',
                        padding: 10,
                      }}
                    >
                      Estimated Costing Range
                    </Grid>
                    {this.state.data.st_video_range ? (
                      <>
                        <Grid
                          item
                          md={4}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          Static
                        </Grid>
                        <Grid
                          item
                          md={8}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          ₹{this.state.data.st_video_range[0]} – ₹
                          {this.state.data.st_video_range[1]}
                        </Grid>
                      </>
                    ) : (
                      ''
                    )}
                    {this.state.data.story_n_swipe_range ? (
                      <>
                        <Grid
                          item
                          md={4}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          Stories
                        </Grid>
                        <Grid
                          item
                          md={8}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          ₹{this.state.data.story_n_swipe_range[0]} – ₹
                          {this.state.data.story_n_swipe_range[1]}
                        </Grid>
                      </>
                    ) : (
                      ''
                    )}
                    {this.state.data.reel_range ? (
                      <>
                        <Grid
                          item
                          md={4}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          Reels
                        </Grid>
                        <Grid
                          item
                          md={8}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          ₹{this.state.data.reel_range[0]} – ₹{this.state.data.reel_range[1]}
                        </Grid>
                      </>
                    ) : (
                      ''
                    )}
                    {this.state.data.igtv_range ? (
                      <>
                        <Grid
                          item
                          md={4}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          IGTV
                        </Grid>
                        <Grid
                          item
                          md={8}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          ₹{this.state.data.igtv_range[0]} – ₹{this.state.data.igtv_range[1]}
                        </Grid>
                      </>
                    ) : (
                      ''
                    )}
                    {this.state.data.dedicated_range ? (
                      <>
                        <Grid
                          item
                          md={4}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          Dedicated
                        </Grid>
                        <Grid
                          item
                          md={8}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          ₹{this.state.data.dedicated_range[0]} – ₹
                          {this.state.data.dedicated_range[1]}
                        </Grid>
                      </>
                    ) : (
                      ''
                    )}
                    {this.state.data.integrated_range ? (
                      <>
                        <Grid
                          item
                          md={4}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          Integrated
                        </Grid>
                        <Grid
                          item
                          md={8}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          ₹{this.state.data.integrated_range[0]} – ₹
                          {this.state.data.integrated_range[1]}
                        </Grid>
                      </>
                    ) : (
                      ''
                    )}
                    {this.state.data.shorts_range ? (
                      <>
                        <Grid
                          item
                          md={4}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          Shorts
                        </Grid>
                        <Grid
                          item
                          md={8}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          ₹{this.state.data.shorts_range[0]} – ₹{this.state.data.shorts_range[1]}
                        </Grid>
                      </>
                    ) : (
                      ''
                    )}
                    {this.state.data.live_range ? (
                      <>
                        <Grid
                          item
                          md={4}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          Live
                        </Grid>
                        <Grid
                          item
                          md={8}
                          style={{
                            background: 'rgba(242, 100, 62, 0.2)',
                            border: '1px solid white',
                            padding: 10,
                          }}
                        >
                          ₹{this.state.data.live_range[0]} – ₹{this.state.data.live_range[1]}
                        </Grid>
                      </>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              ) : null}
              <Grid item className="">
                <Grid
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
                  style={{
                    maxWidth: '600px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Grid
                    item
                    style={{
                      fontSize: '32px',
                      fontWeight: 'bold',
                    }}
                  >
                    Frequently Asked Questions
                  </Grid>
                  <Grid item>
                    <Grid
                      direction="column"
                      justifyContent="center"
                      alignItems="stretch"
                      style={{ marginTop: 30 }}
                    >
                      <Grid item className="faq_div">
                        <Grid
                          direction="column"
                          justifyContent="center"
                          alignItems="stretch"
                          style={{ padding: 20 }}
                        >
                          <Grid item>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid
                                item
                                style={{
                                  color: '#1E9CB2',
                                  fontSize: 20,
                                  textAlign: 'left',
                                }}
                                md={10}
                              >
                                What will the Influencer Price Index show me?
                              </Grid>
                              <Grid item md={2}>
                                <AddIcon
                                  style={{
                                    color: 'grey',
                                    fontSize: 'bold',
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              paddingTop: 20,
                              textAlign: 'left',
                              color: '#808080',
                            }}
                          >
                            The influencer price index is a tool that will present you with
                            estimated commercials for the influencer’s profile. These prices are
                            further segmented based on the percentage weights given to each
                            deliverable as per industry standards.
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="faq_div">
                        <Grid
                          direction="column"
                          justifyContent="center"
                          alignItems="stretch"
                          style={{ padding: 20 }}
                        >
                          <Grid item>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid
                                item
                                style={{
                                  color: '#1E9CB2',
                                  fontSize: 20,
                                  textAlign: 'left',
                                }}
                                md={10}
                              >
                                I’m a brand. Can I use this to estimate how much I should pay an
                                influencer?
                              </Grid>
                              <Grid item md={2}>
                                <AddIcon
                                  style={{
                                    color: 'grey',
                                    fontSize: 'bold',
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              paddingTop: 20,
                              textAlign: 'left',
                              color: '#808080',
                            }}
                          >
                            This tool is a great aid in understanding how much to allocate for an
                            influencer based on their estimated engagement and views. The brand
                            using the projected kpis that a collaborative post would reach, will be
                            able to approach an influencer with a fair price.
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="faq_div">
                        <Grid
                          direction="column"
                          justifyContent="center"
                          alignItems="stretch"
                          style={{ padding: 20 }}
                        >
                          <Grid item>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid
                                item
                                style={{
                                  color: '#1E9CB2',
                                  fontSize: 20,
                                  textAlign: 'left',
                                }}
                                md={10}
                              >
                                How reliable is this calculator?
                              </Grid>
                              <Grid item md={2}>
                                <AddIcon
                                  style={{
                                    color: 'grey',
                                    fontSize: 'bold',
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              paddingTop: 20,
                              textAlign: 'left',
                              color: '#808080',
                            }}
                          >
                            The calculator is highly reliable as it analyzes the real- time data of
                            an influencer’s profile and projects the estimated commercials based
                            reach and engagement. The tool enables a brand/ influencer to initiate a
                            conversation with the counterpart armed with the right set of data to
                            fast-track collaborations.
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="faq_div">
                        <Grid
                          direction="column"
                          justifyContent="center"
                          alignItems="stretch"
                          style={{ padding: 20 }}
                        >
                          <Grid item>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid
                                item
                                style={{
                                  color: '#1E9CB2',
                                  fontSize: 20,
                                  textAlign: 'left',
                                }}
                                md={10}
                              >
                                What are the metrics that impact the cost of influencer
                                collaborations?
                              </Grid>
                              <Grid item md={2}>
                                <AddIcon
                                  style={{
                                    color: 'grey',
                                    fontSize: 'bold',
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              paddingTop: 20,
                              textAlign: 'left',
                              color: '#808080',
                            }}
                          >
                            Engagement rate ( which includes your views, likes and comments) and the
                            reach impacts the cost of collaboration on Instagram. On Youtube, it is
                            the views that majorly contribute to the prices.
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="faq_div">
                        <Grid
                          direction="column"
                          justifyContent="center"
                          alignItems="stretch"
                          style={{ padding: 20 }}
                        >
                          <Grid item>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid
                                item
                                style={{
                                  color: '#1E9CB2',
                                  fontSize: 20,
                                  textAlign: 'left',
                                }}
                                md={10}
                              >
                                How is the pricing calculated on Instagram different from that on
                                Youtube?
                              </Grid>
                              <Grid item md={2}>
                                <AddIcon
                                  style={{
                                    color: 'grey',
                                    fontSize: 'bold',
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              paddingTop: 20,
                              textAlign: 'left',
                              color: '#808080',
                            }}
                          >
                            Our tool for instagram is based on CPE ( cost per engagement) which
                            includes the views , likes and comments whereas the Youtube pricing
                            index is based on CPV ( cost per view) model.
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    ;
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ background: '#F2643E' }}>
                <Grid
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
                  style={{
                    maxWidth: '600px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    minHeight: 260,
                    paddingTop: 40,
                  }}
                >
                  <Grid
                    item
                    style={{
                      fontSize: 32,
                      fontWeight: 'bold',
                      color: 'white',
                    }}
                  >
                    Want to connect with more influencers?
                  </Grid>
                  <Grid item style={{ fontSize: 20 }}>
                    <span style={{ color: 'white' }}>Search top influencers with </span>
                    Influencer.in
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{
                        backgroundColor: 'white',
                        marginBottom: '2vw',
                        marginTop: '2vw',
                        color: 'black',
                      }}
                      href="https://influencer.in"
                    >
                      Sign Up Now
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div>
          <Modal
            open={this.state.emailModalOpen}
            onClose={this.toggleEmailModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              style={{
                backgroundColor: 'white',
                width: '50vw',
                transform: 'translate(50%, 40vh)',
                paddingLeft: '10px',
                borderRadius: '15px',
                boxShadow:
                  '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
              }}
              align={'left'}
            >
              <h3 style={{ paddingTop: '10px' }}>Email</h3>
              <hr style={{ width: '99%' }} />
              <Grid container spacing={2}>
                <Grid item md={12}>
                  Please Enter your Email to know the price Index
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ padding: '10px' }}
                >
                  <Grid item md={2} alignItems="flex-end">
                    <p>Email:</p>
                  </Grid>
                  <Grid item md={9}>
                    <TextField
                      id="email"
                      style={{ margin: 8 }}
                      placeholder="Enter Email"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      variant="outlined"
                      onChange={this.handleChange}
                      validators={['required', 'isEmail']}
                      errorMessages={['this field is required', 'email is not valid']}
                    />
                  </Grid>
                  {!this.state.email_valid ? (
                    <Grid
                      item
                      md={12}
                      style={{
                        color: 'red',
                        textAlign: 'center',
                      }}
                    >
                      Email Not Vaild
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              <br />
              <div
                style={{
                  paddingBottom: '18px',
                  paddingTop: '20px',
                }}
                align={'center'}
              >
                <Button
                  variant="outlined"
                  style={{
                    color: colorConstants.TEXT_BLUE,
                    borderColor: colorConstants.TEXT_BLUE,
                    marginRight: '10px',
                  }}
                  onClick={this.toggleEmailModal}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: colorConstants.TEXT_BLUE,
                    color: 'white',
                  }}
                  onClick={() => this.submitEmailForm()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal>
        </div>
        <Toaster />
      </Grid>
    );
  }
}

export default withRouter(PriceIndexDemo);
