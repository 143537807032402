import React, { useState } from 'react';
import { Toolbar, Grid, Button, Popper } from '@material-ui/core';

import { Link, withRouter } from 'react-router-dom';

import StyledNavLink from '../ux/StyledNavLink';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import NotificationsIcon from '@material-ui/icons/Notifications';
import InfluencerLogo from '../../assets/influencer-logo.png';
import { campaignActions } from '../../actions/campaign';
import { connect } from 'react-redux';

import { colorConstants } from '../../constants/colors';

function NavBar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const logOut = () => {
    //Facebook logout
    try {
      window.FB.api('/me/permissions', 'delete', null, () => window.FB.logout());
    } catch {}

    for (const key of ['access', 'refresh', 'user']) localStorage.removeItem(key);
    window.location = '/login';
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const name = user.user_type === 'brand' ? user.brand.name : user.influencer.name;
  return (
    <Toolbar className={'navbar-outer'}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item md={2}>
          <Grid container justify="flex-start">
            <Link to="/influencer/campaigns">
              <img
                src={InfluencerLogo}
                alt="influencer-logo"
                width={'160px'}
                // height={'35px'}
              ></img>
            </Link>
          </Grid>
        </Grid>
        <Grid item md={5}>
          <Grid
            container
            justify="space-evenly"
            style={{
              fontSize: 13,
            }}
          >
            <StyledNavLink to={'/influencer/campaigns'} label={'My Campaigns'} />
            <StyledNavLink to={'/influencer/past'} label={'Past Campaigns'} />
            <StyledNavLink to={'/influencer/settings/personal-settings'} label={'Account'} />
          </Grid>
        </Grid>
        <Grid item md={2}>
          <Grid container justify="space-evenly" alignItems="center">
            <Grid item style={{ visibility: 'hidden' }}>
              <NotificationsIcon />
            </Grid>
            <Grid item>
              <Grid container justify="space-evenly" alignItems="flex-start">
                <Grid item style={{ paddingTop: 2 }}>
                  Hi, {name}!
                </Grid>
                <Grid item>
                  <PersonOutlineIcon onClick={handleClick} />
                  <Popper open={open} anchorEl={anchorEl}>
                    <Button
                      variant="contained"
                      style={{
                        marginTop: '20px',
                        backgroundColor: colorConstants.TEXT_BLUE,
                        color: 'white',
                      }}
                      onClick={logOut}
                    >
                      Logout
                    </Button>
                  </Popper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

const mapStateToProps = (state) => ({
  loading: state.campaign.loading,
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(withRouter(NavBar));
