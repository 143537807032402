import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, CircularProgress, Box, Typography } from '@material-ui/core';
// import ViewIcon from '@material-ui/icons/VisibilityOutlined';
import ViewIcon from '../../assets/View.png';
import BlackThumpsUpIcon from '../../assets/Black_Thumbs_Up.png';
import BlackViewIcon from '../../assets/users_group.png';
import BlackEngIcon from '../../assets/Black_Engagement.png';
import BlackCPVIcon from '../../assets/Black_CPV.png';
import BlackCPEIcon from '../../assets/Black_CPE.png';

import CountDisplay from '../ux/CountDisplay';

import { campaignActions } from '../../actions/campaign.js';
import { connect } from 'react-redux';

import { ShortlistedInfluencerHeaderSummary } from '../../api_handlers/campaign.js';

class SummaryHeader extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      summary: this.props.summary,
    };
  }

  // componentDidMount() {
  //     ShortlistedInfluencerHeaderSummary().then((data) =>
  //         this.setState({summary: data})
  //     )
  // }
  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState(nextProps);
    }
  }

  render() {
    return (
      <Grid
        justify="space-evenly"
        container
        style={{
          background: 'white',
          border: '0.805172px solid #DBDBDB',
          height: '75px',
          paddingTop: '10px',
        }}
      >
        <Grid item>
          <CountDisplay
            icon={BlackThumpsUpIcon}
            label={'Approved Influencers'}
            count={this.state.summary.accepted_influencer}
            color={'#7158F1'}
          />
        </Grid>
        <Grid item>
          <CountDisplay
            icon={BlackViewIcon}
            info={true}
            label={'Estimated View'}
            count={this.state.summary.estimated_view}
            insta_count={this.state.summary.insta_estimated_view}
            youtube_count={this.state.summary.youtube_estimated_view}
            color={'#7158F1'}
          />
        </Grid>
        <Grid item>
          <CountDisplay
            icon={BlackEngIcon}
            info={true}
            label={'Estimated Engagement'}
            count={this.state.summary.estimated_engagement}
            insta_count={this.state.summary.insta_estimated_engagement}
            youtube_count={this.state.summary.youtube_estimated_engagement}
            color={'#7158F1'}
          />
        </Grid>
        <Grid item>
          <CountDisplay
            icon={BlackCPEIcon}
            info={true}
            label={'Estimated CPE'}
            count={this.state.summary.estimated_cpe}
            insta_count={this.state.summary.insta_estimated_cpe}
            youtube_count={this.state.summary.youtube_estimated_cpe}
            color={'#7158F1'}
            money={true}
          />
        </Grid>
        <Grid item>
          <CountDisplay
            icon={BlackCPVIcon}
            info={true}
            label={'Estimated CPV'}
            count={this.state.summary.estimated_cpv}
            insta_count={this.state.summary.insta_estimated_cpv}
            youtube_count={this.state.summary.youtube_estimated_cpv}
            color={'#7158F1'}
            money={true}
          />
        </Grid>
        <Grid item>
          <Box position="relative" display="inline-flex">
            <CircularProgress
              variant="determinate"
              value={(this.state.summary.budget_remaining / this.state.summary.budget) * 100}
              color={'secondary'}
              size={60}
              thickness={8}
            />
            <Box
              top={15}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="block"
              alignItems="center"
              justifyContent="center"
            >
              <div
                variant="h6"
                component="div"
                color="textSecondary"
                style={{
                  fontSize: '12px',
                  'font-weight': 'bold',
                }}
              >
                {this.state.summary.budget_remaining_string}
              </div>
              <div color="textSecondary" style={{ fontSize: '6px' }}>
                Remaining
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.campaign.loading,
});

const actionCreators = {
  getCampaignDetails: campaignActions.getCampaignDetails,
};

export default connect(mapStateToProps, actionCreators)(withRouter(SummaryHeader));
