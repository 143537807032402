import React from 'react';
import { Grid, List, ListItem, ListItemText, Popper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@mui/icons-material/Info';

const styles = {
  root: {
    cursor: 'pointer',
    margin: '10px 0px',
  },
  awardList: {
    background: '#FEFEFE',
    width: '400px',
    height: '200px',
    overflowY: 'scroll',
    boxShadow: '3px 3px 5px 0px rgba(0,0,0,0.54)',
    borderRadius: '10px',
  },
};

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      element: null,
    };
  }

  closePopup() {
    this.setState({
      element: null,
    });
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          className={classes.root}
          onClick={(event) => {
            this.props.beforeClick.call(this.props.parent, this.props.id);
            setTimeout(() => {
              this.setState({
                element: this.state.element ? null : event.target,
              });
            }, 200);
          }}
          aria-describedby={this.id}
        >
          <Grid item sm="auto" xs="auto" md="auto" lg="auto" style={{ marginRight: 10 }}>
            <img src={this.props.icon} />
          </Grid>
          <Grid item sm="auto" xs="auto" md="auto" lg="auto" style={{ margin: '0px' }}>
            <span>
              <h2>{this.props.title}</h2>
            </span>
          </Grid>
          <Grid
            item
            sm="auto"
            xs="auto"
            md="auto"
            lg="auto"
            style={{
              marginTop: '2px',
              paddingLeft: '2px',
              color: '#9E9E9E',
            }}
          >
            <InfoIcon />
          </Grid>
        </Grid>
        <Popper id={this.props.id} open={Boolean(this.state.element)} anchorEl={this.state.element}>
          <List
            component="nav"
            aria-label="secondary mailbox folders"
            className={classes.awardList}
          >
            {this.props.listItems.map((award, i) => (
              <ListItem
                key={i}
                button
                onClick={() => {
                  this.props.onSelect.call(this.props.parent, award);
                  this.setState({
                    element: null,
                  });
                }}
              >
                <ListItemText primary={award} />
              </ListItem>
            ))}
          </List>
        </Popper>
      </>
    );
  }
}

export default withStyles(styles)(Category);
