import * as React from 'react';
import { TextField, Box, Popper, Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DateRangePicker } from 'react-date-range';

import moment from 'moment';

export default function SimplePopper(props) {
  const { dateWidth } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [dateState, setDateState] = React.useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div>
      <TextField
        variant="outlined"
        placeholder="Select Date Range"
        size="small"
        value={
          props.startDate && props.endDate
            ? moment(props.startDate).format('DD/MM/YYYY') +
              '-' +
              moment(props.endDate).format('DD/MM/YYYY')
            : ''
        }
        onClick={handleClick}
        InputLabelProps={{ shrink: false }}
        aria-describedby={id}
        style={{
          fontSize: 10,
          width: dateWidth ? dateWidth : '100%',

          background: '#FFF',
        }}
        InputProps={{
          style: {
            fontSize: 13,
          },
        }}
      />
      <Popper id={id} open={open} anchorEl={anchorEl} {...props} style={{ zIndex: 10 }}>
        <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
          <Grid container>
            <DateRangePicker
              onChange={(item) => setDateState([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateState}
              direction="horizontal"
            />
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                style={{
                  fontSize: 12,
                  backgroundColor: 'white',
                  color: '#444',
                }}
                onClick={handleClick}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{
                  fontSize: 12,
                  backgroundColor: '#444',
                  color: 'white',
                }}
                onClick={(event) => {
                  handleClick(event);
                  props.onSelect(dateState);
                }}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popper>
    </div>
  );
}
