import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import IconLabel from 'components/Theme/IconLabel';

function Filter({ children, label, labelIconSrc }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Grid container style={{ marginBottom: 10 }}>
          <Grid item md={12}>
            <IconLabel imageSrc={labelIconSrc} label={label} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {},
}));

export default Filter;
