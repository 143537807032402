import {
  esFetch,
  apiServer
} from './index.js';

import { API_HOST } from 'config.js';


export function getInstagramStatisticES(params) {
  const queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');

  return esFetch(`instagram/post/stats?${queryString}`);
}


export function getInstagramTopPerformersES(metric, startDate, endDate) {
  return esFetch(
    `instagram/post/top-performers?metric=${metric}&start_date=${startDate}&end_date=${endDate}`,
  );
}


export function getInstagramEngagmentGraphDataES() {
  return esFetch('instagram/post/graph/engagement-over-time');
}

export function getYoutubeStatisticES(params) {
  if (!params || typeof params !== 'object') {
    return esFetch(`youtube/video/stats`);
  }
  const queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  
  return esFetch(`youtube/video/stats?${queryString}`);
}


export function getYoutubeTopPerformersES(campaign_id, metric, startDate, endDate) {
  return esFetch(
    `youtube/video/top-performers?metric=${metric}&start_date=${startDate}&end_date=${endDate}`,
  );
}


export function getYoutubeEngagmentGraphDataES() {
  return esFetch('youtube/video/graph/engagement-over-time');
}


export function getDiscoverySearchResult(query, source) {
  return apiServer
  .get(`/es/discover/search?query=${encodeURIComponent(query)}`, { cancelToken: source.token })
    .then((response) => {
      return response.data;
    });
}

export function getDiscoverySearchResultv2(query, source, platform) {
  return apiServer
  .get(`/es/discover/search-v2?query=${encodeURIComponent(query)}&platform=${platform}`, { cancelToken: source.token })
    .then((response) => {
      return response.data;
    });
}

export function getPlannerInfluencersES(payload = {}, page = 1) {
  return fetch(`${API_HOST}/api/v1/es/planner/list?page=${page}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ payload: payload }),
  }).then((response) => {
    return response;
  });
}


export function getPlannerRecommendedInfluencersES(payload = {}, page = 1) {
  return fetch(`${API_HOST}/api/v1/es/planner/recommendations?page=${page}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ payload: payload }),
  }).then((response) => {
    return response;
  });
}


export function getPlannerPredictionES(payload = {}) {
  return fetch(`${API_HOST}/api/v1/es/planner/prediction`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ payload: payload }),
  }).then((response) => {
    return response;
  });
}