import { Grid, makeStyles, Typography } from '@material-ui/core';
import CommentIcon from 'assets/discovery/comment-icon.png';
import LikeIcon from 'assets/discovery/like-icon.png';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getHandlerData } from 'api_handlers/competitor';
import insta_placeholder from 'assets/discovery/insta_placeholder.png';
import ImageValidator from 'components/ux/ImageValidator';

export default function CreatorDetailedInsightsCard({ keyword, searchKeyword, paidPost }) {
  const classes = useStyles();
  const [userData, setUserData] = useState([]);
  let [pageNumber, setPageNumber] = useState(1);
  const [isNext,setIsNext]=useState(true)
  const queryParameters = new URLSearchParams(window.location.search);
  const [type, setType] = useState(queryParameters.get('creator_type'));

  useEffect(() => {
    const fetchHandlerData = async () => {
      try {
        const resp = await getHandlerData(keyword, type, pageNumber, searchKeyword, paidPost);
        const mergedData = [...userData, ...resp.results];
        if(resp.next){
          setIsNext(true)
        }else{
          setIsNext(false)
        }
        setUserData(mergedData);
      } catch (error) {
        console.error('Error fetching creator posts:', error);
      }
    };

    fetchHandlerData();
  }, [pageNumber]);

  function fetchMoreData() {
    console.log('userData',userData)
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  }

  function countertostring(counter) {
    counter = parseFloat(counter);
    if (counter >= 1000 && counter < 999999) {
      return (
        parseFloat(counter / 1000.0)
          .toFixed(2)
          .toString() + 'K'
      );
    } else if (counter > 999999) {
      return (
        parseFloat(counter / 1000000.0)
          .toFixed(2)
          .toString() + 'M'
      );
    } else {
      return counter.toString();
    }
  }

  return (
    <InfiniteScroll
      dataLength={userData.length}
      next={fetchMoreData}
      hasMore={isNext}
      loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
              <b>No more posts are found.</b>
            </p>
          // isPlanActive ? (
            
          // ) : (
          //   ""
          // )
        }
    >
      <Grid container>
        {userData.length > 0
          ? userData.map((data, i) => (
              <Grid item md={3}>
                <Grid container justifyContent="space-between">
                  <Grid item className={classes.card} style={{ height: '100%', width: '100%' }}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item style={{ width: '90%' }} md={12}>
                        <Typography
                          className={classes.instaHandler}
                          onClick={() => {
                            window.open(`https://www.instagram.com/${data.created_by}`, '_blank');
                          }}
                        >
                          {data.created_by}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={11}
                        onClick={() => {
                          window.open(`https://www.instagram.com/p/${data.shortcode}`, '_blank');
                        }}
                      >
                        <ImageValidator 
                          className={classes.image} 
                          url={data.image_url} 
                          altText={"icon"}
                          placeContainerHolderStyle={{ marginBottom: '5px' }}
                          >
                          <Grid 
                            container 
                            justifyContent='center' 
                            alignItems='center' 
                            className={classes.image} 
                            style={{background:'#ffffff'}}>
                              <Grid item>
                              <img style={{width:100,height:100}} src={insta_placeholder}  alt="icon" />``
                              </Grid>
                          </Grid>
                        </ImageValidator>
                      </Grid>
                      <Grid item md={11}>
                        {data.caption && data.caption.length < 170 ? (
                          <Typography style={{ height: 100 }} variant="body2">
                            {data.caption}
                          </Typography>
                        ) : (
                          <Typography style={{ height: 100, overflowY: 'scroll' }} variant="body2">
                            {data.caption}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={12}>
                        <Grid container spacing={3} style={{ marginTop: 10 }}>
                          <Grid item>
                            <Grid container alignItems="center">
                              <Grid item>
                                <img src={LikeIcon} alt="likeicon" className={classes.icon} />
                              </Grid>
                              <Grid item>
                                <Typography className={classes.data} variant="h5">
                                {countertostring(data.likes_count)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container alignItems="center">
                              <Grid item>
                                <img src={CommentIcon} alt="commenticon" className={classes.icon} />
                              </Grid>
                              <Grid item>
                                <Typography className={classes.data} variant="h5">
                                {countertostring(data.comments_count)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))
          : ''}
      </Grid>
    </InfiniteScroll>
  );
}

const useStyles = makeStyles(() => ({
  card: {
    background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    padding: 20,
    maxWidth: 300,
    marginLeft: 20,
    marginTop: 50,
  },
  instaHandler: {
    fontSize: 18,
    textAlign: 'start',
    cursor: 'pointer',
    fontWeight: 600,
  },
  image: {
    height: 220,
    width: 220,
    borderRadius: 20,
    marginTop: 10,
    textAlign: 'center',
    cursor: 'pointer',
  },
  icon: {
    height: 20,
    width: 20,
    marginTop: 9,
  },
  data: {
    marginLeft: 3,
  },
}));
