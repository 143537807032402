import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Button, Paper } from '@material-ui/core';

import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import SocialMediaForm from '../forms/ConnectSocialMedia';
import MobileSocialMediaForm from '../forms/MobileConnectSocialMedia';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { updateInfluencerProfile } from '../../../api_handlers/influencer.js';

class ConnectSocialMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInstagramLinked: false,
      isYoutubeLinked: false,
    };
  }

  submitForm() {
    this.props.history.push('/influencer/settings/about-me');
  }

  get_form() {
    if (window.matchMedia('(min-width: 768px)').matches) {
      return <SocialMediaForm />;
    } else {
      return <MobileSocialMediaForm />;
    }
  }

  render() {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        style={{
          marginBottom: 30,
        }}
      >
        {this.get_form()}
        <Grid container style={{ marginTop: 40 }}>
          <Paper style={{ margin: 'auto', marginBottom: '50px' }}>
            <Button
              onClick={() => {
                this.submitForm();
              }}
              style={{
                background: '#1E9CB2',
                color: 'white',
                padding: '5px 20px',
                textTransform: 'none',
              }}
            >
              Save and Continue
            </Button>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(ConnectSocialMedia);
