import React, { useEffect, useState, Component } from 'react';
import { Grid, Typography, Radio, RadioGroup, Chip, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DownArrow from 'assets/planner/down-arrow.png';
import DownArrowActive from 'assets/planner/down-arrow-active.png';

class ListSelect extends Component {
  constructor(props) {
    super(props);
    this.selectOption = this.selectOption.bind(this);
    this.ref = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      all: false,
      recommended: true,
      normal: false,
    };
  }

  handleClickOutside(event) {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.props.setlistSelect(false);
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  selectOption(event) {
    console.log(event.target.value);
    let current_prediction = this.props.selectedPrediction;
    console.log(current_prediction);
    if (event.target.value === 'all') {
      if (event.target.checked) {
        console.log('checked');
        const influencers = this.props.selectedInfluencer;
        this.props.influencers.map((influencer) => {
          if (!influencers.includes(influencer.id)) {
            influencers.push(influencer.id);
            current_prediction['reach'] =
              current_prediction['reach'] + influencer.prediction.views * 1.37;
            current_prediction['views'] = current_prediction['views'] + influencer.prediction.views;
            current_prediction['enagaged_users'] =
              current_prediction['enagaged_users'] + influencer.prediction.enagaged_users;
            current_prediction['followers'] =
              current_prediction['followers'] + influencer.prediction.followers;
            current_prediction['engagement_rate'] =
              current_prediction['engagement_rate'] + influencer.prediction.engagement_rate;
            current_prediction['max_price'] =
              current_prediction['max_price'] + influencer.prediction.max_price;
            if (influencer.prediction.creator_type == 'micro') {
              current_prediction['micro'] = {
                count: current_prediction['micro']['count'] + 1,
                amount: current_prediction['micro']['amount'] + influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'mega') {
              current_prediction['mega'] = {
                count: current_prediction['mega']['count'] + 1,
                amount: current_prediction['mega']['amount'] + influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'nano') {
              current_prediction['nano'] = {
                count: current_prediction['nano']['count'] + 1,
                amount: current_prediction['nano']['amount'] + influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'macro') {
              current_prediction['macro'] = {
                count: current_prediction['macro']['count'] + 1,
                amount: current_prediction['macro']['amount'] + influencer.prediction.max_price,
              };
            }
          }
        });
        this.props.recommendation.map((influencer) => {
          if (!influencers.includes(influencer.id)) {
            influencers.push(influencer.id);
            current_prediction['reach'] =
              current_prediction['reach'] + influencer.prediction.views * 1.37;
            current_prediction['views'] = current_prediction['views'] + influencer.prediction.views;
            current_prediction['enagaged_users'] =
              current_prediction['enagaged_users'] + influencer.prediction.enagaged_users;
            current_prediction['followers'] =
              current_prediction['followers'] + influencer.prediction.followers;
            current_prediction['engagement_rate'] =
              current_prediction['engagement_rate'] + influencer.prediction.engagement_rate;
            current_prediction['max_price'] =
              current_prediction['max_price'] + influencer.prediction.max_price;
            if (influencer.prediction.creator_type == 'micro') {
              current_prediction['micro'] = {
                count: current_prediction['micro']['count'] + 1,
                amount: current_prediction['micro']['amount'] + influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'nano') {
              current_prediction['nano'] = {
                count: current_prediction['nano']['count'] + 1,
                amount: current_prediction['nano']['amount'] + influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'macro') {
              current_prediction['macro'] = {
                count: current_prediction['macro']['count'] + 1,
                amount: current_prediction['macro']['amount'] + influencer.prediction.max_price,
              };
            }
          }
        });
        this.props.setSelectedInfluencer(influencers);
        this.setState({ all: true });
      } else {
        console.log('unchecked');
        this.props.setSelectedInfluencer([]);
        current_prediction = {
          reach: 0,
          views: 0,
          engagement_rate: 0,
          enagaged_users: 0,
          followers: 0,
          max_price: 0,
          mega: {
            count: 0,
            amount: 0,
          },
          macro: {
            count: 0,
            amount: 0,
          },
          micro: {
            count: 0,
            amount: 0,
          },
          nano: {
            count: 0,
            amount: 0,
          },
        };
        this.setState({ all: false });
      }
    }
    if (event.target.value === 'recommended') {
      const influencers = this.props.selectedInfluencer;
      if (event.target.checked) {
        console.log('checked');

        this.props.recommendation.map((influencer) => {
          if (!influencers.includes(influencer.id)) {
            influencers.push(influencer.id);
            current_prediction['reach'] =
              current_prediction['reach'] + influencer.prediction.views * 1.37;
            current_prediction['views'] = current_prediction['views'] + influencer.prediction.views;
            current_prediction['enagaged_users'] =
              current_prediction['enagaged_users'] + influencer.prediction.enagaged_users;
            current_prediction['followers'] =
              current_prediction['followers'] + influencer.prediction.followers;
            current_prediction['engagement_rate'] =
              current_prediction['engagement_rate'] + influencer.prediction.engagement_rate;
            current_prediction['max_price'] =
              current_prediction['max_price'] + influencer.prediction.max_price;
            if (influencer.prediction.creator_type == 'micro') {
              current_prediction['micro'] = {
                count: current_prediction['micro']['count'] + 1,
                amount: current_prediction['micro']['amount'] + influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'mega') {
              current_prediction['mega'] = {
                count: current_prediction['mega']['count'] + 1,
                amount: current_prediction['mega']['amount'] + influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'nano') {
              current_prediction['nano'] = {
                count: current_prediction['nano']['count'] + 1,
                amount: current_prediction['nano']['amount'] + influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'macro') {
              current_prediction['macro'] = {
                count: current_prediction['macro']['count'] + 1,
                amount: current_prediction['macro']['amount'] + influencer.prediction.max_price,
              };
            }
          }
        });
        this.setState({ recommended: true });
      } else {
        console.log('unchecked');
        this.props.recommendation.map((influencer) => {
          if (influencers.includes(influencer.id)) {
            influencers.pop(influencer.id);
            current_prediction['reach'] =
              current_prediction['reach'] - influencer.prediction.views * 1.37;
            current_prediction['views'] = current_prediction['views'] - influencer.prediction.views;
            current_prediction['enagaged_users'] =
              current_prediction['enagaged_users'] - influencer.prediction.enagaged_users;
            current_prediction['followers'] =
              current_prediction['followers'] - influencer.prediction.followers;
            current_prediction['engagement_rate'] =
              current_prediction['engagement_rate'] - influencer.prediction.engagement_rate;
            current_prediction['max_price'] =
              current_prediction['max_price'] - influencer.prediction.max_price;
            if (influencer.prediction.creator_type == 'micro') {
              current_prediction['micro'] = {
                count: current_prediction['micro']['count'] - 1,
                amount: current_prediction['micro']['amount'] - influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'mega') {
              current_prediction['mega'] = {
                count: current_prediction['mega']['count'] - 1,
                amount: current_prediction['mega']['amount'] - influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'nano') {
              current_prediction['nano'] = {
                count: current_prediction['nano']['count'] - 1,
                amount: current_prediction['nano']['amount'] - influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'macro') {
              current_prediction['macro'] = {
                count: current_prediction['macro']['count'] - 1,
                amount: current_prediction['macro']['amount'] - influencer.prediction.max_price,
              };
            }
          }
        });
        this.setState({ recommended: false });
      }
      this.props.setSelectedInfluencer(influencers);
    }
    if (event.target.value === 'normal') {
      const influencers = this.props.selectedInfluencer;
      if (event.target.checked) {
        console.log('checked');
        this.props.influencers.map((influencer) => {
          if (!influencers.includes(influencer.id)) {
            influencers.push(influencer.id);
            current_prediction['reach'] =
              current_prediction['reach'] + influencer.prediction.views * 1.37;
            current_prediction['views'] = current_prediction['views'] + influencer.prediction.views;
            current_prediction['enagaged_users'] =
              current_prediction['enagaged_users'] + influencer.prediction.enagaged_users;
            current_prediction['followers'] =
              current_prediction['followers'] + influencer.prediction.followers;
            current_prediction['engagement_rate'] =
              current_prediction['engagement_rate'] + influencer.prediction.engagement_rate;
            current_prediction['max_price'] =
              current_prediction['max_price'] + influencer.prediction.max_price;
            if (influencer.prediction.creator_type == 'micro') {
              current_prediction['micro'] = {
                count: current_prediction['micro']['count'] + 1,
                amount: current_prediction['micro']['amount'] + influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'nano') {
              current_prediction['nano'] = {
                count: current_prediction['nano']['count'] + 1,
                amount: current_prediction['nano']['amount'] + influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'mega') {
              current_prediction['mega'] = {
                count: current_prediction['mega']['count'] + 1,
                amount: current_prediction['mega']['amount'] + influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'macro') {
              current_prediction['macro'] = {
                count: current_prediction['macro']['count'] + 1,
                amount: current_prediction['macro']['amount'] + influencer.prediction.max_price,
              };
            }
          }
        });
        this.setState({ normal: true });
      } else {
        console.log('unchecked');
        const influencers = this.props.selectedInfluencer;
        this.props.influencers.map((influencer) => {
          if (influencers.includes(influencer.id)) {
            influencers.pop(influencer.id);
            current_prediction['reach'] =
              current_prediction['reach'] - influencer.prediction.views * 1.37;
            current_prediction['views'] = current_prediction['views'] - influencer.prediction.views;
            current_prediction['enagaged_users'] =
              current_prediction['enagaged_users'] - influencer.prediction.enagaged_users;
            current_prediction['followers'] =
              current_prediction['followers'] - influencer.prediction.followers;
            current_prediction['engagement_rate'] =
              current_prediction['engagement_rate'] - influencer.prediction.engagement_rate;
            current_prediction['max_price'] =
              current_prediction['max_price'] - influencer.prediction.max_price;
            if (influencer.prediction.creator_type == 'micro') {
              current_prediction['micro'] = {
                count: current_prediction['micro']['count'] - 1,
                amount: current_prediction['micro']['amount'] - influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'nano') {
              current_prediction['nano'] = {
                count: current_prediction['nano']['count'] - 1,
                amount: current_prediction['nano']['amount'] - influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'mega') {
              current_prediction['mega'] = {
                count: current_prediction['mega']['count'] - 1,
                amount: current_prediction['mega']['amount'] - influencer.prediction.max_price,
              };
            }
            if (influencer.prediction.creator_type == 'macro') {
              current_prediction['macro'] = {
                count: current_prediction['macro']['count'] - 1,
                amount: current_prediction['macro']['amount'] - influencer.prediction.max_price,
              };
            }
          }
        });
        this.setState({ normal: false });
      }
      this.props.setSelectedInfluencer(influencers);
    }
    this.props.setSelectedPrediction(current_prediction);
  }

  render() {
    let {
      classes,
      count,
      listSelect,
      setlistSelect,
      recommendation_count,
      influencers,
      selectedInfluencer,
      setSelectedInfluencer,
      recommendation,
      setRecommendation,
    } = this.props;

    return (
      <div style={{ position: 'relative', zIndex: 10 }}>
        <Checkbox color="primary" size="small" />
        <img
          onClick={() => {
            setlistSelect();
          }}
          className={classes.downArrow}
          src={listSelect ? DownArrowActive : DownArrow}
          width={12}
          height={8}
        />
        {listSelect ? (
          <div className={classes.modal} ref={this.ref}>
            <div>
              <div className={classes.option}>
                {this.state.all ? (
                  <Checkbox
                    color="primary"
                    size="small"
                    value={'all'}
                    onChange={this.selectOption}
                    checked
                  />
                ) : (
                  <Checkbox
                    color="primary"
                    size="small"
                    value={'all'}
                    onChange={this.selectOption}
                  />
                )}
                <Typography className={classes.optionLabel}>All </Typography>
                <Chip
                  size="small"
                  label={`(${influencers.length + recommendation_count}/${count})`}
                  className={classes.font14}
                  style={{ color: '#757575', backgroundColor: 'white' }}
                />
              </div>
              <div className={classes.option}>
                {this.state.recommended ? (
                  <Checkbox
                    color="primary"
                    size="small"
                    value={'recommended'}
                    onChange={this.selectOption}
                    checked
                  />
                ) : (
                  <Checkbox
                    color="primary"
                    size="small"
                    value={'recommended'}
                    onChange={this.selectOption}
                  />
                )}
                <Typography className={classes.optionLabel}>Recommended </Typography>
                <Chip
                  size="small"
                  label={`(${recommendation_count}/${recommendation.length})`}
                  className={classes.font14}
                  style={{ color: '#757575', backgroundColor: 'white' }}
                />
              </div>
              <div className={classes.option}>
                {this.state.normal ? (
                  <Checkbox
                    color="primary"
                    size="small"
                    value={'normal'}
                    onChange={this.selectOption}
                    checked
                  />
                ) : (
                  <Checkbox
                    color="primary"
                    size="small"
                    value={'normal'}
                    onChange={this.selectOption}
                  />
                )}
                <Typography className={classes.optionLabel}>Other Influencers</Typography>
                <Chip
                  size="small"
                  label={`(${influencers.length}/${count - recommendation.length})`}
                  className={classes.font14}
                  style={{ color: '#757575', backgroundColor: 'white' }}
                />
              </div>
              <div className={classes.option}>
                <Typography className={classes.optionLabel}>Selected </Typography>
                <Chip
                  size="small"
                  label={`(${selectedInfluencer.length})`}
                  className={classes.font14}
                  style={{ color: '#757575', backgroundColor: 'white' }}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const styles = {
  modal: {
    background: '#ffffff',
    position: 'absolute',
    width: 300,
    top: 50,
    padding: 20,
    left: 55,
    boxShadow: '5px 4px 20px rgba(104, 104, 104, 0.25)',
    borderRadius: '30px',
    zIndex: 9999,
  },
  heading: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 16,
  },
  option: {
    // background: '#F1F1F1',
    borderRadius: '10px',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    margin: 5,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  optionLabel: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 16,
  },
  font14: {
    fontSize: 14,
  },
};

export default withStyles(styles)(ListSelect);
