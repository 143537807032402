import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';


import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import SubCard from 'ui-component/cards/SubCard';
import Spends from './Spends';

import config, { ThemeMode } from 'config';




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));



export default function AdvancedFilters({ open, setOpen }) {
  const predictions = useSelector(state => state.planner2.predictions);

  const theme = useTheme();
  const handleClose = () => {
    setOpen(false);
  };


  const blockSX = {
    p: 2.5,
    borderLeft: '1px solid ',
    borderBottom: '1px solid ',
    borderLeftColor: theme.palette.mode === ThemeMode.DARK ? 'dark.main' : 'grey.200',
    borderBottomColor: theme.palette.mode === ThemeMode.DARK ? 'dark.main' : 'grey.200'
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Predictions
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Spends predictions={predictions} />
            </Grid>
            <Grid item xs={6}>
              <SubCard title={`You could reach ${predictions.reach} Users with ₹ ${predictions.total_spent}`}>
                <Grid container alignItems="center" spacing={0}>
                  <Grid item xs={12} sm={6} sx={blockSX}>
                    <Grid
                      container
                      alignItems="center"
                      spacing={1}
                      justifyContent={{ xs: 'space-between', sm: 'center' }}
                    >
                      <Grid item sm zeroMinWidth>
                        <Typography variant="h5" align="center">
                          {predictions.reach}
                        </Typography>
                        <Typography variant="subtitle2" align="center">
                          REACH
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={blockSX}>
                    <Grid
                      container
                      alignItems="center"
                      spacing={1}
                      justifyContent={{ xs: 'space-between', sm: 'center' }}
                    >

                      <Grid item sm zeroMinWidth>
                        <Typography variant="h5" align="center">
                          {predictions.views}
                        </Typography>
                        <Typography variant="subtitle2" align="center">
                          Views
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={0}>
                  <Grid item xs={12} sm={6} sx={blockSX}>
                    <Grid
                      container
                      alignItems="center"
                      spacing={1}
                      justifyContent={{ xs: 'space-between', sm: 'center' }}
                    >

                      <Grid item sm zeroMinWidth>
                        <Typography variant="h5" align="center">
                          {predictions.engagements}
                        </Typography>
                        <Typography variant="subtitle2" align="center">
                          ENGAGEMENTS
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={blockSX}>
                    <Grid
                      container
                      alignItems="center"
                      spacing={1}
                      justifyContent={{ xs: 'space-between', sm: 'center' }}
                    >

                      <Grid item sm zeroMinWidth>
                        <Typography variant="h5" align="center">
                          {predictions.engagement_rate}%
                        </Typography>
                        <Typography variant="subtitle2" align="center">
                          ENGAGEMENT_RATE
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SubCard>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
