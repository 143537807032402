import { Component } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { Container, Grid, InputBase } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import SidePanel from './SidePanel';
import InstagramFeed from './InstagramFeed';
import YoutubeFeed from './YoutubeFeed';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FFF',
    border: '0.5px solid #C8C8C8',
    borderRadius: '5px',
    fontSize: 11,
    paddingLeft: '15px',
    height: '20px',
    width: '100px',
  },
}))(InputBase);

class Feed extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Grid container spacing={3} style={{ marginTop: 20 }}>
        <Grid item md={2}>
          <SidePanel />
        </Grid>
        <Grid item md={10}>
          <Switch>
            <Route
              exact
              path={`${this.props.match.url}/youtube`}
              component={() => <YoutubeFeed {...this.props} />}
            />
            <Route
              exact
              path={`${this.props.match.path}/instagram`}
              component={() => <InstagramFeed {...this.props} />}
            />
          </Switch>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Feed);
