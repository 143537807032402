import * as React from 'react';
import { Grid, Typography, Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Drag from 'assets/planner/drag.png';

class PriorityTemplate extends React.Component {
  render() {
    let { classes, item, dragHandleProps } = this.props;

    return (
      <div
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        className="dragHandle"
        {...dragHandleProps}
      >
        <Typography className={classes.number}>{item.id}</Typography>
        <img src={Drag} width={7} height={12} style={{ marginRight: 3 }} />
        <Typography className={classes.label}>{item.name}</Typography>
      </div>
    );
  }
}

const styles = {
  number: {
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: 16,
    color: '#FEBD1C',
    marginLeft: 5,
    marginRight: 10,
  },
  label: {
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    background: '#F1F1F1',
  },
};

export default withStyles(styles)(PriorityTemplate);
