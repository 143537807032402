import React from 'react';
import { useEffect, useState, Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { campaignTypesYoutubeFetch } from 'api_handlers/competitor';

class Summary extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.calculateEngagementRate = this.calculateEngagementRate.bind(this);
    this.countertostring = this.countertostring.bind(this);

    this.state = {
      summaryData: {},
    };
  }

  calculateEngagementRate(likes, comments, shares, views) {
    likes = likes !== null ? likes : 0;
    comments = comments !== null ? comments : 0;
    shares = shares !== null ? shares : 0;
    if (views === 0 || views === null) {
      return 0;
    }
    const engagementRate = ((likes + comments + shares) / views) * 100;
    return parseFloat(engagementRate.toFixed(2));
  }

  countertostring(counter) {
    counter = parseFloat(counter);
    if (counter >= 1000 && counter < 999999) {
      return (
        parseFloat(counter / 1000.0)
          .toFixed(2)
          .toString() + 'K'
      );
    } else if (counter > 999999) {
      return (
        parseFloat(counter / 1000000.0)
          .toFixed(2)
          .toString() + 'M'
      );
    } else {
      return counter.toString();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    try {
      const resp = await campaignTypesYoutubeFetch(
        this.props.brandName,
        this.props.startDate,
        this.props.endDate,
        this.props.searchKeyword,
        this.props.paidPost
      );
      this.setState({ summaryData: resp.summary });
    } catch (error) {
      console.error('Error fetching summary:', error);
    }
  }

  render() {
    const { classes } = this.props;

    const { summaryData } = this.state;

    let engagement_rate = this.calculateEngagementRate(
      summaryData['likes_count'],
      summaryData['comments_count'],
      0,
      summaryData['view_count'],
    );
    let reach = 0;

    if (summaryData['view_count'] > 0) {
      reach = summaryData['view_count'] - summaryData['view_count'] * 0.1;
    }

    return (
      <Grid container spacing={3}>
        <Grid item md={12} className={classes.header}>
          <Grid item md={12}>
            <Typography style={{ color: 'black' }}>SUMMARY</Typography>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid container justifyContent="space-between">
            <Grid item className={classes.summaryItem}>
              <div className={classes.summary_label}>Creators</div>
              <div className={classes.summary_value}>
                {this.countertostring(summaryData['creators']) || 0}
              </div>
            </Grid>
            <Grid item className={classes.summaryItem}>
              <div className={classes.summary_label}>Videos</div>
              <div className={classes.summary_value}>
                {this.countertostring(summaryData['count']) || 0}
              </div>
            </Grid>
            <Grid item className={classes.summaryItem}>
              <div className={classes.summary_label}>View</div>
              <div className={classes.summary_value}>
                {this.countertostring(summaryData['view_count']) || 0}
              </div>
            </Grid>
            <Grid item className={classes.summaryItem}>
              <div className={classes.summary_label}>Comments</div>
              <div className={classes.summary_value}>
                {this.countertostring(summaryData['comments_count']) || 0}
              </div>
            </Grid>
            <Grid item className={classes.summaryItem}>
              <div className={classes.summary_label}>Likes</div>
              <div className={classes.summary_value}>
                {this.countertostring(summaryData['likes_count']) || 0}
              </div>
            </Grid>
            <Grid item className={classes.summaryItem}>
              <div className={classes.summary_label}>Engagement Rate</div>
              <div className={classes.summary_value}>{engagement_rate} %</div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = {
  root: {
    // background: "white",
    // boxShadow:
    //   "-5px 5px 10px rgba(215, 216, 224, 0.2), 5px -5px 10px rgba(215, 216, 224, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 224, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 224, 0.5)",
    // borderRadius: 10,
    margin: 10,
    paddingTop: 0,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30,
    display: 'flex',
    // marginLeft: 30,
    // marginTop: 20,
  },
  header: {
    color: '#4A4A4A',
    fontSize: '20px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    lineHeight: 1.167,
    letterSpacing: '0.03em',
    padding: '15px',
    background: '#F3F4FE',
    boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    borderRadius: '10px',
    marginBottom: '15px',
    marginTop: '18px',
  },
  heading: {
    textAlign: 'left',
    marginLeft: 30,
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: 20,
    color: 'black',
  },
  summary_box: {
    display: 'flex',
    marginLeft: 30,
    marginTop: 20,
  },
  summaryItem: {
    marginTop: 10,
    borderRadius: 15,
    padding: 10,
    width: 180,
    marginRight: 30,
    height: 90,
    color: 'black',
    background: '#EFF0F9',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 224, 0.2), 5px -5px 10px rgba(215, 216, 224, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 224, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 224)',
    borderRadius: 10,
  },
  summary_label: {
    color: 'rgb(74, 74, 74)',
    fontSize: 20,
    fontFamily: 'Nunito Sans sans-serif',
  },
  summary_value: {
    marginTop: 10,
    color: 'rgb(74, 74, 74)',
    fontSize: 20,
    fontFamily: 'Nunito Sans sans-serif',
    fontWeight: 700,
  },
};

export default withStyles(styles)(Summary);
