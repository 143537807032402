import * as React from 'react';
import { Grid, Typography, TextField, Checkbox, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Chip } from '@material-ui/core';

import ObjectiveOption from './ObjectiveOption';

import { getFieldOfInterest } from '../../../../../api_handlers/influencer';

// const interestsMap = [
//     "Actor",
//     "Agriculture",
//     "Art & Craft",
//     "Automobile",
//     "Beauty",
//     "Business",
//     "Career Coach",
//     "Comedy",
//     "Crypto",
//     "Dance",
//     "Decor",
//     "Digital Marketing",
//     "Education",
//     "Entertainment",
//     "Entertainment Page",
//     "Fan Page",
//     "Fashion",
//     "Finance",
//     "Fitness",
//     "Food",
//     "Gadget review",
//     "Gaming",
//     "Gardening",
//     "Graphic Designer",
//     "Haircare",
//     "Health",
//     "Infotainment",
//     "Investment",
//     "Kids",
//     "Legal",
//     "Lifestyle",
//     "Literature",
//     "Meme Page",
//     "Men's Grooming",
//     "Model",
//     "Motor Vloggers",
//     "Movie/TV Review",
//     "Music",
//     "News & Media",
//     "Nutrition",
//     "Parenting",
//     "Pets",
//     "Photography",
//     "Product Review",
//     "Self-Help & Motivation",
//     "Skincare",
//     "Spirituality",
//     "Sports",
//     "Stock Market",
//     "Sustainability",
//     "Technology",
//     "Theatre",
//     "Travel",
//     "Website & Tools",
//     "Yoga"
// ]

class Category extends React.Component {
  constructor(props) {
    super(props);

    this.loadInterest = this.loadInterest.bind(this);

    this.state = {
      interestText: '',
      valueText: '',
      checkList: '',
      isCheck: Array.from(new Set(this.props.category)),
      interestsMap: [],
    };
  }

  async loadInterest() {
    let data = await getFieldOfInterest();
    let interestsMap = [];
    data.map((each) => {
      if (interestsMap.indexOf(each.name) == -1) {
        interestsMap.push(each.name);
      }
    });
    interestsMap.push('Other');
    this.setState({ interestsMap });
  }

  componentDidMount() {
    this.loadInterest();
  }

  handleChange = (event) => {
    if (event.target.checked === true) {
      this.setState({
        isCheck: this.state.isCheck.concat(event.target.value),
      });
    }
    if (event.target.checked === false) {
      const index = this.state.isCheck.indexOf(event.target.value);
      if (index > -1) {
        this.state.isCheck.splice(index, 1);
        this.setState({
          isCheck: this.state.isCheck,
        });
      }
    }
  };

  handleDelete = (t) => {
    this.props.category.splice(this.props.category.indexOf(t), 1);
    this.state.isCheck.splice(this.state.isCheck.indexOf(t), 1);
    this.setState({
      isCheck: this.state.isCheck,
    });
  };

  render() {
    let { selected, setSelectedObjective, classes, category } = this.props;

    let interestText = this.state.interestText;

    return (
      <div
        onClick={() => {
          setSelectedObjective('Category');
        }}
        style={{ position: 'relative' }}
      >
        <ObjectiveOption {...(selected ? { selected: true } : null)}>Category</ObjectiveOption>
        {selected ? (
          <div className={classes.modal}>
            <div style={{ width: '100%', display: 'flex' }}>
              <TextField
                placeholder="Search"
                className={classes.search}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <IconButton
                      sx={{ visibility: interestText ? 'visible' : 'hidden' }}
                      onClick={() =>
                        this.setState({
                          valueText: '',
                          interestText: '',
                        })
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                value={this.state.valueText}
                onChange={(e) => {
                  this.setState({
                    interestText: e.target.value,
                    valueText: e.target.value,
                  });
                }}
              />
              <Button
                className={classes.resetButton}
                onClick={() => {
                  this.props.setFilters('category', []);
                  this.setState({ isCheck: [] });
                }}
              >
                Reset
              </Button>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                margin: 10,
                flexWrap: 'wrap',
                height: '300px',
                overflowY: 'scroll',
                alignContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              {this.state.isCheck.map((tags) => {
                if (category.indexOf(tags) > -1) {
                  return (
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                      <Chip
                        label={tags}
                        variant="outlined"
                        onDelete={() => {
                          this.handleDelete(tags);
                        }}
                        className={classes.chips}
                      />
                    </div>
                  );
                }
              })}
              <div style={{ display: 'inline-block', height: '1px', width: '100%' }}></div>
              {this.state.interestsMap.map((each) => {
                if (each.toLowerCase().indexOf(interestText.toLowerCase()) > -1) {
                  return (
                    <div className={classes.option}>
                      <Checkbox
                        color="primary"
                        checked={category.indexOf(each) > -1}
                        value={each}
                        onClick={(event) => {
                          if (category.indexOf(each) > -1) {
                            category.splice(category.indexOf(each), 1);
                          } else {
                            category.push(each);
                          }
                          // this.setState({
                          //     isCheck: this.state.isCheck.concat(category)
                          // })
                          this.props.setFilters('category', category);
                        }}
                        onChange={this.handleChange}
                      />
                      {each}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const styles = {
  modal: {
    background: '#ffffff',
    position: 'absolute',
    width: 450,
    top: 80,
    padding: 20,
    boxShadow: '5px 4px 20px rgba(104, 104, 104, 0.25)',
    borderRadius: '30px',
    zIndex: 5,
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  search: {
    border: '1px solid #C4C4C4',
    borderRadius: '30px',
    width: 300,
    outline: 'none',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 10,
  },
  option: {
    background: '#F1F1F1',
    borderRadius: '10px',
    paddingLeft: 10,
    paddingRight: 10,
    margin: 5,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  // chipset:{
  //     color: "blue",
  //     fontFamily: "Nunito Sans",
  //     background: "#FFFFFF",
  //     borderRadius: 34,
  //     borderWidth: 1,
  //     borderColor: "#C9C9C9",
  //     // color: color,
  //     fontSize: 12,
  //     fontWeight: 400,
  //     cursor: "pointer",
  //   },
};

export default withStyles(styles)(Category);
