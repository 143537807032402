import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function CategoryChip({ label, color }) {
  const classes = useStyles({ color });
  return <Chip label={label} variant="outlined" className={classes.root} />;
}

const useStyles = makeStyles(() => ({
  root: ({ color }) => ({
    background: '#EFF0F4',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: color,
  }),
}));

export default CategoryChip;
