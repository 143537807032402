import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { updateFilters } from 'actions/planner-filters';


function formatNumber(num) {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(0) + 'B';
  }
  if (num >= 1e6) {
    return (num / 1e6).toFixed(0) + 'M';
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(0) + 'K';
  }
  return num.toString();
}


const StyledTypography = styled(Typography)({
  wordWrap: 'break-word',
  // Alternatively, you can use overflowWrap
  // overflowWrap: 'break-word',
});

const SliderFilter = ({title, min=0, max=100, showTextField=false, useScale=false}) => {
  const dispatch = useDispatch();
  const genderSliderValue = useSelector((state) => state.plannerFilters.audienceGenderSliderValue)
  const genderSelectValue = useSelector((state) => state.plannerFilters.audienceGenderSelectValue)

  return (
    <Grid container spacing={2} alignItems='center' justifyContent='space-evenly' sx={{ mb: 2 }}>
          <Grid item xs={3}>
            <StyledTypography variant="h6" gutterBottom>
              {title}
            </StyledTypography>
          </Grid>
          <Grid item xs={3}>
              <Select
                  labelId="gender-select"
                  id="gender"
                  name="gender"
                  value={genderSelectValue}
                  onChange={(event) => {
                      dispatch(updateFilters('audienceGenderSelectValue', event.target.value))
                  }}
                  label="Platform"
                  fullWidth
                  size='small'
              >
                  <MenuItem value={'male'}>Male</MenuItem>
                  <MenuItem value={'female'}>Female</MenuItem>
              </Select>

          </Grid>
          <Grid item xs={5}>
            <Slider
              value={genderSliderValue}
              onChange={(event, newValue) => {
                dispatch(updateFilters('audienceGenderSliderValue', newValue))
              }}
              valueLabelDisplay="auto"
              getAriaValueText={(value) => formatNumber(value)}
              valueLabelFormat={(value) => formatNumber(value)}
              max={max}
              min={min}
            />
          </Grid>
          {showTextField && (<Grid item xs={3}>
            <TextField size='small' value={genderSliderValue}
            // endAdornment={
            //     <InputAdornment position="end">
            //       <CurrencyRupeeIcon />
            //     </InputAdornment>
            //   }
              />
          </Grid>)}
        </Grid>
  )
}

export default SliderFilter