import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Drawer, List, Divider, ListItem, ListItemIcon } from '@material-ui/core';
import StyledNavLink from '../ux/StyledNavLink';
import { withRouter } from "react-router-dom";
import YouTubeIcon from '../../assets/youtube_social_icon_dark.png'

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    top: 100,
    '& .MuiDrawer-paper': {
      top: 75,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      height: '100%',
    },
    '& .MuiListItemIcon-root': {
      minWidth: 20,
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

class BaseSidePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
    };
  }

  handleDrawerOpen = () => {
    this.setState({ drawerOpen: true });
    if (this.props.handleDrawerAction) {
      this.props.handleDrawerAction(true);
    }
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
    if (this.props.handleDrawerAction) {
      this.props.handleDrawerAction(false);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: this.state.drawerOpen,
            [classes.drawerClose]: !this.state.drawerOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: this.state.drawerOpen,
              [classes.drawerClose]: !this.state.drawerOpen,
            }),
          }}
          onMouseEnter={() => {
            this.handleDrawerOpen();
          }}
          onMouseLeave={() => {
            this.handleDrawerClose();
          }}
        >
          {this.props.renderHeader ? (
            <>
              {this.props.renderHeader(this.state)}
              <Divider />
            </>
          ) : (
            ''
          )}
          <List>
            {this.props.navigationLinks.map((link, index) => (
              <div key={index}>
                <ListItem button key={link.to}
                onClick={()=>this.props.history.push(link.to) }
                >
                  <ListItemIcon>
                    { link.label == 'Youtube' ? <img src={YouTubeIcon} style={{ height: 20 }}/> : <link.icon />}
                  </ListItemIcon>
                  <span style={{ fontSize: '13px' }}>
                    {this.state.drawerOpen ? <StyledNavLink to={link.to} label={link.label} /> : ''}
                  </span>
                </ListItem>
                {link.children && link.children.length > 0 ? (
                  <List component="div" disablePadding>
                    {link.children.map((child, index2) => (
                      <ListItem button className={classes.nested} key={index2}
                      onClick={()=>this.props.history.push(child.to) }
                      >
                        {child.icon ? (
                          <ListItemIcon>
                            { child.label == 'Youtube' ? <img src={YouTubeIcon} style={{ height: 20 }}/> : <child.icon />}
                          </ListItemIcon>
                        ) : (
                          ''
                        )}
                        <span style={{ fontSize: '13px' }}>
                          {this.state.drawerOpen ? <StyledNavLink {...child} /> : ''}

                        </span>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  ''
                )}
              </div>
            ))}
          </List>
          {this.props.children}
        </Drawer>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(BaseSidePanel));
