import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { Grid, IconButton, Checkbox, TextField, Switch as SwitchRadio } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListIcon from '@material-ui/icons/List';
import DnsIcon from '@material-ui/icons/Dns';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FormControl from '@mui/material/FormControl';
import moment from 'moment';

import SummaryHeader from './SummaryHeader';
import InfluencerList from './InfluencerList';
import InfluencerTabularList from './InfluencerTabularList';
import InfluencerTabularListV2 from './InfluencerTabularListV2';

import BaseFilter from '../ux/BaseFilter';
import RightDrawerBase from '../ux/RightDrawerBase';

import { campaignActions } from '../../actions/campaign.js';
import { campaignHandlers } from '../../api_handlers/campaign';
import { ShortlistedInfluencerHeaderSummary } from '../../api_handlers/campaign.js';
import { connect } from 'react-redux';

import Loading from '../Common/Loading/Loading';
import { downloadFile } from '../../api_handlers/index';
import {
  getCities,
  getStates,
  getLanguages,
  getFieldOfInterest,
} from '../../api_handlers/influencer.js';
import { getCookie, setCookie } from '../utils';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#444',
        borderColor: '#444',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(SwitchRadio);

const deliverablesMap = {
  ig_live: 'Instagram Live',
  ig_post: 'Instagram Posts',
  ig_reel: 'Instagram Reel',
  ig_static: 'Instagram Static',
  ig_story: 'Instagram Story',
  ig_tv: 'Instagram TV',
  yt_dedic_video: 'Youtube Dedicated Video',
  yt_integrated_video: 'Youtube Integrated Video',
  yt_shorts: 'Youtube Shorts',
};

class InfluencerDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.campaign_id = this.props.match.params.campaignId;
    this.state = {
      page: 1,
      alignment: 'left',
      statusOptions: ['onboarded', 'deboarded'],
      selectedStatus: [],
      selectedSubscriber: [],
      subscriberOptions: ['Private', '1 - 100K', '100K - 500K', '500K - 1M', '> 1M '],
      languageOptions: [],
      selectedLanguages: [],
      influencerOptions: [],
      selectedInfluencers: [],
      search_text: null,
      category: null,
      categoryOptions: [],
      gender: null,
      location: null,
      cityOptions: [],
      stateOptions: [],
      language: [],
      summary: {},
      selectedColumns: [],
      columns: [
        { name: 'Name', key: 'name', value: (influencer) => influencer.influencer.name },
        { name: 'Status', key: 'status', value: 'status' },
        {
          name: 'Cost',
          key: 'cost',
          value: (influencer) => {
            return influencer.status === 'approved'
              ? influencer.influencer_quote
              : influencer.influencer_quote;
          },
        },
        {
          name: 'Deliverables',
          key: 'deliverables',
          value: (influencer) => {
            const instagram = ['ig_live', 'ig_post', 'ig_reel', 'ig_static', 'ig_story', 'ig_tv']
              .filter((key) => influencer[key] > 0)
              .map((key) => (
                <div>
                  {deliverablesMap[key]} : {influencer[key]}
                </div>
              ));

            const youtube = ['yt_dedic_video', 'yt_integrated_video', 'yt_shorts']
              .filter((key) => influencer[key] > 0)
              .map((key) => (
                <div>
                  {deliverablesMap[key]} : {influencer[key]}
                </div>
              ));
            return (
              <>
                <div>{instagram}</div>
                <div>{youtube}</div>
              </>
            );
          },
        },
        {
          name: 'Instagram Followers',
          key: 'insta_followers',
          value: (influencer) => influencer.instagram_detail.instagram_follower_count,
        },
        {
          name: 'Instagram Avg Views',
          key: 'insta_avg_views',
          value: (influencer) => influencer.instagram_detail.instagram_avg_views,
        },
        {
          name: 'Instagram Avg Engagement',
          key: 'insta_avg_eng',
          value: (influencer) => influencer.instagram_detail.instagram_avg_engagement,
        },
        {
          name: 'Instagram Engagement Rate',
          key: 'insta_eng_rate',
          value: (influencer) => influencer.instagram_detail.instagram_engagement_rate,
        },
        {
          name: 'Instagram CPV',
          key: 'insta_cpv',
          value: (influencer) => influencer.instagram_detail.instagram_estimated_cpv,
        },
        {
          name: 'Instagram CPE',
          key: 'insta_cpe',
          value: (influencer) => influencer.instagram_detail.instagram_estimated_cpe,
        },
        {
          name: 'Youtube Subscribers',
          key: 'yt_followers',
          value: (influencer) => influencer.youtube_detail.youtube_subscribers,
        },
        {
          name: 'Youtube Avg View',
          key: 'yt_avg_views',
          value: (influencer) => influencer.youtube_detail.youtube_avg_views,
        },
        {
          name: 'Youtube Avg Engagement',
          key: 'yt_avg_eng',
          value: (influencer) => influencer.youtube_detail.youtube_avg_engagement,
        },
        {
          name: 'Youtube Engagement Rate',
          key: 'yt_eng_rate',
          value: (influencer) => influencer.youtube_detail.youtube_avg_engagement,
        },
        {
          name: 'Youtube CPV',
          key: 'yt_cpv',
          value: (influencer) => influencer.youtube_detail.youtube_estimated_cpe,
        },
        {
          name: 'Youtube CPE',
          key: 'yt_cpe',
          value: (influencer) => influencer.youtube_detail.youtube_estimated_cpe,
        },
        { name: 'Category', key: 'category', value: 'name' },
        { name: 'Age', key: 'age', value: (influencer) => influencer.influencer.age },
        { name: 'Gender', key: 'gender', value: (influencer) => influencer.influencer.gender },
        { name: 'Address', key: 'address', value: (influencer) => influencer.influencer.address },
      ],
    };
    this.timer = null;
  }

  async loadData() {
    const cities = await getCities();
    const states = await getStates();

    this.setState({
      cityOptions: cities,
      stateOptions: states,
      location: cities.filter((city) => city.id === this.state.city)[0],
    });
    this.setState({
      // languageOptions: await getLanguages(),
      categoryOptions: await getFieldOfInterest(),
    });
  }

  handleAlignment = (event, element) => {
    let alignment = element ? 'left' : 'right';
    this.setState({
      alignment: alignment,
    });
  };

  handleSearchText = (event) => {
    const data = event.target.value;
    this.setState({
      search_text: data,
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.reloadData();
    }, 1000);
  };

  handleGender = (event) => {
    const data = event.target.value;
    this.setState({
      gender: data,
    });
    this.reloadData();
  };

  handleLanguage = (event) => {
    const data = event.target.value;
    console.log(data);
    // let filterdLanguageName = this.state.languageOptions.filter(languages => {
    //     return data.indexOf(languages.id) !== -1;
    // })
    this.setState({
      selectedLanguages: [{ id: data }],
      language: data,
    });
    this.reloadData();
  };

  handleCategory = (event) => {
    const data = event.target.value;
    this.setState({
      category: data,
    });
    this.reloadData();
  };

  getReportFilters() {
    return {
      influencer_id: this.props.match.params.influencerId,
      start_date: this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : null,
      end_date: this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : null,
      languages: this.state.selectedLanguages.map((item) => item.id),
      influencer_status: this.state.selectedStatus,
      influencers: this.state.selectedInfluencers.map((item) => item.influencer_id),
      subscriber: this.state.selectedSubscriber,
      search_text: this.state.search_text ? this.state.search_text : '',
      gender: this.state.gender ? this.state.gender.value : '',
      category: this.state.category ? this.state.category.id : '',
      location_id: this.state.location ? this.state.location.id : '',
    };
  }

  downloadReport() {
    downloadFile(
      `/campaign/${this.campaign_id}/report/influencer_list/download`,
      this.getReportFilters(),
    );
  }
  async reloadData() {
    await this.setState({
      page: 1,
    });
    this.fetchData();
  }

  downloadTabularReport() {
    let url;

    if (this.props.campaign.brand === 78)
      url = `/campaign/${this.campaign_id}/report/tabular_influencer_list/download`;
    else url = `/campaign/${this.campaign_id}/report/tabular_influencer_list/updated/download`;

    downloadFile(url, this.getReportFilters());
  }

  filterInfluencers = (status = null) =>
    this.props.getInfluencers(this.campaign_id, status, this.state.page, {
      languages: this.state.selectedLanguages.map((item) => item.id),
      influencer_status: this.state.selectedStatus,
      influencers: this.state.selectedInfluencers.map((item) => item.influencer_id),
      subscriber: this.state.selectedSubscriber,
      search_text: this.state.search_text ? this.state.search_text : '',
      gender: this.state.gender ? this.state.gender.value : '',
      category: this.state.category ? this.state.category.id : '',
      location_id: this.state.location ? this.state.location.id : '',
    });

  componentDidMount() {
    this.loadData();
    // ShortlistedInfluencerHeaderSummary().then((data) =>
    //     this.setState({summary: data})
    // )
    window.addEventListener('scroll', this.infiniteScroll);
    campaignHandlers.getCampaignLanguageOptions(this.campaign_id).then((data) => {
      this.setState({ languageOptions: data });
    });

    campaignHandlers.getCampaignInfluencerOptions(this.campaign_id).then((data) => {
      this.setState({ influencerOptions: data });
    });
    this.fetchData(this.state.page);

    let previousColumnsPreference = getCookie('influencer_columns');
    console.log('previousColumnsPreference', previousColumnsPreference);

    if (previousColumnsPreference.length === 0)
      previousColumnsPreference = ['name', 'status', 'cost', 'age', 'gender', 'city', 'category'];
    else previousColumnsPreference.split(', ');

    this.setState({
      selectedColumns: this.state.columns.filter((column) =>
        previousColumnsPreference.includes(column.key),
      ),
    });
  }

  getInfluencers(type) {
    if (type === 'eligible') {
      this.filterInfluencers();
    } else {
      this.filterInfluencers(type);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.reloadData();
    }
  }
  componentWillUpdate(props) {
    if (props.download_influencers_report) {
      props.influencers_alignment == 'left' ? this.downloadReport() : this.downloadTabularReport();
    }
  }
  summary_handler = () => {
    ShortlistedInfluencerHeaderSummary().then((data) => this.setState({ summary: data }));
  };

  fetchData = () => {
    ShortlistedInfluencerHeaderSummary().then((data) => this.setState({ summary: data }));
    const filter_route = this.props.location.pathname.substring(
      this.props.location.pathname.lastIndexOf('/') + 1,
      this.props.location.pathname.length,
    );
    let filter = filter_route.split('-')[0];
    this.getInfluencers(filter);
  };

  // componentWillReceiveProps({summary}) {
  //       this.setState({...this.state,summary})
  //     }

  infiniteScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      if (this.props.influencers.length < this.props.count && this.props.loading == false) {
        let newPage = this.state.page;
        newPage++;
        this.setState({
          page: newPage,
        });
        this.fetchData();
      }
    }
  };

  render() {
    const genders = [
      { value: 'male', name: 'Male' },
      { value: 'female', name: 'Female' },
      { value: 'unkown', name: 'Other' },
    ];
    let influencers_alignment = this.props.influencers_alignment;
    return (
      <Grid container justify="center" style={{ marginTop: 0 }}>
        <Grid item md={12}>
          <SummaryHeader summary={this.state.summary} />
        </Grid>
        <BaseFilter>
          <Grid container spacing={3}>
            {influencers_alignment == 'right' && (
              <Grid item md={12}>
                <Autocomplete
                  id="tags-standard"
                  multiple
                  limitTags={1}
                  size="small"
                  InputLabelProps={{ shrink: false }}
                  options={this.state.columns}
                  value={this.state.selectedColumns}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  style={{
                    width: '100%',
                    background: '#F9F9F9',
                    fontSize: 13,
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size="small" placeholder="Columns" />
                  )}
                  onChange={(evt, selectedValues) => {
                    this.setState({ selectedColumns: selectedValues });
                    setCookie(
                      'influencer_columns',
                      selectedValues.map((column) => column.key).join(', '),
                    );
                  }}
                />
              </Grid>
            )}
            {influencers_alignment == 'right' && (
              <Grid item md={12}>
                <Autocomplete
                  id="tags-standard"
                  multiple
                  limitTags={1}
                  size="small"
                  InputLabelProps={{ shrink: false }}
                  options={this.state.influencerOptions}
                  value={this.state.selectedInfluencers}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.influencer__name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.influencer__name}
                    </React.Fragment>
                  )}
                  style={{
                    width: '100%',
                    background: '#F9F9F9',
                    fontSize: 13,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      placeholder="Influencer"
                    />
                  )}
                  onChange={async (event, selectedValues) => {
                    await this.setState({
                      selectedInfluencers: selectedValues,
                      page: 1,
                    });
                    this.getInfluencers();
                  }}
                />
              </Grid>
            )}
            {influencers_alignment == 'left' && (
              <Grid item md={12}>
                <FormControl fullWidth>
                  <TextField
                    labelId="search_text_label"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="search_text"
                    value={this.state.search_text}
                    label="Search Influencer"
                    name="search_text"
                    autoComplete="text"
                    autoFocus
                    onChange={this.handleSearchText}
                    style={{
                      backgroundColor: 'white',
                      radius: 5,
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            {influencers_alignment == 'left' && (
              <Grid item md={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={genders}
                    getOptionLabel={(option) => option.name}
                    value={this.state.gender}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        label="Gender"
                        style={{
                          backgroundColor: 'white',
                          radius: 5,
                          marginTop: 16,
                          marginBottom: 8,
                        }}
                      />
                    )}
                    onChange={(event, value) => {
                      this.setState({
                        gender: value,
                      });
                      this.reloadData();
                    }}
                    // classes={{
                    //     option: classes.font13,
                    //     input: classes.font13,
                    // }}
                  />
                </FormControl>
              </Grid>
            )}
            {influencers_alignment == 'left' && (
              <Grid item md={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={this.state.categoryOptions}
                    getOptionLabel={(option) => option.name}
                    value={this.state.category}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        label="Category"
                        style={{
                          backgroundColor: 'white',
                          radius: 5,
                          marginTop: 16,
                          marginBottom: 8,
                        }}
                      />
                    )}
                    onChange={(event, value) => {
                      this.setState({
                        category: value,
                      });
                      this.reloadData();
                    }}
                    // classes={{
                    //     option: classes.font13,
                    //     input: classes.font13,
                    // }}
                  />
                </FormControl>
              </Grid>
            )}
            {influencers_alignment == 'left' && (
              <Grid item md={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={this.state.stateOptions}
                    getOptionLabel={(option) => option.name}
                    value={this.state.location}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        label="Location"
                        style={{
                          backgroundColor: 'white',
                          radius: 5,
                          marginTop: 16,
                          marginBottom: 8,
                        }}
                      />
                    )}
                    onChange={(event, value) => {
                      this.setState({
                        location: value,
                      });
                      this.reloadData();
                    }}
                    // classes={{
                    //     option: classes.font13,
                    //     input: classes.font13,
                    // }}
                  />
                </FormControl>
              </Grid>
            )}
            {influencers_alignment == 'right' && (
              <Grid item md={12}>
                <Autocomplete
                  id="tags-standard"
                  InputLabelProps={{ shrink: false }}
                  options={this.state.statusOptions}
                  value={this.state.selectedStatus}
                  disableCloseOnSelect
                  // getOptionLabel={(option) => option.influencer__name}
                  // renderOption={(option, { selected }) => (
                  //     <React.Fragment>
                  //     {option}
                  //     </React.Fragment>
                  // )}
                  style={{
                    width: '100%',
                    background: '#F9F9F9',
                    fontSize: 13,
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size="small" placeholder="Status" />
                  )}
                  onChange={async (event, selectedValues) => {
                    await this.setState({
                      selectedStatus: selectedValues,
                      page: 1,
                    });
                    this.getInfluencers();
                  }}
                />
              </Grid>
            )}
            {influencers_alignment == 'right' && (
              <Grid item md={12}>
                <Autocomplete
                  id="tags-standard"
                  multiple
                  limitTags={1}
                  size="medium"
                  InputLabelProps={{ shrink: false }}
                  options={this.state.languageOptions}
                  value={this.state.selectedLanguages}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  style={{
                    width: '100%',
                    background: '#F9F9F9',
                    fontSize: 13,
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size="small" placeholder="Language" />
                  )}
                  onChange={async (event, selectedValues) => {
                    console.log(selectedValues);
                    await this.setState({
                      selectedLanguages: selectedValues,
                      page: 1,
                    });
                    this.reloadData();
                  }}
                />
              </Grid>
            )}
            {influencers_alignment == 'left' && (
              <Grid item md={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={this.state.languageOptions}
                    getOptionLabel={(option) => option.name}
                    value={this.state.language}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        label="Language"
                        style={{
                          backgroundColor: 'white',
                          radius: 5,
                          marginTop: 16,
                          marginBottom: 8,
                        }}
                      />
                    )}
                    onChange={(event, value) => {
                      console.log(value);
                      this.setState({
                        language: value,
                        selectedLanguages: [{ id: value['id'] }],
                      });
                      this.reloadData();
                    }}
                    // classes={{
                    //     option: classes.font13,
                    //     input: classes.font13,
                    // }}
                  />
                </FormControl>
              </Grid>
            )}
            {influencers_alignment == 'right' && (
              <Grid item md={12}>
                <Autocomplete
                  id="tags-standard"
                  InputLabelProps={{ shrink: false }}
                  options={this.state.subscriberOptions}
                  value={this.state.selectedSubscriber}
                  disableCloseOnSelect
                  // getOptionLabel={(option) => option.influencer__name}
                  // renderOption={(option, { selected }) => (
                  //     <React.Fragment>
                  //     {option}
                  //     </React.Fragment>
                  // )}
                  style={{
                    width: '100%',
                    background: '#F9F9F9',
                    fontSize: 13,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      placeholder="Subscriber"
                    />
                  )}
                  onChange={async (event, selectedValues) => {
                    await this.setState({
                      selectedSubscriber: selectedValues,
                      page: 1,
                    });
                    this.getInfluencers();
                  }}
                />
              </Grid>
            )}
          </Grid>
        </BaseFilter>
        {this.props.loading && <Loading />}
        {/* <RightDrawerBase > 
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    // style={{ margin: 10, marginRight: 20 }}
                >
                    <Grid item md={12} style={{ margin: "2px 2px" }}>
                        <IconButton
                            variant="contained"
                            size="small"
                            style={{
                                fontSize: 12,
                                textTransform: "none",
                                backgroundColor: "#444",
                                color: "white",
                                margin: "1px",
                                padding: 3
                            }}
                            onClick={() => {
                                influencers_alignment == "left" ? this.downloadReport() : this.downloadTabularReport();
                            }}
                        >
                            <CloudDownloadIcon />
                        </IconButton>
                    </Grid>
                </Grid> 
                <Grid container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item md={8} style={{ margin: "2px 2px" }}>
                        {/* <ToggleButtonGroup
                            value={influencers_alignment}
                            exclusive
                            aria-label="text alignment"
                        >
                            <ToggleButton
                                value="left"
                                size="small"
                                aria-label="left aligned"
                                onClick={this.handleAlignment}
                            >
                                <DnsIcon />
                            </ToggleButton>
                            <ToggleButton
                                value="right"
                                size="small"
                                aria-label="right aligned"
                                onClick={this.handleAlignment}
                            >
                                <ListIcon />
                            </ToggleButton>
                        </ToggleButtonGroup> 
                        <AntSwitch checked={influencers_alignment == 'left'} onChange={this.handleAlignment} name="checkedC" />
                    </Grid> 
                </Grid> 
                </RightDrawerBase> */}
        <Grid item md={12}>
          <Grid container spacing={2}>
            {influencers_alignment == 'left' && (
              <Grid item md={12} style={{ marginTop: 10 }}>
                <Switch>
                  <Route path={`${this.props.match.url}/eligible-influencers`}>
                    <InfluencerList
                      handler={this.getInfluencers.bind(this)}
                      summaryHandler={this.summary_handler}
                      influencers={this.props.influencers}
                    />
                  </Route>
                  <Route
                    onClick={this.getInfluencers.bind(this, 'approved')}
                    path={`${this.props.match.url}/approved-influencers`}
                  >
                    <InfluencerList
                      handler={this.getInfluencers.bind(this, 'approved')}
                      summaryHandler={this.summary_handler}
                      influencers={this.props.influencers}
                    />
                  </Route>
                  <Route path={`${this.props.match.url}/rejected-influencers`}>
                    <InfluencerList
                      handler={this.getInfluencers.bind(this, 'rejected')}
                      summaryHandler={this.summary_handler}
                      influencers={this.props.influencers}
                    />
                  </Route>
                  <Route path={`${this.props.match.url}/pending-approval`}>
                    <InfluencerList
                      handler={this.getInfluencers.bind(this, 'pending')}
                      summaryHandler={this.summary_handler}
                      influencers={this.props.influencers}
                    />
                  </Route>
                </Switch>
              </Grid>
            )}
            {influencers_alignment == 'right' &&
              (this.props.campaign.brand === 78 ? (
                <InfluencerTabularList
                  handler={this.getInfluencers.bind(this)}
                  influencers={this.props.influencers}
                  campaignId={this.props.match.params.campaignId}
                  selectedColumns={this.state.selectedColumns}
                  columns={this.state.columns}
                />
              ) : (
                <InfluencerTabularListV2
                  handler={this.getInfluencers.bind(this)}
                  influencers={this.props.influencers}
                  campaignId={this.props.match.params.campaignId}
                  selectedColumns={this.state.selectedColumns}
                  columns={this.state.columns}
                />
              ))}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.campaign.loading,
  influencers: state.campaign.influencers,
  count: state.campaign.count,
  campaign: state.campaign.current_campaign,
  influencers_alignment: state.campaign.influencers_alignment,
  download_influencers_report: state.campaign.download_influencers_report,
});

const actionCreators = {
  getInfluencers: campaignActions.getInfluencers,
};

export default connect(mapStateToProps, actionCreators)(withRouter(InfluencerDashboard));
