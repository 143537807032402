import { Checkbox, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function MultiSelect({ value, onChange, options }) {
  const classes = useStyles();
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      // value={value}
      defaultValue={value}
      onChange={onChange}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Checkboxes" placeholder="Favorites" />
      )}
    />
  );
}

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '3px !important',
    paddingBottom: '3px !important',
    cursor: 'pointer',
  },
  selected: {
    border: '3px solid #FEBD1C',
    boxShadow:
      '-5px 5px 10px rgba(210, 211, 219, 0.2), 5px -5px 10px rgba(210, 211, 219, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(210, 211, 219, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(210, 211, 219, 0.5)',
    borderRadius: '20px',
  },
  name: {
    fontSize: 14,
  },
}));

export default MultiSelect;
