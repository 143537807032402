import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import SidePanel from './SidePanel';
import Personal from './Personal';
import Security from './Security';
import Social from './Social';
import AboutMe from './AboutMe';
import ButtonStyledLink from '../../ux/ButtonStyledLink';

class Settings extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 15, width: '92vw', 'padding-left': '25px' }}
      >
        <Grid
          item
          md={12}
          style={{
            backgroundColor: 'white',
            width: '100%',
            border: '1px solid #DCDCDC',
            'border-radius': '5px',
            marginBottom: 10,
          }}
        >
          <ButtonStyledLink to={'/influencer/personal-settings'} label={'Personal Information'} />
        </Grid>
        <Grid
          item
          md={12}
          style={{
            backgroundColor: 'white',
            width: '100%',
            border: '1px solid #DCDCDC',
            'border-radius': '5px',
            marginBottom: 10,
          }}
        >
          <ButtonStyledLink to={'/influencer/security-settings'} label={'Security Settings'} />
        </Grid>
        <Grid
          item
          md={12}
          style={{
            backgroundColor: 'white',
            width: '100%',
            border: '1px solid #DCDCDC',
            'border-radius': '5px',
            marginBottom: 10,
          }}
        >
          <ButtonStyledLink to={'/influencer/social-settings'} label={'Social Accounts'} />
        </Grid>
        <Grid
          item
          md={12}
          style={{
            backgroundColor: 'white',
            width: '100%',
            border: '1px solid #DCDCDC',
            'border-radius': '5px',
            marginBottom: 10,
          }}
        >
          <ButtonStyledLink to={'/influencer/about-me'} label={'About Me'} />
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Settings);
