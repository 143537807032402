import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DropDownSlider from 'components/Theme/Elements/DropDownSlider';

import Filter from '../Filter';

import FollowersIcon from 'assets/discovery/followers-icon.png';

const options = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];

function AudienceGenderSplitFilter({ value = [null, null], setFilters }) {
  const classes = useStyles();

  function handleFilter(values) {
    setFilters({
      audienceGender: values,
    });
  }

  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Filter label={'Audience Gender Split'} labelIconSrc={FollowersIcon}>
          <Grid container className={classes.filterContainer}>
            <DropDownSlider value={value} onChange={handleFilter} options={options} />
          </Grid>
        </Filter>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {},
}));

export default AudienceGenderSplitFilter;
