import { Button, Grid, Popper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import NavLink from 'components/PlannerTheme/Elements/Link';
import { Link } from 'react-router-dom';
import StyledNavLink from 'components/ux/StyledNavLink';
import Logo from 'assets/planner/logo.png';
import BrandLogo from 'assets/planner/brand-logo.png';
import { width } from '@material-ui/system';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { colorConstants } from 'constants/colors';
import { useState } from 'react';

function Header() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'));
  const brand_name = user.user_type === 'brand' ? (user.brand.name ? user.brand.name : '') : '';
  const logo =
    user.user_type === 'brand'
      ? user.brand.brand_logo_url
        ? user.brand.brand_logo_url
        : ''
      : '';
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const logOut = () => {
    for (const key of ['access', 'refresh', 'user']) localStorage.removeItem(key);
    window.location = '/login';
  };
  return (
    <Grid container className={classes.root}>
      <Grid item xs={2} style={{textAlign:'start'}}>
        <Link to={`/login`}>
          <img src={Logo} className={classes.logo} alt="logo" />
        </Link>
      </Grid>
      <Grid item xs={7}>
        <Grid container spacing={5} className={classes.linkBox}>
          <Grid item>
            <StyledNavLink
              className={classes.tabBarText}
              to={`/brand/dashboard/${
                JSON.parse(localStorage.getItem('user')).brand.id === 260 ? 'instagram' : 'youtube'
              }`}
              label={'Dashboard'}
            />
            {/* <Link>
              <Typography className={classes.tabBarText}>Dashboard</Typography>
            </Link> */}
          </Grid>
          <Grid item>
            <StyledNavLink
              className={classes.tabBarText}
              to={`/brand/campaigns`}
              label={'My Campaign'}
            >
              {/* <Typography className={classes.tabBarText}>
                My Campaign
              </Typography> */}
            </StyledNavLink>
          </Grid>
          <Grid item>
            <StyledNavLink className={classes.tabBarText} to={`/planner`} label={'Planner'}>
              {/* <Typography className={classes.tabBarText}>
                Planner
              </Typography> */}
            </StyledNavLink>
          </Grid>
          <Grid item>
          <StyledNavLink
              to={`/competitor-insights/${user.brand.id}`}
              label={'Competitor Insights'}
            />
            {/* <NavLink selected>
              <Typography className={classes.selectedTabBarText}>Competitor Insights</Typography>
            </NavLink> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid container className={classes.brandBox} spacing={2}>
          <Grid item md={6} style={{textAlign:'end'}}>
            <Typography className={classes.brandName}>{brand_name}</Typography>
          </Grid>
          <Grid item md={5} style={{textAlign:'center'}}>
          <img src={logo} style={{maxWidth:'100%',}} height={40} />
          </Grid>
          <Grid item md={1}>
          <PersonOutlineIcon onClick={handleClick} />
        <Popper open={open} anchorEl={anchorEl}>
          <Grid container direction='column'>
            <Grid item>
            <Button
                variant="contained"
                style={{
                  marginTop: '20px',
                  backgroundColor: colorConstants.TEXT_BLUE,
                  color: 'white',
                }}
                onClick={logOut}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </Popper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    background: '#FFFFFF',
    borderRadius: 10,
    height: 60,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  logo: {
    height: 30,
  },
  brandBox: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  linkBox: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  brandName: {
    // marginLeft: 200,
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: 15,
  },
  tabBarText: {
    marginRight: 1,
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: 0.03,
  },
  selectedTabBarText: {
    marginRight: 1,
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: 14,
    letterSpacing: 0.03,
  },
}));

export default Header;
