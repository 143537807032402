import { Button, Grid, TextField, Typography, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Component } from 'react';
import StartDatePicker from './StartDatePicker';
import { useParams } from 'react-router-dom';
import { getListCompetitorBrands, saveBrandRequestToDB } from '../../../api_handlers/competitor';

import InterestIcon from 'assets/discovery/interest-icon.png';
import Tooltip from "@material-ui/core/Tooltip";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      // endDate: new Date().toISOString().split('T')[0],
      brandName: '',
      brandList: [],
    };
  }

  async componentDidMount() {
    const { results } = await getListCompetitorBrands(this.props.params.brand_id);
    this.setState({ brandList: results });
    let { keyword } = this.props.params;
    this.fetchData(keyword);
    
  }

  fetchData = keyword => {
    this.setState({brandName:keyword})

};

 

  async saveBrandRequest(brandName, startDate) {
    await saveBrandRequestToDB({
      handle: brandName,
      start_date: startDate,
    });
    this.props.history.push(`/competitor-insights/${this.props.params.brand_id}/${brandName}`);
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={3}>
        <Grid item md={12} className={classes.header}>
          <Grid item md={12}>
            <Typography style={{ color: 'black' }}>COMPETITOR INSIGHTS</Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="space-evenly">
          <Grid item md={9} className={classes.root}>
            <Grid container direction="column" justifyContent="space-around" alignItems="center">
              <Grid item md={12} xl={12} style={{ width: 800 }}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                >
                  <Grid item md={5}>
                    <span className={classes.brandNameOrHashtagLabel}>Brand Name or Hashtags</span>
                  </Grid>
                  <Grid item md={5}>
                    <TextField
                      InputProps={{
                        disableUnderline: true,
                      }}
                      className={classes.brandNameInput}
                      placeholder=""
                      variant="standard"
                      value={this.state.brandName}
                      onChange={(evt) => {
                        this.setState({ brandName: evt.target.value });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                >
                  <Grid item md={5}>
                    <span className={classes.dateRangeLabel}>Start Date</span>
                  </Grid>
                  <Grid item md={5}>
                    <StartDatePicker/>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={5}>
                    <Button
                      sx={{ boxShadow: 5 }}
                      className={classes.submit_date_range}
                      onClick={() => {
                        this.props.reloadData(
                          this.state.brandName,
                        );
                        this.saveBrandRequest(
                          this.state.brandName,
                          this.state.startDate,
                        );
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* </div> */}
              {/* <div className={classes.right}>
                        <div className={classes.brand_tagged_label}>Brands Tagged</div>
                        <div className={classes.brand_tagged_selection}>

                        </div>
                    </div> */}
            </Grid>
          </Grid>
          <Grid item md={2} style={{minWidth: "260px"}}>
            {/* <List component="nav" aria-label="fdsa" className={classes.brandList} aria-labelledby="nested-list-subheader">
                  {this.state.brandList.map(brand => <ListItem button>
                      <ListItemText primary={brand.handle} />
                    </ListItem>
                  )}
                </List> */}
            <Grid className={classes.card} style={{ height: 160 }}>
              <Grid container spacing={1}>
                <Grid item>
                  <img src={InterestIcon} alt="icon" className={classes.iconSize} />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2" style={{ color: '#0061A7' }}>
                    Fetched Brands
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} className={classes.brandContainer}>
                {this.state.brandList.map((brand, i) => (
                  <Grid item md={12} key={i}>
                    <Grid container alignItems="center">
                      <Grid item style={{textAlign: 'left'}}>
                        <Link href={`/competitor-insights/${this.props.params.brand_id}/${brand.handle}`} color="inherit">
                          {brand.handle}
                        </Link>
                        {brand.tags.length > 0 ? (
                          <>
                      {brand.tags.length < 3 ? (
                        <Grid item>
                          <Typography
                              style={{ color: "inherit", display: "inline-block" }}
                            >
                              [{brand.tags.slice(0, 2).join(", ")}{" "}]
                          </Typography>
                        </Grid>
                      ): (
                        <Grid item>
                          <Tooltip title={brand.tags.slice(2).join(", ")}>
                            <Typography
                              style={{ color: "inherit", display: "inline-block" }}
                            >
                              [{brand.tags.slice(0, 2).join(", ")}{" "}
                              <span
                                style={{ cursor: "pointer" }}
                              >
                                ...
                              </span>
                              ]
                            </Typography>
                          </Tooltip>
                        </Grid>
                      )
                      }
                      </>
                      ) : ("")
                    }
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = {
  header: {
    color: '#4A4A4A',
    fontSize: '20px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    lineHeight: 1.167,
    letterSpacing: '0.03em',
    padding: '15px',
    background: '#F3F4FE',
    boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    borderRadius: '10px',
    marginBottom: '15px',
    marginTop: '30px',
  },
  root: {
    background:
      'linear-gradient(91.68deg, rgba(239, 26, 116, 0.057) 11.95%, rgba(254, 189, 28, 0.1) 68.66%)',
    boxShadow: '4px 4px 20px 2px rgba(172, 172, 172, 0.25)',
    borderRadius: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
    textAlign: 'left',
    paddingTop: 30,
  },
  heading: {
    textAlign: 'left',
    marginLeft: 30,
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: 20,
  },
  mainContainer: {
    display: 'flex',
    marginTop: 10,
  },
  left: {
    flex: 1,
    marginLeft: 30,
    paddingTop: 40,
  },
  right: {
    marginRight: 30,
    width: 200,
  },
  brand_tagged_label: {
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: 16,
    textAlign: 'center',
  },
  brand_tagged_selection: {
    marginTop: 10,
    borderRadius: 15,
    height: 230,
    width: '100%',
    background: 'white',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 224, 0.2), 5px -5px 10px rgba(215, 216, 224, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 224, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 224, 0.5)',
    borderRadius: 10,
  },
  brandNameContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '51px',
  },
  brandNameOrHashtagLabel: {
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: 17,
  },
  brandNameInput: {
    border: '1px solid #C4C4C4',
    borderRadius: '10px',
    background: '#FFFFFF',
    width: 250,
    height: 25,
    outline: 'none',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 12,
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000000',
    },
  },
  submit: {
    textTransform: 'none',
    width: 150,
    height: 30,
    fontWeight: 700,
    background: '#FEBD1C',
    borderRadius: '10px',
    color: 'white',
    padding: 10,
    fontSize: 15,
    marginLeft: 20,
    paddingLeft: 20,
    paddingRight: 20,
    filter: 'drop-shadow(5px 7px 7px rgba(186, 186, 186, 0.25))',
  },
  date_range_container: {
    display: 'flex',
    marginTop: 0,
    alignItems: 'center',
    marginLeft: '69px',
  },
  dateRangeLabel: {
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: 17,
  },
  submit_date_range: {
    textTransform: 'none',
    width: 200,
    height: 39,
    fontWeight: 700,
    background: '#FEBD1C',
    borderRadius: '10px',
    color: 'white',
    padding: 10,
    fontSize: 15,
    marginLeft: 170,
    paddingLeft: 20,
    paddingRight: 20,
    filter: 'drop-shadow(5px 7px 7px rgba(186, 186, 186, 0.25))',
  },
  brandList: {
    height: 100,
    overflowY: 'scroll',
  },
  card: {
    background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    padding: 20,
  },
  iconSize: {
    width: 15,
    height: 15,
  },
  brandContainer: {
    marginTop: 10,
    marginLeft: 10,
    paddingLeft: 5,
    overflowY: 'scroll',
    height: '80%',
  },
};

export default withRouter(withParams( withStyles(styles)(Filter)));
