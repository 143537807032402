import { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import makeAnimated from 'react-select/animated';
import SearchAutocomplete from './components/SearchAutocomplete';
import { Grid, Button } from '@material-ui/core';
import AddToCampaignModal from './components/AddToCampaignModal';
import { updateFilter, getFilteredInfluencer, resetFilter } from 'actions/discovery';
import { getPlannerDownload } from 'api_handlers/discovery';
import DownloadPlannerStaff from './DownloadPlannerStaff';
import BroadcastModal from './components/BroadcastModal/BroadcastModal'
import { isWhatsAppModuleEnable } from 'components/utils';
import AddNewProfileModal from './components/AddNewProfileModal';
import DetailViewModal from '../InfluencerInfoCard/components/DetailViewModal';

const animatedComponents = makeAnimated();

class ActionFilter extends Component {
  // const [campaignModalOpen, setCampaignModalOpen] = useState(false)

  constructor(props) {
    super(props);

    this.setCampaignModalOpen = this.setCampaignModalOpen.bind(this);
    this.setCommunicationModalOpen = this.setCommunicationModalOpen.bind(this);
    this.setDetailViewOpened = this.setDetailViewOpened.bind(this);

    this.state = {
      campaignModalOpen: false,
      search: props.payload ? props.payload.search : '',
      searchOptions: [],
      selectedSearchOptions: [],
      selectedInfluencer: this.props.selectedInfluencer,
      is_contact: 'not_req',
      isDownloadSelected: false,
      communicationModalOpen: false,
      showAddProfileModal: false,
      detailViewOpened: false,
      influencer_id: ''
    };
  }
  setCampaignModalOpen(boolean) {
    this.setState({ campaignModalOpen: boolean });
  }

  setCommunicationModalOpen(boolean) {
    this.setState({ communicationModalOpen: boolean });
  }

  handleApply() {
    console.log(this.props);
    const payload = this.props.payload;
    payload['search'] = this.state.search;
    delete payload['budget_left'];
    this.props.getPlannerInfluencersData(payload, 1);
    this.props.scrollToElement();
    // getFilteredInfluencer()
  }

  async downloadPlanner() {
    console.log(this.props.selectedInfluencer);
    let selected_ids = this.props.selectedInfluencer.map((id) => {
      return id;
    });
    let objective = '';
    if (this.props.selectedObjectiveType == 'Reach') {
      objective = 'reach';
    }
    if (this.props.selectedObjectiveType == 'CPV') {
      objective = 'cpv';
    }
    if (this.props.selectedObjectiveType == 'Engagement Rate') {
      objective = 'engagement_rate';
    }
    let impact_data = {};
    if (this.props.impact_data) {
      impact_data = this.props.impact_data;
    }
    let download_payload = {
      selected_ids: selected_ids,
      objective: objective,
      prediction: this.props.selectedPrediction,
      contacts: this.state.is_contact,
      selectedColumns: this.props.selectedColumns,
    };
    let resp = await getPlannerDownload(download_payload);
  }
  handleButtonClick = (value) => {
    this.setState(
      {
        is_contact: value ? 'req' : 'not_req',
      },
      () => {
        this.downloadPlanner();
      },
    );
  };

  setDownloadSelected = (value) => {
    this.setState({ isDownloadSelected: value });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isDownloadSelected !== this.state.isDownloadSelected) {
      this.props.showColumnSelectModal(this.state.isDownloadSelected);
    }
  }

  setDetailViewOpened(boolean) {
    this.setState({ detailViewOpened: boolean });
  }
  
  render() {
    let {
      classes,
      filters,
      setFilters,
      selectedInfluencer,
      setSelectedInfluencer,
      payload,
      selectedObjectiveType,
      impact_data,
      influencers,
      recommendation,
      getPlannerInfluencersData,
    } = this.props;

    // console.log("Recommendations : ", recommendation.map((influencers) => {
    //   if (selectedInfluencer.includes(influencers.id)){
    //     return influencers
    //   }
    // }))
    // console.log("Influencers: ", influencers.map((inf) => {
    //   if (selectedInfluencer.includes(inf.id)){
    //     return inf
    //   }
    //   else{
    //     return
    //   }
    // }))
    return (
      <Grid container className={classes.root}>
        <Grid item md={7} style={{ textAlign: 'left' }}>
          {/* <TextField
            placeholder="Influencer Name, Insta Handle, url  "
            className={classes.searchInput}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                this.handleApply();
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => this.handleApply()}
                  className={classes.searchButton}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            value={this.state.search || null}
            onChange={({ target }) => {
              this.setState({ search: target.value });
            }}
          /> */}
          <SearchAutocomplete
            getPlannerInfluencersData={getPlannerInfluencersData}
            payload={payload}
          />
          {/* <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={this.state.searchOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onInputChange={(value) => {
              this.updateSearchOptions(value)
            }}
          /> */}
        </Grid>
        {this.props.isStaff ? 
        <Grid item md={1}>
          <Button
            variant="outlined"
            className={classes.communication}
            onClick={(e)=>{
              this.setState({ showAddProfileModal: true })
            }}>
              Add Profile
          </Button>
        </Grid>
        : <Grid item md={1}></Grid> }
        <Grid item md={4}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            {this.props.isStaff ? (
              <Grid item>
                <DownloadPlannerStaff
                  disabled={selectedInfluencer.length === 0}
                  onButtonClick={this.handleButtonClick}
                  setDownloadSelected={this.setDownloadSelected}
                ></DownloadPlannerStaff>
              </Grid>
            ) : (
              ''
            )}
            <Grid item>
              <Button
                variant="outlined"
                className={classes.add_to_campaign}
                disabled={selectedInfluencer.length === 0}
                onClick={() => {this.setCampaignModalOpen(!this.state.campaignModalOpen);
                  console.log("Campaign model open", this.state.campaignModalOpen)
                }}
              >
                Add to Campaign
              </Button>
            </Grid>
            <AddToCampaignModal
              campaignModalOpen={this.state.campaignModalOpen}
              setCampaignModalOpen={this.setCampaignModalOpen}
              selectedInfluencer={this.props.selectedInfluencer}
              setSelectedInfluencer={this.props.setSelectedInfluencer}
            />
            {
              isWhatsAppModuleEnable() ?
              <Grid item>
                <Button
                  variant="outlined"
                  className={classes.communication}
                  disabled={selectedInfluencer.length === 0}
                  onClick={() => {
                    // this.setCommunicationModalOpen(!this.state.communicationModalOpen);
                    // console.log("Is model open :", this.state.communicationModalOpen)
                    this.setState({communicationModalOpen:true})
                  }}
                >
                  Communication
                </Button>
              </Grid> : null
            }
            {
              this.state.communicationModalOpen?
              <BroadcastModal
              communicationModalOpen={this.state.communicationModalOpen}
              setCommunicationModalOpen={this.setCommunicationModalOpen}
              selectedInfluencer={this.props.selectedInfluencer}
              setSelectedInfluencer={this.props.setSelectedInfluencer}
              influencers={this.props.influencers}
              recommendation={this.props.recommendation}
            />
              :''
            }
            {
              this.state.showAddProfileModal?
              <AddNewProfileModal 
                showAddProfileModal={this.state.showAddProfileModal}
                closeAddProfileModal={()=>{
                  this.setState({ showAddProfileModal: false })
                }}
                openDetailViewModal={(influencer_id)=>{
                  this.setState({ influencer_id: influencer_id, detailViewOpened: true });
                }}/>: ''
            }
            {this.state.detailViewOpened ? (
              <DetailViewModal
                id={this.state.influencer_id}
                detailViewOpened={this.state.detailViewOpened}
                setDetailViewOpened={this.setDetailViewOpened}
                is_staff={this.props.isStaff}
              />
            ) : null}
            {/* <BroadcastModal
              communicationModalOpen={this.state.communicationModalOpen}
              setCommunicationModalOpen={this.setCommunicationModalOpen}
              selectedInfluencer={this.props.selectedInfluencer}
              setSelectedInfluencer={this.props.setSelectedInfluencer}
              influencers={this.props.influencers}
              recommendation={this.props.recommendation}
            /> */}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = {
  root: {
    zIndex: 4,
    flexGrow: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    // boxShadow:
    //     "-1px 1px 2px rgba(215, 216, 221, 0.2), 1px -1px 2px rgba(215, 216, 221, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(215, 216, 221, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 221, 0.5)",
    borderRadius: 10,
    // background: "#FFFFFF",
    height: 50,
    position: 'sticky',
    top: 0,
    marginBottom: 15,
  },
  moreWidth: {
    maxWidth: '10.5%',
  },
  matrixBox: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  font12: {
    fontSize: 12,
  },
  font16: {
    fontSize: 16,
  },
  downloadPlan: {
    // width:150,
    textTransform: 'none',
    marginLeft: 20,
    background: '#FEBD1C',
    borderRadius: '10px',
    color: 'white',
    fontSize: 13,
    fontWeight: 600,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    filter: 'drop-shadow(5px 7px 7px rgba(186, 186, 186, 0.25))',
    '&:disabled': {
      backgroundColor: '#DDDDDD',
      color: '#FFFFFF',
    },
    downArrow: {
      marginLeft: 20,
    },
    // '&.MuiButtonBase-root.Mui-disabled': {
    //   cursor: 'pointer',
    // },
  },
  add_to_campaign: {
    textTransform: 'none',
    marginLeft: 20,
    background: 'white',
    borderRadius: '10px',
    borderColor: '#FEBD1C',
    color: '#FEBD1C',
    fontSize: 14,
    fontWeight: 600,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    filter: 'drop-shadow(5px 7px 7px rgba(186, 186, 186, 0.25))',
    // '&:disabled': {
    //   backgroundColor: '#bbbbbb',
    // },
  },
  communication: {
    textTransform: 'none',
    marginLeft: 5,
    background: 'white',
    borderRadius: '10px',
    borderColor: '#FEBD1C',
    color: '#FEBD1C',
    fontSize: 14,
    fontWeight: 600,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    filter: 'drop-shadow(5px 7px 7px rgba(186, 186, 186, 0.25))',
    // '&:disabled': {
    //   backgroundColor: '#bbbbbb',
    // },
  },
};

export default withStyles(styles)(ActionFilter);
