import { feedFetch, influencerFeedFetchV2 } from './index.js';

export function getYoutubeFeeds(page, params) {
  return feedFetch(`youtube`, page, params);
}
export function getInfluencerYoutubeFeeds(page, campaign_id, influencer_id, params) {
  return influencerFeedFetchV2(`youtube`, page, campaign_id, influencer_id, params);
}

export function getInstagramFeeds(page, params) {
  return feedFetch(`instagram`, page, params);
}
export function getInfluencerInstagramFeeds(page, campaignId, influencer_id, params) {
  return influencerFeedFetchV2(`instagram`, page, campaignId, influencer_id, params);
}
export function getInstagramFeedCount(page, params) {
  return feedFetch(`instagram`, page, params)
}
