import { useState, useEffect } from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LocationIcon from 'assets/discovery/Location-Icon.png';
import SelectInterest from 'assets/planner/select_interest.png';

import MultiSelect from 'components/PlannerTheme/Elements/MultiSelect';
import { getDeliverables } from 'api_handlers/brand';

function Deliverables({ infDeliverables, hideAdvanceFilters, classes }) {
  const [deliverables, setDeliverables] = useState([]);

  useEffect(() => {
    getDeliverables().then((response) => {
      setDeliverables(response.data);
    });
  }, []);
  return (
    <>
      <div
        style={{
          display: 'flex',
          marginBottom: 10,
          justifyContent: 'center',
        }}
      >
        <img src={SelectInterest} width={22} style={{ marginRight: 7 }} />
        <Typography className={classes.advanceHeading}>Deliverables</Typography>
      </div>
      <div style={{ marginTop: 10 }}>
        <MultiSelect
          label={'Select Deliverable'}
          options={deliverables.map((deliverable) => ({
            label: deliverable,
            value: deliverable,
          }))}
          value={infDeliverables || []}
          onChange={(event, selectedValues) => {
            hideAdvanceFilters('deliverables', selectedValues);
          }}
        />
      </div>
    </>
  );
}

const styles = {
  advanceHeading: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 14,
  },
};

export default withStyles(styles)(Deliverables);
