import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';

import NoDataLabel from '../common/NoDataLabel';

import { getYoutubeAgeGraphData } from '../../../../api_handlers/reporting';

class AgeSplitupGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  async getData() {
    const resp = await getYoutubeAgeGraphData(this.props.campaignId);
    this.setState({ data: resp.data, loading: false });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  render() {
    return (
      // <Card>
      //   <CardContent>
      <Grid container style={{ border: '1px solid #dedede', padding: 10,height:'100%' }}>
        <Grid item md={12} class="dashboard-card-title">
          <Typography style={{fontWeight:600,fontSize:14}}>Viewers Age Splitup</Typography>
        </Grid>
        <Grid item md={12} style={{ height: 205,margin:10 }}>
          {this.state.loading ? (
            <CircularProgress style={{ marginTop: 80 }} />
          ) : this.state.data.length === 0 ? (
            <NoDataLabel offsetTop={9} />
          ) : (
            <Bar
              style={{
                // position: 'relative',
                // top: '0px',
              }}
              width={700}
              data={{
                labels: this.state.data.labels,
                datasets: [
                  {
                    label: 'Age',
                    data: this.state.data.dataset,
                    backgroundColor: ['rgba(255, 99, 132, 1)'],
                  },
                ],
              }}
            />
          )}
        </Grid>
      </Grid>
      //   </CardContent>
      // </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
  };
};

export default connect(mapStateToProps)(withRouter(AgeSplitupGraph));
