import { Component } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import {
  Container,
  Grid,
  InputBase,
  Modal,
  TextField,
  Radio,
  Button,
  Card,
  CardContent,
  Typography,
  CardActions,
  CardHeader,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { campaignHandlers } from '../../../api_handlers/campaign';

import {
  influencerInstagramFetchNewPosts,
  influencerInstagramFetchMorePosts,
  influencerInstagramFetchedPosts,
  influencerInstagramAddPost,
} from '../../../api_handlers/influencer';

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
    width: '50vw',
    transform: 'translate(50%, 40vh)',
    paddingLeft: '10px',
  },
  imageList: {
    '& .MuiImageListItemBar-titleWrap': {
      color: '#333 !important',
    },
  },
});

class AddPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'all',
      posts: [],
      selectedPost: null,
      toastOpen: false,
      message: '',
      loading: false,
    };
  }

  toggleModal() {
    this.props.onClose();
  }

  fetchNewPosts() {
    influencerInstagramFetchNewPosts().then((resp) => {
      this.setState({ posts: resp.posts });
    });
  }

  async fetchMorePosts() {
    await this.setState({ loading: true });
    influencerInstagramFetchMorePosts().then((resp) => {
      this.setState({
        posts: this.state.posts.concat(resp.posts),
        loading: false,
      });
    });
  }

  componentDidMount() {
    influencerInstagramFetchedPosts().then((resp) => {
      this.setState({ posts: resp });
    });
  }

  async addPost() {
    try {
      const resp = await influencerInstagramAddPost({
        post_id: this.state.selectedPost,
        campaign_id: this.props.campaignId,
      });
      this.setState({
        toastOpen: true,
        message: resp.data.message,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      this.setState({
        toastOpen: true,
        message: error.response.data.message,
      });
    }
  }

  async addPostLink(url) {
    this.setState({
      is_loading: true,
    });
    const post_data = {
      action_type: 'instagram',
      campaign_id: this.props.campaignId,
      url,
    };
    try {
      await campaignHandlers.addPostLink(post_data).then((r) => {
        this.setState({
          toastOpen: true,
          message: r.message,
        });
      });
    } catch (error) {
      this.setState({
        toastOpen: true,
        message: 'Something went wrong',
      });
    }
    // if (this.state.message == "Updated") {
    //     window.location.reload();
    // }
  }

  render() {
    const { classes } = this.props;
    return (
      <Modal
        open={this.props.modalOpen}
        onClose={() => {
          this.toggleModal();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ inset: '-200px 0px 0px 0px' }}
      >
        <Card className={classes.root}>
          <CardHeader title="Add Post" />
          <div style={{ height: '500px', overflow: 'scroll' }}>
            <CardContent>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{
                  padding: '10px',
                }}
              >
                <Grid item md={1} alignItems="flex-start">
                  <p>URL:</p>
                </Grid>
                <Grid item md={8}>
                  <TextField
                    id="post_url"
                    style={{
                      margin: 8,
                    }}
                    placeholder="Paste URL"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} alignItems="flex-end" style={{ textAlign: 'center' }}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: 'rgb(30, 156, 178)',
                      color: 'white',
                    }}
                    onClick={() => this.addPostLink(document.getElementById('post_url').value)}
                  >
                    Add Post
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <h3
                style={{
                  width: '100%',
                  'text-align': 'center',
                  'border-bottom': '1px solid #000',
                  'line-height': '0.1em',
                  margin: '10px 0 20px',
                }}
              >
                <span style={{ background: '#fff', padding: '0 10px' }}>or</span>
              </h3>
            </CardContent>
            <CardHeader
              title=""
              subheader="Pick one post from the list to add it to the instagram feed"
            />
            <CardContent>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Grid item md={'auto'}>
                  <Button
                    onClick={() => {
                      this.fetchNewPosts();
                    }}
                  >
                    Refresh Posts
                  </Button>
                </Grid>
              </Grid>
              <Grid container style={{ height: 300, overflowY: 'scroll' }}>
                <ImageList rowHeight={180} className={classes.imageList}>
                  {this.state.posts.map((item) => (
                    <ImageListItem key={item.id}>
                      <img src={item.thumbnail} alt={item.id} />
                      <ImageListItemBar
                        title={item.post_type}
                        subtitle={<span>Created at: {item.created_at}</span>}
                        actionIcon={
                          <Radio
                            checked={this.state.selectedPost === item.id}
                            onChange={() => {
                              this.setState({ selectedPost: item.id });
                            }}
                            value={item.id}
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'A' }}
                          />
                        }
                        style={{
                          background: 'rgba(255, 255, 255, 0.8)',
                          color: 'black',
                        }}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
                {this.state.loading ? (
                  <Grid item md={6} style={{ margin: 'auto' }}>
                    <Grid container>
                      <Grid item md={'auto'} style={{ margin: 'auto' }}>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item md={'auto'} style={{ margin: 'auto' }}>
                        <h6>Please wait a few minutes while we fetch your post.</h6>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Button
                    onClick={() => {
                      this.fetchMorePosts();
                    }}
                    style={{ margin: 'auto', marginTop: '15px' }}
                    variant="contained"
                    color="primary"
                  >
                    Load More Posts
                  </Button>
                )}
              </Grid>
              <Snackbar
                open={this.state.toastOpen}
                autoHideDuration={5000}
                onClose={() => {
                  this.setState({ toastOpen: false });
                }}
              >
                <Alert
                  onClose={() => {
                    this.setState({ toastOpen: false });
                  }}
                  severity="info"
                >
                  {this.state.message}
                </Alert>
              </Snackbar>
            </CardContent>
          </div>
          <CardActions>
            <Button
              onClick={() => {
                this.addPost();
              }}
              style={{
                color: 'white',
                backgroundColor: 'rgb(30, 156, 178)',
              }}
            >
              Add
            </Button>
            <Button
              onClick={() => {
                this.toggleModal();
              }}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Modal>
    );
  }
}

export default withStyles(styles, {})(AddPost);
