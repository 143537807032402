import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  Paper,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import NoDataLabel from '../common/NoDataLabel';

import { getInstagramTopHashtags } from '../../../../api_handlers/reporting';

class TopHashtags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topHashtags: [],
      loading: true,
    };
  }

  async getData() {
    const resp = await getInstagramTopHashtags(this.props.campaignId);
    this.setState({ topHashtags: resp.data, loading: false });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  render() {
    return (
      <Card>
        <CardContent style={{ padding: '4px 0px', height: 318 }}>
          <Grid container>
            <Grid item md={12}>
              <Grid
                container
                style={{
                  fontSize: '12px',
                }}
              >
                <Grid
                  item
                  md={4}
                  class="dashboard-card-title"
                  style={{ marginLeft: '10px', marginTop: '7px' }}
                >
                  Top Hashtags
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              {this.state.loading ? (
                <CircularProgress style={{ marginTop: 120 }} />
              ) : this.state.topHashtags.length === 0 ? (
                <NoDataLabel offsetTop={100} />
              ) : (
                <List dense>
                  {this.state.topHashtags.map((hashtag, i) => {
                    return (
                      <>
                        <ListItem
                          key={i}
                          button
                          style={{
                            marginTop: '16px',
                            marginBottom: '15px',
                          }}
                        >
                          <span class="dashboard-card-label">{`#${hashtag.name}`}</span>
                          <ListItemSecondaryAction>
                            <Paper elevation={0} class="dashboard-card-label">
                              {hashtag.count}
                            </Paper>
                          </ListItemSecondaryAction>
                        </ListItem>
                        {i !== this.state.topHashtags.length - 1 ? (
                          <Divider variant="fullWidth" />
                        ) : null}
                      </>
                    );
                  })}
                </List>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
  };
};

export default connect(mapStateToProps)(withRouter(TopHashtags));
