import React from 'react';
import { useEffect, useState, Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import CampaignTypes from './youtube/ytCampaignTypes';
import { Line } from 'react-chartjs-2';
import CreatorTypes from './youtube/ytCreator';
import ListHeader from './youtube/ytListHeader';
import CategoryCreatorTypes from './youtube/ytCategoryCreator';

import CreatorInfoCard from './youtube/ytCreatorInfoCard';
import Summary from './youtube/ytSummary';
import { ytTopCreatorFetch, fetchYTPostsOverTime } from 'api_handlers/competitor';

function YoutubeInsights({ classes, brand_id, brandName, startDate, endDate, searchKeyword, paidPost }) {
  const [results, setResults] = useState([]);
  const [postOverTimeData, setPostOverTimeData] = useState([]);

  useEffect(() => {
    const fetchCreators = async () => {
      try {
        const resp = await ytTopCreatorFetch(brandName, startDate, endDate, searchKeyword, paidPost);
        setResults(resp);
      } catch (error) {
        console.error('Error fetching creators:', error);
      }
    };
    fetchCreators();
    const fetchPostsOverTime = async () => {
      try {
        const resp = await fetchYTPostsOverTime(brandName, startDate, endDate, searchKeyword, paidPost);
        setPostOverTimeData(resp);
      } catch (error) {
        console.error('Error fetching creators:', error);
      }
    };
    fetchPostsOverTime();
  }, [searchKeyword, paidPost]);

  return (
    <Grid container style={{ background: 'white' }}>
      <Grid item md={12}>
        <Summary brandName={brandName} startDate={startDate} endDate={endDate} searchKeyword={searchKeyword} paidPost={paidPost}/>
        <Grid item md={12} style={{ paddingTop: '20px', paddingBottom: '20px' }}></Grid>
        <Grid item md={12}>
          <CreatorTypes brand_id={brand_id} brandName={brandName} startDate={startDate} endDate={endDate} searchKeyword={searchKeyword} paidPost={paidPost}/>
        </Grid>
        <Grid item md={12} style={{ marginTop: '54px', paddingTop: '20px', paddingBottom: '0px' }}></Grid>
        <Grid item md={12}>
          <CategoryCreatorTypes brandName={brandName} startDate={startDate} endDate={endDate} searchKeyword={searchKeyword} paidPost={paidPost}/>
        </Grid>
        <Grid
          item
          md={12}
          style={{ marginTop: '35px', paddingTop: '20px', paddingBottom: '0px' }}
        ></Grid>
        <Grid item md={12}>
          <CampaignTypes brandName={brandName} startDate={startDate} endDate={endDate} searchKeyword={searchKeyword} paidPost={paidPost}/>
        </Grid>
        <Grid item md={12} style={{ paddingTop: '20px', paddingBottom: '20px' }}></Grid>

        <Grid container spacing={3}>
          <Grid item md={12} className={classes.header}>
            <Grid item md={12}>
              <Typography style={{ color: 'black' }}>BEST PERFORMING POSTS</Typography>
            </Grid>
          </Grid>
          <Grid item md={12} style={{ paddingTop: '20px', background: 'white' }}></Grid>
          <Grid container spacing={3} className={classes.card}>
            <Grid item md={12}>
              <ListHeader />
            </Grid>
            <Grid item md={12}>
              <Grid container>
                {results &&
                  results.map((result, index) => (
                    <Grid item md={12} key={index}>
                      <CreatorInfoCard {...result} />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} style={{ paddingTop: '20px', paddingBottom: '20px' }}></Grid>

        <Grid container spacing={3}>
          <Grid item md={12} className={classes.header}>
            <Grid item md={12}>
              <Typography style={{ color: 'black' }}>Posts Over Time</Typography>
            </Grid>
          </Grid>
          <Grid item md={12} style={{ paddingTop: '20px', background: 'white' }}></Grid>
          <Grid container spacing={3} className={classes.card}>
            <Line
                height={240}
                width={700}
                data={{
                  labels: postOverTimeData.labels,
                  datasets: [
                    {
                      label: 'Posts Over Time',
                      data: postOverTimeData.post_count,
                      fill: false,
                      backgroundColor: 'rgb(255, 99, 132)',
                      borderColor: 'rgb(255, 99, 132)',
                    },
                  ],
                }}
                options={{
                  indexAxis: 'x',
                  scales: {
                    y: {
                      beginAtZero: true
                    }
                  }
                }}
              />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = (theme) => ({
  header: {
    color: '#4A4A4A',
    fontSize: '20px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    lineHeight: 1.167,
    letterSpacing: '0.03em',
    padding: '15px',
    background: '#F3F4FE',
    boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    borderRadius: '10px',
    marginBottom: '6px',
    marginTop: '35px',
  },
  card: {
    background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: '52px',
    paddingRight: '52px',
  },
});

export default withStyles(styles)(YoutubeInsights);
