import { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, FormControl, NativeSelect, InputBase } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import SidePanel from './SidePanel';
import InstagramDashboard from './InstagramDashboard';
import YoutubeDashboard from './YoutubeDashboard';
import DataRangePicker from '../ux/DateRangePicker';
import BaseFilter from '../ux/BaseFilter';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FFF',
    border: '0.5px solid #C8C8C8',
    borderRadius: '5px',
    fontSize: 11,
    paddingLeft: '15px',
    height: '20px',
    width: '100px',
  },
}))(InputBase);

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
    };
  }
  render() {
    console.log('props', this.props.current_campaign);
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{
          paddingLeft: Object.keys(this.props.current_campaign).length === 0 ? 85 : 0,
          paddingTop: Object.keys(this.props.current_campaign).length === 0 ? 10 : 0,
        }}
      >
        {Object.keys(this.props.current_campaign).length === 0 ? (
          <Grid item md={2}>
            <SidePanel />
          </Grid>
        ) : (
          ''
        )}
        <Grid item md={12}>
          <Grid container spacing={2}>
            <BaseFilter>
              <Grid item md={12}>
                <Grid
                  container
                  justify="flex-center"
                  spacing={0}
                  style={{ marginTop: '0px' }}
                  alignItems="center"
                >
                  <Grid
                    item
                    md={4}
                    style={{
                      fontSize: 11,
                      paddingRight: 0,
                    }}
                  >
                    <span>Filter by:</span>
                  </Grid>
                  <Grid item md={8} style={{ paddingLeft: 0 }}>
                    <DataRangePicker
                      id="date-picker"
                      placement="left-end"
                      onSelect={async (result) => {
                        await this.setState({
                          startDate: result[0].startDate,
                          endDate: result[0].endDate,
                        });
                        if (this.youtubeDashboardRef)
                          this.youtubeDashboardRef.updateDashboardData();
                        else if (this.instagramDashboardRef)
                          this.instagramDashboardRef.updateDashboardData();
                      }}
                    ></DataRangePicker>
                  </Grid>
                </Grid>
              </Grid>
            </BaseFilter>
            <Grid item md={12}>
              <Switch>
                <Route path={`${this.props.match.url}/youtube`}>
                  <YoutubeDashboard
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onRef={(ref) => (this.youtubeDashboardRef = ref)}
                  />
                </Route>
                <Route path={`${this.props.match.url}/instagram`}>
                  <InstagramDashboard
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onRef={(ref) => (this.instagramDashboardRef = ref)}
                    igTotalPosts={this.props.current_campaign.ig_total_posts}
                  />
                </Route>
              </Switch>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  current_campaign: state.campaign.current_campaign,
});

export default connect(mapStateToProps)(withRouter(Dashboard));
