import { useState } from 'react';
import { connect } from 'react-redux';
import { Grid, InputAdornment, IconButton, Button as BaseButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import TextField from 'components/Theme/Elements/TextField';
import Button from 'components/Theme/Elements/Button';
import InstagramIconButton from '../InstagramIconButton';
import YouTubeIconButton from '../YouTubeIconButton';

import FilterDrawer from './components/FilterDrawer';
import AddToCampaignModal from './components/AddToCampaignModal';

import { updateFilter, getFilteredInfluencer, resetFilter } from 'actions/discovery';

function ActionBar({
  filters,
  setFilters,
  selectedInfluencer,
  setSelectedInfluencer,
  getFilteredInfluencer,
  resetFilter,
}) {
  const [filterOpened, setFilterOpened] = useState(false);
  const [campaignModalOpen, setCampaignModalOpen] = useState(false);

  function handleApply() {
    getFilteredInfluencer();
  }

  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item md={6}>
        <Grid container className={classes.filterBox} spacing={3}>
          <Grid item md={3}>
            <FilterDrawer
              open={filterOpened}
              closeDrawer={() => setFilterOpened(false)}
              filters={filters}
              setFilters={setFilters}
              getFilteredInfluencer={getFilteredInfluencer}
              resetFilter={resetFilter}
            />
            <BaseButton
              variant="contained"
              size="large"
              className={classes.filterButton}
              startIcon={<FilterAltIcon className={classes.filterButtonIcon} />}
              onClick={() => setFilterOpened(!filterOpened)}
            >
              Filter
            </BaseButton>
          </Grid>
          <Grid item md={4}>
            <TextField
              placeholder="Search"
              className={classes.searchInput}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleApply} className={classes.searchButton}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              value={filters.search || null}
              onChange={({ target }) => {
                setFilters({ search: target.value });
              }}
            />
          </Grid>
          <Grid item md={'auto'}>
            <InstagramIconButton />
          </Grid>
          <Grid item md={'auto'}>
            <YouTubeIconButton />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={3}>
        <Grid container className={classes.buttonBox}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCampaignModalOpen(!campaignModalOpen)}
            >
              Add To Campaign
            </Button>
          </Grid>
        </Grid>
        <AddToCampaignModal
          campaignModalOpen={campaignModalOpen}
          setCampaignModalOpen={setCampaignModalOpen}
          selectedInfluencer={selectedInfluencer}
          setSelectedInfluencer={setSelectedInfluencer}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: 50,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterBox: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  buttonBox: {
    justifyContent: 'flex-end',
  },
  searchInput: {
    height: 45,
  },
  searchButton: {
    background: 'linear-gradient(163.61deg, rgba(0, 149, 255, 0.81) 22.36%, #0061A7 79.45%)',
    borderRadius: '50%',
    padding: 4,
    color: 'white',
    marginRight: 4,
  },
  filterButton: {
    background: '#EFF0F9',
    boxShadow:
      '-2px 2px 4px rgba(215, 216, 224, 0.2), 2px -2px 4px rgba(215, 216, 224, 0.2), -2px -2px 4px rgba(255, 255, 255, 0.9), 2px 2px 5px rgba(215, 216, 224, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 224, 0.5)',
    borderRadius: 10,
    textTransform: 'none',
    padding: '6px 16px',
  },
  filterButtonIcon: {
    background: 'linear-gradient(180deg, #FEBD1C 0%, #EF1A74 100%)',
    borderRadius: '50%',
    padding: 6,
    color: 'white',
    marginRight: 10,
  },
}));

const mapStateToProps = (state) => {
  return {
    filters: state.discovery.filters,
  };
};

const actionCreators = {
  setFilters: updateFilter,
  getFilteredInfluencer,
  resetFilter,
};

export default connect(mapStateToProps, actionCreators)(ActionBar);

// export default ActionBar
