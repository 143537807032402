import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, Chip, CardMedia, Button } from '@material-ui/core';
import ButtonUnstyled from '@mui/core/ButtonUnstyled';

import { withStyles } from '@material-ui/core/styles';
import more_about_u from '../../assets/more_about_u.png';

const styles = (theme) => ({
  name: {
    fontFamily: "'Lato', sans-serif",
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '17px',
  },
  description: {
    fontFamily: "'Lato', sans-serif",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '175%',
    color: '#555555',
  },
});

class MobileDashboard extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Card
        style={{
          cursor: 'pointer',
          margin: 20,
          borderRadius: 5,
          width: '90vw',
        }}
      >
        <Grid container direction="row" justifyContent="space-around" alignItems="center">
          <Grid item md={4} style={{ margin: 10 }}>
            <img src={more_about_u} style={{ width: '80px' }} />
          </Grid>
          <Grid item md={8} style={{ margin: 10 }}>
            <p
              style={{
                fontWeight: '600',
                fontSize: 12,
                textAlign: 'left',
              }}
            >
              Complete your account, Tell us more about
            </p>
            <p
              style={{
                fontWeight: '600',
                fontSize: 12,
                textAlign: 'left',
              }}
            >
              {' '}
              yourself.
            </p>
            <Grid style={{ textAlign: 'left' }}>
              <NavLink to="/influencer/about-me/">
                <ButtonUnstyled
                  style={{
                    background: '#1E9CB2',
                    color: 'white',
                    padding: '5px 20px',
                    marginBottom: '20px',
                    width: 'Enter Details',
                    fontSize: '10px',
                    borderRadius: '3px',
                    border: '0px',
                  }}
                >
                  Enter Details
                </ButtonUnstyled>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

export default withRouter(withStyles(styles)(MobileDashboard));
