import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import PersonalDetails from './PersonalDetails';
import AboutYourself from './AboutYourself';
import ConnectSocialMedia from './ConnectSocialMedia';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 15,
  },
  active: {
    '& $line': {
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    borderTop: '2px dashed #BCBCBC',
    // backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    boxShadow: '0px 0px 0px 4px rgba(104, 104, 104, 0.17)',
  },
  active: {
    background: '#686868',
    boxShadow: '0px 0px 0px 4px rgba(104, 104, 104, 0.17)',
  },
  completed: {
    boxShadow: '0px 0px 0px 4px rgba(30, 156, 178, 0.17)',
    background: '#1E9CB2',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: '1',
    2: '2',
    3: '3',
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Profile Details', 'About Yourself', 'Connect Social Media'];
}

function getStepContent(stepIndex, handleNext) {
  switch (stepIndex) {
    case 0:
      return <PersonalDetails handleNext={handleNext} />;
    case 1:
      return <AboutYourself handleNext={handleNext} />;
    case 2:
      return <ConnectSocialMedia />;
    default:
      return '';
  }
}

export default function HorizontalLabelPositionBelowStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label, i) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <span
                style={{
                  fontSize: 11,
                  color: i < activeStep ? '#1E9CB2' : '#686868',
                  fontWeight: i < activeStep ? 550 : 500,
                }}
              >
                {label}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={`form_padding`}>
        <div>
          {getStepContent(activeStep, handleNext)}
          <div style={{ marginTop: 15 }}>
            {activeStep === 1 ? (
              <a
                onClick={handleSkip}
                className={classes.backButton}
                style={{
                  cursor: 'pointer',
                  color: '#7C7C7C',
                  fontSize: 12,
                }}
              >
                Skip this step
              </a>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
