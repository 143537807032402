import * as React from 'react';
import { Grid, Typography, RadioGroup, Radio } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import CustomSlider from 'components/PlannerTheme/Elements/Slider/Slider';
import SliderWithoutBackgroundCustomSlider from 'components/PlannerTheme/Elements/SliderWithoutBackground/SliderWithoutBackground';

class AudienceGenderSplit extends React.Component {
  render() {
    const { classes, genderGroupType, genderGroupPercentage } = this.props;

    return (
      <Grid
        container
        style={{ flex: 2, border: '1px solid  #ADADAD', borderRadius: 23, marginRight: 50 }}
      >
        <RadioGroup
          value={genderGroupType}
          onChange={(event) => {
            this.props.setGenderGroup('genderGroupType', event.target.value);
          }}
        >
          <Grid container style={{ alignItems: 'center' }}>
            <Radio color="primary" value={'Male'} />
            <Typography className={classes.optionLabel}>Male</Typography>
          </Grid>
          <Grid container style={{ alignItems: 'center' }}>
            <Radio color="primary" value={'Female'} />
            <Typography className={classes.optionLabel}>Female</Typography>
          </Grid>
        </RadioGroup>
        <div style={{ width: 250, marginLeft: 10 }}>
          <Typography
            className={classes.selectedText}
          >{`atleast ${genderGroupPercentage} %`}</Typography>
          <SliderWithoutBackgroundCustomSlider
            className={classes.slider}
            aria-label="Always visible"
            max={100}
            onChange={(event, newValue) => {
              this.props.setGenderGroup('genderGroupPercentage', newValue);
            }}
            step={1}
            disabled={genderGroupType ? false : true}
            value={genderGroupPercentage}
          />
        </div>
      </Grid>
    );
  }
}

const styles = {
  optionLabel: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 13,
    color: '#757575',
    // marginLeft:10
  },
  selectedText: {
    color: '#FEBD1C',
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: 13,
  },
  slider: {
    width: 150,
  },
};

export default withStyles(styles)(AudienceGenderSplit);
