import { devConfig } from '../config';
import { apiServer } from './index';

export function getDisplayPanels() {
  return apiServer.get('brand/display-panels');
}

export function getBrandProfile() {
  return apiServer.get('brand/profile');
}

export function getDeliverables() {
  return apiServer.get(`/brand/campaign_deliverables`).then((response) => {
    return response.data;
  });
}

export function saveContactDetails(data) {
  const BASE_URL = `${devConfig.API_HOST}/api/v1/brand`;
  let headers = {}
  headers['Content-Type'] = 'application/json';
  headers['Authorization'] = `Bearer ${localStorage.getItem('access')}`;
  return fetch(`${BASE_URL}/save_contact_form`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  });
}
