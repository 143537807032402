import constant from '../constants/brand';

export default (state = { brand: {}, loading: false }, action) => {
  switch (action.type) {
    case constant.DISPLAY_PANEL:
      return {
        ...state,
        displayPanels: action.data,
      };
    default:
      return state;
  }
};
