import { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Link, Grid } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { getInfluencerInstagramFeeds } from '../../../../../api_handlers/feeds';

const useStyles = makeStyles({
  headerCell: {
    background: '#C4C4C4',
    fontWeight: 600,
    textAlign: 'center',
  },
  table: {
    minWidth: 650,
  },
  cell: {
    textAlign: 'center',
  },
});

const styles = (theme) => ({
  headerCell: {
    background: '#C4C4C4',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 12,
  },
  table: {
    minWidth: 650,
  },
  cell: {
    textAlign: 'center',
    fontSize: 12,
  },
});

class InstagramPostTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feeds: [],
      count: 0,
      page: 1,
      loading: false,
    };
  }
  async getData() {
    this.setState({
      loading: true,
    });
    const campaignId = this.props.match.params.campaignId;
    const influencerId = this.props.match.params.influencerId;
    const resp = await getInfluencerInstagramFeeds(this.state.page, campaignId, influencerId, {
      only_story: this.props.storyTab ? true : false,
    });
    this.setState({
      feeds: this.state.feeds.concat(resp.results),
      count: resp.count,
      loading: false,
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.infiniteScroll);
    setTimeout(() => {
      this.getData(this.state.page);
    }, 30);
  }

  ellipsis(text) {
    if (text && text.length > 80) return text.slice(0, 77) + '...';
    return text;
  }

  infiniteScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      if (this.state.feeds.length < this.state.count && this.state.loading == false) {
        let newPage = this.state.page;
        newPage++;
        this.setState({
          page: newPage,
        });
        this.getData();
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <TableContainer component={Paper}>
        <Table stickyHeader className={classes.table} aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>Post Name</TableCell>
              <TableCell className={classes.headerCell}>Post Type</TableCell>
              <TableCell className={classes.headerCell}>User</TableCell>
              <TableCell className={classes.headerCell}>Impressions</TableCell>
              <TableCell className={classes.headerCell}>Reach</TableCell>
              <TableCell className={classes.headerCell}>Likes</TableCell>
              <TableCell className={classes.headerCell}>Comments</TableCell>
              <TableCell className={classes.headerCell}>Saved</TableCell>
              <TableCell className={classes.headerCell}>Video Views</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.feeds.map((feed) => (
              <TableRow key={feed.name}>
                <TableCell component="th" scope="feed" className={classes.cell}>
                  <Grid container spacing={3}>
                    <Grid item={3}>
                      <img src={feed.thumbnail} height={48} weight={48} />
                    </Grid>
                    <Grid item={9}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Grid
                          item
                          md={12}
                          style={{
                            wordBreak: 'normal',
                            width: 180,
                            textAlign: 'left',
                          }}
                        >
                          <span>{this.ellipsis(feed.title)}</span>
                        </Grid>
                        <Grid item md={12}>
                          <span>
                            <i>Added on: {feed.added_on}</i>
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {feed.name}
                </TableCell>
                <TableCell className={classes.cell}>{feed.post_type}</TableCell>
                <TableCell className={classes.cell}>{feed.user_name}</TableCell>
                <TableCell className={classes.cell}>{feed.impressions}</TableCell>
                <TableCell className={classes.cell}>{feed.reach}</TableCell>
                <TableCell className={classes.cell}>{feed.like_count}</TableCell>
                <TableCell className={classes.cell}>{feed.comment_count}</TableCell>
                <TableCell className={classes.cell}>{feed.saved}</TableCell>
                <TableCell className={classes.cell}>{feed.video_views}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default withStyles(styles, {})(InstagramPostTable);
