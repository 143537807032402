import React from 'react';
import { useEffect, useState, Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from '@material-ui/core';

import { creatorTypesFetch } from 'api_handlers/competitor';

const styles = (theme) => ({
  // base: {
  //     background: "white",
  //     height: 700,
  //     width: 1200,
  //     margin: "auto",
  //     marginTop: 50,
  // },
  // root: {
  //     background: "white",
  //     filter: "drop-shadow(5px 8px 15px rgba(171, 171, 171, 0.25))",
  //     height: 700,
  //     width: 1200,
  //     borderRadius: 10,
  //     padding: 40,
  //     overflowY: "scroll",
  // },

  header: {
    color: '#4A4A4A',
    fontSize: '20px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    lineHeight: 1.167,
    letterSpacing: '0.03em',
    padding: '15px',
    background: '#F3F4FE',
    boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    borderRadius: '10px',
    marginBottom: '15px',
  },
  headerCell: {
    // background: "#8282d4",
    background: '#e4e4e4',
    fontWeight: 400,
    lineHeight: 1.167,
    letterSpacing: '0.03em',
    paddingLeft: '40px',
  },
  table: {
    minWidth: 650,
  },
  cell: {
    textAlign: 'center',
    fontSize: 13,
  },
  card: {
    background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: '52px',
    paddingRight: '52px',
  },
  aHover: {
    color: '#111111',
  },
});

// function calculateEngagementRate(likes, comments, views, followers) {
//   likes = likes !== null ? likes : 0;
//   comments = comments !== null ? comments : 0;
//   views = views !== null ? likes : 0;
//   if (followers === 0 || followers === null) {
//     return 0;
//   }
//   const engagementRate = ((likes + comments + views) / followers) * 100;
//   return parseFloat(engagementRate.toFixed(2));
// }

function countertostring(counter) {
  counter = parseFloat(counter);
  if (counter >= 1000 && counter < 999999) {
    return (
      parseFloat(counter / 1000.0)
        .toFixed(2)
        .toString() + 'K'
    );
  } else if (counter > 999999) {
    return (
      parseFloat(counter / 1000000.0)
        .toFixed(2)
        .toString() + 'M'
    );
  } else {
    return counter.toString();
  }
}

function CreatorTypes({ classes, brand_id, brandName, startDate, endDate, searchKeyword, paidPost }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchCreatorsType = async () => {
      let creatorData = [
        {
          influencer_type: 'All Influencers',
          accounts: '0',
          followers: '0',
          likes: '0',
          comments: '0',
          posts: '0',
          eng_rate: '0 %',
          fair_price:'₹0 - 0'
        },
        {
          influencer_type: 'Mega Influencers',
          accounts: '0',
          followers: '0',
          likes: '0',
          comments: '0',
          posts: '0',
          eng_rate: '0 %',
          fair_price:'₹0 - 0'

        },
        {
          influencer_type: 'Macro Influencers',
          accounts: '0',
          followers: '0',
          likes: '0',
          comments: '0',
          posts: '0',
          eng_rate: '0 %',
          fair_price:'₹0 - 0'

        },
        {
          influencer_type: 'Micro Influencers',
          accounts: '0',
          followers: '0',
          likes: '0',
          comments: '0',
          posts: '0',
          eng_rate: '0 %',
          fair_price:'₹0 - 0'

        },
        {
          influencer_type: 'Nano Influencers',
          accounts: '0',
          followers: '0',
          likes: '0',
          comments: '0',
          posts: '0',
          eng_rate: '0 %',
          fair_price:'₹0 - 0'

        },
      ];
      try {
        const resp = await creatorTypesFetch(brandName, startDate, endDate, searchKeyword, paidPost);
        if (resp.creator_type) {
          resp.creator_type.map((each) => {
            if (each.creator_type == 'macro') {
              creatorData[2].accounts = countertostring(each['creators']);
              creatorData[2].followers =
                each['followers'] === null ? 0 : countertostring(each['followers']);
              creatorData[2].likes =
                each['likes_count'] === null ? 0 : countertostring(each['likes_count']);
              creatorData[2].comments =
                each['comments_count'] === null ? 0 : countertostring(each['comments_count']);
              creatorData[2].posts = each['count'] === null ? 0 : countertostring(each['count']);
              creatorData[2].eng_rate = parseFloat(each['engagement__rate']).toFixed(2);
              creatorData[2].fair_price = each['fair_price'];

            }
            if (each.creator_type == 'nano') {
              creatorData[4].accounts = countertostring(each['creators']);
              creatorData[4].followers =
                each['followers'] === null ? 0 : countertostring(each['followers']);
              creatorData[4].likes =
                each['likes_count'] === null ? 0 : countertostring(each['likes_count']);
              creatorData[4].comments =
                each['comments_count'] === null ? 0 : countertostring(each['comments_count']);
              creatorData[4].posts = each['count'] === null ? 0 : countertostring(each['count']);
              creatorData[4].eng_rate = parseFloat(each['engagement__rate']).toFixed(2);
              creatorData[4].fair_price = each['fair_price'];

            }
            if (each.creator_type == 'micro') {
              creatorData[3].accounts = countertostring(each['creators']);
              creatorData[3].followers =
                each['followers'] === null ? 0 : countertostring(each['followers']);
              creatorData[3].likes =
                each['likes_count'] === null ? 0 : countertostring(each['likes_count']);
              creatorData[3].comments =
                each['comments_count'] === null ? 0 : countertostring(each['comments_count']);
              creatorData[3].posts = each['count'] === null ? 0 : countertostring(each['count']);
              creatorData[3].eng_rate = parseFloat(each['engagement__rate']).toFixed(2);
              creatorData[3].fair_price = each['fair_price'];

            }
            if (each.creator_type == 'mega') {
              creatorData[1].accounts = countertostring(each['creators']);
              creatorData[1].followers =
                each['followers'] === null ? 0 : countertostring(each['followers']);
              creatorData[1].likes =
                each['likes_count'] === null ? 0 : countertostring(each['likes_count']);
              creatorData[1].comments =
                each['comments_count'] === null ? 0 : countertostring(each['comments_count']);
              creatorData[1].posts = each['count'] === null ? 0 : countertostring(each['count']);
              creatorData[1].eng_rate = parseFloat(each['engagement__rate']).toFixed(2);
              creatorData[1].fair_price = each['fair_price'];

            }
          });
        }
        if (resp.all_creators) {
          const each = resp.all_creators;
          creatorData[0].accounts = countertostring(each['creators']);
          creatorData[0].followers =
            each['followers'] === null ? 0 : countertostring(each['followers']);
          creatorData[0].likes =
            each['likes_count'] === null ? 0 : countertostring(each['likes_count']);
          creatorData[0].comments =
            each['comments_count'] === null ? 0 : countertostring(each['comments_count']);
          creatorData[0].posts = each['count'] === null ? 0 : countertostring(each['count']);
          creatorData[0].eng_rate = parseFloat(each['engagement__rate']).toFixed(2);
          creatorData[0].fair_price = each['fair_price'];

        }
        setData(creatorData);
      } catch (error) {
        console.error('Error fetching creators:', error);
      }
    };
    fetchCreatorsType();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item md={12} className={classes.header}>
        <Grid item md={12}>
          <Typography style={{ color: 'black' }}>TYPE OF CREATORS (By Size)</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        style={{ paddingTop: '20px', paddingBottom: '20px', background: 'white' }}
      ></Grid>
      <Grid container spacing={3} className={classes.card}>
        <TableContainer component={Paper}>
          <Table stickyHeader className={classes.table} aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell}>Category</TableCell>
                <TableCell className={classes.headerCell}>Accounts</TableCell>
                <TableCell className={classes.headerCell} style={{paddingLeft: '48px'}}>Followers</TableCell>
                <TableCell className={classes.headerCell} style={{paddingLeft: '35px'}}>Likes</TableCell>
                <TableCell className={classes.headerCell} style={{paddingLeft: '46px'}}>Comments</TableCell>
                <TableCell className={classes.headerCell}>Posts</TableCell>
                <TableCell className={classes.headerCell}>Eng Rate</TableCell>
                <TableCell className={classes.headerCell}>Fair price</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="feed" className={classes.cell}>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item={8}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                        >
                          <Grid
                            item
                            md={12}
                            style={{
                              wordBreak: 'normal',
                              width: 180,
                              textAlign: 'left',
                            }}
                          >
                            <Grid style={{ cursor: 'pointer' }}>
                              {row.influencer_type == 'All Influencers' ? (
                                <Typography
                                  onClick={() => {
                                    window.open(
                                      `/competitor-insights/${brand_id}/${brandName}/instagram/detailed-insights?start_date=${startDate}&end_date=${endDate}&keyword=${searchKeyword}&paidPost=${paidPost}`,
                                      '_blank',
                                    );
                                  }}
                                >
                                  {row.influencer_type}
                                </Typography>
                              ) : row.influencer_type == 'Nano Influencers' ? (
                                <Typography
                                  onClick={() => {
                                    window.open(
                                      `/competitor-insights/${brand_id}/${brandName}/instagram/detailed-insights?creator_type=nano&start_date=${startDate}&end_date=${endDate}&keyword=${searchKeyword}&paidPost=${paidPost}`,
                                      '_blank',
                                    );
                                  }}
                                >
                                  {row.influencer_type}
                                </Typography>
                              ) : row.influencer_type == 'Micro Influencers' ? (
                                <Typography
                                  onClick={() => {
                                    window.open(
                                      `/competitor-insights/${brand_id}/${brandName}/instagram/detailed-insights?creator_type=micro&start_date=${startDate}&end_date=${endDate}&keyword=${searchKeyword}&paidPost=${paidPost}`,
                                      '_blank',
                                    );
                                  }}
                                >
                                  {row.influencer_type}
                                </Typography>
                              ) : row.influencer_type == 'Macro Influencers' ? (
                                <Typography
                                  onClick={() => {
                                    window.open(
                                      `/competitor-insights/${brand_id}/${brandName}/instagram/detailed-insights?creator_type=macro&start_date=${startDate}&end_date=${endDate}&keyword=${searchKeyword}&paidPost=${paidPost}`,
                                      '_blank',
                                    );
                                  }}
                                >
                                  {row.influencer_type}
                                </Typography>
                              ) : row.influencer_type == 'Mega Influencers' ? (
                                <Typography
                                  onClick={() => {
                                    window.open(
                                      `/competitor-insights/${brand_id}/${brandName}/instagram/detailed-insights?creator_type=mega&start_date=${startDate}&end_date=${endDate}&keyword=${searchKeyword}&paidPost=${paidPost}`,
                                      '_blank',
                                    );
                                  }}
                                >
                                  {row.influencer_type}
                                </Typography>
                              ) : (
                                ''
                              )}
                              {/* <Typography onClick={()=>{window.open(`/competitor-insights/${brand_id}/${brandName}/instagram/detailed-insights`, "_blank")}}>{row.influencer_type}</Typography> */}
                            </Grid>

                            {/* //   :''
                            // } */}

                            {/* <span>
                              <a
                                href={`/competitor-insights/${brand_id}/${brandName}/instagram/detailed-insights`}
                                target="_blank"
                                style={{
                                  color: "#111111",
                                  textDecoration: "none",
                                //   ':hover': aHover,
                                //   ':visited': aHover,
                                }}
                              >
                                {row.influencer_type}
                              </a>
                            </span> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell className={classes.cell}>{row.accounts}</TableCell>
                  <TableCell className={classes.cell}>{row.followers}</TableCell>
                  <TableCell className={classes.cell}>{row.likes}</TableCell>
                  <TableCell className={classes.cell}>{row.comments}</TableCell>
                  <TableCell className={classes.cell}>{row.posts}</TableCell>
                  <TableCell className={classes.cell}>{row.eng_rate}</TableCell>
                  <TableCell className={classes.cell}>{row.fair_price}</TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(CreatorTypes);
