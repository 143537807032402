import { useEffect, useState, Component } from 'react';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';

import Button from 'components/Theme/Elements/Button';

import CategoryContainer from '../CategoryContainer';
import InstagramRectIconButton from '../InstagramRectIconButton';
import YouTubeIconButton from 'components/Discovery/components/YouTubeIconButton';

import InfoCard from './components/InfoCard';
import DetailViewModal from './components/DetailViewModal';
import Checkbox from '@mui/material/Checkbox';
import { maxWidth } from '@mui/system';

class InfluencerInfoCard extends Component {
  constructor(props) {
    super(props);

    this.setDetailViewOpened = this.setDetailViewOpened.bind(this);

    this.state = {
      detailViewOpened: false,
      is_checked: false,
      // brand_final_price: 25000,
      // influencer_final_price: 15000,
      deliverables: [
        { name: 'Instagram Reel' },
        { name: 'Instagram Post' },
        { name: 'Instagram Static' },
        { name: 'Instagram Story' },
        { name: 'Instagram TV' },
        { name: 'Instagram Live' },
      ],
    };
  }
  setDetailViewOpened(boolean) {
    this.setState({ detailViewOpened: boolean });
  }

  handleSelection(e) {
    // e.preventDefault()
    // console.log(this.props.selectedInfluencer.includes(this.props.influencer))
    const current_prediction = this.props.selectedPrediction;
    if (this.props.selectedInfluencer.includes(this.props.id)) {
      // e.preventDefault()

      // console.log(this.props.selectedPrediction,this.props.prediction.Reach,this.props.prediction,current_prediction['enagaged_users'],this.props.prediction.enagaged_users)
      if (this.props.platform === 'instagram') {
        if (this.props.prediction.followers < 10000) {
          current_prediction['reach'] =
            current_prediction['reach'] + this.props.prediction.followers * 0.6;
        }
        if (this.props.prediction.followers >= 10000 && this.props.prediction.followers <= 100000) {
          current_prediction['reach'] =
            current_prediction['reach'] + this.props.prediction.followers * 0.5;
        }
        if (this.props.prediction.followers > 100000 && this.props.prediction.followers <= 500000) {
          current_prediction['reach'] =
            current_prediction['reach'] + this.props.prediction.followers * 0.4;
        }
        if (this.props.prediction.followers > 500000) {
          current_prediction['reach'] =
            current_prediction['reach'] + this.props.prediction.followers * 0.3;
        }
      } else {
        current_prediction['reach'] =
          current_prediction['reach'] + this.props.prediction.views * 1.37;
      }
      current_prediction['views'] = current_prediction['views'] - this.props.prediction.views;
      current_prediction['enagaged_users'] =
        current_prediction['enagaged_users'] - this.props.prediction.enagaged_users;
      current_prediction['followers'] =
        current_prediction['followers'] - this.props.prediction.followers;
      current_prediction['engagement_rate'] =
        current_prediction['engagement_rate'] - this.props.prediction.engagement_rate;
      current_prediction['max_price'] =
        current_prediction['max_price'] - this.props.prediction.max_price;
      if (this.props.prediction.creator_type == 'micro') {
        current_prediction['micro'] = {
          count: current_prediction['micro']['count'] - 1,
          amount: current_prediction['micro']['amount'] - this.props.prediction.max_price,
        };
      }
      if (this.props.prediction.creator_type == 'mega') {
        current_prediction['mega'] = {
          count: current_prediction['mega']['count'] - 1,
          amount: current_prediction['mega']['amount'] - this.props.prediction.max_price,
        };
      }
      if (this.props.prediction.creator_type == 'nano') {
        current_prediction['nano'] = {
          count: current_prediction['nano']['count'] - 1,
          amount: current_prediction['nano']['amount'] - this.props.prediction.max_price,
        };
      }
      if (this.props.prediction.creator_type == 'macro') {
        current_prediction['macro'] = {
          count: current_prediction['macro']['count'] - 1,
          amount: current_prediction['macro']['amount'] - this.props.prediction.max_price,
        };
      }

      // console.log(current_prediction)
      const influencers = this.props.selectedInfluencer;
      influencers.splice(influencers.indexOf(this.props.id), 1);
      this.props.setSelectedInfluencer(influencers);
      // console.log(this.props.selectedInfluencer)
    } else {
      // console.log(this.props.selectedPrediction,this.props.prediction.Reach,this.props.prediction,current_prediction['enagaged_users'],this.props.prediction.enagaged_users)
      if (this.props.platform === 'instagram') {
        if (this.props.prediction.followers < 10000) {
          current_prediction['reach'] =
            current_prediction['reach'] + this.props.prediction.followers * 0.6;
        }
        if (this.props.prediction.followers >= 10000 && this.props.prediction.followers <= 100000) {
          current_prediction['reach'] =
            current_prediction['reach'] + this.props.prediction.followers * 0.5;
        }
        if (this.props.prediction.followers > 100000 && this.props.prediction.followers <= 500000) {
          current_prediction['reach'] =
            current_prediction['reach'] + this.props.prediction.followers * 0.4;
        }
        if (this.props.prediction.followers > 500000) {
          current_prediction['reach'] =
            current_prediction['reach'] + this.props.prediction.followers * 0.3;
        }
      } else {
        current_prediction['reach'] =
          current_prediction['reach'] + this.props.prediction.views * 1.37;
      }
      current_prediction['views'] = current_prediction['views'] + this.props.prediction.views;
      current_prediction['enagaged_users'] =
        current_prediction['enagaged_users'] + this.props.prediction.enagaged_users;
      current_prediction['followers'] =
        current_prediction['followers'] + this.props.prediction.followers;
      current_prediction['engagement_rate'] =
        current_prediction['engagement_rate'] + this.props.prediction.engagement_rate;
      current_prediction['max_price'] =
        current_prediction['max_price'] + this.props.prediction.max_price;
      if (this.props.prediction.creator_type == 'micro') {
        current_prediction['micro'] = {
          count: current_prediction['micro']['count'] + 1,
          amount: current_prediction['micro']['amount'] + this.props.prediction.max_price,
        };
      }
      if (this.props.prediction.creator_type == 'mega') {
        current_prediction['mega'] = {
          count: current_prediction['mega']['count'] + 1,
          amount: current_prediction['mega']['amount'] + this.props.prediction.max_price,
        };
      }
      if (this.props.prediction.creator_type == 'nano') {
        current_prediction['nano'] = {
          count: current_prediction['nano']['count'] + 1,
          amount: current_prediction['nano']['amount'] + this.props.prediction.max_price,
        };
      }
      if (this.props.prediction.creator_type == 'macro') {
        current_prediction['macro'] = {
          count: current_prediction['macro']['count'] + 1,
          amount: current_prediction['macro']['amount'] + this.props.prediction.max_price,
        };
      }
      // console.log(current_prediction)

      // console.log(this.props.selectedInfluencer)
      this.props.setSelectedInfluencer(this.props.selectedInfluencer.concat(this.props.id));
      // console.log(this.props.selectedInfluencer)
    }
    this.props.setSelectedPrediction(current_prediction);
  }
  render() {
    
    let {
      classes,
      id,
      name,
      gender,
      language,
      picture,
      age_group,
      country,
      followers,
      engagement_rate,
      avg_views,
      audience_gender,
      url,
      interests = [],
      interest_categories,
      fair_price,
      paid_post_performance,
      influencer,
      prediction,
      price_score,
      speed_score,
      impact_score,
      selectedInfluencer,
      setSelectedInfluencer,
      selectedPrediction,
      setSelectedPrediction,
      allowedColumns,
      platform,
      audience_age_split,
      location,
      audience_location_split,
      staff_status,
      contact_details,
      email_details,
      actual_price,
      checkLimit,
      primary_category,
      brand_final_price,
      influencer_final_price,
      deliverables,
    } = this.props;
    let {
      // brand_final_price,
      // influencer_final_price,
      // deliverables,
    } = this.state;
    // console.log(selectedInfluencer,selectedPrediction, "maaz",selectedInfluencer.indexOf(influencer) > -1, influencer)
    let is_checked = selectedInfluencer.indexOf(id) > -1 ? true : false;
    return (
      <Grid container className={classes.root} spacing={0}>
        <Grid item md={1}>
          <Grid container direction="column" justifyContent="space-between" alignItems="center">
            <Grid item style={{ width: 100 }}>
              <Checkbox
                color="primary"
                style={{ marginBottom: 50, color: '#FEBD1C' }}
                size="small"
                onClick={() => {
                  this.handleSelection();
                }}
                checked={is_checked}
              />
            </Grid>
            <Grid item style={{ position: 'initial' }}>
              <InstagramRectIconButton
                onClick={() => {
                  window.open(url, '_blank');
                }}
                platform={this.props.platform}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          style={{ marginRight: 50, cursor: 'pointer' }}
          className={classes.cardBox}
          onClick={() => this.setDetailViewOpened(true)}
        >
          <InfoCard
            name={name}
            gender={gender}
            language={language}
            picture={picture}
            age_group={age_group}
          />
        </Grid>
        <Grid
          item
          md={8}
          onClick={() => this.setDetailViewOpened(true)}
          style={{ cursor: 'pointer' }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            // style={{paddingRight: '45px'}}
            style={{
              maxWidth:
                checkLimit == 2
                  ? '60%'
                  : checkLimit == 3
                  ? '78.666%'
                  : checkLimit == 4
                  ? '88.333%'
                  : checkLimit == 5
                  ? '93%'
                  : checkLimit == 6
                  ? '95.67%'
                  : '100%',
            }}
            className={classes.statsContainer}
          >
            {/* <Grid item md={1} > */}
            {/*     <InstagramRectIconButton onClick={() => {window.open(url, "_blank")}}/> */}
            {/* </Grid> */}
            {Object.values(allowedColumns).includes('Speed Score') ||
            Object.values(allowedColumns).length === 0 ? (
              <Grid
                item
                md
                style={{ marginRight: 0 }}
                className={classes.moreWidth}
                container
                alignItems="center"
                justifyContent="center"
              >
                <Grid className={classes.speed}>
                  <Typography style={{ paddingTop: 10, fontWeight: 700 }}>{speed_score}</Typography>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
            {/* {Object.values(allowedColumns).includes("Price Score") ||
              Object.values(allowedColumns).length === 0 ? (
              <Grid item md className={classes.moreWidth}>
              <div className={classes.price}>
                  <Typography style={{ paddingTop: 10, fontWeight: 700 }}>
                      {price_score}
                  </Typography>
              </div>
              </Grid>
              ):("")} */}
            {Object.values(allowedColumns).includes('Impact Score') ||
            Object.values(allowedColumns).length === 0 ? (
              <Grid item md className={classes.moreWidth}>
                <div className={classes.impact}>
                  <Typography style={{ paddingTop: 10, fontWeight: 700 }}>
                    {impact_score}
                  </Typography>
                </div>
              </Grid>
            ) : (
              ''
            )}
            {Object.values(allowedColumns).includes('Followers') ||
            Object.values(allowedColumns).length === 0 ? (
              <Grid item md className={classes.moreWidth}>
                <Typography className={classes.followers}>{followers}</Typography>
              </Grid>
            ) : (
              ''
            )}
            {Object.values(allowedColumns).includes('Engagement Rate') ||
            Object.values(allowedColumns).length === 0 ? (
              <Grid item md className={classes.moreWidth}>
                <Typography className={classes.subtitles} variant="subtitle1">
                  {engagement_rate}
                </Typography>
              </Grid>
            ) : (
              ''
            )}
            {Object.values(allowedColumns).includes('Average Views') ||
            Object.values(allowedColumns).length === 0 ? (
              <Grid item md className={classes.moreWidth}>
                <Typography className={classes.subtitles} variant="subtitle1">
                  {avg_views}
                </Typography>
              </Grid>
            ) : (
              ''
            )}
            {Object.values(allowedColumns).includes('Audience Gender') ||
            Object.values(allowedColumns).length === 0 ? (
              <Grid item md className={classes.moreWidth}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography className={classes.subtitles} variant="subtitle1">
                      {audience_gender?.percentage??""}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.subtitles2} variant="subtitle3">
                      {audience_gender?.gender??""}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
            {/* <Grid item md className={classes.moreWidth} > */}
            {/*     <Typography variant="subtitle1"> */}
            {/*         {paid_post_performance} */}
            {/*     </Typography> */}
            {/* </Grid> */}

            {/* <Grid item md={1}>
              <Typography className={classes.subtitles} variant="subtitle1">
                ₹{fair_price}
              </Typography>
            </Grid> */}
            {/* </Grid> */}
            {/* </Grid> */}
            {Object.values(allowedColumns).includes('Fair Price') ||
            Object.values(allowedColumns).length === 0 ? (
              <Grid item md={1}>
                <Typography className={classes.subtitles} variant="subtitle1">
                  {fair_price}
                </Typography>
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Interest Category') ? (
              <Grid container className={classes.categoryContainer}>
                <Grid item md={6}>
                  <CategoryContainer
                    categories={(Array.isArray(interests) ? interests : []).map(
                      (interest) => interest.name,
                    )}
                  />
                </Grid>
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Channel') ? (
              <Grid item md className={classes.moreWidth}>
                {platform ? (
                  <Grid item style={{ position: 'initial' }}>
                    <InstagramRectIconButton
                      onClick={() => {
                        window.open(url, '_blank');
                      }}
                      platform={this.props.platform}
                    />
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Language') ? (
              <Grid item md className={classes.moreWidth}>
                <Typography className={classes.subtitles} variant="subtitle1">
                  {language}
                </Typography>
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Audience Age') ? (
              <Grid item md className={classes.moreWidth}>
                <Typography className={classes.subtitles} variant="subtitle1">
                  {audience_age_split.find((o) => o.percentage) ? 
                  audience_age_split.find((o) => o.percentage).code 
                  : ""}

                </Typography>
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Influencer Location') ? (
              <Grid item md className={classes.moreWidth}>
                <Typography className={classes.subtitles} variant="subtitle1">
                  {location}
                </Typography>
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Interest') ? (
              <Grid item md className={classes.moreWidth}>
                {interests.slice(0, 3).map((interest) => (
                  <Grid container>
                    <Grid item>
                      <Typography className={classes.subtitlesInterest} variant="body2">
                        {interest.name}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Audience Location') ? (
              <Grid item md className={classes.moreWidth}>
                {'cities' in audience_location_split
                  ? audience_location_split.cities.slice(0, 3).map((o) => (
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <Typography className={classes.audienceLocation} variant="subtitle1">
                            {o.name}:{(o.weight * 100).toFixed(2)}%
                          </Typography>
                        </Grid>
                      </Grid>
                    ))
                  : ''}
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Influencer Gender') ? (
              <Grid item md className={classes.moreWidth}>
                <Typography className={classes.subtitles} variant="subtitle1">
                  {gender}
                </Typography>
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Influencer Age') ? (
              <Grid item md className={classes.moreWidth}>
                <Typography className={classes.subtitles} variant="subtitle1">
                  {age_group}
                </Typography>
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Country') ||
            Object.values(allowedColumns).length === 0 ? (
              <Grid item md className={classes.moreWidth}>
                <Typography
                  className={classes.subtitles}
                  style={{ lineHeight: '10.1px' }}
                  variant="subtitle1"
                >
                  {country}
                </Typography>
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Contact Number') ? (
              <Grid item md className={classes.moreWidth}>
                <Typography className={classes.subtitles} variant="subtitle1">
                  {contact_details}
                </Typography>
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Email') ? (
              <Grid item md className={classes.moreWidth}>
                {email_details.indexOf('custom') === -1 &&
                email_details.indexOf(contact_details) === -1 ? (
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.emailDetails} variant="subtitle1">
                        {email_details.split('@')[0]}
                      </Typography>
                      <Typography className={classes.emailDetails} variant="subtitle1">
                        @{email_details.split('@')[1]}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  'NA'
                )}
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Actual Price') ? (
              <Grid item md className={classes.moreWidth}>
                {!([null, undefined].includes(actual_price)) ? (
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.actualPrice} variant="subtitle1">
                        {actual_price['deliverables']}: ₹{actual_price['actual_price']}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Brand Final Price') ? (
              <Grid item md className={classes.moreWidth}>
                <Typography className={classes.subtitles} variant="subtitle1">
                  ₹{brand_final_price}
                </Typography>
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Influencer Final Price') ? (
              <Grid item md className={classes.moreWidth}>
                <Typography className={classes.subtitles} variant="subtitle1">
                  ₹{influencer_final_price}
                </Typography>
              </Grid>
            ) : (
              ''
            )}

            {Object.values(allowedColumns).includes('Deliverables') ? (
              <Grid item md className={classes.moreWidth}>
                {deliverables.slice(0, 5).map((deliverable) => (
                  <Grid container>
                    <Grid item>
                      <Typography className={classes.subtitlesInterest} variant="body2">
                        {deliverable.name}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ) : (
              ''
            )}
          </Grid>
          <Grid container className={classes.categoryContainer}>
            <Grid item md={6} style={{ maxWidth: '60%', flexBasis: '60%' }}>
              <CategoryContainer
                primary_category={
                  primary_category && primary_category.name !== '' ? primary_category.name : null
                }
                categories={Array.isArray(interest_categories) ? interest_categories : []}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item md={1}> */}
        {/*     <Grid container direction="column" spacing={3}> */}
        {/*         <Grid item> */}
        {/*             {selectedInfluencer.includes(influencer) ? */}
        {/*                 <Button */}
        {/*                     aria-label="add" */}
        {/*                     variant="contained" */}
        {/*                     color="primary" */}
        {/*                     className={classes.checkButton} */}
        {/*                     onClick={handleUnSelection} */}
        {/*                 > */}
        {/*                     <DoneIcon /> */}
        {/*                 </Button> : */}
        {/*                 <Button */}
        {/*                     aria-label="add" */}
        {/*                     color="primary" */}
        {/*                     variant="contained" */}
        {/*                     className={classes.addButton} */}
        {/*                     onClick={handleSelection} */}
        {/*                 > */}
        {/*                     <AddIcon /> */}
        {/*                 </Button> */}
        {/*             } */}
        {/*         </Grid> */}
        {/*         <Grid item> */}
        {/*             <Button variant="contained" color="secondary" onClick={() => setDetailViewOpened(true)}> */}
        {/*                 View Details */}
        {/*             </Button> */}
        {this.state.detailViewOpened ? (
          <DetailViewModal
            id={id}
            detailViewOpened={this.state.detailViewOpened}
            setDetailViewOpened={this.setDetailViewOpened}
            is_staff={this.props.isStaff}
          />
        ) : null}
        {/*         </Grid> */}
        {/*     </Grid> */}
        {/* </Grid> */}
      </Grid>
    );
  }
}

const styles = {
  root: {
    background: '#FFFFFF',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 224, 0.2), 5px -5px 10px rgba(215, 216, 224, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 224, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 224, 0.5)',
    borderRadius: 10,
    flexGrow: 1,
    height: 150,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    padding: 10,
  },
  cardBox: {
    flexGrow: 0,
    maxWidth: '20%',
    flexBasis: '20%',
  },
  statsContainer: {
    paddingRight: 45,
    marginBottom: 30,
  },
  categoryContainer: {
    // marginLeft: 100,
    color: '#4A4A4A',
  },
  addButton: {
    borderRadius: '50%',
    padding: 4,
    minWidth: 0,
  },
  checkButton: {
    borderRadius: '50%',
    padding: 4,
    minWidth: 0,
    background: '#82E900',
    '&.MuiButton-containedPrimary:hover': {
      background: '#179020',
    },
  },
  moreWidth: {
    maxWidth: '10.5%',
  },
  speed: {
    background: '#FFFFFF',
    height: 42,
    maxWidth: 64,
    textAlign: 'center',
    flex: 1,
    marginTop: 0,
    boxShadow: '4px 4px 4px rgba(206, 206, 206, 0.25)',
    color: '#6CBF02',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.6,
    borderRadius: 6,
  },
  price: {
    background: '#FFFFFF',
    height: 42,
    maxWidth: 64,
    boxShadow: '4px 4px 4px rgba(206, 206, 206, 0.25)',
    color: '#FEBD1C',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.6,
    borderRadius: 6,
  },
  impact: {
    background: '#FFFFFF',
    height: 42,
    maxWidth: 64,
    boxShadow: '4px 4px 4px rgba(206, 206, 206, 0.25)',
    color: '#FC2E2E',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.6,
    borderRadius: 6,
  },
  followers: {
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    color: '#4A4A4A',
    fontSize: 16,
  },
  subtitles: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    color: '#4A4A4A',
    fontSize: 12,
    marginRight: 0,
    lineHeight: '0.1px',
    width: 100,
  },
  subtitles2: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    color: '#4A4A4A',
    fontSize: 10,
  },
  subtitlesInterest: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    color: '#4A4A4A',
    fontSize: 12,
    marginRight: 0,
    lineHeight: '12px',
    width: 100,
  },
  actualPrice: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    color: '#4A4A4A',
    fontSize: 12,
    marginRight: 0,
    lineHeight: '12px',
    width: 100,
  },
  emailDetails: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    color: '#4A4A4A',
    fontSize: 12,
    marginRight: 0,
    lineHeight: '10px',
    width: 100,
  },
  audienceLocation: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    color: '#4A4A4A',
    fontSize: 12,
    marginRight: 0,
    lineHeight: '12px',
    width: 100,
  },
};

export default withStyles(styles)(InfluencerInfoCard);
