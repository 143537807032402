import { Avatar, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import * as React from 'react';
import TableContainer from '@mui/material/TableContainer';
import { getInstagramAdStats } from '../../../../api_handlers/reporting';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// const AdStats = (props) => {
//     function createData(name, calories, fat, carbs, protein) {
//         return { name, calories, fat, carbs, protein };
//     }

//     const rows = [
//         createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//         createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//         createData("Eclair", 262, 16.0, 24, 6.0),
//         createData("Cupcake", 305, 3.7, 67, 4.3),
//         createData("Gingerbread", 356, 16.0, 49, 3.9),
//     ];
class AdStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  async getData() {
    const resp = await getInstagramAdStats();
    this.setState({ data: resp, loading: false });
    console.log(this.state.data);
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 300);
  }

  render() {
    return (
      <Card
        style={{
          width: '100%',
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item md={12}>
              <Grid
                container
                style={{
                  fontSize: '15px',
                  paddingBottom: '10px',
                }}
              >
                <Grid item md={4}>
                  Organic Vs Paid
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="right">Organic</TableCell>
                      <TableCell align="right">BCA</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key="organic_impressions">
                      <TableCell component="th" scope="row">
                        Impressions
                      </TableCell>
                      <TableCell align="right">{this.state.data.organic_impressions}</TableCell>
                      <TableCell align="right">{this.state.data.bca_impressions}</TableCell>
                    </TableRow>
                    <TableRow key="cpm">
                      <TableCell component="th" scope="row">
                        CPM
                      </TableCell>
                      <TableCell align="right">{this.state.data.bca_cpm}</TableCell>
                      <TableCell align="right">{this.state.data.organic_cpm}</TableCell>
                    </TableRow>
                    <TableRow key="views">
                      <TableCell component="th" scope="row">
                        Views
                      </TableCell>
                      <TableCell align="right">{this.state.data.organic_reach}</TableCell>
                      <TableCell align="right">{this.state.data.bca_reach}</TableCell>
                    </TableRow>
                    <TableRow key="cpv">
                      <TableCell component="th" scope="row">
                        CPV
                      </TableCell>
                      <TableCell align="right">{this.state.data.bca_cpv}</TableCell>
                      <TableCell align="right">{this.state.data.organic_cpv}</TableCell>
                    </TableRow>
                    <TableRow key="conversions">
                      <TableCell component="th" scope="row">
                        Conversions
                      </TableCell>
                      <TableCell align="right">{this.state.data.organic_conversions}</TableCell>
                      <TableCell align="right">{this.state.data.bca_conversions}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
  };
};

export default connect(mapStateToProps)(withRouter(AdStats));
