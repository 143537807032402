import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { gridSpacing } from 'store/constant';

import SliderFilter from './SliderFilter'
import CreatorTypeSliderFilter from './CreatorTypeSliderFilter';
import { updateFilters } from 'actions/planner-filters';


const BudgetFilters = () => {
  const dispatch = useDispatch();

  const budget = useSelector((state) => state.plannerFilters.budget || 0);
  const maxPricePerCreator = useSelector((state) => state.plannerFilters.maxPricePerCreator || 0);
  const megaBudget = useSelector((state) => state.plannerFilters.megaBudget || 0);
  const macroBudget = useSelector((state) => state.plannerFilters.macroBudget || 0);
  const microBudget = useSelector((state) => state.plannerFilters.microBudget || 0);
  const nanoBudget = useSelector((state) => state.plannerFilters.nanoBudget || 0);

  const [megaLocked, setMegaLocked] = React.useState(false);
  const [macroLocked, setMacroLocked] = React.useState(false);
  const [microLocked, setMicroLocked] = React.useState(false);
  const [nanoLocked, setNanoLocked] = React.useState(false);

  const unlockedBudgetSlider = useMemo(() => {
    const unlockedEntities = []
    if(!megaLocked) unlockedEntities.push('megaBudget');
    if(!macroLocked) unlockedEntities.push('macroBudget');
    if(!microLocked) unlockedEntities.push('microBudget');
    if(!nanoLocked) unlockedEntities.push('nanoBudget');
    return unlockedEntities.sort((a, b) => {
      return eval(a) - eval(b)
    });
  }, [megaLocked, macroLocked, microLocked, nanoLocked, megaBudget, macroBudget, microBudget, nanoBudget]);

  const handleCreatorBudgetChange = (deltaBudget, payloadKey) => {
    if(eval(`${payloadKey.replace('Budget', '')}Locked`)) return;

    let slidersToUpdate = unlockedBudgetSlider.filter(entity => entity !== payloadKey);
    if(slidersToUpdate.length === 0) return;

    let changeInBudget = Math.abs(parseInt(deltaBudget / slidersToUpdate.length));
    let remainingBudget;

    // When slider is moved to the left
    if (deltaBudget < 0) {
      slidersToUpdate.forEach((entity, index) => {
        if (index !== slidersToUpdate.length - 1) {
          dispatch(updateFilters(entity, eval(entity) + changeInBudget), false)
        } else {
          dispatch(updateFilters(entity, eval(entity) + (Math.abs(deltaBudget) - (slidersToUpdate.length - 1) * changeInBudget)), false)
        }
      })
      remainingBudget = 0;
    } else {
      // When slider is moved to the right
      slidersToUpdate = slidersToUpdate.filter(entity => eval(entity) > 0);
      if(slidersToUpdate.length === 0) return;

      remainingBudget = Math.abs(deltaBudget);
      console.log("slidersToUpdate", slidersToUpdate)
      slidersToUpdate.forEach((entity, index) => {
        const budgetCanChange = Math.min(
          Math.floor(remainingBudget / (slidersToUpdate.length - index)),
          eval(entity)
        )
        dispatch(updateFilters(entity, eval(entity) - budgetCanChange), false)
        remainingBudget -= budgetCanChange;
      })
    }

    dispatch(updateFilters(payloadKey, eval(payloadKey) + deltaBudget - remainingBudget, false))
    
  };


  return (
    <Grid container spacing={gridSpacing} sx={{height: 300, overflowY: 'scroll', p: 2}}>
      <Grid item xs={12}>
        <SliderFilter value={budget} title={`Campaign Budget`} payloadKey='budget'
          min={10000} max={100000000} showTextField={true}
          onValueChange={value => {
            dispatch(updateFilters('megaBudget', 0, false))
            dispatch(updateFilters('macroBudget', value, false))
            dispatch(updateFilters('microBudget', 0, false))
            dispatch(updateFilters('nanoBudget', 0, false))
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SliderFilter value={maxPricePerCreator || budget * 0.1} title={`Max Price per Creator`}
          min={1000} max={budget} payloadKey={'maxPricePerCreator'}
          showTextField={true}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
          Creator Type Budget
        </Typography>
        <CreatorTypeSliderFilter title={`Mega ( > 1M)`} value={megaBudget} mainBudget={budget} payloadKey='megaBudget'
          locked={megaLocked} updateLock={setMegaLocked} onValueChange={value => {
            handleCreatorBudgetChange(value - megaBudget, 'megaBudget')
          }}
        />
        <CreatorTypeSliderFilter title={`Macro (100K - 1M)`} value={macroBudget} mainBudget={budget} payloadKey={'macroBudget'}
          locked={macroLocked} updateLock={setMacroLocked} onValueChange={value => {
            handleCreatorBudgetChange(value - macroBudget, 'macroBudget')
          }}
        />
        <CreatorTypeSliderFilter title={`Micro (10K > 100K)`} value={microBudget} mainBudget={budget}  payloadKey={'microBudget'}
          locked={microLocked} updateLock={setMicroLocked} onValueChange={value => {
            handleCreatorBudgetChange(value - microBudget, 'microBudget')
          }}
        />
        <CreatorTypeSliderFilter title={`Nano ( < 10K)`} value={nanoBudget} mainBudget={budget} payloadKey={'nanoBudget'}
          locked={nanoLocked} updateLock={setNanoLocked} onValueChange={value => {
            handleCreatorBudgetChange(value - nanoBudget, 'nanoBudget')
          }}
        />
      </Grid>
    </Grid>
  );
};

export default BudgetFilters;
