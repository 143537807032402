import React from 'react';
import { useEffect, useState, Component } from 'react';
import { connect } from 'react-redux';
import { Typography, Container, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { USE_PLANNER_ES } from 'config'

import Header from './components/Header';
import Filters from './components/Filters';
import ListHeader from './components/ListHeader';
import ActionFilter from './components/ActionFilter';
import Recommendation from './components/Recommendation';
import InfluencerInfoCard from './components/InfluencerInfoCard';
import { ThemeProvider, Chip } from '@material-ui/core';
import Loading from 'components/Common/Loading/Loading';
import { getPlannerInfluencers, getPlannerDownload } from 'api_handlers/discovery';
import { getPlannerInfluencersES } from 'api_handlers/es';
import themeV2 from 'themeV2';

import { getInfluencers } from 'actions/discovery';

let plannerFetchAPI = getPlannerInfluencers;
if (USE_PLANNER_ES) plannerFetchAPI = getPlannerInfluencersES

class Planner extends Component {
  // function Planner({ fetchInfluencers, prediction }) {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.setSelectedInfluencer = this.setSelectedInfluencer.bind(this);
    this.setSelectedPrediction = this.setSelectedPrediction.bind(this);
    this.checkEnd = this.checkEnd.bind(this);
    this.loadData = this.loadData.bind(this);
    this.fetchMoreData = this.fetchMoreData.bind(this);
    this.setSelectedObjective = this.setSelectedObjective.bind(this);
    this.setSelectedObjectiveType = this.setSelectedObjectiveType.bind(this);
    this.setSelectedFilter = this.setSelectedFilter.bind(this);
    this.setChannel = this.setChannel.bind(this);
    this.setShowModal = this.setShowModal.bind(this);
    this.setIsLoading = this.setIsLoading.bind(this);
    this.setShowLoading = this.setShowLoading.bind(this);
    this.setInfluencer = this.setInfluencer.bind(this);
    this.setInfluencersCount = this.setInfluencersCount.bind(this);
    this.setRecommendation = this.setRecommendation.bind(this);
    this.setImpactData = this.setImpactData.bind(this);
    this.setUserData = this.setUserData.bind(this);
    this.setPayload = this.setPayload.bind(this);
    this.setPage = this.setPage.bind(this);
    this.setlistSelect = this.setlistSelect.bind(this);
    this.setReset = this.setReset.bind(this);
    this.setSortBy = this.setSortBy.bind(this);
    this.setSortOrder = this.setSortOrder.bind(this);
    this.getPlannerInfluencersData = this.getPlannerInfluencersData.bind(this);
    this.allowedCardColumns = this.allowedCardColumns.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleBackToTopClick = this.handleBackToTopClick.bind(this);
    this.showColumnSelectModal = this.showColumnSelectModal.bind(this);
    this.setRecommendationCalculation = this.setRecommendationCalculation.bind(this);

    this.state = {
      selectedInfluencer: [],
      selectedPrediction: {
        reach: 0,
        views: 0,
        engagement_rate: 0,
        enagaged_users: 0,
        followers: 0,
        max_price: 0,
        mega: {
          count: 0,
          amount: 0,
        },
        macro: {
          count: 0,
          amount: 0,
        },
        micro: {
          count: 0,
          amount: 0,
        },
        nano: {
          count: 0,
          amount: 0,
        },
      },
      amount: 100000,
      selectedObjective: '',
      page: 1,
      selectedObjectiveType: '',
      selectedFilter: '',
      channel: [],
      sortBy: '',
      sortOrder: 'asc',
      listselect: false,
      reset: false,
      modal: false,
      isLoading: false,
      showLoading: false,
      influencers: [],
      influencersCount: 0,
      recommendation: [],
      impact_data: {},
      user: {},
      columns: [],
      default: true,
      checkLimit: 0,
      payload: {
        'campaign-level-requirements': {},
        'creator-level-requirements': {},
        'advance-filters': {},
        setReset: false,
      },
      isPlanActive: false,
      showOther: false,
      isVisible: false,
      isColumnSelected: false,
      isNotEnd:true
    };
  }

  scrollToElement = () => {
    this.scrollRef.current?.scrollIntoView();
  };

  allowedCardColumns(column, isDefault, removedItem, checkLimit) {
    if (!isDefault) {
      if (this.state.columns.length === 0) {
        this.setState({
          columns: Array.from(new Set(this.state.columns.concat(column))),
          default: false,
          checkLimit: checkLimit,
        });
      } else {
        this.state.columns = this.state.columns.filter((c) => !removedItem.includes(c));
        this.setState({
          columns: Array.from(new Set(this.state.columns.concat(column))),
          default: false,
          checkLimit: checkLimit,
        });
      }
    } else {
      this.setState({
        default: true,
        columns: [
          'Country',
          'Speed Score',
          'Average Views',
          'Price Score',
          'Fair Price',
          'Impact Score',
          'Followers',
          'Engagement Rate',
          'Audience Gender',
        ],
        checkLimit: checkLimit,
      });
    }
  }

  async getPlannerInfluencersData(payload) {
    if (this.state.isLoading) {
      return;
    }
    this.setState({ isPlanActive: true });
    try {
      let influencers = [];
      this.setPage(1);
      this.setIsLoading(true);
      this.setShowLoading(true);
      this.setPayload(payload);
      let resp = await plannerFetchAPI(payload, 1);
      let data = await resp.json();
      if (!data.payload) {
        this.setIsLoading(false);
        return console.log('payload not set');
      }
      this.setPayload(data.payload);
      if (data.payload && this.state.payload['setReset'] === true) {
        this.setInfluencer([...data.recommendation, ...data.results]);
        this.setRecommendation([]);
      } else {
        this.setInfluencer(data.results);
        this.setRecommendation(data.recommendation);
      }
      this.setInfluencersCount(data.count);
      this.setUserData(data.user);
      this.setImpactData(data.payload.impact_data);
      this.setPage(this.state.page + 1);
      this.setSelectedPrediction({
        reach: 0,
        views: 0,
        engagement_rate: 0,
        enagaged_users: 0,
        followers: 0,
        max_price: 0,
        mega: {
          count: 0,
          amount: 0,
        },
        macro: {
          count: 0,
          amount: 0,
        },
        micro: {
          count: 0,
          amount: 0,
        },
        nano: {
          count: 0,
          amount: 0,
        },
      });
      this.setSelectedInfluencer([]);
      this.setState({ showOther: false });
      this.setIsLoading(false);
      this.setShowLoading(false);
      this.setRecommendationCalculation();
      console.log('=== data.links.next fetchMoreData', data.links.next);
      if (data.links.next) {
        console.log('fetchMoreData')
        await this.fetchMoreData();
      } else {
        this.setState({ showOther: true });
      }
      // if (data.recommendation.length < 20) {
      //   this.setState({ showOther: true });
      // }
    } catch (e) {
      this.setIsLoading(false);
      this.setShowLoading(false);
    }
    // this.scrollToElement();
  }

  setPage(page) {
    this.setState({ page: page });
  }

  setlistSelect() {
    this.setState({ listSelect: !this.state.listSelect });
  }

  setReset(isReset) {
    if (isReset === true) {
      this.setState({
        reset: true,
      });
    } else {
      this.setState({ reset: false });
    }
  }

  setSortBy(sortBy) {
    this.setState({ sortBy: sortBy });
  }

  setSortOrder(sortOrder) {
    this.setState({ sortOrder: sortOrder });
  }

  setPayload(payload) {
    this.setState({ payload: payload });
  }

  setRecommendation(recommendation) {
    let recommendation_dict = {};
    let recommendation_list = [];
    recommendation.map((influencer) => {
      if (!recommendation_dict[influencer.id]) {
        recommendation_dict[influencer.id] = 1;
        recommendation_list.push(influencer);
      }
    });
    this.setState({ recommendation: recommendation_list });
  }

  setImpactData(impact_data) {
    this.setState({ impact_data: impact_data });
  }

  setUserData(user) {
    this.setState({ user: user });
  }

  setInfluencersCount(influencersCount) {
    this.setState({ influencersCount: influencersCount });
  }

  setInfluencer(influencers) {
    let influencers_dict = {};
    let influencers_list = [];
    influencers.map((influencer) => {
      if (!influencers_dict[influencer.id]) {
        influencers_dict[influencer.id] = 1;
        influencers_list.push(influencer);
      }
    });
    if (this.state.payload && this.state.payload['advance-filters'] && this.state.payload['advance-filters'].sortBy && this.state.payload['advance-filters'].sortOrder) {
      influencers_list = influencers_list.sort((a, b)=>{
        return b.followers_count - a.followers_count
      })
    }
    this.setState({ influencers: influencers_list });
  }

  setIsLoading(isLoading) {
    this.setState({ isLoading: isLoading });
  }

  setShowLoading(showLoading) {
    this.setState({ showLoading: showLoading });
  }

  setShowModal(modal) {
    this.setState({ modal: modal });
  }

  setChannel(channel) {
    this.setState({ channel: channel });
  }

  setSelectedFilter(selectedFilter) {
    this.setState({ selectedFilter: selectedFilter });
  }

  setSelectedObjectiveType(selectedObjectiveType) {
    this.setState({ selectedObjectiveType: selectedObjectiveType });
  }

  setSelectedObjective(selectedObjective) {
    this.setState({ selectedObjective: selectedObjective });
  }

  setSelectedInfluencer(selectedInfluencer) {
    this.setState({ selectedInfluencer: selectedInfluencer });
  }

  setSelectedPrediction(selectedPrediction) {
    this.setState({ selectedPrediction: selectedPrediction });
  }

  showColumnSelectModal(columnSelected) {
    this.setState({ isColumnSelected: columnSelected });
  }

  checkEnd() {
    console.log('scrolling');
    if (
      window.innerHeight + window.scrollY >=
      (document.body.offsetHeight * this.settings.scrollAfterPercentage) / 100
    ) {
      console.log('get next page');
    }
  }

  async loadData() {
    if (this.state.isLoading) {
      return;
    }
    try {
      this.setIsLoading(true);
      this.setShowLoading(true);
      let resp = await plannerFetchAPI(this.state.payload, 1);
      let data = await resp.json();
      if (!data.payload) {
        this.setIsLoading(false);
        return console.log('payload not set');
      }
      this.setPayload(data.payload);
      if (data.payload && this.state.payload['setReset'] === true) {
        this.setInfluencer([...data.recommendation, ...data.results]);
        this.setRecommendation([]);
      } else {
        this.setInfluencer(data.results);
        this.setRecommendation(data.recommendation);
      }
      this.setInfluencersCount(data.count);
      this.setImpactData(data.payload.impact_data);
      this.setUserData(data.user);
      this.setPage(this.state.page + 1);
      this.setIsLoading(false);
      this.setShowLoading(false);
      if (data?.recommendation.length < 20 || data?.recommendation.length <= 0) {
        this.setState({ showOther: true });
      }
    } catch (e) {
      this.setIsLoading(false);
      this.setShowLoading(false);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isColumnSelected !== this.state.isColumnSelected) {
      this.setState({ isColumnSelected: this.state.isColumnSelected });
    }
  }

  componentDidMount() {
    this.loadData();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window.pageYOffset > 200) {
      this.setState({ isVisible: true });
    } else {
      this.setState({ isVisible: false });
    }
  }

  handleBackToTopClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  async setRecommendationCalculation() {
    let influencers = this.state.selectedInfluencer;
    const current_prediction = this.state.selectedPrediction;
    this.state.recommendation.map((influencer) => {
      if (!this.state.selectedInfluencer.includes(influencer.id)) {
        influencers.push(influencer.id);
        if (influencer.platform === 'instagram') {
          if (influencer.prediction.followers < 10000) {
            current_prediction['reach'] =
              current_prediction['reach'] + influencer.prediction.followers * 0.6;
          }
          if (
            influencer.prediction.followers >= 10000 &&
            influencer.prediction.followers <= 100000
          ) {
            current_prediction['reach'] =
              current_prediction['reach'] + influencer.prediction.followers * 0.5;
          }
          if (
            influencer.prediction.followers > 100000 &&
            influencer.prediction.followers <= 500000
          ) {
            current_prediction['reach'] =
              current_prediction['reach'] + influencer.prediction.followers * 0.4;
          }
          if (influencer.prediction.followers > 500000) {
            current_prediction['reach'] =
              current_prediction['reach'] + influencer.prediction.followers * 0.3;
          }
        } else {
          current_prediction['reach'] =
            current_prediction['reach'] + influencer.prediction.views * 1.37;
        }
        current_prediction['views'] =
          current_prediction['views'] + influencer.prediction.views;
        current_prediction['enagaged_users'] =
          current_prediction['enagaged_users'] + influencer.prediction.enagaged_users;
        current_prediction['followers'] =
          current_prediction['followers'] + influencer.prediction.followers;
        current_prediction['engagement_rate'] =
          current_prediction['engagement_rate'] + influencer.prediction.engagement_rate;
        current_prediction['max_price'] =
          current_prediction['max_price'] + influencer.prediction.max_price;
        if (influencer.prediction.creator_type == 'micro') {
          current_prediction['micro'] = {
            count: current_prediction['micro']['count'] + 1,
            amount: current_prediction['micro']['amount'] + influencer.prediction.max_price,
          };
        }
        if (influencer.prediction.creator_type == 'mega') {
          current_prediction['mega'] = {
            count: current_prediction['mega']['count'] + 1,
            amount: current_prediction['mega']['amount'] + influencer.prediction.max_price,
          };
        }
        if (influencer.prediction.creator_type == 'nano') {
          current_prediction['nano'] = {
            count: current_prediction['nano']['count'] + 1,
            amount: current_prediction['nano']['amount'] + influencer.prediction.max_price,
          };
        }
        if (influencer.prediction.creator_type == 'macro') {
          current_prediction['macro'] = {
            count: current_prediction['macro']['count'] + 1,
            amount: current_prediction['macro']['amount'] + influencer.prediction.max_price,
          };
        }
      }
    });
    this.setSelectedInfluencer(influencers);
    this.setSelectedPrediction(current_prediction);
  }

  async fetchMoreData() {
    console.log('Fetch more data called', this.state.isLoading);
    if (this.state.isLoading) {
      return;
    }
    console.log(
      'this.state.page * 10 > this.state.influencersCount',
      this.state.page * 10,
      this.state.influencersCount,
    );
    if (this.state.page * 10 > this.state.influencersCount) {
      return;
    }
    try {
      this.setIsLoading(true);
      console.log('API called');
      let resp = await plannerFetchAPI(this.state.payload, this.state.page);
      let data = await resp.json();
     

      console.log('response got');
      if (!data.payload) {
        this.setIsLoading(false);
        return console.log('payload not set');
      }
      this.setPayload(data.payload);
      if (data.payload && this.state.payload['setReset'] === true) {
        this.setInfluencer([...this.state.influencers, ...data.recommendation, ...data.results]);
        this.setInfluencersCount(this.state.influencersCount);
        this.setRecommendation([]);
      } else {
        this.setInfluencer([...this.state.influencers, ...data.results]);
        this.setInfluencersCount(data.count);
        this.setRecommendation([...this.state.recommendation, ...data.recommendation]);
      }
      this.setImpactData(data.payload.impact_data);
      if(data.links.next!==null){
        this.setPage(this.state.page + 1);
        }else{
          this.setState({isNotEnd:false})
          }
      this.setIsLoading(false);
      
      console.log('state set', data && data.recommendation && data.recommendation.length > 0);
      if (data && data.recommendation && data.recommendation.length > 0) {
        console.log('!this.state.showOther', !this.state.showOther);
        // if (!this.state.showOther && this.state.page <= 10) {
        if (!this.state.showOther) {
          this.setRecommendationCalculation();
          if (data.links.next) {
            setTimeout(async ()=>{
              await this.fetchMoreData();
            }, 2000);
          } else {
            this.setState({ showOther: true });
          }
          // if (data.recommendation.length < 20) {
          //   this.setState({ showOther: true });
          // }
        }
      } else {
        this.setState({ showOther: true });
      }
     
    } catch (e) {
      console.log(e);
      this.setIsLoading(false);
    }
  }

  render() {
    let { classes } = this.props;

    let {
      selectedObjective,
      selectedFilter,
      channel,
      selectedInfluencer,
      selectedPrediction,
      selectedObjectiveType,
      amount,
      influencersCount,
      recommendation,
      influencers,
      modal,
      listSelect,
      sortBy,
      sortOrder,
      isPlanActive,
      showOther,
      user,
      payload,
      isColumnSelected,
    } = this.state;

    return (
      <ThemeProvider theme={themeV2}>
        {this.state.showLoading && <Loading />}
        <Container fixed className={classes.root}>
          <Grid container>
            <Grid item md={12}>
              <Header />
            </Grid>
            <Grid item md={12}>
              <Filters
                selectedObjective={selectedObjective}
                setSelectedObjectiveType={this.setSelectedObjectiveType}
                setSelectedObjective={this.setSelectedObjective}
                selectedFilter={selectedFilter}
                setSelectedFilter={this.setSelectedFilter}
                setReset={this.setReset}
                channel={channel}
                setChannel={this.setChannel}
                setShowModal={this.setShowModal}
                getPlannerInfluencersData={this.getPlannerInfluencersData}
                isStaff={user.is_staff}
                isLoading={this.state.isLoading}
              />
            </Grid>
            <Grid item md={12} style={{ zIndex: isColumnSelected ? 4 : 1 }}>
              <ActionFilter
                scrollToElement={this.scrollToElement}
                selectedFilter={selectedFilter}
                setSelectedFilter={this.setSelectedFilter}
                selectedPrediction={selectedPrediction}
                selectedInfluencer={selectedInfluencer}
                setSelectedInfluencer={this.setSelectedInfluencer}
                payload={this.state.payload}
                selectedObjectiveType={selectedObjectiveType}
                impact_data={this.state.impact_data}
                influencers={this.state.influencers}
                recommendation={this.state.recommendation}
                getPlannerInfluencersData={this.getPlannerInfluencersData}
                setPayload={this.setPayload}
                isStaff={user.is_staff}
                selectedColumns={this.state.columns}
                showColumnSelectModal={this.showColumnSelectModal}
              />
            </Grid>
            {selectedObjectiveType && (
              <Grid item md={12}>
                <Recommendation
                  selectedPrediction={selectedPrediction}
                  setSelectedPrediction={this.setSelectedPrediction}
                  selectedObjectiveType={selectedObjectiveType}
                  reset={this.state.reset}
                  amount={amount}
                  showOther={showOther}
                />
              </Grid>
            )}
            <div
              item
              md={12}
              ref={this.scrollRef}
              style={{
                position: 'sticky',
                top: 1,
                width: '100%',
                zIndex: isColumnSelected ? 1 : 4,
              }}
            >
              <ListHeader
                count={influencersCount}
                sortBy={sortBy}
                setSortBy={this.setSortBy}
                sortOrder={sortOrder}
                setSortOrder={this.setSortOrder}
                getPlannerInfluencersData={this.getPlannerInfluencersData}
                listSelect={listSelect}
                setlistSelect={this.setlistSelect}
                // setReset={this.setReset}
                influencers={influencers}
                selectedInfluencer={selectedInfluencer}
                setSelectedInfluencer={this.setSelectedInfluencer}
                recommendation_count={recommendation.length ? recommendation.length : 0}
                recommendation={recommendation}
                setRecommendation={this.setRecommendation}
                selectedPrediction={selectedPrediction}
                setSelectedPrediction={this.setSelectedPrediction}
                onSelectColumns={this.allowedCardColumns}
                columns={this.state.columns}
                payload={this.state.payload}
                isPlanActive={this.state.isPlanActive}
                isStaff={user.is_staff}
              />
            </div>
            {isPlanActive && payload['setReset'] === false ? (
              <Grid item md={4} className={classes.option}>
                <Typography
                  style={{
                    fontSize: 15,
                    fontWeight: 700,
                    color: '#4A4A4A',
                    textAlign: 'left',
                    marginRight: 5,
                  }}
                >
                  Top Recommendations
                </Typography>
                <Chip
                  size="small"
                  label={`(${recommendation.length})`}
                  style={{
                    color: '#757575',
                    backgroundColor: 'white',
                    fontSize: '14px',
                  }}
                />
              </Grid>
            ) : null}
            {payload['setReset'] === false ? (
              <Grid item md={12}>
                <Grid container>
                  <InfiniteScroll
                    dataLength={recommendation.length}
                    next={this.fetchMoreData}
                    hasMore={!showOther && this.state.isNotEnd}
                    loader={<h4>Loading...</h4>}
                    style={{ padding: 20 }}
                    endMessage={
                      isPlanActive ? (
                        <p style={{ textAlign: 'center' }}>
                          <b>No More Recommended Profiles Found.</b>
                        </p>
                      ) : (
                        ''
                      )
                    }
                  >
                    {recommendation.map((recommend, index) => (
                      <Grid item md={12} key={index}>
                        <InfluencerInfoCard
                          {...recommend}
                          selectedInfluencer={selectedInfluencer}
                          setSelectedInfluencer={this.setSelectedInfluencer}
                          selectedPrediction={selectedPrediction}
                          setSelectedPrediction={this.setSelectedPrediction}
                          allowedColumns={this.state.columns}
                          isStaff={user.is_staff}
                          checkLimit={this.state.checkLimit}
                        />
                      </Grid>
                    ))}
                  </InfiniteScroll>
                </Grid>
              </Grid>
            ) : null}
            {isPlanActive && showOther && this.state.payload['setReset'] === false ? (
              <Grid item md={2} style={{ padding: 10 }}>
                <Typography
                  style={{
                    fontSize: 15,
                    fontWeight: 700,
                    color: '#4A4A4A',
                    textAlign: 'left',
                  }}
                >
                  Other Influencers
                </Typography>
              </Grid>
            ) : null}
            {showOther ? (
              <Grid item md={12}>
                <Grid container spacing={4}>
                  <InfiniteScroll
                    dataLength={influencers.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.isNotEnd}
                    loader={<h4>Loading...</h4>}
                    style={{ padding: 20 }}
                    endMessage={
                      <p style={{ textAlign: 'center' }}>
                        <b>Try changing filters to get more influencers.</b>
                      </p>
                    }
                  >
                    {influencers.map((influencer, index) => (
                      <Grid item md={12} key={index}>
                        <InfluencerInfoCard
                          {...influencer}
                          selectedInfluencer={selectedInfluencer}
                          setSelectedInfluencer={this.setSelectedInfluencer}
                          selectedPrediction={selectedPrediction}
                          setSelectedPrediction={this.setSelectedPrediction}
                          allowedColumns={this.state.columns}
                          isStaff={user.is_staff}
                          checkLimit={this.state.checkLimit}
                        />
                      </Grid>
                    ))}
                  </InfiniteScroll>
                </Grid>
              </Grid>
            ) : null}
            <button
              className={classes.backToTop}
              style={{
                display: this.state.isVisible ? 'inline-block' : 'none',
              }}
              onClick={this.handleBackToTopClick}
            >
              <KeyboardArrowUpIcon style={{ fontSize: '1rem' }} />
            </button>
          </Grid>
          {selectedObjective != '' || modal ? (
            <div
              className={classes.modalBackground}
              onClick={() => {
                this.setSelectedObjective('');
              }}
            ></div>
          ) : null}
          {/* { */}
          {/*     (listSelect) ? <div className={classes.modalBackground2} onClick={()=>{this.setlistSelect(false)}}></div> : null  */}
          {/* } */}
        </Container>
      </ThemeProvider>
    );
  }
}

const styles = {
  root: {
    background: '#FFFFFF',
    paddingTop: 20,
    width: '100%',
    maxWidth: '100%',
    height: '100vh',
  },
  modalBackground: {
    background: '#000000',
    opacity: 0.5,
    zIndex: 4,
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  option: {
    // background: '#F1F1F1',
    borderRadius: '10px',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    margin: 5,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  backToTop: {
    position: 'fixed',
    bottom: '20px',
    right: '15px',
    zIndex: 9999,
    fontSize: '16px',
    border: 'none',
    outline: 'none',
    borderRadius: '50%',
    backgroundColor: '#c2e0ff',
    color: '#004C99',
    cursor: 'pointer',
    padding: '10px',
    height: '40px',
    width: '40px',
  },
};

const mapStateToProps = (state) => {
  return {
    prediction: state.discovery.prediction,
  };
};

const actionCreators = {
  fetchInfluencers: getInfluencers,
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(Planner));

// export default Discovery
