import { useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/Theme/Elements/Button';

import PriceIcon from 'assets/discovery/Price-icon.png';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';

import { addInfluencerToCampaign } from 'api_handlers/campaign';

function CampaignCard({ id, name, desc, start_date, budget, selectedInfluencer }) {
  const classes = useStyles();
  const [influencerAdded, setInfluencerAdded] = useState(false);

  function addInfluencerHandler(e) {
    e.preventDefault();
    addInfluencerToCampaign(id, { influencers: selectedInfluencer }).then(() =>
      setInfluencerAdded(true),
    );
  }

  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item md={'auto'}>
            <Typography variant="h3">{name}</Typography>
          </Grid>
          <Grid item md={'auto'}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item md={'auto'}>
                <Typography variant="body1" className={classes.startDateLabel}>
                  Start Date:
                </Typography>
              </Grid>
              <Grid item md={'auto'}>
                <Typography variant="body1">{start_date}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.descContainer}>
          <Grid item md={12}>
            <Typography variant="body1">{desc}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item md={'auto'} className={classes.badge}>
            <Typography variant="body1" style={{ color: 'white' }}>
              Influencer Micro | Small
            </Typography>
          </Grid>
          <Grid item md={'auto'}>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item>
                <span>
                  <img src={PriceIcon} alt="icon" className={classes.budgetIcon} />
                </span>
              </Grid>
              <Grid item className={classes.budgetLabel}>
                {budget}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={'auto'}>
            {influencerAdded ? (
              <Button
                aria-label="add"
                color="primary"
                variant="contained"
                className={classes.checkButton}
              >
                <DoneIcon />
              </Button>
            ) : (
              <Button
                aria-label="add"
                color="primary"
                variant="contained"
                className={classes.addButton}
                onClick={addInfluencerHandler}
              >
                <AddIcon />
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    height: 150,
    background: '#F3F4FE',
    boxShadow:
      '-5px 5px 10px rgba(219, 220, 229, 0.2), 5px -5px 10px rgba(219, 220, 229, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(219, 220, 229, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(219, 220, 229, 0.5)',
    borderRadius: 20,
    padding: '10px 30px',
  },
  descContainer: {
    marginTop: 10,
    height: 50,
  },
  badge: {
    background: 'linear-gradient(180deg, #C6BFEE 0%, rgba(148, 129, 239, 0.71) 100%)',
    borderRadius: 5,
    color: 'white',
    padding: 7,
  },
  budgetIcon: {
    height: 25,
    width: 25,
  },
  budgetLabel: {
    background: 'linear-gradient(135deg, #E3E4ED 0%, #FBFCFF 100%)',
    border: '1px solid #FD6A00',
    boxShadow:
      '-1px 1px 2px rgba(215, 216, 221, 0.2), 1px -1px 2px rgba(215, 216, 221, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(215, 216, 221, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 221, 0.5)',
    borderRadius: 5,
    padding: 4,
  },
  addButton: {
    borderRadius: '50%',
    padding: 4,
    minWidth: 0,
  },
  startDateLabel: {
    fontWeight: 600,
    marginRight: 5,
  },
  checkButton: {
    borderRadius: '50%',
    padding: 4,
    minWidth: 0,
    background: '#82E900',
    '&.MuiButton-containedPrimary:hover': {
      background: '#179020',
    },
  },
}));

export default CampaignCard;
