import * as React from 'react';
import { Grid, Typography, Radio, RadioGroup } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ObjectiveOption from './ObjectiveOption';

class Objective extends React.Component {
  render() {
    let { classes, objective, selected, setSelectedObjective, setFilters } = this.props;

    return (
      <div
        onClick={() => {
          setSelectedObjective('Objective');
        }}
        style={{ position: 'relative' }}
      >
        <ObjectiveOption {...(selected ? { selected: true } : null)}>Objective</ObjectiveOption>
        {selected ? (
          <div className={classes.modal}>
            <Typography className={classes.heading}>Branding Campaign</Typography>
            <RadioGroup
              value={objective}
              onChange={(event) => {
                this.props.setFilters('objective', event.target.value);
              }}
            >
              <div>
                <div className={classes.option}>
                  <Radio color="primary" value={'Reach'} />
                  <Typography className={classes.optionLabel}>Reach</Typography>
                </div>
                <div className={classes.option}>
                  <Radio color="primary" value={'CPV'} />
                  <Typography className={classes.optionLabel}>CPV</Typography>
                </div>
                <div className={classes.option}>
                  <Radio color="primary" value={'Engagement Rate'} />
                  <Typography className={classes.optionLabel}>Engagement Rate</Typography>
                </div>
              </div>
            </RadioGroup>
          </div>
        ) : null}
      </div>
    );
  }
}

const styles = {
  modal: {
    background: '#ffffff',
    position: 'absolute',
    width: 300,
    top: 80,
    padding: 20,
    boxShadow: '5px 4px 20px rgba(104, 104, 104, 0.25)',
    borderRadius: '30px',
    zIndex: 5,
  },
  heading: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 16,
  },
  option: {
    background: '#F1F1F1',
    borderRadius: '10px',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    margin: 5,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  optionLabel: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 16,
  },
};

export default withStyles(styles)(Objective);
