import { useState, useEffect } from 'react';
import { Typography, Grid, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import InstagramIconButton from 'components/Discovery/components/InstagramIconButton';
import YouTubeIconButton from 'components/Discovery/components/YouTubeIconButton';

import BasicInfo from './components/BasicInfo';
import BasicStats from './components/BasicStats';
import InfluencerDetails from './components/InfluencerDetails';
import AudienceDetails from './components/AudienceDetails';
import PopularPosts from './components/PopularPosts';

import { getDiscoveryInfluencersDetails } from 'api_handlers/discovery';

function DetailViewModal({ id, detailViewOpened = false, setDetailViewOpened }) {
  const classes = useStyles();
  const [influenerDetail, setInfluenerDetail] = useState({});

  useEffect(() => {
    getDiscoveryInfluencersDetails(id).then((response) => {
      setInfluenerDetail(response);
    });
  }, []);

  return (
    <Modal
      open={detailViewOpened}
      onClose={() => setDetailViewOpened(false)}
      className={classes.base}
    >
      <Grid container className={classes.root}>
        <Grid item md={12}>
          <Grid container className={classes.header}>
            <Grid item md={3}>
              <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                  <InstagramIconButton selected />
                </Grid>
                <Grid item>
                  <YouTubeIconButton />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              className={classes.closeContainer}
              onClick={() => setDetailViewOpened(false)}
            >
              <Grid container>
                <Grid item>
                  <Typography variant="h4">Close</Typography>
                </Grid>
                <Grid item>
                  <HighlightOffIcon className={classes.closeIcon} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.body} spacing={1}>
            <Grid item md={12}>
              <Grid container spacing={4}>
                <Grid item md={12}>
                  <BasicInfo {...influenerDetail} />
                </Grid>
                <Grid item md={12}>
                  <BasicStats {...influenerDetail} />
                </Grid>
                <Grid item md={12}>
                  <InfluencerDetails {...influenerDetail} />
                </Grid>
                <Grid item md={12}>
                  <AudienceDetails {...influenerDetail} />
                </Grid>
                <Grid item md={12}>
                  <PopularPosts {...influenerDetail} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

const useStyles = makeStyles(() => ({
  base: {
    background: 'white',
    height: 700,
    width: 1200,
    margin: 'auto',
    marginTop: 50,
  },
  root: {
    background: '#F3F4FE',
    filter: 'drop-shadow(5px 8px 15px rgba(171, 171, 171, 0.25))',
    height: 700,
    width: 1200,
    borderRadius: 10,
    padding: 40,
    overflowY: 'scroll',
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 40,
  },

  closeContainer: {
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.9) 0%, #FBFCFF 100%)',
    boxShadow:
      '-1px 1px 2px rgba(215, 216, 220, 0.2), 1px -1px 2px rgba(215, 216, 220, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: 7,
    height: 30,
    cursor: 'pointer',
  },
  closeIcon: {
    fontSize: 14,
    color: '#757575',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 6,
  },
}));

export default DetailViewModal;
