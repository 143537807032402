import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { Paper } from '@mui/material';
import Avatar from 'ui-component/extended/Avatar';
import CircularProgress from '@mui/material/CircularProgress';

import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Chip from 'ui-component/extended/Chip';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import CloseIcon from '@mui/icons-material/Close';
// import { getDiscoverySearchResult } from 'api_handlers/es'
import { getDiscoverySearchResultv2 } from 'api_handlers/es'
import { fetchNewInfluencer } from 'api_handlers/planner2'
import { getFieldOfInterest } from 'api_handlers/influencer';
import { addNewInfluencer, updateNewInfluencerSearch, setAutoCompleteValues } from 'actions/planner2'
import {isValidURL} from './../../utils';

import mixpanel from 'mixpanel-browser';

const SearchAutocomplete = () => {
  const dispatch = useDispatch();
  const autoCompleteValues = useSelector((state) => state.planner2.autoCompleteValues);
  const platform = useSelector((state) => state.plannerFilters.platform);
  const CancelToken = axios.CancelToken;
  const [autoCompleteRequest, setAutoCompleteRequest] = useState(null);
  const [searchAutocompleteOptions, setSearchAutocompleteOptions] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [autocompleteTimeout, setAutocompleteTimeout] = useState(null);
  const [categoryGroup, setCategoryGroup] = useState([]);

  const [newProfileSnackbarOpen, setNewProfileSnackbarOpen] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getFieldOfInterest();
        setCategoryGroup([
          ...data.map((option) => ({
            group: 'Categories',
            firstLetter: option.name[0].toUpperCase(),
            id: option.name,
            type: 'Categories',
            ...option,
          })),
        ]);
      } catch (error) {
        console.error('Error fetching field of interest:', error);
      }
    };
    fetchData();
  }, []);


  const addInfluencerOptions = async (searchText) => {
    const source = CancelToken.source();
    setAutoCompleteRequest(source);
    const { result } = await getDiscoverySearchResultv2(searchText || 'india', source, platform || 'instagram');
    // const { result } = await getDiscoverySearchResult(searchText || 'india', source);

    let influencerOptions = [];

    if (result && result.length > 0) {
      result.forEach((option) =>
        influencerOptions.push({
          firstLetter: 'I',
          name: `Influencer=${searchText}`,
          display_name: `${option.name}`,
          id: option.id,
          src: option.picture,
          group: 'Influencers',
          type: 'influencers-found',
        }),
      );
    } else {
      const validatedURL = isValidURL(searchText, platform);
      influencerOptions.push({
        firstLetter: 'I',
        name: `${searchText}`,
        display_name: validatedURL === false ? `Invalid URL for platform ${platform}` : `${searchText}`,
        id: validatedURL === false ? `Invalid URL for platform ${platform}` : validatedURL,
        type: 'influencer-not-found',
        group: 'Influencers',
      });
    }

    setSearchAutocompleteOptions((prevValue) => [
      ...prevValue.filter((option) => option.type !== 'influencer-pending'),
      ...influencerOptions
  ]);
  };

  const getCategoryOptions = (searchText, selectedIds) => {
    let categories = [];
    if (!searchText) {
      categories = categoryGroup.filter((option) => !selectedIds.includes(option.id)).slice(0, 12);
    } else {
      categories = categoryGroup.filter(
        (option) =>
          !selectedIds.includes(option.id) && option.name.toLowerCase().includes(searchText.toLowerCase()),
      );
    }
    return categories;
  };

  const getExtraOptions = (searchText) => {
    if (!searchText) return [];
    const postKey = 'PostSearch';
    const bioKey = 'BioSearch';
    const extraOption = [];

    let isUrl = false;
    try {
      const newUrl = new URL(searchText);
      isUrl = true;
    } catch (e) {
      isUrl = searchText.startsWith("www.");
    }

    if (!isUrl) {
      if (!autoCompleteValues.find((option) => option.type === postKey))
        extraOption.push({
          firstLetter: 'P',
          name: `${postKey}=${searchText}`,
          display_name: `'${searchText}' post search`,
          id: `${postKey}=${searchText}`,
          type: postKey,
          value: searchText,
        });
    if (!autoCompleteValues.find((option) => option.type === bioKey))
      extraOption.push({
        firstLetter: 'P',
        name: `${bioKey}=${searchText}`,
        display_name: `'${searchText}' bio search`,
        id: `${bioKey}=${searchText}`,
        type: bioKey,
        value: searchText,
      });
    }
    
    if(searchText.length < 3) return extraOption;

    [1, 2, 3].forEach(() => {extraOption.push({
        firstLetter: 'P',
        name: `${searchText}`,
        display_name: `Search influencer have '${searchText}' in their posts`,
        id: `None`,
        type: 'influencer-pending',
        value: searchText,
        group: 'Influencers',
      })
    });
    return extraOption;
  };

  const handleFilterOptions = async (event, inputValue) => {
    if (autoCompleteRequest) autoCompleteRequest.cancel();
    setAutocompleteLoading(true);
    const selectedIds = autoCompleteValues.map((option) => option.id);

    if (inputValue && inputValue.length > 2) {
      setAutocompleteTimeout(
        setTimeout(() => {
          addInfluencerOptions(inputValue);
        }, 500),
      );
    }

    setSearchAutocompleteOptions([
      ...getCategoryOptions(inputValue, selectedIds),
      ...getExtraOptions(inputValue),
    ]);
    setAutocompleteLoading(false);
  };

  const renderOptionGroups = (params) => {
    let parentElement;

    if (params.group === 'Categories') {
      parentElement = <Grid container spacing={1} sx={{
        pl: 2,
        '& .MuiAutocomplete-option': { padding: '8px'}}
      }>{params.children}</Grid>;
    } else {
      parentElement = <ul style={{padding: 0}}>{params.children}</ul>;
    }

    return (
      <React.Fragment key={params.key}>
        <h4 style={{marginLeft: 10}}>{params.group}</h4>
        {parentElement}
      </React.Fragment>
    );
  };

  const renderOption = (props, option) => {
    let element;
    let size = 12;

    if (['Categories'].includes(option.group)) size = 'auto';

    if (option.type === 'influencers-found') {
      element = (
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item>
            <Avatar src={option.src} />
          </Grid>
          <Grid item>{option.display_name}</Grid>
        </Grid>
      );
    } else if (option.type === 'influencer-not-found') {
      element = (
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item>
            <Avatar>{option.name[0].toUpperCase()}</Avatar>
          </Grid>
          <Grid item>{option.display_name}</Grid>
        </Grid>
      );
    } else if (option.type === 'PostSearch') {
      element = <span style={{padding: '8px 0px'}}>Search influencer have <i>{option.value}</i> in their posts</span>;
    } else if (option.type === 'BioSearch') {
      element = <span style={{padding: '8px 0px'}}>Search influencer have <i>{option.value}</i> in their bio</span>;
    } else if (option.type === 'Categories') {
      element = <Chip variant="contained" sx={{color: "secondary.main"}} label={option.name} />;
    } else if (option.type === 'influencer-pending') {
      element = (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Skeleton variant="circular" width={40} height={40} />
          <Stack>
              <Skeleton variant="text" width={110} height={30}/>
          </Stack>
        </Stack>
      );
    }

    return (
      <Grid item sm={size} {...props} style={{ fontSize: 15 }} key={option.id}>
        {element}
      </Grid>
    );
  };

  const fetchInfluencer = async (value) => {
    setNewProfileSnackbarOpen(true)
    try {

      const {data} = await fetchNewInfluencer({
        payload: {
          link: value,
        },
      });
      setNewProfileSnackbarOpen(false);
      if(data.status === "success"){
        const updatedData = data.data
        updatedData.primary_category = {name: updatedData.primary_category}
        updatedData.prediction = {
          enagaged_users_display: updatedData.avg_comments + updatedData.avg_likes,
        }
        updatedData.city = 'Other'
        if (updatedData.platform === 'instagram' || !updatedData.url) {
          updatedData.url = data.link
        }
        
        dispatch(addNewInfluencer(data));
      }
    } catch(error){
      setErrorSnackbar(true)
      setTimeout(() => {
        setNewProfileSnackbarOpen(false);
        setErrorSnackbar(false);
      }, 10000);

    }
  };

  useEffect(() => {
    const notFoundOptions = autoCompleteValues.filter((option) => option.type === 'influencer-not-found')
    notFoundOptions.forEach((notFoundOption) => {
      fetchInfluencer(notFoundOption.id);
    })
    dispatch(updateNewInfluencerSearch(notFoundOptions.map(option => option.id)));

  }, [autoCompleteValues]);

  return (
    <>
      <Autocomplete
        
        multiple
        id="grouped-demo"
        options={searchAutocompleteOptions}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            label="Search by link, keywords, username, influencer name and categories"
            InputProps={{
              ...params.InputProps,
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.stopPropagation();
                }
              },
              endAdornment: (
                <React.Fragment>
                  {autocompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            fullWidth
          />
        )}
        freeSolo={true}
        renderOption={renderOption}
        renderGroup={renderOptionGroups}
        PaperComponent={(props) => <Paper {...props} />}
        onInputChange={(event, inputValue) => {
          if (autocompleteTimeout) clearTimeout(autocompleteTimeout);
          handleFilterOptions(event, inputValue);
        }}
        loading={autocompleteLoading}
        getOptionDisabled={(option) => option.type === 'influencer-pending' || (option.display_name && option.display_name.indexOf("Invalid URL for platform ") > -1)}
        onChange={(event, value) => {
          let name = [];
          try {
            value.map(each=>{
              name.push(each.name);
            })
          } catch {

          }
          if (process.env.NODE_ENV === "production") {
            mixpanel.track('search', {
              'value': name.join(',')
            })
          }
          dispatch(setAutoCompleteValues(value));
        }}
        sx={{
          '& fieldset': {
            borderLeft: 'none',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
          }
        }}
      />
      <Snackbar
        open={newProfileSnackbarOpen}
        // autoHideDuration={5000}
        onClose={() => (event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setNewProfileSnackbarOpen(false);
        }}
      >
        <Box 
          sx={{ 
            p: 2, 
            bgcolor: errorSnackbar ? '#ea3a72' : '#111936', 
            borderRadius: 3, 
            width: 600 
          }}
        >
          <Grid container spacing={1}>
            <Grid item sm={11}>
              <Typography variant={'h3'} sx={{ color: '#ffffff' }}>
              {errorSnackbar ?"Something Went Wrong!" : "We are updating data for this account..."}
              </Typography>
            </Grid>
            <Grid item sm={1}>
              <CloseIcon sx={{
                color: '#ffffff',
                position: 'relative',
                top: '-12px',
                right: '-20px',
                cursor: 'pointer'
              }} onClick={() => {setNewProfileSnackbarOpen(false); setErrorSnackbar(false)}} />
            </Grid>
            <Grid item sm={12}>
              <Typography variant={'body1'} sx={{ color: '#ffffff', position: 'relative', left: '-20px' }}>
                {errorSnackbar?"Please try again after some time." :"It may take upto a minute to update the data for this account."}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Snackbar>
    </>
  );
};

export default SearchAutocomplete;
