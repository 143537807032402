import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Select from 'components/Theme/Elements/Select';

import Filter from '../Filter';

import LocationIcon from 'assets/discovery/Location-Icon.png';
import MultiSelect from 'components/Theme/Elements/MultiSelect';
import { getDiscoveryLanguages } from 'api_handlers/discovery';

function LanguageFilter({ value, setFilters }) {
  const classes = useStyles();
  const [languages, setLanguages] = useState([]);

  function handleFilter(event, selectedValues) {
    setFilters({
      language: selectedValues,
    });
  }

  useEffect(() => {
    getDiscoveryLanguages().then((response) => {
      setLanguages(response.data);
    });
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Filter label={'Influencer Language'} labelIconSrc={LocationIcon}>
          <Grid container className={classes.filterContainer}>
            <MultiSelect
              options={languages
                .filter((language) => language)
                .map((language) => ({ label: language, value: language }))}
              value={value || []}
              onChange={handleFilter}
            />
          </Grid>
        </Filter>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {},
  filterContainer: {},
}));

export default LanguageFilter;
