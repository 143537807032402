import React, { Component } from 'react';
import {
  Button,
  Grid,
  TextField,
  AppBar,
  Tabs,
  Tab,
  Box,
  Checkbox,
  Typography,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

import moment from 'moment';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Statistics from '../reporting/cards/instagram/Statistics';
import EngagementStatistics from './cards/instagram/EngagementStatistics';
import InstagramPostTable from './cards/instagram/InstagramPostTable';
import InstagramThumbnailView from './cards/instagram/InstagramThumbnailView';
import OrganicTable from './cards/instagram/OrganicTable';
import DateRangePicker from '../ux/DateRangePicker';
import { campaignHandlers } from '../../api_handlers/campaign';
import { downloadFile } from '../../api_handlers/index';
import { getInstagramStatistic } from '../../api_handlers/reporting';
import { getInstagramFeedCount } from '../../api_handlers/feeds';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // console.log('propsasasasasas',children.ig_total_posts)

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const items = [
  'Impressions',
  'Reach',
  'Total Engagement',
  'Conversions',
  'Commission',
  'Video Views',
  'Clicks',
  'Spend',
  'Replies',
  'Shares',
  // 'Views',
  'Link Clicks',
  // "Likes",
  // "Comments",
  'Saved',
  'Sticker Taps',
  'Reel Plays',
  'Audience Retention',
  'CPM',
  'CPV',
  'CPE',
  'CPC',
];

// const postTypeOptions = ['static', 'reel'];
const postTypeOptions=[
  { value: "static", inputProps: "Post" },
  { value: "reel", inputProps: "Reel" },
]


class InstagramDashboard extends Component {
  constructor(props) {
    super(props);

    this.handleFilter = this.handleFilter.bind(this);

    this.state = {
      influencerOptions: [],
      selectedInfluencer: null,
      startDate: null,
      endDate: null,
      prev_startDate: null,
      prev_endDate: null,
      campaign: null,
      defaultDateRange: [],
      is_loading: false,
      stats: null,
      selectedFeedTab: 0,
      category: [],
      feedCount: 0,
      storyCount: 0,
      bcaCount: 0,
      organicCount: 0,
      postType: postTypeOptions.map(data=>data.value).join(','),
    };
  }
  // var a = postTypeOptions

  downloadReport() {
    downloadFile(`/campaign/${this.props.campaignId}/report/instagram/feed/download`, {
      influencer_id: this.state.selectedInfluencer,
      tab: this.state.selectedFeedTab,
      start_date: this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : null,
      end_date: this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : null,
      post_type:
        this.state.selectedFeedTab == 0
          ? this.state.postType
          : this.state.selectedFeedTab == 1
          ? 'story'
          : null,
    });
  }
  async getData() {
    const resp = await getInstagramStatistic(
      this.props.campaignId,
      this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : null,
      this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : null,
      this.state.selectedInfluencer,
    );
    this.setState({ stats: resp.data });
    // console.log('resp',resp)
  }

  async getFeedCount() {
    const rep = await getInstagramFeedCount(1, {
      start_date: this.props.startDate,
      end_date: this.props.endDate,
      tab: 'count',
      campaignID: this.props.campaignId,
    });
    console.log('COUNT RESPONSE: ', rep);
    this.setState({
      feedCount: rep.feed_count,
      storyCount: rep.story_count,
      bcaCount: rep.bca_count,
      organicCount: rep.organic_count,
    });
  }

  componentDidMount() {
    campaignHandlers.getCampaignInfluencerOptions(this.props.campaignId).then((data) => {
      this.setState({ influencerOptions: data });
    });
    this.setState({
      loading: true,
    });
    campaignHandlers.getBasicCampaignDetails(this.props.campaignId).then((data) => {
      this.setState({
        campaign: data,
        startDate: data.report_start_date ? new Date(data.report_start_date).toISOString() : null,
        endDate: data.report_end_date ? new Date(data.report_end_date).toISOString() : null,
        loading: false,
        defaultDateRange: [new Date(data.report_start_date), new Date(data.report_end_date)],
      });
    });
    this.getFeedCount();
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  componentDidUpdate() {
    if (
      this.state.prev_startDate !== this.state.startDate &&
      this.state.prev_endDate !== this.state.endDate
    ) {
      this.getData();
      this.setState({
        prev_startDate: this.state.startDate,
        prev_endDate: this.state.endDate,
      });
    }
  }

  handleFilter(event, selectedValues) {
    this.state.category.splice(0, this.state.category.length);
    this.setState({
      category: this.state.category.concat(...selectedValues),
    });
    this.postTable.reloadData();
    this.statsRef.reloadData();
    this.getData();
  }

  render() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} xl={12}>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Grid
                container
                justify="flex-end"
                spacing={1}
                style={{ marginTop: '0px' }}
                alignItems="center"
              >
                <Grid item md={3}>
                  <Autocomplete
                    id="tags-standard"
                    multiple
                    InputLabelProps={{ shrink: false }}
                    options={items}
                    disableCloseOnSelect
                    renderTags={(values) => <Chip label={values[0] + ' +' + (values.length - 1)} />}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </React.Fragment>
                    )}
                    style={{
                      width: '100%',
                      background: '#F9F9F9',
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Columns to display" />
                    )}
                    getOptionDisabled={(options) => (this.state.category.length > 6 ? true : false)}
                    onChange={this.handleFilter}
                  />
                </Grid>

                <Grid
                  item
                  md={1}
                  style={{
                    fontSize: 11,
                    paddingRight: 0,
                  }}
                >
                  <span>Filter by:</span>
                </Grid>
                <Grid item md={7} style={{ paddingLeft: 0 }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    style={{
                      align: 'center',
                    }}
                  >
                    <Grid item md={this.state.selectedFeedTab == 0 ? 3 : 4}>
                      <DateRangePicker
                        id="date-picker"
                        placement="left-end"
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onSelect={async (result) => {
                          await this.setState({
                            startDate: result[0].startDate,
                            endDate: result[0].endDate,
                          });
                          this.postTable.reloadData();
                          this.statsRef.reloadData();
                          this.getData();
                        }}
                      ></DateRangePicker>
                    </Grid>
                    <Grid item md={this.state.selectedFeedTab == 0 ? 3 : 4}>
                      <Autocomplete
                        options={this.state.influencerOptions}
                        getOptionLabel={(option) => option.influencer__name}
                        InputLabelProps={{ shrink: false }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="normal"
                            variant="outlined"
                            size="small"
                            placeholder="Influencer Name"
                            style={{
                              width: '100%',
                              background: '#FFF',
                              fontSize: '11px',
                              height: 35,
                              marginBottom: 23,
                              paddingTop: 0,
                            }}
                          />
                        )}
                        onChange={async (event, value) => {
                          await this.setState({
                            selectedInfluencer: value ? value.influencer_id : null,
                          });
                          this.postTable.reloadData();
                          this.statsRef.reloadData();
                          this.getData();
                        }}
                        style={{
                          fontSize: '11px',
                        }}
                      />
                    </Grid>
                    {this.state.selectedFeedTab == 0 ? (
                      <Grid item md={3}>
                        <Autocomplete
                          options={postTypeOptions}
                          getOptionLabel={(option) => option.value}
                          multiple
                          InputLabelProps={{ shrink: false }}
                          // renderTags={(values) => <Chip label={values.slice(0, 2).join(',')} />}
                          renderTags={(values) => <Chip label={values.map(val=>val.inputProps).join(',') } />}
                          defaultValue={[postTypeOptions[0],postTypeOptions[1]]}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.inputProps}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin="normal"
                              variant="outlined"
                              size="small"
                              placeholder="Post type"
                              style={{
                                width: '100%',
                                background: '#FFF',
                                fontSize: '11px',
                                height: 35,
                                marginBottom: 23,
                                paddingTop: 0,
                              }}
                            >
                            </TextField>
                          )}
                          onChange={async (event, value) => {
                            var inputValue=value.map(data=>data.value)
                            await this.setState({
                              postType: inputValue.join(','),
                            });
                            this.postTable.reloadData();
                            this.statsRef.reloadData();
                            this.getData();
                          }}
                          style={{
                            fontSize: '11px',
                          }}
                        />
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Grid item md={this.state.selectedFeedTab == 0 ? 3 : 4}>
                      <Button
                        variant="contained"
                        endIcon={<CloudDownloadIcon />}
                        style={{
                          fontSize: 12,
                          textTransform: 'none',
                          backgroundColor: '#444',
                          color: 'white',
                        }}
                        onClick={() => {
                          this.downloadReport();
                        }}
                      >
                        Report
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {}
            <Grid item xs={12} md={12} xl={12}>
              <Grid container spacing={1}>
                <Grid item md={4} style={{ paddingTop: '4%' }}>
                  <EngagementStatistics
                    socialPlatform={this.props.report?.socialPlatform}
                    {...this.props.report?.statistics}
                    extraMargin={20}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    stats={this.state.stats}
                  />
                </Grid>
                <Grid item md={8}>
                  <Statistics
                    socialPlatform={this.props.report?.socialPlatform}
                    {...this.props.report?.statistics}
                    extraMargin={20}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    stats={this.state.stats}
                    influencerId={this.state.selectedInfluencer}
                    onRef={(ref) => (this.statsRef = ref)}
                    postType={this.state.postType}
                    selectedFeedTab={this.state.selectedFeedTab}
                  />
                </Grid>
                <Grid item md={12}>
                  <Grid container spacing={1}>
                    <Grid item md={12}>
                      <AppBar
                        position="static"
                        indicatorColor="primary"
                        style={{
                          backgroundColor: '#F3F3F3',
                          color: '#333',
                          display: 'block',
                        }}
                      >
                        <Tabs
                          value={this.state.selectedFeedTab}
                          onChange={async (event, value) => {
                            await this.setState({
                              selectedFeedTab: value,
                              postType: value === 0 ? postTypeOptions.map(data=>data.value).join(',') : 'story',
                            });
                            this.postTable.reloadData();
                            this.statsRef.reloadData();
                            this.getData();
                            this.getFeedCount();
                          }}
                          aria-label="simple tabs example"
                        >
                          <Tab label={`Feeds (${this.state.feedCount})`} id="feed-tab" />
                          <Tab label={`Stories (${this.state.storyCount})`} id="story-tab" />
                          <Tab label={`BCA (${this.state.bcaCount})`} id="bca-tab" />
                          <Tab label={`Organic (${this.state.organicCount})`} id="organic-tab" />
                        </Tabs>
                      </AppBar>
                      <TabPanel value={this.state.selectedFeedTab} index={0}>
                        {!this.state.loading ? (
                          <InstagramPostTable
                            campaignId={this.props.campaignId}
                            influencerId={this.state.selectedInfluencer}
                            startDate={
                              this.state.startDate
                                ? moment(this.state.startDate).format('YYYY-MM-DD')
                                : null
                            }
                            endDate={
                              this.state.endDate
                                ? moment(this.state.endDate).format('YYYY-MM-DD')
                                : null
                            }
                            onRef={(ref) => (this.postTable = ref)}
                            storyTab={false}
                            allowedTableColumns={this.state.category}
                            tab={'feed'}
                            postType={this.state.postType}
                            selectedFeedTab={this.state.selectedFeedTab}
                          />
                        ) : null}
                      </TabPanel>
                      <TabPanel value={this.state.selectedFeedTab} index={1}>
                        {!this.state.loading ? (
                          <InstagramThumbnailView
                            campaignId={this.props.campaignId}
                            influencerId={this.state.selectedInfluencer}
                            startDate={
                              this.state.startDate
                                ? moment(this.state.startDate).format('YYYY-MM-DD')
                                : null
                            }
                            endDate={
                              this.state.endDate
                                ? moment(this.state.endDate).format('YYYY-MM-DD')
                                : null
                            }
                            onRef={(ref) => (this.postTable = ref)}
                            storyTab={true}
                            allowedTableColumns={this.state.category}
                            tab={'story'}
                            postType={this.state.postType}
                            selectedFeedTab={this.state.selectedFeedTab}
                          />
                        ) : null}
                      </TabPanel>
                      <TabPanel value={this.state.selectedFeedTab} index={2}>
                        {!this.state.loading ? (
                          <InstagramPostTable
                            campaignId={this.props.campaignId}
                            influencerId={this.state.selectedInfluencer}
                            startDate={
                              this.state.startDate
                                ? moment(this.state.startDate).format('YYYY-MM-DD')
                                : null
                            }
                            endDate={
                              this.state.endDate
                                ? moment(this.state.endDate).format('YYYY-MM-DD')
                                : null
                            }
                            onRef={(ref) => (this.postTable = ref)}
                            storyTab={true}
                            allowedTableColumns={this.state.category}
                            tab={'bca'}
                          />
                        ) : null}
                      </TabPanel>
                      <TabPanel value={this.state.selectedFeedTab} index={3}>
                        {!this.state.loading ? (
                          <OrganicTable
                            campaignId={this.props.campaignId}
                            influencerId={this.state.selectedInfluencer}
                            startDate={
                              this.state.startDate
                                ? moment(this.state.startDate).format('YYYY-MM-DD')
                                : null
                            }
                            endDate={
                              this.state.endDate
                                ? moment(this.state.endDate).format('YYYY-MM-DD')
                                : null
                            }
                            onRef={(ref) => (this.postTable = ref)}
                            storyTab={true}
                            allowedTableColumns={this.state.category}
                            tab={'organic'}
                          />
                        ) : null}
                      </TabPanel>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default InstagramDashboard;
