import { constants } from '../constants/discovery';

const initialState = {
  searchedSelectedInfluencerIds: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.UPDATE_SEARCHED_SELECTED_INFLUENCER_IDS:
      return {
        ...state,
        searchedSelectedInfluencerIds: action.searchedSelectedInfluencerIds,
      };
    default:
      return state;
  }
}
