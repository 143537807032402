import React, { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { makeStyles, withStyles } from '@material-ui/core/styles';

function StartDatePicker({classes}){

  const [selectedDate, setSelectedDate] = useState(null);

  function handleDateChange(date) {
    console.log("date event", date)
    setSelectedDate(date);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justifyContent="flex-start">
      <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/mm/yyyy"
          margin="normal"
          id="date-picker-inline"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          className={classes.datePicker}
        />
    </Grid>
    </MuiPickersUtilsProvider>
  );
}

const styles = {
  datePicker: {
    background: "white",
    border: "1px solid #C4C4C4",
    borderRadius: 9,
    "& .MuiInput-underline:before": {
      borderBottom: "none"
    },
    "& .MuiInputBase-input": {
      paddingLeft: 10
    }
  }
}

export default withStyles(styles)(StartDatePicker);