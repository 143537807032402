import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroll-component';

import Header from './components/Header';
import ActionBar from './components/ActionBar';
import ListHeader from './components/ListHeader';
import InfluencerInfoCard from './components/InfluencerInfoCard';

import { getInfluencers } from 'actions/discovery';

function Discovery({ fetchInfluencers, influencers, influencersCount }) {
  const classes = useStyles();
  const [selectedInfluencer, setSelectedInfluencer] = useState([]);

  function checkEnd() {
    console.log('scrolling');
    if (
      window.innerHeight + window.scrollY >=
      (document.body.offsetHeight * this.settings.scrollAfterPercentage) / 100
    ) {
      console.log('get enxt page');
    }
  }

  useEffect(() => {
    fetchInfluencers();
  }, []);

  function fetchMoreData() {
    fetchInfluencers();
  }

  return (
    <Container fixed className={classes.root} onScroll={checkEnd}>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Header />
        </Grid>
        <Grid item md={12}>
          <ActionBar
            selectedInfluencer={selectedInfluencer}
            setSelectedInfluencer={setSelectedInfluencer}
          />
        </Grid>
        <Grid item md={12}>
          <ListHeader count={influencersCount} />
        </Grid>
        <Grid item md={12}>
          <Grid container spacing={4}>
            <InfiniteScroll
              dataLength={influencers.length}
              next={fetchMoreData}
              hasMore={influencersCount > influencers.length}
              loader={<h4>Loading...</h4>}
              style={{ padding: 20 }}
            >
              {influencers.map((influencer, index) => (
                <Grid item md={12} key={index}>
                  <InfluencerInfoCard
                    {...influencer}
                    selectedInfluencer={selectedInfluencer}
                    setSelectedInfluencer={setSelectedInfluencer}
                  />
                </Grid>
              ))}
            </InfiniteScroll>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
    width: '100%',
    maxWidth: '100%',
    paddingTop: 20,
    maxHeight: '80vh',
  },
}));

const mapStateToProps = (state) => {
  return {
    influencers: state.discovery.influencers,
    influencersCount: state.discovery.influencersCount,
  };
};

const actionCreators = {
  fetchInfluencers: getInfluencers,
};

export default connect(mapStateToProps, actionCreators)(Discovery);

// export default Discovery 3
