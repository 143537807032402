import React from 'react';
import {
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputLabel,
  ListSubheader,
  Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import MetaTags from 'react-meta-tags';

import Category from './Category';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Logo from '../../assets/awards/logo.png';
import ShortLogo from '../../assets/awards/short_logo.png';
import Banner1 from '../../assets/awards/banner_1.png';
import Banner2 from '../../assets/awards/banner_2.png';
import StepsBanner from '../../assets/awards/steps.png';
import Icon1 from '../../assets/awards/icon1.png';
import Icon2 from '../../assets/awards/icon2.png';
import NominateButton from '../../assets/awards/nominate_now.png';
import LinkedinIcon from '../../assets/awards/linkedin.png';
import YoutubeIcon from '../../assets/youtube-logo.png';
import FacebookIcon from '../../assets/awards/facebook.png';
import InstagramIcon from '../../assets/awards/instagram.png';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import { saveNominationForm } from '../../api_handlers/award';
import { setCookie } from '../utils';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const joinQuotes = [
  'Announcing the Creator Spotlight Awards to recognize creators across 60 genres',
  "Get a chance to work with India's leading brands for paid collaborations",
  'Over 100 brands and 80,000 creators are part of the movement at Influencer.in',
];

const rules = [
  'Nominations are open to all content creators with the following of more than 5,000 on any Social Media platform.',
  'The nomination process is free.',
  'To complete the nomination, select your categories and connect your Facebook, Instagram or Youtube accounts.',
  'Winner Selection objective will be a combination of Jury vote and your engagement rate/reach from your handle/channel.',
  'As a creator, you can apply for multiple categories.',
  'This is a one-time submission form and once submitted cannot be edited',
];

const nicheSpecificCategoriesAwards = [
  'Best Healthcare/Wellness Influencer',
  'Best Fitness Influencer',
  'Best Family/ Parenting Influencer',
  'Best Fashion Influencer.',
  'Best Food Influencer',
  'Best Beauty Influencer',
  'Best Tech Influencer',
  'Best Education Influencer',
  'Best Travel Influencer',
  'Best Social Cause Influencer',
  'Best Money Planning and Personal Finance Influencer.',
  'Best Lifestyle and Luxury Influencer',
  'Best Entertainment Influencer',
  'Best Celebrity Influencer of the Year',
  'Best Body Positivity Influencer',
  'Best Comic Influencer of the Year',
  'Best Music Influencer',
  'Best Dance Influencer',
  'Best Gaming Influencer',
  'Best Photography Influencer',
];

const platformSpecificAwards = [
  'Best Use of Telegram ',
  'Best Use of MoJ',
  'Best Use of Trell',
  'Best Use of Sharechat ',
  'Best Use of Clubhouse ',
  'Best Use of TakaTak',
  'Best Use of Roposso',
  'Best Use of Koo',
  'Best Multi-Channel Creator',
];

const excellenceBasedAwards = [
  'Best Rising Star Influencer',
  'Most Viral Content Creator of the Year',
  'Best Nano Influencer (Upto 10k followers)',
  'Best Micro Influencer (10k to 100k followers)',
  'Best Macro Influencer (100k and more)',
  'Best Blog of the Year',
  'Best Influencer of the Year',
  'People’s choice for Best Influencer',
];

const regionLanguageSpecificAwards = [
  'Best Regional Influencer - Tamil',
  'Best Regional Influencer - Marathi ',
  'Best Regional Influencer - Malayalam',
  'Best Regional Influencer - Bengali ',
  'Best Regional Influencer - Punjabi',
  'Best Regional Influencer - Bhojpuri',
  'Best Regional Influencer - Telugu',
];

const styles = {
  root: {
    margin: 'auto',
    padding: '0px !important',
    textAlign: 'center',
    '& img,h1,h2,h3,h4,h5,h6,button': {
      margin: 'auto',
      textAlign: 'center',
    },
    '& h1': {
      fontWeight: '800 !important',
      fontSize: 38,
      color: 'black',
    },
    '& h2': {
      fontWeight: '400 !important',
      fontSize: 22,
    },
    '& h3': {
      fontWeight: '700 !important',
      fontSize: 18,
    },
    '& h5': {
      fontWeight: '500 !important',
      fontSize: 16,
    },
    '& .contentText span': {
      fontSize: 16,
      color: '#555',
    },
    '& button': {
      background: '#d85130',
      color: 'white',
      borderRadius: '30px',
      padding: '8px 20px 8px 20px',
      textTransform: 'none',
      fontSize: 24,
      fontWeight: '550',
      padding: '10px 70px',
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  section: {
    marginBottom: 50,
  },
  marginVeryHigh: {
    marginTop: 70,
    marginBottom: 70,
  },
  marginHigh: {
    marginTop: 30,
    marginBottom: 30,
  },
  marginMedium: {
    marginTop: 20,
    marginBottom: 20,
  },
  form: {
    marginTop: 50,
    padding: 20,
    marginBottom: 100,
    '& .MuiInputBase-root': {
      borderRadius: '10px',
      borderColor: 'black',
    },
    '& input, & .MuiSelect-select': {
      padding: '15px 20px',
    },
    '& .MuiFormControl-root': {
      margin: '5px 0px',
    },
    '& a': {
      textDecoration: 'none',
    },
    '& .SelectLabel': {
      top: -7,
      left: 16,
    },
  },
  formError: {
    fontSize: '11px',
    marginLeft: '20px',
    marginBottom: '10px',
    color: 'red',
  },
  footer: {
    padding: '20px',
    background: 'white',
  },
};

class BannerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone_number: '+91',
      award_category: [],
      platform: '',
      handle: '',
      other_handle: '',
      is_full_time: '',
      recent_brands: '',
      annual_revenue: null,
      brand_collab: '',
      individualCreatorElement: null,
      specialCategoriesAwards: null,
      formErrors: {},
    };
  }

  async submitNominationForm() {
    const {
      name,
      email,
      phone_number,
      award_category,
      platform,
      handle,
      other_handle,
      is_full_time,
      recent_brands,
      annual_revenue,
      brand_collab,
    } = this.state;
    try {
      const resp = await saveNominationForm({
        name,
        email,
        phone_number,
        award_category: this.state.award_category.join(', '),
        platform,
        handle,
        other_handle,
        is_full_time,
        recent_brands,
        annual_revenue,
        brand_collab,
      });
      setCookie('nomination_form_id', resp.data.id);
      this.props.history.push('/login?t=inf-signup');
    } catch (error) {
      this.setState({
        formErrors: error.response.data,
      });
    }
  }

  async awardSelected(value) {
    await this.setState({ award_category: [value] });
    document.querySelector('#nominationForm').scrollIntoView();
  }

  beforeCategoryPopupOpened(source) {
    if (source !== 'nicheSpecificCategories') this.nicheSpecificCategoriesRef.closePopup();
    if (source !== 'platformSpecific') this.platformSpecificRef.closePopup();
    if (source !== 'excellenceBased') this.excellenceBasedRef.closePopup();
    if (source !== 'regionLanguageSpecific') this.regionLanguageSpecificRef.closePopup();
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Container className={classes.root}>
          <MetaTags>
            <title>Creator Spotlight Awards by Influencer.in</title>
            <meta property="og:title" content="Creator Spotlight Awards by Influencer.in" />
          </MetaTags>
          <Grid container>
            <Grid item sm={12} xs={12} md={6} lg={6} style={{ margin: 'auto' }}>
              <Grid container className={classes.section} style={{ paddingLeft: 10 }}>
                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <Grid container className={classes.marginHigh}>
                    <a href="https://www.influencer.in" target="_blank" style={{ width: '100%' }}>
                      <img src={Logo} height={80} />
                    </a>
                  </Grid>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item sm={12} xs={12} md={6} lg={6}>
                      <Grid container className={classes.marginHigh}>
                        <h1>Creator Spotlight Awards, 2021</h1>
                      </Grid>
                      <Grid container className={classes.marginMedium}>
                        <h2>Recognizing India's Top Creators</h2>
                      </Grid>
                      <Grid container>
                        <h2>Winners stand a chance to collaborate with India's Top Brands!</h2>
                      </Grid>
                      <Grid container className={classes.marginHigh}>
                        <a href="#nominationForm" style={{ width: '100%' }}>
                          <img src={NominateButton} />
                        </a>
                      </Grid>
                    </Grid>
                    <Grid item sm={12} xs={12} md={6} lg={6}>
                      <Grid container className={classes.marginHigh}>
                        <img src={Banner1} style={{ width: '90%' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.marginMedium}>
                    <Grid item sm={12} xs={12} md={12}>
                      <Grid container>
                        <h1>2 easy steps to Nominate</h1>
                      </Grid>
                      <Grid container style={{ marginBottom: 0 }}>
                        <img src={StepsBanner} style={{ width: '90%' }} />
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item sm={4} xs={4} style={{ margin: 'auto' }}>
                          <Grid container style={{ paddingLeft: 0 }}>
                            <Grid item sm={12} xs={12}>
                              <Grid container>
                                <h2>Step 1</h2>
                              </Grid>
                              <Grid container>
                                <h5>Fill out the nomination form below</h5>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sm={4} xs={4}></Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Grid item sm={12} xs={12}>
                              <Grid container>
                                <h2>Step 2</h2>
                              </Grid>
                              <Grid container>
                                <h5>Link your social media accounts</h5>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item sm={4} xs={4} style={{ margin: 'auto' }}>
                          <Grid container style={{ paddingLeft: 0 }}>
                            <Grid item sm={12} xs={12}>
                              <Grid container style={{ marginTop: 40 }}>
                                <h5>Last date to send in your nomination:</h5>
                              </Grid>
                              <Grid container>
                                <h3>10th December</h3>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sm={4} xs={4}></Grid>
                        <Grid item sm={4} xs={4}>
                          <Grid container style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Grid item sm={12} xs={12}>
                              <Grid container style={{ marginTop: 40 }}>
                                <h5>Creator Spotlight Awards LIVE event:</h5>
                              </Grid>
                              <Grid container>
                                <h3>27th December</h3>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.marginMedium}>
                <Grid item sm={12} xs={12} style={{ marginTop: 30 }}>
                  <Grid container style={{ marginTop: 30, marginBottom: 30 }}>
                    <h1>30+ Categories of Awards</h1>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Category
                        id="nicheSpecificCategories"
                        title="Niche Specific Categories"
                        listItems={nicheSpecificCategoriesAwards}
                        icon={Icon1}
                        onSelect={this.awardSelected}
                        parent={this}
                        onRef={(ref) => (this.nicheSpecificCategoriesRef = ref)}
                        beforeClick={this.beforeCategoryPopupOpened}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Category
                        id="platformSpecific"
                        title="Platform Specific"
                        listItems={platformSpecificAwards}
                        icon={Icon2}
                        onSelect={this.awardSelected}
                        parent={this}
                        onRef={(ref) => (this.platformSpecificRef = ref)}
                        beforeClick={this.beforeCategoryPopupOpened}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Category
                        id="excellenceBased"
                        title="Excellence Based"
                        listItems={excellenceBasedAwards}
                        icon={Icon1}
                        onSelect={this.awardSelected}
                        parent={this}
                        onRef={(ref) => (this.excellenceBasedRef = ref)}
                        beforeClick={this.beforeCategoryPopupOpened}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Category
                        id="regionLanguageSpecific"
                        title="Region/Language Specific"
                        listItems={regionLanguageSpecificAwards}
                        icon={Icon2}
                        onSelect={this.awardSelected}
                        parent={this}
                        onRef={(ref) => (this.regionLanguageSpecificRef = ref)}
                        beforeClick={this.beforeCategoryPopupOpened}
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginBottom: 100 }}>
                    <img src={Banner2} style={{ width: '90%' }} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={[classes.form, classes.section]} id="nominationForm">
                <Grid item sm={12} xs={12}>
                  <Grid container className={classes.marginHigh}>
                    <h1>Nomination Form</h1>
                  </Grid>
                  <Grid container>
                    <FormControl fullWidth>
                      <TextField
                        placeholder="Name"
                        variant="outlined"
                        value={this.state.name}
                        onChange={(evt) => this.setState({ name: evt.target.value })}
                      />
                    </FormControl>
                    {this.state.formErrors.name ? (
                      <div className={classes.formError}>{this.state.formErrors.name}</div>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid container>
                    <FormControl fullWidth>
                      <TextField
                        placeholder="Email"
                        variant="outlined"
                        value={this.state.email}
                        onChange={(evt) => this.setState({ email: evt.target.value })}
                      />
                    </FormControl>
                    {this.state.formErrors.email ? (
                      <div className={classes.formError}>{this.state.formErrors.email}</div>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid container>
                    <FormControl fullWidth>
                      <TextField
                        placeholder="Phone number"
                        variant="outlined"
                        value={this.state.phone_number}
                        onChange={(evt) => this.setState({ phone_number: evt.target.value })}
                      />
                    </FormControl>
                    {this.state.formErrors.phone_number ? (
                      <div className={classes.formError}>{this.state.formErrors.phone_number}</div>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid container>
                    <FormControl fullWidth>
                      <InputLabel id="awardCategoryLabel" className="SelectLabel">
                        Awards Category
                      </InputLabel>
                      <Select
                        labelId="awardCategoryLabel"
                        label="Awards Category"
                        variant="outlined"
                        multiple
                        value={this.state.award_category}
                        renderValue={(selected) => {
                          return selected.join(', ');
                        }}
                        onChange={(evt, selectedValues) => {
                          this.setState({ award_category: evt.target.value });
                        }}
                      >
                        <ListSubheader>Niche Specific Categories</ListSubheader>
                        {nicheSpecificCategoriesAwards.map((award) => (
                          <MenuItem value={award} key={award}>
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={
                                  this.state.award_category.indexOf(award) === -1 ? false : true
                                }
                              />
                              {award}
                            </React.Fragment>
                          </MenuItem>
                        ))}
                        <ListSubheader>Platform Specific</ListSubheader>
                        {platformSpecificAwards.map((award) => (
                          <MenuItem value={award} key={award}>
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={
                                  this.state.award_category.indexOf(award) === -1 ? false : true
                                }
                              />
                              {award}
                            </React.Fragment>
                          </MenuItem>
                        ))}
                        <ListSubheader>Excellence Based</ListSubheader>
                        {excellenceBasedAwards.map((award) => (
                          <MenuItem value={award} key={award}>
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={
                                  this.state.award_category.indexOf(award) === -1 ? false : true
                                }
                              />
                              {award}
                            </React.Fragment>
                          </MenuItem>
                        ))}
                        <ListSubheader>Region/Language Specific</ListSubheader>
                        {regionLanguageSpecificAwards.map((award) => (
                          <MenuItem value={award} key={award}>
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={
                                  this.state.award_category.indexOf(award) === -1 ? false : true
                                }
                              />
                              {award}
                            </React.Fragment>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {this.state.formErrors.award_category ? (
                      <div className={classes.formError}>
                        {this.state.formErrors.award_category}
                      </div>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid container>
                    <FormControl fullWidth>
                      <InputLabel id="platformLabel" className="SelectLabel">
                        Platform
                      </InputLabel>
                      <Select
                        labelId="platformLabel"
                        label="Age"
                        variant="outlined"
                        value={this.state.platform}
                        onChange={(evt) => this.setState({ platform: evt.target.value })}
                      >
                        <MenuItem value="instagram">Instagram</MenuItem>
                        <MenuItem value="facebook">Facebook</MenuItem>
                        <MenuItem value="youtube">Youtube</MenuItem>
                      </Select>
                    </FormControl>
                    {this.state.formErrors.platform ? (
                      <div className={classes.formError}>{this.state.formErrors.platform}</div>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid container>
                    <FormControl fullWidth>
                      <TextField
                        placeholder="Insta handle"
                        variant="outlined"
                        value={this.state.handle}
                        onChange={(evt) => this.setState({ handle: evt.target.value })}
                      />
                    </FormControl>
                    {this.state.formErrors.handle ? (
                      <div className={classes.formError}>{this.state.formErrors.handle}</div>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid container>
                    <FormControl fullWidth>
                      <TextField
                        placeholder="Any other social handle"
                        variant="outlined"
                        value={this.state.other_handle}
                        onChange={(evt) => this.setState({ other_handle: evt.target.value })}
                      />
                    </FormControl>
                    {this.state.formErrors.other_handle ? (
                      <div className={classes.formError}>{this.state.formErrors.other_handle}</div>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid container>
                    <FormControl fullWidth>
                      <InputLabel id="fullTimeCreatorLabel" className="SelectLabel">
                        Are you a full-time creator?
                      </InputLabel>
                      <Select
                        labelId="fullTimeCreatorLabel"
                        label="Age"
                        variant="outlined"
                        value={this.state.is_full_time}
                        onChange={(evt) => this.setState({ is_full_time: evt.target.value })}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                    {this.state.formErrors.is_full_time ? (
                      <div className={classes.formError}>{this.state.formErrors.is_full_time}</div>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid container>
                    <FormControl fullWidth>
                      <TextField
                        placeholder="Which brands have you worked with recently"
                        variant="outlined"
                        value={this.state.recent_brands}
                        onChange={(evt) => this.setState({ recent_brands: evt.target.value })}
                      />
                    </FormControl>
                    {this.state.formErrors.recent_brands ? (
                      <div className={classes.formError}>{this.state.formErrors.recent_brands}</div>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid container>
                    <FormControl fullWidth>
                      <TextField
                        type={'number'}
                        placeholder="Annual revenue as a creator"
                        variant="outlined"
                        value={this.state.annual_revenue}
                        onChange={(evt) => this.setState({ annual_revenue: evt.target.value })}
                      />
                    </FormControl>
                    {this.state.formErrors.annual_revenue ? (
                      <div className={classes.formError}>
                        {this.state.formErrors.annual_revenue}
                      </div>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid container>
                    <FormControl fullWidth>
                      <TextField
                        placeholder="Please share one link of your brand collab"
                        variant="outlined"
                        value={this.state.brand_collab}
                        onChange={(evt) => this.setState({ brand_collab: evt.target.value })}
                      />
                    </FormControl>
                    {this.state.formErrors.brand_collab ? (
                      <div className={classes.formError}>{this.state.formErrors.brand_collab}</div>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid container style={{ width: '100%' }}>
                    <Button onClick={() => this.submitNominationForm()}>Submit</Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={12} xs={12}>
                  <Grid container>
                    <h1>Join the Creator Movement with Influencer.in!</h1>
                  </Grid>
                  <Grid container>
                    <List>
                      {joinQuotes.map((quote, i) => {
                        return (
                          <ListItem key={i}>
                            <ListItemIcon>
                              <PlayArrowIcon />
                            </ListItemIcon>
                            <ListItemText className="contentText" primary={quote} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={[classes.marginHigh, classes.section]}>
                <Grid item sm={12} xs={12}>
                  <Grid container className={classes.marginHigh}>
                    <h1>objective & Rules</h1>
                  </Grid>
                  <Grid container>
                    <List>
                      {rules.map((rule, i) => {
                        return (
                          <ListItem key={i}>
                            <ListItemText className="contentText" primary={`${i + 1}. ${rule}`} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  <Grid container>
                    <a href="#nominationForm" style={{ width: '100%' }}>
                      <img src={NominateButton} />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.marginVeryHigh}>
                <Grid item sm={12} xs={12}>
                  <Grid container>
                    <img src={ShortLogo} />
                  </Grid>
                  <Grid container className={classes.marginHigh}>
                    <a
                      href="https://www.influencer.in"
                      target="_blank"
                      style={{
                        width: '100%',
                      }}
                    >
                      <h1>About Influencer.in</h1>
                    </a>
                  </Grid>
                  <Grid container className={[classes.marginHigh, 'contentText']}>
                    <span>
                      Influencer.in is India's fastest growing influencer marketing platform
                      connecting brands with over 80.000 creators. With operations across Mumbai,
                      Bangalore, Delhi and Chennai. Influencer.in aims to unite content creators
                      with brands to develop meaningful content that is enjoyable, inspirational and
                      triggers action.
                    </span>
                  </Grid>
                  <Grid container className={[classes.marginHigh, 'contentText']}>
                    <span>
                      The proprietary dashboard with API integrations enables brands to discover
                      influencers. manage workflows and have integrated reporting from vari-ous
                      social platforms. Some of Influencerin's clients include Himalaya Pharma, M &
                      S. Khatabook, Mala-bar Gold, Pubg. Tata Cliq, mFine, Swiggy, Rummy Culture,
                      Rupeek. Jaguar and more.
                    </span>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <a href="https://www.linkedin.com/company/influencer-in/" target="_blank">
                        <img src={LinkedinIcon} />
                      </a>
                    </Grid>
                    <Grid item>
                      <a
                        href="https://www.youtube.com/channel/UCG9I7TOp2GJ22fRbIzz0vxw"
                        target="_blank"
                      >
                        <img src={YoutubeIcon} style={{ height: '24px' }}/>
                      </a>
                    </Grid>
                    <Grid item>
                      <a href="https://www.facebook.com/influencer.in/" target="_blank">
                        <img src={FacebookIcon} />
                      </a>
                    </Grid>
                    <Grid item>
                      <a href="https://www.instagram.com/influencer.in/" target="_blank">
                        <img src={InstagramIcon} />
                      </a>
                    </Grid>
                  </Grid>
                  <Grid container className={[classes.marginHigh, 'contentText']}>
                    <span style={{ margin: 'auto' }}>
                      PRODUCT OF{' '}
                      <a
                        href="https://www.socialbeat.in"
                        style={{
                          textDecoration: 'underline !important',
                          color: '#555',
                          fontWeight: '700',
                          fontStyle: 'italic',
                        }}
                        target="_blank"
                      >
                        SOCIAL BEAT
                      </a>
                    </span>
                  </Grid>
                  <Grid container className={[classes.marginHigh, 'contentText']}>
                    <span style={{ margin: 'auto' }}>@2021 DIGINFLUENCE PRIVATE LIMITED</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Grid container className={classes.footer}>
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <span>Reach out to us at creators@influencer.in</span>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(BannerPage));
