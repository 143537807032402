import { useEffect, useState, Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, IconButton, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Tooltip from '@material-ui/core/Tooltip';
import Category from './objective/Category';
import Objective from './objective/Objective';
import Budget from './objective/Budget';
import Priority from './objective/Priority';
import PricePerCreator from './objective/PricePerCreator';

import ParticularFilter from './filterSection/ParticularFilter';
import Channel from './filterSection/Channel';
import AudienceAgeGroup from './filterSection/AudienceAgeGroup';
import AudienceLocation from './filterSection/AudienceLocation';
import AudienceGenderSplit from './filterSection/AudienceGenderSplit';
import CreatorsType from './filterSection/CreatorsType';

import ObjectiveOption from './objective/ObjectiveOption';

import AdvanceFilter from 'assets/planner/advance-filter.png';
import Edit from 'assets/planner/edit.png';
import AdvanceFilterWidget from './AdvanceFilterWidget';
import { updatePayload } from 'actions/discovery';

import moment from 'moment';
import FilterSlider from './objective/FilterSlider';
import { SignalCellularNull } from '@mui/icons-material';

class Filters extends Component {
  constructor(props) {
    super(props);

    this.setChannels = this.setChannels.bind(this);
    this.setCelebsType = this.setCelebsType.bind(this);
    this.setInfluencerTypeLock = this.setInfluencerTypeLock.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.setObjectives = this.setObjectives.bind(this);
    this.setList = this.setList.bind(this);

    this.state = {
      channels: [],
      mega: 0,
      macro: 0,
      micro: 300000,
      nano: 0,
      ismegaLocked: false,
      ismacroLocked: false,
      ismicroLocked: false,
      isnanoLocked: false,
      isDisabled: false,
      remainingValue: 0,
      locations: [],
      influencerLocations: [],
      influencerCountries: [],
      influencerState: [],
      influencerCity: [],
      influencerZone: [],
      genderGroupType: '',
      genderGroupPercentage: 0,
      showdAvanceFilter: false,
      ageGroup: [],
      objective: 'Reach',
      budget: 300000,
      pricePerCreator: 30000,
      category: [],
      rate: 0,
      audienceCredibility: 0,
      interest_selection: [],
      avg_engagement: 0,
      ppp: 0,
      avg_views: 0,
      gender: '',
      isEmail: '',
      isPhone: '',
      is_audience_data:'',
      brand_final_price: 0,
      influencer_final_price: 0,
      keywords: '',
      bio_keyword:'',
      deliverables: [],
      languageAdvance: [],
      influencerAge: [],
      endDate: null,
      startDate: null,
      isEdit: true,
      isFirstTime: true,
      priority_list: [
        { name: 'Speed', id: 1 },
        { name: 'Price', id: 2 },
        { name: 'Impact', id: 3 },
      ],
      openAdFilter: false,
    };
  }

  setChannels(channels) {
    this.setState({ channels: channels });
  }

  setInfluencerTypeLock(type, value) {
    let dict = {};
    dict[type] = value;
    this.setState(dict);
    // console.log(dict);
  }
  getFilteredType(typeList, type) {
    return typeList
      .filter((_type) => _type !== type)
      .filter((_type) => !this.state[`is${_type}Locked`]);
  }

  setCelebsType(type, value) {
    if (this.state[`is${type}Locked`]) return;

    let dict = {};
    let typeList = ['mega', 'macro', 'micro', 'nano'];
    let remainedValue = this.state[type] - value;

    if (remainedValue === 0) return;

    let filteredTypeList = this.getFilteredType(typeList, type);

    if (filteredTypeList.length === 0) return;
    // Slider moved to left
    if (remainedValue > 0) {
      if (filteredTypeList.length === 1) {
        this.state[filteredTypeList[0]] += remainedValue;
      } else if (filteredTypeList.length === 2) {
        let floorValue = Math.floor(remainedValue / 2);
        this.state[filteredTypeList[0]] += floorValue;
        this.state[filteredTypeList[1]] += remainedValue - floorValue;
      } else if (filteredTypeList.length === 3) {
        let floorValue = Math.floor(remainedValue / 3);
        this.state[filteredTypeList[0]] += floorValue;
        this.state[filteredTypeList[1]] += floorValue;
        this.state[filteredTypeList[2]] += remainedValue - 2 * floorValue;
      }
      remainedValue = 0;
    } else {
      remainedValue = Math.abs(remainedValue);
      let floorValue;
      filteredTypeList
        .sort((first, second) => {
          if (this.state[first] < this.state[second]) return -1;
          if (this.state[first] > this.state[second]) return 1;
          return 0;
        })
        .forEach((influencerType, index) => {
          if (index === filteredTypeList.length - 1) {
            if (this.state[influencerType] - remainedValue < 0) {
              remainedValue = remainedValue - this.state[influencerType];
              this.state[influencerType] = 0;
            } else {
              this.state[influencerType] -= remainedValue;
              remainedValue = 0;
            }
            return false;
          }
          floorValue = Math.floor(remainedValue / (filteredTypeList.length - index));
          if (this.state[influencerType] - floorValue < 0) {
            remainedValue = remainedValue - this.state[influencerType];
            this.state[influencerType] = 0;
          } else {
            this.state[influencerType] -= floorValue;
            remainedValue -= floorValue;
          }
        });
    }
    dict[type] = value - remainedValue;
    this.setState(dict);
  }
  handleBudgetChange(value) {
    let typeList = ['mega', 'macro', 'micro', 'nano'];
    let totalValue = 0;
    typeList.forEach((type) => {
      totalValue += this.state[type];
    });
    let filteredTypeList = this.getFilteredType(typeList, '');

    if (totalValue == this.state['micro'] && !this.state['ismicroLocked']) {
      console.log('assigning to micro');
      this.state['micro'] = value;
      return;
    }
    let remainedValue = value - totalValue;

    // TODO: Creat a common function for this logic
    if (remainedValue > 0) {
      if (filteredTypeList.length === 1) {
        this.state[filteredTypeList[0]] += remainedValue;
      } else if (filteredTypeList.length === 2) {
        let floorValue = Math.floor(remainedValue / 2);
        this.state[filteredTypeList[0]] += floorValue;
        this.state[filteredTypeList[1]] += remainedValue - floorValue;
      } else if (filteredTypeList.length === 3) {
        let floorValue = Math.floor(remainedValue / 3);
        this.state[filteredTypeList[0]] += floorValue;
        this.state[filteredTypeList[1]] += floorValue;
        this.state[filteredTypeList[2]] += remainedValue - 2 * floorValue;
      } else if (filteredTypeList.length === 4) {
        let floorValue = Math.floor(remainedValue / 4);
        this.state[filteredTypeList[0]] += floorValue;
        this.state[filteredTypeList[1]] += floorValue;
        this.state[filteredTypeList[2]] += floorValue;
        this.state[filteredTypeList[3]] += remainedValue - 3 * floorValue;
      }
      remainedValue = 0;
    } else {
      remainedValue = Math.abs(remainedValue);
      let floorValue;
      filteredTypeList
        .sort((first, second) => {
          if (this.state[first] < this.state[second]) return -1;
          if (this.state[first] > this.state[second]) return 1;
          return 0;
        })
        .forEach((influencerType, index) => {
          if (index === filteredTypeList.length - 1) {
            if (this.state[influencerType] - remainedValue < 0) {
              remainedValue = remainedValue - this.state[influencerType];
              this.state[influencerType] = 0;
            } else {
              this.state[influencerType] -= remainedValue;
              remainedValue = 0;
            }
            return false;
          }
          floorValue = Math.floor(remainedValue / (filteredTypeList.length - index));
          if (this.state[influencerType] - floorValue < 0) {
            remainedValue = remainedValue - this.state[influencerType];
            this.state[influencerType] = 0;
          } else {
            this.state[influencerType] -= floorValue;
            remainedValue -= floorValue;
          }
        });
    }
    return;
  }

  setFilters(type, value) {
    let dict = {};
    dict[type] = value;
    this.setState(dict);
    console.log("setFilters",this.state);
  }

  setObjectives(type, value) {
    let dict = {};
    dict[type] = value;
    if (type == 'budget') {
      this.handleBudgetChange(value);
    }
    this.setState(dict);
  }

  applyFilter() {
    let {
      genderGroupType,
      genderGroupPercentage,
      ageGroup,
      channels,
      locations,
      objective,
      budget,
      pricePerCreator,
      category,
      rate,
      audienceCredibility,
      interest_selection,
      avg_engagement,
      ppp,
      avg_views,
      gender,
      isEmail,
      isPhone,
      is_audience_data,
      brand_final_price,
      influencer_final_price,
      keywords,
      bio_keyword,
      deliverables,
      languageAdvance,
      influencerAge,
      influencerLocations,
      influencerCountries,
      influencerState,
      influencerCity,
      influencerZone,
      startDate,
      endDate,
      priority_list,
    } = this.state;

    let payload = {};
    payload['creator-level-requirements'] = {};
    payload['campaign-level-requirements'] = {};
    payload['setReset'] = false;
    if (channels.length > 0) {
      payload['creator-level-requirements']['platform'] = channels.join(',').toLocaleLowerCase();
    }
    if (
      this.state.mega > 0 ||
      this.state.micro > 0 ||
      this.state.macro > 0 ||
      this.state.nano > 0
    ) {
      payload['creator-level-requirements']['creator-type'] = {};
      if (this.state.mega > 0) {
        payload['creator-level-requirements']['creator-type']['mega'] = this.state.mega;
      }
      if (this.state.micro > 0) {
        payload['creator-level-requirements']['creator-type']['micro'] = this.state.micro;
      }
      if (this.state.macro > 0) {
        payload['creator-level-requirements']['creator-type']['macro'] = this.state.macro;
      }
      if (this.state.nano > 0) {
        payload['creator-level-requirements']['creator-type']['nano'] = this.state.nano;
      }
    }
    if (locations.length > 0) {
      payload['creator-level-requirements']['audienceLocation'] = locations.join(',');
    }
    if (genderGroupType && genderGroupType.length > 0) {
      payload['creator-level-requirements']['audienceGender'] =
        genderGroupType.toLocaleLowerCase() + ',' + genderGroupPercentage;
    }
    if (ageGroup && ageGroup.length > 0) {
      payload['creator-level-requirements']['audienceAgeGroup'] = ageGroup.join(',');
    }
    if (objective && objective.length > 0) {
      payload['campaign-level-requirements']['objective'] = objective;
      this.props.setSelectedObjectiveType(objective);
    }
    if (budget > 0) {
      payload['campaign-level-requirements']['budget'] = budget;
    }
    if (pricePerCreator > 0) {
      payload['campaign-level-requirements']['price-per-creator'] = pricePerCreator;
    }
    if (category.length > 0) {
      payload['campaign-level-requirements']['category'] = category.join(',');
    }
    let priority = [];
    priority_list.map((each) => {
      priority.push(each.name.toLowerCase());
    });
    payload['campaign-level-requirements']['priority'] = priority.join(',');
    payload['advance-filters'] = {};
    payload['advance-filters']['sortBy'] = 'followers';
    payload['advance-filters']['sortOrder'] = 'desc';
    if (rate > 0) {
      payload['advance-filters']['engagementRate'] = rate;
    }
    if (audienceCredibility > 0) {
      payload['advance-filters']['audienceCredibility'] = audienceCredibility;
    }
    let audienceInterest = [];
    interest_selection.map((each) => {
      audienceInterest.push(each.label);
    });
    if (audienceInterest.length > 0) {
      payload['advance-filters']['audienceInterest'] = audienceInterest.join('|');
    }
    if (avg_engagement > 0) {
      payload['advance-filters']['avg_engagement'] = avg_engagement;
    }
    if (ppp > 0) {
      payload['advance-filters']['ppp'] = ppp;
    }
    if (avg_views > 0) {
      payload['advance-filters']['avg_views'] = avg_views;
    }
    if (gender?.value) {
      payload['advance-filters']['gender'] = gender?.value.toLocaleLowerCase();
    }
    if (isEmail.length > 0) {
      payload['advance-filters']['isEmail'] = isEmail.toLocaleLowerCase();
    }
    if (isPhone.length > 0) {
      payload['advance-filters']['isPhone'] = isPhone.toLocaleLowerCase();
    }
    if (is_audience_data.length > 0) {
      payload['advance-filters']['is_audience_data'] = is_audience_data.toLocaleLowerCase();
    }
    if (brand_final_price > 0) {
      payload['advance-filters']['brand_final_price'] = brand_final_price;
    }
    if (influencer_final_price > 0) {
      payload['advance-filters']['influencer_final_price'] = influencer_final_price;
    }
    if (keywords.length > 0) {
      payload['advance-filters']['keyword_filter'] = keywords;
    }
    if (bio_keyword.length > 0) {
      payload['advance-filters']['bio_keyword'] = bio_keyword;
    }
    let deliverablesMap = [];
    deliverables.map((each) => {
      deliverablesMap.push(each.label.toLocaleLowerCase());
    });
    if (deliverablesMap.length > 0) {
      payload['advance-filters']['deliverables'] = deliverablesMap.join(',');
    }
    let languageAdvanceMap = [];
    languageAdvance.map((each) => {
      languageAdvanceMap.push(each.label);
    });
    if (languageAdvanceMap.length > 0) {
      payload['advance-filters']['language'] = languageAdvanceMap.join(',');
    }
    let influencerAgeMap = [];
    influencerAge.map((each) => {
      influencerAgeMap.push(each.label.toLocaleLowerCase());
    });
    if (influencerAgeMap.length > 0) {
      payload['advance-filters']['influencerAgeGroup'] = influencerAgeMap.join(',');
    }
    let influencerLocationsMap = [];
    influencerLocations.map((each) => {
      influencerLocationsMap.push(each.label.toLocaleLowerCase());
    });
    if (influencerLocationsMap.length > 0) {
      payload['advance-filters']['influencer_location'] = influencerLocationsMap.join(',');
    }
    let influencerCountriesMap = [];
    influencerCountries.map((each) => {
      influencerCountriesMap.push(each.label.toLocaleLowerCase());
    });

    let influencerStateMap = [];
    influencerState.map((each) => {
      influencerStateMap.push(each.label.toLocaleLowerCase());
    });

    let influencerCityMap = [];
    influencerCity.map((each) => {
      influencerCityMap.push(each.label.toLocaleLowerCase());
    });

    if (influencerCountriesMap.length > 0) {
      payload['advance-filters']['influencer_country'] = influencerCountriesMap.join(',');
    }
    if (influencerStateMap.length > 0) {
      payload['advance-filters']['influencer_state'] = influencerStateMap.join(',');
    }

    if (influencerCityMap.length > 0) {
      payload['advance-filters']['influencer_city'] = influencerCityMap.join(',');
    }

    let influencerZoneMap = [];
    influencerZone.map((each) => {
      influencerZoneMap.push(each.label);
    });

    if (influencerZoneMap.length > 0) {
      payload['advance-filters']['region'] = influencerZoneMap.join(',');
    }
    if (startDate) {
      payload['advance-filters']['camp_start_date'] = moment(startDate).format('YYYY-MM-DD');
    }
    if (endDate) {
      payload['advance-filters']['camp_end_date'] = moment(endDate).format('YYYY-MM-DD');
    }

    // if (searchedSelectedInfluencerIds) {
    //   payload["searchedSelectedInfluencerIds"] = []
    // }

    console.log("payload",payload)

    this.setState({ isEdit: false });
    this.props.setReset(false);
    this.props.getPlannerInfluencersData(payload);
  }

  resetFilter() {
    let payload = {};
    payload['creator-level-requirements'] = {};
    payload['campaign-level-requirements'] = {};
    payload['advance-filters'] = {};
    payload['setReset'] = true;
    delete payload['budget_left'];
    delete payload['exclude_ids'];

    this.setState({
      isEdit: true,
      channels: [],
      mega: 0,
      macro: 0,
      micro: 300000,
      nano: 0,
      ismegaLocked: false,
      ismacroLocked: false,
      ismicroLocked: false,
      isnanoLocked: false,
      locations: [],
      influencerLocations: [],
      influencerCountries: [],
      influencerState: [],
      influencerCity: [],
      influencerZone: [],
      genderGroupType: '',
      genderGroupPercentage: 0,
      showdAvanceFilter: false,
      ageGroup: [],
      objective: 'Reach',
      budget: 300000,
      pricePerCreator: 30000,
      category: [],
      rate: 0,
      audienceCredibility: 0,
      interest_selection: [],
      avg_engagement: 0,
      ppp: 0,
      avg_views: 0,
      gender: '',
      isEmail: '',
      isPhone: '',
      is_audience_data:'',
      brand_final_price: '',
      influencer_final_price: '',
      keywords: '',
      bio_keyword:'',
      deliverables: [],
      languageAdvance: [],
      influencerAge: [],
      endDate: null,
      startDate: null,
      isEdit: true,
      isFirstTime: true,
      priority_list: [
        { name: 'Speed', id: 1 },
        { name: 'Price', id: 2 },
        { name: 'Impact', id: 3 },
      ],
    });
    this.props.setReset(true);
    this.props.getPlannerInfluencersData(payload);
  }

  setList(list) {
    this.setState({ priority_list: list });
  }

  render() {
    const {
      classes,
      selectedObjective,
      setSelectedObjective,
      selectedFilter,
      setSelectedFilter,
      setShowModal,
    } = this.props;

    let {
      isEdit,
      isFirstTime,
      channels,
      nano,
      micro,
      macro,
      mega,
      locations,
      genderGroupType,
      genderGroupPercentage,
      ageGroup,
      startDate,
      endDate,
      rate,
      audienceCredibility,
      languageAdvance,
      influencerAge,
      influencerLocations,
      influencerCountries,
      influencerState,
      influencerCity,
      influencerZone,
      gender,
      isEmail,
      isPhone,
      is_audience_data,
      ppp,
      avg_views,
      brand_final_price,
      influencer_final_price,
      keywords,
      bio_keyword,
      deliverables,
      avg_engagement,
      interest_selection,
      priority_list,
    } = this.state;

    let languageAdvanceMap = [];
    languageAdvance.map((each) => {
      languageAdvanceMap.push(each.label);
    });
    let influencerAgeMap = [];
    influencerAge.map((each) => {
      influencerAgeMap.push(each.label);
    });
    let influencerLocationsMap = [];
    influencerLocations.map((each) => {
      influencerLocationsMap.push(each.label);
    });
    let influencerCountriesMap = [];
    influencerCountries.map((each) => {
      influencerCountriesMap.push(each.label);
    });

    let influencerStateMap = [];
    influencerState.map((each) => {
      influencerStateMap.push(each.label);
    });

    let influencerCityMap = [];
    influencerState.map((each) => {
      influencerCityMap.push(each.label);
    });
    let influencerZoneMap = [];
    influencerZone.map((each) => {
      influencerZoneMap.push(each.label);
    });
    let audienceInterest = [];
    interest_selection.map((each) => {
      audienceInterest.push(each.label);
    });

    let priorityMap = [];
    priority_list.map((each) => {
      priorityMap.push(each.name.substr(0, 1));
    });
    let deliverablesMap = [];
    deliverables.map((each) => {
      deliverablesMap.push(each.label);
    });

    let creator = [];
    if (mega > 0) {
      creator.push('mega');
    }
    if (macro > 0) {
      creator.push('macro');
    }
    if (micro > 0) {
      creator.push('micro');
    }
    if (nano > 0) {
      creator.push('nano');
    }

    return (
      <Grid container className={classes.root}>
        <Grid item style={{ marginRight: 20 }}>
          <Grid container className={classes.objective}>
            {
              // isEdit ?
              //  (
              <div style={{ padding: 20 }}>
                <Category
                  {...(selectedObjective == 'Category' ? { selected: true } : null)}
                  setSelectedObjective={setSelectedObjective}
                  category={this.state.category}
                  setFilters={this.setObjectives}
                />
                <Typography className={classes.optionValue}>
                  {this.state.category.slice(0, 3).join(',') || '-'}

                  {this.state.category.length > 3 ? (
                    <Tooltip
                      title={
                        <>
                          {this.state.category.slice(3).map((key, i) => (
                            <div>
                              <b>{key}</b>
                            </div>
                          ))}
                        </>
                      }
                      arrow
                      placement="right"
                    >
                      <p className={classes.listValue}>...</p>
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </Typography>
              </div>
              // )
              //  : (
              //   <div style={{ padding: 20 }}>
              //     <Typography className={classes.ObjectiveOption}>
              //       Category
              //     </Typography>
              //     <Typography className={classes.optionValue}>
              //       {this.state.category.join(",") || "-"}
              //     </Typography>
              //   </div>
              // )
            }
            {['Objective', 'Category'].indexOf(selectedObjective) > -1 ? null : (
              <div className={classes.objectiveDivider}></div>
            )}
            {
              // isEdit ?
              // (
              <div style={{ padding: 20 }}>
                <Objective
                  {...(selectedObjective == 'Objective' ? { selected: true } : null)}
                  setSelectedObjective={setSelectedObjective}
                  objective={this.state.objective}
                  setFilters={this.setObjectives}
                />
                <Typography className={classes.optionValue}>
                  {this.state.objective || '-'}
                </Typography>
              </div>
              // )
              // : (
              //   <div style={{ padding: 20 }}>
              //     <Typography className={classes.ObjectiveOption}>
              //       Objective
              //     </Typography>
              //     <Typography className={classes.optionValue}>
              //       {this.state.objective || "-"}
              //     </Typography>
              //   </div>
              // )
            }
            {['Objective', 'Budget'].indexOf(selectedObjective) > -1 ? null : (
              <div className={classes.objectiveDivider}></div>
            )}
            {
              // isEdit ? (
              <div style={{ padding: 20 }}>
                <FilterSlider
                  {...(selectedObjective == 'Budget' ? { selected: true } : null)}
                  setSelectedObjective={setSelectedObjective}
                  currValue={this.state.budget}
                  setFilters={this.setObjectives}
                  maxValue={50000000}
                  minValue={100000}
                  step={50000}
                  filter="budget"
                  objective="Budget"
                  title="Budget"
                />
                <Typography className={classes.optionValue}>₹{this.state.budget}</Typography>
              </div>
              // )
              // : (
              //   <div style={{ padding: 20 }}>
              //     <Typography className={classes.ObjectiveOption}>
              //       Budget
              //     </Typography>
              //     <Typography className={classes.optionValue}>
              //       ₹{this.state.budget}
              //     </Typography>
              //   </div>
              // )
            }
            {['Priority', 'Budget'].indexOf(selectedObjective) > -1 ? null : (
              <div className={classes.objectiveDivider}></div>
            )}
            {
              // isEdit ? (
              <div style={{ padding: 20 }}>
                <Priority
                  list={this.state.priority_list}
                  setList={this.setList}
                  {...(selectedObjective == 'Priority' ? { selected: true } : null)}
                  setSelectedObjective={setSelectedObjective}
                />
                <Typography className={classes.optionValue}>{priorityMap.join(' - ')}</Typography>
              </div>
              // )
              // : (
              //   <div style={{ padding: 20 }}>
              //     <Typography className={classes.ObjectiveOption}>
              //       Priority
              //     </Typography>
              //     <Typography className={classes.optionValue}>
              //       {priorityMap.join(" - ")}
              //     </Typography>
              //   </div>
              // )
            }
            {['Priority', 'PricePerCreator'].indexOf(selectedObjective) > -1 ? null : (
              <div className={classes.objectiveDivider}></div>
            )}
            {
              // isEdit ? (
              <div style={{ padding: 20 }}>
                <FilterSlider
                  {...(selectedObjective == 'PricePerCreator' ? { selected: true } : null)}
                  setSelectedObjective={setSelectedObjective}
                  currValue={this.state.pricePerCreator}
                  setFilters={this.setObjectives}
                  currBudget={this.state.budget}
                  maxValue={1000000}
                  minValue={0}
                  step={1000}
                  filter="pricePerCreator"
                  // objective={selectedObjective}
                  objective="PricePerCreator"
                  title="max Price Per Creator"
                  // errMsg="Budget cannot exceed 5Cr."
                />
                <Typography className={classes.optionValue}>
                  ₹{this.state.pricePerCreator}
                </Typography>
              </div>
              // )
              // : (
              //   <div style={{ padding: 20 }}>
              //     <Typography className={classes.ObjectiveOption}>
              //       Price Per Creator
              //     </Typography>
              //     <Typography className={classes.optionValue}>
              //       ₹{this.state.pricePerCreator}
              //     </Typography>
              //   </div>
              // )
            }
          </Grid>
        </Grid>
        <Grid item>
          {
            // isEdit ? (
            <Button
              sx={{ boxShadow: 5 }}
              className={classes.createPlan}
              onClick={() => {
                this.applyFilter();
                this.props.setReset(false);
                this.setState({ isFirstTime: false });
                setSelectedFilter('');
              }}
              disabled={this.props.isLoading}
            >
              {' '}
              {isFirstTime == true ? 'Create Plan' : 'Apply'}
            </Button>
            // ) : (
            //   <Typography
            //     style={{
            //       cursor: "pointer",
            //       color: "#757575",
            //       fontFamily: "Nunito Sans",
            //       fontWeight: 400,
            //       fontSize: 16,
            //     }}
            //     onClick={() => {
            //       this.setState({ isEdit: true, isFirstTime: false });
            //     }}
            //   >
            //     <img
            //       src={Edit}
            //       width={18}
            //       style={{ marginRight: 5, cursor: "pointer" }}
            //     />
            //     Edit Plan
            //   </Typography>
            // )
          }
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              this.resetFilter();
            }}
            style={{ position: 'relative', left: '15px' }}
            disabled={this.props.isLoading}
          >
            Reset
          </Button>
        </Grid>
        {
          // isEdit ? (
          <Grid container item md={12} style={{ marginTop: '25px' }} className={classes.filters}>
            <div
              style={{ flex: 1 }}
              onClick={() => {
                setSelectedFilter(selectedFilter == 'Channel' ? '' : 'Channel');
              }}
            >
              <ParticularFilter selected={selectedFilter == 'Channel'}>Channel</ParticularFilter>
              <Typography style={{ paddingRight: '10px' }} className={classes.optionValue}>
                {channels.join(' | ')}
              </Typography>
            </div>
            <div
              style={{ flex: 1 }}
              onClick={() => {
                setSelectedFilter(selectedFilter == 'CreatorsType' ? '' : 'CreatorsType');
              }}
            >
              <ParticularFilter selected={selectedFilter == 'CreatorsType'}>
                Creators Type
              </ParticularFilter>
              {macro > 0 || micro > 0 || nano > 0 || mega || 0 ? (
                <Typography className={classes.optionValue}>{creator.join(' | ')}</Typography>
              ) : null}
            </div>
            <div
              style={{ flex: 1 }}
              onClick={() => {
                setSelectedFilter(selectedFilter == 'AudienceLocation' ? '' : 'AudienceLocation');
              }}
            >
              <ParticularFilter selected={selectedFilter == 'AudienceLocation'}>
                Audience Location
              </ParticularFilter>
              {locations.length > 0 ? (
                <Typography className={classes.optionValue}>
                  {this.state.locations.slice(0, 3).join(',') || '-'}

                  {this.state.locations.length > 3 ? (
                    <Tooltip
                      title={
                        <>
                          {this.state.locations.slice(3).map((key, i) => (
                            <div>
                              <b>{key}</b>
                            </div>
                          ))}
                        </>
                      }
                      arrow
                      placement="right"
                    >
                      <p className={classes.listValue}>...</p>
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </Typography>
              ) : null}
            </div>
            <div
              style={{ flex: 1 }}
              onClick={() => {
                setSelectedFilter(
                  selectedFilter == 'AudienceGenderSplit' ? '' : 'AudienceGenderSplit',
                );
              }}
            >
              <ParticularFilter selected={selectedFilter == 'AudienceGenderSplit'}>
                Audience Gender Split
              </ParticularFilter>
              {genderGroupType.length > 0 ? (
                <Typography className={classes.optionValue}>
                  {genderGroupType} - {genderGroupPercentage}%
                </Typography>
              ) : null}
            </div>
            <div
              style={{ flex: 1 }}
              onClick={() => {
                setSelectedFilter(selectedFilter == 'AudienceAgeGroup' ? '' : 'AudienceAgeGroup');
              }}
            >
              <ParticularFilter selected={selectedFilter == 'AudienceAgeGroup'}>
                Audience Age Group
              </ParticularFilter>
              {ageGroup.length > 0 ? (
                <Typography className={classes.optionValue}>{ageGroup.join(' | ')}</Typography>
              ) : null}
            </div>
            <div style={{ marginRight: 10, position: 'relative' }}>
              {this.state.showdAvanceFilter == true ? (
                <AdvanceFilterWidget
                  rate={this.state.rate}
                  interest_selection={this.state.interest_selection}
                  avg_engagement={this.state.avg_engagement}
                  ppp={this.state.ppp}
                  avg_views={this.state.avg_views}
                  audienceCredibility={this.state.audienceCredibility}
                  gender={this.state.gender}
                  isEmail={this.state.isEmail}
                  isPhone={this.state.isPhone}
                  is_audience_data={this.state.is_audience_data}
                  languageAdvance={this.state.languageAdvance}
                  influencerAge={this.state.influencerAge}
                  influencerLocations={this.state.influencerLocations}
                  influencerCountries={this.state.influencerCountries}
                  influencerState={this.state.influencerState}
                  influencerCity={this.state.influencerCity}
                  influencerZone={this.state.influencerZone}
                  brand_final_price={brand_final_price}
                  influencer_final_price={influencer_final_price}
                  keywords={keywords}
                  bio_keyword={bio_keyword}
                  deliverables={deliverables}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  hideAdvanceFilters={this.setFilters}
                  setShowModal={this.props.setShowModal}
                  isStaff={this.props.isStaff}
                />
              ) : null}
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowModal(true);
                  this.setState({ showdAvanceFilter: true });
                }}
              >
                <img src={AdvanceFilter} height={40} width={40} />
              </div>
            </div>
          </Grid>
          // ) : null
        }
        {/* {!isEdit ? (
          <Grid
            container
            item
            md={12}
            className={classes.filters}
            style={{
              flexWrap: "wrap",
              padding: "10px",
              justifyContent: "space-evenly",
            }}
          >
            {channels.length > 0 ? (
              <div>
                <ParticularFilter>Channel</ParticularFilter>
                <Typography className={classes.optionValue}>
                  {channels.join(" | ")}
                </Typography>
              </div>
            ) : null}
            {macro > 0 || micro > 0 || nano > 0 || mega || 0 ? (
              <div>
                <ParticularFilter>Creators Type</ParticularFilter>
                <Typography className={classes.optionValue}>
                  {mega > 0 ? "mega " : " "}
                  {macro > 0 ? "macro " : " "}
                  {micro > 0 ? "micro " : " "}
                  {nano > 0 ? "nano " : " "}
                </Typography>
              </div>
            ) : null}
            {locations.length > 0 ? (
              <div>
                <ParticularFilter>Audience Location</ParticularFilter>
                <Typography className={classes.optionValue}>
                  {locations.join(" | ")}
                </Typography>
              </div>
            ) : null}
            {genderGroupType.length > 0 ? (
              <div>
                <ParticularFilter>Audience Gender Split</ParticularFilter>
                <Typography className={classes.optionValue}>
                  {genderGroupType} - {genderGroupPercentage}%
                </Typography>
              </div>
            ) : null}
            {ageGroup.length > 0 ? (
              <div>
                <ParticularFilter>Audience Age Group</ParticularFilter>
                <Typography className={classes.optionValue}>
                  {ageGroup.join(" | ")}
                </Typography>
              </div>
            ) : null}
            <IconButton
              onClick={() => {
                this.setState({ openAdFilter: !this.state.openAdFilter });
              }}
              style={{ position: "relative" }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Grid>
        ) : null}

        {!isEdit && this.state.openAdFilter ? (
          <Grid
            container
            item
            md={12}
            className={classes.filters}
            style={{
              flexWrap: "wrap",
              padding: "10px",
              justifyContent: "space-evenly",
            }}
          >
            {startDate || endDate ? (
              <div>
                <ParticularFilter>Date</ParticularFilter>
                <Typography className={classes.optionValue}>
                  {startDate ? moment(startDate).format("YYYY-MM-DD") : ""} -{" "}
                  {endDate ? moment(endDate).format("YYYY-MM-DD") : ""}
                </Typography>
              </div>
            ) : null}
            {rate > 0 ? (
              <div>
                <ParticularFilter>Engagement Rate</ParticularFilter>
                <Typography className={classes.optionValue}>{rate}</Typography>
              </div>
            ) : null}
            {audienceCredibility > 0 ? (
              <div>
                <ParticularFilter>Auedience Credibility</ParticularFilter>
                <Typography className={classes.optionValue}>
                  {audienceCredibility}
                </Typography>
              </div>
            ) : null}
            {gender.length > 0 ? (
              <div>
                <ParticularFilter>Influencer Gender</ParticularFilter>
                <Typography className={classes.optionValue}>
                  {gender}
                </Typography>
              </div>
            ) : null}
            {audienceInterest.length > 0 ? (
              <div>
                <ParticularFilter>Audience Interest</ParticularFilter>
                <Typography className={classes.optionValue}>
                  {audienceInterest.join(" | ")}
                </Typography>
              </div>
            ) : null}
            {avg_engagement > 0 ? (
              <div>
                <ParticularFilter>Average Engaged Users</ParticularFilter>
                <Typography className={classes.optionValue}>
                  {avg_engagement}
                </Typography>
              </div>
            ) : null}
            {ppp > 0 ? (
              <div>
                <ParticularFilter>Paid Post Performance</ParticularFilter>
                <Typography className={classes.optionValue}>{ppp}</Typography>
              </div>
            ) : null}
            {languageAdvanceMap.length > 0 ? (
              <div>
                <ParticularFilter>Language</ParticularFilter>
                <Typography className={classes.optionValue}>
                  {languageAdvanceMap.join(" | ")}
                </Typography>
              </div>
            ) : null}
            {influencerAgeMap.length > 0 ? (
              <div>
                <ParticularFilter>Influencer Age Group</ParticularFilter>
                <Typography className={classes.optionValue}>
                  {influencerAgeMap.join(" | ")}
                </Typography>
              </div>
            ) : null}
            {influencerLocationsMap.length > 0 ? (
              <div>
                <ParticularFilter>Influencer Location</ParticularFilter>
                <Typography className={classes.optionValue}>
                  {influencerLocationsMap.join(" | ")}
                </Typography>
              </div>
            ) : null}
          </Grid>
        ) : null} */}

        {
          // isEdit ? (
          <Grid container item md={12} className={classes.filters}>
            {selectedFilter == 'Channel' ? (
              <Channel channels={this.state.channels} setChannels={this.setChannels} />
            ) : null}
            {selectedFilter == 'CreatorsType' ? (
              <Grid container item md={12}>
                <div style={{ flex: 1 }}></div>
                <CreatorsType
                  mega={this.state.mega}
                  macro={this.state.macro}
                  micro={this.state.micro}
                  nano={this.state.nano}
                  isDisabled={this.state.isDisabled}
                  setInfluencerTypeLock={this.setInfluencerTypeLock}
                  setCelebsType={this.setCelebsType}
                  budget={this.state.budget}
                />
                <div style={{ flex: 1, marginRight: 50 }}></div>
              </Grid>
            ) : null}
            {selectedFilter == 'AudienceLocation' ? (
              <Grid container item md={12}>
                <div style={{ flex: 2 }}></div>
                <AudienceLocation locations={this.state.locations} setLocations={this.setFilters} />
              </Grid>
            ) : null}
            {selectedFilter == 'AudienceGenderSplit' ? (
              <Grid container item md={12}>
                <div style={{ flex: 3 }}></div>
                <AudienceGenderSplit
                  genderGroupType={this.state.genderGroupType}
                  genderGroupPercentage={this.state.genderGroupPercentage}
                  setGenderGroup={this.setFilters}
                />
              </Grid>
            ) : null}
            {selectedFilter == 'AudienceAgeGroup' ? (
              <Grid container item md={12} style={{ justifyContent: 'flex-end' }}>
                <AudienceAgeGroup ageGroup={this.state.ageGroup} setAgeGroup={this.setFilters} />
              </Grid>
            ) : null}
          </Grid>
          // ) : null
        }
      </Grid>
    );
  }
}

const styles = {
  root: {
    background:
      'linear-gradient(91.68deg, rgba(239, 26, 116, 0.057) 11.95%, rgba(254, 189, 28, 0.1) 68.66%)',
    boxShadow: '4px 4px 20px 2px rgba(172, 172, 172, 0.25)',
    borderRadius: '10px',
    margin: 10,
    marginLeft: 0,
    paddingTop: 30,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30,
  },
  objective: {
    background: '#FFFFFF',
    boxShadow:
      '-4px -5px 20px rgba(226, 226, 226, 0.25), 7px 6px 15px 3px rgba(207, 207, 207, 0.25)',
    borderRadius: '40px',
    alignItems: 'center',
  },
  objectiveDivider: {
    background: '#E9E9E9',
    height: '29px',
    width: '1px',
  },
  createPlan: {
    textTransform: 'none',
    width: 150,
    height: 40,
    fontWeight: 700,
    background: '#FEBD1C',
    borderRadius: '10px',
    color: 'white',
    padding: 10,
    fontSize: 15,
    marginLeft: 20,
    paddingLeft: 20,
    paddingRight: 20,
    filter: 'drop-shadow(5px 7px 7px rgba(186, 186, 186, 0.25))',
  },
  filters: {
    marginTop: 0,
    marginLeft: 70,
    alignItems: 'flex-start',
  },
  ObjectiveOption: {
    cursor: 'pointer',
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: '0.03em',
  },
  optionValue: {
    color: '#757575',
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 11,
  },
  listValue: {
    color: '#757575',
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 11,
    margin: 0,
    padding: 0,
    width: '10px',
    display: 'inline-block',
  },
};

const mapStateToProps = (state) => {
  return {
    filters: state.discovery.filters,
    searchedSelectedInfluencerIds: state.planner.searchedSelectedInfluencerIds,
  };
};

const actionCreators = {
  setPayload: updatePayload,
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(Filters));

// export default withStyles(styles)(Filters)
