import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseCircleSharp from '@mui/icons-material/PauseCircleSharp';

const styles = (theme) => ({
});

class ImageValidator extends React.Component {

    constructor(props) {
        super(props)

        this.showVideoTag    = this.showVideoTag.bind(this);
        this.showPlaceHolder = this.showPlaceHolder.bind(this);

        this.state = {
            showOriginalImage: true,
            showVideo: false,
            pause: false
        }

        this.vidRef = React.createRef();
        this.imgfRef = React.createRef();
    }

    showVideoTag(env) {
        this.setState({ showOriginalImage: false, showVideo: true });
    }

    showPlaceHolder(env) {
        this.setState({ showVideo: false });
    }

    render() {
        const { className, url, altText, children, placeContainerHolderStyle, showControls,showOnlyPlay } = this.props;
        const { showOriginalImage, showVideo } = this.state;

        if (url && url.length > 0 && showOriginalImage) {
            return <img onError={this.showVideoTag} ref={this.imgfRef} className={className} src={url} alt={altText || ""} />
        } else if (showVideo && showControls) {
            return <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}><video ref={this.vidRef} className={className} onError={this.showPlaceHolder}>
                <source src={url} />
            </video>
            <span 
                style={{ position: 'absolute', left: '50%', top: '50%', color: 'white', cursor: 'pointer', transform: 'translate(-50%, -50%)'}}
                onClick={(e)=>{
                    if (this.state.pause) {
                        this.vidRef.current.pause();
                    } else {
                        this.vidRef.current.play();
                    }
                    this.setState({ pause: !this.state.pause })
                }}> {this.state.pause ? <PauseCircleSharp style={{ transform: 'scale(1.5)' }}/> : <PlayArrowIcon style={{ transform: 'scale(1.5)' }}/>}</span>
            </div>
        }
        else if (showVideo && showOnlyPlay) {
            return <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}><video ref={this.vidRef} className={className} onError={this.showPlaceHolder}>
                <source src={url} />
            </video>
            <span 
                style={{ position: 'absolute', left: '50%', top: '50%', color: 'white', cursor: 'pointer', transform: 'translate(-50%, -50%)'}}
                > <PlayArrowIcon style={{ transform: 'scale(1.5)' }}/></span>
            </div>
        }  
        
        else if (showVideo) {
            return <video className={className} onError={this.showPlaceHolder}>
                <source src={url} />
            </video>
        }

        return (
            <div style={placeContainerHolderStyle || {}}>
                {children}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(ImageValidator));
