import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MinMaxInput from 'components/Theme/Elements/MinMaxInput';

import Filter from '../Filter';

import FollowersIcon from 'assets/discovery/followers-icon.png';

function FollowersFilter({ value = [null, null], setFilters }) {
  const classes = useStyles();
  console.log('values', value);

  function handleFilter(values) {
    setFilters({
      followers: values,
    });
  }

  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Filter label={'Followers'} labelIconSrc={FollowersIcon}>
          <Grid container className={classes.filterContainer}>
            <MinMaxInput value={value} onChange={handleFilter} />
          </Grid>
        </Filter>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {},
}));

export default FollowersFilter;
