import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { AppBar, Box, Button, Grid, Switch as SwitchRadio, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';

import { campaignActions } from '../../actions/campaign.js';

import SearchIcon from '../../assets/planner/search_icon.png';

import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ToggleButton } from '@mui/material';

import AllInfluencersContent from './ContentApproval/AllInfluencersContent.jsx';
import ContentPending from './ContentApproval/ContentPending.jsx';
import ContentApproved from './ContentApproval/ContentApproved.jsx';
import ContentRejected from './ContentApproval/ContentRejected.jsx';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

class ContentApproval extends React.Component {
  constructor(props) {
    super(props);
    this.campaign_id = this.props.match.params.campaignId;
    this.countertostring = this.countertostring.bind(this);
    this.state = {
        selectedTab: 0,
        selectedIndex: -1,
        sortBy: '',
        keyword: '',
        cascade: 'cascade',
        selectedName: '',
        applied_influencer: [
            {
                status: 'pending',
                content_status: '',
                id: 1,
                name: 'Anuv Jain',
                description: "Samsung has announced the Galaxy Z Flip 5 Retro Edition. It is designed to pay homage to Samsung's iconic flip phone that was launched in 2003, the SGH-E700, the first Samsung phone with an internal antenna and OLED cover screen. This Retro Edition features the same color combination that was used on the iconic flip phone. The phone will only be available in some countries. #GalaxyZFlip #SamsungZFlip #MotorolaRazr #MotorolaRazr5G #DualCamera #Ultrawide #HuaweiP50Pocket #MotorolaRazr2022 #FoldablePhone #GalaxyZFlip5 #12MP #OppoFindN3Flip #SamsungZFlip5 #OLED #PixelFold #VivoXFlip #MotorolaRazr40Ultra #MotoRazr40Ultra #TecnoPhantomVFlip #ZFlip5",
                avatar: 'https://influencer-media.s3.ap-south-1.amazonaws.com/1591516710',
                video: 'https://influencer-media.s3.ap-south-1.amazonaws.com/Cy_RES0v-t4'
            },
            {
                status: 'approved',
                content_status: '',
                id: 2,
                description: "#Collab My mini accessory 🌟 #JoinTheFlipSide #GalaxyZFlip5 #TeamGalaxy samsungindia",
                name: '🔥Deep Mitra🔥',
                avatar: 'https://influencer-media.s3.ap-south-1.amazonaws.com/1412571707',
                video: 'https://influencer-media.s3.ap-south-1.amazonaws.com/Cy_HnziPjke'
            },
            {
                status: 'approved',
                content_status: 'approved',
                id: 3,
                description: "Reach for the Moon with #Samsung #GalaxyZFlip5 - Where cutting-edge technology meets the celestial beauty. Join the flip side with endless possibilities under the Moonlight sky🌙✨ #collaboration #JoinTheFlipSide #WithGalaxy samsungindia . . . #captured #withgalaxy #samsungs23ultra #moon #karvachauth #karva #karvachauthspecial",
                name: 'Anuv Jain',
                avatar: 'https://influencer-media.s3.ap-south-1.amazonaws.com/8281606351',
                video: 'https://influencer-media.s3.ap-south-1.amazonaws.com/Cy_F2T9ROP2'
            },
            {
                status: 'approved',
                content_status: 'rejected',
                id: 4,
                description: "Unfold Your Imagination ✨ #collaboration #JoinTheFlipSide #GalaxyZFlip5 #TeamGalaxy @samsungindia #prabirdasphotography",
                name: 'Anuv Jain',
                avatar: 'https://influencer-media.s3.ap-south-1.amazonaws.com/5712272710',
                video: 'https://influencer-media.s3.ap-south-1.amazonaws.com/Cy_AMTotWwY'
            },
            {
                status: 'approved',
                content_status: 'pending',
                id: 5,
                description: "Il segreto di #SUGA dei bts.bighitofficial? Flexcam! #GalaxyZFlip5! #JoinTheFlipSide #GakaxyxSUGA Scopri di più su: www.samsung.com",
                name: '🔥Deep Mitra🔥',
                avatar: 'https://influencer-media.s3.ap-south-1.amazonaws.com/412772765',
                video: 'https://influencer-media.s3.ap-south-1.amazonaws.com/Cy-9wgSyipY'
            },
            {
                status: 'pending',
                content_status: '',
                id: 6,
                description: "his diwali get your hands on cutest and the most flexible phone ever🤌🏽 #GalaxyZFlip5 & #GalaxyZFold5 today! #JoinTheFlipSide #Samsung shot by vishal_kolhe2499 edited by 🙋🏻‍♀️ Inframe tanyathevar Location mobile_expertise Ground floor , City Centre mall, Nashik. Work for samsungindia",
                name: '🔥Deep Mitra🔥',
                avatar: 'https://influencer-media.s3.ap-south-1.amazonaws.com/2984168045',
                video: 'https://influencer-media.s3.ap-south-1.amazonaws.com/Cy-rYNPNJcT'
            },
            {
                status: 'pending',
                content_status: '',
                id: 7,
                description: "Amamos la versatilidad de la cámara del Galaxy Z Flip5. ¿Has usado la cámara trasera para tus selfies? #SamsungGuatemala #GalaxyZFlip5",
                name: 'Abhijit Halder',
                avatar: 'https://influencer-media.s3.ap-south-1.amazonaws.com/ajaydevgn',
                video: 'https://influencer-media.s3.ap-south-1.amazonaws.com/Cy78YxMP3kX'
            },
            {
                status: 'pending',
                content_status: '',
                id: 8,
                description: `Time Magazine has chosen the Galaxy Z Flip 5 as among the best inventions of 2023. The phone is among the other 14 products that also have been chosen in the Consumer Electronic category. Here's why they chose the Z Flip 5 as one of the best inventions this year: "Cell phones are addictive by design, but sometimes you just want to check an email without getting sucked into apps. The Samsung Galaxy Z Flip5's key feature, its Flex Window, fixes that. The flip phone has a 3.4-in. external screen, next to its back-facing lenses, that allows you to dip into important notifications without being lured in" #GalaxyZFlip #SamsungZFlip #MotorolaRazr #MotorolaRazr5G #DualCamera #Ultrawide #HuaweiP50Pocket #MotorolaRazr2022 #FoldablePhone #GalaxyZFlip5 #12MP #OppoFindN3Flip #SamsungZFlip5 #OLED #PixelFold #VivoXFlip #MotorolaRazr40Ultra #MotoRazr40Ultra #TecnoPhantomVFlip #ZFlip5 #PhoneAccessories #PhoneCaseShop`,
                name: 'Abhijit Halder',
                avatar: 'https://influencer-media.s3.ap-south-1.amazonaws.com/UCZSNzBgFub_WWil6TOTYwAg',
                video: 'https://influencer-media.s3.ap-south-1.amazonaws.com/Cyyq17WBzQ4'
            },
            {
                status: 'pending',
                content_status: '',
                id: 9,
                description: `#collaboration Elevate your Tech game with Samsung galaxy . #jointheflipside #GalaxyZFlip5 #TeamGalaxy samsungindia`,
                name: 'Abhijit Halder',
                avatar: 'https://influencer-media.s3.ap-south-1.amazonaws.com/499648201',
                video: 'https://influencer-media.s3.ap-south-1.amazonaws.com/Cy8MVp-RyLm'
            },
            {
                status: 'pending',
                content_status: '',
                id: 10,
                description: `Colorea tu vida con tu tono favorito #GalaxyZFlip5 #ÚneteAlLadoFlip`,
                name: '𝐘𝐚𝐬𝐡𝐮',
                avatar: 'https://influencer-media.s3.ap-south-1.amazonaws.com/393971628',
                video: 'https://influencer-media.s3.ap-south-1.amazonaws.com/Cy5jNmpNb_-'
            },
            {
                status: 'pending',
                content_status: '',
                id: 11,
                description: `Scopri i colori ispirati alla natura. #GalaxyZFlip5`,
                name: '𝐘𝐚𝐬𝐡𝐮',
                avatar: 'https://influencer-media.s3.ap-south-1.amazonaws.com/1723040704',
                video: "https://influencer-media.s3.ap-south-1.amazonaws.com/Cy0ZdcVPUsm"
            },
            {
                status: 'pending',
                content_status: '',
                id: 12,
                description: `Elegante, compacto y versátil. Viste tu personalidad con lo nuevo. #GalaxyZFlip5`,
                name: '𝐘𝐚𝐬𝐡𝐮',
                avatar: 'https://influencer-media.s3.ap-south-1.amazonaws.com/3080791853',
                video: 'https://influencer-media.s3.ap-south-1.amazonaws.com/Cy04agNBcCq'
            }
        ],
        content_pending: [
            {
                status: 'pending',
                name: '𝐘𝐚𝐬𝐡𝐮',
                avatar: 'https://influencer-media.s3.ap-south-1.amazonaws.com/1723040704'
            },
            {
                status: 'pending',
                name: 'Anuv Jain',
                avatar: 'https://influencer-media.s3.ap-south-1.amazonaws.com/3080791853'
            }
        ]
    }
  }

  componentDidMount() {
    this.props.getInfluencers(this.campaign_id);
    
  }

  countertostring(counter) {
    counter = parseFloat(counter);
    if (counter >= 1000 && counter < 999999) {
      return (
        parseFloat(counter / 1000.0)
          .toFixed(2)
          .toString() + 'K'
      );
    } else if (counter > 999999) {
      return (
        parseFloat(counter / 1000000.0)
          .toFixed(2)
          .toString() + 'M'
      );
    } else {
      return counter.toString();
    }
  }

  render() {
    let { selectedTab, selectedIndex, sortBy, keyword, applied_influencer, content_pending, cascade, selectedName } = this.state;

    let influencers_list = [];

    if (selectedTab == 2) {
        applied_influencer.map((each, index)=>{
            if (sortBy == 'pending' && each.content_status == 'pending') {
                influencers_list.push(each);  
            } else if (sortBy == 'approved' && each.content_status == 'approved') {
                influencers_list.push(each);  
            } else if (sortBy == 'rejected' && each.content_status == 'rejected') {
                influencers_list.push(each);  
            }
        })
    
        applied_influencer.map((each, index)=>{
            if (sortBy == 'pending' && each.content_status != 'pending') {
                influencers_list.push(each);  
            } else if (sortBy == 'approved' && each.content_status != 'approved') {
                influencers_list.push(each);  
            } else if (sortBy == 'rejected' && each.content_status != 'rejected') {
                influencers_list.push(each);  
            }
        })
    } else {
        applied_influencer.map((each, index)=>{
            if (sortBy == 'pending' && each.status == 'pending') {
                influencers_list.push(each);  
            } else if (sortBy == 'approved' && each.status == 'approved') {
                influencers_list.push(each);  
            } else if (sortBy == 'rejected' && each.status == 'rejected') {
                influencers_list.push(each);  
            }
        })
    
        applied_influencer.map((each, index)=>{
            if (sortBy == 'pending' && each.status != 'pending') {
                influencers_list.push(each);  
            } else if (sortBy == 'approved' && each.status != 'approved') {
                influencers_list.push(each);  
            } else if (sortBy == 'rejected' && each.status != 'rejected') {
                influencers_list.push(each);  
            }
        }) 
    }

    let influencers_list_name = [];
    let influencers_list_image = [];

    influencers_list.map(each=>{
        if (influencers_list_name.indexOf(each.name) == -1) {
            influencers_list_name.push(each.name);
            influencers_list_image.push(each.avatar)
        }
    })

    return (
      <Grid container justify="center" style={{ marginTop: 0 }}>
          <Grid item md={10}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ToggleButtonGroup
                    defaultChecked={true}
                    value={cascade}
                    exclusive
                    onChange={(event, value)=>{
                        this.setState({ cascade: value })
                    }}
                    aria-label="Cascade"
                    >
                    <ToggleButton value="cascade" aria-label="Cascade View" style={{ fontSize: 12 }}>
                        <span>Cascade View</span>
                    </ToggleButton>
                    <ToggleButton value="tile" aria-label="Tile View" style={{ fontSize: 12 }}>
                        <span>Tile View</span>
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
        </Grid>
        <Grid item md={12}>
            <AppBar
                position="static"
                indicatorColor="primary"
                style={{
                  backgroundColor: '#F3F3F3',
                  color: '#333',
                  display: 'block',
                  marginTop: '20px',
                  marginBottom: '30px',
                }}>
                <Tabs 
                    value={selectedTab}
                    onChange={(event, value) => {
                        if (value >= 0) {
                            this.setState({ selectedTab: value })
                        }
                    }}
                    aria-label="simple tabs content">
                    <Tab label="All Influencers" id="all-influencers" />
                    <Tab label="Content Pending" id="content-pending" />
                    <Tab label="Content Submitted" id="content-submitted" />
                    <Tab label="Content Approved" id="content-approval" />
                    <Tab label="Content Rejected" id="content-rejected" />
                </Tabs>
            </AppBar>
        </Grid>
        <Grid container md={12} alignItems='center'>
            {
                (selectedTab == 0 || selectedTab == 2) ?
                <Grid md={8}>
                    <div style={{ border: '1px solid #c6c6c6', borderRadius: 5, width: 250, padding: 5, position: 'relative' }}>
                        <TextField 
                            placeholder='Search By Name'
                            InputProps={{
                                disableUnderline: true,
                                inputProps: {
                                style: { textJustify: "center", paddingTop: 1 },
                            }
                            }}
                            onChange={(evt) => this.setState({ keyword: evt.target.value })}
                        />
                        <img 
                            src={SearchIcon} 
                            style={{
                                width: 20,
                                position: 'absolute',
                                left: 10,
                            }}/>
                    </div>
                </Grid> : null
            }
            {
                (selectedTab == 0 || selectedTab == 2) && cascade == 'cascade' ?
                <Grid md={4} container justify='flex-start' style={{ paddingRight: 10 }} alignItems='center'>
                    <div>Sort By</div>
                    <div 
                        style={{ background: (sortBy == 'pending' ? '#FEBD1C' : 'white'), color: (sortBy == 'pending' ? 'white' : 'black'),padding: 10, borderRadius: 5, cursor: 'pointer', marginLeft: 10 }}
                        onClick={(e)=>{
                            if (sortBy == 'pending') {
                                this.setState({ sortBy: '' })
                            } else {
                                this.setState({ sortBy: 'pending' })
                            }
                        }}>Pending</div>
                    <div 
                        style={{ background: (sortBy == 'approved' ? '#FEBD1C' : 'white'), color: (sortBy == 'approved' ? 'white' : 'black'),padding: 10, borderRadius: 5, cursor: 'pointer', marginLeft: 10 }}
                        onClick={(e)=>{
                            if (sortBy == 'approved') {
                                this.setState({ sortBy: '' })
                            } else {
                                this.setState({ sortBy: 'approved' })
                            }
                        }}>Approved</div>
                    <div 
                        style={{ background: (sortBy == 'rejected' ? '#FEBD1C' : 'white'), color: (sortBy == 'rejected' ? 'white' : 'black'), padding: 10, borderRadius: 5, cursor: 'pointer', marginLeft: 10 }}
                        onClick={(e)=>{
                            if (sortBy == 'rejected') {
                                this.setState({ sortBy: '' })
                            } else {
                                this.setState({ sortBy: 'rejected' })
                            }
                        }}>Rejected</div>
                </Grid> : null 
            }
        </Grid>
        <TabPanel value={selectedTab} index={0}>
            <AllInfluencersContent 
                cascade={cascade}
                sortBy={sortBy}
                keyword={keyword}
                showPending={true}
                campaign_id={this.campaign_id}/>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
            <ContentPending
                campaign_id={this.campaign_id}/>
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
            <AllInfluencersContent 
                cascade={cascade}
                sortBy={sortBy}
                keyword={keyword}
                campaign_id={this.campaign_id}/>
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
            <ContentApproved
                campaign_id={this.campaign_id}/>
        </TabPanel>
        <TabPanel value={selectedTab} index={4}>
            <ContentRejected
                campaign_id={this.campaign_id}/>
        </TabPanel>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
    influencers: state.campaign.influencers,
});

const actionCreators = {
    getInfluencers: campaignActions.getInfluencers,
};

export default connect(mapStateToProps, actionCreators)(withRouter(ContentApproval));
