import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

function countertostring(counter) {
  counter = parseFloat(counter);
  if (counter >= 1000 && counter < 999999) {
    return (
      parseFloat(counter / 1000.0)
        .toFixed(2)
        .toString() + 'K'
    );
  } else if (counter > 999999) {
    return (
      parseFloat(counter / 1000000.0)
        .toFixed(2)
        .toString() + 'M'
    );
  } else {
    return counter.toString();
  }
}

const CreatorInfoCard = (props) => {
  let {
    classes,
    creator,
    likes_count,
    comments_count,
    reach,
    engagement__rate,
    shortcode,
    followers_count,
    view_count,
    fair_price,
    category_name,
  } = props;

  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item md style={{ maxWidth: '6.0%' }} className={classes.moreWidth}>
        <div className={classes.subtitles}>
          <Typography
            style={{ paddingTop: 10, fontWeight: 600, fontSize: '0.7rem', color: '#4A4A4A' }}
          >
            <a
              style={{
                color: '#4A4A4A',
                fontWeight: 600,
                fontSize: '0.7rem',
                textDecoration: 'none',
              }}
              href={`https://www.instagram.com/${creator}/`}
              target="_blank"
            >
              {creator}
            </a>
          </Typography>
        </div>
      </Grid>
      <Grid item md style={{ maxWidth: '6.0%' }} className={classes.moreWidth}>
        <div className={classes.subtitles}>
          <Typography style={{ paddingTop: 10, fontWeight: 600, fontSize: '0.7rem' }}>
            <a
              style={{
                color: '#4A4A4A',
                fontWeight: 600,
                fontSize: '0.7rem',
                textDecoration: 'none',
              }}
              href={`https://www.instagram.com/p/${shortcode}/`}
              target="_blank"
            >
              Post Link
            </a>
          </Typography>
        </div>
      </Grid>
      <Grid item md style={{ maxWidth: '4.0%' }} className={classes.moreWidth}>
        <div className={classes.subtitles}>
          <Typography style={{ paddingTop: 10, fontWeight: 600, fontSize: '0.7rem' }}>
          {category_name}
          </Typography>
        </div>
      </Grid>
      <Grid item md style={{ maxWidth: '6.0%' }} className={classes.moreWidth}>
        <div className={classes.subtitles}>
          <Typography style={{ paddingTop: 10, fontWeight: 600, fontSize: '0.7rem' }}>
            {countertostring(view_count)}
          </Typography>
        </div>
      </Grid>
      <Grid item md style={{ maxWidth: '6.0%' }} className={classes.moreWidth}>
        <div className={classes.subtitles}>
          <Typography style={{ paddingTop: 10, fontWeight: 600, fontSize: '0.7rem' }}>
            {/* {countertostring(likes_count)} */}
            {countertostring(likes_count)}
          </Typography>
        </div>
      </Grid>
      <Grid item md style={{ maxWidth: '7.5%' }} className={classes.moreWidth}>
        <div className={classes.subtitles}>
          <Typography style={{ paddingTop: 10, fontWeight: 600, fontSize: '0.7rem' }}>
            {countertostring(comments_count)}
          </Typography>
        </div>
      </Grid>
      <Grid item md style={{ maxWidth: '0%' }} className={classes.moreWidth}>
        <div className={classes.engRate}>
          <Typography style={{ paddingTop: 10, fontWeight: 600, fontSize: '0.7rem' }}>
            {parseFloat(engagement__rate).toFixed(2)}%
          </Typography>
        </div>
      </Grid>
      <Grid item md style={{ maxWidth: '20.0%' }} className={classes.moreWidth}>
        <div className={classes.subtitles}>
          <Typography style={{ paddingTop: 10, fontWeight: 600, fontSize: '0.7rem' }}>
            {fair_price}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

const styles = {
  root: {
    background: '#FFFFFF',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 224, 0.2), 5px -5px 10px rgba(215, 216, 224, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 224, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 224, 0.5)',
    borderRadius: 10,
    flexGrow: 1,
    height: '90px',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    padding: 10,
  },
  moreWidth: {
    paddingLeft: '24px',
    maxWidth: '6.5%',
  },
  subtitles: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    color: '#4A4A4A',
    fontSize: 12,
    marginRight: 0,
    lineHeight: '0.1px',
    width: 100,
  },
  reach: {
    background: '#FFFFFF',
    height: 42,
    maxWidth: 64,
    boxShadow: '4px 4px 4px rgba(206, 206, 206, 0.25)',
    color: '#FC2E2E',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.6,
    borderRadius: 6,
  },
  engRate: {
    background: '#FFFFFF',
    height: 42,
    maxWidth: 64,
    textAlign: 'center',
    flex: 1,
    marginTop: 0,
    boxShadow: '4px 4px 4px rgba(206, 206, 206, 0.25)',
    color: '#6CBF02',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.6,
    borderRadius: 6,
  },
  creator: {
    background: 'linear-gradient(118.02deg, rgba(239, 26, 116, 0.57) 32.63%, #FEBD1C 94.6%)',
    boxShadow: '2px 5px 10px rgba(143, 143, 143, 0.25)',
    borderRadius: 10,
    height: 40,
    width: 130,
    justifyContent: 'space-around',
    alignItems: 'center',
    marginRight: 50,
  },
};

export default withStyles(styles)(CreatorInfoCard);
