import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function ObjectiveOption({ selected, children }) {
  const classes = useStyles();
  return (
    <Typography className={selected ? classes.selected : classes.ObjectiveOption}>
      {children}
    </Typography>
  );
}

const useStyles = makeStyles(() => ({
  selected: {
    padding: 20,
    paddingLeft: 25,
    paddingRight: 25,
    background: '#FFFFFF',
    boxShadow: '6px 4px 20px -3px rgba(34, 34, 34, 0.25)',
    borderRadius: '41px',
    cursor: 'pointer',
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: '0.03em',
    zIndex: 5,
    position: 'relative',
  },
  ObjectiveOption: {
    padding: 10,
    paddingBottom: 1,
    paddingTop: 0,
    cursor: 'pointer',
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    // fontSize: 13,
    fontSize: 13,
    letterSpacing: '0.03em',
    // zIndex: 2,
    position: 'relative',
  },
}));

export default ObjectiveOption;
