import React from 'react';

import Grid from '@mui/material/Grid';

import { gridSpacing } from 'store/constant';

import SelectFilter from './SelectFilter';
import TextFilter from './TextFilter';


const InternalTeamFilters = () => {


  return (
    <Grid container spacing={gridSpacing} sx={{height: 300, overflowY: 'scroll'}}>
      {/* Influencer Final Price Filter */}
      <Grid item xs={12}>
        <TextFilter title={'Influencer Final Price'} payloadKey='influencerFinalPrice'  />
      </Grid>

      {/* Brand Final Price Filter */}
      <Grid item xs={12}>
        <TextFilter title={'Brand Final Price'} payloadKey='brandFinalPrice'/>
      </Grid>

      <Grid item xs={12}>
        <SelectFilter title='Email' payloadKey={'isEmail'}
          selectOptions={[{value: 'yes', label: 'Yes'}, {value: 'no', label: 'No'}]}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectFilter title='Phone' payloadKey={'isPhone'}
          selectOptions={[{value: 'yes', label: 'Yes'}, {value: 'no', label: 'No'}]}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectFilter title='Audience Data Available' payloadKey={'isAudienceData'}
          selectOptions={[{value: 'yes', label: 'Yes'}, {value: 'no', label: 'No'}]}
        />
      </Grid>
    </Grid>
  )
}

export default InternalTeamFilters;