import { Button, Container, Grid, TextField } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Loading from '../../components/Common/Loading/Loading';
import FacebookLogin from '../../components/auth/FacebookLogin';
import YoutubeLogin from '../../components/auth/YoutubeLogin';
import ErrorDisplay from '../influencers/forms/ErrorDisplay';

import { authHandlers } from '../../api_handlers/auth';
import { colorConstants } from '../../constants/colors';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      user_type: this.props.brand ? 'brand' : 'influencer',
      formErrors: {},
      is_form_error: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.brand !== this.props.brand) {
      this.setState({ user_type: this.props.brand ? 'brand' : 'influencer' });
    }
  }
  checkUserState() {
    if (
      localStorage.getItem('access') &&
      JSON.parse(localStorage.getItem('user')).user_type == 'brand'
    ) {
      this.props.history.push(
        `/brand/dashboard/${
          JSON.parse(localStorage.getItem('user')).brand.id === 260 ? 'instagram' : 'youtube'
        }`,
      );
    } else if (
      localStorage.getItem('access') &&
      JSON.parse(localStorage.getItem('user')).user_type == 'influencer'
    ) {
      this.props.history.push('/influencer/campaigns');
    }
  }

  async registerToBackendFacebook(data) {
    const user = await authHandlers.facebookLogin(data);
    if (user) this.props.history.push('/influencer/campaigns');
  }

  async registerToBackendYoutube(data) {
    const user = await authHandlers.youtubeLogin(data);
    if (user) this.props.history.push('/influencer/campaigns');
  }

  componentDidMount() {
    this.checkUserState();
  }

  login() {
    const resp = authHandlers.login({
      email: this.state.email,
      password: this.state.password,
      user_type: this.state.user_type,
    });

    resp.then(async (response) => {
      if ([200, 201].includes(response.status)) {
        const userData = await response.json();
        localStorage.setItem('access', userData.access);
        localStorage.setItem('refresh', userData.refresh);
        const user = {
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          user_type: userData.user_type,
          influencer: userData.influencer,
          brand: userData.brand,
        };
        localStorage.setItem('user', JSON.stringify(user));
        this.checkUserState();
      } else if ([400, 401, 402, 403].includes(response.status)) {
        const errors = await response.json();
        this.setState({
          formErrors: errors,
          is_form_error: true,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.props.loading && <Loading />}
        {this.state.user_type === 'influencer' ? (
          <Grid
            container
            style={{ marginTop: 20 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container>
                <FacebookLogin
                  label="Log in With Facebook"
                  onSuccess={(response) => {
                    this.registerToBackendFacebook(response);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container>
                <YoutubeLogin
                  label="Sign in with Google"
                  onSuccess={(response) => {
                    this.registerToBackendYoutube(response);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {this.state.user_type === 'influencer' ? <Grid style={{ marginTop: 10 }}>or</Grid> : ''}
        <Container className={'login-form'}>
          {this.state.is_form_error && (
            <ErrorDisplay errors={this.state.formErrors} backendKeyMap={{}} />
          )}
          <form>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email ID"
              name="email"
              autoComplete="email"
              onChange={(e) => this.setState({ email: e.target.value })}
              autoFocus
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{
                backgroundColor: colorConstants.LOGIN_BUTTON,
                marginBottom: '1vw',
                marginTop: '2vw',
                color: 'white',
              }}
              onClick={(e) => {
                e.preventDefault();
                this.login();
              }}
            >
              Login
            </Button>
          </form>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(withRouter(Login));
