import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Filter from '../Filter';

import LocationIcon from 'assets/discovery/Location-Icon.png';
import MultiSelect from 'components/Theme/Elements/MultiSelect';

function AudienceLocationFilter({ value, setFilters, locations }) {
  const classes = useStyles();

  function handleFilter(event, selectedValues) {
    setFilters({
      audienceLocation: selectedValues,
    });
  }

  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Filter label={'Audience Location'} labelIconSrc={LocationIcon}>
          <Grid container className={classes.filterContainer}>
            <MultiSelect
              options={locations.map((location) => ({ label: location, value: location }))}
              value={value || []}
              onChange={handleFilter}
            />
          </Grid>
        </Filter>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {},
  filterContainer: {},
}));

export default AudienceLocationFilter;
