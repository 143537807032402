import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function CategoryChip({ label, color, borderColor }) {
  const classes = useStyles({ color, borderColor });
  return <Chip label={label} variant="outlined" className={classes.root} />;
}

const useStyles = makeStyles(() => ({
  root: ({ color, borderColor }) => ({
    fontFamily: 'Nunito Sans',
    background: '#FFFFFF',
    borderRadius: 34,
    borderWidth: 1,
    borderColor: borderColor,
    color: color,
    fontSize: 12,
    fontWeight: 400,
    cursor: 'pointer',
  }),
}));

export default CategoryChip;
