import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import GoogleLogin from 'react-google-login';

import { Grid, Button } from '@material-ui/core';

import YouTubeIcon from '@material-ui/icons/YouTube';

const clientId = '517815126133-54u2240mtpg7t640v3d69l668q46dun9.apps.googleusercontent.com';

class YoutubeLogin extends Component {
  constructor(props) {
    super(props);
  }

  onSuccess(res) {
    this.props.onSuccess(res);
  }

  onFailure(res) {}

  getScope() {
    const scopes = [
      'email',
      'profile',
      'https://www.googleapis.com/auth/youtube.readonly',
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/userinfo.email',
      'openid',
    ];

    if (this.props.userType === 'brand')
      scopes.push('https://www.googleapis.com/auth/analytics.readonly');

    return scopes.join(' ');
  }

  render() {
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          cursor: 'pointer',
          margin: '5px',
        }}
      >
        <GoogleLogin
          clientId={clientId}
          buttonText={this.props.label}
          onSuccess={(resp) => {
            this.onSuccess(resp);
          }}
          onFailure={this.onFailure}
          cookiePolicy={'single_host_origin'}
          isSignedIn={false}
          scope={this.getScope()}
          responseType={'code'}
          accessType={'offline'}
          prompt={'consent'}
          // render={renderProps => (
          //     <Button
          //         style={{
          //             background: '#E63B5A',
          //             borderRadius: '3px',
          //             color: 'white',
          //             fontSize: 13,
          //             textTransform: 'none'
          //         }}
          //         onClick={renderProps.onClick}
          //         disabled={this.props.is_linked}
          //     >
          //         <YouTubeIcon />{this.props.label}
          //     </Button>
          //   )}
        ></GoogleLogin>
      </Grid>
    );
  }
}

export default withRouter(YoutubeLogin);
