import { Link } from 'react-router-dom';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import AuthWrapper1 from './AuthWrapper1';
import AuthCardWrapper from './AuthCardWrapper';
import InfluencerLogo from '../../assets/influencer-logo.png';
import AuthResetPassword from './authentication/auth-forms/AuthResetPassword';

const ResetPassword = ({ goToLogin, uid, token }) => {
  const downMD = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <AuthWrapper1>
      <div 
        style={{ 
        zIndex: 0,
        position: 'fixed',   
        backgroundImage: `url(https://influencer-media.s3.ap-south-1.amazonaws.com/signup_web_new_1.jpeg)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: '100vh',
        width: '60vw',
        left: 0,
        right: 0 }} />
      <Grid container direction="column" justifyContent={ window.matchMedia('(min-width: 768px)').matches ? "flex-end" : "center" } sx={{ minHeight: '100vh'}}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
            { window.matchMedia('(min-width: 768px)').matches ? <div style={{ flex: 1 }} /> : null }
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }} style={{ height: window.matchMedia('(min-width: 768px)').matches ? '100vh' : '95%', zIndex: 1, background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', width: window.matchMedia('(min-width: 768px)').matches ? 'auto' : '95%', overflowY: 'scroll' }}>
              <AuthCardWrapper>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item sx={{ mb: 0 }}>
                    <Link to="#" aria-label="logo">
                        <img
                        style={{ marginLeft: '-10px' }}
                        src={InfluencerLogo}
                        alt="influencer-logo"
                        height={'30px'}
                        ></img>
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction={{ xs: 'column-reverse', md: 'row' }} alignItems="center" justifyContent="center">
                      <Grid item>
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                          <Typography color="secondary.main" gutterBottom variant={downMD ? 'h3' : 'h2'}>
                            Reset password?
                          </Typography>
                          <Typography variant="caption" fontSize="16px" textAlign={{ xs: 'center', md: 'inherit' }}>
                            Enter your new password.
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container alignContent={'center'} justifyContent={'center'}>
                    <div style={{ width: '450px' }}>
                      <AuthResetPassword             
                        uid={uid}
                        token={token}/>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid 
                        style={{ cursor: 'pointer' }}
                        item container direction="column" alignItems="center" xs={12}
                        onClick={()=>{
                            goToLogin();
                        }}>
                      <Typography variant="subtitle1" sx={{ textDecoration: 'none' }}>
                        Already have an account?
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper1>
  );
};

export default ResetPassword;