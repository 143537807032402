import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Filter from '../Filter';

import ChannelIcon from 'assets/discovery/channel-icon.png';
import InstagramIcon from 'assets/discovery/insta-icon.png';
import YouTubeIcon from 'assets/discovery/youtube-icon.png';

function PlatformFilter({ value, setFilters }) {
  function handleFilter(platform) {
    setFilters({
      platform,
    });
  }

  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item md={12}>
        <Filter label={'Channel'} labelIconSrc={ChannelIcon}>
          <Grid container className={classes.filterContainer}>
            <Grid item md={'auto'}>
              <Grid container className={classes.filter} onClick={() => handleFilter('instagram')}>
                <Grid item className={classes.iconContainer}>
                  <img src={InstagramIcon} alt="icon" className={classes.icon} />
                </Grid>
                <Grid item>
                  <Typography variant="body2">Instagram</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={'auto'}>
              <Grid container className={classes.filter} onClick={() => handleFilter('youtube')}>
                <Grid item className={classes.iconContainer}>
                  <img src={YouTubeIcon} alt="icon" className={classes.icon} />
                </Grid>
                <Grid item>
                  <Typography variant="body2">YouTube</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Filter>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {},
  filterContainer: {
    background: '#EFF0F9',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 224, 0.2), 5px -5px 10px rgba(215, 216, 224, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 224, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 224, 0.5)',
    borderRadius: 10,
    padding: '15px 10px',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  filter: {
    background: 'linear-gradient(135deg, #E3E4EA 0%, #FBFCFF 100%)',
    boxShadow:
      '-2px 2px 4px rgba(227, 228, 233, 0.2), 2px -2px 4px rgba(227, 228, 233, 0.2), -2px -2px 4px rgba(251, 252, 255, 0.9), 2px 2px 5px rgba(227, 228, 233, 0.9), inset 1px 1px 2px rgba(251, 252, 255, 0.3), inset -1px -1px 2px rgba(227, 228, 233, 0.5)',
    borderRadius: 39,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px 12px 6px 6px',
    cursor: 'pointer',
  },
  iconContainer: {
    background: '#EFF0F9',
    boxShadow:
      '-1px 1px 2px rgba(227, 228, 237, 0.2), 1px -1px 2px rgba(227, 228, 237, 0.2), -1px -1px 2px rgba(251, 252, 255, 0.9), 1px 1px 3px rgba(227, 228, 237, 0.9), inset 1px 1px 2px rgba(251, 252, 255, 0.3), inset -1px -1px 2px rgba(227, 228, 237, 0.5)',
    padding: 8,
    borderRadius: '50%',
    marginRight: 4,
  },
  icon: {
    height: 15,
    width: 15,
  },
}));

export default PlatformFilter;
