import { authConstants } from '../constants/auth';
import { authHandlers } from '../api_handlers/auth';

export const authActions = {
  login,
  signUp,
  storeUserData,
};

function login(userData) {
  return (dispatch) => {
    dispatch(request());

    return authHandlers.login(userData).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        dispatch(failure());
      },
    );
  };

  function request() {
    return { type: authConstants.LOGIN_REQUEST };
  }
  function success(user) {
    return { type: authConstants.LOGIN_SUCCESS, user };
  }
  function failure() {
    return { type: authConstants.LOGIN_FAILURE };
  }
}

function signUp(userData) {
  return (dispatch) => {
    dispatch(request());
    authHandlers.signUp(userData).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        dispatch(failure());
      },
    );
  };

  function request() {
    return { type: authConstants.SIGN_UP_REQUEST };
  }
  function success(user) {
    return { type: authConstants.SIGN_UP_SUCCESS, user };
  }
  function failure() {
    return { type: authConstants.SIGN_UP_FAILURE };
  }
}

function storeUserData(userData) {
  return (dispatch) => {
    if (userData) dispatch(success(userData));
    else dispatch(failure());
  };

  function success(user) {
    return { type: authConstants.STORE_USER_DATA, user };
  }
  function failure() {
    return { type: authConstants.SIGN_UP_FAILURE };
  }
}
