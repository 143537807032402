import { Component } from 'react';
import { Button, Modal, Grid, TextField } from '@material-ui/core';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Loading from '../../../components/Common/Loading/Loading';

import MobileEngagementStatistics from './cards/MobileEngagementStatistics';
import MobileStatistics from './cards/youtube/MobileStatistics';
import YoutubePostCardMobile from './cards/youtube/YoutubePostCardMobile';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import InstagramLogo from '../../../assets/instagram_logo_gray.png';
import YoutubeLogo from '../../../assets/youtube-logo.png';

import { NavLink } from 'react-router-dom';

import { colorConstants } from '../../../constants/colors';
import { campaignHandlers } from '../../../api_handlers/campaign';
import { downloadFile } from '../../../api_handlers/index';
import { getInfluencerYoutubeStatistic } from '../../../api_handlers/reporting';

const linkStyle = {
  padding: '10px 15px',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  background: '#F2F2F2',
  marginRight: '20px',
};

class Feed extends Component {
  constructor(props) {
    super(props);

    this.formatCount = this.formatCount.bind(this);

    this.state = {
      modalOpen: false,
      info_modalOpen: false,
      message: null,
      is_loading: false,
      user_type: JSON.parse(localStorage.getItem('user')).user_type,
      stats: null,
      addPostModalOpen: false,
      selectedFeedTab: 0,
    };
  }

  formatCount(count) {
    if (typeof count === 'string' && count.length > 0) {
      const lastChar = count[count.length - 1].toLocaleUpperCase();
      if (['B', 'M', 'K'].includes(lastChar)) return count;
    }

    var num;
    const parsedCount = parseFloat(count);
    if (parsedCount <= 1) {
      return parsedCount.toFixed(2);
    } else if (parsedCount > 9999 && parsedCount <= 999999) {
      num = parsedCount / 1000;
      if (num % 1 != 0) {
        num = num.toFixed(2);
      }
      return num + 'K';
    } else if (parsedCount > 999999) {
      num = parsedCount / 1000000;
      if (num % 1 != 0) {
        num = num.toFixed(2);
      }
      return num + 'M';
    } else if (parsedCount > 999999999) {
      num = parsedCount / 1000000000;
      if (num % 1 != 0) {
        num = num.toFixed(2);
      }
      return num + 'B';
    } else if (parsedCount === NaN) {
      return count;
    } else if (parsedCount && count % 1 != 0) {
      return count.toFixed(2);
    } else {
      return count;
    }
  }

  async getData() {
    const campaign_id = this.props.match.params.campaignId;
    const influencer_id = this.props.match.params.influencerId;
    const startDate = this.state.startDate
      ? moment(this.state.startDate).format('YYYY-MM-DD')
      : null;
    const endDate = this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : null;
    const resp = await getInfluencerYoutubeStatistic(
      campaign_id,
      influencer_id,
      startDate,
      endDate,
    );
    this.setState({ stats: resp.data });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen });

  downloadReport() {
    downloadFile(`/campaign/all/report/influencer/youtube/feed/download`, {
      influencer_id: this.props.match.params.influencerId,
      start_date: this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : null,
      end_date: this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : null,
    });
  }

  async addPostLink(url) {
    const post_data = {
      action_type: 'youtube',
      campaign_id: this.props.match.params.campaignId,
      influencer_id: this.props.match.params.influencerId,
      url,
    };
    await campaignHandlers.addPostLink(post_data).then((r) => {
      this.setState({
        info_modalOpen: true,
        message: r.message,
      });
    });
    if (this.state.message == 'Updated') {
      window.location.reload();
    }
  }

  render() {
    return (
      <div style={{ background: 'white', paddingTop: '20px' }}>
        {this.state.is_loading && <Loading />}
        <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'flex-start' }}>
          <NavLink
            to={`/influencer/campaigns/${this.props.match.params.campaignId}/feeds/${this.props.match.params.influencerId}/instagram`}
            style={linkStyle}
          >
            <img src={InstagramLogo} height={'20'} width={'20'} />
          </NavLink>
          <span style={linkStyle}>
            <img src={YoutubeLogo} height={'18'} width={'25'} />
          </span>
        </div>
        <div style={{ width: '100%' }}>
          <MobileEngagementStatistics
            campaign_id={this.props.match.params.campaignId}
            influencer_id={this.props.match.params.influencerId}
            stats={this.state.stats}
            formatCount={this.formatCount}
          />
          <MobileStatistics stats={this.state.stats} formatCount={this.formatCount} />
          <div
            style={{
              width: 'calc(100% - 50px)',
              margin: '10px 10px',
              padding: '5px 15px',
              background: '#FFFFFF',
              boxShadow: '2px 5px 10px rgba(139, 139, 139, 0.25)',
              borderRadius: '5px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <div>
              <span style={{ fontSize: '12px' }}>Filter by:</span>
            </div>
            <div>
              <Button
                variant="contained"
                endIcon={<CloudDownloadIcon />}
                style={{
                  fontSize: '12px',
                  textTransform: 'none',
                  backgroundColor: '#444',
                  color: 'white',
                  marginRight: '5px',
                }}
                onClick={() => {
                  this.downloadReport();
                }}
              >
                Report
              </Button>
              <Button
                variant="contained"
                style={{
                  fontSize: '12px',
                  color: 'white',
                  backgroundColor: '#1E9CB2',
                }}
                onClick={this.toggleModal}
              >
                Add Post
              </Button>
              <div>
                <Modal
                  open={this.state.modalOpen}
                  onClose={this.toggleModal}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <div
                    style={{
                      backgroundColor: 'white',
                      width: '90%',
                      transform: 'translate(5%, 40vh)',
                      paddingLeft: '10px',
                      borderRadius: '15px',
                      boxShadow:
                        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                    }}
                    align={'left'}
                  >
                    <h3 style={{ paddingTop: '10px' }}>Add Post</h3>
                    <hr style={{ width: '99%' }} />
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        Paste your Youtube video URL to add it to the Campaign
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        style={{ padding: '10px' }}
                      >
                        <Grid item md={2} alignItems="flex-end">
                          <p>URL:</p>
                        </Grid>
                        <Grid item md={12}>
                          <TextField
                            id="post_url"
                            style={{ margin: 8 }}
                            placeholder="Paste URL"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <br />
                    <div style={{ paddingBottom: '18px', paddingTop: '20px' }} align={'center'}>
                      <Button
                        variant="outlined"
                        style={{
                          color: colorConstants.TEXT_BLUE,
                          borderColor: colorConstants.TEXT_BLUE,
                          marginRight: '10px',
                        }}
                        onClick={this.toggleModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: colorConstants.TEXT_BLUE,
                          color: 'white',
                        }}
                        onClick={() => this.addPostLink(document.getElementById('post_url').value)}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  value={this.state.startDate}
                  autoOk={true}
                  onChange={(value) => {
                    this.setState({ startDate: value });
                    this.youtubePostCards.reloadData();
                    this.getData();
                  }}
                  InputLabelProps={{ shrink: false }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  style={{
                    background: '#F9F9F9',
                    width: '130px',
                  }}
                  InputProps={{
                    style: {
                      fontSize: 13,
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
              <span style={{ margin: '0px 10px' }}>to</span>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  value={this.state.endDate}
                  autoOk={true}
                  onChange={(value) => {
                    this.setState({ endDate: value });
                    this.youtubePostCards.reloadData();
                    this.getData();
                  }}
                  InputLabelProps={{ shrink: false }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  style={{
                    background: '#F9F9F9',
                    width: '130px',
                  }}
                  InputProps={{
                    style: {
                      fontSize: 13,
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <YoutubePostCardMobile
            campaignId={this.props.match.params.campaignId}
            influencerId={this.props.match.params.influencerId}
            onRef={(ref) => (this.youtubePostCards = ref)}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
          />
        </div>
      </div>
    );
  }
}

export default Feed;
