import React, { useEffect } from 'react';

// material-ui
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import PlannerCard from './PlannerCard';

// assets
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import { fetchPlanList, createPlan } from 'api_handlers/planner2';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';
// import { useParams } from 'react-router-dom';

const PlannerList = () => {
    const [plannerItems, setPlannerItems] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [platform, setPlatform] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openCreatePlan, setOpenCreatePlan] = React.useState(false);
    const [planName, setPlanName] = React.useState('');
    const [tags, setTags] = React.useState('');
    const [createPlatform, setCreatePlatform] = React.useState('all');
    const [errors, setErrors] = React.useState({ planName: 'List Name is required' });
    const location = useLocation();
    const user = useSelector((state) => state.planner2.user);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleSearch = async (event) => {
        const newString = event?.target.value;
        // if (newString) {
        setSearch(newString);
        // }

        // if (newString) {
        //     // dispatch(filterProfileCards(newString));
        // } else {
        //     // dispatch(getProfileCards());
        // }
    };
    const fetchAndSetPlannerList = async () => {
        try {
            const response = await fetchPlanList(user.brand.id, platform, search, page, rowsPerPage);
            console.log("P_list_response: ", response)
            const plansData = response.results.plans_lists;
            setPlannerItems(
                Object.keys(plansData).map((key) => {
                let data = plansData[key];
                data['name'] = key;
                return data;
                })
            );
        } catch (error) {
            console.error("Error fetching planner list:", error);
        }
    };

    useEffect(() => {
        
        fetchAndSetPlannerList();
    }, [platform, search, page, rowsPerPage, user]);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.textContent, 10));
        setPage(1);
    };

    const paginatedItems = plannerItems.slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
    );

    const handleCreatePlan = () => {
        if (process.env.NODE_ENV === 'production') {
            mixpanel.track("collab_list_create_list")
        }
        setOpenCreatePlan(true);
    };

    const handleCloseModal = () => {
        setOpenCreatePlan(false);
    };

    const handleSubmitPlan = async () => {
        if (!planName.trim()) {
            setErrors({ planName: 'List Name is required' });
            return;
        }

        try {
            const response = await createPlan({
                name: planName,
                brand_id: user.brand.id,
                tags: tags,
                platform: createPlatform,
            });

            if (response.status === 201) {
                if (process.env.NODE_ENV === 'production') {
                    mixpanel.track("collab_list_created", {
                        name: planName,
                        brand_id: user.brand.id,
                        tags: tags,
                        platform: createPlatform,
                        source: 'collab_list'
                    })
                }
                setPlanName('');
                setTags('');
                setCreatePlatform('all');
                setOpenCreatePlan(false);
                fetchAndSetPlannerList();
            } else {
                console.error('Error creating plan');
            }
        } catch (error) {
            console.error('Error submitting plan:', error);
        }
    };

    return (
        <MainCard
            sx={{ 
                '& .MuiCardHeader-root': {
                    height: 70
                }
             }}
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Collab List</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <FormControl sx={{ m: 1, minWidth: 180 }} size='small'>
                                    <InputLabel id="platform-select">Platform</InputLabel>
                                    <Select
                                        labelId="platform-select"
                                        id="platform"
                                        name="platform"
                                        value={platform}
                                        onChange={(e) => {
                                            const selectedPlatform = e.target.value;
                                            // Check if the selected value is the same as the current platform, and reset if so
                                            setPlatform((prevPlatform) => (prevPlatform === selectedPlatform ? '' : selectedPlatform));
                                          }}
                                        label="Platform"
                                    >
                                        <MenuItem value={'all'}>All Platforms</MenuItem>
                                        <MenuItem value={'instagram'}>Instagram</MenuItem>
                                        <MenuItem value={'youtube'}>YouTube</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                            <FormControl sx={{ m: 1, minWidth: 100 }} size='small'>
                                <OutlinedInput
                                    id="input-search-card-style3"
                                    placeholder="Search by List"
                                    value={search}
                                    onChange={handleSearch}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon stroke={1.5} size="16px" />
                                        </InputAdornment>
                                    }
                                    size="small"
                                />
                                </FormControl>
                            </Grid>

                            <Grid item>
                                <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={handleCreatePlan}
                                >
                                    Create List
                                </Button>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            }
        >
            <Grid container direction="row" spacing={gridSpacing}>
                {paginatedItems.map((item, index) => (
                    <Grid key={index} item xs={12} sm={6} lg={4} xl={3}>
                        <PlannerCard {...item} brandName={user?.influencer?.name.trim() ? user.influencer.name : user.email} />
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination
                                count={Math.ceil(plannerItems.length / rowsPerPage)}
                                color="primary"
                                page={page}
                                onChange={handlePageChange}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="text"
                                size="large"
                                sx={{ color: 'grey.900' }}
                                color="secondary"
                                endIcon={<ExpandMoreRoundedIcon />}
                                onClick={handleClick}
                            >
                                {rowsPerPage} Rows
                            </Button>
                            <Menu
                                id="menu-user-card-style3"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem onClick={handleRowsPerPageChange}>10 Rows</MenuItem>
                                <MenuItem onClick={handleRowsPerPageChange}>20 Rows</MenuItem>
                                <MenuItem onClick={handleRowsPerPageChange}>30 Rows</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Dialog open={openCreatePlan} onClose={handleCloseModal} fullWidth>
                <DialogTitle>Create List</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        label="List Name"
                        fullWidth
                        required
                        value={planName}
                        onChange={(e) => setPlanName(e.target.value)}
                        error={Boolean(errors.planName)}
                        helperText={errors.planName}
                        margin="normal"
                    />
                    <TextField
                        label="Platform"
                        fullWidth
                        select
                        value={createPlatform}
                        onChange={(e) => setCreatePlatform(e.target.value)}
                        margin="normal"
                    >
                        <MenuItem value="instagram">Instagram</MenuItem>
                        <MenuItem value="youtube">YouTube</MenuItem>
                        <MenuItem value="all">All</MenuItem>
                    </TextField>
                    <TextField
                        label="Tags"
                        fullWidth
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                        margin="normal"
                    />
                    <Typography 
                        variant="body2" 
                        color="textSecondary" 
                        sx={{ marginTop: 2, textAlign: 'start'  }}
                    >
                        Note:- Please add influencers from Discover
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="contained" 
                        onClick={handleCloseModal} 
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitPlan}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </MainCard>
    );
};

export default PlannerList;
