import React, { Component } from 'react';
import {Box, AppBar, Toolbar, Container} from '@mui/material';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import ThemeCustomization from 'themes';
import { createTheme } from '@mui/material/styles';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import MainContentStyled from './MainContentStyled';
import DiscoverInfluencer from './components/InfluencerDiscover';
import CuratedList from './components/CuratedList';
import CuratedInfluencers from './components/CuratedInfluencers';
import InfluencerInsight from './components/InfluencerInsight';
import PremiunFeature from './components/PremiunFeature';
import InsightFrame from './components/InfluencerInsight/Insightframe';
import PlannerList from './components/PlannerList';
import config, { MenuOrientation } from 'config'
import { updateUser } from 'actions/planner2';
import 'assets/scss/style.scss';
import PlanInfluencers from './components/PlanInfluencerList'


const {borderRadius, container, menuOrientation} = config
const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL
const theme = createTheme();

class Planner extends Component {

  componentDidMount() {
    const { pathname } = this.props.location;
    const collabPattern = new RegExp(`/planner/collab-list/[^/]+/influencers/[^/]+`);
    const accessToken = localStorage.getItem('access')
    if(accessToken === null){
      if (!collabPattern.test(pathname)) {
        this.props.updateUser();
      }
    }else{
      this.props.updateUser();
    }
  }

  render() {
    console.log("Index Route User: ", this.props)
    // Destructure state
    const { drawerOpen } = this.props;
    console.log('props',this.props)
    return (
      <ThemeCustomization>
        <Box sx={{ display: 'flex' }}>
            <AppBar enableColorOnDark position="fixed" color="inherit" elevation={0} sx={{ bgcolor: 'background.default' }}>
                <Toolbar sx={{ p: isHorizontal ? 1.25 : 2 }}>
                    <Header /> 
                </Toolbar>
            </AppBar>
            <Sidebar />

            <MainContentStyled {...{ borderRadius, menuOrientation, open: drawerOpen, theme }}>
              <Container maxWidth={container ? 'lg' : false} {...(!container && { sx: { px: { xs: 0 } } })}>
                <Switch>
                  <Route path={`${this.props.match.path}/discover`} component={DiscoverInfluencer}/>
                  <Route path={`${this.props.match.path}/curated-list/:curatedItemId`} component={CuratedInfluencers } />
                  <Route path={`${this.props.match.path}/curated-list`} component={CuratedList } />
                  <Route path={`/planner/collab-list/:listName/influencers/:planUUID`} component={ PlanInfluencers } />
                  <Route path={`${this.props.match.path}/collab-list`} component={PlannerList } />
                  <Route path={`${this.props.match.path}/insight/:id`} component={InfluencerInsight}/>
                  <Route path={`/planner/profile-details/:platform/:handle`} component={InsightFrame}></Route>

                  
                </Switch>
                
                {/* <QuickFilters
                  updateFilters={this.updateFilters}
                /> */}
              </Container>
            </MainContentStyled>
        </Box>
        <PremiunFeature />
      </ThemeCustomization>
    );
  }
}

const mapStateToProps = (state) => ({
  drawerOpen: state.planner2.drawerOpen,
  user : state.planner2.user,
});

const plannerActions = {
  updateUser
};

// export default Planner;
export default connect(mapStateToProps, plannerActions)(Planner);