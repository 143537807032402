import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';

class StyledNavLink extends React.Component {
  render() {
    return (
      <NavLink
        to={this.props.to}
        style={{
          textDecoration: 'none',
          lineHeight: '17px',
          margin: '5px',
          color: 'black',
        }}
        activeStyle={{
          fontWeight: 'bold',
          color: '#1E9CB2',
        }}
      >
        {this.props.label}{' '}
        {this.props.show_count ? (this.props.count ? '(' + this.props.count + ')' : '(0)') : ''}
      </NavLink>
    );
  }
}

export default withRouter(StyledNavLink);
