import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { Grid, Container, Divider } from '@material-ui/core';

import ConnectSocialMedia from '../forms/ConnectSocialMedia';

class Social extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  async submitForm() {
    await this.personalDetailForm.submitForm();
  }

  render() {
    return (
      <Container maxWidth={800}>
        <Grid container style={{ marginTop: 20, marginBottom: 10 }}>
          <Grid item>
            <span
              style={{
                fontSize: 15,
              }}
            >
              Social Settings
            </span>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 30 }}>
          <Grid item md={5}>
            <Divider
              style={{
                background:
                  'linear-gradient(90deg, rgba(171,171,171,1) 0%, rgba(176,176,176,0) 100%)',
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={8}>
            <ConnectSocialMedia />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(Social);
