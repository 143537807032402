import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import SidePanel from './SidePanel';
import Social from './Social';

class Settings extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <Grid container justify="center" style={{ marginTop: 15 }}>
        <Grid item md={12}>
          <Grid container spacing={3}>
            <Grid item md={2}>
              <SidePanel />
            </Grid>
            <Grid item md={12} style={{ marginTop: 20, marginLeft: 100 }}>
              <Switch>
                <Route path={`${this.props.match.url}/social-settings`}>
                  <Social />
                </Route>
              </Switch>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Settings);
