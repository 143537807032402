import React from 'react';
import { withRouter } from 'react-router-dom';
import PriceIndexDemo from './PriceIndexDemo';
import MobilePriceIndexDemo from './MobilePriceIndexDemo';

class MobilePriceIndexDemoWrapper extends React.Component {
  render() {
    return (
      <>
        {window.matchMedia('(min-width: 768px)').matches ? (
          <PriceIndexDemo />
        ) : (
          <MobilePriceIndexDemo />
        )}
      </>
    );
  }
}

export default withRouter(MobilePriceIndexDemoWrapper);
