import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import MobileCampaign from './cards/MobileCampaign.jsx';

import { campaignActions } from '../../actions/campaign.js';
import { connect } from 'react-redux';

import Loading from '../Common/Loading/Loading';
import NoCampaign from '../ux/NoCampaign';

class CampaignDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: [],
    };
  }

  componentDidMount() {
    this.props.getInfluencerCampaigns();
  }

  render() {
    return (
      <div style={{ width: '96vw', padding: '5px' }}>
        {this.props.loading && <Loading />}
        {this.props.campaigns.length ? (
          <Grid style={{ marginTop: 15, marginBottom: 70 }}>
            {this.props.campaigns?.map((campaign) => (
              <MobileCampaign {...campaign} key={campaign.id} />
            ))}
          </Grid>
        ) : (
          <Grid container justify="center" style={{ marginTop: 15 }}>
            <Grid item md={12}>
              <NoCampaign />
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.campaign.loading,
  campaigns: state.campaign.campaigns,
});

const actionCreators = {
  getInfluencerCampaigns: campaignActions.getInfluencerCampaigns,
};

export default connect(mapStateToProps, actionCreators)(withRouter(CampaignDashboard));
