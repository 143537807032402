import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { updateFilters } from 'actions/planner-filters';


const TextFilter = ({title, payloadKey}) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => state.plannerFilters[payloadKey]);

  return (
    <Grid container spacing={2} alignItems='center' justifyContent='space-evenly' sx={{ mb: 2 }}>
          <Grid item xs={3}>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField size='small' value={value} fullWidth type='number' onChange={(event) => {
              dispatch(updateFilters(payloadKey, parseInt(event.target.value)))
            }}/>
          </Grid>
        </Grid>
  )
}

export default TextFilter;