import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  CardMedia,
  TextField,
  Modal,
  CardActions,
  Box,
  MenuItem,
  Select,
  InputLabel,
  OutlinedInput,
  CardHeader,
} from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { withStyles } from '@material-ui/core/styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { getInfluencerList } from '../../api_handlers/influencer';
import InfluencerCard from './cards/DiscoveryInfluencerCrad';
import ErrorDisplay from './forms/ErrorDisplay';
import Autocomplete from '@material-ui/lab/Autocomplete';

import BaseFilter from '../ux/BaseFilter';

import {
  getCities,
  getStates,
  getLanguages,
  getFieldOfInterest,
} from '../../api_handlers/influencer.js';

const styles = {
  font12: {
    fontSize: 12,
  },
  editIcon: {
    color: 'white',
    background: '#3581F3',
    borderRadius: '50%',
    height: 20,
    width: 20,
    paddingLeft: 5,
    paddingTop: 5,
  },
};

class ComingSoon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search_text: null,
      category: null,
      categoryOptions: [],
      gender: null,
      platform: [],
      influencers: [],
      count: 0,
      loading: true,
      page: 1,
      advancedModalOpen: false,
      location: null,
      cityOptions: [],
      stateOptions: [],
      age_min: null,
      age_max: null,
      language: [],
      languageOptions: [],
      follower: null,
      engagement: null,
    };
  }

  async loadData() {
    const cities = await getCities();
    const states = await getStates();

    this.setState({
      cityOptions: cities,
      stateOptions: states,
      location: cities.filter((city) => city.id === this.state.city)[0],
    });
    this.setState({
      languageOptions: await getLanguages(),
      categoryOptions: await getFieldOfInterest(),
    });
  }

  async getData() {
    this.setState({
      loading: true,
    });
    const formData = this.collectFormData();
    const resp = await getInfluencerList(this.state.page, formData);
    this.setState({
      influencers: this.state.influencers.concat(resp.results),
      count: resp.count,
      loading: false,
    });
  }

  async reloadData() {
    await this.setState({
      influencers: [],
      count: 0,
      page: 1,
    });
    this.getData();
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
    this.loadData();
    window.addEventListener('scroll', this.infiniteScroll);
    setTimeout(() => {
      this.getData(this.state.page);
    }, 30);
  }

  infiniteScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      if (this.state.influencers.length < this.state.count && this.state.loading == false) {
        let newPage = this.state.page;
        newPage++;
        this.setState({
          page: newPage,
        });
        this.getData();
      }
    }
  };

  collectFormData() {
    let formData = {};
    formData['search_text'] = this.state.search_text;
    formData['category'] = this.state.category;
    formData['platform'] = this.state.platform;
    formData['age_min'] = this.state.age_min;
    formData['age_max'] = this.state.age_max;
    formData['follower'] = this.state.follower;
    formData['engagement'] = this.state.engagement;
    formData['language'] = this.state.language;
    formData['gender'] = this.state.gender;
    formData['location'] = this.state.location;

    // if(Object.keys(this.state.category).length > 0){
    //     formData['category'] = this.state.category.map( category => category.id)
    // }

    // if(Object.keys(this.state.language).length > 0){
    //     formData['language'] = this.state.language.map( language => language.id)
    // }

    return formData;
  }

  async submitForm() {
    const formData = this.collectFormData();

    this.reloadData();
    this.setState({
      advancedModalOpen: false,
    });

    // if(Object.keys(formData).length > 0){
    //     const resp = await updateInfluencerProfile(formData)

    //     if(resp && this.props.callback){ this.props.callback() }
    // } else if(this.props.callback) { this.props.callback() }
  }
  // componentDidUpdate(){
  //     // this.reloadData()
  // }
  handleSearchText = (event) => {
    const data = event.target.value;
    this.setState({
      search_text: data,
    });
    this.reloadData();
  };

  handleCategory = (event) => {
    const data = event.target.value;
    this.setState({
      category: data,
    });
    this.reloadData();
  };

  handlePlatform = (event) => {
    const data = event.target.value;
    this.setState({
      platform: data,
    });
    this.reloadData();
  };

  handleGender = (event) => {
    const data = event.target.value;
    this.setState({
      gender: data,
    });
    this.reloadData();
  };

  render() {
    const platforms = [
      { value: 'youtube', name: 'Youtube' },
      { value: 'instagram', name: 'Instagram' },
    ];
    const genders = [
      { value: 'male', name: 'Male' },
      { value: 'female', name: 'Female' },
      { value: 'unkown', name: 'Other' },
    ];
    const followers = [
      { value: 1, name: 'Macro (1000 - 10,000)' },
      { value: 2, name: 'Small (10,000 - 1,00,000)' },
      { value: 3, name: 'Medium (1,00,000 - 10,00,000)' },
    ];
    const { classes } = this.props;
    return (
      <>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          style={{ fontSize: '12px', width: '96vw', margin: 'auto', marginTop: 6 }}
        >
          <Grid item md={12}>
            <BaseFilter>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item md={12} style={{ margin: 5 }}>
                  <FormControl fullWidth>
                    <InputLabel
                      style={{ color: 'black', textAlign: 'left' }}
                      id="search_text_label"
                      style={{ color: 'black', textAlign: 'left' }}
                    >
                      Search Influencer
                    </InputLabel>
                    <TextField
                      labelId="search_text_label"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="search_text"
                      value={this.state.search_text}
                      // label="Search Influencer"
                      name="search_text"
                      autoComplete="text"
                      autoFocus
                      onChange={this.handleSearchText}
                      style={{
                        backgroundColor: 'white',
                        radius: 5,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12} style={{ margin: 5 }}>
                  <FormControl fullWidth>
                    <InputLabel
                      style={{ color: 'black', textAlign: 'left' }}
                      id="category-select-label"
                    >
                      Category
                    </InputLabel>
                    <Select
                      labelId="category-select-label"
                      id="category-select"
                      variant="outlined"
                      label="Category"
                      input={<OutlinedInput />}
                      value={this.state.category}
                      onChange={this.handleCategory}
                      style={{
                        backgroundColor: 'white',
                        radius: 5,
                        marginTop: 16,
                        marginBottom: 8,
                      }}
                    >
                      {this.state.categoryOptions.map((category) => (
                        <MenuItem key={category} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} style={{ margin: 5 }}>
                  <FormControl fullWidth>
                    <InputLabel
                      style={{ color: 'black', textAlign: 'left' }}
                      id="gender-select-label"
                    >
                      Gender
                    </InputLabel>
                    <Select
                      labelId="gender-select-label"
                      id="gender-select"
                      label="gender"
                      value={this.state.gender}
                      input={<OutlinedInput />}
                      onChange={this.handleGender}
                      style={{
                        backgroundColor: 'white',
                        radius: 5,
                        marginTop: 16,
                        marginBottom: 8,
                      }}
                    >
                      {genders.map((gender) => (
                        <MenuItem key={gender} value={gender.value}>
                          {gender.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} style={{ margin: 5 }}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel
                      style={{ color: 'black', textAlign: 'left' }}
                      id="platform-select-label"
                    >
                      Platform
                    </InputLabel>
                    <Select
                      labelId="platform-select-label"
                      id="platform-select"
                      multiple
                      value={this.state.platform}
                      onChange={this.handlePlatform}
                      style={{
                        backgroundColor: 'white',
                        radius: 5,
                        marginTop: 16,
                        marginBottom: 8,
                      }}
                      input={<OutlinedInput />}
                    >
                      {platforms.map((platform) => (
                        <MenuItem key={platform} value={platform.value}>
                          {platform.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} style={{ margin: 5, marginTop: 16 }}>
                  <Button
                    variant="contained"
                    startIcon={<FilterAltIcon />}
                    style={{
                      backgroundColor: '#1E9CB2',
                      textTransform: 'none',
                    }}
                    onClick={() => {
                      this.setState({
                        advancedModalOpen: true,
                      });
                    }}
                  >
                    Advanced Filter
                  </Button>
                  <Modal
                    open={this.state.advancedModalOpen}
                    onClose={() => {
                      this.setState({
                        advancedModalOpen: false,
                      });
                    }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={{
                      top: 170,
                    }}
                  >
                    <Card
                      style={{
                        width: '70%',
                        left: '15%',
                        position: 'relative',
                      }}
                    >
                      <CardHeader title="Advanced Filter"></CardHeader>
                      <CardContent>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-around"
                          alignItems="center"
                          style={{
                            marginBottom: 10,
                            marginTop: 30,
                          }}
                          spacing={2}
                        >
                          <Grid
                            item
                            md={3}
                            classes={{
                              root: classes.font12,
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel
                                style={{ color: 'black', textAlign: 'left' }}
                                id="category-select-label"
                              >
                                Category
                              </InputLabel>
                              <Select
                                labelId="category-select-label"
                                id="category-select"
                                label="Category"
                                value={this.state.category}
                                input={<OutlinedInput />}
                                onChange={(e) =>
                                  this.setState({
                                    category: e.target.value,
                                  })
                                }
                                style={{
                                  backgroundColor: 'white',
                                  radius: 5,
                                  marginTop: 16,
                                  marginBottom: 8,
                                  textTransform: 'none',
                                }}
                              >
                                {this.state.categoryOptions.map((category) => (
                                  <MenuItem key={category} value={category.id}>
                                    {category.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            classes={{
                              root: classes.font12,
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel
                                style={{ color: 'black', textAlign: 'left' }}
                                id="gender-select-label"
                              >
                                Gender
                              </InputLabel>
                              <Select
                                labelId="gender-select-label"
                                id="gender-select"
                                label="gender"
                                value={this.state.gender}
                                input={<OutlinedInput />}
                                onChange={(e) =>
                                  this.setState({
                                    gender: e.target.value,
                                  })
                                }
                                style={{
                                  backgroundColor: 'white',
                                  radius: 5,
                                  marginTop: 16,
                                  marginBottom: 8,
                                }}
                              >
                                {genders.map((gender) => (
                                  <MenuItem key={gender} value={gender.value}>
                                    {gender.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            classes={{
                              root: classes.font12,
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel
                                style={{ color: 'black', textAlign: 'left' }}
                                id="location-select-label"
                                s
                              >
                                Location
                              </InputLabel>
                              <Autocomplete
                                options={this.state.stateOptions}
                                getOptionLabel={(option) => option.name}
                                value={this.state.location}
                                InputLabelProps={{
                                  shrink: false,
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    margin="normal"
                                    variant="outlined"
                                    label="Location"
                                    style={{
                                      backgroundColor: 'white',
                                      radius: 5,
                                      marginTop: 16,
                                      marginBottom: 8,
                                    }}
                                  />
                                )}
                                onChange={(event, value) => {
                                  this.setState({
                                    location: value,
                                  });
                                }}
                                classes={{
                                  option: classes.font13,
                                  input: classes.font13,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            classes={{
                              root: classes.font12,
                            }}
                          >
                            <FormControl>
                              <InputLabel
                                style={{ color: 'black', textAlign: 'left' }}
                                id="age_group-select-label"
                              >
                                Age Group
                              </InputLabel>
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid item md={5}>
                                  <TextField
                                    labelId="age_min_label"
                                    variant="outlined"
                                    id="age_min"
                                    value={this.state.age_min}
                                    label="Age Min"
                                    name="age_min"
                                    autoComplete="text"
                                    autoFocus
                                    type="number"
                                    onChange={(e) =>
                                      this.setState({
                                        age_min: e.target.value,
                                      })
                                    }
                                    style={{
                                      backgroundColor: 'white',
                                      radius: 5,
                                      marginTop: 16,
                                      marginBottom: 8,
                                    }}
                                  />
                                </Grid>
                                <Grid item md={5}>
                                  <TextField
                                    labelId="age_max_label"
                                    variant="outlined"
                                    id="age_max"
                                    value={this.state.age_max}
                                    label="Age Max"
                                    name="age_max"
                                    autoComplete="text"
                                    autoFocus
                                    type="number"
                                    onChange={(e) =>
                                      this.setState({
                                        age_max: e.target.value,
                                      })
                                    }
                                    style={{
                                      backgroundColor: 'white',
                                      radius: 5,
                                      marginTop: 16,
                                      marginBottom: 8,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-around"
                          alignItems="center"
                          style={{
                            marginBottom: 30,
                            marginTop: 10,
                          }}
                          spacing={2}
                        >
                          <Grid
                            item
                            md={3}
                            classes={{
                              root: classes.font12,
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel
                                style={{ color: 'black', textAlign: 'left' }}
                                id="language-select-label"
                              >
                                Language
                              </InputLabel>
                              <Select
                                labelId="language-select-label"
                                id="language-select"
                                multiple
                                value={this.state.language}
                                onChange={(e) =>
                                  this.setState({
                                    language: e.target.value,
                                  })
                                }
                                style={{
                                  backgroundColor: 'white',
                                  radius: 5,
                                  marginTop: 16,
                                  marginBottom: 8,
                                }}
                                input={<OutlinedInput />}
                              >
                                {this.state.languageOptions.map((language) => (
                                  <MenuItem key={language} value={language.id}>
                                    {language.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            classes={{
                              root: classes.font12,
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel
                                style={{ color: 'black', textAlign: 'left' }}
                                id="follower-select-label"
                              >
                                Follower
                              </InputLabel>
                              <Select
                                labelId="follower-select-label"
                                id="follower-select"
                                value={this.state.follower}
                                onChange={(e) =>
                                  this.setState({
                                    follower: e.target.value,
                                  })
                                }
                                style={{
                                  backgroundColor: 'white',
                                  radius: 5,
                                  marginTop: 16,
                                  marginBottom: 8,
                                }}
                                input={<OutlinedInput />}
                              >
                                {followers.map((follower) => (
                                  <MenuItem key={follower} value={follower.value}>
                                    {follower.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            classes={{
                              root: classes.font12,
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel
                                style={{ color: 'black', textAlign: 'left' }}
                                id="engagement_label"
                              >
                                Engagement
                              </InputLabel>
                              <TextField
                                labelId="engagement_label"
                                variant="outlined"
                                fullWidth
                                id="engagement"
                                value={this.state.engagement}
                                name="engagement"
                                autoComplete="text"
                                autoFocus
                                type="number"
                                onChange={(e) =>
                                  this.setState({
                                    engagement: e.target.value,
                                  })
                                }
                                style={{
                                  backgroundColor: 'white',
                                  radius: 5,
                                  marginTop: 16,
                                  marginBottom: 8,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            classes={{
                              root: classes.font12,
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel
                                style={{ color: 'black', textAlign: 'left' }}
                                id="platform-select-label"
                              >
                                Platform
                              </InputLabel>
                              <Select
                                labelId="platform-select-label"
                                id="platform-select"
                                multiple
                                value={this.state.platform}
                                onChange={(e) =>
                                  this.setState({
                                    platform: e.target.value,
                                  })
                                }
                                style={{
                                  backgroundColor: 'white',
                                  radius: 5,
                                  marginTop: 16,
                                  marginBottom: 8,
                                }}
                                input={<OutlinedInput />}
                              >
                                {platforms.map((platform) => (
                                  <MenuItem key={platform} value={platform.value}>
                                    {platform.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-around"
                          alignItems="center"
                        >
                          <Grid item md={6}>
                            <span>Available Results:</span> {this.state.count}
                          </Grid>
                          <Grid item md={6}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                              spacing={0}
                            >
                              <Grid item md={3}>
                                <Button
                                  style={{
                                    textTransform: 'none',
                                    // border: "1px solid #1E9CB2",
                                    color: 'black',
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      advancedModalOpen: false,
                                    });
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Grid>
                              <Grid item md={4}>
                                <Button
                                  onClick={() => {
                                    this.submitForm();
                                  }}
                                  style={{
                                    textTransform: 'none',
                                    color: 'white',
                                    background: '#1E9CB2',
                                  }}
                                >
                                  Apply Filter
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Modal>
                </Grid>
              </Grid>
            </BaseFilter>
          </Grid>
          <Grid item md={12}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              {this.state.influencers.map((elem) => (
                <Grid item xs={12} sm={6} md={3} key={this.state.influencers.indexOf(elem)}>
                  <InfluencerCard {...elem} key={elem.id} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(ComingSoon);
