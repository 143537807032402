import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Avatar,
  Paper,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import NoDataLabel from '../common/NoDataLabel';
import countertostring from 'components/PlannerV2/components/common';
import { getYoutubeTopVideos } from '../../../../api_handlers/reporting';
import VideoPlaceholder from '../../../../assets/youtube-placeholder.jpg';
import BootstrapInput from '../../../ux/BootstrapInput';

class TopVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      metric: 'view_count',
    };
  }

  async getData() {
    const resp = await getYoutubeTopVideos(this.props.campaignId, this.state.metric);
    this.setState({ data: resp.data, loading: false });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  render() {
    return (
      // <Card
      //   style={{
      //     height: 280,
      //   }}
      // >
      //   <CardContent>
      <Grid container spacing={2} style={{ border: '1px solid #dedede', marginTop: 3 }}>
        <Grid item md={12} style={{ width: '100%' }} class="dashboard-card-title">
          <Grid
            container
            style={{ border: '1px solid #dedede', padding: 10 }}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item md={2}>
              <Typography style={{ fontWeight: 600, fontSize: 14 }}>Top Videos</Typography>
            </Grid>
            <Grid item md={10} style={{ textAlign: 'end', paddingRight: 20 }}>
              <FormControl variant='outlined'>
                <Select
                  // value={age}
                  onChange={async (value) => {
                    await this.setState({ metric: value.target.value });
                    this.getData();
                  }}
                  input={<BootstrapInput />}
                  defaultValue={'view_count'}
                >
                  <MenuItem value={'engagement_rate'}>Engagement rate</MenuItem>
                  <MenuItem value={'view_count'}>View</MenuItem>
                  <MenuItem value={'comment_count'}>Comments</MenuItem>
                  <MenuItem value={'like_count'}>Likes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          {this.state.loading ? (
            <CircularProgress style={{ marginTop: 50 }} />
          ) : this.state.data.length === 0 ? (
            <NoDataLabel offsetTop={50} />
          ) : (
            <Grid
              container
              spacing={0}
              style={{
                height: 225,
                overflowY: 'scroll',
                whiteSpace: 'nowrap',
              }}
            >
              {this.state.data.map((post) => {
                return (
                  <Grid
                    item
                    md={2}
                    style={{ border: '1px solid #dedede', cursor: 'pointer' }}
                    onClick={() => {
                      window.open(post.url, '_blank');
                    }}
                  >
                    <Grid container style={{ paddingTop: 10 }}>
                      <Grid item style={{ margin: 'auto' }}>
                        <img
                          src={post.thumbnail || VideoPlaceholder}
                          style={{
                            height: '120px',
                          }}
                        ></img>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      onClick={() => {
                        window.open(post.channel.link, '_blank');
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ background: '#d3d3d3', padding: 5 }}
                      >
                        <Grid item md={8}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid item md={2}>
                              <Avatar
                                src={post.channel.avatar}
                                style={{
                                  width: '25px',
                                  height: '25px',
                                }}
                              />
                            </Grid>
                            <Grid item md={'auto'}>
                              <Typography style={{ fontSize: 13, marginLeft: 5, fontWeight: 600 }}>
                                {post.channel.title.length <= 13 ? post.channel.title : `${post.channel.title.slice(0,13)}...` }
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={'auto'}>
                          <Grid
                            style={{
                              background: '#f9b725',
                              padding: '4px',
                              color: '#000',
                              fontWeight: '600',
                              fontSize: '11px',
                              marginRight: 13,
                              borderRadius: 3,
                              width: 50
                            }}
                          >
                            {countertostring(post.value)}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
      //   </CardContent>
      // </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
  };
};

export default connect(mapStateToProps)(withRouter(TopVideos));
