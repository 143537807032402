import { useState, useEffect } from 'react';
import { Typography, Grid, Modal, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddIcon from '@mui/icons-material/Add';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

import { createInstagramIQProfile, createYoutubeIQProfile } from 'api_handlers/discovery';

function AddNewProfileModal({
    showAddProfileModal = false,
    closeAddProfileModal,
    openDetailViewModal
}) {
  const classes = useStyles();
  const [profile_links, setProfileLinks] = useState([{ link: '', type: 'instagram' },{ link: '', type: 'youtube' }]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [loadingData, setLoadingData] = useState(false);


  useEffect(() => {
  }, []);

  async function createInstagramProfile(index) {
    setLoadingData(true);
    let profile_links_updated = [...profile_links];
    let data = await createInstagramIQProfile(profile_links_updated[index].link);
    if (data.data.error) {
      profile_links_updated[index]['error'] = data.data.error;
    } else {
      profile_links_updated[index]['show_view'] = true;
      profile_links_updated[index]['id'] = data.data.id;
    }
    setDisableBtn(true);
    setProfileLinks(profile_links);
    setLoadingData(false);
  }

  async function createYoutubeProfile(index) {
    setLoadingData(true);
    let profile_links_updated = [...profile_links];
    let data = await createYoutubeIQProfile(profile_links_updated[index].link);
    if (data.data.error) {
      profile_links_updated[index]['error'] = data.data.error;
    } else {
      profile_links_updated[index]['show_view'] = true;
      profile_links_updated[index]['id'] = data.data.id;
    }
    setDisableBtn(true);
    setProfileLinks(profile_links);
    setLoadingData(false);
  }

  return (
    <Modal open={showAddProfileModal} onClose={closeAddProfileModal} className={classes.base}>
      <Grid container className={classes.root}>
        <Grid item md={12}>
          <Grid container className={classes.header}>
            <Grid item md={3}>
              <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                  <Typography variant="h3">Add profile(s)</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              className={classes.closeContainer}
              onClick={() => closeAddProfileModal()}
            >
              <Grid container alignItems="center">
                <Grid item>
                  <Typography variant="h3">Close</Typography>
                </Grid>
                <Grid item>
                  <HighlightOffIcon className={classes.closeIcon} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            {
              profile_links.map((each, index)=>{
                return <div style={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                    <div
                    style={{ 
                      width: 600,
                      border: '1px solid #c6c6c6',
                      padding: 10,
                      borderRadius: 10,
                      marginTop: 20,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    {
                      each.type == "youtube" ? 
                      <YouTubeIcon 
                      style={{ marginRight: 10 }} />
                      : <InstagramIcon 
                      style={{ marginRight: 10 }}/>
                    }
                    <TextField 
                      style={{ width: 400 }}
                      variant='outlined'
                      onChange={(evt)=>{
                        let profile_links_updated = [...profile_links];
                        profile_links_updated[index].link = evt.target.value; 
                        setProfileLinks(profile_links_updated);
                      }}
                      placeholder='input the profile link here'>
                    </TextField>
                    {
                      each.show_view ?
                      <Button
                      style={{ marginLeft: 10, }}
                      variant='contained'
                      color='primary'
                      onClick={()=>{
                        openDetailViewModal(each.id);
                      }}
                      >View</Button>
                      : <Button
                        disabled={disableBtn || loadingData}
                        style={{ marginLeft: 10, }}
                        variant='contained'
                        color='primary'
                        startIcon={<AddIcon/>}
                        onClick={(e)=>{
                          let profile_links_updated = [...profile_links];
                          if (profile_links_updated.length < 3) {
                            profile_links_updated.push({ link: '' , type: each.type })
                            setProfileLinks(profile_links_updated);
                          }
                        }}></Button>
                    }
                  </div>
                  {
                    each.error ? 
                    <div style={{ padding: 10, color: 'red' }}>
                        {each.error}
                    </div> : null
                  }
                </div>
              })
            }
          </Grid>
          <div
            style={{ 
              marginTop: 20,
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Button
              onClick={() => closeAddProfileModal()}
              style={{ color: 'gray', marginRight: 10 }}>Cancel</Button>
            <Button
              disabled={disableBtn || loadingData}
              onClick={()=>{
                let profile_links_updated = [...profile_links];
                var link_valid = false;
                profile_links_updated.map((each)=>{
                  if (each.type == "instagram" && each.link && each.link.toLocaleLowerCase().indexOf('instagram.com') > 0) {
                    link_valid = true;
                  } else if (each.type == "youtube" && each.link && each.link.toLocaleLowerCase().indexOf('youtube.com') > 0) {
                    link_valid = true;
                  }
                })
                profile_links_updated.map((each, index)=>{
                  profile_links_updated[index].error = "";
                  if (!link_valid || each.link.length > 0) {
                    if (each.type == "instagram") {
                      if (each.link && each.link.toLocaleLowerCase().indexOf('instagram.com') > 0) {
                        createInstagramProfile(index);
                      } else {
                        profile_links_updated[index].error = "Please Enter Valid Insagram Link."
                      }
                    } else {
                      if (each.link && each.link.toLocaleLowerCase().indexOf('youtube.com') > 0) {
                        createYoutubeProfile(index);
                      } else {
                        profile_links_updated[index].error = "Please Enter Valid Youtube Link."
                      }
                    }
                  }
                })
                setProfileLinks(profile_links_updated);
              }}
              variant='contained'
              color='primary'>Create</Button>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
}

const useStyles = makeStyles(() => ({
  base: {
    background: 'white',
    height: 600,
    width: 900,
    margin: 'auto',
    marginTop: 50,
  },
  root: {
    background: '#F3F4FE',
    filter: 'drop-shadow(5px 8px 15px rgba(171, 171, 171, 0.25))',
    height: 600,
    width: 900,
    borderRadius: 10,
    padding: 40,
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  campaignIcon: {
    height: 30,
    width: 30,
  },
  divider: {
    border: '2px solid #FEBD1C',
    borderRadius: 10,
    background: '#FEBD1C',
  },
  body: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 25,
    height: 500,
  },
  cardContainer: {
    overflowY: 'scroll',
    height: 400,
  },
  closeContainer: {
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.9) 0%, #FBFCFF 100%)',
    boxShadow:
      '-1px 1px 2px rgba(215, 216, 220, 0.2), 1px -1px 2px rgba(215, 216, 220, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: 7,
    height: 30,
    cursor: 'pointer',
  },
  closeIcon: {
    fontSize: 14,
    color: '#757575',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 6,
  },
}));

export default AddNewProfileModal;
