import React from 'react';
import { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Link, Grid, Modal, IconButton } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ImageValidator from 'components/ux/ImageValidator';
import insta_placeholder from 'assets/discovery/insta_placeholder.png';
import ShareIcon from '@mui/icons-material/Share';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ViewIcon from 'assets/View.png';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EngagementIcon from 'assets/Engagement.png';
// import Diversity3Icon from '@mui/icons-material/Diversity3';
import StarBorder from '@mui/icons-material/StarBorder';
import { getInstagramFeeds } from '../../../../api_handlers/feeds';
import CloseIcon from '@mui/icons-material/Close';
import { color, fontSize, height, maxWidth, textAlign, width } from '@material-ui/system';

const styles = (theme) => ({
  card: {
    background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    padding: 20,
    maxWidth: 300,
    marginLeft: 20,
    marginTop: 50,
  },
  instaHandler: {
    fontSize: 18,
    textAlign: 'start',
    cursor: 'pointer',
    fontWeight: 600,
  },
  image: {
    height: 250,
    width: 220,
    borderRadius: 20,
    marginTop: 10,
    textAlign: 'center',
    cursor: 'pointer',
  },
  icon: {
    height: 15,
    width: 15,
    marginTop: 9,
  },
  data: {
    marginLeft: 6,
    fontSize: 15,
  },
  modalContainer: {
    filter: 'drop-shadow(5px 8px 15px rgba(171, 171, 171, 0.25))',
    // height: 500,
    width: 'fit-content',
    borderRadius: 10,
    padding: 25,
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  modalImage: {
    width: '90%',
    borderRadius: 10,
    marginTop: 10,
  },
  creation_date: {
    color: 'gray',
    fontSize: '12px',
  }
});

class InstagramThumbnailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feeds: [],
      count: 0,
      page: 1,
      loading: false,
      columnsArray: [],
      open: false,
      selectedPost: null
    };
  }
  async getData() {
    if (this.state.loading) {
      return
    }
    this.setState({
      loading: true,
    });
    const resp = await getInstagramFeeds(this.state.page, {
      influencer_id: this.props.influencerId,
      start_date: this.props.startDate,
      end_date: this.props.endDate,
      tab: this.props.tab,
    });
    this.setState({
      feeds: this.state.feeds.concat(resp.results),
      count: resp.count,
      loading: false,
    });
    console.log('resp', resp);
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
    window.addEventListener('scroll', this.infiniteScroll);
    setTimeout(() => {
      this.getData(this.state.page);
    }, 30);
  }

  infiniteScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      if (this.state.feeds.length < this.state.count && this.state.loading == false) {
        let newPage = this.state.page;
        newPage++;
        this.setState({
          page: newPage,
        });
        this.getData();
      }
    }
  };

  async reloadData() {
    await this.setState({
      feeds: [],
      count: 0,
      page: 1,
    });
    this.getData();
  }

  render() {
    const { classes } = this.props;
    const { selectedPost } = this.state;
    // console.log("Reach count :", feed.branded_content_data['reach'])
    // console.log("Reach Views :", feed.branded_content_data['reach'] * 0.86)
    return (
      <Grid container>
        {this.state.feeds.length > 0
          ? this.state.feeds.map((data) => (
              <Grid item md={4}>
                <Grid container justifyContent="space-between">
                  <Grid item className={classes.card} style={{ height: '100%', width: '100%' }}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid style={{ alignSelf: 'flex-start' }}>
                        <Typography style={{ fontSize: 22 }}>{data.user_name}</Typography>
                      </Grid>
                      <Grid
                        item
                        md={11}
                        onClick={() => {
                          this.setState({ open: true });
                          this.setState({ selectedPost: data });
                        }}
                      >
                        <ImageValidator
                          className={classes.image}
                          url={data.thumbnail}
                    // url='https://www.shutterstock.com/shutterstock/photos/2448673337/display_1500/stock-photo-real-sky-blue-river-in-shape-intestines-in-green-jungle-forest-top-view-2448673337.jpg'
                          
                          altText={'icon'}
                          placeContainerHolderStyle={{ marginBottom: '5px' }}
                        >
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className={classes.image}
                            style={{ background: '#ffffff' }}
                          >
                            <Grid item>
                              <img
                                style={{ width: 100, height: 100 }}
                                src={insta_placeholder}
                                alt="icon"
                              />
                            </Grid>
                          </Grid>
                        </ImageValidator>
                      </Grid>
                      <Grid item md={12}>
                        <Grid
                          container
                          spacing={2}
                          justifyContent="space-evenly"
                          alignItems="center"
                        >
                          <Grid item>
                            <Tooltip title="Shares">
                              <Grid container alignItems="center" justifyContent="center">
                                <Grid item>
                                  <ShareIcon
                                    style={{
                                      height: 15,
                                      width: 15,
                                      marginTop: 4,
                                    }}
                                  ></ShareIcon>
                                </Grid>
                                <Grid item>
                                  <Typography className={classes.data} variant="h5">
                                    {data.shares}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Reach">
                              <Grid container alignItems="center">
                                <Grid item>
                                  <PeopleAltIcon
                                    style={{
                                      height: 18,
                                      width: 18,
                                      marginTop: 6,
                                    }}
                                  ></PeopleAltIcon>
                                  {/* <img src={LikeIcon} alt="likeicon" className={classes.icon} /> */}
                                </Grid>
                                <Grid item>
                                  <Typography className={classes.data} variant="h5">
                                    {data.reach}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Total Engagement">
                              <Grid container alignItems="center" justifyContent="space-around">
                                <Grid item>
                                  <StarBorder
                                    style={{
                                      height: 18,
                                      width: 18,
                                      marginTop: 4,
                                    }}
                                  ></StarBorder>
                                  {/* <img src={EngagementIcon} alt="EngagementIcon" className={classes.icon} /> */}
                                </Grid>
                                <Grid item>
                                  <Typography className={classes.data} variant="h5">
                                    {data.engagement || data.engagement_rate}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Views">
                              <Grid container alignItems="center" justifyContent="center">
                                <Grid item>
                                  <RemoveRedEyeIcon
                                    style={{
                                      height: 18,
                                      width: 18,
                                      marginTop: 6,
                                    }}
                                  ></RemoveRedEyeIcon>
                                  {/* <img src={ViewIcon} alt="likeicon" className={classes.icon} /> */}
                                </Grid>
                                <Grid item>
                                  <Typography className={classes.data} variant="h5">
                                    {data.video_views}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={12} container justifyContent='flex-end'>
                        <Typography className={classes.creation_date}>
                          {data.added_on}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))
          : null}
        {this.state.open ? (
          <Modal
            open={this.state.open}
            style={{
              padding: 20,
              paddingTop: 0,
              position: 'fixed',
              width: '39%',
              left: '25%',
              top: 100,
              height: '70%',
          }}
            //   onClose={this.setState({open:false})}
          >
            <Grid container className={classes.modalContainer}>
              <Grid item style={{alignSelf: 'flex-end', cursor: 'pointer'}} onClick={()=>{this.setState({open:false})}}>
                <CloseIcon />
              </Grid>
              <Grid item md={12}> 
                <ImageValidator
                  className='chat-img'
                  url={selectedPost.thumbnail}
                  // url={
                  //   'https://influencer-media.s3.amazonaws.com/insta_insight_snap/Image_from_iOS.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2XZTXDQHFGFJ53GT%2F20240709%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240709T080641Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=720c6b5961055a4b27dab96a023a4b290277abcdde72fca903b10aa12f8117de'
                  // }
                  altText={'icon'}
                  placeContainerHolderStyle={{ marginBottom: '5px' }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className='chat-img'
                    style={{ background: '#ffffff' }}
                  >
                    <Grid item>
                      <img style={{ width: 100, height: 100 }} src={insta_placeholder} alt="icon" />
                    </Grid>
                  </Grid>
                </ImageValidator>
              </Grid>
            </Grid>
          </Modal>
        ) : null}
      </Grid>
    );
  }
}

export default withStyles(styles, {})(InstagramThumbnailView);
