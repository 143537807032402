import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

import AppRouter from './Routes';
import store from './store';

import mixpanel from 'mixpanel-browser';  

if (process.env.NODE_ENV === "production") {
  mixpanel.init('ffef6e2ce617a23d1c2ae8722e17963a', {debug: false, track_pageview: true, persistence: 'localStorage'});
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.email) {
    mixpanel.identify(user.email);
    mixpanel.people.set('$email', user.email)
  }
}

function App() {
  return (
    <BrowserRouter forceRefresh={true}>
      <div className="App">
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </div>
    </BrowserRouter>
  );
}

export default App;
