import './Loading.styles.css';
import Spinner from '../../../assets/spinner.svg';

const Loading = () => {
  return (
    <div className={'loading'} style={{ background: 'rgba(0,0,0,0.2)' }}>
      <div class="loader loader_bubble">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </div>
      {/* <img src={Spinner} alt="spinner" /> */}
    </div>
  );
};

export default Loading;
