import { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { Link, Grid } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

import YouTubeLogo from '../../assets/youtube-logo.png';

const useStyles = makeStyles({
  headerCell: {
    background: '#C4C4C4',
    fontWeight: 600,
    textAlign: 'center',
  },
  table: {
    minWidth: 650,
  },
  cell: {
    textAlign: 'center',
  },
});

const styles = (theme) => ({
  headerCell: {
    background: '#C4C4C4',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 12,
  },
  table: {
    minWidth: 650,
  },
  cell: {
    textAlign: 'center',
    fontSize: 12,
  },
  link: {
    textDecoration: 'none',
    color: '#333',
    fontStyle: 'italic',
    fontWeight: 700,
  },
});

class InfluencerTabularList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  ellipsis(text) {
    if (text.length > 80) return text.slice(0, 77) + '...';
    return text;
  }

  render() {
    const { classes } = this.props;
    return (
      <TableContainer component={Paper} style={{ margin: '15px 10px' }}>
        <Table stickyHeader className={classes.table} aria-label="sticky table">
          <TableHead>
            <TableRow>
              {this.props.selectedColumns.map((column) => {
                return <TableCell className={classes.headerCell}>{column.name}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.influencers.map((influencer) => (
              <TableRow key={influencer.influencer.name}>
                {this.props.selectedColumns.map((column) => {
                  return (
                    <TableCell className={classes.cell}>
                      {typeof column.value === 'function'
                        ? column.value(influencer)
                        : influencer[column.value]}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default withStyles(styles, {})(InfluencerTabularList);
