
import InstagramIcon from 'assets/discovery/insta-icon.png';
import YoutubeIcon from 'assets/discovery/youtube-icon.png';

function SocialMediaButton({platform, url}) {
  return (
    <a href={url} target='_blank' rel="noreferrer">
      <img height={20} src={platform === 'youtube' ? YoutubeIcon : InstagramIcon} alt="icon"
        style={{marginLeft: platform === 'instagram' ? 5 : 0}} />
    </a>
  );
}

export default SocialMediaButton;
