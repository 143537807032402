import { Avatar, Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import downArrow from 'assets/planner/down-arrow.png';
import upArrow from 'assets/planner/up-arrow.png';

import { Component } from 'react';

class downloadPlannerStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      value: '',
      contacts: false,
    };
  }

  handleDropdownClick = () => {
    this.setState((prevState) => ({
      selected: !prevState.selected,
    }));
  };

  handleItemClick = (index) => {
    if (index === 1) {
      this.props.onButtonClick(true);
    } else {
      this.props.onButtonClick(false);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected !== this.state.selected) {
      this.props.setDownloadSelected(this.state.selected);
    }
  }

  render() {
    let { classes } = this.props;
    const items = ['Download without contact', 'Download with contact'];
    return (
      <Grid container className={classes.dropDown}>
        <Grid item>
          <Button
            disabled={this.props.disabled}
            className={classes.downloadPlan}
            onClick={this.handleDropdownClick}
          >
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <Typography className={classes.buttonName}>Download Plan</Typography>
              </Grid>
              <Grid item>
                <img width={10} src={this.state.selected ? upArrow : downArrow}></img>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        {this.state.selected ? (
          <Grid
            container
            className={classes.root}
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid className={classes.dropdownGrid}>
              {items.map((item, index) => (
                <Grid
                  item
                  key={index}
                  className={classes.menu}
                  onClick={() => this.handleItemClick(index)}
                >
                  {item}
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    );
  }
}

const styles = {
  root: {
    // backgroundColor: "#fff",
    // width: 150,
    // innerWidth: 150,
    // borderRadius: 10,
    // marginLeft: "80%",
    // marginRight: 100,
    // marginTop: 10,
    // zIndex: 20,
  },
  dropDown: {
    position: 'relative',
    display: 'inline-block',
    justifyContent: 'center',
  },
  menu: {
    cursor: 'pointer',
    padding: 10,
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgb(254, 189, 28, 0.50)',
      borderRadius: 10,
      fontSize: 14,
    },
  },
  dropdownGrid: {
    backgroundColor: '#fff',
    position: 'absolute',
    top: '119%',
    left: '-20px',
    right: '-50px',
    width: 200,
    backgroundColor: '#ffffff',
    border: '1px solid #ccc',
    borderRadius: '10px',
    fontSize: 14,
    zIndex: 20,
  },
  downloadPlan: {
    textTransform: 'none',
    // marginLeft: 150,
    background: '#FEBD1C',
    borderRadius: '10px',
    color: 'white',
    fontSize: 13,
    fontWeight: 600,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    filter: 'drop-shadow(5px 7px 7px rgba(186, 186, 186, 0.25))',
    '&:disabled': {
      backgroundColor: '#DDDDDD',
      color: '#FFFFFF',
    },
    cursor: 'pointer',
  },
  icon: {
    width: 10,
    height: 10,
  },
  buttonName: {
    fontSize: 14,
    fontWeight: 600,
    marginRight: 5,
  },
};

export default withStyles(styles)(downloadPlannerStaff);
