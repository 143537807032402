import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import Campaign from './cards/Campaign';

class CampaignList extends React.Component {
  render() {
    return (
      <Grid container>
        <Grid item md={12}>
          {this.props.campaigns?.map((campaign) => (
            <Campaign {...campaign} key={campaign.id} />
          ))}
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(CampaignList);
