import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CommentIcon from 'assets/discovery/comment-icon.png';
import LikeIcon from 'assets/discovery/like-icon.png';

function PopularPosts({ popular_posts = [] }) {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item md={12} className={classes.header}>
        <Typography variant="h3" style={{ color: 'black' }}>
          POPULAR POSTS
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Grid container justifyContent="space-between" style={{ height: '100%' }}>
          {popular_posts.slice(0, 3).map((post, i) => (
            <Grid item md={3} className={classes.card} style={{ height: '100%' }}>
              <Grid container direction="column" alignItems="center" justifyContent="space-between">
                <Grid item md={11} style={{ height: 250 }}>
                  <img src={post.thumbnail ? post.thumbnail : post.image} onError={post.user_picture} className={classes.image} alt="icon" />
                </Grid>
                <Grid item md={11}>
                {
                    post.text? <Typography variant="body2" style={{overflow: 'auto'}}>{post.text && post.text.slice(0, 100)}... </Typography>:''
                }
                 
                </Grid>
                <Grid item md={12}>
                  <Grid container spacing={3} style={{ marginTop: 10 }}>
                    <Grid item>
                      <Grid container>
                        <Grid item>
                          <img src={LikeIcon} alt="likeicon" className={classes.icon} />
                        </Grid>
                        <Grid item>
                          <Typography variant="h3">{post.likes}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container>
                        <Grid item>
                          <img src={CommentIcon} alt="commenticon" className={classes.icon} />
                        </Grid>
                        <Grid item>
                          <Typography variant="h3">{post.comments}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  header: {
    background: '#F3F4FE',
    boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    borderRadius: 10,
    padding: 15,
    marginBottom: 15,
  },
  card: {
    background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    padding: 20,
  },
  image: {
    height: 220,
    width: 220,
    borderRadius: 20,
  },
  icon: {
    height: 20,
    width: 20,
  },
}));

export default PopularPosts;
