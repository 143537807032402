import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import StyledNavLink from '../../ux/StyledNavLink';

class SidePanel extends React.Component {
  render() {
    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        spacing={4}
        style={{
          background: 'white',
          fontWeight: '400',
          fontSize: 13,
          paddingLeft: 20,
          height: 700,
        }}
      >
        <Grid item>
          <StyledNavLink
            to={`/influencer/campaigns/${this.props.match.params.campaignId}/feeds/${this.props.match.params.influencerId}/youtube`}
            label={'YouTube Feeds'}
          />
        </Grid>
        <Grid item>
          <StyledNavLink
            to={`/influencer/campaigns/${this.props.match.params.campaignId}/feeds/${this.props.match.params.influencerId}/instagram`}
            label={'Instagram Feeds'}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(SidePanel);
