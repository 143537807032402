import constant from '../constants/brand';
import { getDisplayPanels } from '../api_handlers/brand';

export function updateBrandDisplayPanels() {
  return (dispatch) => {
    return getDisplayPanels().then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {},
    );
  };

  function success(response) {
    return { type: constant.DISPLAY_PANEL, data: response.data };
  }
}
