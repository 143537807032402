import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomSlider = withStyles({
  root: {
    color: '#EFF0F9',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    background: '#EFF0F9',
    border: '2px solid #82E900',
    boxShadow: '2px 2px 4px rgba(148, 148, 148, 0.25)',
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    boxShadow:
      '-1px 1px 2px rgba(203, 204, 212, 0.2), 1px -1px 2px rgba(203, 204, 212, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(203, 204, 212, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(203, 204, 212, 0.5)',
    borderRadius: 20,
  },
  rail: {
    height: 8,
    boxShadow:
      '-1px 1px 2px rgba(203, 204, 212, 0.2), 1px -1px 2px rgba(203, 204, 212, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(203, 204, 212, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(203, 204, 212, 0.5)',
    borderRadius: 20,
  },
})(Slider);

export default CustomSlider;
