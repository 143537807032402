import { devConfig } from '../config';
import { getCookie } from '../components/utils';

const BASE_URL = `${devConfig.API_HOST}/api/v1`;
const BASE_URL_2 = `${devConfig.API_HOST}/api/v2`;
const headers = {
  'Content-Type': 'application/json',
};
if (localStorage.getItem('access')) {
  headers['Authorization'] = `Bearer ${localStorage.getItem('access')}`;
}

function priceIndex(userData) {
  return fetch(`${BASE_URL}/price-index/`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(userData),
  });
}

function priceIndexDemo(userData) {
  return fetch(`${BASE_URL_2}/price-index/`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(userData),
  });
}

function emailRequired() {
  return fetch(`${BASE_URL}/price-index-email/`, {
    method: 'POST',
    headers: headers,
  });
}

export const nonAuthHandlers = {
  priceIndex,
  priceIndexDemo,
  emailRequired,
};
