import Grid from '@material-ui/core/Grid';
import NumberInputButton from './NumberInputButton';

const NegoitateInput = (props) => {
  return (
    <>
      {props.value ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ margin: '8px' }}
        >
          <Grid item sm={6} container alignItems="center">
            <Grid item sm={1}>
              <img src={props.logo} alt={'instagram-logo'} width={'20'} />
            </Grid>
            <Grid item sm={11}>
              {props.text}
            </Grid>
          </Grid>
          <Grid item sm={6} alignItems="flex-end">
            <NumberInputButton counter={props.value} id={props.id} />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default NegoitateInput;
