import { useState, useEffect } from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LocationIcon from 'assets/discovery/Location-Icon.png';

import MultiSelect from 'components/PlannerTheme/Elements/MultiSelect';
import { getDiscoveryLocations } from 'api_handlers/discovery';

function InfluencerLocation({ influencerLocations, hideAdvanceFilters, classes }) {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    getDiscoveryLocations().then((response) => {
      console.log('response data', response.data);
      setLocations(response.data);
    });
  }, []);
  return (
    <>
      <div
        style={{
          display: 'flex',
          marginBottom: 10,
          justifyContent: 'center',
        }}
      >
        <img src={LocationIcon} width={22} style={{ marginRight: 7 }} />
        <Typography className={classes.advanceHeading}>Influencer Location</Typography>
      </div>
      <div style={{ marginTop: 10 }}>
        <MultiSelect
          label={'Select Location'}
          options={locations
            .filter((location) => location)
            .map((location) => ({ label: location, value: location }))}
          value={influencerLocations || []}
          onChange={(event, selectedValues) => {
            hideAdvanceFilters('influencerLocations', selectedValues);
          }}
        />
      </div>
    </>
  );
}

const styles = {
  advanceHeading: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 14,
  },
};

export default withStyles(styles)(InfluencerLocation);
