import * as React from 'react';
import { useEffect, useState, Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { ytCampaignTypesFetch } from 'api_handlers/competitor';

// function createData(post_type, count, reach, likes, comments) {
//   return { type, count, reach, likes, comments };
// }

// const data = [
//   createData('Insta Reels', 159, 6.0, 24, 4.0),
//   createData('Insta Posts', 237, 9.0, 37, 4.3),
//   createData('Total', 262, 16.0, 24, 6.0),
// ];

function countertostring(counter) {
  counter = parseFloat(counter);
  if (counter >= 1000 && counter < 999999) {
    return (
      parseFloat(counter / 1000.0)
        .toFixed(2)
        .toString() + 'K'
    );
  } else if (counter > 999999) {
    return (
      parseFloat(counter / 1000000.0)
        .toFixed(2)
        .toString() + 'M'
    );
  } else {
    return counter.toString();
  }
}

const CampaignTypes = (props) => {
  let { classes } = props;
  const [aggregatedData, setaggregatedData] = useState([]);
  const [summaryData, setsummaryData] = useState({});

  useEffect(() => {
    const fetchCampaignType = async () => {
      try {
        const resp = await ytCampaignTypesFetch(props.brandName, props.startDate, props.endDate, props.searchKeyword, props.paidPost);
        setaggregatedData(resp.campaign_type);
        setsummaryData(resp.summary);
        console.log(resp);
        console.log('Results: ', aggregatedData);
      } catch (error) {
        console.error('Error fetching campaign types:', error);
      }
    };
    fetchCampaignType();
  }, []);
  return (
    <Grid container spacing={3}>
      <Grid item md={12} className={classes.header}>
        <Grid item md={12}>
          <Typography style={{ color: 'black' }}>TYPE OF CAMPAIGNS</Typography>
        </Grid>
      </Grid>
      <Grid item md={12} style={{ paddingTop: '20px', background: 'white' }}></Grid>
      <Grid container spacing={3} className={classes.card}>
        <TableContainer align="center" component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderRight: '1px solid #0D0000',
                    borderBottom: '1px solid #0D0000',
                    background: '#e4e4e4',
                    // background:"linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)",
                  }}
                ></TableCell>
                <TableCell
                  style={{ borderBottom: '1px solid #0D0000' }}
                  className={classes.headerCell}
                  align="center"
                >
                  Count
                </TableCell>
                <TableCell
                  style={{ borderBottom: '1px solid #0D0000' }}
                  className={classes.headerCell}
                  align="center"
                >
                  View Count
                </TableCell>
                <TableCell
                  style={{ borderBottom: '1px solid #0D0000' }}
                  className={classes.headerCell}
                  align="center"
                >
                  Likes
                </TableCell>
                <TableCell
                  style={{ borderBottom: '1px solid #0D0000' }}
                  className={classes.headerCell}
                  align="center"
                >
                  Comments
                </TableCell>
                <TableCell
                  style={{ borderBottom: '1px solid #0D0000', width: '232px' }}
                  className={classes.headerCell}
                  align="center"
                >
                  Creator's Count
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {aggregatedData.map((row) => (
                <TableRow
                  key={row.post_type}
                  //   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      background: '#e4e4e4',
                      borderRight: '1px solid #0D0000',
                      fontWeight: 400,
                      textAlign: 'center',
                      fontSize: '0.875rem',
                    }}
                  >
                    {row.post_type === 'video' ? 'YT Video' : 'YT Shorts'}
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    {countertostring(row.count)}
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    {countertostring(row.view_count)}
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    {countertostring(row.likes_count)}
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    {countertostring(row.comments_count)}
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    {countertostring(row.creators)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow
                key={summaryData.count}
                //   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    background: '#e4e4e4',
                    borderRight: '1px solid #0D0000',
                    borderTop: '1px solid #0D0000',
                    fontWeight: 400,
                    textAlign: 'center',
                    fontSize: '0.875rem',
                  }}
                >
                  Total
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  {countertostring(summaryData.count)}
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  {countertostring(summaryData.view_count)}
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  {countertostring(summaryData.likes_count)}
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  {countertostring(summaryData.comments_count)}
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  {countertostring(summaryData.creators)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const styles = {
  header: {
    color: '#4A4A4A',
    fontSize: '20px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    lineHeight: 1.167,
    letterSpacing: '0.03em',
    padding: '15px',
    background: '#F3F4FE',
    boxShadow: '0px 4px 20px rgba(170, 170, 170, 0.25)',
    borderRadius: '10px',
    marginBottom: '6px',
    marginTop: '30px',
  },
  headerCell: {
    background: '#e4e4e4',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 12,
  },
  table: {
    minWidth: 650,
  },
  cell: {
    textAlign: 'center',
    fontSize: 12,
    //   border: "1px solid #bbbbbb",
  },
  card: {
    background: 'linear-gradient(135.2deg, #EEEFF8 10.88%, rgba(251, 252, 255, 0.76) 100%)',
    boxShadow:
      '-5px 5px 10px rgba(215, 216, 220, 0.2), 5px -5px 10px rgba(215, 216, 220, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(215, 216, 220, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 216, 220, 0.5)',
    borderRadius: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: '52px',
    paddingRight: '52px',
  },
};

export default withStyles(styles)(CampaignTypes);
