import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import moment from 'moment';

import { USE_ES } from 'config';

import Statistics from './cards/instagram/Statistics';
import CostStatistics from './cards/instagram/CostStatistics';
import ContentSplitupGraph from './cards/instagram/ContentSplitupGraph';
import TopPerformers from './cards/common/TopPerformers';
import EngagementGraph from './cards/instagram/EngagementGraph';
import TopHashtags from './cards/instagram/TopHashtags';
import AdStats from './cards/instagram/AdStats';
import TopPosts from './cards/instagram/TopPosts';

import { getInstagramTopPerformers } from '../../api_handlers/reporting';
import { getInstagramTopPerformersES } from '../../api_handlers/es';

class InstagramDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topPerformers: [],
      loading: true,
      topPerformersMetric: 'video_views',
    };
  }

  async getData() {
    const fetchFunction = USE_ES ? getInstagramTopPerformersES : getInstagramTopPerformers;

    const resp = await fetchFunction(
      this.state.topPerformersMetric,
      this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
      this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
    );
    this.setState({ topPerformers: resp.data, loading: false });
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  updateDashboardData() {
    this.getData();
    this.statsRef.reloadData();
    // this.getYoutubeStatsData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.startDate !== this.props.startDate && prevProps.endDate !== this.props.endDate)
      this.updateDashboardData();
  }

  render() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} xl={12}>
          <Grid container spacing={1}>
            <Grid item xs={9} md={8} xl={8}>
              <Grid container spacing={1}>
                <Grid item md={12}>
                  <Statistics
                    socialPlatform={this.props.report?.socialPlatform}
                    {...this.props.report?.statistics}
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}
                    onRef={(ref) => (this.statsRef = ref)}
                    igTotalPosts={this.props.igTotalPosts}
                  />
                </Grid>
                <Grid item md={12}>
                  <Grid container spacing={1}>
                    <Grid item md={6}>
                      <CostStatistics />
                    </Grid>
                    <Grid item md={6}>
                      <ContentSplitupGraph />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} xl={4}>
              <TopPerformers
                topPerformers={this.state.topPerformers}
                loading={this.state.loading}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                onChange={async (metricEvent) => {
                  await this.setState({ topPerformersMetric: metricEvent.target.value });
                  this.getData();
                }}
                onRef={(ref) => (this.topPerformersRef = ref)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item md={8}>
              <EngagementGraph />
            </Grid>
            <Grid item md={4}>
              <TopHashtags top_hashtags={this.props.report?.top_hashtags} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item md={4}>
              <AdStats />
            </Grid>
            <Grid item md={8}>
              <TopPosts top_posts={this.props.report?.top_posts} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
  };
};

export default connect(mapStateToProps)(withRouter(InstagramDashboard));
