import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Button,
  Radio,
  TextField,
  RadioGroup,
  FormControlLabel,
  Paper,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { updateInfluencerProfile } from '../../../api_handlers/influencer.js';

import PersonalDetailForm from '../forms/PersonalDetails';
import MobilePersonalDetailForm from '../forms/MobilePersonalDetails';

class PersonalDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      email: null,
      phoneNumber: null,
      dob: null,
      gender: null,
      addressLine: null,
      city: null,
      state: null,
      pincode: null,
    };
  }

  collectFormData() {
    let formData = {};
    let address = {};

    if (this.state.name) formData['name'] = this.state.name;
    if (this.state.email) formData['user'] = { email: this.state.email };
    if (this.state.phoneNumber) formData['phone_number'] = this.state.phoneNumber;
    if (this.state.dob)
      formData['d_o_b'] = `${this.state.dob.getFullYear()}-${(
        this.state.dob.getMonth() +
        1 +
        ''
      ).padStart(2, '0')}-${(this.state.dob.getUTCDate() + 1 + '').padStart(2, '0')}`;
    if (this.state.gender) formData['gender'] = this.state.gender;

    if (this.state.addressLine) address['address'] = this.state.addressLine;
    if (this.state.city) address['city'] = this.state.city;
    if (this.state.state) address['state'] = this.state.state;
    if (this.state.pincode) address['pin'] = this.state.pincode;

    if (Object.keys(address).length > 0) formData['address'] = address;

    return formData;
  }

  async submitForm() {
    this.form.submitForm();
  }

  get_form() {
    if (window.matchMedia('(min-width: 768px)').matches) {
      return (
        <PersonalDetailForm
          onRef={(ref) => (this.form = ref)}
          callback={this.props.handleNext}
          fieldsToShow={[
            'name',
            'email',
            'phone_number',
            'dob',
            'gender',
            'addressLine',
            'state/city',
            'pincode',
          ]}
        />
      );
    } else {
      return (
        <MobilePersonalDetailForm
          onRef={(ref) => (this.form = ref)}
          callback={this.props.handleNext}
          fieldsToShow={[
            'name',
            'email',
            'phone_number',
            'dob',
            'gender',
            'addressLine',
            'state/city',
            'pincode',
          ]}
        />
      );
    }
  }

  render() {
    return (
      <>
        {this.get_form()}
        <Grid container>
          <Paper style={{ margin: 'auto', marginBottom: '50px' }}>
            <Button
              onClick={() => {
                this.submitForm();
              }}
              style={{
                background: '#1E9CB2',
                color: 'white',
                padding: '5px 20px',
                textTransform: 'none',
              }}
            >
              Save and Continue
            </Button>
          </Paper>
        </Grid>
      </>
    );
  }
}

export default withRouter(PersonalDetails);
