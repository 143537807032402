import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Card, CardContent, Typography, Grid, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ImageButton from 'components/Theme/Elements/ImageButton';
import CampaignLogo from '../../../assets/campaign-logo.svg';
import InstagramIcon from 'assets/discovery/insta-icon.png';
import YoutubeIcon from 'assets/discovery/youtube-icon.png';

import './Campaign.css';

const styles = (theme) => ({
  name: {
    fontFamily: "'Lato', sans-serif",
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'left',
  },
  objective: {
    fontFamily: "'Lato', sans-serif",
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '155.8%',
  },
  description: {
    fontFamily: "'Lato', sans-serif",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '175%',
    color: '#555555',
  },
});

const formatCount = (count) => {
  const parsedCount = parseInt(count);
  if (parsedCount > 9999) {
    return parseInt(parsedCount / 1000) + 'K';
  } else if (parsedCount === NaN) return count;
  else return count;
};

class Campaign extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Card
        style={{
          marginBottom: 20,
          cursor: 'pointer',
        }}
        onClick={() =>
          this.props.history.push(
            `/brand/campaigns/${this.props.id}/dashboard/${
              this.props.id === 137 ? 'instagram' : 'youtube'
            }`,
          )
        }
      >
        <CardContent style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item md={4}>
              <Grid container>
                <Grid item md={4}>
                  <img
                    src={CampaignLogo}
                    alt={'campaign-logo'}
                    style={
                      {
                        // marginTop: '-30px',
                        // marginLeft: '10px'
                      }
                    }
                  />
                </Grid>
                <Grid item md={8}>
                  <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Grid item md={12}>
                      <Typography variant={'h6'} className={`${classes.name} campaign-name`}>
                        {this.props.name}
                      </Typography>
                    </Grid>
                    <Grid item md={12}>
                      <Typography className={`${classes.objective} campaign-objective`}>
                        Objective: {this.props.objective}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} style={{ paddingRight: 10 }}>
              <Chip
                label={
                  this.props.is_live
                    ? 'Live'
                    : this.props.status.replace(/\b[a-z]/g, function (letter) {
                        return letter.toUpperCase();
                      })
                }
                size="small"
                style={{
                  background: this.props.is_live
                    ? '#8517C8'
                    : this.props.status && this.props.status == 'paused'
                    ? 'grey'
                    : this.props.status && this.props.status == 'completed'
                    ? '#65C817'
                    : 'grey',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '11px',
                  padding: '0px 15px 0px',
                }}
              ></Chip>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{
              marginTop: '15px',
            }}
          >
            <Grid
              item
              md={10}
              style={{
                textAlign: 'left',
                paddingLeft: 25,
              }}
            >
              <Typography className={`${classes.description} campaign-description`}>
                {this.props.desc}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <hr />
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item md={12}>
              <Grid container justify="space-evenly" alignItems="center">
                <Grid item md={1}>
                  <ImageButton imageSrc={YoutubeIcon} height={10} width={10} varient="rectangle" />
                </Grid>
                <Grid item md={1}>
                  <dt className={'value'}>Rs. {this.props.budget.toString()}</dt>
                  <dt className={'key'}>Overall Budget</dt>
                </Grid>
                <Grid item md={1}>
                  <dt className={'value'}>
                    {this.props.is_live || this.props.status != 'draft'
                      ? this.props.total_posts
                      : 'N/A'}
                  </dt>
                  <dt className={'key'}>Videos</dt>
                </Grid>
                <Grid item md={1}>
                  <dt className={'value'}>
                    {this.props.is_live || this.props.status != 'draft'
                      ? formatCount(this.props.total_views)
                      : 'N/A'}
                  </dt>
                  <dt className={'key'}>View</dt>
                </Grid>
                <Grid item md={1}>
                  <dt className={'value'}>
                    {this.props.is_live || this.props.status != 'draft'
                      ? formatCount(this.props.total_likes)
                      : 'N/A'}
                  </dt>
                  <dt className={'key'}>Likes</dt>
                </Grid>
                <Grid item md={1}>
                  <dt className={'value'}>
                    {this.props.is_live || this.props.status != 'draft'
                      ? formatCount(this.props.total_comments)
                      : 'N/A'}
                  </dt>
                  <dt className={'key'}>Comments</dt>
                </Grid>
                <Grid item md={1}>
                  <dt className={'value'}>
                    {this.props.is_live || this.props.status != 'draft'
                      ? this.props.total_engagement_rate
                      : 'N/A'}
                  </dt>
                  <dt className={'key'}>Engagement Rate</dt>
                </Grid>
                <Grid item md={1}>
                  <dt className={'value'}>
                    {this.props.duration ? this.props.duration + ' days' : 'N/A'}
                  </dt>
                  <dt className={'key'}>Duration</dt>
                </Grid>
                <Grid item md={1}>
                  <dt className={'value'}>
                    {this.props.start_date ? this.props.start_date : 'N/A'}
                  </dt>
                  <dt className={'key'}>Start Date</dt>
                </Grid>
                <Grid item md={1}>
                  <dt className={'value'}>{this.props.end_date ? this.props.end_date : 'N/A'}</dt>
                  <dt className={'key'}>End Date</dt>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {this.props.allowedSocailMediaLinks.includes('instagram') ? (
            <>
              <Grid container>
                <hr />
              </Grid>
              <Grid container direction="row" justify="center" alignItems="center">
                <Grid item md={12}>
                  <Grid container justify="space-evenly" alignItems="center">
                    <Grid item md={1}>
                      <ImageButton
                        imageSrc={InstagramIcon}
                        height={10}
                        width={10}
                        varient="rectangle"
                      />
                    </Grid>
                    <Grid item md={1}>
                      <dt className={'value'}>Rs. {this.props.budget.toString()}</dt>
                      <dt className={'key'}>Overall Budget</dt>
                    </Grid>
                    <Grid item md={1}>
                      <dt className={'value'}>
                        {this.props.is_live || this.props.status != 'draft'
                          ? this.props.ig_total_posts
                          : 'N/A'}
                      </dt>
                      <dt className={'key'}>Posts</dt>
                    </Grid>
                    <Grid item md={1}>
                      <dt className={'value'}>
                        {this.props.is_live || this.props.status != 'draft'
                          ? formatCount(this.props.ig_total_views)
                          : 'N/A'}
                      </dt>
                      <dt className={'key'}>Views</dt>
                    </Grid>
                    <Grid item md={1}>
                      <dt className={'value'}>
                        {this.props.is_live || this.props.status != 'draft'
                          ? formatCount(this.props.ig_total_likes)
                          : 'N/A'}
                      </dt>
                      <dt className={'key'}>Engagement</dt>
                    </Grid>
                    <Grid item md={1}>
                      <dt className={'value'}>
                        {this.props.is_live || this.props.status != 'draft'
                          ? formatCount(this.props.ig_total_impression)
                          : 'N/A'}
                      </dt>
                      <dt className={'key'}>Impressions</dt>
                    </Grid>
                    <Grid item md={1}>
                      <dt className={'value'}>
                        {this.props.is_live || this.props.status != 'draft'
                          ? formatCount(this.props.ig_total_reach)
                          : 'N/A'}
                      </dt>
                      <dt className={'key'}>Reach</dt>
                    </Grid>
                    <Grid item md={1}>
                      <dt className={'value'}>
                        {this.props.is_live || this.props.status != 'draft'
                          ? this.props.ig_total_engagement_rate
                          : 'N/A'}
                      </dt>
                      <dt className={'key'}>Engagement Rate</dt>
                    </Grid>

                    <Grid item md={1}>
                      <dt className={'value'}>
                        {this.props.start_date ? this.props.start_date : 'N/A'}
                      </dt>
                      <dt className={'key'}>Start Date</dt>
                    </Grid>
                    <Grid item md={1}>
                      <dt className={'value'}>
                        {this.props.end_date ? this.props.end_date : 'N/A'}
                      </dt>
                      <dt className={'key'}>End Date</dt>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            ''
          )}
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allowedSocailMediaLinks: state.brand.displayPanels?.social_media.link_display,
  };
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Campaign)));
