import { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import fbLogo from '../../assets/fb.png';
import instaLogo from '../../assets/insta.png';
import linkedinLogo from '../../assets/linknew.png';
import twitterLogo from '../../assets/twitter.png';
import youtubeLogo from '../../assets/youtube-logo.png';

class Footer extends Component {
  render() {
    return (
      <Grid
        container
        alignItems="center"
        spacing={1}
        style={{
          background: '#E5E5E5',
          width: '100vw',
        }}
      >
        <Grid item md={12}>
          <span>
            <a
              href="https://www.influencer.in/terms-and-conditions/"
              target="_blank"
              style={{ 'text-decoration': 'none', cursor: 'pointer', color: '#4E5B67' }}
            >
              Terms
            </a>
            <span>&nbsp; | &nbsp;</span>
            <a
              href="https://www.influencer.in/contact/"
              target="_blank"
              style={{ 'text-decoration': 'none', cursor: 'pointer', color: '#4E5B67' }}
            >
              Contact Us
            </a>
            <span>&nbsp; | &nbsp;</span>
            <a
              href="https://www.influencer.in/privacy-policy/"
              target="_blank"
              style={{ 'text-decoration': 'none', cursor: 'pointer', color: '#4E5B67' }}
            >
              Privacy Policy
            </a>
            <span>&nbsp; | &nbsp;</span>
            <a
              href="https://www.influencer.in/disclosure/"
              target="_blank"
              style={{ 'text-decoration': 'none', cursor: 'pointer', color: '#4E5B67' }}
            >
              Disclousre
            </a>
            <span>&nbsp; | &nbsp;</span>
            <a
              href="https://www.youtube.com/t/terms"
              target="_blank"
              style={{ 'text-decoration': 'none', cursor: 'pointer', color: '#4E5B67' }}
            >
              YouTube Terms of Service
            </a>
            <span>&nbsp; | &nbsp;</span>
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              style={{ 'text-decoration': 'none', cursor: 'pointer', color: '#4E5B67' }}
            >
              Google Privacy Policy
            </a>
            <span>&nbsp; | &nbsp;</span>
            <a
              href="https://myaccount.google.com/permissions"
              target="_blank"
              style={{ 'text-decoration': 'none', cursor: 'pointer', color: '#4E5B67' }}
            >
              Google Security Settings
            </a>
          </span>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{
            background: '#E5E5E5',
            width: '100vw',
            fontSize: '8px',
          }}
        >
          <Grid item md={6} alignItems="flex-start">
            <a href="https://www.facebook.com/influencer.in/" target="_blank">
              <img src={fbLogo} alt="fb-logo" className="fb-logo InlineBlock" />
            </a>
            &nbsp;
            <a href="https://www.instagram.com/influencer.in/" target="_blank">
              <img src={instaLogo} alt="insta-logo" className="insta-logo InlineBlock" />
            </a>
            &nbsp;
            <a href="https://twitter.com/SpotInfluencer" target="_blank">
              <img src={twitterLogo} alt="twitter-logo" className="twitter-logo InlineBlock" />
            </a>
            &nbsp;
            <a href="https://www.youtube.com/channel/UCG9I7TOp2GJ22fRbIzz0vxw" target="_blank">
              <img src={youtubeLogo} alt="youtube-logo" className="youtube-logo InlineBlock" style={{ height: '24px' }}/>
            </a>
            &nbsp;
            <a href="https://www.linkedin.com/company/influencer-in/" target="_blank">
              <img src={linkedinLogo} alt="linkedin-logo" className="linkedin-logo InlineBlock" />
            </a>
          </Grid>
          <Grid item md={6} alignItems="flex-end">
            PRODUCT OF SOCIAL BEAT
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default Footer;
