import { Grid, Typography, withStyles } from '@material-ui/core';
import LocationIcon from 'assets/discovery/Location-Icon.png';
import MultiSelect from 'components/PlannerTheme/Elements/MultiSelect';
import { cityStateList } from 'api_handlers/influencer';
import { useState, useEffect } from 'react';

function InfluencerZone({ influencerZone, hideAdvanceFilters, classes }) {
  const [states, setStates] = useState(['North', 'South', 'East', 'West']);

  return (
    <Grid container direction="column">
      <Grid
        item
        style={{
          display: 'flex',
          marginBottom: 10,
          justifyContent: 'center',
        }}
      >
        <img src={LocationIcon} width={22} style={{ marginRight: 7 }} />
        <Typography className={classes.advanceHeading}>Regions</Typography>
      </Grid>
      <Grid item>
        <MultiSelect
          label={'Select Region'}
          options={states
            .filter((location) => location)
            .map((location) => ({ label: location, value: location }))}
          value={influencerZone || []}
          onChange={(event, selectedValues) => {
            hideAdvanceFilters('influencerZone', selectedValues);
          }}
        />
      </Grid>
    </Grid>
  );
}

const styles = {
  advanceHeading: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 14,
  },
};

export default withStyles(styles)(InfluencerZone);
