import { useEffect, useState, Component } from 'react';
import { Grid, Typography, TextField, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Chip } from '@material-ui/core';

import Tick from 'assets/planner/tick.png';

const locationsMap = [
  'Pune',
  'Baghpat',
  'Gorakhpur',
  'Bellary',
  'Bagalkot',
  'Bremen',
  'Bharatpur',
  'Eastchester',
  'Baharampur',
  'Ottawa',
  'Gwalior',
  'New York City',
  'Chitradurga',
  'Karachi',
  'Kozhikode',
  'Thiruvananthapuram',
  'Uttarkashi',
  'Rewari',
  'Kurukshetra',
  'Shimla',
  'Karimnagar',
  'Buenos Aires',
  'Vadodara',
  'Jorhat, Assam',
  'Sikar',
  'Kullu',
  'Panipat',
  'Faridabad',
  'Ernakulam',
  'Sheffield',
  'Belgaum',
  'Alwar',
  'Panjim',
  'Beed',
  'Orai',
  'Jodhpur',
  'Boston',
  'Chennai',
  'Nizamabad',
  'Bardhaman',
  'Churu',
  'Bhilwara',
  'Nagaur',
  'Dausa',
  'Patiala',
  'Sonipat',
  'Jammu',
  'Jalgaon',
  'Chapel Hill',
  'Chandigarh',
  'Jaunpur, Uttar Pradesh',
  'Jalore',
  'Gurdaspur',
  'Chicago',
  'Kota',
  'Bhiwani',
  'Waterloo (Ontario)',
  'Los Angeles',
  'Tumkur',
  'Bishnupur, Bankura',
  'Solan',
  'Mangalore',
  'San Francisco',
  'Buldhana',
  'Haridwar',
  'Nanded',
  'Jakarta',
  'New Delhi',
  'Singapore',
  'Salem',
  'Ajmer',
  'Khammam',
  'Keylong',
  'Kurnool',
  'Gulbarga',
  'Toronto',
  'Etah',
  'Nainital',
  'Thane',
  'Brisbane',
  'Rampur',
  'Muradabad',
  'Korba',
  'South Lakeland',
  'Houston',
  'Guntur',
  'Sundergarh',
  'Anantnag',
  'Bangalore',
  'Chatra, Jharkhand',
  'Sacramento',
  'Sirsa',
  'Rajnandgaon',
  'Koraput',
  'Karnal',
  'Noida',
  'Barcelona',
  'Delhi',
  'Miami',
  'Chikmagalur',
  'Melbourne',
  'Shahjahanpur',
  'Bikaner',
  'Domodossola',
  'Sangli',
  'Balurghat',
  'Rajsamand',
  'Manjhanpur',
  'Shivpuri',
  'Ajman',
  'Banswara',
  'Mumbai',
  'Shillong',
  'Pasig',
  'Kathmandu',
  'Vancouver',
  'Kohima',
  'Anantapur, Andhra Pradesh',
  'Ongole',
  'Hamburg',
  'Jhansi',
  'Varanasi',
  'Leh',
  'Yavatmal',
  'Nagpur',
  'Dehradun',
  'Aligarh',
  'Tezpur',
  'Kollam',
  'Detroit',
  'Paris',
  'London',
  'Rudrapur',
  'Kapurthala',
  'Kheda',
  'Austin',
  'Ramanathapuram',
  'Malappuram',
  'Nagapattinam',
  'Pathanamthitta',
  'Frankfurt',
  'Ambala',
  'Narnaul',
  'Sri Ganganagar',
  'Dhaka',
  'Kaithal',
  'Surat',
  'Latur',
  'Bathinda',
  'Udupi',
  'Montreal',
  'Old Delhi',
  'Hanumangarh',
  'Fredericksburg',
  'Incheon',
  'Lisbon',
  'Valsad',
  'Anand',
  'Vellore',
  'Seattle',
  'Ratlam',
  'Hyderabad',
  'Santa Barbara',
  'Chinsurah',
  'Rio de Janeiro',
  'Baramulla',
  'Mehsana',
  'Ghazipur',
  'Dibrugarh',
  'Ahmedabad',
  'Madurai',
  'Gurugram',
  'Midnapore',
  'Mirzapur',
  'Chesterfield',
  'Bankura',
  'Hassan',
  'Kadapa',
  'Naples',
  'Kasaragod',
  'Panchkula',
  'Margao',
  'Thrissur',
  'Gandhinagar',
  'Karwar',
  'Moga, Punjab',
  'Kannur',
  'Rajauri',
  'Gondia',
  'Ahmednagar',
  'Kanpur',
  'Angul',
  'Abu Dhabi',
  'Firozpur',
  'Charleston',
  'Mandya',
  'Satna',
  'Cuddalore',
  'Lagos',
  'Rajkot',
  'Erode',
  'Jamshedpur',
  'Minneapolis',
  'Vijayawada',
  'Saharanpur',
  'Tigard',
  'Tirunelveli',
  'Indore',
  'Raipur',
  'Silchar',
  'San Sebastián',
  'Satara',
  'Sirohi',
  'Cuttack',
  'Barmer, Rajasthan',
  'São Paulo',
  'Fatehpur',
  'Palakkad',
  'Brig-Glis',
  'Sultanpur, Uttar Pradesh',
  'Eluru',
  'Solapur',
  'Balasore',
  'Painavu',
  'Baripada',
  'Jalandhar',
  'Akola',
  'Ranchi',
  'Jajpur',
  'Patna',
  'Baton Rouge',
  'Hoshiarpur',
  'Deoghar',
  'Durg',
  'Bolton',
  'Amravati',
  'Jabalpur',
  'Alappuzha',
  'Prayag',
  'Mission',
  'Semarang',
  'Hamirpur, Himachal Pradesh',
  'Khordha',
  'Guwahati',
  'Brampton',
  'Perth',
  'Nagaon',
  'Mysore',
  'Dindigul',
  'Mississauga',
  'Patan',
  'Dubai',
  'Wardha',
  'Athens-Clarke County',
  'Riyadh',
  'Guna',
  'Ludhiana',
  'Firozabad',
  'Bulandshahr',
  'Dharwad',
  'Ghaziabad',
  'Hathras',
  'Dharamsala',
  'Kakinada',
  'Gurgaon',
  'Meerut',
  'Dhanbad',
  'Faridkot',
  'Hazaribagh',
  'Pauri',
  'Namchi',
  'Tamluk',
  'Godhra',
  'Munich',
  'Darjeeling',
  'Pali',
  'Krishnanagar',
  'Agra',
  'Kolhapur',
  'Saharsa',
  'Auckland',
  'Sivaganga',
  'Thoothukudi',
  'Jaipur',
  'Warangal Urban district',
  'Ratnagiri',
  'Dharmapuri',
  'Lucknow',
  'Bilaspur',
  'Mathura',
  'Kanker',
  'Udaipur',
  'Davangere',
  'Amritsar',
  'Rohtak',
  'Northampton',
  'Washington, D.C.',
  'Tbilisi',
  'Coimbatore',
  'Bhawanipatna',
  'Bloomington',
  'Surendranagar',
  'Visakhapatnam',
  'Dahod',
  'Bundi',
  'Chhatarpur',
  'Milan',
  'Sarasota',
  'Bareilly',
  'Ujjain',
  'Deoria, Uttar Pradesh',
  'Yamuna Nagar',
  'Agartala',
  'Mandy',
  'Jaisalmer',
  'Nashik',
  'Kottayam',
  'Sitapur',
  'Jalna',
  'Kolkata',
  'Chamoli Gopeshwar',
  'Bhopal',
  'Manchester',
  'Giridih',
  'Aurangabad',
];

class AudienceLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locationText: '',
      valueText: '',
      isCheck: Array.from(new Set(this.props.locations)),
    };
  }

  handleDelete = (t) => {
    this.props.locations.splice(this.props.locations.indexOf(t), 1);
    this.state.isCheck.splice(this.state.isCheck.indexOf(t), 1);
    this.setState({
      isCheck: this.state.isCheck,
    });
  };

  render() {
    let { classes, locations } = this.props;

    let locationText = this.state.locationText;

    return (
      <Grid
        container
        direction="column"
        style={{
          marginRight: 400,
          maxWidth: '30%',
          border: '1px solid #ADADAD',
          borderRadius: '10px',
          padding: 10,
        }}
      >
        <Grid
          item
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            display: 'flex',
          }}
        >
          <Grid container>
            <Grid item>
              <TextField
                placeholder="Search"
                className={classes.search}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <IconButton
                      sx={{ visibility: locationText ? 'visible' : 'hidden' }}
                      onClick={() =>
                        this.setState({
                          valueText: '',
                          locationText: '',
                        })
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                value={this.state.valueText}
                onChange={(e) => {
                  this.setState({
                    locationText: e.target.value,
                    valueText: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item>
              <Button
                className={classes.resetButton}
                onClick={() => {
                  this.props.setLocations(
                    'locations',
                    [''].filter((data) => data[0]),
                  );
                  //   this.setState({ valueText: '' });
                }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ display: 'inline-block', height: '1px', width: '100%' }}></div>
        <Grid
          item
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            maxHeight: 200,
            overflow: 'scroll',
            alignContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          {this.state.isCheck.map((tags) => {
            if (locations.indexOf(tags) > -1) {
              return (
                <Grid
                  item
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'flex-start',
                    marginTop: '10px',
                  }}
                >
                  <Chip
                    label={tags}
                    variant="outlined"
                    onDelete={() => {
                      this.handleDelete(tags);
                    }}
                    className={classes.chipset}
                  />
                </Grid>
              );
            }
          })}
          <div style={{ display: 'inline-block', height: '1px', width: '100%' }}></div>
          {locationsMap.map((each) => {
            if (each.toLowerCase().indexOf(locationText.toLowerCase()) > -1) {
              return (
                <Grid
                  container
                  style={{
                    width: 100,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    margin: 10,
                    padding: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    boxSizing: 'border-box',
                    border: locations.indexOf(each) > -1 ? '1px solid #FEBD1C' : 'none',
                    borderRadius: 20,
                  }}
                  onClick={(e) => {
                    if (locations.indexOf(each) > -1) {
                      locations.splice(locations.indexOf(each), 1);
                      this.state.isCheck.splice(this.state.isCheck.indexOf(each), 1);
                      this.setState({
                        isCheck: this.state.isCheck,
                      });
                    } else {
                      locations.push(each);
                      this.setState({
                        isCheck: this.state.isCheck.concat(each),
                      });
                    }
                    this.props.setLocations('locations', locations);
                    // this.setState({ valueText: '' });
                  }}
                >
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item xs={3}>
                        {locations.indexOf(each) > -1 ? (
                          <img src={Tick} width={14} height={10} style={{ marginRight: 3 }} />
                        ) : null}
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          className={
                            locations.indexOf(each) > -1 ? classes.selectedText : classes.default
                          }
                        >
                          {each}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
          })}
        </Grid>
      </Grid>

      //   <div
      //     style={{
      //       flex: 3,
      //       justifyContent: 'flex-start',
      //       display: 'flex',
      //       flexWrap: 'wrap',
      //       marginRight: 50,
      //     }}
      //   >
      //     <div
      //       style={{
      //         width: 400,
      //         border: '1px solid #ADADAD',
      //         borderRadius: '10px',
      //         padding: 10,
      //       }}
      //     >
      //       <div
      //         style={{
      //           width: '100%',
      //           justifyContent: 'flex-start',
      //           display: 'flex',
      //         }}
      //       >
      //         <TextField
      //           placeholder='Search'
      //           className={classes.search}
      //           InputProps={{ disableUnderline: true }}
      //           value={this.state.valueText}
      //           onChange={(e) => {
      //             this.setState({
      //               locationText: e.target.value,
      //               valueText: e.target.value,
      //             });
      //           }}
      //         />
      //       </div>
      //       <div
      //         style={{
      //           display: 'flex',
      //           flexWrap: 'wrap',
      //           width: '100%',
      //           maxHeight: 200,
      //           overflow: 'scroll',
      //         }}
      //       >
      //         {locationsMap.map((each) => {
      //           if (each.toLowerCase().indexOf(locationText.toLowerCase()) > -1) {
      //             return (
      //               <Grid
      //                 container
      //                 style={{
      //                   width: 100,
      //                   display: 'flex',
      //                   justifyContent: 'flex-start',
      //                   alignItems: 'center',
      //                   margin: 10,
      //                   padding: 5,
      //                   paddingLeft: 10,
      //                   paddingRight: 10,
      //                   boxSizing: 'border-box',
      //                   border:
      //                     locations.indexOf(each) > -1
      //                       ? '1px solid #FEBD1C'
      //                       : 'none',
      //                   borderRadius: 20,
      //                 }}
      //                 onClick={(e) => {
      //                   if (locations.indexOf(each) > -1) {
      //                     locations.splice(locations.indexOf(each), 1);
      //                   } else {
      //                     locations.push(each);
      //                   }
      //                   this.props.setLocations('locations', locations);
      //                   this.setState({ valueText: '' });
      //                 }}
      //               >
      //                 <Grid item xs={4}>
      //                   <Grid
      //                     container
      //                     justifyContent='center'
      //                     alignItems='center'
      //                   >
      //                     <Grid item xs={8}>
      //                       {locations.indexOf(each) > -1 ? (
      //                         <img
      //                           src={Tick}
      //                           width={14}
      //                           height={10}
      //                           style={{ marginRight: 3 }}
      //                         />
      //                       ) : null}
      //                     </Grid>
      //                     <Grid item xs={4}>
      //                       <Typography
      //                         className={
      //                           locations.indexOf(each) > -1
      //                             ? classes.selectedText
      //                             : classes.default
      //                         }
      //                       >
      //                         {each}
      //                       </Typography>
      //                     </Grid>
      //                   </Grid>
      //                 </Grid>
      //               </Grid>
      //               //   <div
      //               //     style={{
      //               //       width: 100,
      //               //       display: 'flex',
      //               //       justifyContent: 'flex-start',
      //               //       alignItems: 'center',
      //               //       margin: 10,
      //               //       padding: 5,
      //               //       paddingLeft: 10,
      //               //       paddingRight: 10,
      //               //       boxSizing: 'border-box',
      //               //       border:
      //               //         locations.indexOf(each) > -1
      //               //           ? '1px solid #FEBD1C'
      //               //           : 'none',
      //               //       borderRadius: 20,
      //               //     }}
      //               //     onClick={(e) => {
      //               //       if (locations.indexOf(each) > -1) {
      //               //         locations.splice(locations.indexOf(each), 1);
      //               //       } else {
      //               //         locations.push(each);
      //               //       }
      //               //       this.props.setLocations('locations', locations);
      //               //       this.setState({ valueText: '' });
      //               //     }}
      //               //   >
      //               //     {locations.indexOf(each) > -1 ? (
      //               //       <img
      //               //         src={Tick}
      //               //         width={14}
      //               //         height={10}
      //               //         style={{ marginRight: 3 }}
      //               //       />
      //               //     ) : null}
      //               //     <Typography
      //               //       className={
      //               //         locations.indexOf(each) > -1
      //               //           ? classes.selectedText
      //               //           : classes.default
      //               //       }
      //               //     >
      //               //       {each}
      //               //     </Typography>
      //               //   </div>
      //             );
      //           }
      //         })}
      //       </div>
      //     </div>
      //   </div>
    );
  }
}

const styles = {
  search: {
    border: '1px solid #C4C4C4',
    borderRadius: '30px',
    width: 300,
    outline: 'none',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 10,
  },
  selectedText: {
    color: '#FEBD1C',
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: 13,
    cursor: 'pointer',
    textAlign: 'left',
  },
  default: {
    color: '#757575',
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 13,
    cursor: 'pointer',
    textAlign: 'left',
  },
  resetButton: {
    textTransform: 'none',
    color: '#757575',
  },
  chipset: {
    fontFamily: 'Nunito Sans',
    background: '#FFFFFF',
    borderRadius: 34,
    borderWidth: 1,
    borderColor: '#C9C9C9',
    fontSize: 12,
    fontWeight: 400,
    cursor: 'pointer',
  },
};

export default withStyles(styles)(AudienceLocation);
