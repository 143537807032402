import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Tick from 'assets/planner/tick.png';

const ageGroupMap = ['18-24', '25-34', '35-44', '45-54', '55+'];

class AudienceAgeGroup extends React.Component {
  render() {
    const { classes, ageGroup } = this.props;

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 50 }}>
        {ageGroupMap.map((each) => {
          return (
            <div
              onClick={() => {
                if (ageGroup.indexOf(each) > -1) {
                  ageGroup.splice(ageGroup.indexOf(each), 1);
                } else {
                  ageGroup.push(each);
                }
                this.props.setAgeGroup('ageGroup', ageGroup);
              }}
              className={
                ageGroup.indexOf(each) > -1 ? classes.selectedBorder : classes.defaultBorder
              }
            >
              {ageGroup.indexOf(each) > -1 ? (
                <img src={Tick} width={17} height={13} style={{ marginRight: 5 }} />
              ) : null}
              <Typography
                className={ageGroup.indexOf(each) > -1 ? classes.selectedText : classes.default}
              >
                {each} Yrs
              </Typography>
            </div>
          );
        })}
      </div>
    );
  }
}

const styles = {
  selectedText: {
    color: '#FEBD1C',
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    fontSize: 13,
    cursor: 'pointer',
  },
  default: {
    color: '#757575',
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: 13,
    cursor: 'pointer',
  },
  selectedBorder: {
    display: 'flex',
    alignItems: 'center',
    margin: 10,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    border: '1px solid #FEBD1C',
    borderRadius: 20,
  },
  defaultBorder: {
    display: 'flex',
    alignItems: 'center',
    margin: 10,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    border: '1px solid #757575',
    borderRadius: 20,
  },
};

export default withStyles(styles)(AudienceAgeGroup);
