import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';

// import {INSTAGRAM_HOST, INSTAGRAM_APP_ID}

class InstagramCallback extends Component {
  async getAccessToken() {
    const params = {};
    this.props.location.search
      .replace('?', '')
      .split('&')
      .map((pair) => (params[pair.split('=')[0]] = pair.split('=')[1]));
    const data = {
      client_id: '487541268980617',
      client_secret: 'e98e72fcf203e1e84dfc173dda553f68',
      grant_type: 'authorization_code',
      redirect_uri: 'https://localhost:3000/instagram/callback/',
      code: params.code,
    };
    const response = await fetch('https://api.instagram.com/oauth/access_token', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // headers: {
      //   'Content-Type': 'application/json'
      //   // 'Content-Type': 'application/x-www-form-urlencoded',
      // },
      // redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
  }

  componentDidMount() {
    this.getAccessToken();
  }

  render() {
    return (
      <Grid
        container
        alignItems="center"
        spacing={1}
        style={{
          background: 'white',
          height: 50,
        }}
      ></Grid>
    );
  }
}

export default withRouter(InstagramCallback);
